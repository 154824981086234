import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { titleCase } from 'js/utils/string';
import { Icon } from 'js/components';

const RegistrationForm = (props) => {
  const {
    state,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    defaultUsername,
  } = props;

  const formikAttributes = (fieldName, errorFieldName) => {
    errorFieldName = errorFieldName || fieldName;

    return {
      id: fieldName,
      name: fieldName,
      value: values[fieldName],
      onChange: handleChange,
      onBlur: handleBlur,
      invalid:
        !!(state.error && state.error[errorFieldName]) ||
        (errors[fieldName] && touched[fieldName]),
    };
  };

  const feedback = (fieldName, errorFieldName) => {
    errorFieldName = errorFieldName || fieldName;

    const messages = [];

    if (errors[fieldName] && touched[fieldName]) {
      messages.push(
        <FormFeedback key={errors[fieldName]}>{errors[fieldName]}</FormFeedback>
      );
    }

    if (state.error && state.error[fieldName]) {
      state.error[fieldName].forEach((message) => {
        messages.push(
          <FormFeedback key={message}>
            {titleCase(fieldName)} {message}
          </FormFeedback>
        );
      });
    }

    return messages;
  };

  const renderErrors = () => {
    if (state.error) {
      return (
        <div>
          <ul>
            {_.map(state.error, (messages, fieldKey) => {
              return messages.map((message) => {
                return (
                  <li
                    className="invalid-feedback"
                    key={`${fieldKey}${message}`}
                  >
                    {titleCase(fieldKey)} {message}
                  </li>
                );
              });
            })}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="registration-form">
      {renderErrors()}
      <fieldset>
        <legend>
          <Icon icon="user" className="icon header-icon" />
          <span>You</span>
          <small>Tell me who are you</small>
        </legend>
        <Row>
          <Col>
            <FormGroup>
              <Label for="username">Email Address</Label>
              <Input
                type="text"
                autoFocus={!defaultUsername}
                disabled={!!defaultUsername}
                readOnly={!!defaultUsername}
                {...formikAttributes('username')}
              />
              {feedback('username')}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="display_name">Name</Label>
              <Input
                type="text"
                {...formikAttributes('display_name')}
                autoFocus={!!defaultUsername}
              />
              {feedback('display_name')}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input type="password" {...formikAttributes('password')} />
              {feedback('password')}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="confirmPassword">Re-enter password</Label>
              <Input type="password" {...formikAttributes('confirmPassword')} />
              {feedback('confirmPassword')}
            </FormGroup>
          </Col>
        </Row>
      </fieldset>
      {/* <fieldset>
        <legend>
          <Icon icon="users" className="icon header-icon" />
          <span>Your team</span>
          <small>Create a new team now, or don't. It's easy to do later.</small>
        </legend>
        <Row>
          <Col>
            <FormGroup>
              <Label for="teamName">Team</Label>
              <Input type="text" {...formikAttributes('teamName')} />
              {feedback('teamName')}
            </FormGroup>
          </Col>
        </Row>
      </fieldset> */}
      <Row>
        <Col>
          <Button
            className="btn-block"
            type="submit"
            value="submit"
            color="primary"
          >
            <Icon icon="rocket" className="btn-icon-left" />
            Sign me up!
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const FormikRegistrationForm = withFormik({
  mapPropsToValues: ({ defaultUsername }) => {
    return {
      username: defaultUsername || '',
      display_name: '',
      password: '',
      confirmPassword: '',
      teamName: '',
    };
  },
  validationSchema: Yup.object().shape({
    username: Yup.string()
      .trim()
      .required(
        "If you don't tell me your name, how will I know what to call you?"
      )
      .email("Hmm, I'll need a valid email address.")
      .max(128),
    display_name: Yup.string().trim().min(3).max(32),
    password: Yup.string()
      .required('You probably want a lock on this door')
      .min(8, 'It needs to be a little longer (8 characters minimum)'),
    confirmPassword: Yup.string().test(
      'match',
      "Your passwords don't match",
      function (password) {
        return password === this.options.parent.password;
      }
    ),
  }),
  handleSubmit: (values, bag) => {
    const { setSubmitting, props } = bag;
    // do nothing
    setSubmitting(true);
    props.onSave({
      username: values.username,
      display_name: values.display_name,
      password: values.password,
      teamName: values.teamName,
    });

    //    setSubmitting(false);
  },
  displayName: 'RegistrationForm',
})(RegistrationForm);

FormikRegistrationForm.propTypes = {
  onSave: PropTypes.func,
};

FormikRegistrationForm.defaultProps = {
  onSave: () => {},
};

export default FormikRegistrationForm;
