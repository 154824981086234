import { trueToFalse } from './base-answers';

export const piPlanningPreparation = {
  name: 'PI Planning Preparation',
  key: 'pi-planning-prep',
  category: 'develop-on-cadence',
  questions: [
    {
      text: 'Prior to PI Planning, our ART defines features with clearly stated benefits',
      key: 'features-state-benefits',
      answers: trueToFalse,
    },
    {
      text: 'Prior to PI Planning, our ART writes features with clearly defined Acceptance Criteria',
      key: 'features-have-clear-acceptance-criteria',
      answers: trueToFalse,
    },
    {
      text: 'Prior to PI Planning, our ART correctly applies WSJF to prioritize the program backlog',
      key: 'wsjf-prioritization',
      answers: trueToFalse,
    },
    {
      text: 'Prior to PI Planning, our ART socializes new features.',
      key: 'socialize-new-features',
      answers: trueToFalse,
    },
    {
      text: 'Prior to PI planning, our ART sufficiently prepares to achieve the desired event outcomes',
      key: 'sufficient-preparation-for-pi-planning',
      answers: trueToFalse,
    },
  ],
};
