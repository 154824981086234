import { trueToFalse as answers } from './base-answers';

export const solutionDelivery = {
  name: 'Enterprise Solution Delivery (ESD)',
  key: 'enterprise-solution-delivery',
  category: 'solution-delivery',
  questions: [
    {
      text: 'We use roadmaps to communicate potential deliverables over multiple planning horizons',
      key: 'use-roadmaps',
      answers,
    },
    {
      text: 'We allow requirements and design to emerge throughout the development process',
      key: 'emergent-requirements',
      answers,
    },
    {
      text: 'Our solution architecture is both intentional and evolutionary',
      key: 'intentional-and-emergent-architecture',
      answers,
    },
    {
      text: 'We perform compliance activities incrementally',
      key: 'incremental-compliance-activities',
      answers,
    },
    {
      text: 'Our suppliers participate in our Agile development processes',
      key: 'suppliers-involved-in-development',
      answers,
    },
    {
      text: 'We can update deployed solutions on demand',
      key: 'deploy-on-demand',
      answers,
    },
  ],
};
