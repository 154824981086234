import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withFormik } from 'formik';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  FormFeedback
} from 'reactstrap';
import * as Yup from 'yup';
import { titleCase } from 'js/utils/string';

import { Icon } from 'js/components';

const ResetPasswordForm = ({
  state,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting
}) => {
  const formikAttributes = (fieldName, errorFieldName) => {
    errorFieldName = errorFieldName || fieldName;

    return {
      id: fieldName,
      name: fieldName,
      value: values[fieldName],
      onChange: handleChange,
      onBlur: handleBlur,
      invalid:
        !!(state.error && state.error[errorFieldName]) ||
        (errors[fieldName] && touched[fieldName])
    };
  };

  const feedback = (fieldName, errorFieldName) => {
    errorFieldName = errorFieldName || fieldName;

    const messages = [];

    if (errors[fieldName] && touched[fieldName]) {
      messages.push(
        <FormFeedback key={errors[fieldName]}>{errors[fieldName]}</FormFeedback>
      );
    }

    if (state.error && state.error[fieldName]) {
      state.error[fieldName].forEach(message => {
        messages.push(
          <FormFeedback key={message}>
            {titleCase(fieldName)} {message}
          </FormFeedback>
        );
      });
    }

    return messages;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <fieldset>
        <Row>
          <Col>
            <FormGroup>
              <Label for="password">New password</Label>
              <Input type="password" {...formikAttributes('password')} />
              {feedback('password')}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="confirmPassword">Re-enter password</Label>
              <Input type="password" {...formikAttributes('confirmPassword')} />
              {feedback('confirmPassword')}
            </FormGroup>
          </Col>
        </Row>
      </fieldset>

      <Button
        type="submit"
        color="primary"
        size="lg"
        className="full-width-sm"
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <Icon
            icon={['far', 'circle-notch']}
            className="btn-icon-left"
            size="lg"
            spin
          />
        ) : (
          <Icon icon="key-skeleton" className="btn-icon-left" size="lg" />
        )}
        Reset my password
      </Button>
    </Form>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func
};

ResetPasswordForm.defaultProps = {
  onSubmit: _.noop,
  state: {}
};

const FormikResetPasswordForm = withFormik({
  mapPropsToValues: () => ({
    password: '',
    confirmPassword: ''
  }),
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .required('You need to put a lock on this door')
      .min(8, 'It needs to be a little longer (8 characters minimum)'),
    confirmPassword: Yup.string().test(
      'match',
      "Your passwords don't match",
      function(password) {
        return password === this.options.parent.password;
      }
    )
  }),
  handleSubmit: async (values, bag) => {
    const { setSubmitting, props } = bag;
    setSubmitting(true);

    try {
      await Promise.resolve(props.onSubmit(values));
    } catch {
      // do nothing
    }

    setSubmitting(false);
  },
  displayName: 'ResetPasswordForm'
})(ResetPasswordForm);

export default FormikResetPasswordForm;
