import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { login, loginAsGuest } from 'js/actions/account-actions';

import LoginPageComponent from '../components/LoginPageComponent';

function LoginPageContainer({
  currentWorkspaceId,
  me,
  history,
  location,
  login,
  loginAsGuest,
}) {
  const _handleLogin = (form) => {
    return _login(() => login(form.username, form.password));
  };

  const _handleLoginAsGuest = () => {
    return _login(loginAsGuest);
  };

  const _login = (loginDelegate) => {
    const { from = {} } = location.state || {};

    return loginDelegate().then((me) => {
      let url;
      if (me.workspaces.length > 0) {
        url = from.pathname
          ? from.pathname + (from.search ?? '')
          : `/w/${currentWorkspaceId || me.workspaces[0].id}/dashboard`;
      } else {
        url = from.pathname
          ? from.pathname + (from.search ?? '')
          : `/a/${me.accounts[0].id}`;
      }

      setTimeout(() => {
        history.push(url);
      }, 0);
    });
  };

  const { from = {} } = location.state || {};

  return (
    <LoginPageComponent
      from={from}
      onLogin={_handleLogin}
      onLoginAsGuest={_handleLoginAsGuest}
    />
  );
}

export default withRouter(
  connect(
    (state) => ({
      currentWorkspaceId: state.currentWorkspaceId,
      me: state.identity.me,
    }),
    {
      login,
      loginAsGuest,
    }
  )(LoginPageContainer)
);
