import React, { useCallback, useMemo } from 'react';
import copy from 'copy-to-clipboard';

import { QRCode } from 'js/components';
import ParticipantList from './ParticipantList';
import InviteForm from 'js/components/Forms/InviteForm';
import { Button } from 'reactstrap';
import { Icon } from 'js/components';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { useNotify } from 'js/hooks/useNotify';

function selectMe(e) {
  e.target.setSelectionRange(0, e.target.value.length);
}

const KickoffNav = ({
  assessment,
  participants,
  liveAssessmentData,
  passphrase,
  onSendInvites,
  sendingInvites,
  assessmentInvitesQueryResult,
}) => {
  const runUrl = `${window.location.origin}/assessments/${assessment.id}/run`;

  const pendingInvitees = useMemo(() => {
    return assessmentInvitesQueryResult.data?.filter(
      (i) => i.email && i.participant_id === null
    );
  }, [assessmentInvitesQueryResult]);

  const notify = useNotify();
  const handleCopyLink = useCallback(() => {
    copy(runUrl);

    notify({ type: 'success', message: 'Link copied to clipboard' });
  }, [runUrl, notify]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col col-12 col-md-3 order-md-9">
          <div className="sidebar-item">
            <ParticipantList
              participants={participants}
              hostId={liveAssessmentData.host_id}
            />
            {pendingInvitees && pendingInvitees.length > 0 && (
              <div className="participant-list my-4">
                <h4>Pending invites</h4>
                {pendingInvitees.map((invitee) => (
                  <div key={invitee.email}>{invitee.email}</div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="col col-12 col-md-9 order-md-1">
          <div className="row page">
            <div className="col col-sm-12 col-md-6 col-xs-12">
              <div>
                <label>Your passphrase is:</label>
                <span className="passphrase">{passphrase}</span>
              </div>

              <div>
                <label className="share-label">
                  Or share this url:
                  <span className="share-links">
                    {/* <MailTo subject="Join me for a team health check!" body={mailBody}>
                      <Icon icon="envelope" size="lg" />
                    </MailTo> */}
                  </span>
                </label>
                <span className="url join-link">
                  <input
                    type="text"
                    readOnly
                    onFocus={selectMe}
                    value={runUrl}
                    className="form-control"
                  />
                  <Button
                    className="btn-inline"
                    color="secondary"
                    onClick={handleCopyLink}
                  >
                    <Icon icon={faCopy} fixedWidth />
                  </Button>
                </span>
              </div>
              <div className="mt-4">
                <label className="share-label">Or send invites:</label>
                <InviteForm
                  heading=""
                  subHeading=""
                  onSendInvites={onSendInvites}
                  loading={sendingInvites}
                  clearAfterSend
                />
              </div>
            </div>
            <div className="col col-sm-12 col-md-6 col-xs-12">
              <div className="qr-code-border">
                <div>
                  <QRCode value={runUrl} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KickoffNav;
