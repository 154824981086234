import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { titleCase } from 'js/utils/string';
import { getSummaryMetrics } from 'js/utils/question-set';

import { Route, Switch } from 'react-router-dom';

import { AssessmentReview } from './Review';
import SurveyAssessmentReviewDetailContainer from '../containers/SurveyAssessmentReviewDetailContainer';
import SurveyAssessmentQuestionDetailContainer from '../containers/SurveyAssessmentQuestionDetailContainer';

const getCategoryLookups = (assessments, supplementalCategories) => {
  // NOTE: we could do this without the supplemental categories, but we'd have to do more calculations
  const getCategories = _.property('config.categories');
  const configCategories = assessments
    .map((a) => {
      return getCategories(a);
    })
    .filter((x) => _.isObject(x))
    .reduce((acc, categories) => {
      return _.mergeWith(acc, categories, (obj, src) =>
        obj !== undefined ? obj : src
      );
    }, {});

  const allCategories = _.mergeWith(
    configCategories,
    supplementalCategories,
    (obj, src, key) =>
      obj !== undefined ? obj : { display_name: titleCase(key) }
  );

  const categoryMap = new Map(
    _.chain(allCategories)
      .map((value, key) => [key, value])
      .sortBy(['[1].ordinal'])
      .value()
  );

  let ci = 0;
  categoryMap.forEach((value) => {
    value.palette = `palette-cat-${++ci}`;
  });

  return categoryMap;
};

const SurveyAssessmentReviewComponent = ({
  match,
  assessments,
  ...myProps
}) => {
  const orderedAssessments = _.orderBy(assessments, ['inserted_at'], ['desc']);
  const name = orderedAssessments.length ? orderedAssessments[0].name : '';

  const dataMap = getSummaryMetrics(orderedAssessments);
  const categoryLookups = getCategoryLookups(assessments, dataMap.categories);
  const categoryColorMap = {};
  categoryLookups.forEach((value, key) => {
    categoryColorMap[key] = value.palette;
  });

  const props = {
    ...myProps,
    assessments: _.orderBy(assessments, ['inserted_at'], ['desc']),
    name,
    dataMap,
    categoryLookups,
    categoryColorMap,
  };

  return (
    <Switch>
      <Route
        path={`${match.path}/detail/:assessmentId/questions/:questionId`}
        render={(routeProps) => (
          <SurveyAssessmentQuestionDetailContainer {...props} {...routeProps} />
        )}
      />
      <Route
        path={`${match.path}/detail/:assessmentId`}
        render={(routeProps) => (
          <SurveyAssessmentReviewDetailContainer {...props} {...routeProps} />
        )}
      />
      <Route
        path={`${match.path}`}
        render={(routeProps) => <AssessmentReview {...props} {...routeProps} />}
      />
    </Switch>
  );
};

SurveyAssessmentReviewComponent.propTypes = {
  onRespond: PropTypes.func,
  onClearSession: PropTypes.func,
};

SurveyAssessmentReviewComponent.defaultProps = {
  onRespond: () => {},
  onClearSession: () => {},
};

export default SurveyAssessmentReviewComponent;
