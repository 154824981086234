import React from 'react';
import { connect } from 'react-redux';

import {
  GuestAuthorization,
  FullUserAuthorization
} from 'js/components/Security';
import { Redirect } from 'react-router-dom';

import GuestTeamsRouteComponent from '../components/GuestTeamsRouteComponent';

const GuestTeams = GuestAuthorization(GuestTeamsRouteComponent);
const FullUserRedirect = FullUserAuthorization(Redirect);

class GuestTeamsRouteContainer extends React.Component {
  render() {
    const { me } = this.props;

    return (
      <>
        <GuestTeams user={me} />
        <FullUserRedirect to="/dashboard" user={me} />
      </>
    );
  }
}

export default connect(
  ({ identity: { me } }) => ({
    me
  }),
  {}
)(GuestTeamsRouteContainer);
