import React from 'react';
import _ from 'lodash';

const Authorization = allowedRoles => WrappedComponent => {
  return class WithAuthorization extends React.Component {
    isAllowed = () => {
      const { user, roles } = this.props;

      if (!user || !user.id) {
        return false;
      }

      let allow = _.intersection(allowedRoles, roles).length > 0;

      // guest and full-user are determined differently at the moment
      allow =
        allow ||
        (!allow && allowedRoles.includes('guest') && user.is_guest === true);
      allow =
        allow ||
        (!allow &&
          allowedRoles.includes('full-user') &&
          user.is_guest === false);

      return allow;
    };

    render() {
      return this.isAllowed() ? <WrappedComponent {...this.props} /> : null;
    }
  };
};

Authorization.defaultProps = {
  roles: []
};

export default Authorization;
