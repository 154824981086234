import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import HarveyBall from './HarveyBall';
import classNames from 'classnames';

const Allocation = ({
  r: radius = 16,
  display = 'edit',
  id,
  name,
  value,
  onChange,
  readonly = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return readonly ? (
    <div className="allocation">
      <HarveyBall r={radius} fraction={value} />
    </div>
  ) : (
    <Dropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      className={classNames({
        allocation: true,
        'allocation-state-view': display === 'view',
      })}
    >
      <DropdownToggle caret>
        <HarveyBall r={radius} fraction={value} />
      </DropdownToggle>
      <DropdownMenu right>
        {[
          { v: 0.25, t: '25%' },
          { v: 0.5, t: '50%' },
          { v: 0.75, t: '75%' },
          { v: 1, t: '100%' },
          { v: null, t: 'Unspecified' },
        ].map(({ v, t }) => (
          <DropdownItem
            className="allocation-option"
            key={t}
            onClick={(e) => {
              onChange({ ...e, target: { value: v, name, id } });
            }}
          >
            <div>
              <HarveyBall r={8} fraction={v} />
            </div>
            <span>{t}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>

      <Dropdown />
    </Dropdown>
  );
};

export default Allocation;
