import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import OrgsRouteComponent from '../components/OrgsComponent';
import { Authorization } from 'js/components';
import {
  createOrg,
  deleteOrg,
  list as listOrganizations,
} from 'js/actions/org-actions';

import { selectWorkspaceOrgsQuery } from 'js/store/reducers/queries';
import { notify } from 'js/actions/alert-actions';
import { noop } from 'lodash';
import { allowOrgs } from 'js/utils/entitlement-utils';

const OrgsComponent = Authorization(['full-user'])(OrgsRouteComponent);
const GuestRedirect = Authorization(['guest'])(Redirect);

const OrgsContainer = ({
  match: {
    params: { workspaceId },
  },
  me,
  listOrganizations,
  organizationsQueryResult,
  createOrg,
  notify,
  history,
  deleteOrg,
  activeAccount,
}) => {
  useEffect(() => {
    if (allowOrgs(activeAccount)) {
      listOrganizations(workspaceId).catch(noop);
    }
  }, [listOrganizations, workspaceId, activeAccount]);

  const handleCreateOrg = useCallback(
    (org) => {
      return (async () => {
        try {
          const created = await createOrg(org);
          history.push(`/w/${workspaceId}/orgs/${created.id}/manage`);
        } catch (e) {
          console.error('failed to create the org:', e);
          notify({
            type: 'danger',
            message: `Uh oh! There was a problem creating the organization.`,
          });
          throw e;
        }
      })();
    },
    [createOrg, notify, history, workspaceId]
  );

  const handleDeleteOrg = useCallback(
    (org) => {
      return (async () => {
        try {
          await deleteOrg(org);
          notify({
            type: 'success',
            message: 'The organization has been deleted!',
          });
          listOrganizations(workspaceId).catch(noop);
        } catch (e) {
          console.error('failed to delete the org:', e);
          notify({
            type: 'danger',
            message: `Uh oh! There was a problem deleting the organization.`,
          });
        }
      })();
    },
    [notify, listOrganizations, deleteOrg, workspaceId]
  );

  return (
    <>
      <OrgsComponent
        user={me}
        workspaceId={workspaceId}
        organizationsQueryResult={organizationsQueryResult}
        onCreateOrg={handleCreateOrg}
        onDeleteOrg={handleDeleteOrg}
        activeAccount={activeAccount}
      />
      <GuestRedirect user={me} to="/dashboard" />
    </>
  );
};

export default connect(
  (state, params) => {
    const workspaceId = params.match?.params?.workspaceId;
    return {
      me: state.identity.me,
      activeAccount: state.activeAccount,
      organizationsQueryResult: selectWorkspaceOrgsQuery(state, workspaceId),
    };
  },
  {
    listOrganizations,
    createOrg,
    notify,
    deleteOrg,
  }
)(OrgsContainer);
