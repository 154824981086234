import { Button } from 'reactstrap';
import styles from './Product.module.scss';
import classnames from 'classnames';

function Product({
  product,
  isCurrent,
  children,
  buttonLabel,
  buttonColor = 'secondary',
  onBuy,
  disabled,
  cycle,
  lightMode = false,
}) {
  const price = product.prices.find((p) => p.interval === cycle);
  return (
    <div
      className={classnames([styles.root, lightMode ? styles.lightMode : ''])}
    >
      {isCurrent && <div className={styles.currentBadge}>Current</div>}
      <div>
        <h2>{product.name}</h2>
        {price ? (
          <div className={styles.price}>
            <span className={styles.amount}>
              ${price.unit_amount / 100} USD
            </span>
            {price.unit_amount !== 0 && (
              <span className={styles.term}>per team per {price.interval}</span>
            )}
          </div>
        ) : (
          <div className={styles.price}>Pricing not available at this time</div>
        )}
        <div className={styles.description}>{children}</div>
      </div>
      <Button
        onClick={() => onBuy(product, price)}
        disabled={disabled || isCurrent || !price}
        color={buttonColor}
      >
        {buttonLabel}
      </Button>
    </div>
  );
}

export default Product;
