import React, { useCallback, useMemo, useRef } from 'react';
import { flatMap, map, memoize, noop } from 'lodash';

import { Grid } from '@visx/grid';
import { AxisBottom } from '../AxisBottom';
import { YearUnderlay } from '../YearUnderlay';
import { scaleLinear } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { curveMonotoneX as curve } from '@visx/curve';

import LineWithRings from './LineWithRings';

import styles from './AssessmentTrendsChart.module.scss';
import Ring from './Ring';

function getHighlightedData(dataPoints, assessments, filter) {
  if (filter?.type === 'question' || filter.subtype === 'question') {
    const { questionSetKey, questionKey } = filter.criteria ?? {};
    const question = dataPoints?.[questionSetKey]?.[questionKey];

    return question
      ? {
          type: 'question',
          questionSetKey,
          questionKey,
          data: question.data,
          questionText: question.text,
        }
      : null;
  } else if (filter?.type === 'assessment') {
    const { assessmentId } = filter.criteria ?? {};
    const assessment = assessments?.[assessmentId];

    return {
      type: 'assessment',
      data: flatMap(assessment?.questionSets, (qs, questionSetKey) =>
        map(qs.questions, ({ normalizedScore, text }, questionKey) => ({
          questionSetKey,
          questionKey,
          data: { insertedAt: assessment.insertedAt, normalizedScore },
          questionText: text,
        }))
      ),
    };
  } else {
    return null;
  }
}

const AssessmentTrendsChart = ({
  xScale,
  dataPoints,
  paletteMap,
  width,
  height = 250,
  generateKey,
  filter = {},
  margins = { top: 10, right: 10, bottom: 40, left: 10 },
  assessments,
  onHoverTrend = noop,
  onUnhoverTrend = noop,
  onClickTrend = noop,
}) => {
  const chartWidth = width - margins.left - margins.right;
  const chartHeight = height - margins.top - margins.bottom;
  const yScale = useMemo(
    () => scaleLinear({ domain: [-0.25, 1.25], range: [chartHeight, 0] }),
    [chartHeight]
  );

  const resetTrendTimeout = useRef(null);
  const memoizedHandleTrendOver = useMemo(
    () =>
      memoize(
        (questionSetKey, questionKey) => () => {
          clearTimeout(resetTrendTimeout.current);
          resetTrendTimeout.current = null;
          onHoverTrend(questionSetKey, questionKey);
        },
        (questionSetKey, questionKey) =>
          `${questionSetKey}😎${questionKey}-hover`
      ),
    [onHoverTrend]
  );

  const memoizedHandleTrendClick = useMemo(
    () =>
      memoize(
        (questionSetKey, questionKey) => () => {
          onClickTrend(questionSetKey, questionKey);
        },
        (questionSetKey, questionKey) =>
          `${questionSetKey}😎${questionKey}-click`
      ),
    [onClickTrend]
  );

  const handleTrendOut = useCallback(() => {
    clearTimeout(resetTrendTimeout.current);
    resetTrendTimeout.current = setTimeout(() => onUnhoverTrend(), 50);
  }, [onUnhoverTrend]);

  const plotData = map(dataPoints, (qs, qsKey) => ({
    key: qsKey,
    questions: map(qs, (q, qKey) => ({
      key: qKey,
      data: q.data,
      text: q.text,
    })),
  }));

  const x = (d) => xScale(d.insertedAt);
  const y = (d) => yScale(d.normalizedScore);

  const highlightedData =
    filter === null
      ? null
      : getHighlightedData(dataPoints, assessments, filter);

  return chartWidth <= 0 ? null : (
    <svg
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className={styles['assessment-trends-chart']}
    >
      <g transform={`translate(${margins.left}, ${margins.top})`}>
        <YearUnderlay height={chartHeight} xScale={xScale} />
        <Grid
          className="chart-grid chart-neutral-line"
          height={chartHeight}
          width={chartWidth}
          rowTickValues={[0, 0.5, 1]}
          xScale={xScale}
          yScale={yScale}
          stroke="#526067"
          numTicksColumns={5}
        />
        <AxisBottom
          chartHeight={chartHeight}
          chartWidth={chartWidth}
          xScale={xScale}
        />
        <g className="muted-element-dark">
          {plotData.flatMap(({ key: qsKey, questions }) =>
            questions
              .map(({ key: qKey, data, text }) => {
                const key = generateKey(qsKey, qKey);

                // return qKey === filter?.criteria?.questionKey &&
                //   qsKey === filter?.criteria?.questionSetKey ? null : (
                return (
                  <g
                    key={key}
                    className={`line ${paletteMap[key]?.className} palette-muted`}
                  >
                    <LinePath
                      className="palette-stroke"
                      curve={curve}
                      x={x}
                      y={y}
                      data={data}
                      onMouseOver={memoizedHandleTrendOver(qsKey, qKey, text)}
                      onMouseOut={handleTrendOut}
                    />
                    <g>
                      {data.map((d, i) => (
                        <circle
                          key={i}
                          cx={x(d)}
                          cy={y(d)}
                          r="2"
                          className="palette-fill"
                          stroke="none"
                        />
                      ))}
                    </g>
                  </g>
                );
              })
              .filter((x) => x)
          )}
        </g>
        <rect
          width={chartWidth}
          height={chartHeight}
          className="bounding-box"
        />

        {highlightedData?.type === 'question' ? (
          <g
            className={(() => {
              const a =
                paletteMap[
                  generateKey(
                    highlightedData.questionSetKey,
                    highlightedData.questionKey
                  )
                ]?.className;
              return a;
            })()}
            onClick={memoizedHandleTrendClick(
              highlightedData.questionSetKey,
              highlightedData.questionKey
            )}
            onMouseOver={memoizedHandleTrendOver(
              highlightedData.questionSetKey,
              highlightedData.questionKey,
              highlightedData.questionText
            )}
            onMouseOut={handleTrendOut}
          >
            <LineWithRings
              curve={curve}
              x={x}
              y={y}
              data={highlightedData.data}
              className="line"
              fill="none"
            />
          </g>
        ) : highlightedData?.type === 'assessment' ? (
          <g>
            {highlightedData.data.map(
              ({ questionSetKey, questionKey, data, questionText }) => {
                const key = generateKey(questionSetKey, questionKey);
                const className = paletteMap[key]?.className;

                return (
                  <g key={key} className={className}>
                    <Ring cx={x(data)} cy={y(data)} />
                  </g>
                );
              }
            )}
          </g>
        ) : null}
      </g>
    </svg>
  );
};

export default AssessmentTrendsChart;
