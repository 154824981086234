import React from 'react';
import _ from 'lodash';

const CategoryPills = ({ categories, categoryLookups }) => {
  return !categories || !categoryLookups ? null : (
    <>
      {_.map(categories, category => {
        const categoryInfo = categoryLookups.get(category);

        return (
          <span
            key={category}
            className={`category-pill ${categoryInfo.palette}`}
          >
            {categoryInfo.display_name}
          </span>
        );
      })}
    </>
  );
};

export default CategoryPills;
