import React from 'react';
import { connect } from 'react-redux';
import InviteAccountUsersComponent from '../components/InviteAccountUsersComponent';
import { useCallback, useState } from 'react';
import { invite as inviteUser } from 'js/actions/account/user-actions';
import { notify } from 'js/actions/alert-actions';
import { withRouter } from 'react-router-dom';

function InviteAccountUsers({ inviteUser, notify, account, history }) {
  const { push } = history;
  const [serverErrors, setServerErrors] = useState({});

  const accountId = account?.id;

  const onSubmit = useCallback(
    async ({ email }) => {
      try {
        await inviteUser(accountId, email);
        notify({
          type: 'success',
          message: 'The invitation is in the mail!',
        });
        push(`/a/${accountId}/users`);
      } catch (e) {
        const emailErrors = e.response?.body?.errors?.email;
        if (emailErrors && emailErrors.includes('already exists')) {
          setServerErrors({
            email: {
              message: 'Looks like that person has already been invited!',
              submittedValue: email,
            },
          });
        }
        notify({
          type: 'danger',
          message: 'Sorry, there was a problem inviting the user',
        });
      }
    },
    [inviteUser, notify, accountId, push, setServerErrors]
  );

  return (
    <InviteAccountUsersComponent
      onSubmit={onSubmit}
      serverErrors={serverErrors}
      account={account}
    />
  );
}

export default withRouter(
  connect(
    (
      store,
      { match: { params: { workspaceId } = { workspaceId: null } } }
    ) => ({
      currentWorkspaceId: workspaceId,
    }),
    {
      inviteUser,
      notify,
    }
  )(InviteAccountUsers)
);
