import React from 'react';
import _ from 'lodash';

import { Link } from 'react-router-dom';
import { Loading, Icon } from 'js/components';

import ResendForm from './ResendForm';

const renderErrorComponent = (title, message, icon) => {
  return (
    <div className="page-result">
      <div className="result-icon">
        <Icon icon={icon} fixedWidth />
      </div>
      <div className="result-body">
        <h2>{title}</h2>
        {message}
      </div>
    </div>
  );
};

const renderError = (error, onResend, resendState) => {
  if (error) {
    // error.message = 'token expired';
    switch (error.toLowerCase()) {
      case 'token not found':
        return renderErrorComponent(
          <>Oops...</>,
          <div>
            <p>
              We looked everywhere but couldn't an account to verify with that
              information.
            </p>
            <ResendForm onSubmit={onResend} state={resendState} />
          </div>,
          ['far', 'comment-alt-exclamation']
        );

      case 'token expired':
        return renderErrorComponent(
          <>Missed it by that much...</>,
          <div>
            <p>
              Your verification link expired (they're only good for 24 hours),
              but that's okay. We can send you another one if you'd like.
            </p>
            <ResendForm onSubmit={onResend} state={resendState} />
          </div>,
          ['far', 'clock']
        );

      default:
    }
  }

  return renderErrorComponent(
    <>Uh oh!</>,
    <>
      There's something happening here. What it is ain't exactly clear, but
      we're trying to figure it out!
    </>,
    ['far', 'comment-alt-exclamation']
  );
};

const renderBody = (inProgress, verified, error, me, onResend, resendState) => {
  if (inProgress) {
    return <Loading />;
  }
  if (verified) {
    return (
      <div className="page-result page-success">
        <div className="result-icon">
          <Icon icon={['far', 'clipboard-check']} fixedWidth />
        </div>
        <div className="result-body">
          <h2>Everything checks out</h2>
          <div>
            <p>Thanks for verifying your account. You're good to go!</p>
            {me ? (
              <>
                <Link to="/dashboard" className="btn btn-lg">
                  <Icon
                    icon={['far', 'truck-monster']}
                    className="btn-icon-left"
                  />{' '}
                  Let's get started
                </Link>
              </>
            ) : (
              <>
                <Link to="/pages/login" className="btn btn-lg full-width-sm">
                  <Icon icon="sign-in" className="btn-icon-left" /> Sign in now
                </Link>
                <p>
                  <span className="tiny">
                    &hellip;or take a break, grab a drink and do it later. We'll
                    be here.
                  </span>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  } else if (error) {
    return renderError(error, onResend, resendState);
  }

  return <Loading />;
};

const EmailVerificationRouteComponent = ({
  me,
  verified,
  error,
  inProgress,
  onResend,
  resendState
}) => {
  return (
    <div id="verify-account-page" className="featured-form">
      <div className="full-page-content">
        <div className="background-page">
          <div className="container">
            <div className="content">
              {renderBody(
                inProgress,
                verified,
                error,
                me,
                onResend,
                resendState
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
};

EmailVerificationRouteComponent.defaultProps = {
  onResend: _.noop,
  resendState: {}
};

export default EmailVerificationRouteComponent;
