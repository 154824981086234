import React, { useCallback, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import AsyncButton from 'js/components/Forms/AsyncButton';
import { Icon } from 'js/components';
import MultiSelectCheckBoxes from 'js/components/Forms/MultiSelectCheckBoxes';
import { isActive } from 'js/utils/team-utils';

function AddTeamsFormDialog({
  parentNode,
  teams,
  isOpen,
  onCancel,
  onConfirm,
  intermediateRoot,
}) {
  const [selectedTeamIds, setSelectedTeamIds] = useState([]);

  const handleConfirm = useCallback(() => {
    const res = onConfirm(selectedTeamIds);
    if (res.then) {
      res.then(() => {
        setSelectedTeamIds([]);
      });
    } else {
      setSelectedTeamIds([]);
    }
    return res;
  }, [onConfirm, setSelectedTeamIds, selectedTeamIds]);

  const sortedTeams = useMemo(() => {
    const currentTeamIds = (parentNode?.children ?? [])
      .filter((child) => child.type === 'team')
      .map((team) => team.id);

    return (teams ?? [])
      .filter((t) => !currentTeamIds.includes(t.id))
      .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
  }, [teams, parentNode]);

  const handleSelectionChange = useCallback(
    (selected) => {
      setSelectedTeamIds(selected);
    },
    [setSelectedTeamIds]
  );

  const handleCancel = useCallback(() => {
    setSelectedTeamIds([]);
    onCancel();
  }, [setSelectedTeamIds, onCancel]);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <div className="primary">Add Teams to {parentNode?.name}</div>
      </ModalHeader>
      <ModalBody>
        <div>
          {intermediateRoot && (
            <Alert color="info">
              <div className="org-change-warning">
                <Icon icon="exclamation-circle" />
                <span>
                  This change will affect the organization{' '}
                  <strong>{intermediateRoot.name}</strong> and any organizations
                  that refer to it.
                </span>
              </div>
            </Alert>
          )}
          <MultiSelectCheckBoxes
            label="Select one or more teams to add"
            value={selectedTeamIds}
            options={sortedTeams.map((t) => ({
              id: t.id,
              name: `${t.name}${isActive(t) ? '' : ' (inactive)'}`,
            }))}
            maxHeight="300px"
            onChange={handleSelectionChange}
          />
          <div></div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancel}>Cancel</Button>
        <AsyncButton
          color="primary"
          onClick={handleConfirm}
          data-test="confirm-add-btn"
          disabled={selectedTeamIds.length === 0}
        >
          {selectedTeamIds.length === 1 ? 'Add team' : 'Add teams'}
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
}

export default AddTeamsFormDialog;
