import React, { useEffect, useState, useCallback } from 'react';
import { request } from 'js/api/axios';
import StripeBillingComponent from '../components/StripeBillingComponent';
import InvoiceBillingComponent from '../components/InvoiceBillingComponent';
import { createBillingPortalSession } from 'js/actions/account-actions';
import { notify } from 'js/actions/alert-actions';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import FreeBillingComponent from 'js/routes/Accounts/components/FreeBillingComponent';
import SkeletonBillingComponent from 'js/routes/Accounts/components/SkeletonBillingComponent';

const AccountsSettingsBilling = ({
  account,
  token,
  createBillingPortalSession,
  notify,
}) => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [productList, setProductList] = useState(null);
  const { accountId } = useParams();

  const handleCreateBillingPortalSession = useCallback(async () => {
    try {
      const { session_url } = await createBillingPortalSession(accountId);
      window.location.href = session_url;
    } catch (e) {
      console.error('failed to create a stripe billing portal session:', e);
      notify({
        type: 'danger',
        message:
          "Uh oh...Looks like we couldn't connect you to your Stripe billing page right now. Please try again in a little bit.",
      });
    }
  }, [createBillingPortalSession, notify, accountId]);

  useEffect(() => {
    (async () => {
      try {
        const resp = await request.get(`/api/products`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProductList(resp.data.data);
        let acctProduct;
        resp.data.data.forEach((p) => {
          if (p.id === account.product_id) {
            acctProduct = p;
          }
        });
        if (acctProduct) {
          const product = {};
          product.name = acctProduct.name;
          product.key = acctProduct.key;
          const priceObject = acctProduct.prices.find(
            (price) => price.id === account.price_id
          );
          if (priceObject) {
            product.price = priceObject.unit_amount / 100;
            product.interval = priceObject.interval;
          }
          setProduct(product);
        }
      } catch (err) {
        notify({
          type: 'danger',
          message: 'An error occurred retrieving price info.',
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [account.product_id, account.price_id, notify, token]);

  function BillingComponent({ account, product }) {
    if (loading) {
      return <SkeletonBillingComponent />;
    }

    if (product?.key === 'free') {
      return (
        <FreeBillingComponent
          products={productList}
          loading={loading}
          account={account}
        />
      );
    }

    if (account?.billing_type === 'invoice') {
      return <InvoiceBillingComponent account={account} product={product} />;
    } else {
      return (
        <StripeBillingComponent
          account={account}
          product={product}
          onCreateBillingPortalSession={handleCreateBillingPortalSession}
        />
      );
    }
  }

  return <BillingComponent account={account} product={product} />;
};

export default connect(
  (state, { account }) => {
    return {
      token: state.identity.token,
    };
  },
  { createBillingPortalSession, notify }
)(AccountsSettingsBilling);
