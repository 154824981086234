import React, { useMemo } from 'react';
import { PanelHeader } from 'js/components';
import WorkspaceSummary from './WorkspaceSummary';

import styles from './AccountsDashboardComponent.module.scss';
import AddWorkspace from './AddWorkspace/AddWorkspace';
import WorkspaceSummarySkeleton from './WorkspaceSummarySkeleton';
import { maxWorkspacesPerAccount } from 'js/utils/entitlement-utils';

const AccountsDashboardComponent = ({
  me: { workspaces },
  isLoading = false,
  accountSummary,
  onCreateWorkspace,
  createWorkspaceSubmissionErrors,
  createWorkspaceSubmissionValues,
  onAddSelfToWorkspace,
}) => {
  const workspaceMap = useMemo(
    () =>
      workspaces.reduce(
        (acc, w) => ({
          ...acc,
          [w.id]: true,
        }),
        {}
      ),
    [workspaces]
  );

  const maxWorkspaces = maxWorkspacesPerAccount(accountSummary?.account);
  const canAddWorkspaces = maxWorkspaces > accountSummary?.workspaces?.length;
  const denormalizedAccountSummary = useMemo(() => {
    if (!accountSummary) {
      return accountSummary;
    }

    return {
      ...accountSummary,
      workspaces: accountSummary.workspaces.map((w) => ({
        ...w,
        account: accountSummary.account,
      })),
    };
  }, [accountSummary]);
  return (
    <div>
      <PanelHeader size="flex" />
      <div className="content">
        <div className={styles.workspaces}>
          {!accountSummary && isLoading ? (
            <WorkspaceSummarySkeleton />
          ) : (
            denormalizedAccountSummary?.workspaces?.map((w) => (
              <WorkspaceSummary
                key={w.id}
                workspace={w}
                isMember={!!workspaceMap[w.id]}
                onAddSelf={onAddSelfToWorkspace}
              />
            ))
          )}
          <div>
            {canAddWorkspaces && (
              <AddWorkspace
                onAddWorkspace={onCreateWorkspace}
                createWorkspaceSubmissionErrors={
                  createWorkspaceSubmissionErrors
                }
                createWorkspaceSubmissionValues={
                  createWorkspaceSubmissionValues
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountsDashboardComponent;
