import React from 'react';
import { noop } from 'lodash';

import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { PanelHeader, Loading } from 'js/components';
import EditUserForm from 'js/components/Forms/EditUserForm';

const AccountInfoRouteComponent = ({
  me,
  pendingEmail,
  onUpdateUser,
  onResendEmailVerification,
  onCancelEmailChange,
  updateUserSubmissionErrors,
  updateUserSubmissionValues
}) => {
  const handleEditUserFormSubmit = values => {
    return onUpdateUser({ id: me.id, ...values });
  };

  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Update your account information</CardTitle>
          </CardHeader>
          <CardBody>
            {!me ? (
              <Loading />
            ) : (
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <EditUserForm
                    user={me}
                    pendingEmail={pendingEmail}
                    onResendEmailVerification={onResendEmailVerification}
                    onCancelEmailChange={onCancelEmailChange}
                    onSubmit={handleEditUserFormSubmit}
                    submissionErrors={updateUserSubmissionErrors}
                    submissionValues={updateUserSubmissionValues}
                  />
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

AccountInfoRouteComponent.defaultProps = {
  onUpdateUser: noop,
  onCancelEmailChange: noop,
  onResendEmailVerification: noop
};

export default AccountInfoRouteComponent;
