import React from 'react';
import classNames from 'classnames';
import { flatten, map, noop } from 'lodash';
import { CategoryPills } from './CategoryPills';

import styles from './CategoryLayout.module.scss';

export const CategoryLayout = ({
  categoryLookups,
  questionSets,
  categories,
  questionSetClassName = null,
  fullNameBar = true,
  stickyCategories = false,
  renderQuestionSet = noop,
  renderQuestionSetName = (questionSet) => questionSet.displayName,
}) => {
  const shouldShowQuestionSet =
    questionSets && Object.keys(questionSets).length > 1;

  const categoryKeys = [...new Set(map(questionSets, (qs) => qs.category))];

  const shouldShowCategoryMarkers = categoryLookups?.size > 1;

  return (
    <div>
      {shouldShowCategoryMarkers && (
        <div
          className={classNames(styles['category-pills'], {
            [styles.sticky]: stickyCategories,
          })}
        >
          <CategoryPills
            categories={categoryKeys}
            categoryLookups={categoryLookups}
          />
        </div>
      )}
      <ul className={styles['category-layout']}>
        {map(categories, (cat) => {
          return flatten(
            map(cat, (_val, qsKey) => {
              const questionSet = questionSets[qsKey];

              if (questionSet === undefined) {
                return null;
              }

              const palette = !shouldShowQuestionSet
                ? ''
                : categoryLookups?.get(questionSet.category)?.palette;

              return (
                <li
                  key={qsKey}
                  className={`${classNames(
                    styles['question-set'],
                    questionSetClassName
                  )}`}
                >
                  {shouldShowQuestionSet && (
                    <div
                      className={
                        fullNameBar ? null : styles['inline-name-wrapper']
                      }
                    >
                      <span className={styles['question-set-name']}>
                        {renderQuestionSetName(questionSet)}
                      </span>
                      <div className={`${styles['name-bar']} ${palette}`}></div>
                    </div>
                  )}
                  <div>
                    {renderQuestionSet(questionSet, `${styles.bar} ${palette}`)}
                  </div>
                </li>
              );
            })
          );
        })}
      </ul>
    </div>
  );
};

export default CategoryLayout;
