import React from 'react';
import moment from 'moment';

import { Link } from 'react-router-dom';

const ListItem = ({ assessment }) => {
  return (
    <Link to={`/assessments/${assessment.id}/run`}>
      {moment(assessment.inserted_at).format('MMMM Do YYYY, h:mm:ss a')}
    </Link>
  );
};

export default ListItem;
