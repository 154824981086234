export function isRecentAndActive(assessment) {
  const insertedAt =
    typeof assessment.inserted_at instanceof Date
      ? assessment.inserted_at
      : new Date(assessment.inserted_at);
  return (
    assessment.active &&
    !assessment.is_locked &&
    insertedAt > new Date().setDate(new Date().getDate() - 14)
  );
}

export function getResponseCount(assessment, participantId) {
  if (!assessment) return 0;

  return assessment.question_sets.reduce((totalCount, questionSet) => {
    return (
      totalCount +
      questionSet.questions.reduce((count, question) => {
        const answered = question.responses.some(
          (response) => response?.participant_id === participantId
        );
        return answered ? count + 1 : count;
      }, 0)
    );
  }, 0);
}

export function getQuestionCount(assessment) {
  if (!assessment) return 0;

  return assessment.question_sets.reduce(
    (count, questionSet) => count + questionSet.questions.length,
    0
  );
}

export function getResponseCounts(assessment) {
  return !assessment
    ? {}
    : assessment.question_sets.reduce(
        (qsAcc, qs) =>
          qs.questions.reduce(
            (qAcc, q) =>
              q.responses.reduce((rAcc, r) => {
                rAcc[r.participant_id] = (rAcc[r.participant_id] || 0) + 1;
                return rAcc;
              }, qAcc),
            qsAcc
          ),
        {}
      );
}

export const questionSetRubric = {
  collaboration: 0.53,
  'continuous-improvement': 0.6,
  'generative-culture': 0.47,
  leadership: 0.69,
  learning: 0.42,
  'make-work-meaningful': 0.27,
  'team-independence': 0.45,
  'team-structure': 0.56,
  'hiring-onboarding': 0.76,
  accountability: 0.8,
  'data-driven-forecasting': 0.12,
  budgeting: 0.1,
  'goal-setting-and-evaluation': 0.36,
  'plan-creation-and-management': 0.69,
  predictability: 0.62,
  'transparency-and-visibility': 0.91,
  'change-management': 0.51,
  'flow of work': 0.53,
  'customer feedback': 0.7,
  experimentation: 0.31,
  focus: 0.64,
  prioritization: 0.77,
  'small batches': 0.53,
  'strategic vision': 0.33,
  observability: 0.75,
  'continuous-integration': 0.46,
  deployment: 0.72,
  'empowered-teams': 0.28,
  'loosely-coupled architecture': 0.48,
  security: 0.68,
  'test automation': 0.5,
  'version-control': 0.98,
  provisioning: 0.62,
};

export function questionSetScoreDistance(questionSetKey, score) {
  return (
    Math.pow(1.2 * Math.max(0, Math.log(100 * (1 - score))), 2) +
    Math.pow(Math.max(0, Math.log(100 * questionSetRubric[questionSetKey])), 2)
  );
}
