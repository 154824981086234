import React, { useMemo } from 'react';
import { get } from 'lodash';

import { getQuestionCount, getResponseCounts } from 'js/utils/assessment-utils';

import { ParentSize } from '@visx/responsive';

import { Icon } from 'js/components';
import CompletionRateChart from './CompletionRateChart';

const ParticipationStats = ({ invites, assessment }) => {
  const responseData = useMemo(() => {
    if (!assessment) {
      return { totals: {}, counts: [], questionCount: null };
    }

    const participantResponseCounts = getResponseCounts(assessment);
    const questionCount = getQuestionCount(assessment);

    const totals = assessment.participants.reduce(
      (acc, p, ...args) => {
        switch (true) {
          case participantResponseCounts[p.id] === undefined ||
            participantResponseCounts[p.id] === 0:
            acc.notStarted++;
            break;
          case participantResponseCounts[p.id] >= questionCount:
            acc.completed++;
            break;
          default:
            acc.partial++;
        }

        return acc;
      },
      { notStarted: 0, partial: 0, completed: 0 }
    );

    const counts = assessment.participants.map((p) => ({
      responses: participantResponseCounts[p.id] || 0,
    }));

    return { totals, counts, questionCount };
  }, [assessment]);

  return (
    <div className="participation-stats">
      <label>Participants</label>
      <div className="stat-summary">
        <div className="invites" title="Invited">
          <Icon icon="envelope" fixedWidth className="label-icon" />
          {get(invites, 'data.length', ' ')}
        </div>
        {/* <td>
                    <IconStack fixedWidth className="mr-1">
                      <Icon icon="user" />
                      <Icon
                        icon="envelope"
                        transform="shrink-9 right-10 up-0.5"
                      />
                    </IconStack>{' '}
                    43
                  </td> */}
        <div className="participants" title="Participants">
          <Icon icon="user" fixedWidth className="label-icon" />
          {get(assessment, 'participants.length', ' ')}
        </div>
        <div className="not-started" title="Not started">
          <Icon icon={['far', 'circle']} fixedWidth className="label-icon" />
          {responseData.totals.notStarted}
        </div>
        <div className="in-progress" title="In progress">
          <Icon icon="dot-circle" fixedWidth className="label-icon" />
          {responseData.totals.partial}
        </div>
        <div className="completed" title="Completed">
          <Icon icon="check-circle" fixedWidth className="label-icon" />
          {responseData.totals.completed}
        </div>
      </div>

      <div>
        {responseData.questionCount && (
          <ParentSize>
            {(parent) => (
              <CompletionRateChart
                participants={responseData.counts}
                questionCount={responseData.questionCount}
                width={parent.width}
                height={45}
              />
            )}
          </ParentSize>
        )}
      </div>
    </div>
  );
};

export default ParticipationStats;
