import React from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { scaleLinear } from '@visx/scale';
import LineWithRings from './QuestionResults/LineWithRings';

const LineWithRingsLegend = ({
  fields,
  pinnedCohort,
  markerWidth = 26,
  markerHeight = 15,
  onPeekCohort = noop,
  onClearPeekedCohort = noop,
  onPinCohort = noop,
  onClearPinnedCohort = noop,
}) => {
  const handleLegendItemMouseEnter = (key) => {
    onPeekCohort(key);
  };

  const handleLegendItemMouseLeave = onClearPeekedCohort;

  const handleLegendItemClick = (key) => {
    if (pinnedCohort === key) {
      onClearPinnedCohort();
    } else {
      onPinCohort(key);
    }
  };

  const scaleX = scaleLinear({
    domain: [0, 1],
    range: [0, markerWidth],
  });
  const x = (d) => {
    switch (d) {
      case 'start':
        return 0;

      case 'end':
        return markerWidth;

      default:
    }

    return scaleX(d.value);
  };

  const y = () => markerHeight / 2;

  return (
    <div className="question-results-legend">
      <label>Cohorts</label>
      <div className="legend-items">
        {fields.map((f) => (
          <span
            className={classNames({
              'legend-item': true,
              pinned: f.key === pinnedCohort,
            })}
            key={f.name}
            aria-pressed={f.key === pinnedCohort}
            onMouseEnter={() => handleLegendItemMouseEnter(f.key)}
            onMouseLeave={handleLegendItemMouseLeave}
            onClick={() => handleLegendItemClick(f.key)}
          >
            <div className="marker">
              <svg
                viewBox={`0 0 ${markerWidth} ${markerHeight}`}
                width={markerWidth}
                height={markerHeight}
              >
                <LineWithRings
                  data={['start', { value: 0.5, answer: { id: 1 } }, 'end']}
                  x={x}
                  y={y}
                  className={`palette-chart-${f.paletteIndex}`}
                />
              </svg>
            </div>
            <div className="name">{f.name}</div>
          </span>
        ))}
      </div>
    </div>
  );
};

export default LineWithRingsLegend;
