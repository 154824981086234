import { request } from 'js/api/axios';
import { callApi, SCHEMAS } from '../api';
import { extractApiKeys, spreadApiKeys } from '../utils';
import keymirror from '../utils/keymirror';

export const types = keymirror({
  ...spreadApiKeys('LOAD_WORKSPACES'),
  ...spreadApiKeys('LOAD_WORKSPACE'),
  ...spreadApiKeys('UPDATE_WORKSPACE'),
  ...spreadApiKeys('CREATE_WORKSPACE'),
  ...spreadApiKeys('GET_WORKSPACE_ACCOUNT_TEAM_COUNT'),
  CHANGE_WORKSPACE: null,
});

export function list() {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_WORKSPACES, types),
        request: (_data, token) =>
          request.get(`/api/workspaces`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        schema: SCHEMAS.WORKSPACE_ARRAY,
      },
      dispatch
    );
  };
}

export function create(attrs) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_WORKSPACE, types),
        request: (data, token) =>
          request.post(`/api/workspaces`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { workspace: attrs },
        schema: SCHEMAS.WORKSPACE,
      },
      dispatch
    );
  };
}

export function updateWorkspace(workspaceName, workspaceId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.UPDATE_WORKSPACE, types),
        request: (data, token) =>
          request.patch(`/api/workspaces/${workspaceId}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { workspace: { name: workspaceName } },
        schema: SCHEMAS.WORKSPACE,
      },
      dispatch
    );
  };
}

export function change(workspaceId) {
  return {
    type: types.CHANGE_WORKSPACE,
    data: workspaceId,
  };
}

export function getAccountTeamCount(workspaceId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.GET_WORKSPACE_ACCOUNT_TEAM_COUNT, types),
        request: (_data, token) =>
          request.get(`/api/workspaces/${workspaceId}/account/team-count`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
      },
      dispatch
    );
  };
}
