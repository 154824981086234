import keymirror from 'keymirror';
import { request } from 'js/api/axios';
import { callApi } from '../api';

import { SCHEMAS } from 'js/api/schemas';
import { spreadApiKeys, extractApiKeys } from '../utils';

export const types = keymirror({
  ...spreadApiKeys('LIST_FAVORITE_TEAMS'),
  ...spreadApiKeys('CREATE_FAVORITE_TEAM'),
  ...spreadApiKeys('DELETE_FAVORITE_TEAM'),
});

export function list() {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LIST_FAVORITE_TEAMS, types),
        request: (_data, token) =>
          request.get('/api/favorite-teams', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        schema: SCHEMAS.FAVORITE_TEAM_ARRAY,
      },
      dispatch
    );
  };
}

export function create(teamHandle) {
  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_FAVORITE_TEAM, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/teams/${teamHandle}/favorite`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: {},
        schema: SCHEMAS.FAVORITE_TEAM,
        meta: {
          teamId: teamHandle,
          create: {
            favoriteTeams: {
              [teamHandle]: {
                team_id: teamHandle,
                user_id: getState().identity.me.id,
              },
            },
          },
        },
      },
      dispatch
    );
  };
}

export function del(teamHandle) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.DELETE_FAVORITE_TEAM, types),
        request: (_data, token) =>
          request.delete(`/api/favorite-teams/${teamHandle}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: {},
        meta: { delete: { favoriteTeams: teamHandle } },
      },
      dispatch
    );
  };
}
