import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { every } from 'lodash';

import { Redirect } from 'react-router-dom';

import { SCHEMAS } from 'js/api/schemas';
import { me as loadMe } from 'js/actions/account-actions';
import { load as loadAssessment } from 'js/actions/assessment-actions';
import { getEngine } from 'js/engines';
import { FullPageLoading } from 'js/components';

const AssessmentHostContainer = ({
  isAuthenticated,
  loadAssessment,
  location,
  loadMe,
  ...props
}) => {
  const {
    me,
    assessment,
    match: {
      params: { assessmentId },
    },
  } = props;

  useEffect(() => {
    loadAssessment(assessmentId);
  }, [assessmentId, loadAssessment]);

  const assessmentType = assessment?.type;
  const myId = me?.id;
  const workspacesLoaded = every(me?.workspaces);
  const meLoaded = !!(myId && workspacesLoaded);
  useEffect(() => {
    if (['live-assessment', 'live'].includes(assessmentType) && !meLoaded) {
      loadMe();
    }
  }, [meLoaded, assessmentType, loadMe]);

  if (!assessment) {
    return <FullPageLoading />;
  }

  // Add protection if the assessment requires authentication
  if (['live-assessment', 'live'].includes(assessment.type)) {
    if (!isAuthenticated) {
      return (
        <Redirect
          to={{ pathname: '/pages/login', state: { from: location } }}
        />
      );
    } else if (!meLoaded) {
      return <FullPageLoading />;
    }

    const HostComponent = getEngine(assessment).Host;

    return <HostComponent {...props} />;
  }

  return null;
};

export default connect(
  (store, props) => {
    const { match } = props;
    const { entities, identity, router, participation } = store;
    const { assessmentId } = match.params;

    const normalizedAssessment = entities.assessments[assessmentId];
    const assessment = denormalize(
      normalizedAssessment,
      SCHEMAS.ASSESSMENT,
      entities
    );

    const isAuthenticated = !!(identity.me && identity.token);

    const me = denormalize(identity.me, SCHEMAS.ME, entities);

    return {
      me,
      participant: participation[assessmentId] || null,
      isAuthenticated,
      assessment,
      location: router.location,
    };
  },
  {
    loadAssessment,
    loadMe,
  }
)(AssessmentHostContainer);
