import React from 'react';
import classnames from 'classnames';

import Question from '../Question';
import VoteTally from '../VoteTally';
import VoteTallyCount from 'js/containers/VoteTallyCount';
import ParticipantList from './ParticipantList';
import { Redirect } from 'react-router-dom';

const HostQuestion = (props) => {
  const { liveAssessmentData, question, participants } = props;

  const voteTallyClasses = {
    'vote-tally-wrapper': true,
    // 'show-results': false
    'show-results':
      question &&
      question.reveal_results_on &&
      question.reveal_results_on <= Date.now(),
  };

  return !question ? (
    <Redirect to="host/kickoff" />
  ) : (
    <div className="container-fluid">
      <div className="row">
        <div className="col col-12 col-md-3 order-md-9">
          <div className="sidebar-item">
            <h4>Responses</h4>
            <div className={classnames(voteTallyClasses)}>
              <div style={{ position: 'relative' }}>
                <VoteTally
                  responses={question.responses}
                  type="horizontal-bar"
                  question={question}
                />
                <VoteTallyCount
                  question={question}
                  responses={question.responses}
                />
              </div>
            </div>
          </div>
          <div className="sidebar-item">
            <ParticipantList
              participants={participants}
              hostId={liveAssessmentData.host_id}
            />
          </div>
        </div>
        <div className="col col-12 col-md-9 order-md-1">
          <Question {...props} isMarker={false} />
        </div>
      </div>
    </div>
  );
};

export default HostQuestion;
