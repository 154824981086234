import keymirror from 'js/utils/keymirror';

export const types = keymirror({
  DISMISS_ALERT: null,
  ALERT: null
});

export function dismiss(id) {
  return {
    type: types.DISMISS_ALERT,
    id
  };
}

export function notify(alert) {
  return {
    type: types.ALERT,
    alert
  };
}
