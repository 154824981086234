import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { flattenDeep, last } from 'lodash';
import { denormalize } from 'normalizr';
import { bindActionCreators } from 'redux';

import { SCHEMAS } from 'js/api/schemas';
import { mod } from 'js/utils';

import {
  respond as respondToAssessment,
  update as updateAssessment,
  bulkUpdateRevealResultsOn,
} from 'js/actions/assessment-actions';
import {
  load as loadLiveAssessmentData,
  changeQuestion,
} from 'js/actions/live-assessment-actions';
import { load as loadPassphrase } from 'js/actions/passphrase-actions';
import {
  joinAssessmentChannel,
  leaveAssessmentChannel,
} from 'js/actions/channel-actions';

import HostComponent from '../components/Host/LiveAssessmentHostComponent';
import { FullPageLoading } from 'js/components';
import {
  createAssessmentInvites,
  listAssessmentInvites,
} from 'js/actions/assessment-invite-actions';
import { selectAssessmentInvitesQuery } from 'js/store/reducers/queries';
import { notify } from 'js/actions/alert-actions';

const LiveAssessmentHostEngine = (props) => {
  const {
    assessment,
    liveAssessmentData,
    question,
    presences,
    passphrase,
    match,
    history,
    loadLiveAssessmentData,
    loadPassphrase,
    joinChannel,
    leaveChannel,
    changeQuestion,
    respondToAssessment,
    updateAssessment,
    assessmentInvitesQueryResult,
    listAssessmentInvites,
    createAssessmentInvites,
    notify,
    bulkUpdateRevealResultsOn,
  } = props;

  const [sendingInvites, setSendingInvites] = useState(false);
  const teamId = assessment?.team_id;
  const workspaceId = assessment?.workspace_id;

  useEffect(() => {
    loadLiveAssessmentData(assessment.id);
    loadPassphrase(assessment.id);
    joinChannel(assessment.id);
    listAssessmentInvites(workspaceId, teamId, assessment.id);

    return () => {
      leaveChannel(assessment.id);
    };
  }, [
    assessment.id,
    loadLiveAssessmentData,
    loadPassphrase,
    joinChannel,
    leaveChannel,
    teamId,
    listAssessmentInvites,
    workspaceId,
  ]);

  const assessmentId = assessment ? assessment.id : undefined;

  const { questionList, currentIndex } = useMemo(() => {
    if (!liveAssessmentData || !assessment)
      return { questionList: [], currentIndex: -1 };

    const { current_question_id } = liveAssessmentData;

    const questionList = flattenDeep(
      assessment.question_sets.map((qs) =>
        qs.questions.map((q) => ({
          questionId: q.id,
          questionSetId: qs.id,
        }))
      )
    );

    const currentIndex = questionList.findIndex(
      (x) => x.questionId === current_question_id
    );

    return { currentIndex, questionList };
  }, [assessment, liveAssessmentData]);

  const shiftQuestion = (increment) => {
    if (
      currentIndex + increment >= questionList.length ||
      currentIndex + increment < 0
    ) {
      history.push(`/assessments/${assessment.id}/host/wrapup`);
    } else {
      const nextIndex = mod(currentIndex + increment, questionList.length);

      changeQuestion(assessment.id, questionList[nextIndex].questionId);
      const hostUrl = `/assessments/${assessment.id}/host`;
      if (history.location.pathname !== hostUrl) {
        history.push(hostUrl);
      }
    }
  };

  const handleChangeQuestion = (questionId) => {
    changeQuestion(assessment.id, questionId);
    history.push(`/assessments/${assessment.id}/host`);
  };

  const handleFirstQuestion = () => {
    const firstQuestion = assessment.question_sets.find(
      (qs) => qs.questions.length > 0
    )?.questions[0];

    handleChangeQuestion(firstQuestion.id);
  };

  const handleLastQuestion = () => {
    const lastQuestion = last(
      assessment.question_sets.findLast((qs) => qs.questions.length > 0)
        .questions
    );

    handleChangeQuestion(lastQuestion.id);
  };

  const handleResponse = (assessmentId, question, answer) => {
    respondToAssessment(assessmentId, question.id, {
      answer_id: answer.id,
      value: answer.value,
    });
  };

  const handleToggleAssessmentLock = (assessment) => {
    return updateAssessment({
      id: assessment.id,
      is_locked: !assessment.is_locked,
    });
  };

  const handleSendInvites = useCallback(
    (emails, message) => {
      setSendingInvites(true);
      return createAssessmentInvites(
        workspaceId,
        teamId,
        assessmentId,
        message,
        emails.map((email) => ({ email }))
      )
        .then(() => {
          notify({
            type: 'success',
            message: 'Your invitations are on their way!',
          });
          listAssessmentInvites(workspaceId, teamId, assessmentId);
        })
        .catch(() =>
          notify({
            type: 'danger',
            message: "Uh oh! Your invitations didn't get through",
          })
        )
        .finally(() => setSendingInvites(false));
    },
    [
      workspaceId,
      teamId,
      assessmentId,
      createAssessmentInvites,
      setSendingInvites,
      notify,
      listAssessmentInvites,
    ]
  );

  const handleUpdateRevealResultsOn = useCallback(
    (newReveal) => {
      return bulkUpdateRevealResultsOn(assessmentId, newReveal);
    },
    [bulkUpdateRevealResultsOn, assessmentId]
  );

  return !liveAssessmentData ||
    (liveAssessmentData.current_question_id !== null && !question) ? (
    <FullPageLoading />
  ) : (
    <HostComponent
      match={match}
      assessment={assessment}
      liveAssessmentData={liveAssessmentData}
      question={question}
      presences={presences}
      passphrase={passphrase}
      onRespond={handleResponse}
      onNextQuestion={() => shiftQuestion(1)}
      onPreviousQuestion={() => shiftQuestion(-1)}
      onToggleAssessmentLock={handleToggleAssessmentLock}
      onFirstQuestion={handleFirstQuestion}
      onLastQuestion={handleLastQuestion}
      onChangeQuestion={handleChangeQuestion}
      assessmentInvitesQueryResult={assessmentInvitesQueryResult}
      onSendInvites={handleSendInvites}
      sendingInvites={sendingInvites}
      onBulkUpdateRevealResultsOn={handleUpdateRevealResultsOn}
    />
  );
};

LiveAssessmentHostEngine.propTypes = {
  assessment: PropTypes.object.isRequired,
};

export default withRouter(
  connect(
    (state, ownProps) => {
      const { assessment } = ownProps;

      const liveAssessmentData = state.entities.liveAssessments[assessment.id];
      let question =
        liveAssessmentData && liveAssessmentData.current_question_id
          ? state.entities.questions[liveAssessmentData.current_question_id]
          : null;

      if (!!question) {
        question = denormalize(question, SCHEMAS.QUESTION, state.entities);
      }

      let passphrase = state.entities.passphrases[assessment.id] || {};

      return {
        liveAssessmentData,
        question,
        me: denormalize(state.identity.me, SCHEMAS.ME, state.entities),
        presences: state.presences[assessment.id] || [],
        passphrase: passphrase.passphrase,
        assessmentInvitesQueryResult: selectAssessmentInvitesQuery(
          state,
          assessment.id
        ),
      };
    },
    (dispatch) => ({
      respondToAssessment: bindActionCreators(respondToAssessment, dispatch),
      updateAssessment: bindActionCreators(updateAssessment, dispatch),
      loadLiveAssessmentData: bindActionCreators(
        loadLiveAssessmentData,
        dispatch
      ),
      changeQuestion: bindActionCreators(changeQuestion, dispatch),
      joinChannel: (key) => dispatch(joinAssessmentChannel(key)),
      leaveChannel: (key) => dispatch(leaveAssessmentChannel(key)),
      loadPassphrase: bindActionCreators(loadPassphrase, dispatch),
      listAssessmentInvites: bindActionCreators(
        listAssessmentInvites,
        dispatch
      ),
      createAssessmentInvites: bindActionCreators(
        createAssessmentInvites,
        dispatch
      ),
      notify: bindActionCreators(notify, dispatch),
      bulkUpdateRevealResultsOn: bindActionCreators(
        bulkUpdateRevealResultsOn,
        dispatch
      ),
    })
  )(LiveAssessmentHostEngine)
);
