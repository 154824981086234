import { trueToFalse as answers } from './base-answers';

export const portfolioManagement = {
  name: 'Lean Portfolio Management (LPM)',
  key: 'lean-portfolio-management',
  category: 'portfolio-management',
  questions: [
    {
      text: 'We regularly communicate a vision describing the future state of our solutions',
      key: 'regularly-communicate-vision',
      answers,
    },
    {
      text: 'We align work to the vision',
      key: 'work-aligned-to-vision',
      answers,
    },
    {
      text: 'We prioritize initiatives based on a defined economic framework',
      key: 'economic-prioritization-model',
      answers,
    },
    {
      text: 'We are moving away from project-based funding',
      key: 'project-funding',
      answers,
    },
    {
      text: 'We review and adjust funding decisions on a cadence',
      key: 'adjust-finding-on-cadence',
      answers,
    },
    {
      text: 'We use outcome-based metrics to measure success',
      key: 'outcome-based-success-metrics',
      answers,
    },
    {
      text: 'We share successful program execution patterns across the organization',
      key: 'share-successful-execution-patterns',
      answers,
    },
  ],
};
