import React from 'react';

import { Link } from 'react-router-dom';

import QuestionOverview from '../QuestionOverview';

const BallotReview = ({ assessment, participant }) => {
  return (
    <section className="ballot-review">
      <h2 className="review-title">Here's what you said:</h2>
      <p className="subtext">
        Feel free to tweak your responses if you have a change of heart.
        Otherwise, you're done, so grab a drink and relax!
      </p>
      <div className="responses">
        {assessment.question_sets
          .map((qs) => {
            return [
              <div className="question-set" key={`question-set-${qs.id}`} />,
              qs.questions.map((q) => {
                const response = q.responses.find(
                  (r) => r.participant_id === participant.id
                );

                return (
                  <Link
                    key={`question-${q.id}`}
                    className="question"
                    to={`/assessments/${assessment.id}/run/questions/${q.id}`}
                  >
                    <QuestionOverview
                      question={q}
                      responses={response ? [response] : []}
                      yLimit={10}
                    />
                  </Link>
                );
              }),
            ];
          })
          .flat()}
      </div>
    </section>
  );
};

export default BallotReview;
