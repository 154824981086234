import { countBy } from 'lodash';
import { removeStopwords as removeStopwordsFromArray } from 'stopword';

const WORD_CASE = {
  LOWER: 'lower',
  UPPER: 'upper',
  EXACT: 'exact',
};

export function removeStopwords(wordArray) {
  return removeStopwordsFromArray(wordArray);
}

export function wordFrequency(text, options) {
  options = {
    case: WORD_CASE.LOWER, // lower, upper, exact
    removeStopwords: true,
    stopwordsFilter: removeStopwords,
    ...options,
  };

  switch (options.case) {
    case WORD_CASE.LOWER:
      text = text.toLowerCase();
      break;

    case WORD_CASE.UPPER:
      text = text.toUpperCase();
      break;

    default:
    // noop
  }

  // TODO? stopwords?
  let words = text.match(/[^\W\d](\w|[-']{1,2}(?=\w))*/g);

  if (options.removeStopwords) {
    words = options.stopwordsFilter(words);
  }
  return countBy(words);
}
