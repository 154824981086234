import React, { useMemo, useState } from 'react';
import { getSummaryMetrics, getCategoryLookups } from 'js/utils/question-set';

import { AssessmentSelector } from './AssessmentSelector';
import { AssessmentDifferentialChart } from './AssessmentDifferentialChart';

import styles from './AssessmentDifferential.module.scss';
import NoAssessmentSelected from './NoAssessmentSelected';

export const AssessmentDifferential = ({ assessments }) => {
  // TODO: intialize this to something reasonable (first/last, last two, last one and closest to 1 year ago?)
  const categoryLookups = useMemo(() => {
    const summaryMetrics = getSummaryMetrics(assessments);
    return getCategoryLookups(assessments, summaryMetrics.categories);
  }, [assessments]);

  const [summaryData, setSummaryData] = useState({
    assessments: null,
    summaryMetrics: null,
  });

  const handleSelectAssessments = (assessmentsToCompare) => {
    setSummaryData({
      assessments: assessmentsToCompare,
      summaryMetrics: getSummaryMetrics(
        assessmentsToCompare.filter((x) => x !== null)
      ),
    });
  };

  const [before, after] =
    summaryData.assessments === null
      ? [null, null]
      : [
          summaryData.summaryMetrics?.assessments[
            summaryData.assessments[0]?.id
          ],
          summaryData.summaryMetrics?.assessments[
            summaryData.assessments[1]?.id
          ],
        ];

  const noAssessmentsSelected = !before && !after;

  return (
    <section className={styles['assessment-differential']}>
      <div className={styles.selector}>
        <AssessmentSelector
          assessments={assessments}
          onSelectAssessments={handleSelectAssessments}
        />
      </div>
      <div className={styles.chart}>
        {noAssessmentsSelected ? (
          <NoAssessmentSelected />
        ) : (
          <AssessmentDifferentialChart
            assessments={[before, after]}
            summaryMetrics={summaryData.summaryMetrics}
            categoryLookups={categoryLookups}
          />
        )}
      </div>
    </section>
  );
};

export default AssessmentDifferential;
