import { creatingValuableProducts } from './question-sets/creating-valuable-products';
import { customerNeeds } from './question-sets/customer-needs';
import { measureAndLearn } from './question-sets/measure-and-learn';
import { piExecution } from './question-sets/pi-execution';
import { piPlanningPreparation } from './question-sets/pi-planning-preparation';
import { piPlanning } from './question-sets/pi-planning';
import { releasingValue } from './question-sets/releasing-value';

export const safeAgileProductDelivery = {
  name: 'SAFe Agile Product Delivery',
  key: 'safe-agile-product-delivery',
  question_sets: [
    customerNeeds,
    creatingValuableProducts,
    piPlanningPreparation,
    piPlanning,
    piExecution,
    releasingValue,
    measureAndLearn,
  ],
};

export default safeAgileProductDelivery;
