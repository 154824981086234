import React from 'react';
import { connect } from 'react-redux';
import { AlertList } from 'react-bs-notifier';

import { dismiss as dismissAlert } from 'js/actions/alert-actions';

class Alerts extends React.Component {
  handleAlertDismissed(alert) {
    const { dismissAlert } = this.props;

    dismissAlert(alert.id);
  }

  render() {
    return (
      <AlertList
        alerts={this.props.alerts}
        timeout={5000}
        onDismiss={this.handleAlertDismissed.bind(this)}
      />
    );
  }
}

export default connect(
  state => {
    return {
      alerts: state.alerts.alerts
    };
  },
  { dismissAlert }
)(Alerts);
