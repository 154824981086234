import * as React from 'react';
import { connect } from 'react-redux';
import InviteUserComponent from 'js/routes/Users/components/InviteUserComponent';
import { useCallback, useState } from 'react';
import { invite as inviteUser } from 'js/actions/user-actions';
import { notify } from 'js/actions/alert-actions';
import { withRouter } from 'react-router-dom';

function InviteUserContainer({
  inviteUser,
  notify,
  currentWorkspaceId,
  history,
}) {
  const { push } = history;
  const [serverErrors, setServerErrors] = useState({});

  const onSubmit = useCallback(
    async ({ email, role }) => {
      try {
        await inviteUser(email, role);
        notify({
          type: 'success',
          message: 'User invited successfully',
        });
        push(`/w/${currentWorkspaceId}/users`);
      } catch (e) {
        const emailErrors = e.response?.data?.errors?.email;
        if (emailErrors && emailErrors.includes('already exists')) {
          setServerErrors({
            email: {
              message: 'The email address already exists in the workspace',
              submittedValue: email,
            },
          });
        }
        notify({
          type: 'danger',
          message: 'Sorry, there was a problem inviting the user',
        });
      }
    },
    [inviteUser, notify, currentWorkspaceId, push, setServerErrors]
  );

  return (
    <InviteUserComponent onSubmit={onSubmit} serverErrors={serverErrors} />
  );
}

export default withRouter(
  connect(
    (
      store,
      { match: { params: { workspaceId } = { workspaceId: null } } }
    ) => ({
      currentWorkspaceId: workspaceId,
    }),
    {
      inviteUser,
      notify,
    }
  )(InviteUserContainer)
);
