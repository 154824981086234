import React, { useCallback } from 'react';
import classnames from 'classnames';

function MultiSelectCheckBoxes({
  value,
  options = [],
  onChange,
  label,
  maxHeight,
  error,
}) {
  const handleChange = useCallback(
    (e) => {
      if (e.target.checked) {
        onChange([...value, +e.target.value]);
      } else {
        onChange(value.filter((v) => v !== +e.target.value));
      }
    },
    [onChange, value]
  );

  return (
    <div className="multi-select-check-boxes">
      <span>{label}</span>
      <div className="choices" style={{ maxHeight }}>
        {options.map((opt) => (
          <div
            className={classnames({
              choice: true,
              selected: value.find((v) => v === opt.id),
            })}
            key={opt.id}
          >
            <input
              id={`opt${opt.id}`}
              type="checkbox"
              checked={!!value.find((v) => v === opt.id)}
              value={opt.id}
              onChange={handleChange}
            />
            <label
              data-test="option-label"
              className="pl-2"
              htmlFor={`opt${opt.id}`}
            >
              {opt.name}
            </label>
          </div>
        ))}
      </div>
      <div className="error-msg">{error}</div>
    </div>
  );
}

export default MultiSelectCheckBoxes;
