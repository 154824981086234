import React from 'react';
import classnames from 'classnames';

const Lollipop = ({
  x,
  y,
  width,
  height,
  fill,
  decorator: Decorator,
  className
}) => {
  const outerRadius = width / 2;
  const innerRadius = 0.5 * outerRadius;
  const barWidth = width / 4;

  const fillProps = {};
  if (fill !== undefined) {
    fillProps.fill = fill;
  }

  return (
    <g
      transform={`translate(${x + width / 2},${y})`}
      className={classnames('lollipop-marker', className)}
    >
      <rect
        x={-barWidth / 2}
        y={innerRadius}
        width={barWidth}
        height={height - innerRadius}
        className="lollipop-stem"
        {...fillProps}
      />
      {Decorator ? <Decorator /> : null}
      <path
        fillRule="evenodd"
        d={[
          `M 0 -${outerRadius}`,
          `A ${outerRadius} ${outerRadius} 0 1 0 0 ${outerRadius}`,
          `A ${outerRadius} ${outerRadius} 0 1 0 0 ${-outerRadius}`,
          'Z',
          `M 0 -${innerRadius}`,
          `A ${innerRadius} ${innerRadius} 0 1 1 0 ${innerRadius}`,
          `A ${innerRadius} ${innerRadius} 0 1 1 0 ${-innerRadius}`
        ].join(' ')}
        className="lollipop-pop"
        {...fillProps}
      />
    </g>
  );
};

Lollipop.defaultProps = {
  x: 0,
  y: 0
};

export default Lollipop;
