import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

function OrgBreadcrumb({ node, buildLink }) {
  const path = useMemo(() => node.ancestors().reverse(), [node]);

  return (
    <div>
      {path.map(({ data: { id, name } }, i) => (
        <span key={id}>
          {i < path.length - 1 ? (
            <Link to={buildLink(id)}>{name}</Link>
          ) : (
            <span>{name}</span>
          )}
          {i < path.length - 1 && <span className="mx-1">/</span>}
        </span>
      ))}
    </div>
  );
}

export default OrgBreadcrumb;
