import React, { useState } from 'react';
import classnames from 'classnames';
import { noop } from 'lodash';

import { Icon } from 'js/components';

import { faCaretRight as falCaretRight } from '@fortawesome/pro-light-svg-icons';
import { faCaretRight as fasCaretRight } from '@fortawesome/pro-solid-svg-icons';

import styles from './AssessmentDifferential.module.scss';

const timeFormatter = new Intl.DateTimeFormat(undefined, {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});

const RANGE_POSITION = {
  start: 0,
  end: 1,
  none: null,
};

function getAssessmentButtonClassNames(assessment, [start, end]) {
  return classnames(styles.btn, {
    [styles['start-range']]: assessment.id === start?.id,
    [styles['end-range']]: assessment.id === end?.id,
    [styles['within-range']]:
      start !== null &&
      end !== null &&
      assessment.inserted_at >= start.inserted_at &&
      assessment.inserted_at <= end.inserted_at,
  });
}

function getAssessmentButtonIcon(
  assessment,
  [start, end],
  lastSetRangePosition
) {
  if (
    lastSetRangePosition === RANGE_POSITION.none ||
    (start === null && end === null)
  ) {
    return falCaretRight;
  } else if (assessment.id === start?.id) {
    return falCaretRight;
  } else if (assessment.id === end?.id) {
    return fasCaretRight;
  } else if (end === null) {
    return assessment.inserted_at > start.inserted_at
      ? fasCaretRight
      : falCaretRight;
  } else if (assessment.inserted_at <= start?.inserted_at) {
    return falCaretRight;
  } else if (assessment.inserted_at >= end?.inserted_at) {
    return fasCaretRight;
  }

  return lastSetRangePosition === RANGE_POSITION.start
    ? fasCaretRight
    : falCaretRight;
}

export const AssessmentSelector = ({
  assessments,
  onSelectAssessments = noop,
}) => {
  const sortedAssessments = [...assessments].sort((a, b) =>
    a.inserted_at > b.inserted_at ? 1 : -1
  );

  const [range, setRange] = useState([null, null]);
  const [lastSetRangePosition, setLastRangePosition] = useState(
    RANGE_POSITION.none
  );

  const setRangeWithCallback = (newRange) => {
    setRange(newRange);
    onSelectAssessments(newRange);
  };

  const handleSelectAssessment = (assessment) => {
    if (assessment.id === range[0]?.id || assessment.id === range[1]?.id) {
      // TODO: handle this better so it keeps alternating between start and end
      // do nothing
    } else if (range[1] === null) {
      setLastRangePosition(RANGE_POSITION.end);
      setRangeWithCallback([range[0], assessment]);
    } else if (range[0] === null) {
      if (range[1].inserted_at < assessment.inserted_at) {
        setLastRangePosition(RANGE_POSITION.end);
        setRangeWithCallback([range[1], assessment]);
      } else {
        setLastRangePosition(RANGE_POSITION.start);
        setRangeWithCallback([assessment, range[1]]);
      }
    } else if (assessment.inserted_at < range[0].inserted_at) {
      // new position is before the start, move the start
      setLastRangePosition(RANGE_POSITION.start);
      setRangeWithCallback([assessment, range[1]]);
    } else if (assessment.inserted_at > range[1].inserted_at) {
      // new position is after the end, move the end
      setLastRangePosition(RANGE_POSITION.end);
      setRangeWithCallback([range[0], assessment]);
    } else if (lastSetRangePosition === RANGE_POSITION.start) {
      // position is in the middle, move the opposite of whichever was moved last
      setLastRangePosition(RANGE_POSITION.end);
      setRangeWithCallback([range[0], assessment]);
    } else {
      setLastRangePosition(RANGE_POSITION.start);
      setRangeWithCallback([assessment, range[1]]);
    }
  };

  return (
    <div>
      {/* <div>
        <label>From</label>
        <p>None</p>
      </div>
      <div>
        <label>To</label>
        <p>None</p>
      </div> */}
      <div>
        <label>Assessments</label>
      </div>
      <div>
        {sortedAssessments.map((a) => {
          return (
            <button
              className={getAssessmentButtonClassNames(a, range)}
              key={a.id}
              onClick={() => handleSelectAssessment(a)}
            >
              <Icon
                icon={getAssessmentButtonIcon(a, range, lastSetRangePosition)}
                className="icon-left"
                fixedWidth
              />
              {timeFormatter.format(a.inserted_at)}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default AssessmentSelector;
