import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { denormalize } from 'normalizr';

import { Switch, Route } from 'react-router-dom';

import {
  respond as respondToAssessment,
  completeParticipantWelcome,
} from 'js/actions/assessment-actions';
import {
  load as loadLiveAssessmentData,
  changeQuestion,
} from 'js/actions/live-assessment-actions';
import { SCHEMAS } from 'js/api/schemas';

import { useAssessmentChannel } from 'js/hooks/sockets/useAssessmentChannel';

// import FullPageLoading from '../components/FullPageLoading';
import { FullPageLoading } from 'js/components';
import RunComponent from '../components/Run/LiveAssessmentRunComponent';
import RunReviewComponent from '../components/Run/RunReviewComponent';

const LiveAssessmentRunEngine = (props) => {
  const {
    assessment,
    loadLiveAssessmentData,
    completeParticipantWelcome,
    liveAssessmentData,
    changeQuestion,
    respondToAssessment,
    participant,
    history,
    question,
    match,
    presences,
  } = props;

  useEffect(() => {
    loadLiveAssessmentData(assessment.id);
  }, [assessment.id, loadLiveAssessmentData]);

  const assessmentId = assessment?.id;
  const participantToken = participant?.token;
  const handleCompleteWelcome = useCallback(
    (vals) => completeParticipantWelcome(assessmentId, participantToken, vals),
    [completeParticipantWelcome, assessmentId, participantToken]
  );

  useAssessmentChannel(assessmentId, participantToken);

  const handleChangeQuestion = useCallback(
    (assessmentId, questionId) => {
      const { host_id } = liveAssessmentData;
      const isHost = host_id === participant.id;

      if (isHost) {
        changeQuestion(assessmentId, questionId);
      } else {
        history.push(
          `/assessments/${assessmentId}/run/questions/${questionId}`
        );
      }
    },
    [liveAssessmentData, participant, changeQuestion, history]
  );

  const shiftQuestion = useCallback(
    (increment) => {
      const questionList = _.flattenDeep(
        assessment.question_sets.map((qs) =>
          qs.questions.map((q) => ({
            questionId: q.id,
            questionSetId: qs.id,
          }))
        )
      );

      const currentIndex = !question
        ? 0
        : _.findIndex(questionList, (x) => x.questionId === question.id);
      const nextIndex = currentIndex + increment;

      if (nextIndex === questionList.length) {
        history.push(`/assessments/${assessment.id}/run/review`);
      } else {
        const nextQuestionId = questionList[nextIndex].questionId;

        handleChangeQuestion(assessment.id, nextQuestionId);
      }
    },
    [assessment, history, handleChangeQuestion, question]
  );

  const push = history.push;
  const gotoLastQuestion = useCallback(() => {
    const lastQuestionSet =
      assessment.question_sets[assessment.question_sets.length - 1];
    const lastQuestionId =
      lastQuestionSet.questions[lastQuestionSet.questions.length - 1].id;

    handleChangeQuestion(assessment.id, lastQuestionId);
    const isHost = liveAssessmentData.host_id === participant.id;
    if (isHost) {
      push(`/assessments/${assessment.id}/run/questions`);
    }
  }, [assessment, handleChangeQuestion, liveAssessmentData, participant, push]);

  const handleRespond = (assessmentId, question, answer) => {
    const myResponse = _.find(
      question.responses,
      (r) => r.participant_id === participant.id
    );

    const response = {
      participant_id: participant.id,
      answer_id: answer.id,
      value: answer.value,
      is_na: answer.is_na,
    };

    if (myResponse) {
      response.id = myResponse.id;
    }

    respondToAssessment(assessmentId, question.id, response);
  };

  const isSynced =
    !liveAssessmentData ||
    !match.params.questionId ||
    liveAssessmentData.host_id === participant.id;

  return !liveAssessmentData ||
    (liveAssessmentData.current_question_id !== null && !question) ? (
    <FullPageLoading />
  ) : (
    <Switch>
      <Route
        path={`${match.path}/review`}
        exact
        render={(routeProps) => (
          <RunReviewComponent
            {...routeProps}
            assessment={assessment}
            presences={presences}
            participant={participant}
            allowNext={false}
            onPreviousQuestion={gotoLastQuestion}
          />
        )}
      />
      <Route path={match.path}>
        <RunComponent
          participant={participant}
          assessment={assessment}
          liveAssessmentData={liveAssessmentData}
          onCompleteWelcome={handleCompleteWelcome}
          isSynced={isSynced}
          question={question}
          presences={presences}
          onRespond={handleRespond}
          onNextQuestion={() => shiftQuestion(1)}
          onPreviousQuestion={() => shiftQuestion(-1)}
        />
      </Route>
    </Switch>
  );
};

LiveAssessmentRunEngine.propTypes = {
  assessment: PropTypes.object.isRequired,
};

export default withRouter(
  connect(
    (state, ownProps) => {
      const { assessment, match } = ownProps;

      const liveAssessmentData = state.entities.liveAssessments[assessment.id];
      const questionId =
        match.params.questionId ||
        assessment.question_sets?.find((qs) => qs.questions.length > 0)
          ?.questions[0]?.id;

      let question = questionId ? state.entities.questions[questionId] : null;

      if (!!question) {
        question = denormalize(question, SCHEMAS.QUESTION, state.entities);
      }

      return {
        liveAssessmentData,
        question,
        presences: state.presences[assessment.id] || [],
        me: state.identity.me,
      };
    },
    (dispatch) => ({
      respondToAssessment: bindActionCreators(respondToAssessment, dispatch),
      completeParticipantWelcome: bindActionCreators(
        completeParticipantWelcome,
        dispatch
      ),
      loadLiveAssessmentData: bindActionCreators(
        loadLiveAssessmentData,
        dispatch
      ),
      changeQuestion: bindActionCreators(changeQuestion, dispatch),
    })
  )(LiveAssessmentRunEngine)
);
