import React from 'react';
import classnames from 'classnames';

import { Icon } from 'js/components';

export default class EditableText extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = (e) => {
    e.stopPropagation();
    if (!this.state.editing) {
      this.edit();
    }
  };

  edit = () => {
    // if disabled = true do prevent editing
    if (this.props.disabled) {
      return;
    }

    this.setState(
      {
        editing: true,
      },
      () => {
        this.domElm.focus();
      }
    );
  };

  save = () => {
    this.setState(
      {
        editing: false,
      },
      () => {
        if (this.props.onSave && this.isValueChanged()) {
          this.props.onSave(this.domElm.textContent);
        }
      }
    );
  };

  delayedSave = () => {
    setTimeout(() => {
      this.save();
    }, 100);
  };

  cancel = () => {
    this.domElm.textContent = this.props.value;
    this.setState({
      editing: false,
    });
  };

  isValueChanged = () => {
    return this.props.value !== this.domElm.textContent;
  };

  handleKeyDown = (e) => {
    const { key } = e;
    switch (key) {
      case 'Escape':
        this.cancel();
        break;
      case 'Enter':
        this.save();
        break;
      default: // nothing special
    }
  };

  render() {
    let editOnClick = true;
    const { editing } = this.state;
    if (this.props.editOnClick !== undefined) {
      editOnClick = this.props.editOnClick;
    }

    const { onSave, disabled, ...props } = this.props;

    return (
      <div className={classnames({ 'content-editable': true, editing })}>
        <div className="content-wrapper">
          <span
            className={editing ? 'editing' : ''}
            onClick={editOnClick ? this.toggleEdit : undefined}
            contentEditable={editing && !disabled}
            suppressContentEditableWarning={true}
            ref={(domNode) => {
              this.domElm = domNode;
            }}
            onBlur={this.delayedSave}
            onKeyDown={this.handleKeyDown}
            {...props}
          >
            {this.props.value}
          </span>
          <div className="content-editable-actions">
            {editing ? (
              <>
                <button className="btn-inline-action" onClick={this.save}>
                  <Icon
                    className="edit-icon"
                    icon={['far', 'check']}
                    fixedWidth
                  />
                </button>
                <button className="btn-inline-action" onClick={this.cancel}>
                  <Icon
                    className="edit-icon"
                    icon={['far', 'times']}
                    fixedWidth
                  />
                </button>
              </>
            ) : (
              <>
                {!disabled && (
                  <button
                    className="btn-inline-action"
                    onClick={this.edit}
                    disabled={disabled}
                  >
                    <Icon
                      className="edit-icon"
                      icon={['far', 'edit']}
                      fixedWidth
                    />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
