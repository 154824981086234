import React, { useCallback, useState } from 'react';
import TeamNode from 'js/routes/Organizations/components/ManageOrg/OrgTreeVeiw/TeamNode';
import { Icon } from 'js/components';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import RenameForm from 'js/routes/Organizations/components/ManageOrg/OrgTreeVeiw/RenameForm';
import useWindowDimensions from 'js/utils/window-dimensions-hook';
import classnames from 'classnames';

const nodeSorter = (a, b) => {
  const aName = `${a.type === 'org' ? 1 : 2}_${a.name.toUpperCase()}`;
  const bName = `${b.type === 'org' ? 1 : 2}_${b.name.toUpperCase()}`;
  return aName.localeCompare(bName);
};

function OrgNode({
  node,
  pathToNode = [],
  depth = 0,
  onUpdateNode,
  expandedNodeIds,
  onExpand,
  onCollapse,
  onAddTeams,
  onAddGroup,
  onAddOrgRef,
  referenceHighlight = false,
  onRemove,
  onRename,
  onPromoteToOrg,
}) {
  const childNodes = node.children.sort(nodeSorter).map((child) => {
    if (child.type === 'org') {
      return (
        <OrgNode
          onUpdateNode={onUpdateNode}
          node={child}
          pathToNode={[...pathToNode, node]}
          depth={depth + 1}
          key={`p-${node.id}-o-${child.id}`}
          onExpand={onExpand}
          onCollapse={onCollapse}
          expandedNodeIds={expandedNodeIds}
          onAddTeams={onAddTeams}
          onAddGroup={onAddGroup}
          onAddOrgRef={onAddOrgRef}
          referenceHighlight={referenceHighlight || child.isRoot}
          onRemove={onRemove}
          onRename={onRename}
          onPromoteToOrg={onPromoteToOrg}
        />
      );
    } else {
      return (
        <TeamNode
          node={child}
          pathToNode={[...pathToNode, node]}
          depth={depth + 1}
          key={`p-${node.id}-t-${child.id}`}
          onRemove={onRemove}
        />
      );
    }
  });

  const open = expandedNodeIds.includes(node.depthId);
  const caretIcon = open ? 'caret-down' : 'caret-right';

  const [showMenu, setShowMenu] = useState(false);
  const [showAddMenu, setShowAddMenu] = useState(false);
  const [editName, setEditName] = useState(false);
  const windowDims = useWindowDimensions();

  const handleToggle = useCallback(() => {
    return open ? onCollapse(node, false) : onExpand(node, false);
  }, [open, onCollapse, onExpand, node]);

  const handleRename = useCallback(
    (name) => {
      return Promise.resolve(onRename(pathToNode, node, name)).finally(() =>
        setEditName(false)
      );
    },
    [onRename, setEditName, node, pathToNode]
  );

  return (
    <div
      className={`org-node ${referenceHighlight ? 'reference-highlight' : ''}`}
    >
      <div
        className={classnames({
          row: true,
          editing: editName,
          'with-menu-open': showMenu || showAddMenu,
        })}
      >
        <div className="gutters">
          {pathToNode.map((n) => (
            <div
              key={n.depthId}
              className={`gutter ${n.isRoot ? 'org-level' : 'group-level'}`}
            />
          ))}
        </div>
        <div className="icons" style={{ display: 'flex' }}>
          <button className="btn btn-link folder-toggle" onClick={handleToggle}>
            <Icon fixedWidth className="tree-icon" icon={['fas', caretIcon]} />
          </button>
          <button className="btn btn-link folder-toggle" onClick={handleToggle}>
            {node.isRoot ? (
              <Icon className="row-icon org-icon" icon="sitemap" />
            ) : (
              <Icon
                className="row-icon folder-icon"
                icon="user-friends"
                mask="folder"
                transform="shrink-9 down-1"
              />
            )}
          </button>
        </div>
        <div className="row-content">
          <div className="name">
            {editName ? (
              <RenameForm
                node={node}
                onCancel={() => setEditName(false)}
                onSave={handleRename}
              />
            ) : (
              <span>{node.name}</span>
            )}
          </div>
          <div className="actions">
            <Dropdown
              size="sm"
              isOpen={showMenu}
              toggle={() => setShowMenu(!showMenu)}
            >
              <DropdownToggle className="btn btn-link">
                <Icon icon={['far', 'ellipsis-h']} />
              </DropdownToggle>
              <DropdownMenu right={windowDims.width <= 600}>
                <DropdownItem onClick={() => onExpand(node, true)}>
                  <Icon icon="expand-alt" className="mx-1" /> Expand all
                </DropdownItem>
                <DropdownItem onClick={() => onCollapse(node, true)}>
                  <Icon icon="compress-alt" className="mx-1" /> Collapse all
                </DropdownItem>
                <DropdownItem onClick={() => setEditName(true)}>
                  <Icon icon="pencil-alt" className="mx-1" /> Rename
                </DropdownItem>
                <DropdownItem
                  disabled={depth === 0}
                  onClick={() => onRemove(pathToNode, node)}
                >
                  <Icon icon="trash-alt" className="mx-1" /> Remove
                </DropdownItem>
                {!node.isRoot && (
                  <DropdownItem onClick={() => onPromoteToOrg(node)}>
                    <Icon icon="sitemap" className="mx-1" /> Promote to
                    organization
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              size="sm"
              isOpen={showAddMenu}
              toggle={() => setShowAddMenu(!showAddMenu)}
            >
              <DropdownToggle
                className="btn btn-link"
                data-test={`node-add-btn`}
              >
                <Icon icon={['fas', 'plus']} />
              </DropdownToggle>
              <DropdownMenu right={windowDims.width <= 600}>
                <DropdownItem
                  data-test="add-group-btn"
                  onClick={() => onAddGroup(node)}
                >
                  <Icon
                    className="folder-icon mx-1"
                    icon="user-friends"
                    mask="folder"
                    transform="shrink-9 down-1"
                  />
                  <span style={{ position: 'relative', bottom: '2px' }}>
                    {' '}
                    Group
                  </span>
                </DropdownItem>
                <DropdownItem onClick={() => onAddOrgRef(node)}>
                  <Icon icon="sitemap" className="mx-1 org-icon" /> Organization
                </DropdownItem>
                <DropdownItem onClick={() => onAddTeams(node)}>
                  <Icon
                    icon="users"
                    data-test={`add-teams-btn`}
                    className="team-icon mx-1"
                  />{' '}
                  Team(s)
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
      {open && <>{childNodes}</>}
    </div>
  );
}

export default OrgNode;
