import React from 'react';
import { Icon, PanelHeader } from 'js/components';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { Button } from 'reactstrap';

const StripeBillingComponent = ({
  account,
  product,
  onCreateBillingPortalSession,
}) => {
  function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  return (
    <div>
      <PanelHeader size="flex" />
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Account Billing</CardTitle>
            <div className="account-settings-container">
              <Icon
                icon={['fab', 'cc-stripe']}
                size="3x"
                className="mr-3 text-muted"
                fixedWidth
                data-test="stripe-icon"
              />{' '}
              <div>
                <div className="account-settings">
                  <div className="label">Billing method:</div>
                  <div className="value" data-test="billing-method-value">
                    {capitalize(account?.billing_type)} (credit card)
                  </div>
                </div>
                <div className="account-settings">
                  <div className="label">Product:</div>
                  <div className="value">{product?.name}</div>
                </div>
                <div className="account-settings">
                  <div className="label">Price:</div>
                  {product ? (
                    <div className="value" data-test="price-value">
                      ${product?.price} per {product?.interval}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <Button
              color="link"
              data-test="stripe-settings-button"
              onClick={onCreateBillingPortalSession}
            >
              Manage your Stripe billing settings
            </Button>
          </CardHeader>
          <CardBody></CardBody>
        </Card>
      </div>
    </div>
  );
};

export default StripeBillingComponent;
