import React from 'react';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { useNotify } from 'js/hooks/useNotify';
import { Link } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { Icon, IconStack } from 'js/components';
import ParticipationStats from './ParticipationStats';
import DropdownFilter from 'js/components/Filter/DropdownFilter';
import { useCallback } from 'react';
import { isArchived } from 'js/utils/team-utils';
import { faCopy, faPoll } from '@fortawesome/pro-regular-svg-icons';
import { faCircle, faRedoAlt } from '@fortawesome/pro-solid-svg-icons';

const TeamName = ({ teamName }) => (
  <>
    <label>Team</label>
    <h2>{teamName} </h2>
  </>
);

const ReviewHeader = ({
  name,
  date,
  team,
  assessmentKey,
  children,
  invites,
  assessment,
  cohorts = [],
  showParticipationStats = false,
  onFilterChange = () => {},
}) => {
  const teamName = (team || {}).name;
  const handleFilterChange = useCallback(
    (cohortsFilter) =>
      onFilterChange({
        cohorts: cohortsFilter,
      }),
    [onFilterChange]
  );

  const notify = useNotify();
  const handleCopyLink = () => {
    const url = `${window.location.origin}/assessments/${assessment.id}/run`;

    copy(url);

    notify({ type: 'success', message: 'Link copied to clipboard' });
  };

  const teamIsArchived = isArchived(team);
  const dropdownProps = teamIsArchived ? { disabled: true, isOpen: false } : {};

  return (
    <div className="panel-content assessment-review-header">
      <section className="details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12"></div>
          </div>
          <div className="row">
            <div className="col-12 col-md-9">
              <label>Assessment</label>
              <h1>
                {name}
                &nbsp;
              </h1>
              {date ? (
                <div className="timestamp">
                  {moment(date).format('MMM D, YYYY h:mm a')}
                </div>
              ) : null}
              {showParticipationStats ? null : <TeamName teamName={teamName} />}
            </div>
            <div className="col-12 col-md-3">
              <div className="actions">
                {assessment ? (
                  <UncontrolledDropdown
                    {...dropdownProps}
                    disabled={teamIsArchived}
                  >
                    <DropdownToggle
                      color="primary"
                      caret
                      disabled={teamIsArchived}
                    >
                      Actions
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        onClick={handleCopyLink}
                        disabled={teamIsArchived}
                      >
                        <Icon
                          icon={faCopy}
                          size="lg"
                          className="btn-icon-left"
                          fixedWidth
                        />
                        Copy link for participants
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to={`/w/${team.workspace_id}/teams/${team.id}/assessments/${assessmentKey}/new`}
                        disabled={teamIsArchived}
                      >
                        <IconStack
                          size="lg"
                          className="btn-icon-left"
                          fixedWidth
                        >
                          <Icon
                            icon={faCircle}
                            mask={faPoll}
                            transform="shrink-4 right-6 down-6"
                          />
                          <Icon
                            icon={faRedoAlt}
                            transform="shrink-7 right-6 down-6"
                          />
                        </IconStack>
                        Run this assessment again
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : (
                  <Link
                    className="btn btn-primary"
                    to={`/w/${team.workspace_id}/teams/${team.id}/assessments/${assessmentKey}/new`}
                    disabled={isArchived(team)}
                  >
                    <Icon
                      icon={['far', 'poll']}
                      size="lg"
                      className="btn-icon-left"
                    />
                    Assess
                  </Link>
                )}
              </div>
            </div>
          </div>
          {!showParticipationStats ? null : (
            <div className="row">
              <div className="col-12 col-md-9">
                <TeamName teamName={teamName} />
                {cohorts.length <= 1 ? null : (
                  <div className="data-filter">
                    <DropdownFilter
                      name="Cohorts"
                      filterOptions={cohorts}
                      onChange={handleFilterChange}
                    />
                  </div>
                )}
              </div>
              <div className="col-12 col-md-3">
                <ParticipationStats assessment={assessment} invites={invites} />
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="aggregate-history panel-chart container-fluid">
        {children}
      </section>
    </div>
  );
};

export default ReviewHeader;
