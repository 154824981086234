import React from 'react';

import { PanelHeader } from 'js/components';
import ReviewChart from './ReviewChart';

import AllAnsweredMessage from './AllAnsweredMessage';
import MissingResponsesMessage from './MissingResponsesMessage';

const AssessmentReview = ({ assessment, participant }) => {
  const participantId = participant.id;
  const allAnswered = assessment.question_sets.every((qs) =>
    qs.questions.every((q) =>
      q.responses.some((r) => r?.participant_id === participantId)
    )
  );

  return (
    <div>
      <PanelHeader size="flex">
        <div className="survey-assessment-review-chart">
          <ReviewChart assessment={assessment} participantId={participantId} />
        </div>
      </PanelHeader>
      <div className="content">
        <div className="assessment survey-assessment-review">
          {allAnswered ? <AllAnsweredMessage /> : <MissingResponsesMessage />}Î
        </div>
      </div>
    </div>
  );
};

export default AssessmentReview;
