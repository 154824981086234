import React, { useCallback, useMemo, useState } from 'react';
import OrgNode from 'js/routes/Organizations/components/ManageOrg/OrgTreeVeiw/OrgNode';
import { descendentsOfNode, pathToChild } from 'js/utils/org-utils';
import AddTeamsFormDialog from 'js/routes/Organizations/components/ManageOrg/OrgTreeVeiw/AddTeamsFormDialog';
import AddOrgFormDialog from 'js/routes/Organizations/components/ManageOrg/OrgTreeVeiw/AddOrgFormDialog';
import AddOrgRefFormDialog from 'js/routes/Organizations/components/ManageOrg/OrgTreeVeiw/AddOrgRefFormDialog';
import RemoveNodeConfirmDialog from 'js/routes/Organizations/components/ManageOrg/OrgTreeVeiw/RemoveNodeConfirmDialog';
import ConfirmModal from 'js/components/modal/ConfirmModal';

function nearestIntermediateRootAncestorToNode(tree, node) {
  if (!tree || !node) {
    return undefined;
  }

  const roots = pathToChild(tree, node.id)
    .slice(1)
    .filter((n) => n.isRoot);

  return roots[roots.length - 1];
}

function OrgTreeView({
  orgTree,
  teams,
  rootOrgs,
  onAddTeamsToOrg,
  onAddGroup,
  onAddOrgRef,
  onRemoveNode,
  onRename,
  onPromoteToOrg,
}) {
  const [expandedNodeIds, setExpandedNodeIds] = useState([orgTree.depthId]);

  const handleExpand = useCallback(
    (node, cascade = false) => {
      const newlyExpanded = cascade
        ? [
            node.depthId,
            ...descendentsOfNode(node)
              .filter((n) => n.type === 'org')
              .map((n) => n.depthId),
          ]
        : [node.depthId];
      setExpandedNodeIds([...expandedNodeIds, ...newlyExpanded]);
    },
    [expandedNodeIds, setExpandedNodeIds]
  );

  const handleCollapse = useCallback(
    (node, cascade = false) => {
      const newlyCollapsed = cascade
        ? [
            node.depthId,
            ...descendentsOfNode(node)
              .filter((n) => n.type === 'org')
              .map((n) => n.depthId),
          ]
        : [node.depthId];
      setExpandedNodeIds(
        expandedNodeIds.filter((i) => !newlyCollapsed.includes(i))
      );
    },
    [expandedNodeIds, setExpandedNodeIds]
  );

  const [showAddTeamsForm, setShowAddTeamsForm] = useState(false);
  const [parentForAddTeams, setParentForAddTeams] = useState();
  const intermediateRootForAddTeams = useMemo(() => {
    return nearestIntermediateRootAncestorToNode(orgTree, parentForAddTeams);
  }, [orgTree, parentForAddTeams]);

  const [showAddGroupForm, setShowAddGroupForm] = useState(false);
  const [parentForAddGroup, setParentForAddGroup] = useState();
  const intermediateRootForAddGroup = useMemo(() => {
    return nearestIntermediateRootAncestorToNode(orgTree, parentForAddGroup);
  }, [orgTree, parentForAddGroup]);

  const [showAddOrgRefForm, setShowAddOrgRefForm] = useState(false);
  const [parentForAddOrgRef, setParentForAddOrgRef] = useState();
  const intermediateRootForAddOrgRef = useMemo(() => {
    return nearestIntermediateRootAncestorToNode(orgTree, parentForAddOrgRef);
  }, [orgTree, parentForAddOrgRef]);

  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [toRemove, setToRemove] = useState({ path: null, nodeToRemove: null });

  const [showPromoteToOrgConfirm, setShowPromoteToOrgConfirm] = useState(false);
  const [nodeToPromoteToOrg, setNodeToPromoteToOrg] = useState();

  const handleAddTeams = useCallback(
    (parentNode) => {
      setShowAddTeamsForm(true);
      setParentForAddTeams(parentNode);
    },
    [setShowAddTeamsForm, setParentForAddTeams]
  );

  const handleConfirmAddTeams = useCallback(
    (teamIds) => {
      return onAddTeamsToOrg(parentForAddTeams, teamIds).then(() => {
        setShowAddTeamsForm(false);
        handleExpand(parentForAddTeams, false);
      });
    },
    [onAddTeamsToOrg, setShowAddTeamsForm, parentForAddTeams, handleExpand]
  );

  const handleAddGroup = useCallback(
    (parentNode) => {
      setShowAddGroupForm(true);
      setParentForAddGroup(parentNode);
    },
    [setShowAddGroupForm, setParentForAddGroup]
  );

  const handleConfirmAddGroup = useCallback(
    (group) => {
      return Promise.resolve(onAddGroup(parentForAddGroup, group)).then(() => {
        setShowAddGroupForm(false);
        handleExpand(parentForAddGroup, false);
      });
    },
    [setShowAddGroupForm, parentForAddGroup, onAddGroup, handleExpand]
  );

  const handleAddOrgRef = useCallback(
    (parentNode) => {
      setShowAddOrgRefForm(true);
      setParentForAddOrgRef(parentNode);
    },
    [setShowAddOrgRefForm, setParentForAddOrgRef]
  );

  const handleConfirmAddOrgRef = useCallback(
    (orgId) => {
      return Promise.resolve(onAddOrgRef(parentForAddOrgRef, orgId)).then(
        () => {
          setShowAddOrgRefForm(false);
          handleExpand(parentForAddOrgRef, false);
        }
      );
    },
    [setShowAddOrgRefForm, parentForAddOrgRef, onAddOrgRef, handleExpand]
  );

  const handleRemove = useCallback(
    (path, nodeToRemove) => {
      setShowRemoveConfirm(true);
      setToRemove({ path, nodeToRemove });
    },
    [setShowRemoveConfirm, setToRemove]
  );

  const handleConfirmRemove = useCallback(() => {
    return Promise.resolve(
      onRemoveNode(
        toRemove.path[toRemove.path.length - 1],
        toRemove.nodeToRemove
      )
    ).then(() => setShowRemoveConfirm(false));
  }, [setShowRemoveConfirm, onRemoveNode, toRemove]);

  const handlePromoteToOrg = useCallback(
    (node) => {
      setNodeToPromoteToOrg(node);
      setShowPromoteToOrgConfirm(true);
    },
    [setShowPromoteToOrgConfirm, setNodeToPromoteToOrg]
  );

  const handleConfirmPromoteToOrg = useCallback(() => {
    return Promise.resolve(onPromoteToOrg(nodeToPromoteToOrg)).finally(() =>
      setShowPromoteToOrgConfirm(false)
    );
  }, [onPromoteToOrg, nodeToPromoteToOrg, setShowPromoteToOrgConfirm]);

  return (
    <div className="org-mgmt-tree mb-3">
      <OrgNode
        node={orgTree}
        expandedNodeIds={expandedNodeIds}
        onExpand={handleExpand}
        onCollapse={handleCollapse}
        onAddTeams={handleAddTeams}
        onAddGroup={handleAddGroup}
        onAddOrgRef={handleAddOrgRef}
        onRemove={handleRemove}
        onRename={onRename}
        onPromoteToOrg={handlePromoteToOrg}
      />
      <AddTeamsFormDialog
        isOpen={showAddTeamsForm}
        parentNode={parentForAddTeams}
        teams={teams}
        onCancel={() => setShowAddTeamsForm(false)}
        onConfirm={handleConfirmAddTeams}
        intermediateRoot={intermediateRootForAddTeams}
      />

      <AddOrgFormDialog
        isOpen={showAddGroupForm}
        parentNode={parentForAddGroup}
        onCancel={() => setShowAddGroupForm(false)}
        onConfirm={handleConfirmAddGroup}
        intermediateRoot={intermediateRootForAddGroup}
      />

      <AddOrgRefFormDialog
        isOpen={showAddOrgRefForm}
        parentNode={parentForAddOrgRef}
        orgTree={orgTree}
        rootOrgs={rootOrgs}
        onCancel={() => setShowAddOrgRefForm(false)}
        onConfirm={handleConfirmAddOrgRef}
        intermediateRoot={intermediateRootForAddOrgRef}
      />

      <RemoveNodeConfirmDialog
        nodeToRemove={toRemove?.nodeToRemove}
        path={toRemove?.path}
        onConfirm={handleConfirmRemove}
        onCancel={() => setShowRemoveConfirm(false)}
        isOpen={showRemoveConfirm}
      />

      <ConfirmModal
        onCancel={() => setShowPromoteToOrgConfirm(false)}
        onConfirm={handleConfirmPromoteToOrg}
        isOpen={showPromoteToOrgConfirm}
        confirmButtonLabel="Promote to Organization"
        title="Promote Group?"
      >
        <p>
          Confirm that you want to promote{' '}
          <strong>{nodeToPromoteToOrg?.name}</strong> to an organization.
        </p>
        <p>
          Organizations are available directly from the organizations page and
          can be added to other organizations and groups.
        </p>
        <p>This action cannot be undone.</p>
      </ConfirmModal>
    </div>
  );
}
export default OrgTreeView;
