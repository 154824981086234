import React from 'react';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { SCHEMAS } from 'js/api';

import { logout } from 'js/actions/account-actions';
import { PagesHeader, Footer } from 'js/components';

//const dashboardRoutes = [];

const Assessment = props => {
  const handleLogout = () => {
    const { logout, history } = props;

    history.push('/');
    logout();
  };

  const { component: Component, children, ...componentProps } = props;

  return (
    <div className="wrapper  assessment-layout">
      <PagesHeader {...props} onLogout={handleLogout} />
      <div className="wrapper wrapper-assessment">
        <div className="main-panel">
          {Component ? <Component {...componentProps} /> : null}
          {children}
          <Footer fluid />
        </div>
      </div>
    </div>
  );
};

export default connect(
  state => {
    const { entities } = state;

    return {
      me: denormalize(state.identity.me, SCHEMAS.ME, entities),
      identity: state.identity,
      currentWorkspaceId: state.currentWorkspaceId
    };
  },
  { logout }
)(Assessment);
