import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { resetPassword, verifyResetToken } from 'js/actions/account-actions';

import PasswordResetRouteComponent from '../components/PasswordResetRouteComponent';

const PasswordResetRouteContainer = ({
  resetPassword,
  verifyResetToken,
  history,
  match: {
    params: { resetKey }
  }
}) => {
  const [tokenVerified, setTokenVerified] = useState(null);

  useEffect(
    () => {
      verifyResetToken(resetKey)
        .then(() => setTokenVerified(true))
        .catch(() => setTokenVerified(false));
    },
    [resetKey, verifyResetToken, setTokenVerified]
  );

  const handleReset = useCallback(
    async ({ password }) => {
      try {
        await resetPassword(resetKey, password);
        history.push('/dashboard');
      } catch {
        setTokenVerified(false);
      }
    },
    [resetKey, resetPassword, history, setTokenVerified]
  );

  return (
    <PasswordResetRouteComponent
      onReset={handleReset}
      tokenVerified={tokenVerified}
    />
  );
};

export default connect(
  null,
  {
    resetPassword,
    verifyResetToken
  }
)(PasswordResetRouteContainer);
