import React, { useCallback, useEffect } from 'react';
import { noop } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { list as listAssessmentTemplates } from 'js/actions/assessment-tempate-actions';
import { getEngineStrategies } from 'js/engines';
import {
  QueryBase,
  selectAssessmentTemplatesQuery,
} from 'js/store/reducers/queries';

import CreateAssessmentsComponent from '../components/CreateAssessmentsComponent';

import templates from 'js/data/assessment-templates';

function CreateAssessmentsContainer({
  match,
  history,
  createAssessment,
  listAssessmentTemplates,
  templates,
  queryId,
}) {
  const { teamId } = match.params;

  const fetchAssessmentTemplates = useCallback(() => {
    listAssessmentTemplates(queryId).catch(noop);
  }, [queryId, listAssessmentTemplates]);

  useEffect(() => fetchAssessmentTemplates(), [fetchAssessmentTemplates]);

  const handleCreateAssessment = useCallback(
    (template) => {
      // const { match, createAssessment, history } = this.props;

      createAssessment({
        type: template.type,
        ...template.assessment,
        team_id: teamId,
      }).then((assessment) => {
        const strategies = getEngineStrategies(assessment);
        history.replace(strategies.buildPostCreateRedirectPath(assessment));
      });
    },
    [teamId, history, createAssessment]
  );

  return (
    <CreateAssessmentsComponent
      templates={templates}
      onCreateAssessment={handleCreateAssessment}
    />
  );
}

export default withRouter(
  connect(
    (
      store,
      {
        match: {
          params: { workspaceId },
        },
      }
    ) => {
      const localIds = selectAssessmentTemplatesQuery(
        store,
        workspaceId
      ).data.map((at) => at.local_id);
      return {
        queryId: `${QueryBase.AssessmentTemplates}-${workspaceId}`,
        templates: templates.filter(
          (x) => x.is_public || localIds.indexOf(x.id) > -1
        ),
      };
    },
    {
      createAssessment: (assessment) => {
        const { create } = getEngineStrategies(assessment);

        return create(assessment);
      },
      listAssessmentTemplates,
    }
  )(CreateAssessmentsContainer)
);
