import React from 'react';
import { AsyncDisplay, LoadingMessage } from 'js/components';
import { Redirect, Route, Switch } from 'react-router';
import OrgRecommendations from './Visualizations/OrgRecommendations';
import SquadScorecard from './Visualizations/SquadScorecard';

function OrgComponentDetailRouter({
  questionScoresQuery,
  match,
  organization,
  workspace,
}) {
  return (
    <AsyncDisplay
      isLoading={questionScoresQuery.query?.isLoading ?? true}
      isError={!!questionScoresQuery.query?.error}
    >
      <AsyncDisplay.Loading>
        <LoadingMessage iconSize="2x">Loading &hellip;</LoadingMessage>
      </AsyncDisplay.Loading>
      <AsyncDisplay.Error>
        <div>Sorry, there was a problem loading the details.</div>
      </AsyncDisplay.Error>
      <AsyncDisplay.Resolved>
        <Switch>
          <Route
            path={`${match.path}/recommendations`}
            exact
            render={() => (
              <OrgRecommendations
                workspace={workspace}
                organization={organization}
                questionScores={questionScoresQuery.data}
                assessmentKey={match.params.assessmentKey}
              />
            )}
          />
          <Route
            path={`${match.path}/squad-scorecard`}
            exact
            render={() => (
              <SquadScorecard
                workspace={workspace}
                organization={organization}
                questionScores={questionScoresQuery.data}
                assessmentKey={match.params.assessmentKey}
              />
            )}
          />
          <Route
            path={match.path}
            exact
            render={() => <Redirect to={`${match.url}/recommendations`} />}
          />
        </Switch>
      </AsyncDisplay.Resolved>
    </AsyncDisplay>
  );
}

export default OrgComponentDetailRouter;
