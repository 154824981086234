import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Icon } from 'js/components';

import styles from './ProfileDropdown.module.scss';
import NavMenuItems from './NavMenuItems';

const ProfileDropdown = ({ profile, onLogout = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle className="nav-link" color="nav">
        <Icon icon="user" size="lg" fixedWidth />
      </DropdownToggle>
      <DropdownMenu right>
        <div className={styles.menu}>
          <div className={styles.profile}>
            <div className={styles.avatar}>
              <Icon icon="user" size="lg" />
            </div>
            <div className={styles.name}>{profile.display_name}</div>
            <div className={styles.username}>{profile.username}</div>
          </div>
          <div className={styles.actions}>
            <NavMenuItems
              profile={profile}
              onLogout={onLogout}
              itemTag={DropdownItem}
              useLogoutButton={false}
            />
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
