import React, { useMemo } from 'react';
import { orderBy } from 'lodash';
import { PanelHeader, Icon } from 'js/components';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import AccountUserList from 'js/components/User/AccountUserList';

const AccountsUsersComponent = ({
  users,
  account,
  me,
  onResendInvite,
  onRemoveUser,
}) => {
  const sortedUsers = useMemo(() => orderBy(users, ['email']), [users]);

  const componentProps = {
    users: sortedUsers,
    account,
    me,
    onResendInvite,
    onRemoveUser,
  };

  return (
    <div>
      <PanelHeader size="flex" />
      <div className="content">
        <Card>
          <CardHeader>
            <div className="action-items">
              <Link
                data-test="add-user-btn"
                className="btn btn-primary float-right create-link"
                to={`/a/${account?.id}/users/invite`}
              >
                <Icon icon="plus" className="btn-icon-left" />
                Invite User
              </Link>
            </div>
            <CardTitle tag="h5">Account Users</CardTitle>
          </CardHeader>
          <CardBody>
            <AccountUserList {...componentProps} />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AccountsUsersComponent;
