import React from 'react';
import { Icon } from 'js/components';
import ReactMarkdown from 'react-markdown';
import { Button, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import AssessmentTypeGlyph from './AssessmentTypeGlyph';

const renderType = (template) => {
  switch (template.type) {
    case 'live-assessment':
    case 'live':
      // prettier-ignore
      return (
        <>
          <div className={`assessment-template-image ${template.type}`}>
            <AssessmentTypeGlyph type={template.type} />
          </div>
          <div className="assessment-template-type-name">
            Live
          </div>
        </>
      );

    case 'survey':
      // prettier-ignore
      return (
          <>
            <div className={`assessment-template-image ${template.type}`}>
              <AssessmentTypeGlyph type={template.type} />
            </div>
            <div className="assessment-template-type-name">
              Survey
            </div>
          </>
        );

    default:
    // passthrough
  }
};

const CreateAssessmentTemplate = ({ template, onSelect }) => {
  const { name, author, description } = template;

  const typeComponent = renderType(template);

  return (
    <Card className="assessment-template">
      <CardBody>
        <div className="assessment-template-content">
          <div className="action-items d-none d-sm-block">
            <Link to={`${template.key}/new`} className="btn btn-primary">
              {template.configurable ? (
                <span>
                  Configure
                  <Icon
                    className="btn-icon-right"
                    icon="arrow-right"
                    size="lg"
                  />
                </span>
              ) : (
                <span>
                  <Icon
                    className="btn-icon-left"
                    icon={['far', 'poll']}
                    size="lg"
                  />
                  Start
                </span>
              )}
            </Link>
          </div>
          <div className="assessment-template-type d-none d-sm-block">
            {typeComponent}
          </div>
          <div className="assessment-template-details">
            <div className="assessment-template-type d-block d-sm-none">
              {typeComponent}
            </div>
            <h3 className="assessment-template-name">{name}</h3>
            <label>Created By</label>
            <p className="assessment-template-author">{author}</p>
            <label>Description</label>
            <div className="assessment-template-description">
              <ReactMarkdown source={description} />
            </div>
          </div>
        </div>
        <div className="action-items mobile d-block d-sm-none">
          <Button color="primary" size="lg" block>
            Continue <Icon className="btn-icon-right" icon="arrow-right" />
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

CreateAssessmentTemplate.defaultProps = {
  onSelect: () => {},
};

export default CreateAssessmentTemplate;
