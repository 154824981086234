import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconStack, Icon } from 'js/components';

import { faPlay } from '@fortawesome/pro-solid-svg-icons';

const OverviewNav = (props) => {
  const { assessment } = props;

  return (
    <nav className="navbar fixed-bottom navbar-expand-lg bottom-bar">
      <ul className="navbar-nav navbar-expand mr-auto">
        <li className="nav-item">
          <Link to={`/assessments/${assessment.id}/host`}>
            <IconStack size="2x">
              <Icon icon={['far', 'square']} />
              <Icon icon={faPlay} transform="shrink-8" />
            </IconStack>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

OverviewNav.propTypes = {
  assessment: PropTypes.object.isRequired,
};

export default OverviewNav;
