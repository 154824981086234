import React from 'react';
import { useCombobox } from 'downshift';
import classNames from 'classnames';
import { Icon } from 'js/components';

const standardRoles = [
  'Architect',
  'Business Analyst',
  'Designer',
  'Developer',
  'DevOps Engineer',
  'Product Owner',
  'Scrum Master',
  'Security Engineer',
  'Tech Lead',
];

function getRolesFilter(inputValue) {
  inputValue = inputValue.toLowerCase();

  return function rolesFilter(role) {
    return !inputValue || role.toLowerCase().includes(inputValue);
  };
}

function Role({ placeholder = '(Role)', onChange = () => {}, value }) {
  const [items, setItems] = React.useState(standardRoles);
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      setItems(standardRoles.filter(getRolesFilter(inputValue)));
      onChange(inputValue);
    },
    items,
    itemToString(item) {
      return item.length ? item : '';
    },
    inputValue: value ?? '',
  });

  return (
    <div className="combobox">
      <div className="input-group">
        <input
          placeholder={placeholder}
          className="form-control"
          {...getInputProps()}
        />
        <div className="input-group-append">
          <span
            aria-label="toggle menu"
            className=""
            type="button"
            {...getToggleButtonProps()}
          >
            {isOpen ? (
              <Icon icon={['far', 'chevron-up']} fixedWidth />
            ) : (
              <Icon icon={['far', 'chevron-down']} fixedWidth />
            )}
          </span>
        </div>
      </div>
      <ul
        {...getMenuProps()}
        className="combobox-items"
        style={!isOpen ? { display: 'none' } : {}}
      >
        {isOpen &&
          items.map((item, index) => (
            <li
              className={classNames({
                highlighted: highlightedIndex === index,
                selected: selectedItem === item,
                'combobox-item': true,
              })}
              key={`${item.value}${index}`}
              {...getItemProps({ item, index })}
            >
              <span>{item}</span>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Role;
