import React from 'react';
import { flatMap } from 'lodash';
import { generateDomain } from 'js/utils/color';
import { scaleColorTraffic as color } from 'js/utils/color';
import { scaleLinear } from '@visx/scale';

import styles from './AssessmentDetailPane.module.scss';

export const QuestionScores = ({
  lollipopAssessment,
  detailedBarWidth = 3,
  detailedBarHeight = 40,
  detailedSpotRadius = 3,
  detailedBarGap = 3,
}) => {
  const questions = flatMap(lollipopAssessment?.questionSets, (qs) =>
    Object.values(qs.questions).map((q) => ({
      ...q,
      questionKey: q.key,
      questionSetKey: qs.key,
    }))
  );

  const width = Math.max(detailedBarWidth, detailedSpotRadius * 2);
  const height = detailedBarHeight + detailedSpotRadius * 2 + detailedBarGap;
  const barOffsetX = (width - detailedBarWidth) / 2;
  const spotOffsetX = width / 2 - detailedSpotRadius;

  return (
    <div>
      <div className={styles['question-scores']}>
        {questions.map((q) => {
          const questionColor = color
            .copy()
            .domain(generateDomain(q.answerDomain, color.range()));

          const totalResponses = q.responseCount + q.naCount;
          const h =
            totalResponses === 0
              ? () => detailedBarHeight
              : scaleLinear({
                  domain: [0, totalResponses],
                  range: [0, detailedBarHeight],
                });

          const { responseBars } = Object.entries(q.responseTotals)
            .sort(([aVal, _aCount], [bVal, _bCount]) => aVal - bVal)
            .reduce(
              (acc, [responseVal, responseCount]) => {
                if (responseCount === 0) {
                  return acc;
                }
                const runningCount = acc.runningCount + responseCount;
                return {
                  responseBars: [
                    <rect
                      key={`q-${q.id}-r-${responseVal}`}
                      width={detailedBarWidth}
                      height={h(runningCount)}
                      fill={questionColor(responseVal)}
                    />,
                    ...acc.responseBars,
                  ],
                  runningCount,
                };
              },
              { responseBars: [], runningCount: 0 }
            );

          const getFill = () => {
            return q.responseCount > 0 ? color(q.normalizedScore) : {};
          };

          return (
            <svg
              key={q.id}
              width={width}
              height={height}
              viewBox={`0 0 ${width} ${height}`}
            >
              <circle
                className={q.responseCount === 0 ? 'vote-none' : null}
                cx={spotOffsetX + detailedSpotRadius}
                cy={height - detailedSpotRadius}
                r={detailedSpotRadius}
                fill={getFill()}
              />
              <g transform={`translate(${barOffsetX},0)`}>
                <rect
                  width={detailedBarWidth}
                  height={h(q.responseCount + q.naCount)}
                  className="vote-none"
                />
                {responseBars}
              </g>
            </svg>
          );
        })}
      </div>
    </div>
  );
};

export default QuestionScores;
