import React from 'react';
import classnames from 'classnames';

const NullResults = ({
  question,
  filter,
  participantCohortMap = {},
  tag: Tag = 'article',
  className,
  width,
  height,
  margin,
  ...props
}) => {
  return (
    <Tag className={classnames(className, 'question-results')} {...props}>
      <div className="question-results-wrapper">
        <div className="question-text">
          <p>{question.text}</p>
        </div>
      </div>
    </Tag>
  );
};

export default NullResults;
