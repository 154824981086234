import keymirror from 'keymirror';
import { request } from 'js/api/axios';

import { spreadApiKeys, extractApiKeys } from '../utils';
import { callApi } from '../api';
import { SCHEMAS } from '../api/schemas';

export const types = keymirror({
  ...spreadApiKeys('LOGIN'),
  ...spreadApiKeys('LOAD_ME'),
  ...spreadApiKeys('LOGOUT'),
  ...spreadApiKeys('REGISTER'),
  ...spreadApiKeys('UPGRADE_GUEST'),
  ...spreadApiKeys('VERIFY_ACCOUNT'),
  ...spreadApiKeys('RESEND_VERIFICATION'),
  ...spreadApiKeys('VERIFY_RESET_TOKEN'),
  ...spreadApiKeys('SEND_PASSWORD_RESET'),
  ...spreadApiKeys('RESET_PASSWORD'),
  ...spreadApiKeys('CANCEL_VERIFICATION'),
  ...spreadApiKeys('LOAD_VERIFICATIONS'),
  ...spreadApiKeys('UPDATE_ACCOUNT'),
  ...spreadApiKeys('CREATE_BILLING_PORTAL_SESSION'),
});

export function login(username, password) {
  return (dispatch, getState) => {
    const data = { user: { username, password } };

    return callApi(
      {
        types: extractApiKeys('LOGIN', types),
        request: () => request.post('/api/auth/identity/callback', data),
        data,
      },
      dispatch
    ).then(() => {
      return me()(dispatch, getState);
    });
  };
}

export function loginAsGuest(displayName) {
  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys('LOGIN', types),
        request: (data) => request.post('/api/auth/register/guest', data),
        data: { display_name: displayName },
      },
      dispatch
    ).then(() => {
      return me()(dispatch, getState);
    });
  };
}

export function logout() {
  return (dispatch, getState) => {
    const token = getState().identity.token;

    return callApi(
      {
        types: extractApiKeys(types.LOGOUT, types),
        request: () =>
          request.post('/api/auth/identity/revoke', null, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
      },
      dispatch
    );
  };
}

export function register(user, team) {
  const data = {
    user: {
      username: user.username,
      display_name: user.display_name,
      password: user.password,
    },
  };

  if (!!team && (team.name || '').trim().length > 0) {
    data.team = {
      name: team.name.trim(),
    };
  }

  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys('REGISTER', types),
        request: () => request.post('/api/auth/register', data),
        data,
      },
      dispatch
    ).then(() => {
      return me()(dispatch, getState);
    });
  };
}

export function upgrade(patch) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.UPGRADE_GUEST, types),
        request: (data, token) =>
          request.post('/api/auth/identity/upgrade', data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { user: patch },
        schema: SCHEMAS.ME,
      },
      dispatch
    );
  };
}

export function me() {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LOAD_ME', types),
        request: (x, token) =>
          request.get('/api/auth/identity/me', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        schema: SCHEMAS.ME,
      },
      dispatch
    ).catch((e) => {
      if (e?.response?.status === 401) {
        console.warn(
          'cannot load user info because session is not authenticated'
        );
      } else {
        return Promise.reject(e);
      }
    });
  };
}

// export function me() {
//   return (dispatch) => {
//     return callApi(
//       {
//         types: extractApiKeys('LOAD_ME', types),
//         request: (x, token) =>
//           request
//             .get('//localhost:4000/api/auth/identity/me')
//             .set('Authorization', `Bearer ${token}`),
//         schema: SCHEMAS.ME,
//       },
//       dispatch
//     ).catch((e) => {
//       if (e.status === 401) {
//         console.log(
//           'cannot load user info because session is not authenticated'
//         );
//       } else {
//         return Promise.reject(e);
//       }
//     });
//   };
// }

export function verifyAccount(key) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.VERIFY_ACCOUNT, types),
        request: () => request.get(`/api/auth/identity/verify/${key}`),
      },
      dispatch
    );
  };
}

export function updateAccount(accountName, accountId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.UPDATE_ACCOUNT, types),
        request: (data, token) =>
          request.patch(`/api/accounts/${accountId}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { account: { name: accountName, id: accountId } },
        schema: SCHEMAS.ACCOUNT,
      },
      dispatch
    );
  };
}

export function resendVerification(email) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.RESEND_VERIFICATION, types),
        request: (data) => request.post(`/api/auth/identity/verify`, data),
        data: { email },
      },
      dispatch
    );
  };
}

export function sendPasswordReset(email) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.SEND_PASSWORD_RESET, types),
        request: (data) =>
          request.post('/api/auth/identity/passwordreset', data),
        data: { email },
      },
      dispatch
    );
  };
}

export function verifyResetToken(token) {
  return async (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.VERIFY_RESET_TOKEN, types),
        request: () => request.get(`/api/auth/identity/passwordreset/${token}`),
      },
      dispatch
    );
  };
}

export function resetPassword(token, password) {
  return async (dispatch, getState) => {
    try {
      const result = await callApi(
        {
          types: extractApiKeys(types.RESET_PASSWORD, types),
          request: (data) =>
            request.post(`/api/auth/identity/passwordreset/${token}`, data),
          data: { password },
        },
        dispatch
      );
      await me()(dispatch, getState);

      return result;
    } catch (error) {
      throw error;
    }
  };
}

export function cancelEmailChange(email) {
  return async (dispatch, getState) => {
    const result = await callApi(
      {
        types: extractApiKeys(types.CANCEL_VERIFICATION, types),
        request: (data, token) =>
          request.delete(`/api/auth/identity/me/verifications`, {
            data,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { email },
      },
      dispatch
    );
    await listVerifications()(dispatch, getState);
    return result;
  };
}

export function listVerifications() {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_VERIFICATIONS, types),
        request: (_data, token) =>
          request.get('/api/auth/identity/me/verifications', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
      },
      dispatch
    );
  };
}

export function createBillingPortalSession(accountId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_BILLING_PORTAL_SESSION, types),
        request: (data, token) =>
          request.post(
            `/api/accounts/${accountId}/billing-portal-session-request`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: {
          return_url: window.location.href,
        },
      },
      dispatch
    );
  };
}
