import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { PanelHeader, Icon, AsyncDisplay, LoadingMessage } from 'js/components';
import OrgList from './OrgList';
import AddOrgFormDialog from 'js/routes/Organizations/components/ManageOrg/OrgTreeVeiw/AddOrgFormDialog';
import { noop } from 'lodash';
import ConfirmModal from 'js/components/modal/ConfirmModal';
import { Card, CardBody } from 'reactstrap';
import OrgTeaser from 'js/routes/Organizations/components/OrgTeaser';
import { allowOrgs } from 'js/utils/entitlement-utils';

const OrgsComponent = ({
  workspaceId,
  organizationsQueryResult,
  onCreateOrg,
  onDeleteOrg,
  activeAccount,
}) => {
  const [showCreateForm, setShowCreateForm] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState();

  const handleDelete = useCallback(
    (org) => {
      setOrgToDelete(org);
      setShowDeleteModal(true);
    },
    [setOrgToDelete, setShowDeleteModal]
  );

  const confirmDelete = useCallback(() => {
    return Promise.resolve(onDeleteOrg(orgToDelete)).then(() =>
      setShowDeleteModal(false)
    );
  }, [onDeleteOrg, setShowDeleteModal, orgToDelete]);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const isLoading = useMemo(() => {
    return organizationsQueryResult?.query?.isLoading ?? true;
  }, [organizationsQueryResult]);

  const anyOrgs = useMemo(() => {
    return !isLoading && organizationsQueryResult.data.length > 0;
  }, [isLoading, organizationsQueryResult]);

  const handleCreateOrg = useCallback(
    (org) => {
      return Promise.resolve(onCreateOrg(org))
        .then(() => mounted.current && setShowCreateForm(false))
        .catch(noop);
    },
    [onCreateOrg, setShowCreateForm]
  );

  const rootOrgNames = useMemo(() => {
    return (organizationsQueryResult?.data ?? []).map((o) => o.name);
  }, [organizationsQueryResult]);

  return activeAccount && !allowOrgs(activeAccount) ? (
    <OrgTeaser account={activeAccount} />
  ) : (
    <div className="mb-4">
      <PanelHeader size="flex">
        <div className="panel-content px-4">
          <div className="orgs-panel-header">
            <div>
              <h1>Organizations</h1>
              <p>
                Organizations allow you to build org structures for viewing the
                performance of multiple teams.
              </p>
              <p>
                {allowOrgs(activeAccount) && (
                  <Link
                    className="btn btn-secondary"
                    to={`/w/${workspaceId}/orgs/0`}
                  >
                    View your default organization
                  </Link>
                )}
              </p>
            </div>
            <div>
              {anyOrgs && (
                <button
                  data-test="create-org"
                  className="btn btn-primary create-link mr-3"
                  onClick={() => setShowCreateForm(true)}
                >
                  <Icon icon="plus" className="btn-icon-left" /> Create
                </button>
              )}
            </div>
          </div>
        </div>
      </PanelHeader>
      <div className="content">
        <AsyncDisplay
          isLoading={isLoading}
          isError={!!organizationsQueryResult?.query.error}
        >
          <AsyncDisplay.Loading>
            <LoadingMessage iconSize="2x">
              Loading your organizations&hellip;
            </LoadingMessage>
          </AsyncDisplay.Loading>
          <AsyncDisplay.Resolved>
            {anyOrgs ? (
              <OrgList
                organizations={organizationsQueryResult.data}
                workspaceId={workspaceId}
                onDeleteOrg={handleDelete}
              />
            ) : (
              <Card>
                <CardBody>
                  <div className="media text-muted">
                    <Icon
                      icon={['far', 'star-exclamation']}
                      size="4x"
                      className="mr-3"
                      fixedWidth
                    />
                    <div className="media-body">
                      <p>You don't have any custom organizations yet.</p>
                      <p>
                        <button
                          data-test="create-org"
                          className="btn btn-primary create-link mr-3"
                          onClick={() => setShowCreateForm(true)}
                        >
                          <Icon icon="plus" className="btn-icon-left" /> Create
                          an Organization
                        </button>
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </AsyncDisplay.Resolved>
        </AsyncDisplay>
      </div>

      <AddOrgFormDialog
        onCancel={() => setShowCreateForm(false)}
        isOpen={showCreateForm}
        onConfirm={handleCreateOrg}
        rootOrgNames={rootOrgNames}
      />

      <ConfirmModal
        onConfirm={confirmDelete}
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        confirmButtonLabel="Delete Organization"
        title="Delete Organization?"
      >
        <p>
          Please confirm you want to permanently remove the organization{' '}
          <strong>{orgToDelete?.name}</strong>.
        </p>
        <p>
          This will also remove any references to this organization from other
          organizations.{' '}
        </p>
      </ConfirmModal>
    </div>
  );
};

OrgsComponent.propTypes = {
  workspaceId: PropTypes.any.isRequired,
  organizationsQueryResult: PropTypes.object,
};

export default OrgsComponent;
