import {
  continuousLearning,
  leadership,
  organizationalAgility,
  portfolioManagement,
  productDelivery,
  solutionDelivery,
  teamAndTechnical,
} from './question-sets';

export const safeBusinessAgility = {
  name: 'SAFe Business Agility',
  key: 'safe-business-agility',
  question_sets: [
    teamAndTechnical,
    productDelivery,
    solutionDelivery,
    portfolioManagement,
    leadership,
    organizationalAgility,
    continuousLearning,
  ],
};

export default safeBusinessAgility;
