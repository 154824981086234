import React from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import _ from 'lodash';

function createChart(dom, props) {
  const data = props.data;

  const y = d3
    .scaleLinear()
    .domain([
      0,
      props.yLimit ||
        d3.max(data, d => {
          return d.count;
        })
    ])
    .range([props.height, 0]);

  const barWidth =
    (props.width - Math.max(0, props.data.length - 1)) /
    Math.max(1, props.data.length);

  const chart = d3
    .select(dom)
    .append('svg')
    .attr('class', 'd3 bar-chart')
    .attr('width', props.width)
    .attr('height', props.height)
    .attr('viewBox', '0 0 ' + props.width + ' ' + props.height)
    .attr('preserveAspectRatio', props.preserveAspectRatio);

  chart
    .selectAll('rect')
    .data(data)
    .enter()
    .append('svg:rect')
    .attr('transform', function(d, i) {
      return 'translate(' + (i * barWidth + 1) + ', 0)';
    })
    .attr('y', d => {
      return y(d.count);
    })
    .attr('height', d => {
      return props.height - y(d.count);
    })
    .attr('class', d => {
      return d.className;
    })
    .attr('width', barWidth);

  return chart;
}

function updateChart(chart, props) {
  const data = props.data;

  const y = d3
    .scaleLinear()
    .domain([
      0,
      props.yLimit ||
        d3.max(data, d => {
          return d.count;
        })
    ])
    .range([props.height, 0]);

  chart
    .selectAll('rect')
    .data(data)
    .transition()
    .duration(props.duration)
    .attr('y', d => {
      return y(d.count);
    })
    .attr('height', d => {
      return props.height - y(d.count);
    })
    .attr('class', d => {
      return d.className;
    });
}

class BarChart extends React.Component {
  componentDidMount() {
    const dom = ReactDOM.findDOMNode(this);
    this.chart = createChart(dom, this.props);
  }

  shouldComponentUpdate(props) {
    // check the incoming props to see if we should update
    function isEqual(a, b, compareProps) {
      return _.isEqual(_.pick(a, compareProps), _.pick(b, compareProps));
    }

    if (!isEqual(this.props, props, ['data', 'yLimit', 'height'])) {
      updateChart(this.chart, props);
    }
    return false;
  }

  render() {
    return (
      <div className="bar-chart">
        {(() => {
          return this.props.title ? <h4>{this.props.title}</h4> : null;
        })()}
      </div>
    );
  }
}

BarChart.defaultProps = {
  width: 100,
  height: 100,
  title: '',
  legend: true,
  data: [],
  labels: [],
  duration: 1000,
  preserveAspectRatio: 'none',
  yLimit: null
};

export default BarChart;
