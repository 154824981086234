export const culturalQuestionSets = [
  {
    name: 'Support a generative culture',
    key: 'generative-culture',
    category: 'culture',
    questions: [
      {
        text: 'Information is actively sought',
        key: 'information-seeking',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Messengers are not punished when they deliver news of failures or other bad news',
        key: 'punish-messengers',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Responsibilities are shared',
        key: 'shared-responsibilities',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Encourage & support learning',
    key: 'encourage-learning',
    category: 'culture',
    questions: [
      {
        text: 'Failure causes inquiry',
        key: 'failure-causes-inquiry',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'New ideas are welcomed',
        key: 'welcome-new-ideas',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Failures are treated primarily as opportunities to improve the system',
        key: 'failures-as-opportunity',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Support & facilitate collaboration among teams',
    key: 'facilitate-collaboration',
    category: 'culture',
    questions: [
      {
        text: 'Collaboration among teams in my area of the organization is encouraged and rewarded',
        key: 'collaboration-local-teams',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Collaboration among teams across different areas of the organization is encouraged and rewarded',
        key: 'collaboration-global-teams',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Make work meaningful',
    key: 'make-work-meaningful',
    category: 'culture',
    questions: [
      {
        text: 'I am glad I chose to work for this organization rather than another company',
        key: 'glad-i-work-here',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'I talk of this organization to my friends as a great company to work for',
        key: 'tell-friends-great-company',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text:
          'I am willing to put in a great deal of effort beyond what is normally expected to help my organization be successful',
        key: 'put-in-extra-effort',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: "I find that my values and my organization's values are very similar",
        key: 'values-align',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'In general, the people employed by my organization are working toward the same goals',
        key: 'working-toward-same-goals',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'I find that my organization cares about me',
        key: 'organization-cares-about-me',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Transformational leadership',
    key: 'transformational-leadership',
    category: 'culture',
    questions: [
      {
        text: 'My leader or manager has a clear understanding of where we are going',
        key: 'leader-understands-where-we-are-going',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager has a clear sense of where he/she wants our team to be in five years',
        key: 'leader-team-vision',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager has a clear idea of where the organization is going',
        key: 'leader-organization-vision',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager says things that make employees proud to be a part of this organization',
        key: 'leader-makes-team-proud',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager says positive things about the work unit',
        key: 'leader-gives-positive-feedback',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager encourages people to see changing environments as situations full of opportunities',
        key: 'leader-encourages-change-as-opportunity',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager challenges me to think about old problems in new ways',
        key: 'leader-encourages-new-solutions',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text:
          'My leader or manager has ideas that have forced me to rethink some things that I have never questioned before',
        key: 'leader-makes-me-rethink',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager has challenged me to rethink some of my basic assumptions about my work',
        key: 'leader-challenges-assumptions',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager considers my personal feelings before acting',
        key: 'leader-considers-personal-feelings',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager behaves in a manner which is thoughtful of my personal needs',
        key: 'leader-is-thoughtful',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager sees that the interests of employees are given due consideration',
        key: 'leader-considers-employee-interests',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager commends me when I do a better than average job',
        key: 'leader-commends',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager acknowledges improvement in my quality of work',
        key: 'leader-acknowledges-improvement',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'My leader or manager personally compliments me when I do outstanding work',
        key: 'leader-compliments-outstanding-work',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  }
];

export default culturalQuestionSets;
