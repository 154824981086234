import { trueToFalse } from './base-answers';

export const measureAndLearn = {
  name: 'Measure and Learn',
  key: 'measure-and-learn',
  category: 'develop-on-cadence',
  questions: [
    {
      text: 'Our ART allocates time for innovation activities',
      key: 'allocates-innovation-time',
      answers: trueToFalse,
    },
    {
      text: 'Our ART implements improvements as a result of our Inspect and Adapt process',
      key: 'implements-improvements-from-inspect-and-adapt',
      answers: trueToFalse,
    },
    {
      text: 'Our ART has clearly defined outcome metrics',
      key: 'clear-outcome-metrics',
      answers: trueToFalse,
    },
    {
      text: 'Our ART measures all aspects of flow',
      key: 'measures-flow',
      answers: trueToFalse,
    },
    {
      text: 'Our ART addresses delays in the workflow',
      key: 'addresses-delays-in-flow',
      answers: trueToFalse,
    },
  ],
};
