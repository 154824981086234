import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { selectActiveWorkspace } from 'js/store/reducers/workspaces';
import { canCreateTeams } from 'js/utils/authorization-utils';
import { notify } from 'js/actions/alert-actions';

import { SCHEMAS } from 'js/api';

import {
  list as listTeams,
  update as updateTeam,
} from 'js/actions/team-actions';
import {
  list as listFavoriteTeams,
  create as createFavoriteTeam,
  del as deleteFavoriteTeam,
} from 'js/actions/favorite-team-actions';

import { Redirect } from 'react-router-dom';
import TeamsRouteComponent from '../components/TeamsRouteComponent';
import { Authorization } from 'js/components';

const TeamsComponent = Authorization(['full-user'])(TeamsRouteComponent);
const GuestRedirect = Authorization(['guest'])(Redirect);

const rootTeamsQueryId = 'active-teams';

const Teams = ({
  me,
  teams,
  query,
  workspace,
  activeAccount,
  listFavoriteTeams,
  listTeams,
  teamsQueryId,
  createFavoriteTeam,
  deleteFavoriteTeam,
  updateTeam,
  notify,
}) => {
  const [queryLoaded, setQueryLoaded] = useState(false);

  useEffect(() => {
    setQueryLoaded(false);

    listFavoriteTeams();
    listTeams({}, teamsQueryId).then(() => {
      setQueryLoaded(true);
    });
  }, [teamsQueryId, listFavoriteTeams, listTeams]);

  const handleFavoriteTeam = useCallback(
    (team, isFavorite) => {
      return isFavorite
        ? createFavoriteTeam(team.id)
        : deleteFavoriteTeam(team.id);
    },
    [createFavoriteTeam, deleteFavoriteTeam]
  );

  const modifyTeamState = useMemo(
    () => (newState) => (team) => {
      updateTeam({ id: team.id, archived_state: newState })
        .then(() => {
          const op = newState === 'archived' ? 'archived' : 'restored';
          notify({
            type: 'success',
            message: `The "${team.name}" team was successfully ${op}.`,
          });
        })
        .catch(() => {
          const op = newState === 'archived' ? 'archiving' : 'restoring';
          notify({
            type: 'danger',
            message: `Sorry, there was a problem ${op} the team, "${team.name}".`,
          });
        });
    },
    [updateTeam, notify]
  );

  const handleArchiveTeam = useMemo(
    () => modifyTeamState('archived'),
    [modifyTeamState]
  );
  const handleRestoreTeam = useMemo(
    () => modifyTeamState(null),
    [modifyTeamState]
  );

  const canModifyTeams = canCreateTeams(me, workspace.id);

  return (
    <>
      <TeamsComponent
        user={me}
        workspace={workspace}
        loaded={queryLoaded}
        query={query}
        teams={teams}
        canModifyTeams={canModifyTeams}
        onArchiveTeam={handleArchiveTeam}
        onRestoreTeam={handleRestoreTeam}
        onFavoriteTeam={handleFavoriteTeam}
        activeAccount={activeAccount}
      />
      <GuestRedirect user={me} to="/guest/teams" />
    </>
  );
};

export default connect(
  (state, ownProps) => {
    const { favoriteTeams } = state.entities;
    const {
      match: {
        params: { workspaceId },
      },
    } = ownProps;
    const teamsQueryId = `${rootTeamsQueryId}-${workspaceId}`;

    const query = state.queries[teamsQueryId] || {};

    const teams = denormalize(
      query.result || [],
      SCHEMAS.TEAM_ARRAY,
      state.entities
    ).map((t) => ({
      ...t,
      is_favorite: !!favoriteTeams[t.id],
    }));

    return {
      me: state.identity.me,
      teams,
      teamsQueryId,
      workspace: selectActiveWorkspace(state),
      activeAccount: state.activeAccount,
    };
  },
  {
    listTeams,
    updateTeam,
    listFavoriteTeams,
    createFavoriteTeam,
    deleteFavoriteTeam,
    notify,
  }
)(Teams);
