import { useState, useMemo, useCallback } from 'react';

export function useFilter(layerOrder, filterComparer) {
  const [stackOrder] = useState([...layerOrder]);
  const [filters, setFilters] = useState(
    Object.fromEntries(stackOrder.map((o) => [o, null]))
  );

  const setFilter = useCallback(
    (layer, filter) => {
      setFilters(
        Object.fromEntries(
          stackOrder.map((o) => [o, layer === o ? filter : filters[o]])
        )
      );
    },
    [setFilters, stackOrder, filters]
  );

  const activeFilter = useMemo(() => {
    const activeLayer = stackOrder.find((layer) => !!filters[layer], [filters]);
    return activeLayer !== undefined ? filters[activeLayer] : null;
  }, [stackOrder, filters]);

  const clearFilter = useCallback(
    (layer) => {
      setFilter(layer, null);
    },
    [setFilter]
  );

  const resetFilters = useCallback(() => {
    setFilters(Object.fromEntries(stackOrder.map((o) => [o, null])));
  }, [setFilters, stackOrder]);

  const toggleFilter = useCallback(
    (layer, filter) => {
      if (filterComparer(filters[layer], filter)) {
        clearFilter(layer);
      } else {
        setFilter(layer, filter);
      }
    },
    [setFilter, clearFilter, filters, filterComparer]
  );

  return {
    setFilter,
    clearFilter,
    resetFilters,
    toggleFilter,
    activeFilter,
    filterStack: filters,
  };
}
