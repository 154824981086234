import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PasswordResetRouteContainer from './PasswordResetRouteContainer';
import ForgotPasswordRouteContainer from './ForgotPasswordRouteContainer';

const PasswordResetRouter = ({ me, match, ...props }) => {
  return (
    <Switch>
      <Route
        path={`${match.path}/:resetKey`}
        exact
        component={PasswordResetRouteContainer}
      />
      <Route
        path={`${match.path}`}
        exact
        component={ForgotPasswordRouteContainer}
      />
      <Redirect to={match.path} />
    </Switch>
  );
};

export default PasswordResetRouter;
