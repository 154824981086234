import { Icon, Loading } from 'js/components';
import React from 'react';
import { Link } from 'react-router-dom';

function PurchaseSuccess({ loading, provisioningError }) {
  return (
    <div className="featured-form">
      <div className="full-page-content">
        <div className="background-page">
          <div className="container">
            <div className="content">
              {loading ? (
                <Loading />
              ) : (
                <div className="page-result page-success">
                  <div className="result-icon">
                    <Icon icon={['far', 'clipboard-check']} fixedWidth />
                  </div>
                  <div className="result-body">
                    <h2 className="mb-5">Thank you for subscribing!</h2>
                    {provisioningError ? (
                      <section className="danger">
                        <p>
                          Sorry, there was a slight hiccup provisioning your
                          account but don't worry we are working to get it
                          resolved.
                        </p>
                        <p>
                          In the meantime you can start using Waypointr by
                          clicking the button below.
                        </p>
                      </section>
                    ) : (
                      <section>
                        <p>
                          Welcome to Waypointr premium. We're thrilled to have
                          you on board and can't wait to show you what our
                          platform can do. Get ready to take your organizational
                          insights to the next level!
                        </p>
                      </section>
                    )}
                    <Link to="/dashboard" className="btn btn-lg">
                      <Icon
                        icon={['far', 'truck-monster']}
                        className="btn-icon-left"
                      />{' '}
                      Let's get started
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
}

export default PurchaseSuccess;
