/* eslint jsx-a11y/anchor-is-valid: "off" */
import React from 'react';

import { Nav } from 'reactstrap';
import { Link } from 'react-router-dom';

import { ListNavLink, Icon } from '../';
import { Button } from 'js/components';

class GuestSidebar extends React.Component {
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  toggleSidebar = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  render() {
    return (
      <div>
        <div className="sidebar" data-color="green">
          <div className="logo">
            <Link to="/dashboard" className="simple-text logo-mini">
              <Icon icon={['fas', 'map-marker-alt']} />
            </Link>
            <Link to="/dashboard" className="simple-text logo-normal">
              Waypointr
            </Link>

            <div className="navbar-minimize">
              <Button
                simple
                neutral
                icon
                round
                color="transparent"
                id="minimizeSidebar"
                onClick={this.toggleSidebar}
              >
                <i className="visible-on-sidebar-regular">
                  <Icon icon="align-center" />
                </i>
                <i className="visible-on-sidebar-mini">
                  <Icon icon="list-ul" />
                </i>
              </Button>
            </div>
          </div>

          <div className="sidebar-wrapper" ref="sidebar">
            <Nav>
              <ListNavLink
                to={`/guest`}
                exact
                className="nav-link"
                activeClassName="active"
              >
                <i>
                  <Icon icon="user-friends" fixedWidth />
                </i>
                <p>Activity</p>
              </ListNavLink>
              <ListNavLink
                to={`/guest/teams`}
                exact
                className="nav-link"
                activeClassName="active"
              >
                <i>
                  <Icon icon="user-friends" fixedWidth />
                </i>
                <p>Teams</p>
              </ListNavLink>
            </Nav>
          </div>
        </div>
      </div>
    );
  }
}

export default GuestSidebar;
