/* eslint jsx-a11y/anchor-is-valid: "off" */
import React, { useState } from 'react';

import { monogram } from 'js/utils/string';

import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';

const MultipleAccounts = ({ accounts, activeAccount }) => {
  const [accountsOpen, setAccountsOpen] = useState(false);

  return (
    <>
      <a
        data-toggle="collapse"
        aria-expanded={accountsOpen}
        onClick={() => setAccountsOpen(!accountsOpen)}
      >
        <span data-test="current-workspace-name">
          <span
            className="current-workspace-name"
            data-test="current-workspace-top-menu"
          >
            {activeAccount.name}
          </span>
          <b className="caret" />
        </span>
      </a>
      <Collapse isOpen={accountsOpen}>
        <ul className="nav">
          {accounts.map((account) => (
            <li key={account.id}>
              <Link
                className="btn-link"
                to={`/a/${account.id}`}
                onClick={() => setAccountsOpen(false)}
              >
                <span className="sidebar-mini-icon">
                  {monogram(account.name, 2, 2).toUpperCase()}
                </span>
                <span
                  className="sidebar-normal workspace-name"
                  data-test="account-name-span"
                >
                  {account.name}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </Collapse>
    </>
  );
};

export default MultipleAccounts;
