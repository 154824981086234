import React, { useEffect, useRef, useState } from 'react';

function RenameForm({ node, onCancel, onSave }) {
  const inputRef = useRef();
  const [name, setName] = useState(node.name);

  useEffect(() => {
    setTimeout(() => inputRef.current.select(), 1);
  }, [inputRef]);

  return (
    <form
      className="name-form"
      onSubmit={(e) => {
        e.preventDefault();
        onSave(name);
      }}
    >
      <input
        ref={inputRef}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div>
        <button
          type="button"
          className="btn btn-link mr-2 btn-default"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-link" disabled={!name}>
          Save
        </button>
      </div>
    </form>
  );
}

export default RenameForm;
