import React, { useCallback, useMemo, useState } from 'react';

import assessmentTemplates from 'js/data/assessment-templates';

import { NavLink } from 'react-router-dom';
import { PanelHeader, ChartSelector } from 'js/components';
import LoadingMessage from 'js/components/Loading/LoadingMessage';
import AssessmentPicker from './AssessmentPicker';
import {
  RecommendationsGlyph,
  ScorecardGlyph,
  TreeGlyph,
  TreeGlyphRectangles,
} from './Glyphs';
import OrgTree from './Visualizations/OrgTree';
import OrgAssessmentDetailContainer from '../../containers/OrgAssessmentDetailContainer';
import Participation from './Visualizations/Participation';
import SendRemindersModal from 'js/routes/Organizations/components/OrgComponent/SendRemindersModal';

const detailViews = {
  recommendations: (navLinkProps, baseUrl, key) => (
    <NavLink
      {...navLinkProps}
      to={{ pathname: `${baseUrl}/recommendations`, state: { noScroll: true } }}
      key={key}
    >
      {RecommendationsGlyph}
    </NavLink>
  ),
  'squad-scorecard': (navLinkProps, baseUrl, key) => (
    <NavLink
      {...navLinkProps}
      to={{
        pathname: `${baseUrl}/squad-scorecard`,
        search: '?depth=full',
        state: { noScroll: true },
      }}
      key={key}
    >
      {ScorecardGlyph}
    </NavLink>
  ),
};

const navLinkProps = {
  className: 'btn switch-menu-item',
  activeClassName: 'active',
  exact: true,
};

const OrgComponent = ({
  organization,
  assessmentHistorySummaries,
  loading,
  error,
  workspace,
  history,
  teamScoresQuery,
  teamParticipationQuery,
  assessmentKey,
  match,
  onSendReminders,
}) => {
  const assessmentTemplate = useMemo(
    () => assessmentTemplates.find(({ key }) => key === assessmentKey),
    [assessmentKey]
  );
  const {
    views: { orgDetail: detailViewConfig },
  } = assessmentTemplate;

  const [showConfirmSendReminders, setShowConfirmReminders] = useState(false);
  const [reminderData, setReminderData] = useState({
    orgName: '',
    assessmentIds: [],
  });

  const confirmSendReminders = useCallback(
    (orgName, assessmentIds) => {
      setReminderData({ orgName, assessmentIds });
      setShowConfirmReminders(true);
    },
    [setReminderData, setShowConfirmReminders]
  );

  const handleSendReminders = useCallback(
    (assessmentIds, threshold) => {
      return (async () => {
        await onSendReminders(assessmentIds, threshold);
        setShowConfirmReminders(false);
      })();
    },
    [setShowConfirmReminders, onSendReminders]
  );

  return (
    <div>
      <PanelHeader size="flex">
        <div className="panel-content assessment-review-header pb-0">
          <section className="details">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-9">
                  <label>Organization</label>
                  {loading ? (
                    <div className="mt-3">
                      <LoadingMessage iconSize="2x">
                        Loading &hellip;
                      </LoadingMessage>
                    </div>
                  ) : error ? (
                    <p className="mt-3 danger">
                      Uh oh, there was a problem loading the organization
                    </p>
                  ) : (
                    <h1>
                      {organization.id === 0
                        ? workspace.name
                        : organization.name}
                    </h1>
                  )}
                </div>
                <div className="col-12 col-md-3">
                  {loading ? null : (
                    <AssessmentPicker
                      assessmentHistorySummaries={assessmentHistorySummaries}
                      defaultActiveKey={assessmentKey}
                      onChoose={(key) => {
                        history.push(
                          `/w/${workspace.id}/orgs/${organization.id}/${key}`
                        );
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="container-fluid org-chart-selector"
          style={{ marginBottom: '2rem' }}
        >
          {organization && (
            <ChartSelector
              loading={
                !teamScoresQuery.data &&
                (teamScoresQuery.query.isLoading ?? true)
              }
              error={teamScoresQuery.query.error}
              charts={[
                {
                  label: TreeGlyph,
                  component: OrgTree,
                  componentProps: {
                    organization,
                    assessmentKey,
                    workspaceId: workspace?.id,
                    teamScores: teamScoresQuery.data,
                    loading: teamScoresQuery.query.isLoading,
                    error: teamScoresQuery.query.error,
                  },
                },
                {
                  label: TreeGlyphRectangles,
                  component: Participation,
                  componentProps: {
                    organization,
                    teamParticipation: teamParticipationQuery.data,
                    loading: teamParticipationQuery.query.isLoading,
                    error: teamParticipationQuery.query.error,
                    workspaceId: workspace.id,
                    assessmentKey: assessmentKey,
                    onSendReminders: confirmSendReminders,
                  },
                },
                // { label: TrendGlyph, component: AssessmentTrend }
              ]}
            />
          )}
        </div>
        <div className="container-fluid org-chart-selector">
          <div className="switch-menu">
            {detailViewConfig?.map((key) =>
              detailViews[key]?.(navLinkProps, match.url, key)
            )}
          </div>
        </div>
      </PanelHeader>
      {!loading && !error && (
        <div className="content">
          <OrgAssessmentDetailContainer
            match={match}
            organization={organization}
          />
        </div>
      )}
      <SendRemindersModal
        orgName={reminderData.orgName}
        assessmentIds={reminderData.assessmentIds}
        isOpen={showConfirmSendReminders}
        onConfirm={handleSendReminders}
        onCancel={() => setShowConfirmReminders(false)}
      />
    </div>
  );
};

export default OrgComponent;
