import React from 'react';
import LikertResults from './LikertResults';

export const UnscoredLikertResults = (props) => {
  return (
    <LikertResults
      {...props}
      primaryResultsOverlayText={
        <span title="This question does not count toward the overall assessment score">
          Unscored
        </span>
      }
    />
  );
};

export default UnscoredLikertResults;
