export const cslpmDemographics = [
  {
    name: 'Demographics',
    key: 'demographics',
    category: 'demographics',
    questions: [
      {
        text: 'What role best describes your role at CareSource?',
        key: 'cslpm-demo-role',
        answers: [
          { value: 'Business', text: 'Business' },
          { value: 'Product', text: 'Product' },
          { value: 'Project Sponsor', text: 'Project Sponsor' },
          { value: 'IT Partner', text: 'IT Partner' },
          { value: 'User (EPMO or IMO)', text: 'User (EPMO or IMO)' },
        ],
        type: 'single-select',
      },
      {
        text: 'In what domain do you primarily work?',
        key: 'cslpm-demo-domain',
        answers: [
          { value: 'Clinical', text: 'Clinical' },
          { value: 'Claims', text: 'Claims' },
          { value: 'Member', text: 'Member' },
          { value: 'Provider', text: 'Provider' },
          { value: 'Compliance', text: 'Compliance' },
          { value: 'New Business', text: 'New Business' },
          { value: 'Business Configuration', text: 'Business Configuration' },
          { value: 'Corporate', text: 'Corporate' },
          { value: 'IT', text: 'IT' },
          { value: 'Pricing Configuration', text: 'Pricing Configuration' },
          { value: 'Digital Services', text: 'Digital Services' },
          { value: 'Encounters', text: 'Encounters' },
          { value: 'Supplemental Benefits', text: 'Supplemental Benefits' },
          { value: 'Other', text: 'Other' },
        ],
        type: 'single-select',
      },
      {
        text: 'What is your tenure at CareSource?',
        key: 'cslpm-demo-tenure',
        answers: [
          { value: '10+ years', text: '10+ years' },
          { value: '5-10 years', text: '5-10 years' },
          { value: '3-5 years', text: '3-5 years' },
          { value: '1-2 years', text: '1-2 years' },
          { value: '0-1 year', text: '0-1 year' },
        ],
        type: 'single-select',
      },
    ],
  },
];
