import React from 'react';

const NodeFill = ({
  width,
  height,
  maskId,
  notStarted,
  partial,
  completed,
}) => {
  const total = notStarted + partial + completed;

  return (
    <>
      <g mask={`url(#${maskId})`}>
        <rect width={width} height={height} className="node-fill-completed" />
        <rect
          width={(width * (notStarted + partial)) / total}
          height={height}
          className="node-fill-partial"
        />
        <rect
          width={(width * notStarted) / total}
          height={height}
          className="node-fill-not-started"
        />
      </g>
      <rect
        width={width}
        height={height}
        className="participation-tree-node"
        rx={height / 5}
      />
    </>
  );
};

export default NodeFill;
