import keymirror from 'keymirror';
import { request } from 'js/api/axios';

import { SCHEMAS } from '../api/schemas';
import { spreadApiKeys, extractApiKeys } from '../utils';

import { callApi, noSchemaQuerySuccessMutator } from '../api';
import { getCreatedResponse } from 'js/utils/response-utils';

export const types = keymirror({
  ...spreadApiKeys('CREATE_ASSESSMENT'),
  ...spreadApiKeys('LOAD_ASSESSMENT'),
  ...spreadApiKeys('LOAD_ASSESSMENTS'),
  ...spreadApiKeys('UPDATE_ASSESSMENT'),
  ...spreadApiKeys('PARTICIPATE_IN_ASSESSMENT'),
  ...spreadApiKeys('COMPLETE_PARTICIPANT_WELCOME'),
  ...spreadApiKeys('RESPOND_TO_ASSESSMENT'),
  ...spreadApiKeys('UNRESPOND_TO_ASSESSMENT'),
  ...spreadApiKeys('LIST_RESPONSES_TO_QUESTION'),
  ...spreadApiKeys('SEND_ASSESSMENT_REMINDERS'),
  ...spreadApiKeys('BULK_UPDATE_REVEAL_RESULTS_ON'),
  CLEAR_PARTICIPATION_SESSION: null,
});

export function create(assessment) {
  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_ASSESSMENT, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/teams/${assessment.team_id}/assessments`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { assessment },
        schema: SCHEMAS.ASSESSMENT,
      },
      dispatch,
      getState
    );
  };
}

export function list(query, queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LOAD_ASSESSMENTS', types),
        request: (data, token, currentWorkspaceId) =>
          request.get(`/api/workspaces/${currentWorkspaceId}/assessments`, {
            params: data.query,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { query },
        meta: { queryId },
        schema: SCHEMAS.ASSESSMENT_ARRAY,
      },
      dispatch
    );
  };
}

export function listRecent(queryId) {
  const meta = { queryId };
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LOAD_ASSESSMENTS', types),
        request: (_data, token) =>
          request.get(`/api/auth/identity/me/recent-assessments`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        meta,
        mutators: [undefined, noSchemaQuerySuccessMutator(meta), undefined],
      },
      dispatch
    );
  };
}

export function load(handle, participantToken = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LOAD_ASSESSMENT', types),
        request: (_data, token) => {
          const headers = {
            Authorization: `Bearer ${token}`,
          };

          if (participantToken !== null) {
            headers['Participant-Token'] = participantToken;
          }

          return request.get(`/api/assessments/${handle}`, { headers });
        },
        data: { id: handle },
        schema: SCHEMAS.ASSESSMENT,
      },
      dispatch
    );
  };
}

export function loadMeta(handle) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LOAD_ASSESSMENT', types),
        request: (_data, token) =>
          request.get(`/api/assessments/${handle}/views/meta`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { id: handle },
        schema: SCHEMAS.ASSESSMENT_META,
      },
      dispatch
    );
  };
}

export function update(patch) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('UPDATE_ASSESSMENT', types),
        request: (data, token, currentWorkspaceId) =>
          request.put(
            `/api/workspaces/${currentWorkspaceId}/assessments/${patch.id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { assessment: patch },
        meta: { patch: { assessments: { [patch.id]: patch } } },
        schema: SCHEMAS.ASSESSMENT,
      },
      dispatch
    );
  };
}

export function participate(
  assessmentId,
  participantToken = undefined,
  inviteToken = null,
  cohort = null
) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('PARTICIPATE_IN_ASSESSMENT', types),
        data: { invite_token: inviteToken, cohort },
        request: (data, token) => {
          const headers = {};

          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
          }

          if (participantToken) {
            headers['Participant-Token'] = participantToken;
          }

          return request.post(
            `/api/assessments/${assessmentId}/participants`,
            data,
            { headers }
          );
        },
      },
      dispatch
    );
  };
}

export function completeParticipantWelcome(
  assessmentId,
  participantToken,
  { id, name }
) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('COMPLETE_PARTICIPANT_WELCOME', types),
        data: { participant: { name, completed_welcome: true } },
        request: (data, token) => {
          const headers = {};

          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
          }

          headers['Participant-Token'] = participantToken;

          return request.patch(
            `/api/assessments/${assessmentId}/participants/${id}`,
            data,
            { headers }
          );
        },
      },
      dispatch
    );
  };
}

export function clearParticipationSession() {
  return {
    type: types.CLEAR_PARTICIPATION_SESSION,
  };
}

export function respond(
  assessmentId,
  questionId,
  response,
  listResponseFilter = null
) {
  return (dispatch, getState) => {
    const participantToken = (getState().participation[assessmentId] || {})
      .token;

    return callApi(
      {
        types: extractApiKeys('RESPOND_TO_ASSESSMENT', types),
        data: {
          response: getCreatedResponse({
            ...response,
            question_id: questionId,
          }),
        },
        meta: { assessmentId, questionId, response },
        request: (data, token) =>
          request.post(
            `/api/assessments/${assessmentId}/questions/${questionId}/responses`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Participant-Token': participantToken,
              },
            }
          ),
        schema: SCHEMAS.RESPONSE,
        errorMessages: {
          assessment: {
            'assessment locked': `Couldn't record your response because the assessment is locked`,
          },
        },
        responseCallback: (resp) => {
          const participantToken = resp.headers['participant-token'];
          const currentParticipantToken = (
            getState().participation[assessmentId] || {}
          ).token;

          if (participantToken !== currentParticipantToken) {
            participate(assessmentId, participantToken)(dispatch, getState);
          }
        },
      },
      dispatch
    ).then(() =>
      listResponsesToQuestion(
        assessmentId,
        questionId,
        listResponseFilter
      )(dispatch, getState)
    );
  };
}

export function unrespond(assessmentId, questionId, listResponseFilter = null) {
  return (dispatch, getState) => {
    const participantToken = (getState().participation[assessmentId] || {})
      .token;

    return callApi(
      {
        types: extractApiKeys('UNRESPOND_TO_ASSESSMENT', types),
        meta: { assessmentId, questionId },
        request: (data, token) =>
          request.delete(
            `/api/assessments/${assessmentId}/questions/${questionId}/responses`,
            {
              data,
              headers: {
                Authorization: `Bearer ${token}`,
                'Participant-Token': participantToken,
              },
            }
          ),
        errorMessages: {
          assessment: {
            'assessment locked': `Couldn't record your response because the assessment is locked`,
          },
        },
        responseCallback: (resp) => {
          const participantToken = resp.headers['participant-token'];
          const currentParticipantToken = (
            getState().participation[assessmentId] || {}
          ).token;
          if (participantToken !== currentParticipantToken) {
            participate(assessmentId, participantToken)(dispatch, getState);
          }
        },
      },
      dispatch
    ).then(() =>
      listResponsesToQuestion(
        assessmentId,
        questionId,
        listResponseFilter
      )(dispatch, getState)
    );
  };
}

export function listResponsesToQuestion(
  assessmentId,
  questionId,
  filter = null
) {
  return (dispatch, getState) => {
    const participantToken = (getState().participation[assessmentId] || {})
      .token;

    return callApi(
      {
        types: extractApiKeys(types.LIST_RESPONSES_TO_QUESTION, types),
        request: (_data, token) =>
          request.get(
            `/api/assessments/${assessmentId}/questions/${questionId}/responses`,
            {
              params: filter,
              headers: {
                Authorization: `Bearer ${token}`,
                'Participant-Token': participantToken,
              },
            }
          ),
        schema: SCHEMAS.RESPONSE_ARRAY,
        mutators: [
          null,
          (action, reqData, respData, _status) => {
            return {
              ...action,
              meta: {
                assessmentId,
                questionId,
                request: reqData,
                patch: {
                  questions: { [questionId]: { responses: respData.result } },
                  responses: respData?.entities?.responses,
                },
              },
            };
          },
          null,
        ],
      },
      dispatch,
      getState
    );
  };
}

export function sendReminders(assessmentIds, threshold) {
  return async (dispatch, getState) => {
    const data = {
      assessment_ids: assessmentIds,
      threshold,
    };
    return callApi(
      {
        types: extractApiKeys(types.SEND_ASSESSMENT_REMINDERS, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/assessment-reminders`,
            { reminder: data },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data,
      },
      dispatch,
      getState
    );
  };
}

export function bulkUpdateRevealResultsOn(assessmentId, revealResultsOn) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('BULK_UPDATE_REVEAL_RESULTS_ON', types),
        request: (data, token) =>
          request.post(
            `/api/assessments/${assessmentId}/update-question-reveal`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { reveal_results_on: revealResultsOn },
        meta: { assessmentId },
      },
      dispatch
    );
  };
}
