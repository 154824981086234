import React, { useState, useEffect } from 'react';
import { msToCountdown } from 'js/utils';

const CountdownClock = (props) => {
  const initialCountdown = props.countdownTo
    ? props.countdownTo - Date.now()
    : null;
  const [countdown, setCountdown] = useState(initialCountdown);

  const { countdownTo, onEnd, fps } = props;

  useEffect(() => {
    let interval = null;

    if (countdownTo) {
      if (countdownTo - Date.now() > 0) {
        interval = setInterval(() => {
          const newCountdown = countdownTo - Date.now();

          if (newCountdown <= 0) {
            onEnd();
            clearInterval(interval);
            interval = null;
            setCountdown(null);
          }

          setCountdown(newCountdown);
        }, 1000 / fps);
      }
    } else {
      setCountdown(null);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [countdownTo, onEnd, fps]);

  const clock =
    countdown && countdown > 0
      ? msToCountdown(countdown)
      : props.displayAtZero || msToCountdown(0);

  return <span className="countdown-clock">{clock}</span>;
};

CountdownClock.defaultProps = {
  countdownTo: null,
  fps: 30,
  displayAtZero: null,
  onEnd: () => {},
};

export default CountdownClock;
