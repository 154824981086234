import React, { useMemo } from 'react';
import { toArray } from 'lodash';
import { useRouteMatch } from 'react-router';

import LongTextQuestionDetail from './LongTextQuestionDetail';
import MultiSelectQuestionDetail from './MultiSelectQuestionDetail';
import NotLoadedQuestionDetail from './NotLoadedQuestionDetail';
import RedirectToAssessment from './RedirectToAssessment';

function getQuestionDetailComponent(question) {
  switch (question?.type) {
    case 'long-text':
      return LongTextQuestionDetail;

    case 'multi-select':
    case 'single-select':
      return MultiSelectQuestionDetail;

    case undefined:
      return NotLoadedQuestionDetail;

    default:
      return RedirectToAssessment;
  }
}

const QuestionDetail = ({ assessments, ...props }) => {
  const {
    params: { assessmentId, questionId: matchQuestionId },
  } = useRouteMatch();
  const questionId = +matchQuestionId;

  const { assessment, question, cohorts, participantCohortMap } =
    useMemo(() => {
      const assessment = assessments.find((a) => a.id === assessmentId);
      const questions =
        assessment?.question_sets.flatMap((qs) => qs.questions) ?? [];

      const question = questions.find((q) => q.id === questionId);
      const { cohortMap, participantMap: participantCohortMap } = (
        assessment?.participants ?? []
      ).reduce(
        (acc, p) => {
          const cohort =
            p.cohort === null
              ? { value: null, text: '(None)' }
              : { value: p.cohort, text: p.cohort };
          acc.cohortMap[cohort.value] = cohort;
          acc.participantMap[p.id] = cohort.value;
          return acc;
        },
        { cohortMap: {}, participantMap: {} }
      );

      return {
        assessment,
        question,
        cohorts: toArray(cohortMap).sort(),
        participantCohortMap,
      };
    }, [assessments, questionId, assessmentId]);

  const QuestioDetailComponent = getQuestionDetailComponent(question);

  const componentProps = {
    ...props,
    assessment,
    question,
    cohorts,
    participantCohortMap,
  };

  return <QuestioDetailComponent {...componentProps} />;
};

export default QuestionDetail;
