import React from 'react';
import { scaleColorTraffic as color } from 'js/utils/color';

import styles from '../DetailsPane.module.scss';

const timeFormatter = new Intl.DateTimeFormat(undefined, {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});

export const QuestionDetailPane = ({
  summaryData,
  filter: {
    criteria: { questionSetKey, questionKey },
  },
}) => {
  const lastAskedAssessment = Object.values(summaryData.assessments)
    .sort((a, b) => b.inserted_at - a.inserted_at)
    .find((a) => !!a.questionSets[questionSetKey]?.questions[questionKey]);
  const questionSet = summaryData.questionSets[questionSetKey];
  const question = questionSet.questions[questionKey];

  return (
    <div>
      <label>Question</label>
      <p>{question.text}</p>
      <label>Last asked</label>
      <div className={`${styles['panel-section']} text-sm`}>
        {timeFormatter.format(lastAskedAssessment.inserted_at)}
      </div>
      <label>Answers</label>
      <section className={`${styles['answer-grid']} text-sm`}>
        {question.answers.map((answer, index) => (
          <React.Fragment
            key={`${answer.value}-${answer.ordinal}-${answer.text}`}
          >
            <div className={styles.score}>
              <div
                className={styles['score-value']}
                style={{
                  borderColor: color(answer.normalizedScore),
                }}
              />
            </div>
            <div>{answer.text}</div>
          </React.Fragment>
        ))}
      </section>
    </div>
  );
};

export default QuestionDetailPane;
