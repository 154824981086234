import _ from 'lodash';
import { scaleLinear } from 'd3-scale';

export const scaleColor = scaleLinear()
  .domain([0, 1, 2])
  .range(['#ed2228', '#f0dd2b', '#4c9745']);

export const scaleColorDiscrete = (value) => {
  if (value >= 1.5) {
    return '#4c9745';
  } else if (value >= 0.5) {
    return '#f0dd2b';
  }

  return '#ed2228';
};

export function getAverageScore(responses) {
  return responses.filter((r) => !r.is_na).length > 0
    ? _.sumBy(responses, (r) => parseInt(r.value)) / responses.length
    : null;
}

export function getAverageQuestionScore(question) {
  return getAverageScore(question.responses);
}

export function getQuestionColor(question) {
  // TODO: review name/implementation
  return question.responses;
}
