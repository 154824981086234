import keymirror from 'keymirror';
import { request } from 'js/api/axios';
import { callApi, noSchemaQuerySuccessMutator } from '../api';

import { SCHEMAS } from 'js/api/schemas';
import { spreadApiKeys, extractApiKeys } from '../utils';
import { QueryBase } from 'js/store/reducers/queries';

export const types = keymirror({
  ...spreadApiKeys('LOAD_ORGS'),
  ...spreadApiKeys('LOAD_ORG'),
  ...spreadApiKeys('LOAD_TEAM_SCORES'),
  ...spreadApiKeys('LOAD_QUESTION_SCORES'),
  ...spreadApiKeys('LOAD_TEAM_PARTICIPATION'),
  ...spreadApiKeys('ADD_TEAMS_TO_ORG'),
  ...spreadApiKeys('REMOVE_TEAM_FROM_ORG'),
  ...spreadApiKeys('CREATE_ORG'),
  ...spreadApiKeys('DELETE_ORG'),
  ...spreadApiKeys('UPDATE_ORG'),
  ...spreadApiKeys('ADD_CHILD_TO_ORG'),
  ...spreadApiKeys('REMOVE_CHILD_FROM_ORG'),
  ...spreadApiKeys('LIST_ORG_ASSESSMENT_HISTORY_SUMMARIES'),
});

export function list(workspaceId) {
  return (dispatch) => {
    const meta = { queryId: `${QueryBase.WorkspaceOrgs}-${workspaceId}` };
    return callApi(
      {
        types: extractApiKeys(types.LOAD_ORGS, types),
        request: (_data, token) =>
          request.get(`/api/workspaces/${workspaceId}/organizations`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        meta,
        mutators: [undefined, noSchemaQuerySuccessMutator(meta), undefined],
      },
      dispatch
    );
  };
}

export function load(handle) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_ORG, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/organizations/${handle}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { id: handle },
        schema: SCHEMAS.ORGANIZATION,
      },
      dispatch
    );
  };
}

export function teamScores(orgId, assessmentKey) {
  return (dispatch) => {
    const meta = {
      queryId: `${QueryBase.TeamScores}-${orgId}-${assessmentKey}`,
    };
    return callApi(
      {
        types: extractApiKeys(types.LOAD_TEAM_SCORES, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/organizations/${orgId}/team-scores/${assessmentKey}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        meta,
        mutators: [
          undefined,
          //TODO: figure out a way where this is not necessry
          (action, _reqData, respData, status) => {
            return {
              ...action,
              status,
              data: { result: respData },
              meta,
            };
          },
          undefined,
        ],
      },
      dispatch
    );
  };
}

export function lookupQuestionScores(orgId, assessmentKey, query) {
  return (dispatch) => {
    const meta = {
      queryId: `${QueryBase.QuestionScores}-${orgId}-${assessmentKey}`,
    };
    return callApi(
      {
        types: extractApiKeys(types.LOAD_QUESTION_SCORES, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/organizations/${orgId}/question-scores/${assessmentKey}`,
            {
              params: query,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        meta,
        mutators: [undefined, noSchemaQuerySuccessMutator(meta), undefined],
      },
      dispatch
    );
  };
}

export function lookupTeamParticipation(orgId, assessmentKey) {
  return (dispatch) => {
    const meta = {
      queryId: `${QueryBase.TeamParticipation}-${orgId}-${assessmentKey}`,
    };
    return callApi(
      {
        types: extractApiKeys(types.LOAD_TEAM_PARTICIPATION, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/organizations/${orgId}/team-participation/${assessmentKey}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        meta,
        mutators: [undefined, noSchemaQuerySuccessMutator(meta), undefined],
      },
      dispatch
    );
  };
}

export function addTeamsToOrg(org, teamIds) {
  const data = { team_ids: teamIds };
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.ADD_TEAMS_TO_ORG, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/organizations/${org.id}/team-associations`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data,
      },
      dispatch
    );
  };
}

export function removeTeamFromOrg(parentId, teamId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.REMOVE_TEAM_FROM_ORG, types),
        request: (_data, token, currentWorkspaceId) =>
          request.delete(
            `/api/workspaces/${currentWorkspaceId}/organizations/${parentId}/team-associations/${teamId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
      },
      dispatch
    );
  };
}

export function addChildToOrg(parentId, childId) {
  const data = { child_org_id: +childId };
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.ADD_CHILD_TO_ORG, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/organizations/${parentId}/child-associations`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data,
      },
      dispatch
    );
  };
}

export function removeChildFromOrg(parentId, childId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.REMOVE_CHILD_FROM_ORG, types),
        request: (_data, token, currentWorkspaceId) =>
          request.delete(
            `/api/workspaces/${currentWorkspaceId}/organizations/${parentId}/child-associations/${childId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
      },
      dispatch
    );
  };
}

export function createOrg(organization, parentOrgId) {
  const data = { organization };
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_ORG, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/organizations`,
            data,
            {
              params: parentOrgId ? { parent: parentOrgId } : {},
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data,
      },
      dispatch
    );
  };
}

export function deleteOrg(organization) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.DELETE_ORG, types),
        request: (_data, token, currentWorkspaceId) =>
          request.delete(
            `/api/workspaces/${currentWorkspaceId}/organizations/${organization.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
      },
      dispatch
    );
  };
}

export function updateOrg(id, patch) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.UPDATE_ORG, types),
        request: (data, token, currentWorkspaceId) =>
          request.put(
            `/api/workspaces/${currentWorkspaceId}/organizations/${id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { organization: patch },
      },
      dispatch
    );
  };
}

export function listAssessmentHistorySummaries(orgId) {
  const meta = {
    queryId: `${QueryBase.AssessmentHistorySummaries}-${orgId}`,
  };

  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LIST_ORG_ASSESSMENT_HISTORY_SUMMARIES', types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/organizations/${orgId}/assessment-history-summaries`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        meta,
        mutators: [undefined, noSchemaQuerySuccessMutator(meta), undefined],
      },
      dispatch
    );
  };
}
