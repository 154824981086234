import React from 'react';
import AssessmentTypeGlyph from 'js/components/Assessment/AssessmentTypeGlyph';

import styles from './AssessmentActivityItem.module.scss';

const AssessmentActivityItem = ({
  tag: Tag = 'div',
  className,
  workspaceId,
  assessment,
  ...props
}) => {
  const assessmentDate = new Date(assessment.assessment_created_at);

  return (
    <Tag
      className={`${styles['assessment-activity-item']} ${className}`.trim()}
      {...props}
    >
      <div className={styles.icon}>
        <AssessmentTypeGlyph type={assessment.type} />
      </div>
      <div className={styles.details}>
        <div className={styles['assessment-name']}>{assessment.name}</div>
        <div className={styles.subdetails}>
          <div className={styles['assessment-time']}>
            <span className={styles.date}>
              {assessmentDate.toLocaleDateString([], {
                // year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
              {','}
            </span>{' '}
            <span className={styles.time}>
              {assessmentDate.toLocaleTimeString([], {
                hour: 'numeric',
                minute: 'numeric',
              })}
            </span>
          </div>
          <div className={styles.team}>{assessment.team_name}</div>
        </div>
      </div>
    </Tag>
  );
};

export default AssessmentActivityItem;
