export const multiSelectQuestionSets = [
  {
    name: 'Multiple-Choice Questions',
    key: 'multi-choice-questions',
    category: 'multi-choice',
    questions: [
      {
        text: 'What fruits do you like?',
        key: 'fruits-liked',
        type: 'multi-select',
        answers: [
          { value: 'apple', text: 'Apples' },
          { value: 'bananas', text: 'Bananas' },
          { value: 'oranges', text: 'Oranges' },
          { value: 'pears', text: 'Pears' },
          { value: 'none', text: 'I hate fruit' },
        ],
      },
    ],
  },
];
