import { trueToFalse as answers } from './base-answers';

export const leadership = {
  name: 'Lean-Agile Leadership (LAL)',
  key: 'leadership',
  category: 'leadership',
  questions: [
    {
      text: 'Our leaders clearly communicate a compelling vision for change',
      key: 'leadership-vision',
      answers,
    },
    {
      text: 'Our leaders lead with authenticity',
      key: 'authentic-leaders',
      answers,
    },
    {
      text: 'Our leaders exhibit an openness to an Agile way of working',
      key: 'agile-leaders',
      answers,
    },
    {
      text: 'Our leaders lead the transformation to the new way of working through personal advocacy and engagement',
      key: 'leadership-advocacy',
      answers,
    },
    {
      text: 'Our leaders move decision authority to where the information is',
      key: 'decentralized-decisions',
      answers,
    },
    {
      text: 'Our leaders proactively remove organizational obstacles',
      key: 'leaders-remove-obstacles',
      answers,
    },
  ],
};
