import React from 'react';
import { Redirect } from 'react-router-dom';

const RedirectToAssessment = ({ assessment }) => {
  return (
    <Redirect
      to={`/w/${assessment.workspace_id}/teams/${assessment.team_id}/assessments/${assessment.key}/${assessment.id}`}
    />
  );
};

export default RedirectToAssessment;
