import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import copy from 'copy-to-clipboard';

import { Link } from 'react-router-dom';
import { useState } from 'react';

import { Icon } from 'js/components';
import FileDownload from 'js/containers/FileDownload';
import ConfirmArchiveAssessModal from 'js/components/Assessment/ConfirmArchiveAssessModal';

const ListItem = ({
  assessment,
  onToggleAssessmentLock,
  onDeleteAssessment,
  onCopyLink,
}) => {
  const [assessToArchive, setAssessToArchive] = useState(null);
  const setParentAssmToArchive = (assess) => {
    setAssessToArchive(assess);
  };
  const insertedAt = moment(assessment.inserted_at);

  const handleToggleAssessmentLock = () => {
    onToggleAssessmentLock(assessment);
  };

  const handleDeleteAssessment = () => {
    onDeleteAssessment(assessment);
  };

  const handleCopyLink = () => {
    const url = `${window.location.origin}/assessments/${assessment.id}/run`;

    copy(url);

    onCopyLink(url);
  };

  const hasResponses = _.some(assessment.question_sets || [], (qs) =>
    _.some(qs.questions || [], (q) => (q.responses || []).length > 0)
  );

  const { workspace_id, team_id, key, id } = assessment;
  const url = `/w/${workspace_id}/teams/${team_id}/assessments/${key}/detail/${id}`;

  return (
    <div className="assessment-list-item">
      <div className="timestamp">
        <Link to={url}>
          <span>{insertedAt.format('MMMM Do YYYY, h:mm:ss a')}</span>
        </Link>
      </div>
      <div className="action-items">
        <button
          className="btn btn-outline-info btn-inline"
          onClick={handleCopyLink}
        >
          <Icon icon="copy" className="btn-icon-left" />
          Link
        </button>
        <button
          className="btn btn-outline-info btn-inline"
          onClick={handleToggleAssessmentLock}
        >
          <Icon
            icon={assessment.is_locked ? 'lock-alt' : 'lock-open-alt'}
            fixedWidth
          />
        </button>
        <button
          className="btn btn-outline-info btn-inline"
          onClick={
            hasResponses
              ? () => setAssessToArchive(assessment)
              : handleDeleteAssessment
          }
        >
          <Icon icon="trash-alt" fixedWidth />
        </button>
        {hasResponses ? (
          <FileDownload
            href={`/reports/assessment-results?assessment_id=${assessment.id}`}
            className="btn btn-outline-info btn-inline"
          >
            <Icon icon="cloud-download" fixedWidth />
          </FileDownload>
        ) : null}
      </div>
      <ConfirmArchiveAssessModal
        assmToArchive={assessToArchive}
        setParentAssmToArchive={setParentAssmToArchive}
        onDeleteAssessment={onDeleteAssessment}
      />
    </div>
  );
};

ListItem.propTypes = {
  onToggleAssessmentLock: PropTypes.func,
  onDeleteAssessment: PropTypes.func,
  onCopyLink: PropTypes.func,
};

ListItem.defaultProps = {
  onToggleAssessmentLock: _.noop,
  onDeleteAssessment: _.noop,
  onCopyLink: _.noop,
};

export default ListItem;
