import React from 'react';

export const GridOverlay = ({
  cx,
  cy,
  sliceCount,
  radius,
  innerRadius = undefined,
  chartTicks = [0.2, 0.4, 0.6, 0.8],
  tag: Tag = 'g',
  ...props
}) => {
  innerRadius = innerRadius ?? radius;
  const wedgeArc = (2 * Math.PI) / sliceCount;

  return (
    <Tag {...props}>
      {Array.from({ length: sliceCount }).map((_d, i) => {
        const thetaOffset = -Math.PI / 2 + wedgeArc * i;

        return (
          <line
            key={`arc-${i}`}
            x1={cx}
            y1={cy}
            x2={cx + radius * Math.cos(thetaOffset)}
            y2={cy + radius * Math.sin(thetaOffset)}
            className="wedge-divider inner-divider"
          />
        );
      })}

      {chartTicks.map((scale) => (
        <circle
          key={scale}
          r={scale * innerRadius}
          cx={cx}
          cy={cy}
          className="chart-grid-dense"
        />
      ))}
    </Tag>
  );
};

export default GridOverlay;
