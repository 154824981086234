import * as React from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'reactstrap';
import { Icon } from 'js/components';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import ConfirmModal from 'js/components/modal/ConfirmModal';
import { useState } from 'react';

const TeamList = ({ teams, onArchiveTeam, onFavoriteTeam, canModifyTeams }) => {
  const [teamToArchive, setTeamToArchive] = useState(null);
  const handleFavoriteTeam = (team) => {
    onFavoriteTeam(team, !team.is_favorite);
  };

  return (
    <Table responsive className="team-list">
      <tbody>
        {teams.map((team) => {
          return (
            <tr
              key={team.id}
              className={classnames({
                team: true,
                favorite: !!team.is_favorite,
              })}
            >
              <td>
                <div
                  className="stamp"
                  onClick={handleFavoriteTeam.bind(this, team)}
                >
                  <span className="fa-layers fa-fw">
                    <Icon icon="star" className="background-layer" />
                    <Icon icon={['far', 'star']} />
                  </span>
                </div>
                <Link to={`/w/${team.workspace_id}/teams/${team.id}/dashboard`}>
                  {team.name}
                </Link>
              </td>
              <td className="text-right">
                {canModifyTeams ? (
                  <Button
                    className="btn-icon"
                    onClick={() => setTeamToArchive(team)}
                  >
                    <Icon icon="archive" />
                  </Button>
                ) : (
                  <div title="Only admin users can archive teams">
                    <Button className="btn-icon" disabled>
                      <Icon icon="archive" />
                    </Button>
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
      <ConfirmModal
        isOpen={!!teamToArchive}
        title="Archive team?"
        onConfirm={() => {
          onArchiveTeam(teamToArchive);
          setTeamToArchive(null);
        }}
        onCancel={() => setTeamToArchive(null)}
        confirmButtonLabel="Archive"
      >
        <p>
          Please confirm that you want to archive{' '}
          <strong>{teamToArchive?.name || 'this team'}</strong>.
        </p>
      </ConfirmModal>
    </Table>
  );
};

TeamList.propTypes = {
  teams: PropTypes.array.isRequired,
  onArchiveTeam: PropTypes.func,
  onFavoriteTeam: PropTypes.func,
};

TeamList.defaultProps = {
  onArchiveTeam: () => {},
  onFavoriteTeam: () => {},
};

export default TeamList;
