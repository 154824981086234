import * as React from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'reactstrap';
import { Icon } from 'js/components';
import { Link } from 'react-router-dom';

const CompanyList = ({ companies, onDelete }) => {
  return (
    <Table responsive>
      <thead className="text-primary">
        <tr>
          <th>Name</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {companies.map(company => {
          return (
            <tr key={company.id} className="company">
              <td>
                <Link to={`/companies/${company.id}/dashboard`}>
                  {company.name}
                </Link>
              </td>
              <td className="text-right">
                <Button
                  className="btn-icon btn btn-secondary"
                  onClick={() => onDelete(company)}
                >
                  <Icon icon="trash-alt" />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

CompanyList.propTypes = {
  companies: PropTypes.array.isRequired,
  onDelete: PropTypes.func
};

CompanyList.defaultProps = {
  companies: [],
  onDelete: () => {}
};

export default CompanyList;
