import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class DashboardRedirectContainer extends React.Component {
  render() {
    const { currentWorkspaceId } = this.props;

    return <Redirect to={`/w/${currentWorkspaceId}/dashboard`} />;
  }
}

export default connect(
  ({ currentWorkspaceId }) => {
    return { currentWorkspaceId };
  },
  {}
)(DashboardRedirectContainer);
