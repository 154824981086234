import keymirror from 'keymirror';
import { request } from 'js/api/axios';
import moment from 'moment';

import { callApi } from 'js/api';
import { SCHEMAS } from '../api/schemas';
import { spreadApiKeys, extractApiKeys } from '../utils';

export const types = keymirror({
  ...spreadApiKeys('LOAD_QUESTION'),
  ...spreadApiKeys('UPDATE_QUESTION'),
});

export function load(handle) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_QUESTION, types),
        request: (_data, token) =>
          request.get(`/api/questions/${handle}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        schema: SCHEMAS.QUESTION,
      },
      dispatch
    );
  };
}

export function update(patch) {
  const formattedPatch = formatQuestionData(patch);

  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.UPDATE_QUESTION, types),
        request: (data, token) =>
          request.put(`/api/questions/${patch.id}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { question: formattedPatch },
        schema: SCHEMAS.QUESTION,
        meta: { patch: { questions: { [patch.id]: patch } } },
      },
      dispatch
    );
  };
}

export function setQuestionReveal(questionId, revealOn) {
  return update({ id: questionId, reveal_results_on: revealOn });
}

function formatQuestionData(question) {
  if (question.reveal_results_on) {
    return {
      ...question,
      reveal_results_on: moment(question.reveal_results_on).format(
        'YYYY-MM-DDTHH:mm:ss.SSSZZ'
      ),
    };
  }

  return question;
}
