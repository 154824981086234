import React from 'react';
import cn from 'classnames';
import { Icon, IconStack } from 'js/components';

const preventDefault = (e) => e.preventDefault();
const getAdditionalPropsForAnswer = (value) => {
  switch (value) {
    case '0':
      return {
        className: 'performing-poorly',
        icon: (
          <IconStack className="response-icon">
            <Icon icon="circle" />
            <Icon icon={['far', 'thumbs-down']} inverse transform="shrink-7" />
          </IconStack>
        ),
      };
    case '1':
      return {
        className: 'performing-middle',
        icon: (
          <IconStack className="response-icon">
            <Icon icon="circle" />
            <Icon icon={['far', 'hand-paper']} inverse transform="shrink-7" />
          </IconStack>
        ),
      };

    case '2':
      return {
        className: 'performing-well',
        icon: (
          <IconStack className="response-icon">
            <Icon icon="circle" />
            <Icon icon={['far', 'thumbs-up']} inverse transform="shrink-7" />
          </IconStack>
        ),
      };
    default:
      return {};
  }
};

const Question = ({
  question,
  children,
  response,
  onVote,
  isMarker = true,
}) => {
  const handleVote = (e, value) => {
    console.log('poop');
    try {
      e.target.blur();
    } catch (e) {
      // suppress failures
    }
    onVote(value);
  };

  const options = question
    ? question.answers
        .map(({ id, text, value }) => ({
          id,
          text,
          value,
          classNames: {
            chosen: response?.value === value,
            'not-chosen': response && response.value !== value,
          },
          notChosenClassName: response?.value === value ? 'selected' : '',
          ...getAdditionalPropsForAnswer(value),
        }))
        .sort((a, b) => +b.value - +a.value)
    : [];

  const Tag = isMarker ? 'button' : 'div';

  return question ? (
    <div className="assessment-question row">
      <div className="col">
        <h2 className="question-title">
          {children}
          {question.text}
        </h2>
        <div className="question-responses list-unstyled">
          {options.map(({ id, text, value, className, classNames, icon }) => (
            <Tag
              key={id}
              className={cn('question-response', className, classNames)}
              {...(isMarker
                ? {
                    type: 'button',
                    onMouseDown: preventDefault,
                    onClick: (e) => handleVote(e, value),
                  }
                : {})}
            >
              {icon} <div>{text}</div>
            </Tag>
          ))}
          {/* <button
            className="question-response performing-well"
            type="button"
            onMouseDown={preventDefault}
            onClick={(e) => handleVote(e, question.answers[0].value)}
          >
            <IconStack className="response-icon">
              <Icon icon="circle" />
              <Icon icon={['far', 'thumbs-up']} inverse transform="shrink-7" />
            </IconStack>{' '}
            <div>{question.answers[0].text}</div>
          </button>
          <button
            className="question-response performing-middle"
            type="button"
            onMouseDown={preventDefault}
            onClick={(e) => handleVote(e, question.answers[1].value)}
          >
            <IconStack className="response-icon">
              <Icon icon="circle" />
              <Icon icon={['far', 'hand-paper']} inverse transform="shrink-7" />
            </IconStack>
            <div>{question.answers[1].text}</div>
          </button>
          <button
            className="question-response performing-poorly"
            type="button"
            onMouseDown={preventDefault}
            onClick={(e) => handleVote(e, question.answers[2].value)}
          >
            <IconStack className="response-icon">
              <Icon icon="circle" />
              <Icon
                icon={['far', 'thumbs-down']}
                inverse
                transform="shrink-7"
              />
            </IconStack>
            <div>{question.answers[2].text}</div>
          </button> */}
        </div>
      </div>
    </div>
  ) : null;
};

export default Question;
