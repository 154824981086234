import React from 'react';
import styles from './Variability.module.scss';

export function Variability({
  tag: Tag = 'span',
  varianceScore: normalizedDeviation,
}) {
  switch (true) {
    case normalizedDeviation > 0.21:
      return (
        <Tag className={`${styles.variability} ${styles['high-variance']}`}>
          High
        </Tag>
      );

    case normalizedDeviation > 0.17:
      return (
        <Tag className={`${styles.variability} ${styles['medium-variance']}`}>
          Medium
        </Tag>
      );

    case normalizedDeviation === null:
      return (
        <Tag className={`${styles.variability} ${styles['text-muted']}`}>
          N/A
        </Tag>
      );

    default:
      return (
        <Tag className={`${styles.variability} ${styles['low-variance']}`}>
          Low
        </Tag>
      );
  }
}
