import { connect } from 'react-redux';
import { PricingList } from 'js/components/Pricing';
import { list as listProducts } from 'js/actions/product-actions';
import { denormalize } from 'normalizr';
import { useEffect, useState } from 'react';
import { SCHEMAS } from 'js/api/schemas';

function PricingListContainer({ products, listProducts, me }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        await listProducts();
      } catch (e) {
        console.error('failed to lookup products: ', e);
      } finally {
        setLoading(false);
      }
    })();
  }, [setLoading, listProducts]);

  return (
    <PricingList
      products={products}
      loading={loading}
      account={me?.accounts?.[0]}
    />
  );
}

export default connect(
  (store) => ({
    products: denormalize(
      Object.values(store.entities.products || {}),
      SCHEMAS.PRODUCT_ARRAY,
      store.entities
    ),
    me: denormalize(store.identity.me, SCHEMAS.ME, store.entities),
  }),
  {
    listProducts,
  }
)(PricingListContainer);
