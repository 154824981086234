import { mapValues, reduce } from 'lodash';
import { isScoredType } from 'js/utils/question-set';

const scoreTeam = (teamNode, questionScores) => {
  const rawTeamScores = questionScores.find((ts) => {
    return ts.team_id === teamNode.id;
  });

  // generate all the question set and question totals/counts for a team
  const rawScoringData = (rawTeamScores?.scores ?? []).reduce(
    (acc, { question_key, question_set_key, question_type, score }) => {
      const questionSetInfo = acc[question_set_key] ?? {
        question_set_key,
        scoredTotal: 0,
        scoredCount: 0,
        total: 0,
        count: 0,
        questions: {},
      };

      const questionInfo = questionSetInfo.questions[question_key] ?? {
        question_key,
        question_type,
        scoredTotal: 0,
        scoredCount: 0,
        total: 0,
        count: 0,
      };

      if (isScoredType(question_type)) {
        questionSetInfo.scoredTotal += score;
        questionSetInfo.scoredCount += 1;
        questionInfo.scoredTotal += score;
        questionInfo.scoredCount += 1;
      }

      questionSetInfo.total += score;
      questionSetInfo.count += 1;
      questionInfo.total += score;
      questionInfo.count += 1;

      questionSetInfo.questions[question_key] = questionInfo;
      acc[question_set_key] = questionSetInfo;

      return acc;
    },
    {}
  );

  // TODO: accumulate the overall score for the team
  const teamScores = mapValues(
    rawScoringData,
    ({
      question_set_key,
      scoredTotal: qsScoredTotal,
      scoredCount: qsScoredCount,
      questions,
    }) => ({
      question_set_key,
      score: qsScoredCount > 0 ? qsScoredTotal / qsScoredCount : null,
      questions: mapValues(
        questions,
        ({
          question_key,
          question_type,
          scoredTotal: qScoredTotal,
          scoredCount: qScoredCount,
          total: qTotal,
          count: qCount,
        }) => ({
          question_key,
          question_type,
          score: qScoredCount > 0 ? qScoredTotal / qScoredCount : null,
          unScore: qCount > 0 ? qTotal / qCount : null,
        })
      ),
    })
  );

  return {
    ...teamNode,
    type: 'team',
    scores: teamScores,
    children: [],
  };
};

const scoreGroup = (groupNode, questionScores) => {
  const children = [
    ...(groupNode.child_orgs ?? []),
    ...(groupNode.teams ?? []),
  ].map((n) => scoreNode(n, questionScores));

  const rawScoringData = children.reduce((acc, { scores }) => {
    return reduce(
      scores,
      (qsAcc, { questions }, qsKey) => {
        const qsTotals = qsAcc[qsKey] ?? {
          question_set_key: qsKey,
          questions: {},
        };

        qsTotals.questions = reduce(
          questions,
          (qAcc, { score, unScore, question_type }, qKey) => {
            const qTotals = qAcc[qKey] ?? {
              question_key: qKey,
              question_type,
              scoredTotal: 0,
              scoredCount: 0,
              total: 0,
              count: 0,
            };

            if (isScoredType(question_type)) {
              qTotals.scoredTotal += score;
              qTotals.scoredCount += 1;
            }

            qTotals.total += score ?? unScore;
            qTotals.count += 1;

            qAcc[qKey] = qTotals;

            return qAcc;
          },
          qsTotals.questions
        );

        qsAcc[qsKey] = qsTotals;
        return qsAcc;
      },
      acc
    );
  }, {});

  const scores = mapValues(rawScoringData, ({ questions }, qsKey) => {
    const { scoredTotal: qsScoredTotal, scoredCount: qsScoredCount } = reduce(
      questions,
      (
        acc,
        {
          scoredTotal: qScoredTotal,
          scoredCount: qScoredCount,
          total: qTotal,
          count: qCount,
        }
      ) => {
        return {
          scoredTotal: acc.scoredTotal + qScoredTotal,
          scoredCount: acc.scoredCount + qScoredCount,
          total: acc.total + qTotal,
          count: acc.count + qCount,
        };
      },
      { total: 0, count: 0, scoredTotal: 0, scoredCount: 0 }
    );

    const result = {
      question_set_key: qsKey,
      score: qsScoredCount > 0 ? qsScoredTotal / qsScoredCount : null,
      questions: mapValues(
        questions,
        (
          {
            scoredTotal: qScoredTotal,
            scoredCount: qScoredCount,
            total: qTotal,
            count: qCount,
            question_type,
          },
          qKey
        ) => ({
          question_key: qKey,
          question_type,
          score: qScoredCount > 0 ? qScoredTotal / qScoredCount : null,
          unScore: qCount > 0 ? qTotal / qCount : null,
        })
      ),
    };

    return result;
  });

  return {
    ...groupNode,
    type: 'org',
    scores,
    children,
  };
};

const scoreNode = (node, questionScores) => {
  return !node.child_orgs && !node.teams
    ? scoreTeam(node, questionScores)
    : scoreGroup(node, questionScores);
};

export { scoreNode };
