import React, { useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardBody,
  Card,
  Row,
  Col,
} from 'reactstrap';
import { PanelHeader } from 'js/components';

function ChangeWorkspaceName({ workspace, onSubmit }) {
  const [name, setName] = useState(workspace.name);
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(name);
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };
  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col sm={4}>
                  <Label for="workspace_name">Workspace Name</Label>
                  <FormGroup>
                    <Input
                      onChange={handleChange}
                      value={name}
                      id="workspace_name"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    color="primary"
                    type="submit"
                    value="submit"
                    disabled={
                      name === workspace.name ||
                      name === '' ||
                      name.trim() === ''
                    }
                    className="float-left btn btn-primary"
                    data-test="workspace-settings-submit"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default ChangeWorkspaceName;
