import React, { useEffect, useCallback } from 'react';
import { noop } from 'lodash';
import {
  list as listAccountUsers,
  resendInvite,
  removeFromAccount,
} from 'js/actions/account/user-actions';
import { notify } from 'js/actions/alert-actions';

import AccountsUsersComponent from '../components/AccountsUsersComponent';
import { connect } from 'react-redux';
import { QueryBase, selectAccountUsersQuery } from 'js/store/reducers/queries';

const AccountsUsers = ({
  queryId,
  account,
  users,
  me,
  listAccountUsers,
  resendInvite,
  removeFromAccount,
  notify,
}) => {
  const accountId = account?.id;

  const fetchUsers = useCallback(() => {
    listAccountUsers(accountId, queryId).catch(noop);
  }, [accountId, queryId, listAccountUsers]);

  useEffect(() => {
    if (accountId !== undefined && accountId !== null) {
      fetchUsers(accountId);
    }
  }, [accountId, fetchUsers]);

  const handleResendInvite = useCallback(
    (email) => {
      return accountId === undefined || accountId === null
        ? Promise.resolve()
        : (async () => {
            try {
              await resendInvite(accountId, email);
              fetchUsers(accountId);
              notify({
                type: 'success',
                message: 'The invite has been resent',
              });
            } catch {
              notify({
                type: 'danger',
                message: 'Sorry, there was a problem resending the invite',
              });
            }
          })();
    },
    [resendInvite, notify, fetchUsers, accountId]
  );
  const handleRemoveUser = useCallback(
    (user) => {
      return (async () => {
        try {
          await removeFromAccount(accountId, user.user_id);
          await fetchUsers(accountId);
          notify({
            type: 'success',
            message: 'The user has been removed from the account',
          });
        } catch {
          notify({
            type: 'danger',
            message: 'Sorry, there was a problem removing the user',
          });
        }
      })();
    },
    [removeFromAccount, notify, fetchUsers, accountId]
  );

  return (
    <AccountsUsersComponent
      users={users}
      me={me}
      account={account}
      onResendInvite={handleResendInvite}
      onRemoveUser={handleRemoveUser}
    />
  );
};

export default connect(
  (state, { account }) => {
    const accountId = account?.id;
    const queryId = `${QueryBase.UsersInAccount}-${accountId}`;

    const usersQuery = selectAccountUsersQuery(state, accountId);
    const users = usersQuery?.data ?? [];

    return {
      queryId,
      users,
    };
  },
  { listAccountUsers, resendInvite, removeFromAccount, notify }
)(AccountsUsers);
