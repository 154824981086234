import React from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import _ from 'lodash';

function createChart(dom, props) {
  const data = props.data;

  const x = d3
    .scaleLinear()
    .domain([
      0,
      d3.max(
        _.map(data, d => {
          return d.count;
        })
      )
    ])
    .range([0, props.width]);

  const height = props.barHeight * data.length - 1;

  const chart = d3
    .select(dom)
    .append('svg')
    .attr('class', 'd3 horizontal-bar-chart')
    .attr('width', props.width)
    .attr('height', height)
    .attr('viewBox', '0 0 ' + props.width + ' ' + height)
    .attr('preserveAspectRatio', props.preserveAspectRatio);

  chart
    .selectAll('rect')
    .data(data)
    .enter()
    .append('svg:rect')
    .attr('transform', function(d, i) {
      return 'translate(0,' + i * props.barHeight + ')';
    })
    .attr('width', d => {
      return x(d.count);
    })
    .attr('class', d => {
      return d.className;
    })
    .attr('height', props.barHeight - 1);

  chart
    .append('svg:line')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', 0)
    .attr('y2', height)
    .attr('class', 'axis');

  return chart;
}

function updateChart(chart, props) {
  const data = props.data;

  const x = d3
    .scaleLinear()
    .domain([
      0,
      d3.max(
        _.map(data, d => {
          return d.count;
        })
      )
    ])
    .range([0, props.width]);

  chart
    .selectAll('rect')
    .data(data)
    .transition()
    .duration(props.duration)
    .attr('width', d => {
      return x(d.count);
    })
    .attr('class', d => {
      return d.className;
    });
}

class HorizontalBarChart extends React.Component {
  componentDidMount() {
    const dom = ReactDOM.findDOMNode(this);
    this.chart = createChart(dom, this.props);
  }

  shouldComponentUpdate(props) {
    // check the incoming props to see if we should update
    function isEqual(a, b, compareProps) {
      return _.isEqual(_.pick(a, compareProps), _.pick(b, compareProps));
    }

    if (!isEqual(this.props, props, ['data', 'yLimit', 'height'])) {
      updateChart(this.chart, props);
    }
    return false;
  }

  render() {
    return <div className="horizontal-bar-chart" />;
  }
}

HorizontalBarChart.defaultProps = {
  width: 100,
  barHeight: 30,
  title: '',
  legend: true,
  data: [],
  labels: [],
  duration: 1000,
  preserveAspectRatio: 'none'
};

export default HorizontalBarChart;
