import { alwaysNeverAnswers, agreeDisagreeAnswers, cslpmRightData } from './base-answers';

export const cslpmEpmoImpact = [
  {
    name: 'Effect of EPMO',
    key: 'epmoeffect',
    category: 'epmoeffect',
    questions: [
      {
        text: 'Do you have a clear understanding of the purpose and outcome expected for EPMO managed projects? ',
        key: 'cslpm-outcomes-effect',
        answers: alwaysNeverAnswers,
      },
      {
        text: 'The EPMO actively works with me to address dependencies, impediments, and risks.',
        key: 'cslpm-workswith-effect',
        answers: agreeDisagreeAnswers,
      },
      {
        text: 'The EPMO plays an active role in enhancing our project delivery.',
        key: 'cslpm-delivery-effect',
        answers: agreeDisagreeAnswers,
      },
      {
        text: 'EPMO managed projects allow us to do “good” work as we go - quality and compliance are not an afterthought.',
        key: 'cslpm-quality-effect',
        answers: alwaysNeverAnswers,
      },
      {
        text: 'Do you have the right data to determine if a project should continue, stop, or start?',
        key: 'cslpm-determination-effect',
        answers: alwaysNeverAnswers,
      },
      {
        text: 'Do you have the right data to determine which work items take priority when faced with two competing requests?',
        key: 'cslpm-rightdata-effect',
        answers: cslpmRightData,
      },
      {
        text: 'Reducing delays is a way to improve our ability to deliver. What types of delays have you personally experienced in the last 6 months? Pick ALL that apply.',
        key: 'cslpm-delays-effect',
        type: 'multi-select',
        answers: [
          { value: 'budget-approval', text: 'Waiting for budget approval' },
          { value: 'business-decision', text: 'Waiting for a business decision to be made' },
          { value: 'technical-decision', text: 'Waiting for a technical or architectural decision to be made' },
          { value: 'priorities', text: 'Waiting for priorities to be clarified' },
          { value: 'devops-support', text: 'Waiting for DevOps support' },
          { value: 'legal', text: 'Waiting for contracting or legal agreements' },
          { value: 'dependency', text: 'Waiting on a dependency to be met' },
          { value: 'misunderstanding', text: 'Reworking something due to a misunderstanding or missing information' },
          { value: 'feedback-technical', text: 'Waiting for a code review or technical feedback' },
          { value: 'feedback-business', text: 'Waiting for business SME feedback or approval' },
          { value: 'feedback-cross-team', text: 'Waiting for feedback or approval from another IT team' },
          { value: 'availability-environment', text: 'Waiting for a non-production environment to be available' },
          { value: 'availability-cross-team', text: 'Waiting for availability from other teams' },
          { value: 'availability-personal', text: 'Freeing up my own time to work on tasks' },
          { value: 'technical-designs', text: 'Working through provided technical designs' },
        ],
      },
      {
        text: 'Are there other types of delays you have experienced in the last 6 months?',
        key: 'cslpm-delays-effect-other',
        type: 'long-text',
      },
    ],
  },
];
