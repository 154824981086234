export const aceAssessment = {
  name: 'ACE Agile Assessment',
  key: 'ace-agile',
  engine: 'survey',
  question_sets: [
    {
      name: 'Team Dynamics',
      key: 'team-dynamics',
      questions: [
        {
          text: 'Being Agile',
          key: 'being-agile',
          answers: [
            { value: '1', text: 'No understanding of the spirit of Agile' },
            { value: '2', text: 'Doing the mechanics' },
            {
              value: '3',
              text: 'Written down, agreed on by the team, clearly visible in a public area such as the team room.'
            },
            { value: '4', text: 'Followed by the team' },
            {
              value: '5',
              text: 'Followed naturally, very short list, highly visible, exception sare quickly identified and addressed.'
            }
          ]
        },
        {
          text: 'Morale',
          key: 'morale',
          answers: [
            {
              value: '1',
              text:
                'Blame game, finger pointing, denial, anger, shouting, backstabbing, passive aggressive, turn-over and other behaviors on a regular basis. Desire to go back to the oldways, active resistance to change, scapegoating. There is churn or people are frequently making references to quitting or how much they dislike their work or work'
            },
            {
              value: '2',
              text:
                'There are still elements of previous state, but there is steady progress away from those behaviors, problems are being actively addressed, and there is a general feeling that morale is improving'
            },
            {
              value: '3',
              text:
                'For the most part people are getting along and happy at work. There is very little if any talk about "going back" and it is generally believed that things are either better than before or at least not worse'
            },
            {
              value: '4',
              text:
                'The team generally believes that their work life is significantly better than before. They are happy, engaged, productive, and genuinely enjoy working together.'
            },
            {
              value: '5',
              text:
                'Most team members feel like this is one of the best teams they have ever worked on, they are excited to come in to work and are looking forward to the next day when they leave.'
            }
          ]
        },
        {
          text: 'Tuckman Stage',
          key: 'tuckman-stage',
          answers: [
            { value: '1', text: 'Forming' },
            { value: '2', text: 'Storming' },
            { value: '3', text: 'Norming' },
            {
              value: '4',
              text: 'Have been performing consistently for at least 8 weeks'
            },
            {
              value: '5',
              text: 'Have been performing consistently for the past 6 months'
            }
          ]
        },
        {
          text: 'Working Agreement',
          key: 'working-agreement',
          answers: [
            { value: '1', text: 'Non-existent' },
            {
              value: '2',
              text:
                "Some defacto team norms that are generally recognized, but haven't yet been written down and agreed on by the team."
            },
            {
              value: '3',
              text: 'Written down, agreed on by the team, clearly visible in a public area such as the team room.'
            },
            { value: '4', text: 'Followed by the team' },
            {
              value: '5',
              text: 'Followed naturally, very short list, highly visible, exceptions are quickly identified and addressed.'
            }
          ]
        }
      ]
    },
    {
      name: 'Team Structure',
      key: 'team-structure',
      questions: [
        {
          text: 'Team Size',
          key: 'team-size',
          answers: [
            { value: '1', text: '>20 people on the team' },
            { value: '2', text: '12-20 people on the team' },
            {
              value: '3',
              text: '10-12 people on the team and heavily reliant on external individuals and/or part time team members'
            },
            {
              value: '4',
              text:
                '10-12 people on the team and we recognize the team shouldstill be smaller and have an action plan to achieve that size'
            },
            { value: '5', text: '6 +/-3 people on the team' }
          ]
        },
        {
          text: 'Dedicated Team Members',
          key: 'dedicated-team-members',
          answers: [
            {
              value: '1',
              text: 'Most team members are on multiple teams or working on multiple projects'
            },
            {
              value: '2',
              text: 'Most people are 50% dedicated to the team. Nobody is lessthan 30% dedicated to the team.'
            },
            {
              value: '3',
              text: 'Most people are 70% dedicated to the team. Nobody is lessthan 50% dedicated to the team.'
            },
            {
              value: '4',
              text: 'Most people are 90% dedicated to the team. Nobody is lessthan 70% dedicated to the team.'
            },
            {
              value: '5',
              text: 'Most people are 100% dedicated to the team, nobody is lessthan 60% dedicated to the team.'
            }
          ]
        },
        {
          text: 'Continuity/Standing Team',
          key: 'continuity-standing-team',
          answers: [
            {
              value: '1',
              text:
                'Constant churn of people on the team and/or team was formed for a single release or a single major initiative and will be disbanded after shipping.'
            },
            {
              value: '2',
              text:
                'There is an understanding that this is important, progress is being made, and further steps are being taken to get to the next stage'
            },
            {
              value: '3',
              text:
                '50%+ of the team is constant over the past 9 months and team has made multiple production releases or worked on multiple major initiatives without being reformed each time.'
            },
            {
              value: '4',
              text:
                'More than 70% of the team is constant over the past 9 months and team has made multiple production releases and worked on multiple major initiatives without being reformed each time.'
            },
            {
              value: '5',
              text: 'More than 90% of the team has been constant over the past 12 months'
            }
          ]
        },
        {
          text: 'Cross Functional',
          key: 'cross-functional',
          answers: [
            {
              value: '1',
              text: 'A significant portion of what is needed to get the stories to done exists outside of the team'
            },
            {
              value: '2',
              text: 'Some of the skills necessary to get the stories to done exists outside of the team'
            },
            {
              value: '3',
              text: 'All of the necessary skills for performing the work exist on the team'
            },
            {
              value: '4',
              text:
                'All of the necessary skills for performing the work exist on the team and there is some cross training of skills'
            },
            {
              value: '5',
              text:
                'All of the necessary skills for performing the work exist on the team and most of the team is cross trained on most of those skills'
            }
          ]
        },
        {
          text: 'Collocation',
          key: 'collocation',
          answers: [
            {
              value: '1',
              text: 'Team members have very little proximity to each other.'
            },
            {
              value: '2',
              text: 'Plans are in place to move team members as close to each other as is currently feasible.'
            },
            {
              value: '3',
              text: 'Most team members are accessible to any other team member within 30 seconds'
            },
            {
              value: '4',
              text: 'Most team members sit within hearing distance of each other'
            },
            {
              value: '5',
              text: 'Most team members are sitting in a team area together.'
            }
          ]
        },
        {
          text: 'Self Organization',
          key: 'self-organization',
          answers: [
            {
              value: '1',
              text:
                'Most people do not have the ability to choose what they work on, estimates are not determined by the team. Team does not feel like it can make decisions on its own. Some members just want to be told what to do.'
            },
            {
              value: '2',
              text: 'Some of the behaviors from the next stage are being discussed, encouraged, or tried'
            },
            {
              value: '3',
              text:
                'Teams are pulling work from the product backlog themselves, doing their own team-based estimation, choosing what to work on themselves, and using the definitions of ready and done to guide interaction with those outside the team.'
            },
            {
              value: '4',
              text:
                'The roles and responsibilities of the Scrum Master are shared by the entire team and the need for a designated and/or dedicated Scrum Master is significantly reduced. When some members of the team are not present, the team is able to adjust and continue getting stories done.'
            },
            { value: '5', text: 'The team is self organized' }
          ]
        },
        {
          text: 'Sustainable Pace',
          key: 'sustainable-pace',
          answers: [
            {
              value: '1',
              text:
                'People are tired, irritable, burnt out, working overtime on a regular basis. Current situation is considered business as usual.'
            },
            {
              value: '2',
              text:
                'There is a recognition that the current pace is not sustainable and steps are being taken to improve the situation.'
            },
            {
              value: '3',
              text:
                'Consensus is that the team is working at a pace that is close to sustainable indefinitely, though the workload is still inconsistent with bursts of heavy work loads'
            },
            {
              value: '4',
              text:
                'Consensus is that the team is working at a pace that is sustainable indefinitely, though there is still occasional crunch time'
            },
            {
              value: '5',
              text:
                'Steps are actively taken by the organization and the team to make sure that the team has a high morale, works no more than 40 hours per week, takes all vacation days and is a high performing team'
            }
          ]
        },
        {
          text: 'Multi-Team Synchronization',
          key: 'multi-team-synchronization',
          answers: [
            {
              value: '1',
              text:
                'Synchronization with other teams that are working together toward a common deliverable is either ad-hoc or done primarily through up-front planning and setting iteration content towards a planned integration phase'
            },
            {
              value: '2',
              text:
                'Team synchronizes with other teams using at least one of the methods (having common iteration start/stop dates (or use Kanban), standup-of-standups (or similar), retro-of-retros (or similar), and participates in a whole-product review)'
            },
            {
              value: '3',
              text:
                'Team uses as least 2 of the methods (having common iteration start/stop dates (or use Kanban), standup-of-standups (or similar), retro-of-retros (or similar), and participates in a whole-product review) and integrates their work with the whole product at least every 4 weeks'
            },
            {
              value: '4',
              text:
                'Team uses at least 3 of the methods (having common iteration start/stop dates (or use Kanban), standup-of-standups (or similar), retro-of-retros (or similar), and participates in a whole-product review) or equivalent, and there is little or no pre-planning of iteration content towards a planned integration phase (integration is continuous)'
            },
            {
              value: '5',
              text:
                'Team synchronizes with other teams by having common iteration start/stop dates (or use Kanban), standup-of-standups (or similar), retro-of-retros (or similar), and participates in a whole-product review on a frequent basis.'
            }
          ]
        },
        {
          text: 'Impediments',
          key: 'impediments',
          answers: [
            {
              value: '1',
              text:
                'Invisible and/or ignored. Fear of reprisals. Reluctance to raise impediments. Impediments that are raised are not resolved.'
            },
            {
              value: '2',
              text:
                'Raising impediments is actively encouraged and is frequently done. Some impediments are resolved. The team is beginning to see the benefits of this practice and feel comfortable practicing it.'
            },
            {
              value: '3',
              text:
                'Raising impediments is becoming routine and there is a high degree of comfort in doing it. Impediments are usually resolved. Root cause analysis is sometimes performed and there is a growing recognition of the value of raising impediments.'
            },
            {
              value: '4',
              text:
                'Impediment raising and resolution are a cultural norm. Individual and team impediments that can be addressed at those levels are addressed. Root cause analysis is frequently performed and acted on.'
            },
            {
              value: '5',
              text: 'Root cause analysis and resolution is a cultural norm'
            }
          ]
        }
      ]
    },
    {
      name: 'Product',
      key: 'product',
      questions: [
        {
          text: 'Shippability',
          key: 'shippability',
          answers: [
            {
              value: '1',
              text: 'No stories shippable in less than four weeks from ready to done or not measured'
            },
            { value: '2', text: 'Shippability is measured and visible' },
            { value: '3', text: 'Team strives for shippability' },
            {
              value: '4',
              text: '60% of story points go from ready to done in less than four weeks'
            },
            {
              value: '5',
              text: '90% of story points go from ready to done in less than two weeks'
            }
          ]
        },
        {
          text: 'End-to-end cycle time',
          key: 'end-to-end-cycle-time',
          answers: [
            {
              value: '1',
              text: 'A year or more from concept to ready to release'
            },
            {
              value: '2',
              text: 'Can get from concept to ready to release in 6 months'
            },
            {
              value: '3',
              text: 'Can get from concept to ready to release in 3 months'
            },
            {
              value: '4',
              text: 'Can get from concept to ready to release in weeks'
            },
            { value: '5', text: 'Days from concept to ready to release' }
          ]
        },
        {
          text: 'Product vision',
          key: 'product-vision',
          answers: [
            { value: '1', text: 'Not defined' },
            {
              value: '2',
              text: 'It is written down somewhere or the product owner or similar person knows what it is'
            },
            {
              value: '3',
              text: 'There is a written definition which is accurate and well known by everyone involved'
            },
            {
              value: '4',
              text:
                'There is a compelling product vision which can be clearly articulated by the product owner or similar person'
            },
            {
              value: '5',
              text: 'Simple, clear, compelling, everyone involved can articulate it well.'
            }
          ]
        },
        {
          text: 'Stories follow INVEST',
          key: 'stories-follow-invest',
          answers: [
            { value: '1', text: 'No knowledge of INVEST' },
            {
              value: '2',
              text: 'Team understands INVEST and is starting to follow parts of it on some stories.'
            },
            { value: '3', text: 'Following most of INVEST on many stories' },
            { value: '4', text: 'Following INVEST for most stories' },
            { value: '5', text: 'Following INVEST for all stories' }
          ]
        },
        {
          text: 'Definition of ready',
          key: 'definition-of-ready',
          answers: [
            { value: '1', text: 'Does not exist' },
            {
              value: '2',
              text:
                'There is an understanding of the need for a definition of ready and/or there is a tacit agreement for the content of one'
            },
            {
              value: '3',
              text:
                'There is a fairly good definition of ready which resulted from the collaboration between multiple members of the team. Definition of ready includes existence of acceptance criteria'
            },
            {
              value: '4',
              text:
                'There is a strong, clear, comprehensive (yet simple) definition of ready which resulted from the collaboration of most of the members, agreement and input from all, and it is publicly posted'
            },
            {
              value: '5',
              text: 'In place, comprehensive, periodically reviewed and updated, strictly followed'
            }
          ]
        },
        {
          text: 'Definition of done',
          key: 'definition-of-done',
          answers: [
            { value: '1', text: 'Does not exist' },
            {
              value: '2',
              text:
                'There is an understanding of the need for a definition of done and/or there is a tacit agreement for the content of one'
            },
            {
              value: '3',
              text:
                'There is a fairly good definition of done which resulted from the collaboration between multiple members of the team'
            },
            {
              value: '4',
              text:
                'There is a strong, clear, comprehensive (yet simple) definition of done which resulted from the collaboration of most of the members, agreement and input from all, and it is publicly posted'
            },
            {
              value: '5',
              text: 'In place, comprehensive, periodically reviewed and updated, strictly followed'
            }
          ]
        },
        {
          text: 'Story size',
          key: 'story-size',
          answers: [
            { value: '1', text: 'Random' },
            {
              value: '2',
              text: 'The team is starting to see the relationship between small stories and success.'
            },
            {
              value: '3',
              text: 'Team has a rule of thumb encouraging small stories'
            },
            { value: '4', text: 'Most stories can be done in a week or less' },
            { value: '5', text: 'Most stories shippable in 1-3 days' }
          ]
        },
        {
          text: 'Backlog grooming',
          key: 'backlog-grooming',
          answers: [
            {
              value: '1',
              text: 'Stories are rarely ready to be worked on prior to the team starting to work on those stories'
            },
            {
              value: '2',
              text:
                'It is understood that consistent and frequent grooming is an important goal and steps are being taken to get there.'
            },
            {
              value: '3',
              text: '60%+ of the time there are stories ready when needed'
            },
            { value: '4', text: 'There are usually just enough stories ready' },
            {
              value: '5',
              text: 'There are always more than enough stories ready'
            }
          ]
        },
        {
          text: 'Stories use vertical slices',
          key: 'stories-use-vertical-slices',
          answers: [
            {
              value: '1',
              text: "No knowledge of vertical slices or they can't be done due to external constraints"
            },
            {
              value: '2',
              text: 'Using vertical slices for an increasing percentage of stories'
            },
            { value: '3', text: 'Using vertical slices for 50%+ of stories' },
            { value: '4', text: 'Using vertical slices for 70%+ of stories' },
            { value: '5', text: 'Using vertical slices for 90%+ of stories' }
          ]
        },
        {
          text: 'Work in progress',
          key: 'work-in-progress',
          answers: [
            {
              value: '1',
              text: 'Amount of WIP unknown. No knowledge of one piece flow (e.g. small batch size)'
            },
            {
              value: '2',
              text:
                'WIP is tracked and visible. One piece flow is understood and there is interest in doing it. Most of the time, members are working on 2 or more stories at a time.'
            },
            {
              value: '3',
              text:
                'One piece flow is actively being pursued, WIP limits are set, most of the time members are working on at most 2 stories and usually only one. Sometimes, multiple members are working on the same story.'
            },
            {
              value: '4',
              text:
                'WIP limits are set and respected. Most of the time members are only working on one story and frequently more than one member is working on the same story.'
            },
            {
              value: '5',
              text:
                'Only as much work that can be done simultaneously without increasing the cycle time of any of the work in progress. Most of the time multiple members are working on the same story.'
            }
          ]
        }
      ]
    },
    {
      name: 'Agile Process Mechanics',
      key: 'agile-process-mechanics',
      questions: [
        {
          text: 'Standups, Check-ins, huddles, or similar',
          key: 'standups-check-ins-huddles-or-similar',
          answers: [
            { value: '1', text: 'Not being held' },
            { value: '2', text: 'Being held regularly and has some value' },
            {
              value: '3',
              text:
                '80% of the team participates on a regular basis, the main meeting is less than 20 minutes, real impediments are raised on a regular basis, the focus is on the stories for this team, the team understands that the meeting is for them.'
            },
            {
              value: '4',
              text:
                'Daily, short, effective. Runs well with or without somebody officially responsible for the meeting. Team does an on-the-spot analysis of progress towards shippability and takes corrective action if needed.'
            },
            { value: '5', text: 'Positively adapted to the needs of the team' }
          ]
        },
        {
          text: 'Retrospectives',
          key: 'retrospectives',
          answers: [
            { value: '1', text: 'Not being held' },
            {
              value: '2',
              text: 'Held, but not regularly or not frequently enough'
            },
            {
              value: '3',
              text: 'Held regularly, well attended, produces action items. Action items are frequently acted on'
            },
            {
              value: '4',
              text:
                'Held regularly, well attended, enjoyable, produces action items that are recorded and generally acted on'
            },
            {
              value: '5',
              text:
                'Creatively run, format varied from time to time, forward looking, often produces breakthrough ideas that are acted on and produce results'
            }
          ]
        },
        {
          text: 'Iteration planning',
          key: 'iteration-planning',
          answers: [
            { value: '1', text: 'Not being held' },
            {
              value: '2',
              text: 'Team does not participate in commitment or estimation of stories.'
            },
            { value: '3', text: 'Team lead commits for team.' },
            {
              value: '4',
              text:
                'A relative estimating method is utilized to estimate size of stories and all individual points of view are considered and resolved. Themes are identified where appropriate.'
            },
            {
              value: '5',
              text:
                'All team members commit to fully understood and estimated user stories and have considered dependencies and external constraints.'
            }
          ]
        },
        {
          text: 'All work based on user stories',
          key: 'all-work-based-on-user-stories',
          answers: [
            { value: '1', text: 'Not being followed' },
            {
              value: '2',
              text:
                'It is understood that it is important to use user stories for all work and steps are being taken to get there.'
            },
            {
              value: '3',
              text:
                'User stories exist for 50%+ of the work, but still using other artifacts for some work or translating some user stories to other artifacts for some work.'
            },
            {
              value: '4',
              text:
                'User stories exist for 80%+ of work, but still using other artifacts for some work or translating some user stories to other artifacts for some work.'
            },
            { value: '5', text: 'All work based on user stories' }
          ]
        },
        {
          text: 'Estimation',
          key: 'estimation',
          answers: [
            {
              value: '1',
              text: 'Ad-hoc, done by a few people, based on hours, or entirely task-based'
            },
            { value: '2', text: 'Done on a regular basis' },
            {
              value: '3',
              text:
                'The whole team participates in estimation, real story points are used. Most team members no longer thinking in hours.'
            },
            {
              value: '4',
              text: '90+% of the time estimation involves the whole team thinking in story points.'
            },
            {
              value: '5',
              text: 'Consistently done at least weekly by the whole team thinking in story points.'
            }
          ]
        },
        {
          text: 'Progress tracking',
          key: 'progress-tracking',
          answers: [
            { value: '1', text: 'Not implemented' },
            {
              value: '2',
              text:
                'Progress is tracked and known using burnup, burndown, CFD or similar method and sometimes influences behavior of the team.'
            },
            {
              value: '3',
              text: 'Progress is tracked and frequently influences the behavior of the team'
            },
            {
              value: '4',
              text: 'Progress information usually influences the behavior of the team'
            },
            {
              value: '5',
              text: 'The team proactively uses progress information to head off potential problems'
            }
          ]
        },
        {
          text: 'BVIR/ Kanban',
          key: 'bvir-kanban',
          answers: [
            { value: '1', text: 'No visual representation of work' },
            { value: '2', text: 'Kanban with backlog, in progress, and done' },
            {
              value: '3',
              text: 'Kanban with each step in the process identified'
            },
            {
              value: '4',
              text: 'Kanban with WIP limits, each step in the process identified'
            },
            {
              value: '5',
              text:
                'Kanban with WIP limits, each step in the process identified, queues before active steps, documented understanding of how to pull from queues'
            }
          ]
        },
        {
          text: 'Metrics',
          key: 'metrics',
          answers: [
            { value: '1', text: 'No visual representation of metrics' },
            { value: '2', text: 'Aware a burndown/up chart exists' },
            { value: '3', text: 'The burndown is updated/reviewed daily' },
            {
              value: '4',
              text:
                'Iteration burndown is updated daily, people know where to find it, a release burndown exists and is updated each iteration'
            },
            {
              value: '5',
              text:
                'Iteration Burndown is based on points delivered, is visible, updated in real time, Release burndown is visible, reviewed and updated real time. Cumulative Value Flow is visible and understood'
            }
          ]
        },
        {
          text: 'Reviews',
          key: 'reviews',
          answers: [
            {
              value: '1',
              text: 'Not happening, not happening on a regular basis, or happening less often than once in 6 weeks'
            },
            {
              value: '2',
              text:
                'Happening at least once every six weeks, but some or all of the following are happening: not reviewing all stories, illprepared to do the review, trying to "sell" what was done as opposed to finding missed expectations and encouraging feedback'
            },
            {
              value: '3',
              text:
                'Happening at least once every four weeks, most stories are reviewed, team is fairly well prepared, feedback is encouraged and incorporated into future stories'
            },
            {
              value: '4',
              text:
                'Reviews are a cultural norm. Every story is reviewed and the team is very well prepared. Active feedback is encouraged, the reviews are well attended and perceived as valuable to stakeholders.'
            },
            {
              value: '5',
              text:
                'The team proactively involves stakeholders on a regular basis and frequently delights stakeholders during reviews. The team and stakeholders work closely together and often discover unexpected value as a result of that interaction.'
            }
          ]
        }
      ]
    },
    {
      name: 'Agile Engineering Practices',
      key: 'agile-engineering-practices',
      questions: [
        {
          text: 'Architecture',
          key: 'architecture',
          answers: [
            {
              value: '1',
              text: 'Primarily done by designated architects up-front prior to implementation'
            },
            {
              value: '2',
              text: 'Team starting to work with architects and architects starting to delegate more decisions to the team'
            },
            {
              value: '3',
              text: '50% of architectural decisions made by the team. 50% of architectural decisions made just-in-time'
            },
            {
              value: '4',
              text: '80% of architectural decisions made by the team. 80% of architectural decisions made just-in-time'
            },
            {
              value: '5',
              text: 'Primarily done on a just-in-time basis by the team in consultation with the architecture team.'
            }
          ]
        },
        {
          text: 'Proximity of testing to implementation',
          key: 'proximity-of-testing-to-implementation',
          answers: [
            { value: '1', text: 'Long after implementation' },
            { value: '2', text: 'Within eight weeks' },
            { value: '3', text: 'Mostly within four weeks' },
            {
              value: '4',
              text: 'Mostly within two weeks and mostly before the next story is started'
            },
            {
              value: '5',
              text: 'For software projects, TDD with UI-based testing done immediately after story is coded'
            }
          ]
        },
        {
          text: 'Holistic testing (sw projects)',
          key: 'holistic-testing-sw-projects',
          answers: [
            {
              value: '1',
              text: 'Different kinds of testing (unit, functional, integration, etc.) all done without coordination'
            },
            {
              value: '2',
              text:
                'There is a recognition that holistic testing is a good thing and steps are being taken to move towards it.'
            },
            {
              value: '3',
              text: 'For 50%+ of user stories, the developers and testers coordinated their testing efforts'
            },
            {
              value: '4',
              text: 'For 80%+ of user stories, the developers and testers coordinated their testing efforts'
            },
            {
              value: '5',
              text: 'All testing coordinated ahead of coding and based around user stories'
            }
          ]
        },
        {
          text: 'Test automation (sw projects)',
          key: 'test-automation-sw-projects',
          answers: [
            { value: '1', text: 'Not being used' },
            {
              value: '2',
              text: '30%+ code coverage via test automation and plans are in place to increase this level'
            },
            {
              value: '3',
              text: '50%+ code coverage for all new user stories via test automation'
            },
            { value: '4', text: '50%+ code coverage via test automation' },
            { value: '5', text: '90% + code coverage via test automation' }
          ]
        },
        {
          text: 'Continuous Integration (sw projects)',
          key: 'continuous-integration-sw-projects',
          answers: [
            { value: '1', text: 'Not implemented' },
            {
              value: '2',
              text: 'Set up, but manually run. Failures not fixed right away.'
            },
            {
              value: '3',
              text: 'Run every hour. Failures fixed fairly quickly.'
            },
            {
              value: '4',
              text: 'Run every 10 minutes. Drop everything on failures until fixed.'
            },
            { value: '5', text: 'Run on every check-in.' }
          ]
        },
        {
          text: 'Unit testing * (sw projects)',
          key: 'unit-testing-sw-projects',
          answers: [
            { value: '1', text: 'Not being used' },
            {
              value: '2',
              text:
                'Some coding involves unit testing. There is an understanding that unit testing produces better code and reduces overall effort'
            },
            {
              value: '3',
              text: 'All new stories involve some amount of unit testing'
            },
            {
              value: '4',
              text:
                'All new stories involve the responsible amount of unit testing. Unit testing of stories included in the definition of done.'
            },
            {
              value: '5',
              text:
                'Hard to imagine a shop that is better at unit testing. Deep knowledge of the latest unit testing techniques, using mock objects, etc.'
            }
          ]
        },
        {
          text: 'Refactoring (sw projects)',
          key: 'refactoring-sw-projects',
          answers: [
            { value: '1', text: 'Not understood and/or not being done' },
            {
              value: '2',
              text:
                'Some understanding of single responsibility principle (SRP) and open/closed principle. Some amount of refactoring done as needed when implementing stories.'
            },
            {
              value: '3',
              text:
                'Refactoring around single responsibility principle (SRP) and open/closed (O/C) principle. Doing the appropriate amount of refactoring with most user stories'
            },
            {
              value: '4',
              text: 'Deep understanding of refactoring. True refactoring is a cultural norm.'
            },
            {
              value: '5',
              text:
                'Hard to imagine a shop that is better at refactoring. Deep knowledge of the latest refactoring techniques. Refactoring to patterns.'
            }
          ]
        }
      ]
    }
  ]
};
