import { trueToFalse } from './base-answers';

export const customerNeeds = {
  name: 'Customer Needs',
  key: 'customer-needs',
  category: 'customer-centricity',
  questions: [
    {
      text: 'Our ART clearly defines the market segments that the solution addresses',
      key: 'defined-market-segments',
      answers: trueToFalse,
    },
    {
      text: 'Our ART identifies the personas that represent our customers',
      key: 'uses-personas',
      answers: trueToFalse,
    },
    {
      text: 'Our ART builds empathy with customers to understand their needs',
      key: 'builds-customer-empathy',
      answers: trueToFalse,
    },
    {
      text: 'Our ART researches customer experiences through Gemba',
      key: 'gemba-walks',
      answers: trueToFalse,
    },
    {
      text: 'Our ART gathers feedback from customers who use our solutions',
      key: 'gather-feedback-from-customers',
      answers: trueToFalse,
    },
  ],
};
