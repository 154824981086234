import { setActiveCustomerAccount } from 'js/actions/channel-actions/customer-account-actions';

let state = {
  accountId: null,
  channel: null,
};

export function joinCustomerAccountChannel(
  accountId,
  socket,
  dispatch,
  getState
) {
  console.log(`joining acct:${accountId}`);
  if (!socket) {
    return;
  }

  // already joined
  if (state.accountId === accountId) {
    return;
  }

  const handleRefresh = (account) => {
    refreshAccount(account, dispatch, getState);
  };

  const channel = socket.channel(`account:${accountId}`, {});

  channel.on('refresh', handleRefresh);

  channel
    .join()
    .receive('ok', (resp) => {
      console.log('Joined acct successfully', resp);
    })
    .receive('error', (resp) => {
      console.log('Unable to join acct', resp);
    });
}

export function leaveAccountChannel(accountId) {
  if (state.accountId !== accountId) {
    return;
  }

  state.channel.leave();

  state = {
    accountId: null,
    channel: null,
  };
}

function refreshAccount(account, dispatch, _getState) {
  dispatch(setActiveCustomerAccount(account));
}
