import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PanelHeader } from 'js/components';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import JoinAssessmentForm from 'js/components/Forms/JoinAssessmentForm';
import { AsyncDisplay, LoadingMessage, Icon } from 'js/components';
import moment from 'moment';
import { groupBy } from 'lodash';

const GuestHomeRouteComponent = (props) => {
  const { onJoinAssessment, joinAssessmentMessage, loaded, assessments } =
    props;

  const handleJoin = (values) => {
    return onJoinAssessment(values.passphrase);
  };

  const workspaceAssessments = useMemo(() => {
    return groupBy(assessments, (a) => a.workspace_id);
  }, [assessments]);

  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <div className="row">
          <div className="col-12 col-md-8">
            <AsyncDisplay isLoading={!loaded} isError={false}>
              <AsyncDisplay.Loading>
                <Card>
                  <CardBody>
                    <LoadingMessage iconSize="2x">
                      Loading your recent activity&hellip;
                    </LoadingMessage>
                  </CardBody>
                </Card>
              </AsyncDisplay.Loading>
              <AsyncDisplay.Resolved>
                <Card>
                  <CardBody className="media text-muted">
                    <Icon
                      icon={['far', 'star-exclamation']}
                      size="4x"
                      className="mr-3"
                      fixedWidth
                    />
                    <div className="media-body">
                      {assessments && assessments.length > 0 ? (
                        <>
                          <label>Recent Assessments</label>
                          <div className="mb-4">
                            {Object.values(workspaceAssessments).map((list) => (
                              <div key={list[0].workspace_id} className="mb-3">
                                <div className="mb-1">
                                  {list[0].workspace.name}
                                </div>
                                <div>
                                  {list.map((a) => (
                                    <div key={a.id}>
                                      <Link to={`/assessments/${a.id}/run`}>
                                        {moment(a.inserted_at).format(
                                          'MMM Do YYYY'
                                        )}
                                        : {a.name}
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <p>
                          You haven't participated in any assessments recently.
                        </p>
                      )}
                      <p>
                        Join an assessment to contribute to your team's growth,
                        or <Link to="/account">convert to a full account</Link>{' '}
                        and run your own assessments.
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </AsyncDisplay.Resolved>
            </AsyncDisplay>
          </div>
          <div className="col-12 col-md-4">
            <Card>
              <CardBody>
                <JoinAssessmentForm
                  onJoin={handleJoin}
                  message={joinAssessmentMessage}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

GuestHomeRouteComponent.propTypes = {
  onJoinAssessment: PropTypes.func,
};

GuestHomeRouteComponent.defaultProps = {
  onJoinAssessment: () => {},
};

export default GuestHomeRouteComponent;
