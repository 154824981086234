import React, { useCallback, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import AsyncButton from 'js/components/Forms/AsyncButton';
import { pathToChild } from 'js/utils/org-utils';
import { Icon } from 'js/components';

function AddOrgRefFormDialog({
  parentNode,
  rootOrgs,
  orgTree,
  isOpen,
  onCancel,
  onConfirm,
  intermediateRoot,
}) {
  const [orgId, setOrgId] = useState('');

  const handleConfirm = useCallback(() => {
    return Promise.resolve(onConfirm(orgId)).then(() => setOrgId(''));
  }, [onConfirm, setOrgId, orgId]);

  const sortedRootOrgs = useMemo(() => {
    const currentChildOrgIds = (parentNode?.children ?? [])
      .filter((child) => child.type === 'org')
      .map((org) => org.id);

    const pathIds = parentNode
      ? pathToChild(orgTree, parentNode.id).map((n) => n.id)
      : [];

    return (rootOrgs ?? [])
      .filter(
        (o) => !currentChildOrgIds.includes(o.id) && !pathIds.includes(o.id)
      )
      .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
  }, [rootOrgs, orgTree, parentNode]);

  const handleCancel = useCallback(() => {
    setOrgId('');
    onCancel();
  }, [setOrgId, onCancel]);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <div className="primary">Add Organization to {parentNode?.name}</div>
      </ModalHeader>
      <ModalBody>
        <div>
          {intermediateRoot && (
            <Alert color="info">
              <div className="org-change-warning">
                <Icon icon="exclamation-circle" />
                <span>
                  This change will affect the organization{' '}
                  <strong>{intermediateRoot.name}</strong> and any organizations
                  that refer to it.
                </span>
              </div>
            </Alert>
          )}
          <FormGroup>
            <Label for="exampleSelectMulti">
              Select an organization to add
            </Label>
            <Input
              type="select"
              size={1}
              value={orgId}
              onChange={(e) => setOrgId(e.target.value)}
            >
              <option value="" />
              {sortedRootOrgs &&
                sortedRootOrgs.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
            </Input>
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancel}>Cancel</Button>
        <AsyncButton
          color="primary"
          onClick={handleConfirm}
          data-test="confirm-add-btn"
          disabled={!orgId}
        >
          Add organization
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
}

export default AddOrgRefFormDialog;
