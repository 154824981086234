import React from 'react';

const GradedQuestionScore = ({
  question,
  response,
  barWidth,
  dotDiameter,
  height,
  maxHeight,
  minHeight,
  width,
  color,
}) => {
  const range = question.answers.reduce(
    (acc, a) => {
      const value = +a.value;

      return {
        min: acc.min === null ? value : Math.min(value, acc.min),
        max: acc.max === null ? value : Math.max(value, acc.max),
      };
    },
    { min: null, max: null }
  );

  const noResponse = response === undefined;

  return (
    <g transform={`translate(${width / 2}, 0)`} className="question-score">
      <rect
        x={-width / 2}
        y={0}
        width={width}
        height={height}
        className={noResponse ? 'no-response' : 'response'}
        // fill={`#${Math.floor(0xffffff * Math.random()).toString(16)}`}
      />
      {noResponse
        ? (() => {
            const outerRadius = dotDiameter / 2;
            const innerRadius = outerRadius * 0.5;

            return (
              <g transform={`translate(0, ${height / 2})`}>
                <path
                  fillRule="evenodd"
                  d={[
                    `M 0 -${outerRadius}`,
                    `A ${outerRadius} ${outerRadius} 0 1 0 0 ${outerRadius}`,
                    `A ${outerRadius} ${outerRadius} 0 1 0 0 ${-outerRadius}`,
                    'Z',
                    `M 0 -${innerRadius}`,
                    `A ${innerRadius} ${innerRadius} 0 1 1 0 ${innerRadius}`,
                    `A ${innerRadius} ${innerRadius} 0 1 1 0 ${-innerRadius}`,
                  ].join(' ')}
                  className="no-response-marker"
                />
              </g>
            );
            // <circle r={dotDiameter / 2} cy={height / 2} className="no-response-marker" />
          })()
        : (() => {
            const barHeight =
              minHeight +
              ((maxHeight - minHeight) * (+response.value - range.min)) /
                (range.max - range.min);
            const yVal = maxHeight - barHeight;
            const score = (response || {}).value;

            return (
              <g transform={`translate(0, ${yVal + (height - maxHeight)})`}>
                <rect
                  x={-barWidth / 2}
                  y={0}
                  width={barWidth}
                  height={barHeight}
                  className="rect-post"
                  fill={color(score)}
                />
                <circle
                  r={dotDiameter / 2}
                  cy={0}
                  className="post-top"
                  fill={color(score)}
                />
              </g>
            );
          })()}
    </g>
  );
};

export default GradedQuestionScore;
