import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class GuestContainer extends React.Component {
  render() {
    const { me, children } = this.props;

    return me.is_guest ? <>{children}</> : <Redirect to="/dashboard" />;
  }
}

export default connect(
  ({ identity: { me } }) => ({
    me
  }),
  {}
)(GuestContainer);
