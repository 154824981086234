import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

const PendingEmailChange = ({ onCancel, onResendVerification }) => {
  const handleCancel = useCallback(
    e => {
      onCancel();

      e.preventDefault();
    },
    [onCancel]
  );

  const handleResendVerification = useCallback(
    e => {
      onResendVerification();

      e.preventDefault();
    },
    [onResendVerification]
  );

  return (
    <div className="pending-email-change warning-feedback">
      One more step - Check your email for a link to verify that you truly,
      madly, deeply do own this email.
      <div className="btn-group">
        <button className="btn btn-link" type="button" onClick={handleCancel}>
          Cancel
        </button>
        <button
          className="btn btn-link"
          type="button"
          onClick={handleResendVerification}
        >
          Resend
        </button>
      </div>
    </div>
  );
};

PendingEmailChange.propTypes = {
  onCancel: PropTypes.func,
  onResendVerification: PropTypes.func
};

PendingEmailChange.defaultProps = {
  onCancel: noop,
  onResendVerification: noop
};

export default PendingEmailChange;
