import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormFeedback,
  Spinner,
} from 'reactstrap';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import _ from 'lodash';

const InviteUserForm = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    serverErrors,
    roles = [
      { value: 'admin', name: 'Admin' },
      { value: 'user', name: 'User', default: true },
    ],
  } = props;

  const isInvalid = (fieldName) => {
    return (
      (errors[fieldName] && touched[fieldName]) ||
      (serverErrors[fieldName] &&
        values[fieldName] === serverErrors[fieldName].submittedValue)
    );
  };

  const { workspaceId, accountId } = useParams();

  const formikAttributes = (fieldName) => ({
    id: fieldName,
    name: fieldName,
    onChange: handleChange,
    onBlur: handleBlur,
    invalid: isInvalid(fieldName),
  });

  const formikFeedback = (fieldName) => {
    if (errors[fieldName] && touched[fieldName]) {
      return <FormFeedback>{errors[fieldName]}</FormFeedback>;
    }

    if (
      serverErrors[fieldName] &&
      values[fieldName] === serverErrors[fieldName].submittedValue
    ) {
      return <FormFeedback>{serverErrors[fieldName].message}</FormFeedback>;
    }

    return null;
  };

  const defaultRoleValue = useMemo(
    () => roles.find((r) => r.default === true)?.value,
    [roles]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col sm={4}>
          <FormGroup>
            <Label for="email">Email address</Label>
            <Input type="text" {...formikAttributes('email')} autoFocus />
            {formikFeedback('email')}
          </FormGroup>
          {roles?.length > 0 && (
            <FormGroup>
              <Label for="role">User role</Label>
              <Input
                type="select"
                className="pb-0 pt-0"
                {...formikAttributes('role')}
                data-test="user-role-dropdown"
                defaultValue={defaultRoleValue}
              >
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </Input>
              {formikFeedback('role')}
            </FormGroup>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className={'float-right'}
            type="submit"
            value="submit"
            color="primary"
            disabled={isSubmitting}
          >
            Invite them! {isSubmitting && <Spinner type="grow" size="sm" />}
          </Button>
          <Link
            className={'float-right btn btn-secondary'}
            to={
              workspaceId !== undefined
                ? `/w/${workspaceId}/users`
                : `/a/${accountId}/users`
            }
          >
            Cancel
          </Link>
        </Col>
      </Row>
    </Form>
  );
};

const FormikInviteUserForm = withFormik({
  mapPropsToValues: () => {
    return {
      email: '',
      role: 'user',
    };
  },
  mapPropsToErrors: ({ serverErrors = {} }) => serverErrors,
  validationSchema() {
    return Yup.object().shape({
      email: Yup.string()
        .required('We need an email to know who to invite!')
        .email("That doesn't seem to be a valid email address"),
      role: Yup.string().required('Please select a user role'),
    });
  },
  handleSubmit: (values, bag) => {
    const { setSubmitting, props } = bag;
    setSubmitting(true);
    props
      .onSave(values)
      .then(_.noop)
      .finally(() => setSubmitting(false));
  },
  displayName: 'InviteUserForm',
})(InviteUserForm);

FormikInviteUserForm.propTypes = {
  onSave: PropTypes.func,
  autoFocus: PropTypes.bool,
};

FormikInviteUserForm.defaultProps = {
  onSave: () => {},
  autoFocus: false,
};

export default FormikInviteUserForm;
