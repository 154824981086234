import { trueFalse, trueFalseNa, trueFalseReverse } from './base-answers';

export const productManagementAndDevelopment = {
  name: 'Product Management & Development',
  key: 'product-management-and-development',
  category: 'product-management-and-development',
  questions: [
    {
      text: 'The Team conducts Backlog refinement every Sprint.',
      key: 'conducts-backlog-refinement',
      answers: trueFalse,
    },
    {
      text: 'The Product Owner or Product Manager is empowered to prioritize the product backlog.',
      key: 'po-empowered-to-prioritize',
      answers: trueFalse,
    },
    {
      text: 'All the team members are part of discussions and provide their input during backlog refinement.',
      key: 'team-members-contribute-to-refinement',
      answers: trueFalse,
    },
    {
      text: 'The team does not have a shared, consistent estimation framework.',
      key: 'shared-estimation-framework',
      answers: trueFalseReverse,
    },
    {
      text: 'The output of the Sprint Planning is a realistic Sprint Backlog that the Team agrees can be fully completed within the sprint timebox.',
      key: 'realistic-sprint-plans',
      answers: trueFalseNa,
    },
    {
      text: 'Acceptance Criteria contains a majority of the Use Cases and are written in advance of development for most User Stories.',
      key: 'acceptance-criteria-ready-for-dev',
      answers: trueFalse,
    },
  ],
};

export default productManagementAndDevelopment;
