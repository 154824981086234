import React from 'react';
import { connect } from 'react-redux';

import { PagesHeader, Footer } from 'js/components';
import { me as loadMe } from 'js/actions/account-actions';
import { logout } from 'js/actions/account-actions';

class Pages extends React.Component {
  constructor(props) {
    super(props);

    this._handleLogout = this._handleLogout.bind(this);
  }

  componentDidMount() {
    this.props.loadMe();
  }

  _handleLogout() {
    const { logout, history } = this.props;

    history.push('/');
    logout();
  }

  render() {
    const { component: Component, ...props } = this.props;

    return (
      <div>
        <PagesHeader {...props} onLogout={this._handleLogout} />
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className="full-page section-image">
            {Component ? <Component {...props} /> : null}
            {this.props.children}
            <Footer fluid />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => {
    return {
      me: state.identity.me,
      identity: state.identity,
      currentWorkspaceId: state.currentWorkspaceId
    };
  },
  {
    loadMe,
    logout
  }
)(Pages);
