import React, { useMemo } from 'react';
import ConfirmModal from 'js/components/modal/ConfirmModal';
import { Alert } from 'reactstrap';
import { Icon } from 'js/components';

function RemoveNodeConfirmDialog({
  nodeToRemove,
  path,
  isOpen,
  onCancel,
  onConfirm,
}) {
  const typeForRemoval =
    nodeToRemove?.type === 'team'
      ? 'Team'
      : nodeToRemove?.isRoot
      ? 'Organization'
      : 'Group';

  const intermediateRoot = useMemo(() => {
    if (path && nodeToRemove) {
      for (let i = path.length - 1; i >= 1; i--) {
        if (path[i]?.isRoot) {
          return path[i];
        }
      }
    } else {
      return undefined;
    }
  }, [path, nodeToRemove]);

  return (
    <ConfirmModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      isOpen={isOpen}
      confirmButtonLabel={`Remove ${typeForRemoval}`}
      title={`Remove ${typeForRemoval}?`}
    >
      {intermediateRoot && (
        <Alert color="info">
          <div className="org-change-warning">
            <Icon icon="exclamation-circle" />
            <span>
              This change will affect the organization{' '}
              <strong>{intermediateRoot.name}</strong> and any organizations
              that refer to it.
            </span>
          </div>
        </Alert>
      )}
      <p>
        Please confirm that you want to remove{' '}
        <strong>{nodeToRemove?.name}</strong> from{' '}
        <strong>{path?.[path.length - 1]?.name}</strong>.
      </p>
    </ConfirmModal>
  );
}

export default RemoveNodeConfirmDialog;
