import React from 'react';
import { connect } from 'react-redux';

import UserComponent from '../components/UserComponent';

import { load as loadUser } from 'js/actions/user-actions';

class UserRouteContainer extends React.Component {
  componentWillMount() {
    const { loadUser, match } = this.props;

    loadUser(match.params.userId);
  }

  render() {
    const { user } = this.props;

    return <UserComponent user={user} />;
  }
}

export default connect(
  (store, props) => {
    const { match } = props;

    return {
      user: store.entities.users[match.params.userId]
    };
  },
  {
    loadUser
  }
)(UserRouteContainer);
