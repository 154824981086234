import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import classNames from 'classnames';

const ProgressSlider = ({ assessment, participantId, questionSetIndex }) => {
  const activeQuestionSet = assessment.question_sets[questionSetIndex];

  return (
    <div className="progress-slider">
      <div className="progress-text">
        Step {questionSetIndex + 1} of {assessment.question_sets.length}:{' '}
        {activeQuestionSet.name}
      </div>
      <div className="questions">
        {_.flattenDeep(
          assessment.question_sets.map((qs, qsi) => (
            <Link
              key={qs.id}
              to={`/assessments/${assessment.id}/run/set/${qs.id}`}
              className={classNames({
                'question-set': true,
                past: questionSetIndex > qsi,
                current: questionSetIndex === qsi,
                future: questionSetIndex < qsi,
              })}
              style={{ flexGrow: qs.questions.length }}
            >
              {qs.questions.map((q) => {
                const classes = {
                  question: true,
                  answered: _.some(
                    q.responses,
                    (r) => r?.participant_id === participantId
                  ),
                };

                return <div key={q.id} className={classNames(classes)} />;
              })}
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default ProgressSlider;
