import { trueToFalse } from './base-answers';

export const releasingValue = {
  name: 'Releasing Value',
  key: 'releasing-value',
  category: 'develop-on-cadence',
  questions: [
    {
      text: 'Our ART releases value to customers on demand',
      key: 'release-on-demand',
      answers: trueToFalse,
    },
    {
      text: 'Our ART has efficient release processes',
      key: 'efficient-release-processes',
      answers: trueToFalse,
    },
    {
      text: 'Our ART supports customers in adopting our solutions',
      key: 'supports-customer-adoption',
      answers: trueToFalse,
    },
    {
      text: 'Our ART analyzes how customers use our solution',
      key: 'analyzes-customer-usage',
      answers: trueToFalse,
    },
  ],
};
