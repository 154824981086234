import { request } from 'js/api/axios';
import keymirror from 'js/utils/keymirror';
import { spreadApiKeys, extractApiKeys } from 'js/utils';

import { callApi } from 'js/api';

import { me } from '../account-actions';

export const types = keymirror(
  {
    ...spreadApiKeys('ADD_SELF_TO_WORKSPACE'),
  },
  { prefix: 'ACCOUNT/' }
);

export function addSelfToWorkspace(workspaceId) {
  return (dispatch, getState) => {
    const {
      identity: {
        me: { id: myId },
      },
    } = getState();

    return callApi(
      {
        types: extractApiKeys('ADD_SELF_TO_WORKSPACE', types),
        request: async (data, token) => {
          return request
            .post(`/api/workspaces/${workspaceId}/users`, data, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(async (res) => {
              await me()(dispatch);
              return res;
            });
        },
        data: {
          workspace_user: {
            workspace_id: workspaceId,
            user_id: myId,
            role: 'admin',
          },
        },
      },
      dispatch
    );
  };
}
