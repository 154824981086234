import React from 'react';
import classNames from 'classnames';

// even though we don't reference styles directly, we need to import it so that
// the styles are included in the build
import styles from './Differential.module.scss'; // eslint-disable-line no-unused-vars

const strokeDashArray = [3, 3];

export const DifferentialItem = ({
  from,
  to,
  yScale,
  width,
  height,
  markerRadius = 8,
  markerStrokeWidth = 2,
  noResultsMarkerRadius = 4,
  arrowId,
  ticks = [],
}) => {
  const hasTo = to !== undefined && to !== null;
  const hasFrom = from !== undefined && from !== null;

  const hasConnectorOverlap =
    Math.abs(yScale(from) - yScale(to)) <
    2 * (markerRadius + markerStrokeWidth);

  const radiusBuffer =
    (from - to < 0 ? -1 : 1) * (markerRadius + markerStrokeWidth / 2);

  const baseTickProps = {
    x1: width / 2 - markerRadius - 3,
    x2: width / 2 + markerRadius + 3,
    style: {
      strokeDashoffset: -(
        ((2 * (markerRadius + 3) + strokeDashArray[0]) %
          (strokeDashArray[0] + strokeDashArray[1])) /
        2
      ),
      strokeDasharray: strokeDashArray.join(' '),
    },
  };

  const noResultsStrikePath = `M -${noResultsMarkerRadius} -${noResultsMarkerRadius} l ${
    2 * noResultsMarkerRadius
  } ${2 * noResultsMarkerRadius}`;

  const connectorPath = `M ${width / 2} ${yScale(from) + radiusBuffer} L ${
    width / 2
  } ${yScale(to) - radiusBuffer - Math.sign(from - to) * 2}`;

  return (
    <g className="differential-item">
      <g>
        <line
          x1={0}
          y1={0}
          x2={width}
          y2={0}
          className="chart-axis"
          strokeWidth="1"
        />
        <line
          x1={0}
          y1={height}
          x2={width}
          y2={height}
          className="chart-axis"
          strokeWidth="1"
        />
        {ticks.map((tickValue) => {
          const y = yScale(tickValue);
          return (
            <line
              key={tickValue}
              y1={y}
              y2={y}
              className="chart-inline-tick"
              {...baseTickProps}
            />
          );
        })}
      </g>
      {hasTo && hasFrom && (
        <g>
          <path
            d={`M ${width / 2} ${yScale(from) + radiusBuffer} L ${width / 2} ${
              yScale(to) - radiusBuffer - Math.sign(from - to) * 2
            }`}
            className={classNames('differential-item-connector', {
              overlapped: hasConnectorOverlap,
            })}
            style={{ d: connectorPath }}
            markerEnd={`url(#${arrowId})`}
          />
        </g>
      )}

      <g>
        {hasFrom && (
          <circle
            cx={width / 2}
            cy={yScale(from)}
            r={markerRadius}
            className="differential-item-marker from"
          />
        )}
        {hasTo && (
          <circle
            cx={width / 2}
            cy={yScale(to)}
            r={markerRadius}
            className="differential-item-marker to"
          />
        )}
        {hasFrom && (
          <circle
            cx={width / 2}
            cy={yScale(from)}
            r={markerRadius}
            className="differential-item-marker from-ghost"
          />
        )}
      </g>
      {!(hasFrom && hasTo) && (
        <g
          transform={`translate(${width - 4 * noResultsMarkerRadius} ${
            height - markerRadius - noResultsMarkerRadius
          })`}
          className="differential-item-no-results"
        >
          {!hasTo && (
            <g transform="translate(0 0)">
              <circle
                cx={0}
                cy={0}
                r={noResultsMarkerRadius}
                className="differential-item-marker to no-results"
              />
              <path d={noResultsStrikePath} className="differential-item-x" />
            </g>
          )}
          {!hasFrom && (
            <g
              transform={`translate(0 ${
                !hasTo ? -3 * noResultsMarkerRadius : 0
              })`}
            >
              <circle
                cx={0}
                cy={0}
                r={noResultsMarkerRadius}
                className="differential-item-marker from no-results"
              />
              <path d={noResultsStrikePath} className="differential-item-x" />
            </g>
          )}
        </g>
      )}
    </g>
  );
};

export default DifferentialItem;
