import React from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import QuestionResults from './QuestionResults';
import LineWithRingsLegend from './LineWithRingsLegend';
import { ReactAutoMosaic } from 'react-auto-mosaic';

function AssessmentReviewDetailsResults({
  assessment,
  filter,
  participantCohortMap,
  cohorts,
  legendFields,
  activeCohort,
  pinnedCohort,
  peekedCohort,
  onPinCohort,
  onClearPinnedCohort,
  onPeekCohort,
  onClearPeekedCohort,
}) {
  const legendProps = {
    fields: legendFields,
    pinnedCohort,
    peekedCohort,
    onPinCohort,
    onClearPinnedCohort,
    onPeekCohort,
    onClearPeekedCohort,
  };

  const renderQuestionSets = (assessment) => {
    return assessment.question_sets.map((qs) => (
      <div key={qs.id}>
        <Card id={qs.key} key={qs.id} tag="section">
          <CardHeader>
            <CardTitle tag="h5">{qs.name}</CardTitle>
          </CardHeader>
          <CardBody className="pt-0">
            {legendFields.length > 2 ? (
              <LineWithRingsLegend {...legendProps} />
            ) : null}

            <section className="survey-review-grid">
              {qs.questions.map((q) => (
                <QuestionResults
                  key={q.id}
                  id={`${qs.key}_${q.key}`}
                  question={q}
                  filter={filter}
                  participantCohortMap={participantCohortMap}
                  cohorts={cohorts}
                  activeCohort={activeCohort}
                />
              ))}
            </section>
          </CardBody>
        </Card>
      </div>
    ));
  };

  return (
    <div className="content survey-review-detail">
      {!assessment ? null : (
        <ReactAutoMosaic
          gridColumns={12}
          gridGutterWidth={10}
          gridBreakpoints={{
            1920: 12,
            6000: 6,
          }}
        >
          {renderQuestionSets(assessment)}
        </ReactAutoMosaic>
      )}
    </div>
  );
}

export default AssessmentReviewDetailsResults;
