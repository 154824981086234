import { canManageAccount } from 'js/utils/authorization-utils';
import React from 'react';
import { Link } from 'react-router-dom';

function PastDueMessage({ me, activeAccount }) {
  let message;

  if (activeAccount.billing_type === 'stripe') {
    if (canManageAccount(me, activeAccount.id)) {
      message = (
        <>
          Payment for this account is past due. To continue to benefit from
          premium features please visit the{' '}
          <Link to={`/a/${activeAccount.id}/settings/billing`}>
            account billing page
          </Link>{' '}
          and provide valid payment as soon as possible.
        </>
      );
    } else {
      message = (
        <>
          Payment for this account is past due. To continue to benefit from
          premium features please notify your account admin.
        </>
      );
    }
  } else {
    if (canManageAccount(me, activeAccount.id)) {
      message = (
        <>
          Payment for this account is past due. To continue to benefit from
          premium features please provide valid payment as soon as possible.
        </>
      );
    } else {
      message = (
        <>
          Payment for this account is past due. To continue to benefit from
          premium features please notify your account admin.
        </>
      );
    }
  }

  return message;
}

export default PastDueMessage;
