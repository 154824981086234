import React from 'react';
import { Icon, PanelHeader } from 'js/components';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

const InvoiceBillingComponent = ({ _account, product }) => {
  return (
    <div>
      <PanelHeader size="flex" />
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Account Billing</CardTitle>
            <div className="account-settings-container">
              <Icon
                icon={['far', 'file-invoice-dollar']}
                size="3x"
                className="mr-3 text-muted"
                fixedWidth
                data-test="invoice-icon"
              />{' '}
              <div>
                <div className="account-settings">
                  <div className="label">Billing method:</div>
                  <div className="value" data-test="billing-method-value">
                    Invoice
                  </div>
                </div>
                <div className="account-settings">
                  <div className="label">Product:</div>
                  <div className="value">{product?.name}</div>
                </div>
                <div></div>
              </div>
            </div>
            <div>
              <br />
              <i className="reseller-msg">
                Please contact your reseller for pricing information.
              </i>
            </div>
          </CardHeader>
          <CardBody></CardBody>
        </Card>
      </div>
    </div>
  );
};

export default InvoiceBillingComponent;
