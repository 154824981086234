import React from 'react';
import { map, sum } from 'lodash';
import ConditionalLinkWrapper from '../ConditionalLinkWrapper';

const UnscoredQuestionResult = ({
  tag: Tag = 'div',
  questionData,
  questionHref,
  parameters,
  color,
}) => {
  const [minScore, maxScore] = questionData.answerDomain;
  const domainScalar = minScore !== maxScore ? maxScore - minScore : 1;
  const normalizedScore =
    sum(
      map(questionData.responseTotals, (count, answer) => {
        return count * answer;
      }).filter((x) => !!x)
    ) /
    questionData.responseCount /
    domainScalar;

  const score = questionData.meanScore ?? null;

  const responseColor =
    questionData.responseCount === 0 ? '#61c4d2' : color(normalizedScore);

  return (
    <Tag
      className="assessment-history-question unscored"
      data-score={score}
      title="Unscored - This question does not count toward the overall assessment score"
    >
      <div
        className="assessment-history-unscored-background"
        style={{ backgroundColor: responseColor }}
      />
      <ConditionalLinkWrapper
        wrap={!!questionHref}
        href={questionHref}
        parameters={parameters}
      />
    </Tag>
  );
};

export default UnscoredQuestionResult;
