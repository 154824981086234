import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { faLevelUpAlt } from '@fortawesome/pro-regular-svg-icons';
import { faTrophyAlt } from '@fortawesome/pro-light-svg-icons';
import { Icon, IconStack } from 'js/components';

const UpgradeNudge = ({ children }) => {
  return (
    <Card>
      <CardBody className="media text-muted">
        <IconStack className="launch-icon mr-3" size="4x" fixedWidth>
          <Icon icon={faTrophyAlt} transform="shrink-3" />
          <Icon icon={faLevelUpAlt} transform="right-8 down-2 shrink-10" />
        </IconStack>

        <div className="media-body">{children}</div>
      </CardBody>
    </Card>
  );
};

export default UpgradeNudge;
