import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import workspaceSummaryStyles from '../WorkspaceSummary.module.scss';

import {
  Button,
  FormFeedback,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { Icon } from 'js/components';
import { mergeSubmissionErrorsWithFormik } from 'js/utils/formik';

const AddWorkspaceForm = ({
  values,
  touched,
  errors: formikErrors,
  submissionErrors,
  submissionValues,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit,
  handleReset,
  onCancel = () => {},
}) => {
  const errors = mergeSubmissionErrorsWithFormik(
    submissionErrors,
    submissionValues,
    formikErrors,
    values
  );

  const formikAttributes = (fieldName, errorFieldName) => {
    errorFieldName = errorFieldName || fieldName;

    return {
      id: fieldName,
      name: fieldName,
      value: values[fieldName],
      onChange: handleChange,
      onBlur: handleBlur,
      invalid: errors[fieldName] && touched[fieldName],
    };
  };

  const feedback = (fieldName, errorFieldName) => {
    errorFieldName = errorFieldName || fieldName;

    const messages = [];

    if (errors[fieldName] && touched[fieldName]) {
      messages.push(
        <FormFeedback key={errors[fieldName]}>{errors[fieldName]}</FormFeedback>
      );
    }

    return messages;
  };

  return (
    <Card>
      <CardTitle className={workspaceSummaryStyles.name}>
        New workspace
      </CardTitle>
      <CardBody className={workspaceSummaryStyles.body}>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name`">
              What do you want to name your new workspace?
            </Label>
            <Input type="text" autoFocus={true} {...formikAttributes('name')} />
            {feedback('name')}
          </FormGroup>
          <Button type="submit" color="primary" disabled={isSubmitting}>
            {isSubmitting ? (
              <Icon
                icon={['far', 'circle-notch']}
                className="btn-icon-left"
                fixedWidth
                spin
              />
            ) : (
              <Icon icon="plus" className="btn-icon-left" fixedWidth />
            )}
            Create workspace
          </Button>
          <Button
            type="reset"
            onClick={() => {
              handleReset();
              onCancel();
            }}
          >
            Not right now
          </Button>
        </form>
      </CardBody>
    </Card>
  );
};

export default withFormik({
  mapPropsToValues: () => {
    return {
      name: '',
    };
  },
  mapPropsToErrors: ({ submissionErrors = {} }) => submissionErrors,
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(
        3,
        "You need at least 3 characters in your name, and spaces don't count"
      )
      .max(
        100,
        "I like long names, but that's a bit much. Keep it under 100 characters please (or things are going to start looking ugly around here)"
      )
      .required(
        "I'm sorry, Dave. I'm afraid I can't do that. You need a name."
      ),
  }),
  handleSubmit: (values, bag) => {
    const {
      setSubmitting,
      isSubmitting,
      props: { onSubmit },
    } = bag;
    if (isSubmitting) {
      return false;
    }

    setSubmitting(true);
    Promise.resolve(
      onSubmit({
        name: values.name,
      })
    ).then(() => setSubmitting(false));
  },
  displayName: 'AddWorkspaceForm',
})(AddWorkspaceForm);
