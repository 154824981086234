export const productQuestionSets = [
  {
    name: 'Customer feedback',
    key: 'customer feedback',
    category: 'product',
    questions: [
      {
        text: 'Our organization regularly seeks out customer feedback and incorporates it into the design of our products',
        key: 'seek-customer-feedback',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Customer feedback is shared directly with development teams',
        key: 'share-customer-feedback',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Experimentation',
    key: 'experimentation',
    category: 'product',
    questions: [
      {
        text: 'Every idea starts with a value-based hypothesis (e.g. this will lower support costs or increase retention)',
        key: 'hypothesis-driven-development',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'On average, I spend ____ experimenting and innovating',
        key: 'experimentation-time',
        answers: [
          { value: '1', text: 'less than one day per year' },
          { value: '2', text: 'between one day per year and one day per quarter' },
          { value: '3', text: 'between one day per quarter and one day per month' },
          { value: '4', text: 'between one day per month and one day per week' },
          { value: '5', text: 'at least one day per week' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Our team is able to try out new ideas and evaluate their success without requiring approval',
        key: 'freedom-to-experiment',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Experimentation is a regular part of our process',
        key: 'regular-experimentation',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Focus',
    key: 'focus',
    category: 'product',
    questions: [
      {
        text: '____ of the work my team does moves us closer to our strategic goals.',
        key: 'work-goal-alignment',
        answers: [
          { value: '2', text: '0-25%' },
          { value: '3', text: '25-50%' },
          { value: '4', text: '50-75%' },
          { value: '5', text: '75-100%' },
          { value: '1', text: `I'm not sure what our strategic goals are` },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Our organization works on ____ goals simultaneously',
        key: 'goal-wip',
        answers: [
          { value: '3', text: 'Too few' },
          { value: '4', text: 'Just enough' },
          { value: '2', text: 'Too many' },
          { value: '1', text: 'Far too many' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Our team usually has ____ work items in progress (started but not completed)',
        key: 'work-item-wip',
        answers: [
          { value: '5', text: '1-2' },
          { value: '4', text: '3-5' },
          { value: '3', text: '6-10' },
          { value: '2', text: '11-15' },
          { value: '1', text: 'more than 15' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Before a release, our team usually has ____ items completed and waiting to deploy',
        key: 'deployment-queue',
        answers: [
          { value: '6', text: '1' },
          { value: '5', text: '2-5' },
          { value: '4', text: '6-10' },
          { value: '3', text: '11-20' },
          { value: '2', text: '20-30' },
          { value: '1', text: 'more than 30' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Prioritization',
    key: 'prioritization',
    category: 'product',
    questions: [
      {
        text: 'We spend the right amount of time on analysis and prioritization before deciding to do work',
        key: 'proper-analysis',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We use an objective process (e.g. WSJF, MoSCoW) to guide our prioritization decisions',
        key: 'economic-prioritization-model',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'The priority of major initiatives is aligned across our organization',
        key: 'priority-alignment',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'When we take on unplanned work, we have made an intentional decision to prioritize it over other work',
        key: 'intentionally-prioritize-churn',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Small batches',
    key: 'small batches',
    category: 'product',
    questions: [
      {
        text: 'We break work down into small, shippable increments that can be completed in less than a week',
        key: 'small-increments',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We release minimal versions of features or products and iterate on them as we learn what customers value most',
        key: 'mvp',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Strategic Vision',
    key: 'strategic vision',
    category: 'product',
    questions: [
      {
        text: `I can clearly communicate my organization's vision to people outside of my organization`,
        key: 'understand-org-vision',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'I understand how our strategic vision differentiates us from our competitors',
        key: 'understand-strategic-differentiation',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
];

export default productQuestionSets;
