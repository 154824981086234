// To use Phoenix channels, the first step is to import Socket
// and connect at the socket path in "lib/web/endpoint.ex":
import { Socket } from 'phoenix';

let socket = null;
let currentToken = null;

// When you connect, you'll often need to authenticate the client.
// For example, imagine you have an authentication plug, `MyAuth`,
// which authenticates the session and assigns a `:current_user`.
// If the current user exists you can assign the user's token in
// the connection for use in the layout.
//
// In your "lib/web/router.ex":
//
//     pipeline :browser do
//       ...
//       plug MyAuth
//       plug :put_user_token
//     end
//
//     defp put_user_token(conn, _) do
//       if current_user = conn.assigns[:current_user] do
//         token = Phoenix.Token.sign(conn, "user socket", current_user.id)
//         assign(conn, :user_token, token)
//       else
//         conn
//       end
//     end
//
// Now you need to pass this token to JavaScript. You can do so
// inside a script tag in "lib/web/templates/layout/app.html.eex":
//
//     <script>window.userToken = "<%= assigns[:user_token] %>";</script>
//
// You will need to verify the user token in the "connect/2" function
// in "lib/web/channels/user_socket.ex":
//
//     def connect(%{"token" => token}, socket) do
//       # max_age: 1209600 is equivalent to two weeks in seconds
//       case Phoenix.Token.verify(socket, "user socket", token, max_age: 1209600) do
//         {:ok, user_id} ->
//           {:ok, assign(socket, :user, user_id)}
//         {:error, reason} ->
//           :error
//       end
//     end
//
// Finally, pass the token on connect as below. Or remove it
// from connect if you don't care about authentication.

export function connect(token) {
  if (!token) {
    return null;
  }

  if (currentToken !== token && !!socket) {
    socket.disconnect();
    socket = null;
  }

  if (!socket) {
    socket = new Socket('/socket', {
      params: { token }
    });

    // socket.onOpen(ev => console.log("OPEN", ev))
    // socket.onError(ev => console.log("ERROR", ev))
    // socket.onClose(e => console.log("CLOSE", e))

    socket.connect();
    currentToken = token;
  }

  return socket;
}

// setInterval(() => { if(socket) { console.log(socket.connectionState()) }}, 3000)

// Now that you are connected, you can join channels with a topic:
//let channel = socket.channel("topic:subtopic", {})
//channel.join()
//.receive("ok", resp => { console.log("Joined successfully", resp) })
//.receive("error", resp => { console.log("Unable to join", resp) })
