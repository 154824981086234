import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ManageOrg from 'js/routes/Organizations/components/ManageOrg/ManageOrg';
import {
  addChildToOrg,
  addTeamsToOrg,
  createOrg,
  deleteOrg,
  list as listOrganizations,
  load,
  removeChildFromOrg,
  removeTeamFromOrg,
  updateOrg,
} from 'js/actions/org-actions';
import { denormalize } from 'normalizr';
import { SCHEMAS } from 'js/api/schemas';
import { list as listTeams } from 'js/actions/team-actions';
import _, { noop } from 'lodash';
import { notify } from 'js/actions/alert-actions';
import { selectWorkspaceOrgsQuery } from 'js/store/reducers/queries';
import { selectActiveWorkspace } from 'js/store/reducers/workspaces';

const ManageOrgContainer = ({
  orgId,
  org,
  loadOrg,
  teams,
  listTeams,
  teamsQueryId,
  addTeamsToOrg,
  notify,
  createOrg,
  workspace,
  listOrganizations,
  organizationsQueryResult,
  addChildToOrg,
  removeChildFromOrg,
  removeTeamFromOrg,
  deleteOrg,
  updateOrg,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const fetchOrg = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        await loadOrg(orgId);
      } catch (e) {
        console.error('failed to load the org:', e);
        setError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [loadOrg, setError, setLoading, orgId]);

  useEffect(() => {
    fetchOrg();
  }, [fetchOrg]);

  useEffect(() => {
    listTeams({}, teamsQueryId).catch(noop);
  }, [listTeams, teamsQueryId]);

  useEffect(() => {
    listOrganizations(workspace.id).catch(_.noop);
  }, [listOrganizations, workspace.id]);

  const handleAddTeamsToOrg = useCallback(
    (org, teamIds) => {
      return (async () => {
        try {
          await addTeamsToOrg(org, teamIds);
          fetchOrg();
          notify({
            type: 'success',
            message: 'The teams have been added!',
          });
        } catch (e) {
          console.error('failed to add teams to org:', e);
          notify({
            type: 'danger',
            message:
              'Uh oh! There was a problem adding the teams to the organization.',
          });
        }
      })();
    },
    [addTeamsToOrg, notify, fetchOrg]
  );

  const handleAddGroup = useCallback(
    (parent, group) => {
      return (async () => {
        try {
          await createOrg(group, parent.id);
          fetchOrg();
          notify({
            type: 'success',
            message: 'The group has been added!',
          });
        } catch (e) {
          console.error('failed to create the org:', e);
          notify({
            type: 'danger',
            message: 'Uh oh! There was a problem adding the group.',
          });
        }
      })();
    },
    [createOrg, notify, fetchOrg]
  );

  const handleAddOrgRef = useCallback(
    (parent, orgRefId) => {
      return (async () => {
        try {
          await addChildToOrg(parent.id, orgRefId);
          fetchOrg();
          notify({
            type: 'success',
            message: 'The organization has been added!',
          });
        } catch (e) {
          console.error('failed to add the organization:', e);
          notify({
            type: 'danger',
            message: 'Uh oh! There was a problem adding the organization.',
          });
        }
      })();
    },
    [addChildToOrg, fetchOrg, notify]
  );

  const handleRemoveNode = useCallback(
    (parent, nodeToRemove) => {
      return (async () => {
        let term;
        try {
          if (nodeToRemove.type === 'team') {
            term = 'team';
            await removeTeamFromOrg(parent.id, nodeToRemove.id);
          } else {
            if (nodeToRemove.isRoot) {
              term = 'organization';
              await removeChildFromOrg(parent.id, nodeToRemove.id);
            } else {
              term = 'group';
              await deleteOrg(nodeToRemove);
            }
          }
          fetchOrg();
          notify({
            type: 'success',
            message: `The ${term} has been removed!`,
          });
        } catch (e) {
          console.error(`failed to remove the ${term}:`, e);
          notify({
            type: 'danger',
            message: `Uh oh! There was a problem removing the ${term}.`,
          });
        }
      })();
    },
    [fetchOrg, notify, removeTeamFromOrg, removeChildFromOrg, deleteOrg]
  );

  const handleRename = useCallback(
    (pathToNode, node, newName) => {
      return (async () => {
        const term = node.isRoot ? 'organization' : 'group';
        let names;
        if (pathToNode[pathToNode.length - 1]) {
          names = pathToNode[pathToNode.length - 1].children
            .filter((o) => o.type === 'org')
            .map((o) => o.name.toUpperCase());
        } else {
          names = (organizationsQueryResult?.data ?? []).map((o) =>
            o.name.toUpperCase()
          );
        }

        if (names.includes(newName.toUpperCase())) {
          notify({
            type: 'danger',
            message: 'Oops! That name is already taken.',
          });
          return;
        }

        try {
          await updateOrg(node.id, { name: newName });
          await fetchOrg();
        } catch (e) {
          console.error(`failed to rename the ${term}`, e);
          notify({
            type: 'danger',
            message: `Uh oh! There was a problem renaming the ${term}.`,
          });
        }
      })();
    },
    [updateOrg, fetchOrg, notify, organizationsQueryResult]
  );

  const handlePromoteToOrg = useCallback(
    (node) => {
      return (async () => {
        try {
          await updateOrg(node.id, { is_root: true });
          await fetchOrg();
        } catch (e) {
          console.error(`failed to promote the group to an organization`, e);
          notify({
            type: 'danger',
            message: `Uh oh! There was a problem promoting the group.`,
          });
        }
      })();
    },
    [updateOrg, fetchOrg, notify]
  );

  return (
    <ManageOrg
      organization={org}
      errorLoading={error}
      loading={loading}
      teams={teams}
      rootOrgs={organizationsQueryResult?.data ?? []}
      onAddTeamsToOrg={handleAddTeamsToOrg}
      onAddGroup={handleAddGroup}
      onAddOrgRef={handleAddOrgRef}
      onRemoveNode={handleRemoveNode}
      workspace={workspace}
      onRename={handleRename}
      onPromoteToOrg={handlePromoteToOrg}
    />
  );
};

export default connect(
  (store, { match }) => {
    const workspaceId = match.params.workspaceId;
    const teamsQueryId = `all-teams-${workspaceId}`;
    return {
      orgId: match.params.orgId,
      org: denormalize(
        store.entities.organizations[match.params.orgId],
        SCHEMAS.ORGANIZATION,
        store.entities
      ),
      teamsQueryId,
      teams: denormalize(
        store.queries[teamsQueryId]?.result ?? [],
        SCHEMAS.TEAM_ARRAY,
        store.entities
      ),
      organizationsQueryResult: selectWorkspaceOrgsQuery(store, workspaceId),
      workspace: selectActiveWorkspace(store),
    };
  },
  {
    loadOrg: load,
    listTeams,
    addTeamsToOrg,
    createOrg,
    notify,
    listOrganizations,
    addChildToOrg,
    removeChildFromOrg,
    removeTeamFromOrg,
    deleteOrg,
    updateOrg,
  }
)(ManageOrgContainer);
