import { library } from '@fortawesome/fontawesome-svg-core';
import * as Brands from './imports/brands';
import * as Light from './imports/light';
import * as Regular from './imports/regular';
import * as Solid from './imports/solid';

export const configureIcons = () => {
  library.add(
    ...Object.values(Brands),
    ...Object.values(Light),
    ...Object.values(Regular),
    ...Object.values(Solid)
  );
};

export default configureIcons;
