import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import className from 'classnames';

import { withFormik } from 'formik';

import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { Icon } from 'js/components';
import PendingEmailChange from './PendingEmailChange';

const EditUserForm = (props) => {
  const {
    user: { username },
    values,
    submissionErrors,
    submissionValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    message,
    isSubmitting,
    pendingEmail,
    onCancelEmailChange,
    onResendEmailVerification,
  } = props;
  const handleCancelEmailChange = useCallback(
    (...args) => {
      onCancelEmailChange(...args);
      setFieldValue('username', username);
    },
    [onCancelEmailChange, setFieldValue, username]
  );

  const isInvalid = (fieldName) => {
    // console.log(touched[fieldName]);
    return (
      (errors[fieldName] && touched[fieldName]) ||
      (submissionErrors[fieldName] &&
        values[fieldName] === submissionValues[fieldName])
    );
  };

  const formikAttributes = (fieldName) => ({
    id: fieldName,
    name: fieldName,
    value: values[fieldName],
    onChange: handleChange,
    onBlur: handleBlur,
    invalid: isInvalid(fieldName),
  });

  const feedback = (fieldName, errorFieldName) => {
    errorFieldName = errorFieldName || fieldName;

    const messages = [];

    if (errors[fieldName] && touched[fieldName]) {
      messages.push(
        <FormFeedback key={errors[fieldName]}>{errors[fieldName]}</FormFeedback>
      );
    }

    if (
      submissionErrors[fieldName] &&
      values[fieldName] === submissionValues[fieldName]
    ) {
      messages.push(
        <FormFeedback key={submissionErrors[fieldName]}>
          {submissionErrors[fieldName]}
        </FormFeedback>
      );
    }

    return messages;
  };

  return (
    <Form
      onSubmit={isSubmitting ? () => false : handleSubmit}
      className="edit-user-form"
    >
      <div>
        {message && message.length > 0 ? (
          <div className="validation-message">{message}</div>
        ) : null}
      </div>
      <Row>
        <Col>
          <FormGroup>
            <Label for="username">Email</Label>
            <Input
              type="text"
              autoFocus={true}
              {...formikAttributes('username')}
              className={className({ 'is-warning': !!pendingEmail })}
            />
            {feedback('username')}
            {!pendingEmail ? null : (
              <PendingEmailChange
                onCancel={handleCancelEmailChange}
                onResendVerification={onResendEmailVerification}
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="display_name">Name</Label>
            <Input
              type="text"
              placeholder="What should we call you?"
              {...formikAttributes('display_name')}
            />
            {feedback('display_name')}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="password">New password</Label>
            <Input type="password" {...formikAttributes('password')} />
            {feedback('password')}
          </FormGroup>
        </Col>
      </Row>
      {values.password || values.confirmPassword ? (
        <Row>
          <Col>
            <FormGroup>
              <Label for="confirmPassword">Re-enter new password</Label>
              <Input type="password" {...formikAttributes('confirmPassword')} />
              {feedback('confirmPassword')}
            </FormGroup>
          </Col>
        </Row>
      ) : null}
      {values.password || values.username !== username ? (
        <Row>
          <Col>
            <div className="alert alert-info">
              <p>Your current password is required to authorize the changes.</p>
              <FormGroup>
                <Label for="current_password">Current password</Label>
                <Input
                  type="password"
                  {...formikAttributes('current_password')}
                />
                {feedback('current_password')}
              </FormGroup>
            </div>
          </Col>
        </Row>
      ) : null}
      <div>
        <button type="submit" className="btn btn-primary">
          {isSubmitting ? (
            <Icon
              icon={['far', 'circle-notch']}
              className="btn-icon-left"
              spin
            />
          ) : null}
          Update
        </button>
      </div>
    </Form>
  );
};

const FormikEditUserForm = withFormik({
  mapPropsToValues: ({ user: { username, display_name }, pendingEmail }) => {
    return {
      username: pendingEmail || username,
      display_name: display_name || username,
      password: '',
      confirmPassword: '',
      current_password: '',
    };
  },
  mapPropsToErrors: ({ submissionErrors = {} }) => submissionErrors,
  validationSchema: ({ user: { username } }) =>
    Yup.object().shape({
      username: Yup.string()
        .trim()
        .required(
          "If you don't tell me your email, how will I know how to reach you?"
        )
        .email("Hmm, I'll need a valid email address.")
        .max(64),
      display_name: Yup.string().trim().min(3).max(32).nullable(),
      password: Yup.string().nullable(),
      confirmPassword: Yup.string()
        .test('match', 'Passwords do not match', function (password) {
          return password === this.options.parent.password;
        })
        .nullable(),
      current_password: Yup.string()
        .test(
          'cond-required',
          'Current password is required',
          function (currentPassword) {
            const currentPasswordRequired =
              this.options.parent.password ||
              this.options.parent.username !== username;
            return !currentPasswordRequired || !!currentPassword;
          }
        )
        .nullable(),
    }),
  handleSubmit: (values, bag) => {
    const { props, setSubmitting, resetForm } = bag;
    const { password, confirmPassword } = values;

    const submittedValues = { ...values };
    if (password === '' || password === null) {
      delete submittedValues.password;
    }
    if (confirmPassword === '' || confirmPassword === null) {
      delete submittedValues.confirmPassword;
    }

    setSubmitting(true);

    Promise.resolve(props.onSubmit(submittedValues))
      .then(() => {
        resetForm({
          values: {
            username: submittedValues.username,
            display_name: submittedValues.display_name,
            password: '',
            confirmPassword: '',
            current_password: '',
          },
        });
      })
      .catch(noop)
      .finally(() => {
        setSubmitting(false);
      });

    // let { passphrase } = values;
    // passphrase = passphrase.trim();

    // if (passphrase.length > 0) {
    //   setSubmitting(true);

    //   Promise.resolve(props.onJoin({ passphrase })).finally(() => {
    //     setSubmitting(false);
    //   });
    // } else {
    //   setSubmitting(false);
    // }
  },
})(EditUserForm);

FormikEditUserForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancelEmailChange: PropTypes.func,
  onResendEmailVerification: PropTypes.func,
};

FormikEditUserForm.defaultProps = {
  // submissionErrors: { username: 'poop' },
  // submissionValues: { username: 'waypointr@sketchdev.io' },
  submissionErrors: {},
  submissionValues: {},
  onSubmit: noop,
  onCancelEmailChange: noop,
  onResendEmailVerification: noop,
};

export default FormikEditUserForm;
