import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import copy from 'copy-to-clipboard';

import { Link } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import { ChartSelector, PanelHeader, Icon } from 'js/components';
import ReviewHeader from './ReviewHeader';
import AssessmentHistoryChart, {
  Glyph as AssessmentHistoryGlyph,
} from './AssessmentHistoryChart';
import {
  AssessmentDifferential,
  Glyph as AssessmentDifferentialGlyph,
} from 'js/components/Charts/AssessmentDifferential';
import { isArchived } from 'js/utils/team-utils';
import { isRecentAndActive } from 'js/utils/assessment-utils';

const AssessmentComponent = ({
  assessments,
  team,
  name,
  assessmentKey,
  dataMap,
  categoryLookups,
  categoryColorMap,
  match,
  onCopyLink,
}) => {
  const handleCopyLink = (assessmentId) => {
    const url = `${window.location.origin}/assessments/${assessmentId}/run`;

    copy(url);

    onCopyLink(url);
  };

  const isTeamArchived = isArchived(team);

  return (
    <div className="survey-assessment review">
      <PanelHeader size="flex">
        <ReviewHeader
          name={name}
          team={team}
          dataMap={dataMap}
          assessmentKey={assessmentKey}
          categoryLookups={categoryLookups}
        >
          <ChartSelector
            charts={[
              {
                label: AssessmentHistoryGlyph,
                component: AssessmentHistoryChart,
                buttonProps: { 'data-chart-type': 'assessment-history' },
                componentProps: {
                  dataMap,
                  categoryLookups,
                },
              },
              {
                label: AssessmentDifferentialGlyph,
                component: AssessmentDifferential,
                buttonProps: { 'data-chart-type': 'differential' },
                componentProps: {},
              },
            ]}
            assessments={assessments}
          />
        </ReviewHeader>
      </PanelHeader>
      <div className="content">
        <Card>
          <CardBody>
            {assessments.map((assessment) => {
              const d = moment(assessment.inserted_at);
              return (
                <div className="assessment-summary" key={assessment.id}>
                  <h3 className="assessment-date">
                    <Icon icon={['far', 'calendar-alt']} />
                    {d.format('MMM D, YYYY h:mm a')}
                    <div className="action-items">
                      <Link
                        to={`${match.url}/detail/${assessment.id}`.replace(
                          '//',
                          '/'
                        )}
                        className="btn btn-info btn-inline"
                      >
                        Details
                      </Link>
                      {!isTeamArchived && isRecentAndActive(assessment) && (
                        <Button
                          className="btn-inline"
                          color="info"
                          onClick={() => handleCopyLink(assessment.id)}
                        >
                          <Icon icon="copy" className="btn-icon-left" />
                          Participant Link
                        </Button>
                      )}
                    </div>
                  </h3>
                  <div>
                    <AssessmentHistoryChart
                      dataMap={dataMap}
                      assessmentId={assessment.id}
                      categoryLookups={categoryLookups}
                      categoryColorMap={categoryColorMap}
                    />
                  </div>
                </div>
              );
            })}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

AssessmentComponent.defaultProps = {
  onCopyLink: () => {},
};

AssessmentComponent.propTypes = {
  assessments: PropTypes.arrayOf(PropTypes.object),
};

export default AssessmentComponent;
