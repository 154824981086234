import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { denormalize } from 'normalizr';

import { SCHEMAS } from 'js/api/schemas';

import { list as listAssessments } from 'js/actions/assessment-actions';
import { getEngineForList } from 'js/engines';

const AssessmentContainer = (props) => {
  const {
    listAssessments,
    match: {
      params: { assessmentKey, teamId },
    },
    assessments,
  } = props;

  useEffect(() => {
    listAssessments({ key: assessmentKey, team_id: teamId, full: true });
  }, [assessmentKey, teamId, listAssessments]);

  const { Review } = getEngineForList(assessments, assessmentKey);

  return <Review {...{ assessments, assessmentKey, teamId }} {...props} />;
};

export default connect(
  (state, ownProps) => {
    const { assessmentKey, teamId } = ownProps.match.params;
    const { entities } = state;
    const normalizedAssessments = _.filter(entities.assessments, {
      key: assessmentKey,
      team_id: +teamId,
    });
    const assessments = denormalize(
      normalizedAssessments,
      SCHEMAS.ASSESSMENT_ARRAY,
      entities
    );

    return {
      assessments,
    };
  },
  {
    listAssessments,
  }
)(AssessmentContainer);
