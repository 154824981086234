import React, { useMemo } from 'react';
import { median } from 'js/utils/math';
import { map, reduce, groupBy, mean } from 'lodash';
import classNames from 'classnames';
import SetDetailChart from '../SetDetailChart';

function Variability({ varianceScore }) {
  switch (true) {
    case varianceScore > 1:
      return <h5 className="high-variance">High</h5>;

    case varianceScore > 0.2:
      return <h5 className="medium-variance">Medium</h5>;

    case varianceScore === null:
      return <h5 className="text-muted">N/A</h5>;

    default:
      return <h5 className="low-variance">Low</h5>;
  }
}

const AssessmentSubview = ({ questionSets, categoryColorMap }) => {
  const detailData = useMemo(() => {
    const qsByCategory = groupBy(questionSets, (qs) => qs.category);
    return map(qsByCategory, (sets, categoryKey) => {
      const { totalScore, count } = reduce(
        sets,
        (acc, qs) => {
          return qs.normalizedScore === null
            ? acc
            : {
                totalScore: acc.totalScore + qs.normalizedScore,
                count: acc.count + 1,
              };
        },
        { totalScore: 0, count: 0 }
      );

      const normalizedScore = count > 0 ? totalScore / count : null;

      const variances = map(sets, (s) =>
        s.normalizedScore === null
          ? null
          : Math.pow(s.normalizedScore - normalizedScore, 2)
      ).filter((x) => x !== null);
      const normalizedVariance =
        variances.length === 0 ? null : mean(variances);

      return {
        key: categoryKey,
        normalizedScore,
        normalizedVariance,
        className: categoryColorMap[categoryKey],
        details: sets,
      };
    });
  }, [questionSets, categoryColorMap]);

  const scores = detailData
    .map((d) => d.normalizedScore)
    .filter((x) => x !== null);
  const medianScore = scores.length === 0 ? null : median(scores);
  const meanScore = scores.length === 0 ? null : mean(scores);
  const { score: varianceRawScore, count: varianceCount } = detailData.reduce(
    (acc, d) => {
      const stdDev =
        d.normalizedVariance === null ? null : Math.sqrt(d.normalizedVariance);

      const normalizedDev =
        d.normalizedScore === null
          ? null
          : Math.abs(meanScore - d.normalizedScore);

      let selfScore = 0;
      if (normalizedDev >= 0.2) {
        selfScore = 2;
      } else if (normalizedDev >= 0.07) {
        selfScore = 1;
      }

      let childrenScore = 0;
      if (stdDev >= 0.2) {
        childrenScore = 2;
      } else if (stdDev >= 0.07) {
        childrenScore = 1;
      }

      const score = selfScore + childrenScore;

      return normalizedDev !== null || stdDev !== null
        ? { score: acc.score + score, count: acc.count + 1 }
        : acc;
    },
    { score: 0, count: 0 }
  );
  const varianceScore =
    varianceCount === 0 ? null : varianceRawScore / varianceCount;

  return (
    <>
      <section className="figure-stats">
        <label>Viewing</label>
        <h3 className="view-name">Assessment overview</h3>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <label>Mean Score</label>
                <h5
                  className={classNames({ 'text-muted': meanScore === null })}
                >
                  {meanScore === null
                    ? 'N/A'
                    : `${
                        Math.round(meanScore * 1000 + Number.EPSILON) / 10
                      }/100`}
                </h5>
              </td>
              <td>
                <label>Median Score</label>
                <h5
                  className={classNames({ 'text-muted': medianScore === null })}
                >
                  {medianScore === null
                    ? 'N/A'
                    : `${
                        Math.round(medianScore * 1000 + Number.EPSILON) / 10
                      }/100`}
                </h5>
              </td>
            </tr>
            <tr>
              <td>
                <label>Variability</label>
                <Variability varianceScore={varianceScore} />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="figure-spacer"></section>
      <section className="figure-detail">
        <label>Aggregate Scores</label>
        <SetDetailChart data={detailData} />
      </section>
    </>
  );
};

export default AssessmentSubview;
