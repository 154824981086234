import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';

import { PanelHeader, Overlay } from 'js/components';

import Question from '../Question';
// import BallotMarker from './BallotMarker';
import BallotNav from './BallotNav';
import BallotStatus from './BallotStatus';
import WelcomeForm from './WelcomeForm';
import { useParticipants } from '../../hooks/useParticipants';

const LiveAssessmentRunComponent = (props) => {
  const {
    assessment,
    onRespond,
    participant,
    isSynced,
    liveAssessmentData,
    presences,
    onCompleteWelcome,
    onNextQuestion,
    onPreviousQuestion,
  } = props;

  let { question } = props;
  const participants = useParticipants(assessment, presences);

  if (!liveAssessmentData) {
    return null;
  }
  question =
    question ||
    (assessment.question_sets && assessment.question_sets.length > 0
      ? assessment.question_sets[0].questions[0]
      : null);

  const handleVote = (value) => {
    onRespond(
      assessment.id,
      question,
      _.find(question.answers, ['value', value])
    );
  };

  const isHost = liveAssessmentData.host_id === participant.id;

  const myResponse = _.find(
    question.responses,
    (r) => r.participant_id === participant.id
  );

  const voteClasses = myResponse
    ? {
        'vote-red': myResponse.value === '0',
        'vote-yellow': myResponse.value === '1',
        'vote-green': myResponse.value === '2',
      }
    : {};

  const myClass = {
    'assessment live-assessment voting-booth': true,
    ...voteClasses,
  };

  return (
    <div className={classnames(myClass)}>
      <PanelHeader size="xs" />
      <div className="content has-bottom-bar">
        <div className="ballot">
          <BallotStatus
            question={question}
            responses={question.responses || []}
            response={myResponse}
            participantCount={participants.length}
          />
          <BallotNav
            assessment={assessment}
            isHost={isHost}
            isSynced={isSynced}
            allowPreviousQuestion={
              question &&
              question.id !== assessment.question_sets[0].questions[0].id
            }
            onNextQuestion={onNextQuestion}
            onPreviousQuestion={onPreviousQuestion}
          />
          <Question
            question={question}
            onVote={handleVote}
            response={myResponse}
          >
            <div className="status-info-placeholder" />
          </Question>
        </div>
      </div>
      {/* <div className="detached-nav-bar">
        <BallotMarker onVote={handleVote} />
      </div> */}
      {(!participant || !participant.completed_welcome) && (
        <Overlay>
          {participant && (
            <WelcomeForm
              assessment={assessment}
              team={assessment.team}
              participant={participant}
              onComplete={onCompleteWelcome}
            />
          )}
        </Overlay>
      )}
      <style>{`
        .main-panel { padding-bottom: 0; }
        .footer { display: none; }
      `}</style>
    </div>
  );
};

LiveAssessmentRunComponent.propTypes = {
  onRespond: PropTypes.func,
  onNextQuestion: PropTypes.func,
  onPreviousQuestion: PropTypes.func,
};

LiveAssessmentRunComponent.defaultProps = {
  onRespond: () => {},
  onNextQuestion: () => {},
  onPreviousQuestion: () => {},
};

export default LiveAssessmentRunComponent;
