import keymirror from 'keymirror';
import { request } from 'js/api/axios';

import { spreadApiKeys, extractApiKeys } from '../utils';
import { callApi } from '../api';
import { SCHEMAS } from '../api/schemas';

export const types = keymirror({
  ...spreadApiKeys('LIST_PRODUCTS'),
  ...spreadApiKeys('CREATE_PURCHASE_REQUEST'),
  ...spreadApiKeys('CREATE_PURCHASE_SUCCESS'),
});

export function list() {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LIST_PRODUCTS, types),
        request: () => request.get(`/api/products`),
        schema: SCHEMAS.PRODUCT_ARRAY,
      },
      dispatch
    );
  };
}

export function createPurchaseRequest(productId, priceId) {
  const urlPrefix = `${window.location.protocol}//${window.location.host}`;
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_PURCHASE_REQUEST, types),
        request: (data, token) =>
          request.post(`/api/products/${productId}/purchase-request`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: {
          price_id: priceId,
          cancel_url: `${urlPrefix}/pricing`,
          success_url: `${urlPrefix}/products/${productId}/purchase-success/{CHECKOUT_SESSION_ID}`,
        },
      },
      dispatch
    );
  };
}

export function createPurchaseSuccess(productId, checkoutSessionId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_PURCHASE_SUCCESS, types),
        request: (data, token) =>
          request.post(`/api/products/${productId}/purchase-success`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: {
          checkout_session_id: checkoutSessionId,
        },
      },
      dispatch
    );
  };
}
