import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '..';
import AsyncButton from '../Forms/AsyncButton';
import { getQuestionCount, getResponseCount } from 'js/utils/assessment-utils';
import { isArchived } from 'js/utils/team-utils';

const InviteeList = ({ invitees, onSendReminders, assessment, team }) => {
  const questionCount = useMemo(() => {
    return getQuestionCount(assessment);
  }, [assessment]);

  const countResponses = useCallback(
    (invite) => {
      const count = invite.participant_id
        ? getResponseCount(assessment, invite.participant_id)
        : 0;
      return count;
    },
    [assessment]
  );

  return (
    <div className="invitee-list">
      {invitees.length === 0 && (
        <p data-test="no-invitees-msg">There are no invitees</p>
      )}
      {invitees.map((inv) => (
        <div className="invitee" key={inv.token}>
          <div className="email">{inv.email ?? 'Anonymous'}</div>
          <div className="status">
            {inv.accepted_at ? (
              countResponses(inv) < questionCount ? (
                <span>
                  In Progress ({countResponses(inv)} of {questionCount})
                </span>
              ) : (
                <span className="success">
                  Completed
                  <Icon
                    icon={['fas', 'check']}
                    transform="shrink-1"
                    className="ml-2"
                  />
                </span>
              )
            ) : (
              <span className="info">Invitation pending</span>
            )}
          </div>
          <div className="actions">
            {(!inv.accepted_at || countResponses(inv) < questionCount) &&
              inv.email && (
                <AsyncButton
                  data-test="remind-btn"
                  className="icon-btn m-0"
                  size="sm"
                  color="primary"
                  disabled={isArchived(team)}
                  outline
                  onClick={() => onSendReminders([inv.email])}
                >
                  <Icon icon="redo" size="sm" className="" /> Remind
                </AsyncButton>
              )}
          </div>
        </div>
      ))}
    </div>
  );
};

InviteeList.propTypes = {
  invitees: PropTypes.array.isRequired,
};

InviteeList.defaultProps = {};

export default InviteeList;
