import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { download } from 'js/actions/file-actions';

const FileDownload = ({
  tag: Tag = 'button',
  download,
  href,
  onClick,
  ...props
}) => {
  const handleFileDownload = (...args) => {
    download(href, {});
    if (onClick) {
      onClick(...args);
    }
  };

  return <Tag {...props} onClick={handleFileDownload} />;
};

FileDownload.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default connect(
  null,
  {
    download
  }
)(FileDownload);
