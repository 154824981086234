import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withFormik } from 'formik';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  FormFeedback
} from 'reactstrap';
import * as Yup from 'yup';
import { titleCase } from 'js/utils/string';

import { Icon } from 'js/components';

const ResendForm = ({
  state,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting
}) => {
  const formikAttributes = (fieldName, errorFieldName) => {
    errorFieldName = errorFieldName || fieldName;

    return {
      id: fieldName,
      name: fieldName,
      value: values[fieldName],
      onChange: handleChange,
      onBlur: handleBlur,
      invalid:
        !!(state.error && state.error[errorFieldName]) ||
        (errors[fieldName] && touched[fieldName])
    };
  };

  const feedback = (fieldName, errorFieldName) => {
    errorFieldName = errorFieldName || fieldName;

    const messages = [];

    if (errors[fieldName] && touched[fieldName]) {
      messages.push(
        <FormFeedback key={errors[fieldName]}>{errors[fieldName]}</FormFeedback>
      );
    }

    if (state.error && state.error[fieldName]) {
      state.error[fieldName].forEach(message => {
        messages.push(
          <FormFeedback key={message}>
            {titleCase(fieldName)} {message}
          </FormFeedback>
        );
      });
    }

    return messages;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input type="text" {...formikAttributes('email')} />
            {feedback('email')}
          </FormGroup>
        </Col>
      </Row>

      <Button
        type="submit"
        color="primary"
        size="lg"
        className="full-width-sm"
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <Icon
            icon={['far', 'circle-notch']}
            className="btn-icon-left"
            size="lg"
            spin
          />
        ) : (
          <Icon
            icon={['fas', 'envelope']}
            className="btn-icon-left"
            size="lg"
          />
        )}
        Send me another!
      </Button>
    </Form>
  );
};

ResendForm.propTypes = {
  onSubmit: PropTypes.func
};

ResendForm.defaultProps = {
  onSubmit: _.noop,
  state: {},
  email: null
};

const FormikResendForm = withFormik({
  mapPropsToValues: ({ email }) => {
    return {
      email: email || ''
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Hmm, I'll need a valid email address.")
  }),
  handleSubmit: async (values, bag) => {
    const { setSubmitting, props } = bag;
    setSubmitting(true);

    try {
      await Promise.resolve(props.onSubmit(values));
    } catch {
      // do nothing
    }

    setSubmitting(false);
  },
  displayName: 'TeamForm'
})(ResendForm);

export default FormikResendForm;
