import React from 'react';

import { Loading, Overlay, PanelHeader } from 'js/components';

const FullPageLoading = (props) => {
  return (
    <div className="full-page-loading" {...props}>
      <PanelHeader size="xs" />
      <div className="content">
        <Loading />
        <Overlay />
      </div>
    </div>
  );
};

export default FullPageLoading;
