export const organizationQuestionSets = [
  {
    name: 'Team Independence',
    key: 'team-independence',
    category: 'org-structure',
    questions: [
      {
        text: 'We can complete our work without needing to communicate and coordinate with other teams',
        key: 'independent-team-work',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: `We can change our team's priorities without needing to coordinate with people outside of our team`,
        key: 'independent-priorities',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Team structure',
    key: 'team-structure',
    category: 'org-structure',
    questions: [
      {
        text: 'Our developers and testers are able to dedicate ____ of their time to our team',
        key: 'dev-dedication',
        answers: [
          { value: '1', text: 'Less than 50%' },
          { value: '2', text: '50-75%' },
          { value: '3', text: '75-90%' },
          { value: '4', text: '90-100%' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Our other team members (not developers or testers) are able to dedicate ____ of their time to our team',
        key: 'other-dedication',
        answers: [
          { value: '1', text: 'Less than 50%' },
          { value: '2', text: '50-75%' },
          { value: '3', text: '75-90%' },
          { value: '4', text: '90-100%' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Our team has all of the skills and knowledge necessary to complete and deliver our work without relying on other teams',
        key: 'cross-functional-team',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Everyone on our team is working toward the same goals even if they have different managers',
        key: 'team-goal-alignment',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Hiring & Onboarding',
    key: 'hiring-onboarding',
    category: 'org-structure',
    questions: [
      {
        text: 'We have a great system for finding the right candidates for our teams',
        key: 'finding-candidates',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'New team members have all required hardware, software and access within ____ of their start date',
        key: 'onboarding-equipment',
        answers: [
          { value: '5', text: '1-2 days' },
          { value: '4', text: '1 week' },
          { value: '3', text: '1-2 weeks' },
          { value: '2', text: '2 weeks to 1 month' },
          { value: '1', text: 'More than 1 month' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'New team members are provided with enough information about technology, products and business functions to effectively do their job',
        key: 'onboarding-domain-knowledge',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'New team members are able to make a contribution within ____ of their start date',
        key: 'onboarding-time-to-contribution',
        answers: [
          { value: '5', text: '1-2 days' },
          { value: '4', text: '1 week' },
          { value: '3', text: '1-2 weeks' },
          { value: '2', text: '2 weeks to 1 month' },
          { value: '1', text: 'More than 1 month' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Accountability',
    key: 'accountability',
    category: 'org-structure',
    questions: [
      {
        text: 'My organization relies heavily on a handful of experts with deep knowledge of our systems to get things done.',
        key: 'reliance-on-heros',
        answers: [
          { value: '7', text: 'Strongly disagree' },
          { value: '6', text: 'Disagree' },
          { value: '5', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '3', text: 'Somewhat agree' },
          { value: '2', text: 'Agree' },
          { value: '1', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'People have a clear understanding of their role and responsibilities',
        key: 'role-clarity',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'People are held accountable for things outside their scope of control',
        key: 'role-accountability-control',
        answers: [
          { value: '7', text: 'Strongly disagree' },
          { value: '6', text: 'Disagree' },
          { value: '5', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '3', text: 'Somewhat agree' },
          { value: '2', text: 'Agree' },
          { value: '1', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We experience delays due to role ambiguity (too many cooks, parallel processes, etc.)',
        key: 'role-ambiguity',
        answers: [
          { value: '4', text: 'Less than once per month' },
          { value: '3', text: 'Monthly' },
          { value: '2', text: 'Weekly' },
          { value: '1', text: 'Daily' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
];

export default organizationQuestionSets;
