import React from 'react';
import { Link } from 'react-router-dom';

function PurchaseRequestError() {
  return (
    <div className="featured-form">
      <div className="full-page-content">
        <div className="container">
          <div className="content">
            <div className="text-center">
              <h2 className="mb-5">
                Oops! There was a problem processing your request.
              </h2>
              <Link to="/pricing" className="btn btn-lg">
                Back to the pricing page
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
}

export default PurchaseRequestError;
