import React from 'react';

import ResetRequestForm from './ResetRequestForm';
import { Icon } from 'js/components';

const SendInstructions = ({ onReset, resendState }) => {
  return (
    <>
      <div className="result-icon">
        <Icon
          fixedWidth
          icon="question"
          transform="shrink-13 left-2 up-2.5"
          mask={['far', 'head-side-brain']}
        />
      </div>
      <div className="result-body">
        <h2>Forgot your password?</h2>
        <div>
          <p>
            Enter your email and we'll send you some instructions on how to get
            a new one (click a link, type a new password, yada yada yada).
          </p>

          <ResetRequestForm onSubmit={onReset} state={resendState} />
        </div>
      </div>
    </>
  );
};

export default SendInstructions;
