import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

function CheckboxAnswer({
  text,
  value,
  namespace,
  selected,
  onSelect = () => {},
}) {
  const handleClick = (event) => {
    event.preventDefault();

    onSelect(!selected);
  };

  return (
    <FormGroup className="answer" onClick={handleClick}>
      <Label check>
        <Input
          type="checkbox"
          name={`${namespace}`}
          value={value}
          checked={selected}
          readOnly
        />
        {text}
      </Label>
    </FormGroup>
  );
}

export default CheckboxAnswer;
