import React, { useRef, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { Input } from 'reactstrap';

const LongTextQuestion = ({
  question,
  response,
  onRespond = () => {},
  onUnrespond = () => {},
}) => {
  const [recordedValue, setRecordedValue] = useState(response?.value || null);
  const [responseValue, setResponseValue] = useState(response?.value || '');

  const recordChangeDebounced = useRef(
    debounce((questionId, newValue, handleRespond) => {
      setRecordedValue(newValue);
      handleRespond(questionId, null, newValue);
    }, 5000)
  ).current;

  const { id: questionId } = question;
  const handleChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      const trimLength = newValue.trim().length;
      setResponseValue(newValue);
      if (trimLength > 0 && newValue !== recordedValue) {
        recordChangeDebounced(questionId, newValue, onRespond);
      } else if (trimLength === 0 && recordedValue !== null) {
        recordChangeDebounced(questionId, null, onUnrespond);
      }
    },
    [
      questionId,
      setResponseValue,
      onRespond,
      recordedValue,
      onUnrespond,
      recordChangeDebounced,
    ]
  );

  const handleBlur = useCallback(
    (e) => {
      const newValue = e.target.value;
      const trimLength = newValue.trim().length;

      recordChangeDebounced.cancel();
      if (trimLength > 0 && newValue !== recordedValue) {
        setRecordedValue(newValue);
        onRespond(questionId, null, newValue);
      } else if (trimLength === 0 && recordedValue !== null) {
        setRecordedValue(null);
        onUnrespond(questionId);
      }
    },
    [
      questionId,
      onRespond,
      recordedValue,
      setRecordedValue,
      onUnrespond,
      recordChangeDebounced,
    ]
  );

  const { text } = question;
  return (
    <div className="question">
      <div className="question-text">{text}</div>
      <div className="question-answers form-group">
        <Input
          type="textarea"
          name={question.key}
          onChange={handleChange}
          onBlur={handleBlur}
          value={responseValue}
          id={`${question.key}-answer`}
        />
      </div>
    </div>
  );
};

export default LongTextQuestion;
