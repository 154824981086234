import React from 'react';
import { connect } from 'react-redux';

import { upgrade as upgradeGuestAccount } from 'js/actions/account-actions';
import { notify } from 'js/actions/alert-actions';

import GuestAccountRouteComponent from '../components/GuestAccountRouteComponent';

class GuestAccountRouteContainer extends React.Component {
  handleUpgradeGuestAccount = values => {
    const { upgradeGuestAccount, notify } = this.props;

    return upgradeGuestAccount(values).then(() => {
      notify({
        type: 'success',
        message: 'Congratulations! Your account has been upgraded.'
      });
    });
  };

  render() {
    const { me } = this.props;

    return (
      <GuestAccountRouteComponent
        me={me}
        onUpgradeGuestAccount={this.handleUpgradeGuestAccount}
      />
    );
  }
}

export default connect(
  ({ identity }) => ({
    me: identity.me
  }),
  {
    upgradeGuestAccount,
    notify
  }
)(GuestAccountRouteContainer);
