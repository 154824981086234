export const processQuestionSets = [
  {
    name: 'Transparency & Visibility',
    key: 'transparency-and-visibility',
    category: 'process',
    questions: [
      {
        text: 'We use tools like kanban and storyboards to organize our work',
        key: 'visual-organization-of-work',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We use visual displays or dashboards to share information on completed, current and upcoming work',
        key: 'visual-communication-of-work',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Quality metrics (e.g. failure and defect rates) are:',
        key: 'quality-metrics',
        answers: [
          { value: '1', text: 'Not captured or not accurate' },
          { value: '2', text: 'Captured but not reviewed' },
          { value: '3', text: 'Reviewed by the team but rarely used to generate actions' },
          { value: '4', text: 'Regularly used to inform changes to our practices' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Productivity metrics (e.g. burndown/burnup charts, say-do ratios, churn) are:',
        key: 'productivity-metrics',
        answers: [
          { value: '1', text: 'Not captured or not accurate' },
          { value: '2', text: 'Captured but not reviewed' },
          { value: '3', text: 'Reviewed by the team but rarely used to generate actions' },
          { value: '4', text: 'Regulary used to inform changes to our process' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'I can easily see what work my team has in progress',
        key: 'visualized-our-wip',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'I can easily see what work other teams have in progress',
        key: 'view-other-team-wip',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Change management',
    key: 'change-management',
    category: 'process',
    questions: [
      {
        text: 'Our change approval process can best be described as',
        key: 'change-approval-process',
        answers: [
          { value: '4', text: 'Peer review' },
          { value: '3', text: 'High-risk changes are reviewed by an external group' },
          { value: '2', text: 'All changes are reviewed by an external group' },
          { value: '1', text: 'We have no change approval process' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'The change approval process necessary to modify production systems often takes',
        key: 'change-approval-wait-time',
        answers: [
          { value: '5', text: 'Less than 1 hour' },
          { value: '4', text: '1 hour to 1 day' },
          { value: '3', text: '1 day to 1 week' },
          { value: '2', text: '1 week to 1 month' },
          { value: '1', text: 'More than 1 month' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Flow of work',
    key: 'flow of work',
    category: 'process',
    questions: [
      {
        text: 'The flow of work through my team can best be described as',
        key: 'flow-of-work',
        answers: [
          { value: '4', text: 'Mostly smooth' },
          { value: '3', text: 'Some bottlenecks' },
          { value: '2', text: 'Frequent bottlenecks' },
          { value: '1', text: 'Mostly clogged or blocked' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'I understand how work in this organization flows from initial idea to delivery of the end result',
        key: 'understand-idea-value-stream',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We can depend on the teams and vendors that we coordinate with to deliver on-time and with high quality',
        key: 'external-dependency-reliability',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
];

export default processQuestionSets;
