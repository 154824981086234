import React, { useMemo } from 'react';
import { map, groupBy, uniqueId } from 'lodash';
import classNames from 'classnames';

import { withParentSize } from '@visx/responsive';
import { GridPolar } from '@visx/grid';
import CircleChartWedge from './CircleChartWedge';
import { scaleLinear } from '@visx/scale';
import { useCallback } from 'react';

const CircleChart = ({
  parentWidth,
  margin,
  questionSets,
  categoryColorMap,
  onSelectSlice = () => {},
  onClearSlices = () => {},
}) => {
  const [outerRingMaskId, unansweredMaskId] = useMemo(() => {
    const chartId = uniqueId('circle-chart-');
    return [`${chartId}-outer-ring-mask`, `${chartId}-unanswered-mask`];
  }, []);

  const handleClearSlices = useCallback(() => onClearSlices(), [onClearSlices]);

  const margins = { top: 0, right: 0, bottom: 0, left: 0, ...margin };

  const chartWidth = parentWidth;
  const width = parentWidth - (margins.left + margins.right);
  const height = width;
  const chartHeight = height + margins.top + margins.bottom;

  const questionSetsByCategory = useMemo(() => {
    return groupBy(questionSets, (qs) => qs.category);
  }, [questionSets]);

  const wedgeArc = (2 * Math.PI) / (Object.keys(questionSets).length || 1);
  let i = 0,
    j = 0,
    k = 0;

  const rMax = width / 2 - 5;
  const rInner = rMax - 15;
  const center = { x: width / 2, y: width / 2 };
  const radialScale = scaleLinear({ domain: [0, 1], range: [0, rInner] });

  return (
    <svg
      viewBox={`0 0 ${chartWidth} ${chartHeight}`}
      className="assessment-history-circle-chart"
    >
      <mask id={outerRingMaskId}>
        <circle
          cx={width / 2}
          cy={width / 2}
          r={rMax}
          fill="#fff"
          className="circle-chart-outer-mask"
        />
        <circle
          cx={width / 2}
          cy={width / 2}
          r={rInner}
          fill="#000"
          className="circle-chart-outer-mask"
        />
      </mask>
      <mask id={unansweredMaskId}>
        <GridPolar
          left={width / 2}
          top={width / 2}
          outerRadius={rInner}
          innerRadius={0}
          scaleAngle={radialScale}
          scaleRadial={radialScale}
          numTicksRadial={20}
          numTicksAngle={0}
          strokeWidthRadial={rInner / 40}
        />
      </mask>

      <g transform={`translate(${margins.left},${margins.right})`}>
        <g className="wedges">
          {map(questionSetsByCategory, (c, categoryKey) => {
            const result = (
              <g className={categoryColorMap[categoryKey]} key={categoryKey}>
                <CircleChartWedge
                  className="chart-wedge-category"
                  x={width / 2}
                  y={width / 2}
                  r={rMax}
                  theta={wedgeArc * c.length}
                  thetaOffset={-Math.PI / 2 + wedgeArc * k}
                  mask={`url(#${outerRingMaskId})`}
                />
              </g>
            );

            k += c.length;
            return result;
          })}
        </g>
        <g className="wedges" onMouseLeave={handleClearSlices}>
          {map(questionSetsByCategory, (c, categoryKey) => {
            return (
              <g className={categoryColorMap[categoryKey]} key={categoryKey}>
                {map(c, (qs, id) => {
                  return (
                    <g
                      key={`category-wedge-${id}`}
                      className="circle-chart-wedge-hover-target"
                      onMouseEnter={() => onSelectSlice(qs)}
                    >
                      <CircleChartWedge
                        className="chart-wedge-hover-spacer"
                        x={width / 2}
                        y={width / 2}
                        r={rInner}
                        theta={wedgeArc}
                        thetaOffset={-Math.PI / 2 + wedgeArc * i}
                      />
                      <CircleChartWedge
                        className={classNames({
                          'chart-wedge-question-set': true,
                          unanswered: qs.normalizedScore === null,
                        })}
                        x={width / 2}
                        y={width / 2}
                        r={(qs.normalizedScore ?? 1) * rInner}
                        theta={wedgeArc}
                        thetaOffset={-Math.PI / 2 + wedgeArc * i++}
                        mask={
                          qs.normalizedScore === null
                            ? `url(#${unansweredMaskId})`
                            : null
                        }
                      />
                    </g>
                  );
                })}
              </g>
            );
          })}
        </g>
        <g style={{ pointerEvents: 'none' }}>
          {map(questionSetsByCategory, (c, categoryId) => {
            const thetaOffset = -Math.PI / 2 + wedgeArc * j;

            return (
              <g key={categoryId}>
                <line
                  x1={center.x}
                  y1={center.y}
                  x2={center.x + rMax * Math.cos(thetaOffset)}
                  y2={center.y + rMax * Math.sin(thetaOffset)}
                  className="wedge-divider"
                />
                {map(c, (_qs, k) => {
                  const thetaOffsetInner = -Math.PI / 2 + wedgeArc * ++j;

                  return k === c.length - 1 ? null : (
                    <line
                      key={`arc-${k}`}
                      x1={center.x}
                      y1={center.y}
                      x2={center.x + rInner * Math.cos(thetaOffsetInner)}
                      y2={center.y + rInner * Math.sin(thetaOffsetInner)}
                      className="wedge-divider inner-divider"
                    />
                  );
                }).filter((x) => !!x)}
              </g>
            );
          })}
          {[0.2, 0.4, 0.6, 0.8].map((scale) => (
            <circle
              key={scale}
              r={scale * rInner}
              cx={width / 2}
              cy={width / 2}
              className="chart-grid-dense"
            />
          ))}
        </g>
      </g>
    </svg>
  );
};

export default withParentSize(CircleChart);
