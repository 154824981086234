import React from 'react';
import classnames from 'classnames';

import { Icon } from 'js/components';
import VoteTally from '../VoteTally';

const BallotStatus = ({ question, responses, response, participantCount }) => {
  return (
    <div className="status-info">
      {(() => {
        const voteTallyClasses = {
          'info-item vote-tally-item': true,
          'show-results': !question || !!response
        };

        return question ? (
          <div className={classnames(voteTallyClasses)}>
            <VoteTally
              responses={responses}
              type="bar"
              question={question}
              title=""
              width="30"
              height="30"
              duration="500"
              display="compact"
            />
            <span className="badged-icon vote-tally-icon">
              <Icon icon="check" />
              <span className="badge">{responses.length}</span>
            </span>
          </div>
        ) : null;
      })()}
      <div className="info-item">
        <span className="badged-icon">
          <Icon icon="user" />
          <span className="badge">{participantCount}</span>
        </span>
      </div>
    </div>
  );
};

export default BallotStatus;
