import { trueFalse, trueFalseReverse } from './base-answers';

export const agileProcess = {
  name: 'Agile Process Mechanics',
  key: 'agile-process-mechanics',
  category: 'agile-process-mechanics',
  questions: [
    {
      text: 'Team backlog has user stories those are refined at least for the first 2 sprints by the time Sprint 0 is complete.',
      key: 'two-sprints-refined',
      answers: trueFalse,
    },
    {
      text: 'Product/Solution Vision is presented during the PI Planning Event, and it was informative to your team.',
      key: 'product-solution-vision-during-pi',
      answers: trueFalse,
    },
    {
      text: 'Solution Architecture is presented during the PI Planning Event, and it was informative to your team.',
      key: 'solution-architecture-during-pi',
      answers: trueFalse,
    },
    {
      text: "Our stand-ups are long, ineffective, don't help to resolve impediments, and are more of the status calls.",
      key: 'short-effective-standups',
      answers: trueFalseReverse,
    },
    {
      text: 'Sprint planning meetings occur every sprint and our sprint planning sets our teams up for success throughout the sprint.',
      key: 'sprint-planning-every-sprint',
      answers: trueFalse,
    },
    {
      text: 'Our retros are meaningful and enable us to continuously improve and overcome obstacles.',
      key: 'meaningful-retros',
      answers: trueFalse,
    },
    {
      text: 'During sprint reviews, our showcases reflect completed user stories (demonstrable, to get the feedback from the stakeholder).',
      key: 'sprint-reviews-demo',
      answers: trueFalse,
    },
  ],
};

export default agileProcess;
