import React, { useMemo } from 'react';
import { uniq } from 'lodash';
import { histogram, max } from 'd3-array';

import { scaleLinear } from '@visx/scale';
import { LinePath, AreaClosed } from '@visx/shape';
import { Axis, Orientation } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { curveMonotoneX as curve } from '@visx/curve';

// TODO: update d3
const bin = histogram;

const axisTicks = [0, 25, 50, 75, 100];
const tickLabelPropsFactory = (scale) => (d, i, array) => {
  let propsEnhancer = null;
  switch (true) {
    case i === 0:
      propsEnhancer = {
        textAnchor: 'start',
      };
      break;

    case i === array.length - 1:
      propsEnhancer = {
        textAnchor: 'end',
      };
      break;

    default:
      propsEnhancer = {
        textAnchor: 'middle',
        x: scale(d) + 3,
      };
      break;
  }

  const result = {
    fontSize: 9,
    strokeWidth: 0,
    paintOrder: 'stroke',
    fontFamily: 'sans-serif',
    ...propsEnhancer,
  };

  return result;
};

const CompletionRateChart = ({
  questionCount,
  binCount = 10,
  participants,
  width,
  height,
  margin = {
    top: 1,
    left: 0.5,
    bottom: 15,
    right: 0.5,
  },
}) => {
  const actualBinCount = Math.max(1, Math.min(questionCount - 2, binCount));
  const scaleHeight = height - margin.top - margin.bottom;
  const scaleWidth = width - margin.left - margin.right;

  const filteredParticipants = participants.reduce(
    (acc, p) => {
      if (p.responses === 0) {
        acc.notStarted.push(p);
      } else if (p.responses >= questionCount) {
        acc.completed.push(p);
      } else {
        acc.partial.push(p);
      }
      return acc;
    },
    { notStarted: [], completed: [], partial: [] }
  );

  const x = useMemo(
    () =>
      scaleLinear({
        domain: [0, questionCount],
        range: [0, scaleWidth],
      }),
    [questionCount, scaleWidth]
  );

  const binner = useMemo(() => {
    const binIncrement = (questionCount - 2) / actualBinCount;

    const binThresholds = uniq(
      [...Array(actualBinCount)].map(
        (_b, i) => 1 + Math.round(i * binIncrement)
      )
    );

    return bin()
      .value((d) => d.responses)
      .domain([1, questionCount - 1])
      .thresholds(binThresholds);
  }, [actualBinCount, questionCount]);
  const bins = binner(filteredParticipants.partial);
  const maxBinLength = max(
    [filteredParticipants.notStarted, filteredParticipants.completed, ...bins],
    (d) => d.length
  );

  const y = useMemo(
    () =>
      scaleLinear({
        range: [scaleHeight, 1],
        domain: [0, maxBinLength],
      }),
    [scaleHeight, maxBinLength]
  );

  const areaPoints = [
    [0, y(filteredParticipants.notStarted.length)],
    ...bins.map((d) => [x(d.x0) + (x(d.x1) - x(d.x0)) / 2, y(d.length)]),
    [scaleWidth, y(filteredParticipants.completed.length)],
  ];

  const axisScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, 100],
        range: [0, scaleWidth],
      }),
    [scaleWidth]
  );

  const tickLabelProps = useMemo(
    () => tickLabelPropsFactory(axisScale),
    [axisScale]
  );

  return scaleWidth < 0 || scaleHeight < 0 ? null : (
    <figure>
      <svg viewBox={`0 0 ${width} ${height}`} className="completion-rate-chart">
        <g transform={`translate(${margin.left},${margin.top})`}>
          <AreaClosed
            className="completion-rate-chart-area fill"
            data={areaPoints}
            curve={curve}
            yScale={y}
          />
          <LinePath
            className="completion-rate-chart-area stroke"
            data={areaPoints}
            curve={curve}
          />
          <g className="chart-axis">
            <GridColumns
              className="chart-grid-dense"
              scale={axisScale}
              height={scaleHeight}
              tickValues={axisTicks}
            />
            <rect
              x="0"
              y="0"
              fill="none"
              width={scaleWidth}
              height={scaleHeight}
            />
            <Axis
              className="chart-axis"
              top={scaleHeight}
              orientation={Orientation.bottom}
              scale={axisScale}
              tickValues={axisTicks}
              tickLength={2}
              tickFormat={(d) => d + '%'}
              tickLabelProps={tickLabelProps}
            />
          </g>
        </g>
      </svg>
    </figure>
  );
};

export default CompletionRateChart;
