import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { noop } from 'lodash';

import styles from './MemberForm.module.scss';

import { Button, Label, FormGroup, FormFeedback } from 'reactstrap';
import Role from '../Role';
import { Icon } from 'js/components';
import Allocation from '../Allocation';

const EditMemberForm = ({
  teamMember,
  values,
  errors,
  handleChange,
  setFieldValue,
  handleBlur,
  touched,
  isSubmitting,
  handleSubmit,
  handleReset,
  onCancel = noop,
  onDelete = noop,
}) => {
  const formikAttributes = (fieldName) => ({
    id: fieldName,
    name: fieldName,
    value: values[fieldName],
    onChange: handleChange,
    onBlur: handleBlur,
    invalid: errors[fieldName] && touched[fieldName],
  });

  const formikFeedback = (fieldName) => {
    return errors[fieldName] && touched[fieldName] ? (
      <FormFeedback>{errors[fieldName]}</FormFeedback>
    ) : null;
  };

  const handleCancel = () => {
    handleReset();
    onCancel();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={`form ${styles['team-member-card']} edit`}
    >
      <div className={styles['team-member-avatar']}>
        <div className={styles['team-member-avatar-image']}>
          <Icon icon="user" size="lg" />
        </div>
      </div>
      <div className={styles['team-member-name']}>
        <div>{teamMember.name}</div>
      </div>
      <div className={styles['team-member-email']}>
        {teamMember.email ?? <em>(no email)</em>}
      </div>
      <div className={styles['team-member-role']}>
        <Label for="role">Role</Label>
        <Role
          placeholder="(None)"
          onChange={(value) => setFieldValue('role', value)}
          value={values['role']}
        />
      </div>
      <div className={styles['team-member-allocation']}>
        <Label for="email">Allocation</Label>
        <FormGroup>
          <Allocation {...formikAttributes('allocation')} />
          {formikFeedback('allocation')}
        </FormGroup>
      </div>
      <div className={styles['team-member-actions']}>
        <Button color="primary" disabled={isSubmitting} type="submit">
          {isSubmitting ? (
            <Icon
              icon={['far', 'circle-notch']}
              className="btn-icon-left"
              spin
              fixedWidth
            />
          ) : (
            <Icon icon="save" fixedWidth />
          )}
          Save changes
        </Button>
        <Button
          color="secondary"
          disabled={isSubmitting}
          type="button"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          color="danger"
          disabled={isSubmitting}
          type="button"
          onClick={onDelete}
        >
          Delete
        </Button>
      </div>
    </form>
  );
};

const FormikEditMemberForm = withFormik({
  mapPropsToValues: ({
    teamMember: { role = null, allocation = null } = {},
  }) => {
    return {
      role: role ?? '',
      allocation,
    };
  },
  validationSchema: Yup.object().shape({}),
  handleSubmit: async (values, bag) => {
    const {
      setSubmitting,
      props: { teamMember, onSave = noop },
    } = bag;
    // do nothing
    setSubmitting(true);
    try {
      await onSave(teamMember, values);
    } finally {
      setSubmitting(false);
    }
  },
  displayName: 'AddMember',
})(EditMemberForm);

export default FormikEditMemberForm;
