import React, { useCallback, useMemo } from 'react';
import { keyBy, uniqueId } from 'lodash';
import { hierarchy, Cluster } from '@visx/hierarchy';
import { LinkHorizontal } from '@visx/shape';
import { RootNode, GroupNode, TeamNode } from './Nodes';
import { calculateBreadth } from 'js/utils/cluster';
import { normalizeOrg } from 'js/utils/org-utils';

function decorateGroupNode(node, assessmentLookup, workspaceId, assessmentKey) {
  const children = node.children.map((n) =>
    decorateNode(n, assessmentLookup, workspaceId, assessmentKey)
  );
  return {
    ...node,
    workspaceId,
    assessmentKey,
    children,
    assessmentData: children.reduce(
      (acc, c) => {
        acc.invitations += c.assessmentData.invitations;
        acc.in_progress += c.assessmentData.in_progress;
        acc.completed += c.assessmentData.completed;

        return acc;
      },
      { invitations: 0, in_progress: 0, completed: 0 }
    ),
  };
}

function decorateTeamNode(node, assessmentLookup, workspaceId, assessmentKey) {
  return {
    ...node,
    workspaceId,
    assessmentKey,
    assessmentData: assessmentLookup[node.id] || {
      invitations: 0,
      in_progress: 0,
      completed: 0,
    },
  };
}

function decorateNode(node, assessmentLookup, workspaceId, assessmentKey) {
  return node.type === 'team'
    ? decorateTeamNode(node, assessmentLookup, workspaceId, assessmentKey)
    : decorateGroupNode(node, assessmentLookup, workspaceId, assessmentKey);
}

function getTeamAssessmentData(nodeData) {
  if (nodeData.type === 'team') {
    return [nodeData.assessmentData];
  }
  return nodeData.children.flatMap((child) => getTeamAssessmentData(child));
}

const Node = ({ onSendReminders, ...props }) => {
  const { node } = props;
  const {
    depth,
    data: { type = null },
  } = node;

  const handleSendReminders = useCallback(
    (nodeData) => {
      const assessmentIds = getTeamAssessmentData(nodeData)
        .filter((ad) => ad.assessment_id && ad.invitations - ad.completed > 0)
        .map((ad) => ad.assessment_id);

      return onSendReminders(nodeData.name, assessmentIds);
    },
    [onSendReminders]
  );

  if (depth === 0) {
    return <RootNode {...props} onSendReminders={handleSendReminders} />;
  }
  if (type === 'org') {
    return <GroupNode {...props} onSendReminders={handleSendReminders} />;
  }
  return <TeamNode {...props} onSendReminders={handleSendReminders} />;
};

const ParticipationTree = ({
  margin = { top: 10, left: 30, bottom: 10, right: 30 },
  width,
  minHeight = 200,
  teamNameBuffer = 160,
  targetLeafHeight = 34,
  targetClusterMargin = 60,
  organization,
  teamParticipation,
  workspaceId,
  assessmentKey,
  onSendReminders,
}) => {
  const chartId = useMemo(() => uniqueId('participation-org-chart-'), []);

  const normalizedOrg = useMemo(() => {
    return normalizeOrg(organization);
  }, [organization]);

  const assessmentLookup = useMemo(() => {
    return keyBy(teamParticipation, (p) => p.team_id);
  }, [teamParticipation]);

  const height = useMemo(
    () =>
      Math.max(
        minHeight,
        calculateBreadth(normalizedOrg, targetLeafHeight, targetClusterMargin)
      ),
    [normalizedOrg, targetLeafHeight, targetClusterMargin, minHeight]
  );

  const data = useMemo(
    () =>
      hierarchy(
        decorateNode(
          normalizedOrg,
          assessmentLookup,
          workspaceId,
          assessmentKey
        )
      ),
    [normalizedOrg, assessmentLookup, workspaceId, assessmentKey]
  );

  const nodeProps = {
    width: 36,
    height: 18,
    maskId: `${chartId}-mask`,
  };

  const clusterWidth = width - margin.left - margin.right - teamNameBuffer;
  const clusterHeight = height - margin.top - margin.bottom;

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      width="100%"
      className="participation-tree"
    >
      <mask id={nodeProps.maskId}>
        <rect
          width={nodeProps.width}
          height={nodeProps.height}
          className="participation-chart-node-mask"
          fill="#fff"
          rx={nodeProps.height / 5}
        />
      </mask>
      {/* flip height/width for the cluster because we're rendering horizontally instead of vertically */}
      <Cluster
        size={[clusterHeight, clusterWidth]}
        root={data}
        separation={(a, b) =>
          a.parent === b.parent ? targetLeafHeight : targetClusterMargin
        }
      >
        {(tree) => (
          <g
            className="org-participation-chart"
            transform={`translate(${margin.left},${margin.top})`}
          >
            {tree.links().map((link, i) => (
              <LinkHorizontal
                key={`link-${i}`}
                data={link}
                className="participation-tree-link chart-link"
              />
            ))}
            {tree.descendants().map((node, i) => (
              <Node
                key={`node-${i}`}
                node={node}
                {...nodeProps}
                workspaceId={workspaceId}
                assessmentKey={assessmentKey}
                onSendReminders={onSendReminders}
              />
            ))}
          </g>
        )}
      </Cluster>
    </svg>
  );
};

export default ParticipationTree;
