import { listAssessmentInvites } from 'js/actions/assessment-invite-actions';
import { selectAssessmentInvitesQuery } from 'js/store/reducers/queries';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AssessmentReviewDetail from '../components/Review/AssessmentReviewDetail';

function LiveAssessmentReviewDetailContainer({
  listAssessmentInvites,
  assessmentInvitesQueryResult,
  currentWorkspaceId,
  teamId,
  match,
  ...props
}) {
  const assessmentId = match.params.assessmentId;
  useEffect(() => {
    listAssessmentInvites(currentWorkspaceId, teamId, assessmentId);
  }, [listAssessmentInvites, currentWorkspaceId, teamId, assessmentId]);
  return (
    <AssessmentReviewDetail
      {...props}
      match={match}
      assessmentInvitesQueryResult={assessmentInvitesQueryResult}
    />
  );
}

export default connect(
  (store, params) => ({
    currentWorkspaceId: store.currentWorkspaceId,
    teamId: store.teamId,
    assessmentInvitesQueryResult: selectAssessmentInvitesQuery(
      store,
      params.match.params.assessmentId
    ),
  }),
  { listAssessmentInvites }
)(LiveAssessmentReviewDetailContainer);
