import { keyBy, toArray } from 'lodash';
import { useMemo } from 'react';

const mapParticipant = (present) => (p) => ({
  id: p.id,
  name: p.name,
  present,
});

export function useParticipants(assessment, presences) {
  const participants = useMemo(() => {
    const participantCollection = {
      ...keyBy(
        (assessment?.participants || [])
          .map(mapParticipant(false))
          .filter((a) => a),
        (x) => x.id
      ),
      ...keyBy(
        presences?.map(mapParticipant(true)).filter((a) => a),
        (x) => x.id
      ),
    };

    return toArray(participantCollection).sort((a, b) => {
      if (a.present !== b.present) {
        return a.present ? -1 : 1;
      }

      return a.name.localeCompare(b.name, 'en', {
        sensitivity: 'base',
      });
    });
  }, [assessment, presences]);

  return participants;
}
