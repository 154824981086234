import React from 'react';
import ConditionalLinkWrapper from '../ConditionalLinkWrapper';

const NotScorableQuestionResult = ({
  tag: Tag = 'div',
  questionHref,
  parameters,
}) => {
  return (
    <Tag
      className="assessment-history-question-stacked-bar not-scorable"
      title="Not Scorable - A numeric score can't be calculated for this type of question"
    >
      <ConditionalLinkWrapper
        wrap={!!questionHref}
        href={questionHref}
        parameters={parameters}
      />
    </Tag>
  );
};

export default NotScorableQuestionResult;
