import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Icon } from 'js/components';

function AddOrgFormDialog({
  parentNode,
  isOpen,
  onCancel,
  onConfirm,
  rootOrgNames,
  intermediateRoot,
}) {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const existingNames = useMemo(() => {
    const names =
      parentNode?.children
        ?.filter((o) => o.type === 'org')
        ?.map((o) => o.name) ??
      rootOrgNames ??
      [];
    return names.map((n) => n.toUpperCase());
  }, [parentNode, rootOrgNames]);

  const validateName = useCallback(
    (val) => {
      if (!val) {
        setNameError('A name is required');
        return false;
      } else if (existingNames.includes(val.toUpperCase())) {
        setNameError(
          parentNode
            ? `There is already a group under ${parentNode?.name} with this name`
            : 'There is already an organization with this name'
        );
        return false;
      } else {
        setNameError('');
        return true;
      }
    },
    [setNameError, existingNames, parentNode]
  );

  const handleNameChange = useCallback(
    (e) => {
      setName(e.target.value);
      validateName(e.target.value);
    },
    [setName, validateName]
  );

  const reset = useCallback(() => {
    setName('');
    setNameError('');
  }, [setName, setNameError]);

  const handleConfirm = useCallback(
    (e) => {
      e.preventDefault();

      if (!validateName(name)) return;

      setSubmitting(true);

      return Promise.resolve(onConfirm({ name }))
        .then(() => mounted.current && reset())
        .finally(() => setSubmitting(false));
    },
    [onConfirm, reset, name, validateName]
  );

  const handleCancel = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const nameInputRef = useRef();

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.getElementById('name-input')?.focus();
      }, 1);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen}>
      <Form onSubmit={handleConfirm}>
        <ModalHeader>
          {parentNode ? (
            <div className="primary">Add Group to {parentNode?.name}</div>
          ) : (
            <div className="primary">Create Organization</div>
          )}
        </ModalHeader>
        <ModalBody>
          <div>
            {intermediateRoot && (
              <Alert color="info">
                <div className="org-change-warning">
                  <Icon icon="exclamation-circle" />
                  <span>
                    This change will affect the organization{' '}
                    <strong>{intermediateRoot.name}</strong> and any
                    organizations that refer to it.
                  </span>
                </div>
              </Alert>
            )}
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                id="name-input"
                name="name"
                value={name}
                onChange={handleNameChange}
                invalid={!!nameError}
                ref={nameInputRef}
              />
              <FormFeedback>{nameError}</FormFeedback>
              {!nameError && <div style={{ height: 29 }}>&nbsp;</div>}
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            disabled={submitting || !!nameError}
            type="submit"
            color="primary"
            data-test="confirm-add-btn"
          >
            {parentNode ? 'Add Group' : 'Create Organization'}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default AddOrgFormDialog;
