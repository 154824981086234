import { request } from 'js/api/axios';

import { types as FileActionTypes } from 'js/actions/file-actions';

async function handleFileDownload(_dispatch, getState, action) {
  const { token } = getState().identity;

  request
    .post('/api/auth/identity/download-token', null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const { token: downloadToken } = res.data;

      const joinChar = action.path.indexOf('?') === -1 ? '?' : '&';

      const iframe = document.createElement('iframe');
      iframe.setAttribute(
        'src',
        [action.path, joinChar, 'token=', downloadToken].join('')
      );
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
    });
}

export const fileMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.type === FileActionTypes.DOWNLOAD_FILE) {
      handleFileDownload(dispatch, getState, action);
    }

    next(action);
  };

export default fileMiddleware;
