import React, { useState, useCallback, useMemo, useRef } from 'react';
import { find, toArray, trimEnd, isEqual } from 'lodash';

import standardCohortKeys from 'js/utils/cohort-keys';

import { PanelHeader, ChartSelector } from 'js/components';

import ReviewHeader from './ReviewHeader';
import AssessmentHistoryChart, {
  Glyph as ScoreGlyph,
} from './AssessmentHistoryChart';
import AssessmentHistoryStackedBarChart, {
  Glyph as BarGlyph,
} from './AssessmentHistoryStackedBarChart';
import AssessmentHistoryBoxPlot, {
  Glyph as BoxPlotGlyph,
} from './AssessmentHistoryBoxPlot';
import AssessmentHistoryRadarChart, {
  Glyph as RadarGlyph,
} from './AssessmentHistoryRadarChart';

import { Route, Switch } from 'react-router';
import AssessmentReviewDetailsResults from './AssessmentReviewDetailResults';
import AssessmentReviewDetailsInvitees from './AssessmentReviewDetailInvitees';
import AssessmentReviewDetailOverview from './AssessmentReviewDetailOverview';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getSummaryMetrics } from 'js/utils/question-set';

const AssessmentReviewDetail = ({
  assessments,
  team,
  name,
  assessmentKey,
  categoryLookups,
  categoryColorMap,
  match,
  location,
  onSendInvites,
  sendingInvites,
  onSendReminders,
  assessmentInvitesQueryResult,
}) => {
  const {
    params: { assessmentId },
  } = match;
  const currentView =
    location.pathname.slice(match.url.length)?.replaceAll('/', '') ||
    'overview';

  const [filter, setFilter] = useState({});
  const handleFilterChange = useCallback(
    (newFilters) => {
      if (!isEqual(filter, newFilters)) {
        setFilter(newFilters);
      }
    },
    [filter, setFilter]
  );

  const [pinnedCohort, setPinnedCohort] = useState(null);
  const handlePinCohort = useCallback(
    (cohort) => {
      setPinnedCohort(cohort);
    },
    [setPinnedCohort]
  );

  const handleClearPinnedCohort = useCallback(() => {
    setPinnedCohort(null);
  }, [setPinnedCohort]);

  const cohortPeekTimeout = useRef(null);
  const [peekedCohort, setPeekedCohort] = useState(null);
  const handlePeekCohort = useCallback(
    (cohort) => {
      clearTimeout(cohortPeekTimeout.current);
      cohortPeekTimeout.current = null;
      setPeekedCohort(cohort);
    },
    [cohortPeekTimeout, setPeekedCohort]
  );

  const handleClearPeekedCohort = useCallback(() => {
    setPeekedCohort(null);
  }, [setPeekedCohort]);

  const activeCohort = peekedCohort ?? pinnedCohort;

  // let assessment = _.find(assessments, (a) => a.id === assessmentId);
  const { assessment, cohorts, participantCohortMap } = useMemo(() => {
    const assessment = find(assessments, (a) => a.id === assessmentId);
    const { cohortMap, participantMap: participantCohortMap } = (
      assessment?.participants ?? []
    ).reduce(
      (acc, p) => {
        const cohort =
          p.cohort === null
            ? { value: standardCohortKeys.NULL, text: '(None)' }
            : { value: p.cohort, text: p.cohort };
        acc.cohortMap[cohort.value] = cohort;
        acc.participantMap[p.id] = cohort.value;
        return acc;
      },
      { cohortMap: {}, participantMap: {} }
    );

    return {
      assessment,
      cohorts: toArray(cohortMap).sort(),
      participantCohortMap,
    };
  }, [assessments, assessmentId]);

  const legendFields = useMemo(() => {
    const cohortFilter = filter?.cohorts ?? {};
    return [
      {
        paletteIndex: 1,
        key: standardCohortKeys.CUMULATIVE,
        name: '(Combined)',
      },
      ...(cohorts ?? [])
        .filter(
          (c) => Object.keys(cohortFilter).length === 0 || cohortFilter[c.value]
        )
        .map((c, i) => ({
          paletteIndex: i + 2,
          key: c.value ?? standardCohortKeys.NULL,
          name: c.text,
        })),
    ];
  }, [cohorts, filter?.cohorts]);

  const dataMap = useMemo(
    () => (!assessment ? null : getSummaryMetrics([assessment], { filter })),
    [assessment, filter]
  );

  const cohortProps = {
    onPinCohort: handlePinCohort,
    onClearPinnedCohort: handleClearPinnedCohort,
    onPeekCohort: handlePeekCohort,
    onClearPeekedCohort: handleClearPeekedCohort,
    cohorts,
    activeCohort,
    pinnedCohort,
    peekedCohort,
    legendFields,
  };

  return (
    <div className="survey-assessment review">
      <PanelHeader size="flex">
        <ReviewHeader
          glow={assessment === undefined}
          name={name}
          date={assessment ? assessment.inserted_at : undefined}
          team={team}
          dataMap={dataMap}
          cohorts={cohorts}
          categoryColorMap={categoryColorMap}
          assessment={assessment}
          invites={assessmentInvitesQueryResult}
          assessmentKey={assessmentKey}
          categoryLookups={categoryLookups}
          onFilterChange={handleFilterChange}
          showParticipationStats
        >
          <label>Charts</label>

          {assessment === undefined ? null : (
            <ChartSelector
              charts={[
                {
                  label: RadarGlyph,
                  component: AssessmentHistoryRadarChart,
                  buttonProps: { 'data-chart-type': 'radar' },
                },
                {
                  label: ScoreGlyph,
                  component: AssessmentHistoryChart,
                  buttonProps: { 'data-chart-type': 'assessment-history' },
                },
                {
                  label: BarGlyph,
                  component: AssessmentHistoryStackedBarChart,
                  buttonProps: { 'data-chart-type': 'stacked-bar' },
                },
                {
                  label: BoxPlotGlyph,
                  component: AssessmentHistoryBoxPlot,
                  buttonProps: { 'data-chart-type': 'box-plot' },
                },
              ]}
              assessmentId={assessmentId}
              dataMap={dataMap}
              categoryColorMap={categoryColorMap}
              categoryLookups={categoryLookups}
              questionSetHref={(questionSetKey) => `#${questionSetKey}`}
              questionHref={(questionSetKey, questionKey) =>
                `#${questionSetKey}_${questionKey}`
              }
            />
          )}
        </ReviewHeader>
        <div className="container-fluid">
          <Nav className="tab-nav nav-underline">
            <NavItem>
              <NavLink
                tag={Link}
                active={currentView === 'overview'}
                data-view="overview"
                to={`${trimEnd(match.url, '/')}`}
              >
                Overview
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                active={currentView === 'results'}
                data-view="results"
                to={`${trimEnd(match.url, '/')}/results`}
              >
                Results
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                active={currentView === 'invitees'}
                data-view="invitees"
                to={`${trimEnd(match.url, '/')}/invitees`}
              >
                Invitations
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </PanelHeader>
      <Switch>
        <Route
          path={`${match.path}/overview`}
          render={() => (
            <AssessmentReviewDetailOverview
              assessment={assessment}
              filter={filter}
              participantCohortMap={participantCohortMap}
              dataMap={dataMap}
              {...cohortProps}
            />
          )}
        />
        <Route
          path={`${match.path}/results`}
          render={() => (
            <AssessmentReviewDetailsResults
              assessment={assessment}
              filter={filter}
              participantCohortMap={participantCohortMap}
              {...cohortProps}
            />
          )}
        />
        <Route
          path={`${match.path}/invitees`}
          render={() => (
            <AssessmentReviewDetailsInvitees
              onSendInvites={onSendInvites}
              sendingInvites={sendingInvites}
              onSendReminders={onSendReminders}
              queryResult={assessmentInvitesQueryResult}
              assessment={assessment}
              team={team}
            />
          )}
        />
        <Route
          path={`${match.path}`}
          render={() => (
            <AssessmentReviewDetailOverview
              assessment={assessment}
              filter={filter}
              participantCohortMap={participantCohortMap}
              dataMap={dataMap}
              {...cohortProps}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default AssessmentReviewDetail;
