import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';

const config = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
};

const instance = axios.create(config);

export { axios, instance as request, instance as default };
