export function median(arr, predicate = (x) => x) {
  var sorted = arr.map(predicate).sort((a, b) => {
    return a - b;
  });

  var middleIndex = Math.floor((sorted.length - 1) / 2);

  return sorted.length % 2
    ? sorted[middleIndex]
    : (sorted[middleIndex] + sorted[middleIndex + 1]) / 2.0;
}
