import styles from './AssessmentTrends.module.scss';

export const Glyph = (
  <svg viewBox="0 0 32 32" className={`chart-glyph ${styles.glyph}`}>
    <g>
      <circle cx="7" cy="7" r="7" className={styles.lolli1} />
      <rect x="4" y="10" width="6" height="28" className={styles.lolli1} />
      <circle cx="7" cy="7" r="3" className={styles.dot} />
    </g>
    <g>
      <circle cx="25" cy="18" r="7" className={styles.lolli2} />
      <rect x="23" y="21" width="6" height="28" className={styles.lolli2} />
      <circle cx="25" cy="18" r="3" className={styles.dot} />
    </g>
  </svg>
);
