import { trueToFalse } from './base-answers';

export const piExecution = {
  name: 'PI Execution',
  key: 'pi-execution',
  category: 'develop-on-cadence',
  questions: [
    {
      text: 'Throughout the PI, our ART synchronizes work on a cadence.',
      key: 'synchronized-cadence',
      answers: trueToFalse,
    },
    {
      text: 'Throughout the PI, our ART adjusts the PI plan accordingly when new information is available',
      key: 'adjusts-pi-plan',
      answers: trueToFalse,
    },
    {
      text: 'Throughout the PI, our ART considers multiple design choices before committing',
      key: 'considers-multiple-design-choices',
      answers: trueToFalse,
    },
    {
      text: 'Throughout the PI, our ART addresses known and emerging risks.',
      key: 'addresses-risks',
      answers: trueToFalse,
    },
    {
      text: 'At the end of each Iteration, our ART demonstrates an integrated Solution during the System Demo.',
      key: 'demos-integrated-solution',
      answers: trueToFalse,
    },
    {
      text: 'Throughout the PI, Our ART successfully manages and resolves dependencies',
      key: 'manages-dependencies',
      answers: trueToFalse,
    },
    {
      text: 'Throughout the PI, our ART predictably meets the PI commitments',
      key: 'meets-pi-commitments',
      answers: trueToFalse,
    },
    {
      text: 'At the end of the PI, Business Owners assess the actual business value delivered.',
      key: 'asses-actual-value-delivered',
      answers: trueToFalse,
    },
  ],
};
