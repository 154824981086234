import React, { useCallback } from 'react';
import { AxisBottom as VisxAxisBottom } from '@visx/axis';
import { timeFormatNoYear } from 'js/utils/time-format';

const tickLabelPropsFactory = (scale) => (d, i, array) => {
  const result = {
    fontSize: 9,
    strokeWidth: 0,
    paintOrder: 'stroke',
    textAnchor: 'start',
    x: scale(d) + 5,
    y: 12,
    className: 'tick-label',
  };

  return result;
};

export const AxisBottom = ({
  chartHeight,
  chartWidth,
  xScale,
  className = 'chart-axis',
  tickLength = 13,
  tickLineProps = { className: 'tick' },
}) => {
  const xAxisProps = tickLabelPropsFactory(xScale);
  const numTicks = Math.min(Math.floor(chartWidth / 100), 5);
  const tickFormat = useCallback(
    (d) => {
      return xScale(d) < chartWidth - 60 ? timeFormatNoYear(d) : '';
    },
    [chartWidth, xScale]
  );

  return (
    <VisxAxisBottom
      top={chartHeight}
      scale={xScale}
      width={chartWidth}
      numTicks={numTicks}
      className={className}
      tickLength={tickLength}
      tickFormat={tickFormat}
      tickLineProps={tickLineProps}
      tickLabelProps={xAxisProps}
    />
  );
};

export default AxisBottom;
