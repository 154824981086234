import styles from './AssessmentDifferential.module.scss';

export const Glyph = (
  <svg viewBox="0 0 32 32" className={`chart-glyph ${styles.glyph}`}>
    <g transform="translate(-9 0)">
      <line x1="16.5" y1="11" x2="16.5" y2="21" className={styles.connector} />
      <polygon points="14 16, 16.5 11, 19 16" className={styles.arrow} />
      <circle cx="16.5" cy="26" r="5" className={styles.from} />
      <circle cx="16.5" cy="6" r="5" className={styles.to} />
    </g>
    <g transform="rotate(180 16.5 16) translate(-9 0)">
      <line x1="16.5" y1="11" x2="16.5" y2="21" className={styles.connector} />
      <polygon points="14 16, 16.5 11, 19 16" className={styles.arrow} />
      <circle cx="16.5" cy="26" r="5" className={styles.from} />
      <circle cx="16.5" cy="6" r="5" className={styles.to} />
    </g>
  </svg>
);
