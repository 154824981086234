import React, { useState, useEffect } from 'react';
import { Collapse, Button } from 'reactstrap';
import classnames from 'classnames';

const ConfigureQuestionSet = ({
  tag: Tag,
  questionSet,
  categoryReference,
  removed,
  className,
  ...attributes
}) => {
  const [showQuestions, setShowQuestions] = useState(false);
  useEffect(
    () => {
      if (removed) {
        setShowQuestions(false);
      }
    },
    [removed]
  );

  let combinedClassName =
    className === null ? 'question-set' : `question-set ${className}`;

  const category = questionSet.category || 'uncategorized';
  const myCategoryReference = categoryReference[category];

  const toggleShowQuestions = e => {
    e.preventDefault();

    setShowQuestions(!showQuestions);
  };

  return (
    <Tag className={combinedClassName} {...attributes}>
      <div
        className={classnames({ 'question-set-header': true, muted: removed })}
      >
        <div className="question-count">
          {questionSet.questions.length} questions <br />(
          <Button color="link" onClick={toggleShowQuestions}>
            {showQuestions ? 'hide' : 'show'}
          </Button>
          )
        </div>
        <div className="question-set-categories">
          <span
            className={`question-set-category ${
              myCategoryReference.paletteClass
            }`}
          >
            {myCategoryReference.display_name}
          </span>
        </div>
        <h5 className="question-set-name">{questionSet.name}</h5>
      </div>
      <Collapse isOpen={showQuestions}>
        <ul className="questions">
          {questionSet.questions.map(q => {
            return (
              <li className="question" key={q.key}>
                {q.text}
              </li>
            );
          })}
        </ul>
      </Collapse>
    </Tag>
  );
};

ConfigureQuestionSet.defaultProps = {
  tag: 'div',
  className: null
};

export default ConfigureQuestionSet;
