import * as React from 'react';
import { PanelHeader } from 'js/components';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import InviteUserForm from 'js/components/User/InviteUserForm';

function InviteAccountUsersComponent({ onSubmit, serverErrors, account }) {
  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Invite someone to {account.name}</CardTitle>
          </CardHeader>
          <CardBody>
            <InviteUserForm
              onSave={onSubmit}
              serverErrors={serverErrors}
              roles={[]}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default InviteAccountUsersComponent;
