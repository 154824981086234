import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'js/components';
import moment from 'moment';
import ConfirmModal from '../modal/ConfirmModal';
import AsyncButton from '../Forms/AsyncButton';

import { useCallback } from 'react';

// TODO: refactor this and UserList to use the same base with customized columns
function isExpired(dateStr) {
  return !dateStr || moment(dateStr).isBefore(moment());
}

function recordClassName(workspace_user) {
  let className;
  if (workspace_user.is_invite) {
    className = isExpired(workspace_user.invite_expires_at)
      ? 'invite expired'
      : 'invite';
  } else {
    className = 'user';
  }
  return className;
}

function UserIcon({ user }) {
  return (
    <div>
      {user.is_invite ? (
        <Icon icon={['far', 'envelope']} />
      ) : (
        <Icon icon="user" className="info" />
      )}
    </div>
  );
}

function UserDisplayName({ user }) {
  return (
    <div className="display-name">
      {user.user?.display_name ? (
        <span>{user.user.display_name}</span>
      ) : (
        <span className="missing">&mdash;&mdash;</span>
      )}
    </div>
  );
}

function UserStatus({ user }) {
  return (
    <div className="status">
      {user.is_invite &&
        (isExpired(user.invite_expires_at) ? (
          <div className="danger">INVITATION EXPIRED</div>
        ) : (
          <div className="info">INVITATION PENDING</div>
        ))}
    </div>
  );
}

function AccountUserList({ users, onResendInvite, onRemoveUser, account, me }) {
  const [toRemove, setToRemove] = useState(null);

  const handleRemoveUser = useCallback(async () => {
    try {
      await onRemoveUser(toRemove);
      setToRemove(null);
    } finally {
      // do nothing
    }
  }, [onRemoveUser, toRemove, setToRemove]);

  return (
    <div className="user-list">
      {users.map((u) => (
        <div className={recordClassName(u)} key={`${u.account_id}-${u.email}`}>
          <div className="icon">
            <UserIcon user={u} />
          </div>
          <div className="username">{u.email}</div>
          <UserDisplayName user={u} />
          <UserStatus user={u} />
          <div className="actions">
            {u.is_invite && (
              <AsyncButton
                color="info"
                size="sm"
                className="m-0"
                onClick={() => onResendInvite(u.email)}
              >
                <Icon icon={['fas', 'redo']} className="mr-1" /> Invite
              </AsyncButton>
            )}
            {!u.is_invite && me.id !== u.user_id && (
              <Button
                data-test="remove-user-btn"
                className="btn-icon"
                size="sm"
                onClick={() => setToRemove(u)}
              >
                <Icon icon={['fas', 'trash-alt']} />
              </Button>
            )}
          </div>
        </div>
      ))}
      <ConfirmModal
        isOpen={!!toRemove}
        title="Remove user?"
        onConfirm={handleRemoveUser}
        onCancel={() => setToRemove(null)}
        confirmButtonLabel="Remove User"
      >
        <p>
          Please confirm that you want to remove{' '}
          <strong>{toRemove?.user?.display_name ?? toRemove?.email}</strong>{' '}
          from the account: <strong>{account?.name}.</strong>
        </p>
      </ConfirmModal>
    </div>
  );
}

AccountUserList.propTypes = {
  users: PropTypes.array.isRequired,
  onResendInvite: PropTypes.func,
  resendingInviteEmail: PropTypes.string,
};

AccountUserList.defaultProps = {};

export default AccountUserList;
