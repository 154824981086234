import keymirror from 'js/utils/keymirror';
import { request } from 'js/api/axios';

import { spreadApiKeys, extractApiKeys } from '../utils';
import { callApi, SCHEMAS } from '../api';

export const types = keymirror({
  ...spreadApiKeys('LOAD_PASSPHRASE'),
  ...spreadApiKeys('LOAD_PASSPHRASES'),
});

export function load(assessmentId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_PASSPHRASE, types),
        request: (_data, token) =>
          request.get(`/api/passphrases/${assessmentId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { assessment_id: assessmentId },
        schema: SCHEMAS.PASSPHRASE,
      },
      dispatch
    );
  };
}

export function search(query) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_PASSPHRASES, types),
        request: (data, token) =>
          request.get(`/api/passphrases`, {
            params: data.query,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { query },
        schema: SCHEMAS.PASSPHRASE_ARRAY,
      },
      dispatch
    );
  };
}
