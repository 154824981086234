import React from 'react';
import PropTypes from 'prop-types';

import { withFormik } from 'formik';

import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormFeedback
} from 'reactstrap';
import * as Yup from 'yup';
import { Icon } from 'js/components';

const EditUserForm = props => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    message,
    isSubmitting
  } = props;

  const formikAttributes = fieldName => ({
    id: fieldName,
    name: fieldName,
    value: values[fieldName],
    onChange: handleChange,
    onBlur: handleBlur,
    invalid: errors[fieldName] && touched[fieldName]
  });

  const feedback = (fieldName, errorFieldName) => {
    errorFieldName = errorFieldName || fieldName;

    const messages = [];

    if (errors[fieldName] && touched[fieldName]) {
      messages.push(
        <FormFeedback key={errors[fieldName]}>{errors[fieldName]}</FormFeedback>
      );
    }

    // if (state.error && state.error[fieldName]) {
    //   state.error[fieldName].forEach(message => {
    //     messages.push(
    //       <FormFeedback key={message}>
    //         {titleCase(fieldName)} {message}
    //       </FormFeedback>
    //     );
    //   });
    // }

    return messages;
  };

  return (
    <Form
      onSubmit={isSubmitting ? () => false : handleSubmit}
      className="edit-user-form"
    >
      <div>
        {message && message.length > 0 ? (
          <div className="validation-message">{message}</div>
        ) : null}
      </div>
      <Row>
        <Col>
          <FormGroup>
            <Label for="username">Email</Label>
            <Input
              type="text"
              autoFocus={true}
              {...formikAttributes('username')}
            />
            {feedback('username')}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="display_name">Name</Label>
            <Input type="text" {...formikAttributes('display_name')} />
            {feedback('display_name')}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input type="password" {...formikAttributes('password')} />
            {feedback('password')}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="confirmPassword">Re-enter password</Label>
            <Input type="password" {...formikAttributes('confirmPassword')} />
            {feedback('confirmPassword')}
          </FormGroup>
        </Col>
      </Row>
      <div>
        <button type="submit" className="btn btn-primary">
          {isSubmitting ? (
            <Icon
              icon={['far', 'circle-notch']}
              className="btn-icon-left"
              spin
            />
          ) : (
            <Icon icon={['far', 'arrow-alt-up']} className="btn-icon-left" />
          )}
          Upgrade
        </button>
      </div>
    </Form>
  );
};

const FormikEditUserForm = withFormik({
  mapPropsToValues: ({ user: { display_name } }) => ({
    username: '',
    display_name,
    password: '',
    confirmPassword: ''
  }),
  validationSchema: Yup.object().shape({
    username: Yup.string()
      .trim()
      .required(
        "If you don't tell me your email, how will I know how to reach you?"
      )
      .email("Hmm, I'll need a valid email address.")
      .max(64),
    display_name: Yup.string()
      .trim()
      .min(3, 'Your name must be at least 3 letters long')
      .max(32, "Your name can't be more than 32 letters long")
      .nullable(),
    password: Yup.string().required('You probably want a lock on this door'),
    confirmPassword: Yup.string().test(
      'match',
      'Passwords do not match',
      function(password) {
        return password === this.options.parent.password;
      }
    )
  }),
  handleSubmit: (values, bag) => {
    const { props, setSubmitting } = bag;
    const { display_name } = values;

    const submittedValues = { ...values };
    if (display_name === null || display_name.trim() === '') {
      delete submittedValues.display_name;
    }

    setSubmitting(true);

    Promise.resolve(props.onSubmit(submittedValues)).finally(() => {
      setSubmitting(false);
    });
  }
})(EditUserForm);

FormikEditUserForm.propTypes = {
  onSubmit: PropTypes.func
};

FormikEditUserForm.defaultProps = {
  onSubmit: () => {}
};

export default FormikEditUserForm;
