import React, { useState, useMemo } from 'react';
import { map } from 'lodash';

import { scaleColorTraffic } from 'js/utils/color';

import CategoryPills from '../CategoryPills';
import CircleChart from './CircleChart';
import AssessmentSubview from './AssessmentSubview';
import { useCallback } from 'react';
import QuestionSetSubview from './QuestionSetSubview';

const AssessmentHistoryChart = ({
  dataMap,
  assessmentId,
  categoryColorMap,
  categoryLookups,
}) => {
  const questionSets = useMemo(
    () =>
      assessmentId
        ? dataMap.assessments[assessmentId].questionSets
        : dataMap.questionSets,
    [dataMap, assessmentId]
  );

  const categories = [...new Set(map(questionSets, (qs) => qs.category))];

  const [detailQuestionSet, setDetailQuestionSet] = useState(null);

  const handleSelectSlice = useCallback(
    (questionSet) => setDetailQuestionSet(questionSet),
    [setDetailQuestionSet]
  );

  const handleClearSlices = useCallback(
    () => setDetailQuestionSet(null),
    [setDetailQuestionSet]
  );

  return (
    <>
      <div className="row">
        <div className="col">
          <CategoryPills
            categories={categories}
            categoryLookups={categoryLookups}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="assessment-history-chart">
            <div className="assessment-history-radar-figure">
              <div className="figure-radar">
                <CircleChart
                  questionSets={questionSets}
                  categoryLookups={categoryLookups}
                  categoryColorMap={categoryColorMap}
                  onSelectSlice={handleSelectSlice}
                  onClearSlices={handleClearSlices}
                />
              </div>
              {detailQuestionSet === null ? (
                <AssessmentSubview
                  questionSets={questionSets}
                  categoryLookups={categoryLookups}
                  categoryColorMap={categoryColorMap}
                />
              ) : (
                <QuestionSetSubview
                  questionSet={detailQuestionSet}
                  categoryLookups={categoryLookups}
                  categoryColorMap={categoryColorMap}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AssessmentHistoryChart.defaultProps = {
  colors: {
    domain: scaleColorTraffic.domain(),
    range: scaleColorTraffic.range(),
  },
};

export const Glyph = (
  <svg
    viewBox="0 0 32 32"
    className="chart-glyph assessment-history-radar-chart-glyph"
  >
    <g className="radar-back">
      <line x1="16" y1="31" x2="16" y2="1" />
      <line x1="29" y1="23.5" x2="3" y2="8.5" />
      <line x1="29" y1="8.5" x2="3" y2="23.5" />
      <path
        d="M16 31 L29 23.5 L29 8.5 L16 1 L3 8.5 L3 23.5 L16 31"
        className="radar-outline"
      />
    </g>
    <g className="scores">
      <path
        d="M16 27 L27.258 22.5 L26.392 10 L16 3 L15.134 15.5 L6.474 21.5 L16 27"
        className="score-fill"
      />
    </g>
  </svg>
);

export default AssessmentHistoryChart;
