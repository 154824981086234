import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noop, chain } from 'lodash';
import { Button, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import {
  AssessmentGroup,
  PanelHeader,
  GoalSet,
  Loading,
  EditableText,
  AsyncDisplay,
  LoadingMessage,
  Icon,
} from 'js/components';
import ConfirmModal from 'js/components/modal/ConfirmModal';
import { Link } from 'react-router-dom';

import NotFound from 'js/routes/NotFound';
import TeamMember from './TeamDashboard/TeamMember';
import { AddMemberForm, EditMemberForm } from './TeamDashboard/MemberForm';
import { isArchived } from 'js/utils/team-utils';
import { Alert } from 'react-bs-notifier';
import styles from './TeamDashboardComponent.module.scss';

const TeamDashboard = (props) => {
  const {
    goals,
    team,
    query,
    onSetPrimary,
    onAddTeamMember = () => {},
    onUpdateTeamMember = () => {},
    onRemoveTeamMember = () => {},
    assessments,
    onToggleAssessmentLock,
    onDeleteAssessment,
    onUpdateTeam,
    onCopyLink,
  } = props;
  const { members } = team;

  const [toRemoveMember, setToRemoveMember] = useState(null);
  const [editingMemberId, setEditingMemberId] = useState(null);
  const [isAddingTeamMember, setIsAddingTeamMember] = useState(false);
  const toggleAddTeamMember = () => {
    setIsAddingTeamMember(!isAddingTeamMember);
    setEditingMemberId(null);
  };

  const handleAddTeamMember = async (member) => {
    const result = await onAddTeamMember(team.id, member);
    setIsAddingTeamMember(false);
    return result;
  };

  const handleUpdateTeamMember = async (member, values) => {
    const result = await onUpdateTeamMember({ ...member, ...values });
    setEditingMemberId(null);
    return result;
  };

  const handleUpdateTeam = (name) => {
    if (team.name === name) {
      return;
    }

    return onUpdateTeam({ id: team.id, name });
  };

  return (
    <AsyncDisplay
      isLoading={!!query.isLoading && team.id === undefined}
      isError={!!(query.error && query.error.status === 404)}
    >
      <AsyncDisplay.Loading>
        <div>
          <PanelHeader size="sm" />

          <div className="content">
            <Card>
              <CardBody>
                <LoadingMessage iconSize="2x">
                  Loading your team&hellip;
                </LoadingMessage>
              </CardBody>
            </Card>
          </div>
        </div>
      </AsyncDisplay.Loading>
      <AsyncDisplay.Error>
        <NotFound />
      </AsyncDisplay.Error>
      <AsyncDisplay.Resolved>
        <div>
          <PanelHeader size="sm" />

          <div className={`content ${styles.TeamInfo}`}>
            <Card>
              {isArchived(team) && (
                <Alert color="warning" type="warning" full-width>
                  This team is currently{' '}
                  <span className={styles.ArchiveState}>
                    {team.archived_state?.toUpperCase()}
                  </span>
                  . You will not be able to make edits or create assessments for
                  this team.
                </Alert>
              )}
              <CardHeader>
                <CardTitle tag="h5">
                  <div style={{ paddingBottom: '-.3em' }}>
                    <EditableText
                      value={team.name}
                      onSave={handleUpdateTeam}
                      disabled={isArchived(team)}
                    />
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody />
            </Card>

            <div style={{ display: 'none' }}>
              {team.id !== undefined ? (
                <GoalSet
                  entity={team}
                  goals={goals}
                  onSetPrimary={onSetPrimary}
                />
              ) : (
                <Loading />
              )}
            </div>

            <Card>
              <CardHeader>
                <div className="action-items">
                  <Link
                    className="btn btn-primary"
                    to={`/w/${team.workspace_id}/teams/${team.id}/assessments/new`}
                    disabled={isArchived(team)}
                  >
                    <Icon
                      icon={['far', 'poll']}
                      size="lg"
                      className="btn-icon-left"
                    />
                    Assess
                  </Link>
                </div>
                <CardTitle tag="h5">Assessments</CardTitle>
              </CardHeader>
              <CardBody>
                {chain(assessments)
                  .groupBy('key')
                  .map((assessmentList, key) => {
                    const name = assessmentList[0].name;
                    return (
                      <AssessmentGroup
                        team={team}
                        workspaceId={team.workspace_id}
                        key={key}
                        templateId={key}
                        name={name}
                        assessments={assessmentList}
                        onToggleAssessmentLock={onToggleAssessmentLock}
                        onDeleteAssessment={onDeleteAssessment}
                        onCopyLink={onCopyLink}
                      />
                    );
                  })
                  .value()}
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <div className="action-items">
                  {isAddingTeamMember ? (
                    <Button color="secondary" onClick={toggleAddTeamMember}>
                      Cancel
                    </Button>
                  ) : (
                    members?.length > 0 && (
                      <Button
                        color="primary"
                        onClick={toggleAddTeamMember}
                        disabled={isArchived(team)}
                      >
                        <Icon icon="plus" className="btn-icon-left" />
                        Add member
                      </Button>
                    )
                  )}
                </div>
                <CardTitle tag="h5">Team Members</CardTitle>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled team-member-list">
                  {isAddingTeamMember && (
                    <li>
                      <AddMemberForm
                        onAddMember={handleAddTeamMember}
                        text={
                          members?.length > 0
                            ? 'Who else is on your team?'
                            : 'Who is on your team?'
                        }
                        onCancel={() => setIsAddingTeamMember(false)}
                      />
                    </li>
                  )}
                  {members && members.length ? (
                    members.map((m) =>
                      m.member_id === editingMemberId ? (
                        <li key={m.member_id} className="edit-form">
                          <EditMemberForm
                            teamMember={m}
                            onCancel={() => setEditingMemberId(null)}
                            onSave={handleUpdateTeamMember}
                            onDelete={() => setToRemoveMember(m)}
                          />
                        </li>
                      ) : (
                        <TeamMember
                          key={m.member_id}
                          teamMember={m}
                          className="team-member"
                          onEdit={() => {
                            setIsAddingTeamMember(false);
                            setEditingMemberId(m.member_id);
                          }}
                        />
                      )
                    )
                  ) : (
                    <>
                      {isAddingTeamMember ? null : (
                        <div className="media text-muted">
                          <Icon
                            icon={['fas', 'users']}
                            size="4x"
                            className="mr-3"
                            fixedWidth
                          />
                          <div className="media-body">
                            <p>You haven't added any team members yet.</p>
                            <p>
                              Adding people to your team lets you track changes
                              to team composition that may impact your
                              effectiveness. It also unlocks some handy
                              shortcuts for managing your assessment for teams
                              and organizations.
                            </p>
                            <p>
                              <Button
                                color="primary"
                                onClick={toggleAddTeamMember}
                                disabled={isArchived(team)}
                              >
                                <Icon icon="plus" className="btn-icon-left" />
                                Add some team members now
                              </Button>
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </ul>
              </CardBody>
            </Card>
          </div>
        </div>
        <ConfirmModal
          isOpen={!!toRemoveMember}
          title="Remove team member?"
          onConfirm={async () => {
            const remove = await onRemoveTeamMember(
              team.id,
              toRemoveMember?.member_id
            );
            setToRemoveMember(null);
            return remove;
          }}
          onCancel={() => setToRemoveMember(null)}
          confirmButtonLabel="Remove User"
        >
          <p>
            Please confirm that you want to remove{' '}
            <strong>{toRemoveMember?.name ?? toRemoveMember?.email}</strong>{' '}
            from the team: <strong>{team.name}.</strong>
          </p>
        </ConfirmModal>
      </AsyncDisplay.Resolved>
    </AsyncDisplay>
  );
};

TeamDashboard.propTypes = {
  assessments: PropTypes.arrayOf(PropTypes.object),
  goals: PropTypes.arrayOf(PropTypes.object),
  team: PropTypes.object,
  onSetPrimary: PropTypes.func,
  onAddTeamMember: PropTypes.func,
  onToggleAssessmentLock: PropTypes.func,
  onDeleteAssessment: PropTypes.func,
  onUpdateTeam: PropTypes.func,
};

TeamDashboard.defaultProps = {
  assessments: [],
  goals: [],
  team: {},
  onSetPrimary: noop,
  onAddTeamMember: noop,
  onToggleAssessmentLock: noop,
  onDeleteAssessemnt: noop,
  onUpdateTeam: noop,
  onCopyLink: noop,
};

export default TeamDashboard;
