import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'js/components';

const LoadingMessage = ({ children, iconSize }) => (
  <div className="media loading-message text-muted">
    <Icon
      icon={['fal', 'circle-notch']}
      size={iconSize}
      className="mr-3"
      fixedWidth
      spin
    />
    <div className="media-body">{children}</div>
  </div>
);

LoadingMessage.propTypes = {
  iconSize: PropTypes.oneOf([
    'xs',
    'sm',
    'lg',
    '2x',
    '3x',
    '4x',
    '5x',
    '6x',
    '7x',
    '8x',
    '9x',
    '10x'
  ])
};

LoadingMessage.defaultProps = {
  iconSize: '4x'
};

export default LoadingMessage;
