import React from 'react';

export const UnscorableDifferentialItem = ({ width, height }) => {
  return (
    <g className="differential-item">
      <g>
        <line
          x1={0}
          y1={0}
          x2={width}
          y2={0}
          className="chart-axis"
          strokeWidth="1"
        />
        <line
          x1={0}
          y1={height}
          x2={width}
          y2={height}
          className="chart-axis"
          strokeWidth="1"
        />
      </g>
      <g transform={`translate(${width / 2} ${height / 2})`}>
        <text textAnchor="middle" className="no-response-fill">
          Not Scorable
        </text>
      </g>
    </g>
  );
};

export default UnscorableDifferentialItem;
