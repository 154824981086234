import React, { useEffect, useContext } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { SCHEMAS } from 'js/api/schemas';
import {
  load as loadAssessment,
  loadMeta as loadAssessmentMeta,
} from 'js/actions/assessment-actions';

import { HistoryContext } from 'js/contexts/HistoryContext';

import { LayoutRoute } from 'js/components';
import AssessmentLayout from 'js/layouts/Assessment';

import AssessmentRunContainer from './AssessmentRunContainer';
import AssessmentHostContainer from './AssessmentHostContainer';

const AssessmentRouterContainer = ({
  assessment,
  assessmentMeta,
  loadAssessment,
  loadAssessmentMeta,
  ...props
}) => {
  const { match } = props;
  const {
    params: { assessmentId },
  } = match;

  const history = useContext(HistoryContext);

  useEffect(() => {
    if (assessmentId !== undefined && assessmentId !== null) {
      loadAssessmentMeta(assessmentId);
    }
  }, [assessmentId, loadAssessmentMeta]);

  const AssessmentRoute = LayoutRoute;
  return (
    <Switch>
      <AssessmentRoute
        path={`${match.path}/run/questions/:questionId`}
        layout={AssessmentLayout}
        assessmentMeta={assessmentMeta}
        component={AssessmentRunContainer}
        history={history}
      />
      <AssessmentRoute
        path={`${match.path}/run`}
        layout={AssessmentLayout}
        assessmentMeta={assessmentMeta}
        component={AssessmentRunContainer}
        history={history}
      />
      <AssessmentRoute
        path={`${match.path}/host`}
        layout={AssessmentLayout}
        assessmentMeta={assessmentMeta}
        component={AssessmentHostContainer}
        history={history}
      />
    </Switch>
  );
};

export default connect(
  (store, props) => {
    const { match } = props;
    const { entities, identity } = store;
    const { assessmentId } = match.params;

    const normalizedAssessment = entities.assessments[assessmentId];
    const assessment = denormalize(
      normalizedAssessment,
      SCHEMAS.ASSESSMENT,
      entities
    );

    const assessmentMeta = entities.assessmentMetas[assessmentId];

    const isAuthenticated = !!(identity.me && identity.token);

    return {
      assessment,
      assessmentMeta,
      isAuthenticated,
    };
  },
  {
    loadAssessment,
    loadAssessmentMeta,
  }
)(AssessmentRouterContainer);
