import * as React from 'react';
import { connect } from 'react-redux';

import {
  load as loadTeam,
  create as createTeam,
  update as updateTeam
} from 'js/actions/team-actions';

import TeamRouteComponent from '../components/TeamRouteComponent';

class TeamContainer extends React.Component {
  componentDidMount() {
    const { loadTeam, match } = this.props;

    loadTeam(match.params.teamId);
  }

  render() {
    const { team, upsertTeam } = this.props;

    return <TeamRouteComponent team={team} onSave={upsertTeam} />;
  }
}

export default connect(
  (store, props) => {
    const { match } = props;
    const { entities } = store;

    return {
      team: entities.teams[match.params.teamId]
    };
  },
  {
    loadTeam,
    upsertTeam: team => {
      return !team.id || team.id === '_' ? createTeam(team) : updateTeam(team);
    }
  }
)(TeamContainer);
