import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { Icon } from 'js/components';
import { scaleColor as color } from '../../utilities';

// TODO: specify required fields
const ParticipantBar = (props) => {
  const { assessment } = props;

  if (!(assessment && assessment.participants)) {
    return <div className="participant-bar" />;
  }

  const participantData = _.orderBy(
    assessment.participants.map((p) => {
      const responses = _.flatten(
        assessment.question_sets.map((qs) =>
          qs.questions.map((q) => {
            const response = _.find(q.responses, (r) => {
              return r.participant_id === p.id;
            });
            return response && !response.is_na
              ? parseInt(response.value, 10)
              : null;
          })
        )
      );

      const filteredResponses = _.filter(responses, (x) => x !== null);

      return {
        responses,
        overall:
          filteredResponses.length > 0
            ? _.sum(filteredResponses) / filteredResponses.length
            : null,
        id: p.id,
      };
    }),
    [(o) => (o.overall === null ? -1 : o.overall)],
    ['desc']
  );

  function selectParticipant(id) {
    props.onSelectParticipant(id);
  }

  return (
    <div className="participant-bar">
      {participantData.map((pd) => {
        const hasResponses =
          _.reject(pd.responses, (r) => r === null).length !== 0;

        const style = hasResponses
          ? {
              color: color(pd.overall),
            }
          : {};

        const classes = {
          'vote-none': !hasResponses,
        };

        return (
          <span
            className="participant"
            key={pd.id}
            onMouseEnter={selectParticipant.bind(pd, pd.id)}
            onMouseLeave={selectParticipant.bind(pd, null)}
          >
            <Icon icon="user" className={classnames(classes)} style={style} />
          </span>
        );
      })}
    </div>
  );
};
ParticipantBar.defaultProps = {
  onSelectParticipant: () => {},
};

export default ParticipantBar;
