import React from 'react';
import ConditionalLinkWrapper from '../ConditionalLinkWrapper';

const ScorableQuestionResult = ({
  tag: Tag = 'div',
  questionData,
  questionHref,
  parameters,
  color,
}) => {
  const [min, max] = questionData.answerDomain;

  const score = questionData.meanScore ?? null;

  const responseColor =
    score === null ? '#61c4d2' : color((score - min) / (max - min));

  return (
    <Tag
      className="assessment-history-question"
      style={{ backgroundColor: responseColor }}
      data-score={score}
    >
      <ConditionalLinkWrapper
        wrap={!!questionHref}
        href={questionHref}
        parameters={parameters}
      />
    </Tag>
  );
};

export default ScorableQuestionResult;
