import React from 'react';

import { Link } from 'react-router-dom';
import { Icon } from 'js/components';

const InstructionsSent = () => {
  return (
    <>
      <div className="result-icon">
        <Icon icon={['far', 'pumpkin']} transform="shrink-2" />
      </div>
      <div className="result-body">
        <h2>Well, that won't work</h2>
        <div>
          <p className="mb-5">
            Your link seems a bit stale. They're only valid for 24 hours, after
            which they turn back into pumpkins. Not to worry, you can
            just&hellip;
          </p>
          <p className="action-items">
            <Link to="/reset" className="btn full-width-sm">
              Try to reset your password again
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default InstructionsSent;
