import React from 'react';

const HeroBadge = ({ renderIcon, title, children }) => {
  return (
    <div className="hero-badge">
      <div className="hero-badge-icon">{renderIcon()}</div>
      <div className="hero-badge-content">
        <div className="hero-badge-title">{title}</div>
        {children}
      </div>
    </div>
  );
};

export default HeroBadge;
