import SurveyAssessmentEngine from './SurveyAssessment';
import NullAssessment from './NullAssessment';
import LiveAssessment from './LiveAssessment';

// Factory to get the appropriate engine
export function getEngine(assessment) {
  if (!assessment || !assessment.id) {
    return NullAssessment;
  }

  switch (assessment.type) {
    case 'live':
    case 'live-assessment':
      return LiveAssessment;

    default:
      return SurveyAssessmentEngine;
  }
}

export function getEngineForList(assessments) {
  const type = assessments.length > 0 ? assessments[0].type : 'null';
  switch (type) {
    case 'live':
    case 'live-assessment':
      return LiveAssessment;

    case undefined:
    case null:
    case 'null':
      return NullAssessment;

    default:
      return SurveyAssessmentEngine;
  }
}

export function getEngineStrategies(assessment) {
  switch (assessment.type) {
    case 'live':
    case 'live-assessment':
      return LiveAssessment.strategies;

    case undefined:
    case null:
      return NullAssessment.strategies;

    default:
      return SurveyAssessmentEngine.strategies;
  }
}

export function getEngineForTemplate(template) {
  if (!template) {
    return NullAssessment;
  }

  switch (template.type) {
    case 'live':
    case 'live-assessment':
      return LiveAssessment;

    case 'survey':
      return SurveyAssessmentEngine;

    default:
      return NullAssessment;
  }
}
