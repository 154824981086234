import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import _ from 'lodash';

import { SCHEMAS } from 'js/api';
import { me as loadMe } from 'js/actions/account-actions';
import { selectActiveWorkspace } from 'js/store/reducers/workspaces';
import { allows } from 'js/utils/entitlement-utils';

const PrivateRoute = ({
  component: Component,
  unverified = false,
  isAuthenticated,
  me,
  loadMe,
  layout: Layout,
  location,
  children,
  requiredEntitlement,
  activeWorkspace,
  ...rest
}) => {
  const myId = me ? me.id : null;
  const workspacesLoaded = myId && _.every(me.workspaces);
  const accountsLoaded = myId && _.every(me.accounts);
  const meLoaded = myId && workspacesLoaded && accountsLoaded;
  const meVerified = me ? me.verified : null;

  useEffect(() => {
    if (isAuthenticated && !meLoaded) {
      loadMe();
    }
  }, [isAuthenticated, myId, workspacesLoaded, meVerified, loadMe, meLoaded]);

  const renderRoute = (props) => {
    if (!isAuthenticated) {
      const authPage = location.state?.authPage ?? 'login';
      return (
        <Redirect
          to={{ pathname: `/pages/${authPage}`, state: { from: location } }}
        />
      );
    }

    if (!unverified && !meVerified) {
      return (
        <Redirect to={{ pathname: '/verify', state: { from: location } }} />
      );
    }

    if (
      requiredEntitlement &&
      !allows(activeWorkspace.account, requiredEntitlement)
    ) {
      return <Redirect to="/dashboard" />;
    }

    return Layout ? (
      <Layout {...props} component={Component}>
        {children}
      </Layout>
    ) : (
      <Component {...props}>{children}</Component>
    );
  };

  return isAuthenticated && !meLoaded ? null : (
    <Route {...rest} render={renderRoute} />
  );
};

export default connect(
  (store) => {
    const { identity, router, entities } = store;
    const isAuthenticated = !!(identity.me && identity.token);
    const { me } = identity;

    return {
      me: denormalize(me, SCHEMAS.ME, entities),
      isAuthenticated,
      location: router.location,
      activeWorkspace: selectActiveWorkspace(store),
    };
  },
  {
    loadMe,
  }
)(PrivateRoute);
