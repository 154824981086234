export function accumulateExtent(node, depth = 0) {
  const isLeaf = !node.children || node.children.length === 0;

  const result = {
    isLeaf,
    leaves: isLeaf ? 1 : 0,
    clusters: depth === 0 && isLeaf ? 1 : 0,
  };

  if (node.children && node.children.length > 0) {
    const childResults = node.children.reduce(
      (acc, c) => {
        const childResult = accumulateExtent(c, depth + 1);

        return {
          leaves: acc.leaves + childResult.leaves,
          clusters: acc.clusters + childResult.clusters,
          hasLeavesAsChildren: acc.hasLeavesAsChildren || childResult.isLeaf,
        };
      },
      { leaves: 0, clusters: 0, hasLeavesAsChildren: false }
    );

    result.leaves += childResults.leaves;
    result.clusters +=
      childResults.clusters + (childResults.hasLeavesAsChildren ? 1 : 0);
  }

  return result;
}

export function calculateBreadth(tree, targetLeafHeight, targetClusterMargin) {
  const extent = accumulateExtent(tree);

  return (
    targetLeafHeight * extent.leaves +
    targetClusterMargin * (extent.clusters - 1)
  );
}
