import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { withFormik } from 'formik';

import { Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'js/components';

const GuestLoginPageComponent = (props) => {
  const {
    from,
    values,
    errors,
    submitCount,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;

  const formikAttributes = (fieldName) => ({
    id: fieldName,
    name: fieldName,
    value: values[fieldName],
    onChange: handleChange,
    onBlur: handleBlur,
  });

  const renderErrors = () => {
    return errors.displayName && submitCount > 0 ? (
      <div className="validation-message">{errors.displayName}</div>
    ) : null;
  };

  return (
    <div id="login-page" className="featured-form dark">
      <div className="full-page-content">
        <div class="container">
          <form onSubmit={isSubmitting ? () => false : handleSubmit}>
            <div className="row">
              <div className="col-12 col-md-8">
                <h1>Join as a guest</h1>
              </div>
              <div className="col-12 col-md-4 text-right">
                <h1>
                  <small>
                    <Link to={{ pathname: '/pages/login', state: { from } }}>
                      …or login with an account
                    </Link>
                  </small>
                </h1>
              </div>
            </div>
            {renderErrors()}
            <p className="overlay-input-group padded-layout input-lg">
              <span className="input-prepend-icon">
                <Icon icon="user" />
              </span>
              <Input
                type="text"
                placeholder={
                  values['anonymous']
                    ? "We'll make up a name for you..."
                    : 'Name...'
                }
                disabled={values['anonymous']}
                autoFocus={true}
                {...formikAttributes('displayName')}
              />
            </p>
            <p className="form-check">
              <label className="form-check-label">
                <Input
                  type="checkbox"
                  placeholder="Name..."
                  {...formikAttributes('anonymous')}
                />
                <span className="form-check-sign">
                  Keep my identity secret and make up a name for me
                </span>
              </label>
            </p>
            <div className="row">
              <div className="col" />
              <div className="col">
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  inverse="true"
                  block
                >
                  {isSubmitting ? (
                    <Icon
                      icon={['far', 'circle-notch']}
                      className="btn-icon-left"
                      spin
                    />
                  ) : (
                    <Icon icon="sign-in" className="btn-icon-left" />
                  )}
                  Join
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
};

const FormikGuestLoginPageComponent = withFormik({
  mapPropsToValues: () => {
    return {
      displayName: '',
      anonymous: false,
    };
  },
  validationSchema: Yup.object().shape({
    displayName: Yup.mixed().test({
      name: 'Nonanonymous',
      message: `You need a name if you're not anonymous`,
      test: function (value) {
        return this.parent.anonymous || (value || '').trim().length > 0;
      },
    }),
  }),
  handleSubmit: (values, bag) => {
    const { setSubmitting, props } = bag;

    setSubmitting(true);

    Promise.resolve(props.onLogin(values)).finally(() => {
      setSubmitting(false);
    });
  },
  displayName: 'LoginPageComponent',
})(GuestLoginPageComponent);

FormikGuestLoginPageComponent.defaultProps = {
  onLogin: () => {},
  from: null,
};

FormikGuestLoginPageComponent.propTypes = {
  onLogin: PropTypes.func,
  from: PropTypes.object,
};

export default FormikGuestLoginPageComponent;
