import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  list as listAssessments,
  create as createAssessment
} from 'js/actions/assessment-actions';

import { assessments } from 'js/data/question-sets';

import AssessmentsComponent from '../components/AssessmentsComponent';

class AssessmentsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleAssess = this.handleAssess.bind(this);
  }

  componentWillMount() {
    this.props.listAssessments();
  }

  handleAssess(assessmentKey) {
    const { history, createAssessment } = this.props;

    createAssessment(assessments[assessmentKey]).then(result => {
      history.push(`/assessments/${result.id}/run`);
    });
  }

  render() {
    const { assessments } = this.props;

    return (
      <AssessmentsComponent
        assessments={assessments}
        onAssess={this.handleAssess}
      />
    );
  }
}

export default withRouter(
  connect(
    state => {
      const { entities } = state;

      return {
        assessments: entities.assessments
      };
    },
    {
      listAssessments,
      createAssessment
    }
  )(AssessmentsContainer)
);
