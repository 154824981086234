import React from 'react';

const CircleChartWedge = ({
  x,
  y,
  r,
  theta,
  thetaOffset = 0,
  className,
  mask,
  style = {},
}) => {
  const arcStart = `${x + r * Math.cos(thetaOffset)} ${
    y + r * Math.sin(thetaOffset)
  }`;
  const arcEnd = `${x + r * Math.cos(thetaOffset + theta)} ${
    y + r * Math.sin(thetaOffset + theta)
  }`;
  const path = `M${x} ${y} L${arcStart} A${r} ${r} 0 ${
    theta > Math.PI ? 1 : 0
  } 1 ${arcEnd} L${x} ${y}`;

  return (
    <g>
      <path d={path} className={className} style={style} mask={mask} />
    </g>
  );
};

export default CircleChartWedge;
