import React, { useRef, useState, useMemo, useLayoutEffect } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

import Lollipop from 'js/components/Charts/Lollipop';

const AssessmentDetailChart = ({ assessment, height, gradientStops }) => {
  const popWidth = 10;
  const verticalMargin = popWidth / 2;
  const chartHeight = height - popWidth;

  const gradientProps = useMemo(() => {
    return {
      stops: gradientStops,
      domain: gradientStops.map((x) => x[0]),
      range: gradientStops.map((x) => x[1]),
    };
  }, [gradientStops]);

  const chartRef = useRef();
  const [width, setWidth] = useState(null);

  useLayoutEffect(() => {
    const handleResize = () => {
      setWidth(chartRef.current ? chartRef.current.offsetWidth : null);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!assessment || !assessment.id) {
    return null;
  }

  const questionCount = assessment.question_sets.reduce(
    (acc, qs) => acc + qs.questions.length,
    0
  );
  const questionWidth = width / Math.max(questionCount, 1);

  const counts = {};
  assessment.question_sets.forEach((qs) => {
    qs.questions.forEach((q) => {
      q.responses.forEach((r) => {
        const key = `${q.id}-${r.value}`;
        counts[key] = counts.hasOwnProperty(key) ? counts[key] + 1 : 1;
      });
    });
  });

  const yScale = d3.scaleLinear().domain([0, 2]).range([0, chartHeight]);

  const color = d3
    .scaleLinear()
    .domain(gradientProps.domain)
    .range(gradientProps.range);

  const maxStopIndex = gradientProps.stops.length - 1;
  return (
    <div className="live-assessment-detail-chart" ref={chartRef}>
      <svg viewBox={`0 0 ${width} ${height}`}>
        <defs>
          <linearGradient
            id="assessment-detail-line-chart-gradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            {gradientProps.stops.map((stop, i) => (
              <stop
                key={i}
                offset={`${(100 * i) / maxStopIndex}%`}
                style={{ stopColor: stop[1], stopOpacity: 1 }}
              />
            ))}
          </linearGradient>
        </defs>
        <g className="chart-axes" transform={`translate(0,${verticalMargin})`}>
          <line
            x1={0}
            y1={chartHeight - 0.75}
            x2={width}
            y2={chartHeight - 0.75}
            className="chart-axis"
            strokeWidth="1.5px"
          />
          <rect
            x={0}
            y={0}
            height={chartHeight}
            width={2}
            className="color-scale"
            fill="url(#assessment-detail-line-chart-gradient)"
          />
        </g>
        <g transform={`translate(0,${-verticalMargin})`}>
          {assessment.question_sets.map((qs, qsi) => (
            <g
              key={qs.id}
              transform={`translate(${
                qsi * qs.questions.length * questionWidth
              })`}
            >
              {qs.questions.map((q, qi) => {
                let responses = q.responses.filter((r) => !r.is_na);
                if (responses.length === 0) {
                  return (
                    <g
                      key={q.id}
                      transform={`translate(${qi * questionWidth}, 0)`}
                    >
                      <g
                        transform={`translate(${
                          (questionWidth - popWidth) / 2
                        }, ${height - chartHeight / 2})`}
                      >
                        <Lollipop
                          height={chartHeight / 2}
                          width={popWidth}
                          className="no-response-marker"
                        />
                      </g>
                    </g>
                  );
                }
                const score =
                  _.sumBy(responses, (r) => +r.value) / responses.length;

                const barHeight = yScale(score);

                return (
                  <g
                    key={q.id}
                    transform={`translate(${qi * questionWidth}, 0)`}
                  >
                    <g
                      transform={`translate(${
                        (questionWidth - popWidth) / 2
                      }, ${height - barHeight})`}
                    >
                      <Lollipop
                        height={barHeight}
                        width={popWidth}
                        decorator={() => (
                          <line
                            x1={-popWidth / 2}
                            y1={barHeight - 0.75}
                            x2={popWidth / 2}
                            y2={barHeight - 0.75}
                            className="chart-axis"
                          />
                        )}
                        fill={color(score)}
                      />
                    </g>
                  </g>
                );
              })}
            </g>
          ))}
        </g>
      </svg>
      <div>
        <ol className="question-labels">
          {_.flatten(
            assessment.question_sets.map((qs) =>
              qs.questions.map((q) => {
                return (
                  <li key={q.key} className="question-label">
                    {q.text}
                  </li>
                );
              })
            )
          )}
        </ol>
      </div>
    </div>
  );
};

AssessmentDetailChart.defaultProps = {
  gradientStops: [
    [2, '#4c9745'],
    [1, '#f0dd2b'],
    [0, '#ed2228'],
  ],
};

export default AssessmentDetailChart;
