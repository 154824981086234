import React from 'react';

import { Link } from 'react-router-dom';
import { Loading, Icon } from 'js/components';

const renderError = error => {
  if (error) {
    // error.message = 'token expired';
    switch (error.toLowerCase()) {
      case 'token not found':
        return renderErrorComponent(
          <>Oops...</>,
          <div>
            We looked everywhere but couldn't an account to verify with that
            information.
          </div>,
          ['far', 'comment-alt-exclamation']
        );

      case 'token expired':
        return renderErrorComponent(
          <>Missed it by that much...</>,
          <div>
            Your verification link expired (they're only good for 24 hours), but
            that's okay. We can send you another one if you'd like.
            {/* TODO: add link */}
          </div>,
          ['far', 'clock']
        );

      default:
    }
  }

  return renderErrorComponent(<>Uh oh!</>, <>An unknown error has occurred</>, [
    'far',
    'comment-alt-exclamation'
  ]);
};

const renderBody = (successOrError, me) => {
  if (successOrError === true) {
    return (
      <div className="page-result page-success">
        <div className="result-icon">
          <Icon icon={['far', 'clipboard-check']} fixedWidth />
        </div>
        <div className="result-body">
          <h2>Everything checks out</h2>
          <div>
            <p>
              Your account has been verified and your user profile has been
              updated to use the new address.
            </p>
            {me ? (
              <>
                <Link to="/dashboard" className="btn btn-lg">
                  <Icon
                    icon={['far', 'truck-monster']}
                    className="btn-icon-left"
                  />{' '}
                  Let's get started
                </Link>
              </>
            ) : (
              <>
                <Link to="/pages/login" className="btn btn-lg">
                  <Icon icon="sign-in" className="btn-icon-left" /> Sign in now
                </Link>
                <p>
                  <span className="tiny">
                    &hellip;or take a break, grab a drink and do it later. We'll
                    be here.
                  </span>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  } else if (successOrError && successOrError.message) {
    return renderError(successOrError);
  }

  return <Loading />;
};

const renderErrorComponent = (title, message, icon) => {
  return (
    <div className="page-result">
      <div className="result-icon">
        <Icon icon={icon} fixedWidth />
      </div>
      <div className="result-body">
        <h2>{title}</h2>
        {message}
      </div>
    </div>
  );
};

const VerifyAccountPageComponent = ({ me, successOrError }) => {
  return (
    <div id="verify-account-page" className="featured-form">
      <div className="full-page-content">
        <div className="container">
          <div className="content">{renderBody(successOrError, me)}</div>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
};

export default VerifyAccountPageComponent;
