import React from 'react';
import { map } from 'lodash';

export const CategoryPills = ({ categories, categoryLookups }) => {
  return !categories || !categoryLookups ? null : (
    <div>
      {map(categories, (category) => {
        const categoryInfo = categoryLookups.get(category);

        return (
          <span
            key={category}
            className={`category-pill ${categoryInfo?.palette}`}
          >
            {categoryInfo?.display_name}
          </span>
        );
      })}
    </div>
  );
};

export default CategoryPills;
