import React from 'react';

import { IconStack, Icon, PanelHeader } from 'js/components';
import LoadingMessage from 'js/components/Loading/LoadingMessage';
import OrgTree from './Visualizations/OrgTree';
import { Card, CardBody } from 'reactstrap';

const UnassessedOrgComponent = ({
  organization,
  loading,
  error,
  workspace,
}) => {
  return (
    <div>
      <PanelHeader size="flex">
        <div className="panel-content assessment-review-header pb-0">
          <section className="details">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <label>Organization</label>
                  {loading ? (
                    <div className="mt-3">
                      <LoadingMessage iconSize="2x">
                        Loading &hellip;
                      </LoadingMessage>
                    </div>
                  ) : error ? (
                    <p className="mt-3 danger">
                      Uh oh, there was a problem loading the organization
                    </p>
                  ) : (
                    <h1>
                      {organization.id === 0
                        ? workspace.name
                        : organization.name}
                    </h1>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="container-fluid org-chart-selector"
          style={{ marginBottom: '2rem' }}
        >
          {organization && (
            <OrgTree organization={organization} teamScores={[]} />
          )}
        </div>
      </PanelHeader>
      <div className="content">
        <Card>
          <CardBody className="media text-muted mt-1">
            <IconStack size="4x" fixedWidth className="mr-3">
              <Icon icon={['far', 'search']} />
              <Icon icon="question" transform="shrink-9 left-1.5 up-1.5" />
            </IconStack>
            <div className="media-body">
              <p>
                You haven't assessed any of the teams in this organization yet.
              </p>
              <p>
                Once you do, come back here to compare the results across your
                entire organization.
              </p>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default UnassessedOrgComponent;
