import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { withFormik } from 'formik';

import { Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'js/components';

const mapErrors = (error) => {
  const { response } = error;
  let errors = {};

  if (response && response.status === 401) {
    errors = {
      login:
        "I couldn't find anyone with that username and password. Try again?",
    };
  }

  return errors;
};

const LoginPageComponent = (props) => {
  const {
    from,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;

  const formikAttributes = (fieldName) => ({
    id: fieldName,
    name: fieldName,
    value: values[fieldName],
    onChange: handleChange,
    onBlur: handleBlur,
  });

  const renderErrors = () => {
    return errors.login ? (
      <div className="validation-message">{errors.login}</div>
    ) : null;
  };

  return (
    <div id="login-page" className="featured-form">
      <div className="full-page-content shallow">
        <div className="container">
          <div className="central-form">
            <form onSubmit={isSubmitting ? () => false : handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <h1>Login</h1>
                </div>
              </div>
              {renderErrors()}
              <p className="overlay-input-group padded-layout input-lg">
                <span className="input-prepend-icon">
                  <Icon icon="user" />
                </span>
                <Input
                  type="text"
                  autoFocus={true}
                  placeholder="Username..."
                  {...formikAttributes('username')}
                />
              </p>
              <p className="overlay-input-group padded-layout input-lg">
                <span className="input-prepend-icon">
                  <Icon icon="key" />
                </span>
                <Input
                  type="password"
                  placeholder="Password..."
                  {...formikAttributes('password')}
                />
              </p>
              <p className="decorator-link">
                <Link to="/reset">Forgot your password?</Link>
              </p>

              <div className="row">
                <div className="col">
                  <Link
                    to={{ pathname: '/pages/register', state: { from } }}
                    className="btn btn-primary btn-ghost btn-block"
                  >
                    <span className="fa-fw fa-layers btn-icon-left">
                      <Icon
                        icon="rectangle-wide"
                        transform="shrink-4 right-4 down-7 rotate-135"
                        mask={['far', 'clipboard-list']}
                      />
                      <Icon
                        icon="pencil-alt"
                        transform="shrink-6 right-4 down-5"
                      />
                    </span>
                    Register
                  </Link>
                </div>
                <div className="col">
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    inverse="true"
                    block
                  >
                    {isSubmitting ? (
                      <Icon
                        icon={['far', 'circle-notch']}
                        className="btn-icon-left"
                        spin
                      />
                    ) : (
                      <Icon icon="sign-in" className="btn-icon-left" />
                    )}
                    Login
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
};

const FormikLoginPageComponent = withFormik({
  mapPropsToValues: () => {
    return {
      username: '',
      password: '',
    };
  },
  validationSchema: Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  }),
  handleSubmit: (values, bag) => {
    const { setSubmitting, props, setErrors } = bag;

    setSubmitting(true);

    Promise.resolve(props.onLogin(values))
      .catch((error) => {
        setErrors(mapErrors(error));
      })
      .finally(() => {
        setSubmitting(false);
      });
  },
  displayName: 'LoginPageComponent',
})(LoginPageComponent);

FormikLoginPageComponent.defaultProps = {
  onLogin: () => {},
  from: null,
};

FormikLoginPageComponent.propTypes = {
  onLogin: PropTypes.func,
  from: PropTypes.object,
};

export default FormikLoginPageComponent;
