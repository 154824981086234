// TODO: Consolidate with the assessment middleware in webapp/src/js/middleware/assessment.js. The middleware
// uses a socket that requires a user to be authenticated. This hook requires a participant token. The consolidated version
// should support both - possibly by providing the socket, which may require a refactor of other middleware that uses sockets.
import { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Socket, Presence } from 'phoenix';
import { throttle } from 'lodash';

import { types as ChannelTypes } from 'js/actions/channel-actions';
import { load as loadAssessment } from 'js/actions/assessment-actions';
import { types as LiveAssessmentTypes } from 'js/actions/live-assessment-actions';

const handleUpdatedAssessment = (channel, assessmentId, dispatch, getState) => {
  if (channel) {
    channel.load =
      channel.load ||
      throttle((key, dispatch, getState) => {
        loadAssessment(key)(dispatch, getState);
      }, 2000);

    channel.load(assessmentId, dispatch, getState);
  } else {
    loadAssessment(assessmentId)(dispatch, getState);
  }
};

const syncPresentUsers = (presence, key, dispatch) => {
  const participants = presence.list().map((p) => p.metas[0]);

  dispatch({
    type: ChannelTypes.PRESENCES_UPDATED,
    key,
    participants,
  });
};

const leaveChannel = (socket, channel) => {
  channel?.leave();
  socket?.disconnect();
};

export function useAssessmentChannel(assessmentId, participantToken) {
  // const [SOCKET, setSocket] = useState(null);
  const socketRef = useRef(null);
  const channelRef = useRef(null);

  const dispatch = useDispatch();
  const store = useStore();

  const connect = useCallback(
    (assessmentId, participantToken) => {
      if (socketRef.current) {
        return socketRef.current;
      }

      const socket = new Socket('/participant-socket', {
        params: {
          assessment_id: assessmentId,
          token: participantToken,
        },
      });

      socket.connect();
      socketRef.current = socket;

      return socket;
    },
    [socketRef]
  );

  const joinChannel = useCallback(() => {
    // connection will fail without an assessmentId and participantToken
    if (!(assessmentId && participantToken)) {
      return;
    }

    const socket = connect(assessmentId, participantToken);

    // already joined (might need to be smarter)
    if (channelRef.current) {
      return;
    }

    channelRef.current = socket.channel(`assessment:${assessmentId}`, {});

    const updateAssessment = () =>
      handleUpdatedAssessment(
        channelRef.current,
        assessmentId,
        dispatch,
        store.getState
      );
    const updateLiveAssessment = (message) => {
      const { data: liveAssessment, ack } = message;

      dispatch({
        type: LiveAssessmentTypes.UPDATE_LIVE_ASSESSMENT_SUCCESS,
        data: {
          entities: {
            liveAssessments: { [liveAssessment.assessment_id]: liveAssessment },
          },
        },
        meta: { ack },
      });
    };

    channelRef.current.on('RESPONSE_RECORDED', updateAssessment);
    channelRef.current.on('CREATED', updateAssessment);
    channelRef.current.on('UPDATED', updateAssessment);
    channelRef.current.on('PARTICIPANT_CREATED', updateAssessment);
    channelRef.current.on('PARTICIPANT_UPDATED', updateAssessment);
    channelRef.current.on('LIVE_ASSESSMENT_UPDATED', updateLiveAssessment);

    const presence = new Presence(channelRef.current);
    presence.onSync(() => syncPresentUsers(presence, assessmentId, dispatch));

    channelRef.current
      .join()
      .receive('ok', (resp) => {
        console.log('Joined successfully', resp);
      })
      .receive('error', (resp) => {
        console.log('Unable to join', resp);
      });
  }, [assessmentId, participantToken, dispatch, store.getState, connect]);

  useEffect(() => {
    joinChannel();

    return () => {
      leaveChannel(socketRef.current, channelRef.current);
      channelRef.current = null;
      socketRef.current = null;
    };
  }, [assessmentId, participantToken, joinChannel]);
}
