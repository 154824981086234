import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { denormalize } from 'normalizr';
import _ from 'lodash';

import { SCHEMAS } from 'js/api';

import {
  list as listAssessments,
  update as updateAssessment,
} from 'js/actions/assessment-actions';
import { list as listTeams } from 'js/actions/team-actions';
import { search as searchPassphrases } from 'js/actions/passphrase-actions';
import { notify } from 'js/actions/alert-actions';

import HomeRouteComponent from '../components/HomeRouteComponent';

const queryIdBase = 'home-route-container';

export class HomeRouteContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passphraseMessage: '',
      initialLoadComplete: false,
    };
  }

  componentDidMount() {
    const { listTeams, listAssessments } = this.props;

    listTeams({ favorite: true, active: true }, `${queryIdBase}-favorite-teams`)
      .then((teams) => {
        if (teams.length > 0) {
          return listAssessments(
            { 'team_id[]': teams.map((t) => t.id) },
            `${queryIdBase}-team-assessments`
          );
        } else {
          return [];
        }
      })
      .then(() => {
        this.setState({ initialLoadComplete: true });
      });
  }

  componentWillUnmount() {
    this.setState({ initialLoadComplete: false });
  }

  handleJoinAssessment = (passphrase) => {
    const { searchPassphrases, history } = this.props;
    this.setState({
      passphraseMessage: '',
    });

    const setNotFound = () => {
      this.setState({
        passphraseMessage: 'No assessment found',
      });
    };

    return searchPassphrases({ passphrase }).then((results) => {
      if (results.length > 0) {
        history.push(`/assessments/${results[0].assessment_id}/run`);
      } else {
        setNotFound();
      }
    }, setNotFound);
  };

  handleToggleAssessmentLock = (assessment) => {
    const { updateAssessment } = this.props;

    return updateAssessment({
      id: assessment.id,
      is_locked: !assessment.is_locked,
    });
  };

  handleDeleteAssessment = (assessment) => {
    const { updateAssessment } = this.props;

    return updateAssessment({
      id: assessment.id,
      active: false,
    });
  };

  handleCopyLink = () => {
    const { notify } = this.props;

    notify({ type: 'success', message: 'Link copied to clipboard' });
  };

  render() {
    const { favoriteTeams, loadingAssessments, currentWorkspaceId } =
      this.props;
    const { initialLoadComplete, passphraseMessage } = this.state;

    return (
      <HomeRouteComponent
        workspaceId={currentWorkspaceId}
        joinAssessmentMessage={passphraseMessage}
        teams={favoriteTeams}
        loaded={initialLoadComplete}
        loadingAssessments={loadingAssessments}
        onJoinAssessment={this.handleJoinAssessment}
        onToggleAssessmentLock={this.handleToggleAssessmentLock}
        onDeleteAssessment={this.handleDeleteAssessment}
        onCopyLink={this.handleCopyLink}
      />
    );
  }
}

export default withRouter(
  connect(
    (state) => {
      const { currentWorkspaceId } = state;

      const teamQueryInfo =
        state.queries[`${queryIdBase}-favorite-teams`] || {};
      const assessmentQueryInfo =
        state.queries[`${queryIdBase}-team-assessments`] || {};

      const favoriteTeams = denormalize(
        teamQueryInfo.result || [],
        SCHEMAS.TEAM_ARRAY,
        state.entities
      );

      const assessments = _.groupBy(
        denormalize(
          assessmentQueryInfo.result || [],
          SCHEMAS.ASSESSMENT_ARRAY,
          state.entities
        ),
        (x) => x.team_id
      );

      favoriteTeams.forEach((team) => {
        team.assessments = _.chain(assessments[team.id] || [])
          .orderBy(['inserted_at'], ['desc'])
          .groupBy((a) => a.key)
          .value();
      });

      return {
        favoriteTeams,
        assessments,
        loadingAssessments: assessmentQueryInfo.isLoading || false,
        currentWorkspaceId,
      };
    },
    {
      searchPassphrases,
      listTeams,
      listAssessments,
      updateAssessment,
      notify,
    }
  )(HomeRouteContainer)
);
