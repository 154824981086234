import { types as AccountTypes } from 'js/actions/account-actions';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { denormalize } from 'normalizr';
import { SCHEMAS } from 'js/api';

export const QueryBase = Object.freeze({
  AccountSummary: 'account-summary',
  UsersInAccount: 'users-in-account',
  UsersInWorkspace: 'users-in-workspace',
  AssessmentInvites: 'assessment-invites',
  AssessmentTemplates: 'assessment-templates',
  WorkspaceOrgs: 'workspace-orgs',
  TeamScores: 'team-scores',
  QuestionScores: 'question-scores',
  TeamParticipation: 'team-participation',
  AssessmentHistorySummaries: 'assessment-history-summaries',
});

const initialState = {};

const queries = (state = initialState, action) => {
  switch (action.type) {
    case AccountTypes.LOGOUT:
    case AccountTypes.LOGOUT_SUCCESS:
    case 'API_ERROR/INVALID_TOKEN':
      return initialState;

    default:
    // noop
  }

  if (action.meta && action.meta.queryId) {
    return {
      ...state,
      [action.meta.queryId]: {
        id: action.meta.queryId,
        ...action.status,
        result: action.data && action.data.result ? action.data.result : null,
        error: action.error ? action.error : null,
      },
    };
  }

  return state;
};

const selectEntities = (store) => store.entities;
const selectQuery =
  (queryBase) =>
  (store, id, ...args) => {
    return store.queries[`${queryBase}-${id}`];
  };

export const selectWorkspaceUsersQuery = createSelector(
  selectQuery(QueryBase.UsersInWorkspace),
  selectEntities,
  (query, entities) => {
    if (!query) {
      return { query: {}, data: [] };
    }

    const data = query?.result
      ? _.chain(
          denormalize(query.result, SCHEMAS.WORKSPACE_USER_ARRAY, entities)
        )
          .filter((u) => !u.is_guest)
          .sortBy(['username'])
          .value()
      : [];

    return { query, data };
  }
);

export const selectAccountUsersQuery = createSelector(
  selectQuery(QueryBase.UsersInAccount),
  selectEntities,
  (query, entities) => {
    if (!query) {
      return { query: {}, data: [] };
    }

    const data = query?.result
      ? denormalize(query.result, SCHEMAS.ACCOUNT_USER_ARRAY, entities)
      : [];

    return { query, data };
  }
);

export const selectAssessmentInvitesQuery = createSelector(
  selectQuery(QueryBase.AssessmentInvites),
  selectEntities,
  (query, entities) => {
    if (!query) {
      return { query: {}, data: [] };
    }

    const data = query?.result
      ? _.chain(
          denormalize(query.result, SCHEMAS.ASSESSMENT_INVITES_ARRAY, entities)
        )
          .sortBy(['email'])
          .value()
      : [];

    return { query, data };
  }
);

export const selectAssessmentTemplatesQuery = createSelector(
  selectQuery(QueryBase.AssessmentTemplates),
  selectEntities,
  (query, entities) => {
    if (!query) {
      return { query: {}, data: [] };
    }

    const data = query?.result
      ? denormalize(query.result, SCHEMAS.ASSESSMENT_TEMPLATE_ARRAY, entities)
      : [];

    return { query, data };
  }
);

export const selectWorkspaceOrgsQuery = createSelector(
  selectQuery(QueryBase.WorkspaceOrgs),
  (query) => {
    if (!query) {
      return { query: {}, data: [] };
    }

    const data = query?.result
      ? _.chain(query.result).sortBy(['name']).value()
      : [];

    return { query, data };
  }
);

export const selectTeamScoresQuery = createSelector(
  selectQuery(QueryBase.TeamScores),
  (query) => {
    return { query: query ?? {}, data: query?.result ?? null };
  }
);

export const selectQuestionScoresQuery = createSelector(
  selectQuery(QueryBase.QuestionScores),
  (query) => {
    return { query: query ?? {}, data: query?.result ?? null };
  }
);

export const selectTeamParticipationQuery = createSelector(
  selectQuery(QueryBase.TeamParticipation),
  (query) => {
    return { query: query ?? {}, data: query?.result ?? null };
  }
);

export const selectAssessmentHistorySummaries = createSelector(
  selectQuery(QueryBase.AssessmentHistorySummaries),
  (query) => {
    return { query: query ?? {}, data: query?.result ?? null };
  }
);

export const selectAccountSummary = createSelector(
  selectQuery(QueryBase.AccountSummary),
  selectEntities,
  (query) => {
    return { query: query ?? {}, data: query?.result ?? null };
  }
);

export default queries;
