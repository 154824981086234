export const yesNo = [
  { value: '1', text: 'Yes' },
  { value: '0', text: 'No' },
];

export const trueFalse = [
  { value: '1', text: 'True' },
  { value: '0', text: 'False' },
];

export const trueFalseReverse = [
  { value: '0', text: 'True' },
  { value: '1', text: 'False' },
];

export const trueFalseNa = [...trueFalse, { value: null, text: 'Not Applicable', is_na: true }];
