import React, { useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { selectQuestionScoresQuery } from 'js/store/reducers/queries';
import _ from 'lodash';
import { lookupQuestionScores } from 'js/actions/org-actions';
import OrgComponentDetailRouter from '../components/OrgComponent/OrgComponentDetailRouter';
import { connect } from 'react-redux';
import { selectActiveWorkspace } from 'js/store/reducers/workspaces';

function OrgAssessmentDetailContainer({
  detailOrgId,
  assessmentKey,
  questionScoresQuery,
  lookupQuestionScores,
  match,
  location,
  organization,
  workspace,
}) {
  const shouldLoadQuestionScores =
    !questionScoresQuery?.data &&
    !questionScoresQuery?.query?.error &&
    questionScoresQuery?.query?.isLoading !== true;

  const query = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search]
  );

  useEffect(() => {
    if (shouldLoadQuestionScores) {
      lookupQuestionScores(detailOrgId, assessmentKey, query).catch(_.noop);
    }
  }, [
    lookupQuestionScores,
    detailOrgId,
    assessmentKey,
    query,
    shouldLoadQuestionScores,
  ]);

  return (
    <OrgComponentDetailRouter
      match={match}
      questionScoresQuery={questionScoresQuery}
      organization={organization}
      workspace={workspace}
    />
  );
}

export default connect(
  (store, params) => {
    const orgId = params.match.params.orgId;
    const assessmentKey = params.match.params.assessmentKey;
    const detailOrgId = params.match.params.subOrgId ?? orgId;
    return {
      detailOrgId,
      assessmentKey,
      questionScoresQuery: selectQuestionScoresQuery(
        store,
        `${detailOrgId}-${assessmentKey}`
      ),
      organization: params.organization,
      workspace: selectActiveWorkspace(store),
    };
  },
  {
    lookupQuestionScores,
  }
)(withRouter(OrgAssessmentDetailContainer));
