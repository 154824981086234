import React from 'react';
import classNames from 'classnames';
// import { Card, CardHeader, CardBody } from 'reactstrap';
import { Icon } from 'js/components';

import styles from './FeatureCard.module.scss';

// export const FeatureCard = ({
//   className,
//   src,
//   alt,
//   icon,
//   title,
//   children,
// }) => {
//   return (
//     <Card className={classNames(className, styles['feature-card'])}>
//       <CardHeader>
//         <img src={src} alt={alt} />
//       </CardHeader>
//       <CardBody>
//         <h4 className={styles.title}>
//           {icon ? (
//             <span className={styles['header-grid']}>
//               <span>
//                 {React.isValidElement(icon) ? icon : <Icon icon={icon} />}
//               </span>
//               <span>{title}</span>
//             </span>
//           ) : (
//             { title }
//           )}
//         </h4>

//         {children}
//       </CardBody>
//     </Card>
//   );
// };

export const FeatureCard = ({ className, src, alt, icon, title, children }) => {
  return (
    <div className={classNames(className, styles['feature-card2'])}>
      <div className={styles.image}>
        <img src={src} alt={alt} />
      </div>
      <div className={styles.gap}></div>
      <div className={styles.body}>
        <div className={styles.content}>
          <h4 className={styles.title}>
            {icon ? (
              <span className={styles['header-grid']}>
                <span>
                  {React.isValidElement(icon) ? icon : <Icon icon={icon} />}
                </span>
                <span>{title}</span>
              </span>
            ) : (
              { title }
            )}
          </h4>

          {children}
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
