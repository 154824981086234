import * as React from 'react';
import { PanelHeader } from 'js/components';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import InviteUserForm from 'js/components/User/InviteUserForm';

function InviteUserComponent({ onSubmit, serverErrors }) {
  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Invite a user to the workspace</CardTitle>
          </CardHeader>
          <CardBody>
            <InviteUserForm onSave={onSubmit} serverErrors={serverErrors} />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default InviteUserComponent;
