import { request } from 'js/api/axios';
import keymirror from 'js/utils/keymirror';
import { spreadApiKeys, extractApiKeys } from 'js/utils';

import { callApi } from 'js/api';
import { SCHEMAS } from 'js/api/schemas';

import { me } from '../account-actions';

export const types = keymirror(
  {
    ...spreadApiKeys('LIST_USERS'),
    ...spreadApiKeys('INVITE_USER'),
    ...spreadApiKeys('REMOVE_USER'),
    ...spreadApiKeys('VERIFY_INVITE_TOKEN'),
    ...spreadApiKeys('ACCEPT_INVITE'),
    ...spreadApiKeys('REGISTER'),
    // ...spreadApiKeys('CREATE_USER'),
    // ...spreadApiKeys('INVITE_USER'),
  },
  { prefix: 'ACCOUNT/' }
);

export function list(accountId, queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LIST_USERS', types),
        request: (_data, token) =>
          request.get(`/api/accounts/${accountId}/users`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        schema: SCHEMAS.ACCOUNT_USER_ARRAY,
        meta: queryId !== null ? { queryId } : {},
      },
      dispatch
    );
  };
}

export function invite(accountId, email) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('INVITE_USER', types),
        request: (data, token) =>
          request.post(`/api/accounts/${accountId}/invites`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { email },
      },
      dispatch
    );
  };
}

export function resendInvite(accountId, email) {
  return (dispatch) => {
    const encodedEmail = encodeURIComponent(email);
    return callApi(
      {
        types: extractApiKeys('INVITE_USER', types),
        request: (_data, token) =>
          request.put(
            `/api/accounts/${accountId}/invites/${encodedEmail}`,
            null,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
      },
      dispatch
    );
  };
}

export function verifyInviteToken(key, token) {
  return async (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('VERIFY_INVITE_TOKEN', types),
        request: (data) =>
          request.get(`/api/accounts/invites/tokens/${token}`, {
            params: data,
          }),
        data: { key },
      },
      dispatch
    );
  };
}

export function acceptInviteToken(token, key, registration = null) {
  const data = {
    registration,
    key,
  };

  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys(
          registration ? 'REGISTER' : 'ACCEPT_INVITE',
          types
        ),
        request: () =>
          request.post(`/api/accounts/invites/tokens/${token}`, data),
        data,
      },
      dispatch
    ).then(() => {
      return me()(dispatch, getState);
    });
  };
}

export function removeFromAccount(accountId, userId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('REMOVE_USER', types),
        request: async (_data, token) => {
          return request
            .delete(`/api/accounts/${accountId}/users/${userId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              list(accountId);
              return res;
            });
        },
      },
      dispatch
    );
  };
}
