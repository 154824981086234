import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container,
} from 'reactstrap';
import { Icon } from 'js/components';
import ProfileDropdown from './ProfileDropdown';
import NavMenuItems from './NavMenuItems';
import Logo from 'images/logo.svg';
import LogoDark from 'images/logo-dark.svg';

const DashboardLink = ({ me, isAuthenticated, currentWorkspaceId }) => {
  const hasWorkspaces = (me?.workspaces?.length ?? 0) > 0;
  const fallbackAccountId =
    me?.workspaces?.find((w) => w?.id === +currentWorkspaceId)?.account?.id ??
    me?.accounts?.[0]?.id;

  if (isAuthenticated && hasWorkspaces) {
    return (
      <NavItem>
        <Link to={`/w/${currentWorkspaceId}/dashboard`} className="nav-link">
          <i className="now-ui-icons design_bullet-list-67" /> Dashboard
        </Link>
      </NavItem>
    );
  } else if (fallbackAccountId !== undefined) {
    return (
      <NavItem>
        <Link to={`/a/${fallbackAccountId}/dashboard`} className="nav-link">
          <i className="now-ui-icons design_bullet-list-67" /> Dashboard
        </Link>
      </NavItem>
    );
  }

  return null;
};

const PagesHeader = ({
  me,
  identity,
  currentWorkspaceId,
  onLogout = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const node = useRef(null);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 993 && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    const handleClick = ({ target }) => {
      if (!node.current || !isOpen || node.current.contains(target)) {
        return;
      }

      setIsOpen(false);
    };

    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [node, isOpen, setIsOpen]);

  const isAuthenticated = identity.token && identity.me;

  return (
    <div ref={node} className="app-header">
      <Navbar
        expand="lg"
        className={
          isOpen
            ? 'bg-white navbar-absolute'
            : 'navbar-transparent navbar-absolute'
        }
      >
        <Container fluid={true}>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <NavbarToggler onClick={toggle}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            <Link to="/" className="navbar-brand">
              {isOpen ? (
                <img src={LogoDark} className="logo" alt="logo" />
              ) : (
                <img src={Logo} className="logo" alt="logo" />
              )}
            </Link>
          </div>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <DashboardLink
                me={me}
                currentWorkspaceId={currentWorkspaceId}
                isAuthenticated={isAuthenticated}
              />
              {isAuthenticated && (
                <>
                  <NavItem
                    data-test="nav-item-account"
                    className="d-none d-lg-block"
                  >
                    <ProfileDropdown profile={me} onLogout={onLogout} />
                  </NavItem>
                  <NavMenuItems
                    profile={me}
                    onLogout={onLogout}
                    itemTag={NavItem}
                    itemProps={{ className: 'd-lg-none' }}
                    linkProps={{ className: 'nav-link' }}
                    buttonProps={{ className: 'nav-link' }}
                  />
                </>
              )}

              {!isAuthenticated && (
                <NavItem>
                  <Link to="/pages/login" className="nav-link">
                    <Icon icon="sign-in" size="lg" fixedWidth />
                    <p>
                      <span className="d-md-block">Sign in</span>
                    </p>
                  </Link>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default PagesHeader;
