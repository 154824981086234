import React from 'react';

import { faRobot } from '@fortawesome/pro-light-svg-icons';
import { Icon } from 'js/components';

import styles from './AssessmentDifferential.module.scss';

export const NoAssessmentSelected = () => {
  return (
    <div className={styles['no-assessment-selected']}>
      <div className="media loading-message text-muted">
        <Icon icon={faRobot} size={'5x'} className="mr-3" fixedWidth />
        <div className="media-body">
          <p>
            Choose any two assessments and we'll show you how your results have
            changed over time.
          </p>
          <p>Because knowing is half the battle. The other half is lasers.</p>
        </div>
      </div>
    </div>
  );
};

export default NoAssessmentSelected;
