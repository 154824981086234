import { canManageAccount } from 'js/utils/authorization-utils';
import React from 'react';
import { Link } from 'react-router-dom';

function UnpaidMessage({ me, activeAccount }) {
  let message;

  if (activeAccount.billing_type === 'stripe') {
    if (canManageAccount(me, activeAccount.id)) {
      message = (
        <>
          Your account has been downgraded to our free tier because we couldn't
          collect payment. To reinstate premium features, please visit the{' '}
          <Link to={`/a/${activeAccount.id}/settings/billing`}>
            account billing page
          </Link>{' '}
          and upgrade your subscription.
        </>
      );
    } else {
      message = (
        <>
          Your account has been downgraded to our free tier because we couldn't
          collect payment. To reinstate premium features, please notify your
          account admin.
        </>
      );
    }
  } else {
    if (canManageAccount(me, activeAccount.id)) {
      message = (
        <>
          Your account has been downgraded to our free tier because we couldn't
          collect payment. To reinstate premium features, please contact your
          sales representitive.
        </>
      );
    } else {
      message = (
        <>
          Your account has been downgraded to our free tier because we couldn't
          collect payment. To reinstate premium features, please notify your
          account admin.
        </>
      );
    }
  }

  return message;
}

export default UnpaidMessage;
