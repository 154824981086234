import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { Icon } from 'js/components';

const OrgList = ({ organizations, workspaceId, onDeleteOrg }) => {
  return (
    <div className="org-list">
      {organizations.map((org) => (
        <div className="org-item" key={org.id}>
          <Card>
            <CardHeader className="px-4">
              <div className="org-item-header">
                <div>
                  <CardTitle tag="h5">{org.name}</CardTitle>
                  <div className="team-count">{org.team_count} teams</div>
                </div>
                <div className="actions">
                  <Button
                    className="my-0 mr-2"
                    color="primary"
                    to={`/w/${workspaceId}/orgs/${org.id}/manage`}
                    tag={Link}
                  >
                    <Icon icon="pencil-alt" />
                  </Button>
                  <Button
                    className="btn my-0"
                    color="secondary"
                    onClick={() => onDeleteOrg(org)}
                  >
                    <Icon icon="trash-alt" />
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody className="px-4">
              <p>
                <Link to={`/w/${workspaceId}/orgs/${org.id}`}>
                  <Icon icon="analytics" className="mr-2" /> View organization
                  data
                </Link>
              </p>
            </CardBody>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default OrgList;
