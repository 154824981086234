import React from 'react';

import styles from './TeamMember.module.scss';

import { Button } from 'reactstrap';
import { Icon } from 'js/components';
import Allocation from './Allocation';

const TeamMember = ({
  teamMember,
  tag: Tag = 'li',
  onEdit = () => {},
  ...props
}) => {
  return (
    <Tag {...props}>
      <div className={styles['team-member-card']}>
        <div className={styles['team-member-avatar']}>
          <div className={styles['team-member-avatar-image']}>
            <Icon icon="user" size="lg" />
          </div>
        </div>
        <div className={styles['team-member-name']}>{teamMember.name}</div>
        <div className={styles['team-member-role']}>
          {teamMember.role ?? <span>--</span>}
        </div>
        <div className={styles['team-member-email']}>
          {teamMember.email ?? <em>(no email)</em>}
        </div>
        <div className={styles['team-member-allocation']}>
          <Allocation value={teamMember.allocation} readonly />
        </div>
        <div className={styles['team-member-actions']}>
          <Button
            data-test="edit-user-btn"
            className="btn-icon"
            size="sm"
            onClick={onEdit}
          >
            <Icon icon={['far', 'user-edit']} />
          </Button>
        </div>
      </div>
    </Tag>
  );
};

export default TeamMember;
