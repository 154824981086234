import React from 'react';

const NullFill = ({ width, height }) => (
  <rect
    width={width}
    height={height}
    className="participation-tree-node null"
    rx={height / 5}
  />
);
export default NullFill;
