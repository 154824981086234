import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Icon } from '..';
import styles from './ArchivedTeamList.module.scss';
import moment from 'moment';
import classNames from 'classnames';

const noop = () => {};

const archivedStates = {
  blocked: { label: 'Blocked', className: 'palette-cat-1' },
  archived: { label: 'Archived', className: 'palette-cat-3' },
  unknown: { label: 'Archived', className: 'palette-cat-3' },
};

const ArchiveStateBadge = ({ archiveState }) => {
  const { label, className } =
    archivedStates[archiveState] || archivedStates.unknown;

  return <div className={classNames('badge', className)}>{label}</div>;
};

const ArchivedTeamList = ({
  teams,
  canModifyTeams,
  canRestoreTeams,
  onRestoreTeam = noop,
}) => {
  return (
    <Table responsive className="team-list">
      <tbody>
        {teams.map((team) => {
          return (
            <tr
              key={team.id}
              className={classnames({
                team: true,
              })}
            >
              <td>
                <div className={styles.InfoCell}>
                  <div>
                    <span className={styles.IconContainer}>
                      <Icon
                        icon={['far', 'archive']}
                        className="muted"
                        fixedWidth
                      />
                    </span>
                    <Link
                      to={`/w/${team.workspace_id}/teams/${team.id}/dashboard`}
                    >
                      {team.name}
                    </Link>
                  </div>
                </div>
              </td>
              <td valign="middle">
                <ArchiveStateBadge archiveState={team.archived_state} />
              </td>
              <td className="muted">
                {moment(team.archived_at).format('M/D/YYYY')}
              </td>
              <td className="text-right">
                {canModifyTeams && canRestoreTeams ? (
                  <Button
                    className="btn-icon"
                    onClick={() => onRestoreTeam(team)}
                  >
                    <Icon icon="box-open" />
                  </Button>
                ) : (
                  <div
                    title={
                      !canModifyTeams
                        ? 'Only admin users can archive/restore teams'
                        : 'Make room to restore archived teams by archiving active teams'
                    }
                  >
                    <Button className="btn-icon" disabled>
                      <Icon icon="box-open" />
                    </Button>
                  </div>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

ArchivedTeamList.propTypes = {
  teams: PropTypes.array.isRequired,
};

ArchivedTeamList.defaultProps = {
  teams: [],
};

export default ArchivedTeamList;
