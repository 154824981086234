export const technicalQuestionSets = [
  {
    name: 'Observability',
    key: 'observability',
    category: 'technical',
    questions: [
      {
        text: 'We use data from application performance and infrastructure monitoring tools to make business decisions on a daily basis',
        key: 'observability-data-decisions',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We incorporate monitoring metrics in business objectives and goals',
        key: 'monitoring-informed-goals',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Continuous Integration',
    key: 'continuous-integration',
    category: 'technical',
    questions: [
      {
        text: 'Each check-in triggers a set of quick automated tests',
        key: 'check-in-triggers-tests',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'If the automated build or tests fail, developers stop other work until the issues are fixed',
        key: 'fix-failing-build',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'The build process creates canonical artifacts that are used as-is for deployments',
        key: 'build-artifacts',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Deployment',
    key: 'deployment',
    category: 'technical',
    questions: [
      {
        text: 'We deploy',
        key: 'deployment-frequency',
        answers: [
          { value: '6', text: 'on demand' },
          { value: '5', text: 'between once per hour and once per day' },
          { value: '4', text: 'between once per day and once per week' },
          { value: '3', text: 'between once per week and once per month' },
          { value: '2', text: 'between once per month and once per 6 months' },
          { value: '1', text: 'less than once per 6 months' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'The lead time required to make changes to production is',
        key: 'change-lead-time',
        answers: [
          { value: '6', text: 'less than 1 hour' },
          { value: '5', text: 'between 1 hour and 1 day' },
          { value: '4', text: 'between 1 day and 1 week' },
          { value: '3', text: 'between 1 week and 1 month' },
          { value: '2', text: 'between 1 month and 6 months' },
          { value: '1', text: 'more than 6 months' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Our average (mean) time to recover from production incidents is',
        key: 'mean-time-to-recovery',
        answers: [
          { value: '5', text: 'less than 15 minutes' },
          { value: '4', text: 'between 15 minutes and 1 hour' },
          { value: '3', text: 'between 1 hour and 1 day' },
          { value: '2', text: 'between 1 day and 1 week' },
          { value: '1', text: 'more than 1 week' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'When we make changes, we encounter errors',
        key: 'change-error-rate',
        answers: [
          { value: '5', text: '0-15% of the time' },
          { value: '4', text: '16-30% of the time' },
          { value: '3', text: '31-45% of the time' },
          { value: '2', text: '46-60% of the time' },
          { value: '1', text: 'more than 60% of the time' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Empowered teams',
    key: 'empowered-teams',
    category: 'technical',
    questions: [
      {
        text: 'We are allowed to experiment with new approaches to solve our problems',
        key: 'team-technical-experimentation',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We are allowed to choose our own tools and frameworks based on what is best for completing our work and supporting our users',
        key: 'team-tooling',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Loosely-Coupled Architecture',
    key: 'loosely-coupled architecture',
    category: 'technical',
    questions: [
      {
        text: 'We deploy our application independently of other applications/services it depends on',
        key: 'deployment-independence',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We can make large-scale changes to the design of our system without the permission of somebody outside of the team',
        key: 'team-architecture-independence',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We can make large-scale changes to the design of our system without depending on other teams to make changes in their systems or creating significant work for other teams',
        key: 'team-architecture-decoupling',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We can do most of our testing on demand without requiring all integrated systems to be functional',
        key: 'isolated-testing',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Security',
    key: 'security',
    category: 'technical',
    questions: [
      {
        text: 'The security/infosec team is involved in the design and demo process for applications',
        key: 'involve-security-early',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Automated security tests or scans are run after code is committed',
        key: 'automated-security-scans',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Security practices do not slow down our development process',
        key: 'security-not-hinderance',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Test Automation',
    key: 'test automation',
    category: 'technical',
    questions: [
      {
        text: 'Developers write unit, integration and functional tests as part of delivery',
        key: 'developers-write-tests',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'When our automated tests pass, we are confident that the software is releasable',
        key: 'tests-give-confidence',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We can acquire or generate test data on demand',
        key: 'test-data-generation',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We can condition test data in our CI/CD pipeline so test runs can operate independently',
        key: 'independent-test-data',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Version Control',
    key: 'version-control',
    category: 'technical',
    questions: [
      {
        text: 'Our system and infrastructure configurations are in a version control system',
        key: 'version-control-system-configs',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Our application code is in a version control system',
        key: 'version-control-source',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Our scripts for automating builds are in a version control system',
        key: 'version-control-builds',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Code under active development is usually committed',
        key: 'code-commit-frequency',
        answers: [
          { value: '5', text: 'Multiple times per day' },
          { value: '4', text: 'Once per day' },
          { value: '3', text: 'Once per day to once per week' },
          { value: '2', text: 'Once per week to once per month' },
          { value: '1', text: 'Less than once per month' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Provisioning',
    key: 'provisioning',
    category: 'technical',
    questions: [
      {
        text: 'Acquiring new infrastructure for a new system takes',
        key: 'infrastructure-acquisition-time',
        answers: [
          { value: '6', text: 'less than 1 hour' },
          { value: '5', text: 'between 1 hour and 1 day' },
          { value: '4', text: 'between 1 day and 1 week' },
          { value: '3', text: 'between 1 week and 1 month' },
          { value: '2', text: 'between 1 month and 6 months' },
          { value: '1', text: 'more than 6 months' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Creating a new environment for an existing system (e.g. for isolated testing) takes',
        key: 'environment-provisioning-time',
        answers: [
          { value: '6', text: 'less than 1 hour' },
          { value: '5', text: 'between 1 hour and 1 day' },
          { value: '4', text: 'between 1 day and 1 week' },
          { value: '3', text: 'between 1 week and 1 month' },
          { value: '2', text: 'between 1 month and 6 months' },
          { value: '1', text: 'more than 6 months' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
];

export default technicalQuestionSets;
