export const processQuestionSets = [
  {
    name: 'Customer feedback',
    key: 'customer-feedback',
    category: 'process',
    questions: [
      {
        text: 'Our ogranization actively and regularly seeks out customer feedback and incorporates it into the design of our products',
        key: 'seeking-customer-feedback',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
        ],
      },
      {
        text: 'Customer feedback is shared directly with development teams',
        key: 'sharing-customer-feedback',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
        ],
      },
    ],
  },
  {
    name: 'Make flow of work visible',
    key: 'flow-of-work',
    category: 'process',
    questions: [
      {
        text: 'Our team understands the flow of work in our organization, from how work is identified and approved through to how value is delivered to our customers',
        key: 'workflow-understanding',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
        ],
      },
      {
        text: 'We have visibility into the work performed by other teams and services that we depend on',
        key: 'workflow-dependency-visibility',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
        ],
      },
      {
        text: 'The status of our work is visible to all stakeholders',
        key: 'workflow-status-visibiltiy',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
        ],
      },
    ],
  },
  {
    name: 'Work in small batches',
    key: 'small-batches',
    category: 'process',
    questions: [
      {
        text: 'We break work down into small increments that can be completed in less than a week',
        key: 'ad',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
        ],
      },
      {
        text: 'We release minimal versions of features or products and iterate on them as we learn what customers value most',
        key: 'mvp',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
        ],
      },
    ],
  },
  {
    name: 'Team experimentation',
    key: 'team-experimentation',
    category: 'process',
    questions: [
      {
        text: 'Our team has the authority to create and change specifications as part of the development process without requiring approval',
        key: 'permission-to-modify',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
        ],
      },
      {
        text: 'Our team is able to try out new ideas and evaluate their success without requiring approval',
        key: 'permission-to-try',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
        ],
      },
    ],
  },
];

export default processQuestionSets;
