import React from 'react';
import { scaleColorTraffic } from 'js/utils/color';

const ScoreFill = ({ score, radius }) => {
  const scoreIsValid = typeof score === 'number' && !isNaN(score);
  const scoreColor = scoreIsValid ? scaleColorTraffic(score) : '#555';

  return (
    <>
      <circle cx="0" cy="0" r={radius} fill={scoreColor} data-score={score} />
      {!scoreIsValid && <circle cx="0" cy="0" r={radius - 2} fill="#2c2c2c" />}
    </>
  );
};

export default ScoreFill;
