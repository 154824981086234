import React, { useCallback } from 'react';
import classnames from 'classnames';
import { noop, flatMap, map, mean } from 'lodash';
import RadialPieChart from 'js/components/Charts/RadialPieChart';
import DetailData from './DetailData';

import styles from '../DetailsPane.module.scss';

export const OverviewDetailPane = ({
  summaryData,
  filter,
  categoryColorMap,
  paletteMap,
  onSliceOver = noop,
  onSliceOut = noop,
  generateKey = (questionSetKey, questionKey) =>
    `${questionSetKey}-${questionKey}`,
}) => {
  // TODO: If there are multiple question sets, we need to show a view at the question set level.
  //       For now, expect that there is only one question set.
  const dataPoints = flatMap(summaryData.questionSets, (qs, qsKey) =>
    map(qs.questions, (q, qKey) => {
      return {
        ...q,
        category: qs.category,
        questionSetKey: qsKey,
        questionKey: qKey,
        paletteKey: generateKey(qsKey, qKey),
      };
    })
  );

  const paletteClassName = useCallback(
    ({ questionKey, questionSetKey, paletteKey }) => {
      return classnames(
        paletteMap[paletteKey]?.className,
        filter?.criteria?.questionKey === questionKey &&
          filter?.criteria?.questionSetKey === questionSetKey
          ? undefined
          : 'palette-muted'
      );
    },
    [paletteMap, filter]
  );

  const handleSelectSlice = useCallback(
    ({ questionSetKey, questionKey }) => {
      onSliceOver(questionSetKey, questionKey);
    },
    [onSliceOver]
  );

  const handleClearSlices = useCallback(() => {
    onSliceOut();
  }, [onSliceOut]);

  const getDetailData = () => {
    if (filter?.subtype === 'question') {
      const question =
        summaryData.questionSets[filter.criteria.questionSetKey].questions[
          filter.criteria.questionKey
        ];
      const normalizationFactor =
        question.answerDomain[1] - question.answerDomain[0];
      const responses = Object.keys(question.responseTotals)
        .map((key) => parseInt(key, 10))
        .sort()
        .flatMap((key) => new Array(question.responseTotals[key]).fill(key));
      const normalizedMedianScore =
        responses.length > 0
          ? (responses[Math.floor(responses.length / 2)] +
              responses[Math.ceil((responses.length - 1) / 2)]) /
            (2 * normalizationFactor)
          : null;

      return {
        titleLabel: 'Question',
        title: question.text,
        meanScore: question.normalizedScore,
        medianScore: normalizedMedianScore,
        stdDev: Math.sqrt(question.normalizedVariance),
      };
    }

    // else
    const scores = dataPoints
      .map((d) => d.normalizedScore)
      .filter((x) => x !== null);
    const meanScore = scores.length > 0 ? mean(scores) : null;
    const medianScore =
      scores.length > 0
        ? (scores[Math.floor(scores.length / 2)] +
            scores[Math.ceil((scores.length - 1) / 2)]) /
          2
        : null;
    const variance =
      scores.length > 1
        ? mean(scores.map((s) => Math.pow(s - meanScore, 2)))
        : null;

    return {
      title: 'Overview summary',
      meanScore,
      medianScore,
      stdDev: Math.sqrt(variance),
    };
  };

  const detailProps = getDetailData();

  return (
    <div>
      <label>Statistics</label>
      <div className={styles['overview-data']}>
        <div className={styles['overview-chart']}>
          <div className="assessment-history-chart">
            <div className="assessment-history-circle-chart">
              <div>
                <RadialPieChart
                  dataPoints={dataPoints}
                  colorMap={categoryColorMap ?? {}}
                  onSelectSlice={handleSelectSlice}
                  onClearSlices={handleClearSlices}
                  paletteClassName={paletteClassName}
                />
              </div>
            </div>
          </div>
        </div>
        <DetailData {...detailProps} />
      </div>
    </div>
  );
};

export default OverviewDetailPane;
