import React from 'react';

import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Icon, ChartSelector } from 'js/components';

import AssessmentTrends, {
  Glyph as AssessmentTrendsGlyph,
} from './AssessmentTrends';
import {
  AssessmentDifferential,
  Glyph as AssessmentDifferentialGlyph,
} from 'js/components/Charts/AssessmentDifferential';

function AssessmentReviewHeader({
  assessments,
  name,
  team,
  teamId,
  assessmentKey,
  ...props
}) {
  if (
    !assessments ||
    (assessments.length > 0 && !assessments[0].question_sets)
  ) {
    return (
      <Redirect
        to={team ? `/w/${team.workspace_id}/teams/${team.id}/dashboard` : `/`}
      />
    );
  }

  return (
    <div className="assessment-review-header">
      <section className="details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-9">
              <label>Assessment</label>
              <h1>
                {name}
                &nbsp;
              </h1>
              <label>Team</label>
              <h2>
                {team.name}
                &nbsp;
              </h2>
            </div>
            <div className="col-12 col-lg-3">
              <div className="actions">
                <Link
                  className="btn btn-primary"
                  to={`/w/${team.workspace_id}/teams/${teamId}/assessments/${assessmentKey}/new`}
                >
                  <Icon
                    icon={['far', 'poll']}
                    size="lg"
                    className="btn-icon-left"
                  />
                  Assess
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="details-chart container-fluid">
        <ChartSelector
          charts={[
            {
              label: AssessmentTrendsGlyph,
              component: AssessmentTrends,
              buttonProps: { 'data-chart-type': 'assessment-trends' },
            },
            {
              label: AssessmentDifferentialGlyph,
              component: AssessmentDifferential,
              buttonProps: { 'data-chart-type': 'differential' },
              componentProps: {
                items: [
                  {
                    id: 1,
                    name: 'poop',
                    currentValue: 0.7,
                    previousValue: 0.3,
                    range: [0, 1],
                  },
                ],
              },
            },
          ]}
          assessments={assessments}
        />
      </section>
    </div>
  );
}

export default AssessmentReviewHeader;
