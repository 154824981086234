import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { updateWorkspace } from 'js/actions/workspace-actions';

import ChangeWorkspaceName from 'js/routes/WorkspaceSettings/components/ChangeWorkspaceName';
import { withRouter } from 'react-router-dom';
import { notify } from 'js/actions/alert-actions';
import { selectActiveWorkspace } from 'js/store/reducers/workspaces';

function WorkspaceSettingsContainer({
  updateWorkspace,
  notify,
  activeWorkspace,
  match,
}) {
  const onSubmit = useCallback(
    async (workspaceName) => {
      try {
        await updateWorkspace(workspaceName, activeWorkspace.id);
        notify({
          type: 'success',
          message: 'Workspace name updated successfully',
        });
      } catch (e) {}
    },
    [activeWorkspace, updateWorkspace, notify]
  );

  return (
    <ChangeWorkspaceName
      workspace={activeWorkspace}
      onSubmit={onSubmit}
      match={match}
    />
  );
}

export default withRouter(
  connect(
    (store) => ({
      activeWorkspace: selectActiveWorkspace(store),
    }),
    {
      updateWorkspace,
      notify,
    }
  )(WorkspaceSettingsContainer)
);
