import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import styles from './MemberForm.module.scss';

import { Button, Input, Label, FormGroup, FormFeedback } from 'reactstrap';
import Role from '../Role';
import { Icon } from 'js/components';
import Allocation from '../Allocation';
import { noop } from 'lodash';

const AddMemberForm = ({
  values,
  errors,
  text,
  handleChange,
  setFieldValue,
  handleBlur,
  handleReset,
  touched,
  isSubmitting,
  handleSubmit,
  onCancel = noop,
}) => {
  const formikAttributes = (fieldName) => ({
    id: fieldName,
    name: fieldName,
    value: values[fieldName],
    onChange: handleChange,
    onBlur: handleBlur,
    invalid: errors[fieldName] && touched[fieldName],
  });

  const formikFeedback = (fieldName) => {
    return errors[fieldName] && touched[fieldName] ? (
      <FormFeedback>{errors[fieldName]}</FormFeedback>
    ) : null;
  };

  const handleCancel = () => {
    handleReset();
    onCancel();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={`form ${styles['team-member-card']}`}
    >
      <div className={styles['form-header']}>
        <h5>
          <small className="text-muted">
            {text ?? 'Who else is on your team?'}
          </small>
        </h5>
      </div>
      <div className={styles['team-member-avatar']}>
        <div className={styles['team-member-avatar-image']}>
          <Icon icon="user" size="lg" />
        </div>
      </div>
      <div className={styles['team-member-name']}>
        <Label for="name">Name</Label>
        <FormGroup>
          <Input type="text" {...formikAttributes('name')} />
          {formikFeedback('name')}
        </FormGroup>
      </div>
      <div className={styles['team-member-email']}>
        <Label for="email">Email</Label>
        <FormGroup>
          <Input type="text" {...formikAttributes('email')} />
          {formikFeedback('email')}
        </FormGroup>
      </div>
      <div className={styles['team-member-role']}>
        <Label for="role">Role</Label>
        <Role
          placeholder="(None)"
          onChange={(value) => setFieldValue('role', value)}
          value={values['role']}
        />
      </div>
      <div className={styles['team-member-allocation']}>
        <Label for="email">Allocation</Label>
        <FormGroup>
          <Allocation {...formikAttributes('allocation')} />
          {formikFeedback('allocation')}
        </FormGroup>
      </div>
      <div className={styles['team-member-actions']}>
        <Button color="primary" disabled={isSubmitting} type="submit">
          <Icon icon="plus" /> Add member
        </Button>
        <Button
          color="secondary"
          disabled={isSubmitting}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

const FormikAddMemberForm = withFormik({
  mapPropsToValues: ({
    teamMember: {
      name = null,
      role = null,
      email = null,
      allocation = null,
    } = {},
  }) => {
    return {
      name: name ?? '',
      role: role ?? '',
      email: email ?? '',
      allocation,
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Hmm, that doesn't look like a valid email address yet")
      .max(64),
    name: Yup.string()
      .trim()
      .required(
        "I've been through the desert on a horse with no name, but all your teammates need one"
      )
      .max(
        128,
        "That's a long name you got there. Unfortunately we cut you off at 128 characters."
      ),
  }),
  handleSubmit: async (values, bag) => {
    const {
      setSubmitting,
      props: { onAddMember },
    } = bag;
    // do nothing
    setSubmitting(true);
    try {
      await onAddMember(values);
    } finally {
      setSubmitting(false);
    }
  },
  displayName: 'AddMember',
})(AddMemberForm);

export default FormikAddMemberForm;
