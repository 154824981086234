import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import optimist from 'redux-optimist';

import channelMiddleware from 'js/middleware/channels';
import fileMiddleware from 'js/middleware/files';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from './reducers';

export function configureStore(history, initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // Build middleware. These are functions that can process the actions before they reach the store.
  const createStoreWithMiddleware = composeEnhancers(
    applyMiddleware(
      thunk,
      routerMiddleware(history),
      channelMiddleware,
      fileMiddleware
    )
  )(createStore);

  // Combine all reducers and instantiate the app-wide store instance
  const rootReducer = buildRootReducer(history, reducers);

  const store = createStoreWithMiddleware(rootReducer, initialState);
  const persistor = persistStore(store);

  // // Enable Webpack hot module replacement for reducers
  // if (module.hot) {
  //     module.hot.accept('./store', () => {
  //         const nextRootReducer = require('./store');
  //         store.replaceReducer(buildRootReducer(nextRootReducer.reducers));
  //     });
  // }

  return { store, persistor };
}

function buildRootReducer(history, appReducers) {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['identity', 'currentWorkspaceId', 'participation']
  };

  const combinedReducers = optimist(
    combineReducers({
      ...appReducers,
      router: connectRouter(history)
    })
  );

  return persistReducer(persistConfig, combinedReducers);
}
