import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconStack, Icon } from 'js/components';

const HostNav = ({
  assessment,
  onNextQuestion,
  onPreviousQuestion,
  allowNextQuestion = true,
  allowPreviousQuestion = true,
  children,
}) => {
  const handleNextQuestion = useCallback(
    (e) => {
      e.preventDefault();
      if (allowNextQuestion) {
        onNextQuestion();
      }
    },
    [onNextQuestion, allowNextQuestion]
  );

  const handlePreviousQuestion = useCallback(
    (e) => {
      e.preventDefault();
      if (allowPreviousQuestion) {
        onPreviousQuestion();
      }
    },
    [onPreviousQuestion, allowPreviousQuestion]
  );

  return (
    <nav className="navbar fixed-bottom navbar-expand-lg bottom-bar">
      <ul className="navbar-nav navbar-expand mr-auto">{children}</ul>
      <ul className="nav-items">
        <li className="nav-item">
          <Link
            to={`/assessments/${assessment.id}/host`}
            onClick={handlePreviousQuestion}
            className={allowPreviousQuestion ? '' : 'disabled'}
          >
            <IconStack size="2x">
              <Icon icon={['far', 'circle']} />
              <Icon icon="arrow-left" transform="shrink-7" />
            </IconStack>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={`/assessments/${assessment.id}/host`}
            onClick={handleNextQuestion}
            className={allowNextQuestion ? '' : 'disabled'}
          >
            <IconStack size="2x">
              <Icon icon={['far', 'circle']} />
              <Icon icon="arrow-right" transform="shrink-7" />
            </IconStack>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

HostNav.propTypes = {
  assessment: PropTypes.object.isRequired,
  // questionId: PropTypes.string,
  // qrTo: PropTypes.oneOf(['kickoff', 'question']),
  onPreviousQuestion: PropTypes.func,
  onNextQuestion: PropTypes.func,
};

HostNav.defaultProps = {
  qrTo: 'kickoff',
  onPreviousQuestion: () => {},
  onNextQuestion: () => {},
};

export default HostNav;
