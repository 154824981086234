import alerts from './alerts';
import entities from './entities';
import identity from './identity';
import forms from './forms';
import participation from './participation';
import presences from './presences';
import queries from './queries';
import { currentWorkspaceId } from './workspaces';
import { activeAccount } from './customer-account';

const reducers = {
  alerts,
  entities,
  identity,
  forms,
  participation,
  presences,
  currentWorkspaceId,
  activeAccount,
  queries,
};

export default reducers;
