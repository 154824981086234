import React, { useMemo } from 'react';
import { map, uniqueId } from 'lodash';
import { histogram, max } from 'd3-array';

import { withParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { AreaClosed } from '@visx/shape';
import { curveMonotoneX as curve } from '@visx/curve';

import { scaleColorTraffic as scaleColor } from 'js/utils/color';
import { isScoredType } from 'js/utils/question-set';

// TODO: update d3
const bin = histogram;

const dataHelpers = {
  questionSet: {
    getScores: (node, questionSetKey) => {
      const questions = node.data.scores[questionSetKey]?.questions ?? {};
      return map(questions, ({ score, question_type }) =>
        isScoredType(question_type) ? { node, score } : null
      ).filter((x) => !!x);
      // let scores = map(c.data.scores[questionSetKey] ?? {});
    },
  },
  question: {
    getScores: (node, questionSetKey, questionKey) => {
      const question = node.data.scores[questionSetKey]?.questions[questionKey];

      return !!question ? { node, score: question.score } : null;
    },
  },
};

const RecommendationResultsDistribution = ({
  parentWidth,
  height = 20,
  node,
  questionKey,
  questionSetKey,
  binCount = 11,
  margin = {
    top: 0.5,
    left: 0.5,
    bottom: 0.5,
    right: 0.5,
  },
}) => {
  const width = parentWidth;
  const scaleHeight = height - margin.top - margin.bottom;
  const scaleWidth = width - margin.left - margin.right;
  const { maskId, gradientId } = useMemo(() => {
    const chartId = uniqueId('recommendation-results-distribution-');
    return {
      chartId,
      maskId: `${chartId}-mask`,
      gradientId: `${chartId}-gradient`,
    };
  }, []);

  const helpers = !questionKey ? dataHelpers.questionSet : dataHelpers.question;

  const x = useMemo(
    () =>
      scaleLinear({
        domain: [0, 1],
        range: [0, scaleWidth],
      }),
    [scaleWidth]
  );

  const childScores = node?.children
    ?.map((c) => helpers.getScores(c, questionSetKey, questionKey))
    .flat()
    .filter(
      (x) => !!x && x.score !== undefined && isScoredType(x.question_type)
    );

  const rawBinScores = childScores.reduce(
    (acc, score) => {
      switch (true) {
        case score.score === 0:
          return {
            ...acc,
            lowerBoundCount: acc.lowerBoundCount + 1,
          };
        case score.score === 1:
          return {
            ...acc,
            upperBoundCount: acc.upperBoundCount + 1,
          };
        default:
          return {
            ...acc,
            remaining: [...acc.remaining, score],
          };
      }
    },
    {
      lowerBoundCount: 0,
      upperBoundCount: 0,
      remaining: [],
    }
  );

  const binner = useMemo(() => {
    const binThresholds = [...Array(binCount)].map(
      (_b, i) => (i + 1) / binCount
    );

    return bin()
      .value((d) => d.score)
      .domain([0, 1])
      .thresholds(binThresholds);
  }, [binCount]);
  const bins = binner(rawBinScores.remaining);
  const rawDataPoints = [
    { x: x(0), yValue: rawBinScores.lowerBoundCount },
    ...bins.slice(0, bins.length - 1).map((d) => ({
      x: x((d.x0 + d.x1) / 2),
      yValue: d.length,
    })),
    { x: x(1), yValue: rawBinScores.upperBoundCount },
  ];
  const maxBinSize = max(rawDataPoints, (d) => d.yValue);

  const y = useMemo(() => {
    return scaleLinear({ domain: [0, maxBinSize], range: [scaleHeight, 0] });
  }, [maxBinSize, scaleHeight]);
  const areaPoints = rawDataPoints.map(({ x, yValue }) => [x, y(yValue)]);

  const gradientPoints = scaleColor.range();
  const stopIncrement = 100 / Math.max(1, gradientPoints.length - 1);

  return (
    <svg width="100%" height="20" viewBox={`0 0 ${width} ${height}`}>
      <g transform={`translate(${margin.left},${margin.top})`}>
        <defs>
          <linearGradient id={gradientId}>
            {gradientPoints.map((stopColor, i) => (
              <stop
                key={`${i}-${stopColor}`}
                stopColor={stopColor}
                offset={`${i * stopIncrement}%`}
              />
            ))}
          </linearGradient>
        </defs>
        <mask id={maskId}>
          <rect
            x="0"
            y="0"
            width={scaleWidth}
            height={scaleHeight}
            fill="black"
          />
          <AreaClosed
            className=""
            data={areaPoints}
            curve={curve}
            yScale={y}
            fill="#fff"
          />
        </mask>
        {childScores.length > 0 ? (
          <rect
            x="0"
            y="0"
            height={scaleHeight}
            width={scaleWidth}
            fill={`url(#${gradientId})`}
            mask={`url(#${maskId})`}
          />
        ) : (
          <text
            className="rec-results-dist-unscored"
            textAnchor="middle"
            dominantBaseline="middle"
            x={scaleWidth / 2}
            y={scaleHeight / 2}
          >
            No scored results
          </text>
        )}
        <g>
          <path
            d={`M0,0 l0,${scaleHeight} l${scaleWidth},0 l0,${-scaleHeight}`}
            className="rec-results-dist-bounds"
            fill="none"
          />
          {/* <line
            x1="0"
            y1={scaleHeight}
            x2={scaleWidth}
            y2={scaleHeight}
            stroke="black"
          />
          <line x1="0" y1="0" x2="0" y2={scaleHeight} stroke="black" />
          <line
            x1={scaleWidth}
            y1={0}
            x2={scaleWidth}
            y2={scaleHeight}
            stroke="black"
          /> */}
        </g>
        {/* <rect x="0" y="0" width={scaleWidth} height={scaleHeight} fill="black" />
      <AreaClosed
        className=""
        data={areaPoints}
        curve={curve}
        yScale={y}
        fill="#fff"
      /> */}
      </g>
    </svg>
  );
};

export default withParentSize(RecommendationResultsDistribution);
