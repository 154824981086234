import React, { useState, useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import _ from 'lodash';

import { SCHEMAS } from 'js/api';
import {
  verifyAccount,
  resendVerification,
  me as loadMe,
} from 'js/actions/account-actions';
import { notify } from 'js/actions/alert-actions';

import { HistoryContext } from 'js/contexts/HistoryContext';

import EmailVerificationRouteComponent from '../components/EmailVerificationRouteComponent';

const parseErrors = (errors) => {
  return _.reduce(
    errors,
    (result, value) => {
      const [errorCode] = value;

      return result === 'unknown' && errorCode ? errorCode : result;
    },
    'unknown'
  );
};

const EmailVerificationRouteContainer = ({
  me,
  verifyAccount,
  resendVerification,
  loadMe,
  notify,
  match: {
    params: { verificationKey },
  },
  ...props
}) => {
  const [verificationStatus, setVerificationStatus] = useState({
    verified: false,
    inProgress: true,
    error: null,
  });

  useEffect(() => {
    verifyAccount(verificationKey)
      .then(() => {
        setVerificationStatus({
          verified: true,
          inProgress: false,
          error: null,
        });

        return loadMe();
      })
      .catch((error) => {
        const {
          response: { body, statusCode },
        } = error;

        if (body && body.message) {
          setVerificationStatus({
            verified: false,
            inProgress: false,
            error: body.message,
          });
        } else if (body && body.errors) {
          setVerificationStatus({
            verified: false,
            inProgress: false,
            error: parseErrors(body.errors),
          });
        } else if (statusCode === 404) {
          setVerificationStatus({
            verified: false,
            inProgress: false,
            error: 'not found',
          });
        } else {
          setVerificationStatus({
            verified: false,
            inProgress: false,
            error: 'unknown',
          });
        }
      });
  }, [verificationKey, loadMe, verifyAccount]);

  const history = useContext(HistoryContext);
  const [resendState, setResendState] = useState({ error: null, sent: false });

  const handleResend = useCallback(
    async ({ email }) => {
      try {
        const result = await resendVerification(email);
        setResendState((currentResendState) => ({
          ...currentResendState,
          error: null,
          sent: true,
        }));
        notify({
          type: 'success',
          message: "A new verification mail is on it's way!",
        });
        history.push('/verify');

        return result;
      } catch (error) {
        setResendState((currentResendState) => ({
          ...currentResendState,
          error,
          sent: true,
        }));

        throw error;
      }
    },
    [resendVerification, setResendState, notify, history]
  );

  return (
    <EmailVerificationRouteComponent
      me={me}
      {...verificationStatus}
      onResend={handleResend}
      resendState={resendState}
    />
  );
};

export default connect(
  (state) => {
    const { entities } = state;

    return {
      me: denormalize(state.identity.me, SCHEMAS.ME, entities),
    };
  },
  {
    verifyAccount,
    resendVerification,
    notify,
    loadMe,
  }
)(EmailVerificationRouteContainer);
