import React from 'react';
import { Link } from 'react-router-dom';
import StrokedText from '../../Participation/Nodes/StrokedText';
import ScoreFill from './ScoreFill';

const TeamNode = ({
  radius,
  workspaceId,
  assessmentKey,
  node: {
    x: y,
    y: x,
    data: { id, name, score },
  },
}) => {
  const displayName = name;
  const scoreIsValid = typeof score === 'number' && !isNaN(score);
  const { wrapperProps, wrapperComponent: Wrapper } = scoreIsValid
    ? {
        wrapperProps: {
          to: `/w/${workspaceId}/teams/${id}/assessments/${assessmentKey}`,
        },
        wrapperComponent: Link,
      }
    : { wrapperProps: {}, wrapperComponent: React.Fragment };

  return (
    <g transform={`translate(${x},${y})`}>
      <ScoreFill radius={radius} score={score} />
      <Wrapper {...wrapperProps}>
        <StrokedText
          strokeWidth={3}
          strokeColor="#2c2c2c"
          textAnchor="start"
          fill="#fff"
          x={radius + 4}
          y={1}
          dominantBaseline="middle"
          className="team-name"
          fontSize={10}
        >
          {displayName}
        </StrokedText>
      </Wrapper>
    </g>
  );
};

export default TeamNode;
