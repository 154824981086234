import React from 'react';
import PropTypes from 'prop-types';
import { countBy, filter, map, reduce } from 'lodash';
import classnames from 'classnames';
import BarChart from 'js/components/Charts/BarChart';
import { Icon, IconStack } from 'js/components';

import {
  faThumbsUp,
  faThumbsDown,
  faHandPaper,
  faCircle,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';

function countResponses(responses) {
  const defaults = { green: 0, yellow: 0, red: 0 };

  const actuals = countBy(responses, (response) => {
    let responseValue = response.value;

    switch (responseValue) {
      case '0':
        responseValue = 'red';
        break;

      case '1':
        responseValue = 'yellow';
        break;

      case '2':
        responseValue = 'green';
        break;

      default:
        responseValue = 'blue';
    }

    return responseValue;
  });

  return { ...defaults, ...actuals };
}

function getResponseData(counts) {
  return map(counts, (count, name) => {
    return {
      name,
      count,
      className: 'vote-' + name,
    };
  });
}

function QuestionOverview({
  participant,
  question,
  onClick,
  responses,
  yLimit,
  forceShowResponses = true,
}) {
  const filteredResponses = participant
    ? filter(responses, (r) => r.participant_id === participant.id && !r.is_na)
    : filter(responses, (r) => !r.is_na);

  const colorResponseCounts = countResponses(filteredResponses);
  const responseCount = reduce(colorResponseCounts, (sum, n) => sum + n, 0);
  const averageResponse =
    responseCount > 0
      ? reduce(
          colorResponseCounts,
          (sum, count, key) => {
            let val = -100;

            switch (key) {
              case 'red':
                val = -1 * count;
                break;

              case 'yellow':
                val = 0;
                break;

              case 'green':
                val = count;
                break;

              default:
                val = count;
                break;
            }

            return sum + val;
          },
          0
        ) / responseCount
      : 0;

  const voteClasses =
    forceShowResponses ||
    (question.reveal_results_on !== null &&
      question.reveal_results_on <= Date.now())
      ? {
          'vote-red': Math.round(averageResponse) === -1,
          'vote-yellow': Math.round(averageResponse) === 0 && responseCount > 0,
          'vote-green': Math.round(averageResponse) === 1,
        }
      : {};

  const renderResultIcon = () => {
    if (voteClasses['vote-green']) {
      return <Icon icon={faThumbsUp} transform="shrink-8" />;
    } else if (voteClasses['vote-red']) {
      return <Icon icon={faThumbsDown} transform="shrink-8" />;
    } else if (voteClasses['vote-yellow']) {
      return <Icon icon={faHandPaper} transform="shrink-8" />;
    }

    return <Icon icon="question" transform="shrink-7" />;
  };

  const renderResponses = () => {
    switch (true) {
      case !!participant:
        return null;

      case responseCount === 0:
        return (
          <span className="no-results-chart">
            No
            <br />
            results
          </span>
        );

      case !forceShowResponses &&
        (question.reveal_results_on === null ||
          question.reveal_results_on >= Date.now()):
        return (
          <span className="total-response-count">
            <span className="total-response-count-value">
              {responseCount} <Icon icon={faCheck} />
            </span>
          </span>
        );

      default:
        return (
          <div className="response-data">
            <span className="response-count">
              {responseCount} <Icon icon="check" />
            </span>
            <BarChart
              data={getResponseData(colorResponseCounts)}
              title=""
              duration="500"
              yLimit={yLimit}
            />
          </div>
        );
    }
  };

  return (
    <div
      className={classnames('question-overview', voteClasses)}
      onClick={onClick}
    >
      <div className="content">
        <h4 className="question-title">{question.text}</h4>
        {renderResponses()}
        <div className="response-result">
          <IconStack className={classnames('result-icon', voteClasses)}>
            <Icon icon={faCircle} />
            {renderResultIcon()}
          </IconStack>
        </div>
      </div>
    </div>
  );
}

QuestionOverview.propTypes = {
  onClick: PropTypes.func,
};

QuestionOverview.defaultProps = {
  question: {},
  responses: [],
  onClick: () => {},
};

export default QuestionOverview;
