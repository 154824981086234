import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import { update as updateUser } from 'js/actions/user-actions';
import {
  listVerifications,
  resendVerification,
  cancelEmailChange,
  me as loadMe,
} from 'js/actions/account-actions';
import { notify } from 'js/actions/alert-actions';

import AccountInfoRouteComponent from '../components/AccountInfoRouteComponent';
import { noop } from 'lodash/util';

const errorMessages = {
  username: {
    'has already been taken': {
      field: 'username',
      message: 'Sorry, this email is already being used. Do you have another?',
    },
  },
  current_password: {
    'is invalid': {
      field: 'current_password',
      message: 'The password is not correct',
    },
  },
};

const AccountInfoRouteContainer = ({
  updateUser,
  notify,
  listVerifications,
  resendVerification,
  cancelEmailChange,
  me,
  loadMe,
  pendingVerifications,
}) => {
  const [updateUserApiResults, setUpdateUserApiResults] = useState({});

  const handleUpdateUser = useCallback(
    async (patch) => {
      try {
        const result = await updateUser(patch);
        notify({
          type: 'success',
          message: 'Your profile has been updated',
        });
        setUpdateUserApiResults({
          errors: {},
          values: patch,
        });

        // load updated info into store
        loadMe().then(noop).catch(noop);
        return result;
      } catch (e) {
        if (e.response && e.response.body && e.response.body.errors) {
          const errors = {};
          _.forEach(e.response.body.errors, (errs, key) => {
            errs.forEach((err) => {
              if (errorMessages[key] && errorMessages[key][err]) {
                const { field, message } = errorMessages[key][err];
                errors[field] = message;
              }
            });
          });

          setUpdateUserApiResults({ errors, values: patch });
        }

        notify({
          type: 'danger',
          message:
            "Uh oh...Looks like we couldn't update your profile. Check out the messages and try again.",
        });

        throw e;
      }
    },
    [updateUser, notify, setUpdateUserApiResults, loadMe]
  );

  useEffect(() => {
    listVerifications();
  }, [me, listVerifications]);

  const pendingEmail = useMemo(() => {
    return (
      _.sortBy(pendingVerifications, (x) => -x.expires_at).map(
        (x) => x.email
      )[0] || null
    );
  }, [pendingVerifications]);

  const handleResendEmailVerification = useCallback(
    () => resendVerification(pendingEmail),
    [pendingEmail, resendVerification]
  );

  const handleCancelEmailChange = useCallback(
    () => cancelEmailChange(pendingEmail),
    [pendingEmail, cancelEmailChange]
  );

  return (
    <AccountInfoRouteComponent
      me={me}
      pendingEmail={pendingEmail}
      onUpdateUser={handleUpdateUser}
      updateUserSubmissionValues={updateUserApiResults.values}
      updateUserSubmissionErrors={updateUserApiResults.errors}
      onResendEmailVerification={handleResendEmailVerification}
      onCancelEmailChange={handleCancelEmailChange}
    />
  );
};

export default withRouter(
  connect(
    ({ identity: { pendingVerifications, me } }) => {
      return {
        me,
        pendingVerifications,
      };
    },
    {
      updateUser,
      notify,
      listVerifications,
      resendVerification,
      cancelEmailChange,
      loadMe,
    }
  )(AccountInfoRouteContainer)
);
