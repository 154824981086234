import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { sendPasswordReset } from 'js/actions/account-actions';

import ForgotPasswordRouteComponent from '../components/ForgotPasswordRouteComponent';

const initialResetState = { error: null, sent: false };

const ForgotPasswordRouteContainer = ({ sendPasswordReset }) => {
  const [resetState, setResetState] = useState({ ...initialResetState });

  const handleRetry = useCallback(() => {
    setResetState({ ...initialResetState });
  }, []);

  const handleReset = useCallback(
    async ({ email }) => {
      try {
        const result = await sendPasswordReset(email);

        setResetState({
          error: null,
          sent: true,
        });

        return result;
      } catch (error) {
        setResetState({
          error,
          sent: true,
        });

        throw error;
      }
    },
    [sendPasswordReset, setResetState]
  );

  return (
    <ForgotPasswordRouteComponent
      onReset={handleReset}
      onRetry={handleRetry}
      resetState={resetState}
    />
  );
};

export default connect(null, {
  sendPasswordReset,
})(ForgotPasswordRouteContainer);
