import React, { useCallback, useState, useMemo } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from 'reactstrap';

const DropdownFilter = ({
  name = 'Dropdown',
  filterOptions = [
    { value: 'banana', text: 'Banana' },
    { value: 'apple', text: 'Apple' },
  ],
  onChange = () => {},
}) => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOptionCount, allOptionsSelected] = useMemo(() => {
    const selectedOptionCount = filterOptions.filter(
      (o) => !!selectedOptions[o.value]
    ).length;

    return [
      selectedOptionCount,
      selectedOptionCount === 0 || selectedOptionCount === filterOptions.length,
    ];
  }, [filterOptions, selectedOptions]);

  const toggleDropdown = useCallback(() => {
    const newIsOpen = !dropdownOpen;
    setDropdownOpen(newIsOpen);
  }, [dropdownOpen, setDropdownOpen]);
  const toggleOption = useCallback(
    ({ value }) => {
      let newOptions = {
        ...selectedOptions,
        [value]: !selectedOptions[value],
      };

      const selectedOptionCount = filterOptions.filter(
        (o) => !!newOptions[o.value]
      ).length;

      const noOptionsSelected = selectedOptionCount === 0;

      newOptions = noOptionsSelected ? {} : newOptions;
      setSelectedOptions(newOptions);
      onChange(newOptions);
    },
    [filterOptions, selectedOptions, setSelectedOptions, onChange]
  );
  const setAllOptions = useCallback(() => {
    const newOptions =
      allOptionsSelected && selectedOptionCount > 0
        ? {}
        : filterOptions.reduce((acc, { value }) => {
            acc[value] = true;
            return acc;
          }, {});
    setSelectedOptions(newOptions);
    onChange(selectedOptionCount === filterOptions.length ? {} : newOptions);
  }, [
    allOptionsSelected,
    selectedOptionCount,
    filterOptions,
    setSelectedOptions,
    onChange,
  ]);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle caret size="sm">
        {allOptionsSelected ? (
          name
        ) : (
          <b>
            {name} ({selectedOptionCount})
          </b>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem toggle={false} onClick={setAllOptions}>
          <Input
            type="checkbox"
            value={''}
            onChange={() => {}}
            checked={allOptionsSelected && selectedOptionCount > 0}
          />{' '}
          (Select All)
        </DropdownItem>
        {filterOptions.map((o) => (
          <DropdownItem
            divider={false}
            key={o.value}
            toggle={false}
            onClick={() => {
              toggleOption(o);
            }}
          >
            <Input
              type="checkbox"
              value={o.value ?? '-|-'}
              onChange={() => {}}
              checked={!!selectedOptions[o.value]}
            />{' '}
            {o.text}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownFilter;
