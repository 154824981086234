import React, { useMemo } from 'react';
import { map } from 'lodash';
import { LinePath } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';

const LineWithRings = ({ x, y, data, curve = curveMonotoneX, ...props }) => {
  const strippedData = useMemo(
    () => data.filter((d) => typeof d === 'object'),
    [data]
  );
  return (
    <g {...props}>
      <g>
        <LinePath
          className="distribution-curve palette-stroke"
          data={data}
          x={x}
          y={y}
          curve={curve}
        />
        <g>
          {map(strippedData, (d) => {
            const cx = x(d);
            const cy = y(d);

            return (
              <g
                key={`answer-set-${d?.answer?.id}`}
                className="vote-count-glyph"
              >
                <g>
                  <circle className="spacer" cx={cx} cy={cy} r={7} />
                  <circle className="palette-fill" cx={cx} cy={cy} r={5} />
                  <circle className="inner-circle" cx={cx} cy={cy} r={3} />
                </g>
              </g>
            );
          })}
        </g>
      </g>
    </g>
  );
};

export default LineWithRings;
