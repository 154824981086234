import React from 'react';
import PropTypes from 'prop-types';
import qrImage from 'qr-image';

const QRCode = ({ value, fill }) => {
  const svg = qrImage.svgObject(value, { type: 'svg', margin: 1 });
  const viewBox = '0 0 ' + svg.size + ' ' + svg.size;

  return (
    <svg viewBox={viewBox} className="qr-code">
      <path d={svg.path} fill={fill} />
    </svg>
  );
};

QRCode.propTypes = {
  value: PropTypes.string.isRequired,
  fill: PropTypes.string
};

QRCode.defaultProps = {
  fill: '#000000'
};

export default QRCode;
