import React, { useCallback } from 'react';
import { noop } from 'lodash';

import { Link } from 'react-router-dom';
import { Icon } from 'js/components';

const InstructionsSent = ({ onRetry }) => {
  const handleReset = useCallback(
    e => {
      e.preventDefault();

      onRetry();
    },
    [onRetry]
  );

  return (
    <>
      <div className="result-icon">
        <Icon fixedWidth icon={['far', 'shipping-fast']} transform="shrink-2" />
      </div>
      <div className="result-body">
        <h2>Help is on the way</h2>
        <div>
          <p className="mb-5">
            Check your email for instructions on how to reset your password (if
            you don't see it, check your junk mail folder - sometimes it
            accidentally ends up there).
          </p>
          <p>
            <Link to="/pages/login" className="btn full-width-sm">
              I got it! Take me to the login
            </Link>{' '}
            <Link
              to="/reset"
              className="btn btn-secondary full-width-sm"
              onClick={handleReset}
            >
              I still don't see it
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

InstructionsSent.defaultProps = {
  onRetry: noop
};

export default InstructionsSent;
