import React, { useMemo } from 'react';

const NoResponse = ({ dotDiameter, height, width }) => {
  const outerRadius = dotDiameter / 2;
  const innerRadius = outerRadius * 0.5;

  const path = useMemo(
    () =>
      [
        `M 0 -${outerRadius}`,
        `A ${outerRadius} ${outerRadius} 0 1 0 0 ${outerRadius}`,
        `A ${outerRadius} ${outerRadius} 0 1 0 0 ${-outerRadius}`,
        'Z',
        `M 0 -${innerRadius}`,
        `A ${innerRadius} ${innerRadius} 0 1 1 0 ${innerRadius}`,
        `A ${innerRadius} ${innerRadius} 0 1 1 0 ${-innerRadius}`,
      ].join(' '),
    [outerRadius, innerRadius]
  );

  return (
    <g transform={`translate(${width / 2}, 0)`} className="question-score">
      <rect
        x={-width / 2}
        y={0}
        width={width}
        height={height}
        className="no-response"
      />

      <g transform={`translate(0, ${height / 2})`}>
        <path fillRule="evenodd" d={path} className="no-response-marker" />
      </g>
    </g>
  );
};

export default NoResponse;
