import { types as CustomerAccountTypes } from 'js/actions/channel-actions/customer-account-actions';
import { types as AccountTypes } from 'js/actions/account-actions';

export function activeAccount(state = null, action) {
  switch (action.type) {
    case CustomerAccountTypes.SET_ACTIVE_CUSTOMER_ACCOUNT:
      return action.data;

    case AccountTypes.LOGOUT_SUCCESS:
      return null;

    default:
      return state;
  }
}
