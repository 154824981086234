import * as React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

const Answer = ({ text, value, namespace, selected, onSelect }) => {
  const handleClick = (event) => {
    event.preventDefault();

    onSelect(value);
  };

  return (
    <FormGroup className="answer" onClick={handleClick}>
      <Label check>
        <Input
          type="radio"
          name={`${namespace}`}
          value={value}
          checked={selected}
          readOnly
        />
        {text}
      </Label>
    </FormGroup>
  );
};

Answer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};

Answer.defaultProps = {
  selected: false,
  onSelect: () => {},
};

export default Answer;
