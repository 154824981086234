import * as React from 'react';
import { PanelHeader } from 'js/components';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import { TeamForm } from 'js/components';

const TeamRouteComponent = ({ team, onSave }) => {
  const handleSave = teamProps => {
    onSave(teamProps);
  };

  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Create a team</CardTitle>
          </CardHeader>
          <CardBody>
            <TeamForm team={team} onSave={handleSave} autoFocus={true} />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default TeamRouteComponent;
