import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Link } from 'react-router-dom';

import { Icon } from 'js/components';

const AssessmentGroupSummary = props => {
  const { group, onAssess } = props;

  const handleAssess = (key, e) => {
    e.preventDefault();

    onAssess(key);
  };

  return (
    <div className="assessment-group-summary">
      <div>
        {group.name}
        <div className="action-items">
          <button
            className="btn btn-primary btn-inline"
            onClick={handleAssess.bind(this, group.key)}
          >
            <Icon icon={['far', 'poll']} size="lg" className="btn-icon-left" />
            Assess
          </button>
        </div>
      </div>
      <ol className="list-unstyled">
        {group.assessments.slice(0, 5).map(a => {
          const createdOn = moment(a.inserted_at);

          return (
            <li key={a.id} className="recent-activity-assessment list-card">
              <Link to={`/assessments/${a.id}/run`}>
                {createdOn.format('MMMM D YYYY h:mm a')}
              </Link>
              <small>
                {' '}
                <span>({createdOn.fromNow()})</span>{' '}
              </small>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

AssessmentGroupSummary.propTypes = {
  onAssess: PropTypes.func
};

AssessmentGroupSummary.defaultProps = {
  onAssess: () => {}
};

export default AssessmentGroupSummary;
