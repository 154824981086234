import React from 'react';
import { orderBy } from 'lodash';
import { scaleColorTraffic } from 'js/utils/color';
import LineWithRingsLegend from '../LineWithRingsLegend';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardSubtitle,
} from 'reactstrap';
import QuestionResults from '../QuestionResults';

function WorstResults({
  assessment,
  questions,
  filter,
  cohorts,
  activeCohort,
  participantCohortMap,
  legendFields,
  pinnedCohort,
  peekedCohort,
  onPinCohort,
  onClearPinnedCohort,
  onPeekCohort,
  onClearPeekedCohort,
}) {
  const legendProps = {
    fields: legendFields,
    pinnedCohort,
    peekedCohort,
    onPinCohort,
    onClearPinnedCohort,
    onPeekCohort,
    onClearPeekedCohort,
  };
  const top5 = orderBy(
    questions,
    ['normalizedScore', 'questionKey'],
    ['asc', 'asc']
  )
    .slice(0, 5)
    .map((stats) => {
      const question = assessment.question_sets
        .find((qs) => qs.key === stats.questionSetKey)
        ?.questions.find((q) => q.key === stats.questionKey);
      return { stats, question };
    });

  return (
    <Card tag="section">
      <CardHeader>
        <CardTitle tag="h5">Biggest opportunities</CardTitle>
        <CardSubtitle>
          You have the most room for improvement with these results.
        </CardSubtitle>
      </CardHeader>
      <CardBody>
        {legendFields.length > 2 ? (
          <LineWithRingsLegend {...legendProps} />
        ) : null}
        <section className="survey-review-grid">
          {top5.map(({ stats, question: q }) => (
            <div key={q.id}>
              <div className="detail-overview-question-header">
                <h6 className="question-set-name">
                  {stats.questionSetDisplayName}
                </h6>
                <div
                  className="question-score"
                  style={{ color: scaleColorTraffic(stats.normalizedScore) }}
                >
                  {Math.round(stats.normalizedScore * 100 + Number.EPSILON)}/100
                </div>
              </div>
              <QuestionResults
                question={q}
                filter={filter}
                participantCohortMap={participantCohortMap}
                cohorts={cohorts}
                activeCohort={activeCohort}
              />
            </div>
          ))}
        </section>
      </CardBody>
    </Card>
  );
}

export default WorstResults;
