import React from 'react';
import {
  width as checkWidth,
  height as checkHeight,
  svgPathData as checkSvgPathData,
} from '@fortawesome/pro-solid-svg-icons/faCheck';

const IsCompletedQuestionScore = ({ height, width, color }) => {
  const minDimension = Math.min(width, height) * 0.4;
  const scale = minDimension / Math.max(checkHeight, checkWidth);

  const iconWidth = checkWidth * scale;
  const iconHeight = checkHeight * scale;

  return (
    <g transform={`translate(${width / 2}, 0)`} className="question-score">
      <rect
        x={-width / 2}
        y={0}
        width={width}
        height={height}
        className="response"
      />
      <g
        transform={`translate(${-iconWidth / 2}, ${
          (height - iconHeight) / 2
        }) scale(${scale})`}
      >
        <path d={checkSvgPathData} fill={color(1)} />
      </g>
    </g>
  );
};

export default IsCompletedQuestionScore;
