import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconStack, Icon, QRCode } from 'js/components';
import HostNav from './HostNav';

import { faAnalytics } from '@fortawesome/pro-solid-svg-icons';

const QuestionNav = (props) => {
  const { assessment } = props;
  const qrUrl = `${window.location.origin}/assessments/${assessment.id}/host/kickoff`;

  return (
    <HostNav {...props}>
      <li className="nav-item">
        <Link to={`/assessments/${assessment.id}/host/overview`}>
          <IconStack size="2x">
            <Icon icon={['far', 'square']} />
            <Icon icon={faAnalytics} transform="shrink-9 left-1 down-0.5" />
          </IconStack>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={`/assessments/${assessment.id}/host/kickoff`}
          className="nav-qr"
        >
          <IconStack size="2x">
            <Icon icon={['far', 'square']} />
            <QRCode value={qrUrl} />
          </IconStack>
        </Link>
      </li>
    </HostNav>
  );
};

QuestionNav.propTypes = {
  assessment: PropTypes.object.isRequired,
  // questionId: PropTypes.string,
  // qrTo: PropTypes.oneOf(['kickoff', 'question']),
  onPreviousQuestion: PropTypes.func,
  onNextQuestion: PropTypes.func,
};

QuestionNav.defaultProps = {
  qrTo: 'kickoff',
  onPreviousQuestion: () => {},
  onNextQuestion: () => {},
};

export default QuestionNav;
