import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'js/components';
import moment from 'moment';
import ConfirmModal from '../modal/ConfirmModal';
import AsyncButton from '../Forms/AsyncButton';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { Input } from 'reactstrap';
import { canChangeWorkspaceSettings } from 'js/utils/authorization-utils';
import { useSelector } from 'react-redux';
import { allowWorkspaceInvites } from 'js/utils/entitlement-utils';

function isExpired(dateStr) {
  return !dateStr || moment(dateStr).isBefore(moment());
}

function recordClassName(workspace_user) {
  let className;
  if (workspace_user.is_invite) {
    className = isExpired(workspace_user.invite_expires_at)
      ? 'invite expired'
      : 'invite';
  } else {
    className = 'user';
  }
  return className;
}

function UserIcon({ workspace_user }) {
  return (
    <div>
      {workspace_user.is_invite ? (
        <Icon icon={['far', 'envelope']} />
      ) : (
        <Icon icon="user" className="info" />
      )}
    </div>
  );
}

function UserDisplayName({ workspace_user }) {
  return (
    <div className="display-name">
      {workspace_user.user?.display_name ? (
        <span>{workspace_user.user.display_name}</span>
      ) : (
        <span className="missing">&mdash;&mdash;</span>
      )}
    </div>
  );
}

function UserStatus({ workspace_user }) {
  return (
    <div className="status">
      {workspace_user.is_invite &&
        (isExpired(workspace_user.invite_expires_at) ? (
          <div className="danger">INVITATION EXPIRED</div>
        ) : (
          <div className="info">INVITATION PENDING</div>
        ))}
    </div>
  );
}

const UserList = ({
  onUpdateRole,
  workspaceUsers,
  onResendInvite,
  onRemoveUser,
  activeWorkspace,
}) => {
  const [toRemove, setToRemove] = useState(null);
  const me = useSelector((state) => state.identity.me);
  let canChangeWorkspace = canChangeWorkspaceSettings(me, activeWorkspace?.id);

  function UserRole({ workspace_user }) {
    const [displayRoleDropdown, setDisplayRoleDropdown] = useState(false);
    const updateRole = () => {
      let value = document.getElementById('role').value;

      onUpdateRole({ patch: { user_id: workspace_user.user.id, role: value } });
    };

    return (
      <>
        {!workspace_user.is_invite &&
          !displayRoleDropdown &&
          canChangeWorkspace &&
          allowWorkspaceInvites(activeWorkspace?.account) && ( //cannot change role of invitee
            <span
              className="role"
              onClick={() => setDisplayRoleDropdown(!displayRoleDropdown)}
            >
              {workspace_user.role}
              <Icon className="role-action" icon={faPencil} fixedWidth />
            </span>
          )}

        {displayRoleDropdown && (
          <Input
            type="select"
            id="role"
            className="role pb-0 pt-0"
            defaultValue={workspace_user.role}
            onChange={updateRole}
            onBlur={() => setDisplayRoleDropdown(false)}
            autoFocus
          >
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </Input>
        )}
      </>
    );
  }

  return (
    <div className="user-list">
      {workspaceUsers.map((wu) => (
        <div
          className={recordClassName(wu)}
          key={`${wu.workspace_id}-${wu.email}`}
        >
          <div className="icon">
            <UserIcon workspace_user={wu} />
          </div>
          <div className="username">{wu.email}</div>
          <UserDisplayName workspace_user={wu} />
          <UserRole workspace_user={wu} />
          <UserStatus workspace_user={wu} />
          <div className="actions">
            {wu.is_invite && (
              <AsyncButton
                color="info"
                size="sm"
                className="m-0"
                disabled={!allowWorkspaceInvites(activeWorkspace?.account)}
                onClick={() => onResendInvite(wu.email)}
              >
                <Icon icon={['fas', 'redo']} className="mr-1" /> Invite
              </AsyncButton>
            )}
            {!wu.is_invite && canChangeWorkspace && (
              <Button
                data-test="remove-user-btn"
                className="btn-icon"
                size="sm"
                disabled={!allowWorkspaceInvites(activeWorkspace?.account)}
                onClick={() => setToRemove(wu)}
              >
                <Icon icon={['fas', 'trash-alt']} />
              </Button>
            )}
          </div>
        </div>
      ))}
      <ConfirmModal
        isOpen={!!toRemove}
        title="Remove user?"
        onConfirm={() => onRemoveUser(toRemove?.user_id)}
        onCancel={() => setToRemove(null)}
        confirmButtonLabel="Remove User"
      >
        <p>
          Please confirm that you want to remove{' '}
          <strong>{toRemove?.user?.display_name ?? toRemove?.email}</strong>{' '}
          from the workspace: <strong>{activeWorkspace.name}.</strong>
        </p>
      </ConfirmModal>
    </div>
  );
};

UserList.propTypes = {
  workspaceUsers: PropTypes.array.isRequired,
  onResendInvite: PropTypes.func,
  resendingInviteEmail: PropTypes.string,
};

UserList.defaultProps = {};

export default UserList;
