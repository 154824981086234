import Engine from './containers/SurveyAssessmentRunEngine';
import ListItem from './components/ListItem';
import Review from './containers/SurveyAssessmentReviewEngine';
import { create, load } from 'js/actions/assessment-actions';
import Configure from './components/ConfigureSurveyAssessment';
import Launch from './containers/SurveyAssessmentLaunchContainer';

const SurveyAssessment = {
  Run: Engine,
  Check: Engine,
  Review,
  Configure,
  Launch,
  ListItem,
  strategies: {
    create,
    load: (assessmentId, participantToken) =>
      load(assessmentId, participantToken),
    buildPostCreateRedirectPath: ({ id, team_id, workspace_id }) =>
      `/w/${workspace_id}/teams/${team_id}/assessments/${id}/launch`,
  },
};

export default SurveyAssessment;
