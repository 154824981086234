import React from 'react';
import StrokedText from '../../Participation/Nodes/StrokedText';
import ScoreFill from './ScoreFill';

const RootNode = ({
  radius,
  node: {
    x: y,
    y: x,
    data: { name, score },
  },
}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <ScoreFill radius={radius} score={score} />
      <StrokedText
        strokeWidth={3}
        strokeColor="#2c2c2c"
        textAnchor="start"
        fill="#fff"
        x={radius + 4}
        y={1}
        dominantBaseline="middle"
        className="group-name"
        fontSize={10}
      >
        {name}
      </StrokedText>
    </g>
  );
};

export default RootNode;
