import React, { useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { resendVerification } from 'js/actions/account-actions';
import { notify } from 'js/actions/alert-actions';

import EmailVerificationFormRouteComponent from '../components/EmailVerificationFormRouteComponent';

const EmailVerificationFormRouteContainer = ({
  resendVerification,
  notify,
  location
}) => {
  const [resendState, setResendState] = useState({ error: null, sent: false });

  const email = useMemo(
    () => {
      try {
        const query = new URLSearchParams(location.search);
        return query.get('email');
      } catch {
        // do nothing
      }
      return null;
    },
    [location.search]
  );

  const handleResend = useCallback(
    async ({ email }) => {
      try {
        const result = await resendVerification(email);
        setResendState(currentResendState => ({
          ...currentResendState,
          error: null,
          sent: true
        }));
        notify({
          type: 'success',
          message: "A new verification mail is on it's way!"
        });

        return result;
      } catch (error) {
        setResendState(currentResendState => ({
          ...currentResendState,
          error,
          sent: true
        }));

        throw error;
      }
    },
    [resendVerification, setResendState, notify]
  );

  return (
    <EmailVerificationFormRouteComponent
      onResend={handleResend}
      resendState={resendState}
      email={email}
    />
  );
};

export default connect(
  null,
  {
    resendVerification,
    notify
  }
)(EmailVerificationFormRouteContainer);
