import React from 'react';
import _ from 'lodash';

import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import { AssessmentGroup, AsyncDisplay, Loading } from 'js/components';

const FavoriteTeamSummary = ({
  team,
  loaded,
  loadingAssessments,
  onToggleAssessmentLock,
  onDeleteAssessment,
  onCopyLink,
}) => (
  <Card key={team.id}>
    <CardHeader>
      <CardTitle tag="h5">
        <Link to={`/w/${team.workspace_id}/teams/${team.id}/dashboard`}>
          {team.name}
        </Link>
      </CardTitle>
    </CardHeader>
    <CardBody>
      <AsyncDisplay
        isLoading={loadingAssessments}
        hasLoaded={loaded}
        isError={false}
      >
        <AsyncDisplay.Loading>
          <Loading />
        </AsyncDisplay.Loading>
        <AsyncDisplay.Resolved>
          {_.some(team.assessments, (assessments) =>
            _.some(assessments, (a) => a.active)
          ) ? (
            <>
              <label>Recent Assessments</label>
              {_.map(team.assessments, (assessments, key) => {
                const name = assessments[0].name;
                return (
                  <AssessmentGroup
                    team={team}
                    workspaceId={team.workspace_id}
                    key={key}
                    templateId={key}
                    name={name}
                    assessments={assessments}
                    maxResults={3}
                    onToggleAssessmentLock={onToggleAssessmentLock}
                    onDeleteAssessment={onDeleteAssessment}
                    onCopyLink={onCopyLink}
                  />
                );
              })}
            </>
          ) : (
            <p className="text-muted">No recent assessments were found</p>
          )}
        </AsyncDisplay.Resolved>
      </AsyncDisplay>
    </CardBody>
  </Card>
);

export default FavoriteTeamSummary;
