const architectureQuestionSets = [
  {
    name: 'Loosely-coupled architecture',
    key: 'architecture',
    category: 'architecture',
    questions: [
      {
        text: 'We can do most of our testing without requiring an integrated environment',
        key: 'most-testing-isolated',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We deploy our application independently of other applications/services it depends on',
        key: 'independent-deployment',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We can make large-scale changes to the design of our system without the permission of somebody outside of the team',
        key: 'authority-to-change-design',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We can make large-scale changes to the design of our system without depending on other teams to make changes in their systems or creating significant work for other teams',
        key: 'independent-design-changes',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We can complete our work without needing to communicate and coordinate with other teams',
        key: 'decoupled-work',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We can do most of our testing on demand without requiring all integrated systems to be functional',
        key: 'testing-supports-mocked-dependencies',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Empowered Teams',
    key: 'empowered-teams',
    category: 'architecture',
    questions: [
      {
        text: 'We are allowed to choose our own tools and frameworks based on what is best for completing our work and supporting our users',
        key: 'choose-own-tools',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We are allowed to experiment with new approaches to solve our problems',
        key: 'team-experimentation',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  }
];

export default architectureQuestionSets;
