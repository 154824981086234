import React from 'react';
import { uniqueId } from 'lodash';
import { Card, CardBody, CardTitle } from 'reactstrap';

import styles from './WorkspaceSummary.module.scss';

const items = [...Array(3)].map((x) => uniqueId());

const WorkspaceSummarySkeleton = () => {
  return (
    <Card className={`${styles['workspace-summary']} skeleton`}>
      <CardTitle className={`${styles.name} skeleton-bone-after`}></CardTitle>
      <CardBody className={styles.body}>
        <div className={styles.teams}>
          <div className={`${styles.count} skeleton-bone`}></div>
          <div className={`${styles.subtitle} skeleton-bone`}></div>
        </div>
        <div className={styles.history}>
          <label className="skeleton-bone-after"></label>
          <ul className={styles.assessments}>
            {items.map((key) => (
              <li key={key} className={`${styles.item} skeleton-bone`} />
            ))}
          </ul>
        </div>
        <div className={styles.actions}></div>
      </CardBody>
    </Card>
  );
};

export default WorkspaceSummarySkeleton;
