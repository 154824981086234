import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Link } from 'react-router-dom';
import { getEngine } from 'js/engines';

const AssessmentGroup = (props) => {
  const {
    templateId,
    name,
    assessments,
    workspaceId,
    team,
    onToggleAssessmentLock,
    onDeleteAssessment,
    onCopyLink,
    maxResults,
  } = props;

  const activeAssessments = assessments.filter((a) => a.active);

  if (activeAssessments.length === 0) {
    return null;
  }

  const assessmentResultsPath = `/w/${workspaceId}/teams/${team.id}/assessments/${templateId}`;

  return (
    <div className="assessment-group">
      <h5 className="assessment-group-name">
        <Link to={assessmentResultsPath}>{name}</Link>
      </h5>
      <ul className="assessment-list list-unstyled">
        {activeAssessments.map((a, i) => {
          const { ListItem } = getEngine(a);

          return !!maxResults && i >= maxResults ? null : (
            <li key={a.id} className="assessment-description">
              <ListItem
                assessment={a}
                onToggleAssessmentLock={onToggleAssessmentLock}
                onDeleteAssessment={onDeleteAssessment}
                team={team}
                onCopyLink={onCopyLink}
              />
            </li>
          );
        })}
        {!maxResults || activeAssessments.length <= maxResults ? null : (
          <li className="additional-assessments">
            <Link to={assessmentResultsPath}>Find more&hellip;</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

AssessmentGroup.propTypes = {
  maxResults: PropTypes.number,
  onToggleAssessmentLock: PropTypes.func,
  onDeleteAssessment: PropTypes.func,
  onCopyLink: PropTypes.func,
};

AssessmentGroup.defaultProps = {
  onToggleAssessmentLock: _.noop,
  onDeleteAssessment: _.noop,
  onCopyLink: _.noop,
};

export default AssessmentGroup;
