import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { denormalize } from 'normalizr';
import { SCHEMAS } from 'js/api';

import {
  load as loadTeam,
  update as updateTeam,
  addMember as addTeamMember,
  updateMember as updateTeamMember,
  removeMember as removeTeamMember,
} from 'js/actions/team-actions';
import {
  list as listAssessments,
  update as updateAssessment,
} from 'js/actions/assessment-actions';
import { load as loadGoals } from 'js/actions/goal-actions';
import { notify } from 'js/actions/alert-actions';

import TeamDashboardComponent from '../components/TeamDashboardComponent';

class TeamDashboardContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialLoadComplete: false,
      workspaceId: null,
      teamId: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { workspaceId, teamId } = prevState;
    const {
      match: {
        params: { workspaceId: nextWorkspaceId, teamId: nextTeamId },
      },
    } = nextProps;

    if (workspaceId !== nextWorkspaceId || teamId !== nextTeamId) {
      return {
        initialLoadComplete: false,
        workspaceId: nextWorkspaceId,
        teamId: nextTeamId,
      };
    }

    return null;
  }

  componentDidMount() {
    const { queryId, loadTeam, loadGoals, match, listAssessments } = this.props;

    const { teamId } = match.params;
    loadTeam(teamId, queryId).then(() => {
      this.setState({ initialLoadComplete: true });
    });
    loadGoals();
    listAssessments({ team_id: teamId });
  }

  componentWillUnmount() {
    this.setState({
      initialLoadComplete: false,
      workspaceId: null,
      teamId: null,
    });
  }

  handleSetPrimary = (team, goal) => {
    const { setPrimaryGoal } = this.props;

    setPrimaryGoal(team.id, goal.id);
  };

  handleToggleAssessmentLock = (assessment) => {
    const { updateAssessment } = this.props;

    return updateAssessment({
      id: assessment.id,
      is_locked: !assessment.is_locked,
    });
  };

  handleDeleteAssessment = (assessment) => {
    const { updateAssessment } = this.props;

    return updateAssessment({
      id: assessment.id,
      active: false,
    });
  };

  handleUpdateTeam = (team) => {
    const { updateTeam } = this.props;

    return updateTeam(team);
  };

  handleCopyLink = () => {
    const { notify } = this.props;

    notify({ type: 'success', message: 'Link copied to clipboard' });
  };

  render() {
    const {
      goals,
      team,
      assessments,
      addTeamMember,
      updateTeamMember,
      removeTeamMember,
      query,
    } = this.props;

    return (
      <TeamDashboardComponent
        team={team}
        query={query}
        goals={goals}
        assessments={assessments}
        onToggleAssessmentLock={this.handleToggleAssessmentLock}
        onDeleteAssessment={this.handleDeleteAssessment}
        onAddTeamMember={addTeamMember}
        onUpdateTeamMember={updateTeamMember}
        onRemoveTeamMember={removeTeamMember}
        onSetPrimary={this.handleSetPrimary}
        onUpdateTeam={this.handleUpdateTeam}
        onCopyLink={this.handleCopyLink}
      />
    );
  }
}

export default connect(
  (store, props) => {
    const { match } = props;
    const { entities } = store;
    let { teamId, workspaceId } = match.params;
    teamId = +teamId;
    workspaceId = +workspaceId;

    const queryId = `w/${workspaceId}/t/${teamId}`;

    let team = entities.teams[teamId];
    if (!!team && team.workspace_id !== workspaceId) {
      team = undefined;
    }

    return {
      queryId,
      query: store.queries[queryId] || {},
      team,
      goals: _.map(entities.goals, (x) => x).sort(
        (a, b) => a.ordinal - b.ordinal
      ),
      assessments: _.chain(entities.assessments)
        .filter((a) => a.team_id === teamId && a.active)
        .orderBy(['name', 'inserted_at'], ['asc', 'desc'])
        .map((a) => denormalize(a, SCHEMAS.ASSESSMENT, entities))
        .value(),
    };
  },
  {
    loadGoals,
    loadTeam,
    addTeamMember,
    updateTeamMember,
    removeTeamMember,
    setPrimaryGoal: (teamId, goalId) => {
      return updateTeam({ id: teamId, primary_goal: goalId });
    },
    updateTeam,
    listAssessments,
    updateAssessment,
    notify,
  }
)(TeamDashboardContainer);
