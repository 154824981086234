import React from 'react';
import { map } from 'lodash';
import { scaleLinear } from '@visx/scale';

import { scaleColorTraffic } from 'js/utils/color';

import { CategoryLayout } from 'js/components/Charts/CategoryLayout';
import ConditionalLinkWrapper from '../ConditionalLinkWrapper';
import NotScorableQuestionResult from './NotScorableQuestionResult';
import ScorableQuestionResult from './ScorableQuestionResult';
import NoResponseQuestionResult from './NoResponseQuestionResult';
import UnscoredQuestionResult from './UnscoredQuestionResult';

function getQuestionResult(questionData) {
  if (questionData.responseCount === 0 && questionData.naCount === 0) {
    return NoResponseQuestionResult;
  }

  switch (questionData.type) {
    case 'long-text':
    case 'multi-select':
    case 'single-select':
      return NotScorableQuestionResult;

    case 'unscored-likert':
      return UnscoredQuestionResult;

    case 'likert':
    default:
      return ScorableQuestionResult;
  }
}

const AssessmentHistoryChart = ({
  dataMap,
  assessmentId,
  colors,
  categoryLookups,
  questionSetHref,
  questionHref,
}) => {
  const color = scaleLinear({ domain: colors.domain, range: colors.range });
  const questionSets = assessmentId
    ? dataMap.assessments[assessmentId].questionSets
    : dataMap.questionSets;

  const width = 20;
  const height = 120;

  return (
    <div className="row">
      <div className="col">
        <div className="assessment-history-chart">
          <div className="assessment-history-visualization" />
          <div className="assessment-history-questions">
            <CategoryLayout
              categoryLookups={categoryLookups}
              categories={dataMap?.categories}
              questionSets={questionSets}
              questionSetClassName="assessment-history-question-set"
              renderQuestionSetName={(questionSet) => (
                <span className="question-set-name">
                  <ConditionalLinkWrapper
                    wrap={!!questionSetHref}
                    href={questionSetHref}
                    parameters={[questionSet.key]}
                  >
                    {questionSet.displayName}
                  </ConditionalLinkWrapper>
                </span>
              )}
              renderQuestionSet={(questionSet, _palette) => {
                return (
                  <ul className="category-question-list">
                    {map(questionSet.questions, (q, qKey) => {
                      const QuestionResult = getQuestionResult(q);

                      return (
                        <QuestionResult
                          key={qKey}
                          tag="li"
                          width={width}
                          height={height}
                          questionData={q}
                          questionHref={questionHref}
                          parameters={[questionSet.key, qKey]}
                          color={color}
                        />
                      );
                    })}
                  </ul>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AssessmentHistoryChart.defaultProps = {
  colors: {
    domain: scaleColorTraffic.domain(),
    range: scaleColorTraffic.range(),
  },
};

export const Glyph = (
  <svg
    viewBox="0 0 32 32"
    className="chart-glyph assessment-history-stacked-bar-chart-glyph"
  >
    <rect x="2" y="21" width="8" height="9" className="bar-1 section-1" />
    <rect x="2" y="17" width="8" height="4" className="bar-1 section-2" />
    <rect x="2" y="2" width="8" height="15" className="bar-1 section-3" />
    <rect x="12" y="25" width="8" height="5" className="bar-2 section-1" />
    <rect x="12" y="9" width="8" height="16" className="bar-2 section-2" />
    <rect x="12" y="2" width="8" height="7" className="bar-2 section-3" />
    <rect x="22" y="12" width="8" height="18" className="bar-3 section-1" />
    <rect x="22" y="2" width="8" height="10" className="bar-3 section-2" />
  </svg>
);

export default AssessmentHistoryChart;
