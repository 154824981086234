import React from 'react';
import NullMarker from '../NullMarker';
import ConditionalLinkWrapper from '../ConditionalLinkWrapper';

const NoResponseQuestionResult = ({
  tag: Tag = 'div',
  questionHref,
  parameters,
}) => {
  return (
    <Tag className="assessment-history-question">
      <ConditionalLinkWrapper
        wrap={!!questionHref}
        href={questionHref}
        parameters={parameters}
      >
        <NullMarker width={20} height={20} viewBox="0 0 20 20" />
      </ConditionalLinkWrapper>
    </Tag>
  );
};

export default NoResponseQuestionResult;
