import { createPurchaseSuccess } from 'js/actions/product-actions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PurchaseSuccess from '../components/PurchaseSuccess';
import { me as loadMe } from 'js/actions/account-actions';

function PurchaseSuccessContainer({
  productId,
  checkoutSessionId,
  createPurchaseSuccess,
  loadMe,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await createPurchaseSuccess(productId, checkoutSessionId);
        loadMe();
      } catch (e) {
        console.error('failed to handle purchase success:', e);
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [
    createPurchaseSuccess,
    setError,
    productId,
    setLoading,
    loadMe,
    checkoutSessionId,
  ]);

  return <PurchaseSuccess loading={loading} provisioningError={error} />;
}

export default connect(
  (_store, props) => ({
    productId: props.match.params.id,
    checkoutSessionId: props.match.params.checkoutSessionId,
  }),
  {
    createPurchaseSuccess,
    loadMe,
  }
)(PurchaseSuccessContainer);
