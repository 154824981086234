import React, { useState, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container,
} from 'reactstrap';
import ProfileDropdown from './ProfileDropdown';
import NavMenuItems from './NavMenuItems';

function Header({ location, me, onLogout = () => {} }) {
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState('transparent');

  const node = useRef(null);
  const sidebarToggle = useRef(null);

  useEffect(() => {
    const updateColor = () => {
      setColor(window.innerWidth < 993 && isOpen ? 'white' : 'transparent');
    };
    window.addEventListener('resize', updateColor);

    return () => {
      window.removeEventListener('resize', updateColor);
    };
  }, [isOpen, setColor]);

  const toggle = useCallback(() => {
    const newOpenState = !isOpen;

    setColor(newOpenState ? 'white' : 'transparent');
    setIsOpen(newOpenState);
  }, [isOpen, setColor, setIsOpen]);

  useEffect(() => {
    const handleClick = ({ target }) => {
      if (!node.current || !isOpen || node.current.contains(target)) {
        return;
      }

      setColor('transparent');
      setIsOpen(false);
    };

    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, [node, isOpen, setColor, setIsOpen]);

  const toggleSidebar = useCallback(() => {
    document.documentElement.classList.toggle('nav-open');
    sidebarToggle.current.classList.toggle('toggled');
  }, [sidebarToggle]);

  useEffect(() => {
    document.documentElement.classList.toggle('nav-open', false);
    sidebarToggle.current.classList.toggle('toggled', false);
  }, [location.pathname]);

  return (
    <div ref={node} className="app-header">
      <Navbar
        color={color}
        expand="lg"
        className={classNames({
          'navbar-absolute fixed-top': true,
          'navbar-transparent': color === 'transparent',
        })}
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={sidebarToggle}
                className="navbar-toggler"
                onClick={() => toggleSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
          </div>
          <NavbarToggler onClick={toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar className="justify-content-end">
            <Nav navbar>
              <NavItem
                data-test="nav-item-account"
                className="d-none d-lg-block"
              >
                <ProfileDropdown profile={me} onLogout={onLogout} />
              </NavItem>
              <div className="d-lg-none">
                <NavMenuItems
                  profile={me}
                  onLogout={onLogout}
                  itemTag={NavItem}
                  linkProps={{ className: 'nav-link' }}
                  buttonProps={{ className: 'nav-link' }}
                />
              </div>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
export default Header;
