import { wowAnswers } from './base-answers';

export const metricsAndMeasures = [
  {
    name: 'Metrics & Measures',
    key: 'metrics-n-measures',
    category: 'metrics-measures',
    questions: [
      {
        text: 'Demo happens after every sprint or after feature delivery (or as needed)',
        key: 'demo-wow',
        answers: wowAnswers,
      },
      {
        text: 'Retrospective happens after every sprint (or as needed)',
        key: 'retro-wow',
        answers: wowAnswers,
      },
      // {
      //   text: 'The team allocates enough time for retrospectives',
      //   key: 'retro-time-wow',
      //   answers: wowAnswers,
      // },
      // {
      //   text: 'Velocity is measured and used in planning work',
      //   key: 'velocity-wow',
      //   answers: wowAnswers,
      // },
      {
        text: 'Work Category % breakout is reported',
        key: 'work-category-wow',
        answers: wowAnswers,
      },
      {
        text: 'Team uses, reviews and reports cycle times',
        key: 'control—charts-wow',
        answers: wowAnswers,
      },
      {
        text: 'Percent of planned work completed for each sprint is reviewed and reported',
        key: 'percent-planned-completed-wow',
        answers: wowAnswers,
      },
      {
        text: 'Retrospectives include reviews based on quantitative data',
        key: 'qr-wow',
        answers: wowAnswers,
      },
      {
        text: 'DORA Metric - deployment frequency is measured',
        key: 'deployment—frequency-wow',
        answers: wowAnswers,
      },
      {
        text: 'DORA Metric - Lead time for changes is measured',
        key: 'lead-time-wow',
        answers: wowAnswers,
      },
      // {
      //   text: 'DORA Metric - Time to restore service is measured',
      //   key: 'restore-time-wow',
      //   answers: wowAnswers,
      // },
      // {
      //   text: 'DORA Metric - Change failure rate is measured',
      //   key: 'change-failure-rate-wow',
      //   answers: wowAnswers,
      // },
      {
        text: 'The team uses a Release Management kanban board for Release and DORA metric reporting',
        key: 'release-management-metrics-wow',
        answers: wowAnswers,
      },
      {
        text: 'Heart metrics are being measured and reviewed',
        key: 'heart-metrics-wow',
        answers: wowAnswers,
      },
      {
        text: 'Squad Health Checks (Waypointr) are being measured each quarter',
        key: 'squad-health-checks-wow',
        answers: wowAnswers,
      },
      {
        text: 'Agile WOW health checks are being measured twice a year',
        key: 'wow—health-checks-wow',
        answers: wowAnswers,
      },
      {
        text: 'The team reviews and updates their definition of done (DoD)',
        key: 'dod-wow',
        answers: wowAnswers,
      },
      // {
      //   text: 'The team has a documented value stream map',
      //   key: 'vsm-wow',
      //   answers: wowAnswers,
      // },
      // {
      //   text: 'The team periodically reviews and updates their value stream map',
      //   key: 'update—vsm-wow',
      //   answers: wowAnswers,
      // },
    ],
  },
];
