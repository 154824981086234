import styles from './Contact.module.scss';
import * as React from 'react';
import { Icon } from 'js/components/index.js';
import classnames from 'classnames';

class Contact extends React.Component {
  render() {
    return (
      <div
        id="contact-page"
        className={classnames(['featured-form', styles.root])}
      >
        <div className="full-page-content">
          <div className="container">
            <div className={classnames(['content', styles.content])}>
              <div>
                <h2 className="mb-5">Contact Us</h2>
                <div>
                  <div className={classnames(['content', styles.content])}>
                    <Icon
                      icon={['fas', 'location-circle']}
                      size={'lg'}
                      className={'mt-1'}
                    />
                    <div className={classnames(['itemText', styles.itemText])}>
                      <p className="mb-0">930 Kehrs Mill Rd #302</p>
                      <p className="mb-0">Ballwin, MO 63011</p>
                    </div>
                  </div>
                  <div
                    className={classnames(['content', styles.content], 'mt-4')}
                  >
                    <Icon
                      icon={['fas', 'envelope']}
                      size={'lg'}
                      className={'mt-1'}
                    />
                    <div className={classnames(['itemText', styles.itemText])}>
                      <p className="mb-0">waypointr@waypointr.com</p>
                    </div>
                  </div>
                  <div
                    className={classnames(['content', styles.content], 'mt-4')}
                  >
                    <Icon
                      icon={['fas', 'link']}
                      size={'lg'}
                      className={'mt-1'}
                    />
                    <div className={classnames(['itemText', styles.itemText])}>
                      <p className="mb-0">
                        <a href="https://waypointr.com">waypointr.com</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="full-page-background" />
      </div>
    );
  }
}

export default Contact;
