import { planning } from './question-sets/planning';
import { scopeManagement } from './question-sets/scope-management';
import { estimating } from './question-sets/estimating';
import { sprintPractices } from './question-sets/sprint-practices';
import { metricsAndMeasures } from './question-sets/metrics-and-measures';

export const nbmWaysOfWorking = {
  name: 'New Business Models - Ways of Working',
  key: 'nbm-wow',
  question_sets: [...planning, ...scopeManagement, ...estimating, ...sprintPractices, ...metricsAndMeasures],
};

export default nbmWaysOfWorking;
