import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { load as loadTeam } from 'js/actions/team-actions';
import { notify } from 'js/actions/alert-actions';
import { createAssessmentInvites } from 'js/actions/assessment-invite-actions';

import SurveyAssessmentReviewComponent from '../components/SurveyAssessmentReviewComponent';

const SurveyAssessmentReviewContainer = (props) => {
  const { teamId, loadTeam, notify } = props;

  useEffect(() => {
    loadTeam(teamId);
  }, [teamId, loadTeam]);

  const handleCopyLink = () => {
    notify({ type: 'success', message: 'Link copied to clipboard' });
  };

  return (
    <SurveyAssessmentReviewComponent {...props} onCopyLink={handleCopyLink} />
  );
};

export default connect(
  ({ entities: { teams } }, { teamId }) => {
    return {
      team: teams[teamId] || {},
    };
  },
  {
    loadTeam,
    notify,
    createAssessmentInvites,
  }
)(SurveyAssessmentReviewContainer);
