import React, { useMemo } from 'react';
import moment from 'moment';

import { Card, CardBody } from 'reactstrap';
import { PanelHeader } from 'js/components';

import styles from './LongTextQuestionDetail.module.scss';

const LongTextQuestionDetail = ({ assessment, question, team }) => {
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  const { responses } = question;

  const sortedResponses = useMemo(
    () => responses.sort((a, b) => a.updated_at - b.updated_at),
    [responses]
  );

  return (
    <div className="survey-assessment review">
      <PanelHeader size="flex">
        <div className="panel-content assessment-review-header">
          <section className="details">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <label>Assessment</label>
                  <h1>{assessment.name}</h1>
                  <div className="timestamp">
                    {moment(assessment.inserted_at).format(
                      'MMM D, YYYY h:mm a'
                    )}
                  </div>
                  <label>Team</label>
                  <h2>{team?.name}</h2>
                  <label>Question</label>
                  <div className="lead">{question.text}</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </PanelHeader>
      <div className="content survey-review-detail">
        {sortedResponses.map((r) => {
          const date = new Date(r.updated_at);

          return (
            <article key={r.id}>
              <label className={styles.label}>
                {`${date.toLocaleDateString(userLocale, {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })}, ${date.toLocaleTimeString(userLocale)}`}
              </label>

              <Card>
                <CardBody>
                  <div>
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      {r.value.trim()}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </article>
          );
        })}
      </div>
    </div>
  );
};

export default LongTextQuestionDetail;
