import React, { useMemo } from 'react';
import { uniqueId, noop } from 'lodash';

const HarveyBall = ({ r: radius, slices = 4, fraction, onChange = noop }) => {
  const diameter = radius * 2;

  const { slicedMaskId, nullPatternId, nullMaskId, fullMaskId } =
    useMemo(() => {
      const componentId = uniqueId('allocation-');

      return {
        componentId,
        slicedMaskId: `${componentId}-mask-slices`,
        fullMaskId: `${componentId}-mask-full`,
        nullPatternId: `${componentId}-pattern-null`,
        nullMaskId: `${componentId}-mask-null`,
      };
    }, []);

  const theta = (2 * Math.PI) / slices;
  const slicePath = Array(slices)
    .fill(null)
    .map(
      (_x, i) =>
        `M${radius} ${radius} l${radius * Math.cos(i * theta)} ${
          radius * Math.sin(i * theta)
        }`
    );

  let outline = (
    <circle
      cx={radius}
      cy={radius}
      r={radius}
      className="harvey-ball-outline"
      mask={`url(#${slicedMaskId})`}
    />
  );

  let ball = null;
  switch (true) {
    case fraction === null || fraction === undefined:
      ball = (
        <g>
          <circle
            cx={radius}
            cy={radius}
            r={radius}
            className="harvey-ball-fill-null"
          />
          <circle
            cx={radius}
            cy={radius}
            r={radius}
            className="harvey-ball-fill-null-stripes"
            mask={`url(#${nullMaskId})`}
          />
        </g>
      );
      outline = (
        <circle
          cx={radius}
          cy={radius}
          r={radius}
          className="harvey-ball-outline"
          mask={`url(#${fullMaskId})`}
        />
      );

      break;

    case fraction <= 0:
      ball = (
        <circle
          cx={radius}
          cy={radius}
          r={radius}
          className="harvey-ball-fill-empty"
          mask={`url(#${slicedMaskId})`}
        />
      );
      break;

    case fraction >= 1:
      ball = (
        <circle
          cx={radius}
          cy={radius}
          r={radius}
          className="harvey-ball-fill"
          mask={`url(#${slicedMaskId})`}
        />
      );
      break;

    default:
      const arcFlag = fraction > 0.5 ? 1 : 0;
      ball = (
        <path
          d={[
            `M ${radius} ${radius} l0 ${-radius} `,
            `A${radius} ${radius} 0 ${arcFlag} 1 ${
              radius + radius * Math.sin(2 * Math.PI * fraction)
            }  ${radius - radius * Math.cos(2 * Math.PI * fraction)}`,
            `L${radius} ${radius}`,
          ].join()}
          className="harvey-ball-fill"
          mask={`url(#${slicedMaskId})`}
        />
      );
      break;
  }

  return (
    <svg
      viewBox={`0 0 ${diameter} ${diameter}`}
      width={diameter}
      height={diameter}
      className="harvey-ball"
    >
      <defs>
        <pattern
          id={nullPatternId}
          width={6}
          height={6}
          patternTransform={`translate(${diameter % 6},0)`}
          patternUnits="userSpaceOnUse"
        >
          <path
            d="M-1,1 l2,-2
        M0,6 l6,-6
        M5,7 l2,-2"
            stroke="white"
            strokeWidth={2}
          />
        </pattern>
      </defs>
      <mask id={nullMaskId}>
        <rect
          width={diameter}
          height={diameter}
          style={{ fill: `url(#${nullPatternId})` }}
        />
      </mask>
      <mask id={slicedMaskId}>
        <circle cx={radius} cy={radius} r={radius} fill="white" />
        {/* <rect x={0} y={0} width={diameter} height={diameter} fill="white" /> */}
        <path d={slicePath.join()} stroke="black" strokeWidth={1} />
      </mask>
      <mask id={fullMaskId}>
        <circle cx={radius} cy={radius} r={radius} fill="white" />
      </mask>
      {ball}
      {outline}
    </svg>
  );
};

export default HarveyBall;
