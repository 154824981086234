import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import BarChart from 'js/components/Charts/BarChart';
import HorizontalBarChart from 'js/components/Charts/HorizontalBarChart';
import { Icon } from 'js/components';

function countResponses(responses) {
  const defaults = { green: 0, yellow: 0, red: 0 };

  const actuals = _.countBy(
    responses.filter((r) => !r.is_na),
    function (response) {
      let responseValue = '' + response.value;

      switch (responseValue) {
        case '0':
          responseValue = 'red';
          break;

        case '1':
          responseValue = 'yellow';
          break;

        case '2':
          responseValue = 'green';
          break;

        default:
          responseValue = 'unknown';
      }

      return responseValue;
    }
  );

  return { ...defaults, ...actuals };
}

function getResponseData(counts) {
  return _.map(counts, (count, name) => {
    return {
      name,
      count,
      className: 'vote-' + name,
    };
  });
}

function getChart(title, data, width, height, duration, type) {
  let chart;

  const chartProps = {
    title,
    data,
    width,
    height,
    duration,
  };

  switch (type) {
    case 'bar':
      chart = <BarChart {...chartProps} />;
      break;

    case 'horizontal-bar':
    default:
      chart = <HorizontalBarChart {...chartProps} />;
  }

  return chart;
}

const VoteTally = (props) => {
  const [counts, setCounts] = useState(countResponses(props.responses));

  useEffect(() => {
    setCounts(countResponses(props.responses || []));
  }, [props.responses]);

  const resultCount = _.sum(_.values(counts));

  const classes = {
    'vote-tally': true,
    'no-results': resultCount === 0,
    'vote-tally-compact': props.display === 'compact',
  };

  if (props.type) {
    classes[props.type] = true;
  } else {
    classes['horizontal-bar'] = true;
  }

  return (
    <div className={classnames(classes)}>
      <div className="vote-tally-chart">
        {getChart(
          props.title,
          getResponseData(counts),
          props.width,
          props.height,
          props.duration,
          props.type
        )}
        {(() => {
          return resultCount > 0 ? null : (
            <div className="no-results-label">
              <div className="no-results-wrapper">
                <div className="no-results-icon">
                  <Icon icon="check" size="4x" />
                  <span className="no-results-text">No results</span>
                </div>
              </div>
            </div>
          );
        })()}
      </div>
    </div>
  );
};

VoteTally.defaultProps = {
  width: 100,
  height: 100,
  duration: 1000,
  countdown: 20000,
};

export default VoteTally;
