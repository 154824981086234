import React from 'react';

import SendInstructions from './SendInstructions';
import InstructionsSent from './InstructionsSent';

const ForgotPasswordRouteComponent = ({ onReset, onRetry, resetState }) => {
  const { sent = false, error = null } = resetState;

  return (
    <div id="forgot-password-form-page" className="featured-form">
      <div className="full-page-content">
        <div className="background-page">
          <div className="container">
            <div className="content">
              <div className="page-result page-success">
                {sent && !error ? (
                  <InstructionsSent onRetry={onRetry} />
                ) : (
                  <SendInstructions onReset={onReset} resetState={resetState} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
};

export default ForgotPasswordRouteComponent;
