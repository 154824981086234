import { likertQuestionSets } from './question-sets/likert';
import { mixedQuestionSets } from './question-sets/mixed';
import { longTextQuestionSets } from './question-sets/long-text';
import { unscoredLikertQuestionSets } from './question-sets/unscored-likert';
import { multiSelectQuestionSets } from './question-sets/multi-select';
import { singleSelectQuestionSets } from './question-sets/single-select';

export const sketchCapabilities = {
  name: 'Question Types',
  key: 'question-types',
  question_sets: [
    ...mixedQuestionSets,
    ...likertQuestionSets,
    ...longTextQuestionSets,
    ...unscoredLikertQuestionSets,
    ...multiSelectQuestionSets,
    ...singleSelectQuestionSets,
  ],
};

export default sketchCapabilities;
