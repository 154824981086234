import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon, IconStack } from 'js/components';

const Participant = props => {
  const { name, type, present } = props;
  const isHost = type === 'host';

  const classes = {
    participant: true,
    host: isHost,
    present,
    'not-present': !present
  };

  return (
    <div className={classnames(classes)}>
      {(() => {
        return isHost ? (
          <IconStack className="participant-icon" size="2x">
            <Icon icon="circle" className="icon-background" />
            <Icon icon="user" transform="shrink-8" />
          </IconStack>
        ) : (
          <Icon icon="user" />
        );
      })()}
      <span className="participant-name">{name}</span>
    </div>
  );
};

Participant.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  present: PropTypes.bool
};

export default Participant;
