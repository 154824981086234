import React from 'react';
import classnames from 'classnames';
import { Icon } from 'js/components';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

import { scaleColorTraffic } from 'js/utils/color';

import styles from './AssessmentDetailPane.module.scss';

export const ParticipantScores = ({ lollipopAssessment, tag: Tag = 'div' }) => {
  const participantData = Object.entries(lollipopAssessment.participantMetrics)
    .map(([id, p]) => ({ ...p, id }))
    .sort((a, b) => b.normalizedScore - a.normalizedScore);

  return (
    <Tag className={styles['participant-bar']}>
      <p>
        {participantData.map((p) => (
          <span
            key={p.id}
            className={classnames('participant', {
              'vote-none': p.meanNormalizedScore === null,
            })}
            style={{ color: scaleColorTraffic(p.meanNormalizedScore) }}
          >
            <Icon icon={faUser} />
          </span>
        ))}
      </p>
    </Tag>
  );
};

export default ParticipantScores;
