import React, { useMemo } from 'react';
import { sortBy } from 'lodash';
import { hierarchy, Cluster } from '@visx/hierarchy';
import { LinkHorizontal } from '@visx/shape';
import { RootNode, GroupNode, TeamNode } from './Nodes';
import { calculateBreadth } from 'js/utils/cluster';

const scoreNode = (node /*org or team*/, teamScores) => {
  if (!node.child_orgs && !node.teams) {
    // Team
    const scores = teamScores.find((ts) => ts.team_id === node.id)?.scores;
    let score;
    if (scores) {
      score = sortBy(scores, (s) => s.date)[scores.length - 1]?.score;
    }
    return {
      id: node.id,
      name: node.name,
      type: 'team',
      score,
      children: [],
    };
  }

  let allChildren = [
    ...sortBy(node.child_orgs ?? [], (o) => o.name),
    ...sortBy(node.teams ?? [], (t) => t.name),
  ];
  allChildren = allChildren.map((child) => scoreNode(child, teamScores));

  const total = allChildren.reduce(
    (total, child) => total + (child.score ?? 0),
    0
  );
  const count = allChildren.filter((c) => c.score !== undefined).length;
  const score = count === 0 ? undefined : total / count;

  return {
    id: node.id,
    name: node.name,
    type: 'group',
    score,
    children: allChildren,
  };
};

const Node = (props) => {
  const { node } = props;
  const {
    depth,
    data: { type = null },
  } = node;

  if (depth === 0) {
    return <RootNode {...props} />;
  }
  if (type === 'org') {
    return <GroupNode {...props} />;
  }
  return <TeamNode {...props} />;
};

const OrgTree = ({
  margin = { top: 10, left: 30, bottom: 10, right: 30 },
  width,
  minHeight = 200,
  nodeRadius = 8,
  targetLeafHeight = 25,
  targetClusterMargin = 10,
  teamNameBuffer = 160,
  organization,
  teamScores,
  workspaceId,
  assessmentKey,
}) => {
  const scoredStructure = useMemo(() => {
    return scoreNode(organization, teamScores);
  }, [organization, teamScores]);
  const tree = useMemo(() => hierarchy(scoredStructure), [scoredStructure]);

  const height = useMemo(
    () =>
      Math.max(
        minHeight,
        calculateBreadth(tree, targetLeafHeight, targetClusterMargin)
      ),
    [tree, targetLeafHeight, targetClusterMargin, minHeight]
  );

  const clusterWidth = width - margin.left - margin.right - teamNameBuffer;
  const clusterHeight = height - margin.top - margin.bottom;

  return (
    <svg viewBox={`0 0 ${width} ${height}`} width="100%" className="org-tree">
      {/* flip height/width for the cluster because we're rendering horizontally instead of vertically */}
      <Cluster size={[clusterHeight, clusterWidth]} root={tree}>
        {(tree) => (
          <g
            className="org-chart"
            transform={`translate(${margin.left},${margin.top})`}
          >
            {tree.links().map((link, i) => (
              <LinkHorizontal
                key={`link-${i}`}
                data={link}
                className="org-tree-link chart-link"
              />
            ))}
            {tree.descendants().map((node, i) => (
              <Node
                key={`node-${i}`}
                node={node}
                radius={nodeRadius}
                workspaceId={workspaceId}
                assessmentKey={assessmentKey}
              />
            ))}
          </g>
        )}
      </Cluster>
    </svg>
  );
};

export default OrgTree;
