import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';

function AsyncButton({ children, onClick, disabled, ...otherProps }) {
  const [loading, setLoading] = useState(false);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        setLoading(true);
        const res = onClick(e);
        if (res && res.finally) {
          res.finally(() => mounted.current && setLoading(false));
        } else {
          setLoading(false);
        }
      }
    },
    [setLoading, onClick]
  );
  return (
    <Button
      {...otherProps}
      disabled={!!disabled || loading}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
}

export default AsyncButton;
