import React, { useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { change as changeWorkspace } from 'js/actions/workspace-actions';
import { joinAccountChannel } from 'js/actions/channel-actions/customer-account-actions';
import { find } from 'lodash';
import { bindActionCreators } from 'redux';
import { denormalize } from 'normalizr';
import { SCHEMAS } from 'js/api/schemas';

const Workspace = ({
  me,
  currentWorkspaceId,
  workspace,
  match,
  changeWorkspace,
  joinAccountChannel,
  activeAccount,
  children,
}) => {
  const workspaceId = +match.params?.workspaceId;

  useEffect(() => {
    if (workspaceId !== currentWorkspaceId) {
      changeWorkspace(workspaceId);
    } else if (activeAccount?.id === undefined) {
      joinAccountChannel(workspace?.account?.id);
    }
  }, [
    workspaceId,
    currentWorkspaceId,
    activeAccount?.id,
    workspace?.account?.id,
    changeWorkspace,
    joinAccountChannel,
  ]);

  if (me.is_guest) {
    return <Redirect to="/guest" />;
  }

  if (workspaceId !== currentWorkspaceId) {
    if (me.workspaces.indexOf(workspaceId) === -1) {
      return <Redirect to={{ pathname: `/w/${me.workspaces[0]}/dashboard` }} />;
    }

    return null;
  } else if (me.workspaces.indexOf(currentWorkspaceId) === -1) {
    // NOTE: Should this return a 404?
    return <Redirect to={{ pathname: `/w/${me.workspaces[0]}/dashboard` }} />;
  }

  return <>{children}</>;
};
// class aWorkspace extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {};
//   }

//   static getDerivedStateFromProps(nextProps) {
//     const { currentWorkspaceId, match, changeWorkspace, activeAccount } =
//       nextProps;
//     let { workspaceId } = match.params;

//     workspaceId = +workspaceId;

//     console.log({ workspaceId, currentWorkspaceId, activeAccount });

//     if (workspaceId !== currentWorkspaceId) {
//       changeWorkspace(workspaceId);
//     }

//     return null;
//   }

//   render() {
//     const { me, currentWorkspaceId, match } = this.props;
//     let { workspaceId } = match.params;
//     workspaceId = +workspaceId;

//     // guests aren't allowed to access workspace-restricted content
//     if (me.is_guest) {
//       return <Redirect to="/guest" />;
//     }

//     if (workspaceId !== currentWorkspaceId) {
//       if (me.workspaces.indexOf(workspaceId) === -1) {
//         return (
//           <Redirect to={{ pathname: `/w/${me.workspaces[0]}/dashboard` }} />
//         );
//       }

//       return null;
//     } else if (me.workspaces.indexOf(currentWorkspaceId) === -1) {
//       // NOTE: Should this return a 404?
//       return <Redirect to={{ pathname: `/w/${me.workspaces[0]}/dashboard` }} />;
//     }

//     // return null;
//     return <>{this.props.children}</>;
//   }
// }

export default withRouter(
  connect(
    ({ currentWorkspaceId, activeAccount, identity: { me }, entities }) => {
      return {
        me,
        workspace: denormalize(
          find(entities.workspaces || [], (w) => w.id === currentWorkspaceId),
          SCHEMAS.WORKSPACE,
          entities
        ),
        currentWorkspaceId,
        activeAccount,
      };
    },
    (dispatch) => ({
      changeWorkspace: bindActionCreators(changeWorkspace, dispatch),
      joinAccountChannel: (account) => dispatch(joinAccountChannel(account)),
    })
  )(Workspace)
);
