import React from 'react';
import { median } from 'js/utils/math';
import { map, mean } from 'lodash';
import classNames from 'classnames';

function Variability({ varianceScore }) {
  switch (true) {
    case varianceScore > 1:
      return <h5 className="high-variance">High</h5>;

    case varianceScore > 0.2:
      return <h5 className="medium-variance">Medium</h5>;

    case varianceScore === null:
      return <h5 className="text-muted">N/A</h5>;

    default:
      return <h5 className="low-variance">Low</h5>;
  }
}

const QuestionSetSubview = ({ questionSet, categoryColorMap }) => {
  const variances = map(questionSet.questions, (q) =>
    q.normalizedScore === null
      ? null
      : Math.pow(questionSet.normalizedScore - q.normalizedScore, 2)
  ).filter((x) => x !== null);
  const normalizedVariance = variances.length === 0 ? null : mean(variances);
  const normalizedDev =
    normalizedVariance === null ? null : Math.sqrt(normalizedVariance);
  const scores = map(questionSet.questions, (q) => q.normalizedScore).filter(
    (x) => x !== null
  );

  const medianScore = scores.length === 0 ? null : median(scores);
  const meanScore = scores.length === 0 ? null : mean(scores);

  return (
    <>
      <section className="figure-stats">
        <label>Question Set</label>
        <h3 className="view-name">{questionSet.displayName}</h3>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <label>Mean Score</label>
                <h5
                  className={classNames({ 'text-muted': meanScore === null })}
                >
                  {meanScore === null
                    ? 'N/A'
                    : `${
                        Math.round(meanScore * 1000 + Number.EPSILON) / 10
                      }/100`}
                </h5>
              </td>
              <td>
                <label>Median Score</label>
                <h5
                  className={classNames({ 'text-muted': medianScore === null })}
                >
                  {medianScore === null
                    ? 'N/A'
                    : `${
                        Math.round(medianScore * 1000 + Number.EPSILON) / 10
                      }/100`}
                </h5>
              </td>
            </tr>
            <tr>
              <td>
                <label>Variability</label>
                <Variability varianceScore={normalizedDev} />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="figure-spacer"></section>
      <section className="figure-detail">
        {/* <label>Aggregate Scores</label> */}
        {/* <SetDetailChart data={detailData} /> */}
      </section>
    </>
  );
};

export default QuestionSetSubview;
