import React from 'react';

const NullQuestion = ({ question: { text } }) => {
  return (
    <div className="question">
      <div className="question-text">{text}</div>
      <div className="question-answers"></div>
    </div>
  );
};

export default NullQuestion;
