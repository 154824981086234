import React from 'react';

import { Icon } from 'js/components';

const AssessmentTypeGlyph = ({ type }) => {
  switch (type) {
    case 'live-assessment':
    case 'live':
      // prettier-ignore
      return (
        <span className="assessment-type-glyph assessment-type-live fa-layers fa-fw">
          <Icon icon={['fas', 'circle']} className="roundel-fill" />
          <Icon icon={['fal', 'circle']} className="roundel" />
          <Icon icon={['fas', 'bolt']} className="background-layer" transform="shrink-6" />
          <Icon icon={['fal', 'bolt']} transform="shrink-6" />
        </span>
      );

    case 'survey':
      // prettier-ignore
      return (
          <span className="assessment-type-glyph assessment-type-survey fa-layers fa-fw">
            <Icon icon={['fas', 'circle']} className="roundel-fill" />
            <Icon icon={['fal', 'circle']} className="roundel" />
            <Icon icon={['fas', 'square']} className="background-layer" transform="shrink-6" />
            <Icon icon={['far', 'poll']} transform="shrink-6" />
          </span>
        );

    default:
      return null;
    // passthrough
  }
};

export default AssessmentTypeGlyph;
