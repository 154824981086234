export const likertQuestionSets = [
  {
    name: 'Likert Questions',
    key: 'likert-questions',
    category: 'likert',
    questions: [
      {
        text: 'Collaboration within my team is encouraged and rewarded',
        key: 'intra-team-collaboration',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Adapting our budget spend to changing business priorities mid-cycle is',
        key: 'budget-reallocation',
        answers: [
          { value: '5', text: 'Very easy' },
          { value: '4', text: 'Easy' },
          { value: '3', text: 'Neither easy nor difficult' },
          { value: '2', text: 'Difficult' },
          { value: '1', text: 'Very difficult' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
];
