import React from 'react';
import PropTypes from 'prop-types';

class AsyncDisplay extends React.Component {
  render() {
    const { isLoading, isError, children } = this.props;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { isLoading, isError })
    );

    return <>{childrenWithProps}</>;
  }
}

AsyncDisplay.Loading = ({ children, isLoading }) => {
  return <>{isLoading ? children : null}</>;
};

AsyncDisplay.Error = ({ children, isLoading, isError }) => {
  return <>{isLoading || !isError ? null : children}</>;
};

AsyncDisplay.Resolved = ({ children, isLoading, isError }) => {
  return <>{isLoading || isError ? null : children}</>;
};

AsyncDisplay.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired
};

export default AsyncDisplay;
