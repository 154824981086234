import * as React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { Icon } from 'js/components';

const BusinessGoal = props => {
  const { title, primary, onSetPrimary } = props;

  const goalClasses = {
    'business-goal': true,
    primary: primary
  };

  return (
    <Card sm="12" md="6" className={classnames(goalClasses)}>
      <CardHeader>
        <CardTitle tag="h5">{title}</CardTitle>
        <div className="stamp" onClick={onSetPrimary}>
          <span className="fa-layers fa-fw">
            <Icon icon="star" size="2x" className="background-layer" />
            <Icon icon={['far', 'star']} size="2x" />
          </span>
        </div>
      </CardHeader>
      <CardBody>{props.children}</CardBody>
    </Card>
  );
};

BusinessGoal.propTypes = {
  primary: PropTypes.bool,
  title: PropTypes.string,
  onSetPrimary: PropTypes.func
};

BusinessGoal.defaultProps = {
  onSetPrimary: () => {}
};

export default BusinessGoal;
