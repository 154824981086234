export const singleSelectQuestionSets = [
  {
    name: 'Single-Choice Questions',
    key: 'single-choice-questions',
    category: 'single-select',
    questions: [
      {
        text: 'What is your favorite fruit?',
        key: 'favorite-fruit',
        type: 'single-select',
        answers: [
          { value: 'apple', text: 'Apples' },
          { value: 'bananas', text: 'Bananas' },
          { value: 'oranges', text: 'Oranges' },
          { value: 'pears', text: 'Pears' },
          { value: 'none', text: 'I hate fruit' },
        ],
      },
    ],
  },
];
