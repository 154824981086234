import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { AsyncDisplay, LoadingMessage } from 'js/components';

const ChartSelector = ({ charts, className, loading, error, ...props }) => {
  const [chartIndex, setChartIndex] = useState(0);

  const { component: ChartComponent, componentProps = {} } = charts[chartIndex];

  return (
    <>
      <div className={`switch-menu ${className}`}>
        {charts.map((c, i) => (
          <button
            {...c.buttonProps}
            key={c.key || i}
            onClick={(e) => {
              e.preventDefault();
              setChartIndex(i);
            }}
            className={classnames({
              'switch-menu-item': true,
              active: charts[chartIndex].component === c.component,
            })}
          >
            {c.label}
          </button>
        ))}
      </div>
      <AsyncDisplay isLoading={!!loading} isError={!!error}>
        <AsyncDisplay.Loading>
          <LoadingMessage iconSize="2x">Loading data&hellip;</LoadingMessage>
        </AsyncDisplay.Loading>
        <AsyncDisplay.Error>
          <div className="danger">
            Uh oh, looks like there was a problem loading the data
          </div>
        </AsyncDisplay.Error>
        <AsyncDisplay.Resolved>
          <ChartComponent {...props} {...componentProps} />
        </AsyncDisplay.Resolved>
      </AsyncDisplay>
    </>
  );
};

ChartSelector.propTypes = {
  charts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      component: PropTypes.elementType.isRequired,
    })
  ),
};

export default ChartSelector;
