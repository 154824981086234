import keymirror from 'keymirror';
import { request } from 'js/api/axios';

import { SCHEMAS } from '../api/schemas';
import { spreadApiKeys, extractApiKeys } from '../utils';
import { generateAck } from '../utils/ack';
import { callApi } from '../api';

export const types = keymirror({
  ...spreadApiKeys('CREATE_LIVE_ASSESSMENT'),
  ...spreadApiKeys('LOAD_LIVE_ASSESSMENT_DATA'),
  ...spreadApiKeys('UPDATE_LIVE_ASSESSMENT'),
  ...spreadApiKeys('CHANGE_QUESTION'),
});

export function list(query) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LOAD_LIVE_ASSESSMENT_DATA', types),
        request: (data, token) =>
          request.get('/api/live-assessments', {
            params: data.query,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { query },
        schema: SCHEMAS.LIVE_ASSESSMENT_ARRAY,
      },
      dispatch
    );
  };
}

export function load(handle) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LOAD_LIVE_ASSESSMENT_DATA', types),
        request: (_data, token) =>
          request.get(`/api/live-assessments/${handle}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { assessment_id: handle },
        schema: SCHEMAS.LIVE_ASSESSMENT,
      },
      dispatch
    );
  };
}

export function changeQuestion(assessmentId, questionId) {
  return (dispatch) => {
    const ack = generateAck();

    return callApi(
      {
        types: extractApiKeys('CHANGE_QUESTION', types),
        request: (data, token) =>
          request.put(
            `/api/live-assessments/${assessmentId}`,
            { live_assessment: data },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                Ack: ack,
              },
            }
          ),
        data: { assessment_id: assessmentId, current_question_id: questionId },
        meta: { ack },
        schema: SCHEMAS.LIVE_ASSESSMENT,
      },
      dispatch
    );
  };
}

export function update(patch) {
  return (dispatch) => {
    const ack = generateAck();

    return callApi(
      {
        types: extractApiKeys('UPDATE_LIVE_ASSESSMENT', types),
        request: (data, token) =>
          request.put(
            `/api/live-assessments/${patch.assessment_id}`,
            { live_assessment: data },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                Ack: ack,
              },
            }
          ),
        data: patch,
        schema: SCHEMAS.LIVE_ASSESSMENT,
        meta: { ack },
      },
      dispatch
    );
  };
}
