import React from 'react';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { Route, Switch } from 'react-router-dom';

import { SCHEMAS } from 'js/api';

import { logout } from 'js/actions/account-actions';
import {
  Header,
  Footer,
  AccountsSidebar,
  Sidebar,
  GuestSidebar,
  GlobalMessages,
} from 'js/components';
import {
  GuestAuthorization,
  FullUserAuthorization,
} from 'js/components/Security';
import { selectActiveWorkspace } from 'js/store/reducers/workspaces';

const AuthorizedGuestSidebar = GuestAuthorization(GuestSidebar);
const AuthorizedSidebar = FullUserAuthorization(Sidebar);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this._handleLogout = this._handleLogout.bind(this);
  }

  _handleLogout() {
    const { logout, history } = this.props;

    history.push('/');
    logout();
  }

  render() {
    const { component: Component, children, ...props } = this.props;

    return (
      <div className="wrapper">
        <Switch>
          <Route
            path="/a/:accountId"
            render={() => <AccountsSidebar {...props} user={props.me} />}
          />
          <Route
            path="/"
            render={() =>
              !!props.activeWorkspace && (
                <>
                  <AuthorizedSidebar user={props.me} {...props} />{' '}
                  <AuthorizedGuestSidebar user={props.me} {...props} />
                </>
              )
            }
          />
        </Switch>
        <div className="main-panel" ref="mainPanel">
          <GlobalMessages {...props} />
          <Header {...props} onLogout={this._handleLogout} />
          {Component ? <Component {...props}>{children}</Component> : children}
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { entities } = state;

    return {
      me: denormalize(state.identity.me, SCHEMAS.ME, entities),
      activeAccount: state.activeAccount,
      activeWorkspace: selectActiveWorkspace(state),
    };
  },
  { logout }
)(Dashboard);
