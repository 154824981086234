import React from 'react';
import { flatMap, map, noop } from 'lodash';
import classnames from 'classnames';

const innerStroke = 1;
const outerStroke = 0.5;

const Marker = ({
  d,
  questionFilter,
  transientAssessmentFilter,
  lockedAssessmentFilter,
  x,
  y,
  color,
  getAssessmentScore,
  getQuestionScore,
  getNullScore,
  height,
  radius,
  innerRadius,
  highlightStrokeWidth = 3,
  onHover = noop,
  onUnhover = noop,
  onClick = noop,
}) => {
  const questions = flatMap(d.questionSets, (qs, qsKey) =>
    map(qs.questions, (q) => ({
      questionSetKey: qsKey,
      questionKey: q.key,
      ...q,
    }))
  );

  const transientAssessmentFilterId =
    transientAssessmentFilter?.criteria?.assessmentId;
  const lockedAssessmentFilterId =
    lockedAssessmentFilter?.criteria?.assessmentId;

  let score =
    questionFilter && questionFilter.type === 'question'
      ? getQuestionScore(
          d.questionSets?.[questionFilter?.criteria?.questionSetKey]?.questions[
            questionFilter?.criteria?.questionKey
          ]
        )
      : getAssessmentScore(d);
  if (score === null) {
    score = getNullScore();
  }

  const yVal = y(score);

  const noResponses = !questions.some(
    (q) =>
      q.responseCount > 0 &&
      ((questionFilter &&
        questionFilter?.type === 'question' &&
        q.questionKey === questionFilter.criteria.questionKey &&
        q.questionSetKey === questionFilter.criteria.questionSetKey) ||
        questionFilter?.type !== 'question')
  );

  const markerClasses = {
    'no-responses': noResponses,
  };

  const highlightClasses = {
    locked: lockedAssessmentFilterId === d.id,
    transient: transientAssessmentFilterId === d.id,
  };

  const handleMouseOver = (event, datum) => {
    onHover(d.id);
  };

  const handleMouseOut = () => {
    onUnhover();
  };

  const handleClick = () => {
    onClick(d.id);
  };

  return (
    <g transform={`translate(${x(d)},0)`} className="marker">
      {
        <g className={classnames('marker-highlight', highlightClasses)}>
          <rect
            x={-(innerRadius + highlightStrokeWidth + outerStroke)}
            y={yVal + innerRadius}
            width={2 * (innerRadius + highlightStrokeWidth + outerStroke)}
            height={height - yVal - innerRadius}
            className="post-outer-outline"
          />
          <circle
            r={radius + highlightStrokeWidth + outerStroke}
            cy={yVal}
            className="post-outer-outline"
          />
          <rect
            x={-(innerRadius + highlightStrokeWidth)}
            y={yVal + innerRadius}
            width={2 * (innerRadius + highlightStrokeWidth)}
            height={height - yVal - innerRadius}
            className="rect-post"
          />
          <circle
            r={radius + highlightStrokeWidth}
            cy={yVal}
            className="post-top"
          />
          <rect
            x={-(innerRadius + innerStroke)}
            y={yVal + innerRadius}
            width={2 * (innerRadius + innerStroke)}
            height={height - yVal - innerRadius}
            className="post-outline"
          />
          <circle r={radius + innerStroke} cy={yVal} className="post-outline" />
        </g>
      }
      <g
        className={classnames(markerClasses)}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
      >
        <rect
          x={-innerRadius}
          y={yVal + innerRadius}
          width={2 * innerRadius}
          height={height - yVal - innerRadius}
          className="rect-post"
          fill={noResponses ? '' : color(score)}
        />
        <circle
          r={radius}
          cy={yVal}
          className="post-top"
          fill={noResponses ? '' : color(score)}
        />
        <circle r={innerRadius} cy={yVal} className="post-hole" />
      </g>
      Ï
    </g>
  );
};

export default Marker;
