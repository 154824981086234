import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';

import { notify } from 'js/actions/alert-actions';
import { load as loadTeam } from 'js/actions/team-actions';
import { createAssessmentInvites } from 'js/actions/assessment-invite-actions';

import { Link } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';

import { PanelHeader, Icon, IconStack } from 'js/components';
import { useCallback } from 'react';
import InviteForm from 'js/components/Forms/InviteForm';

const LaunchComponent = ({
  match,
  assessment,
  team,
  notify,
  createAssessmentInvites,
  loadTeam,
}) => {
  const {
    params: { workspaceId, teamId, assessmentId },
  } = match;

  const [showInviteForm, setShowInviteForm] = useState(false);
  const [sendingInvites, setSendingInvites] = useState(false);

  useEffect(() => {
    loadTeam(teamId);
  }, [teamId, loadTeam]);

  const handleSendInvites = useCallback(
    (emails, message) => {
      setSendingInvites(true);
      createAssessmentInvites(
        workspaceId,
        teamId,
        assessmentId,
        message,
        emails.map((email) => ({ email }))
      )
        .then(() => {
          notify({
            type: 'success',
            message: 'Your invitations are on their way!',
          });
          setShowInviteForm(false);
        })
        .catch(() =>
          notify({
            type: 'danger',
            message: "Uh oh! Your invitations didn't get through",
          })
        )
        .finally(() => setSendingInvites(false));
    },
    [
      workspaceId,
      teamId,
      assessmentId,
      createAssessmentInvites,
      setSendingInvites,
      notify,
    ]
  );

  const handleCopyLink = (e) => {
    e.preventDefault();

    const url = `${window.location.origin}/assessments/${assessment.id}/run`;
    copy(url);

    notify({ type: 'success', message: 'Link copied to clipboard' });
  };

  return (
    <div id="launch-survey-assessment">
      <PanelHeader size="sm" />
      <div className="content">
        <Card>
          <CardBody className="media">
            <IconStack className="launch-icon mr-3" size="4x" fixedWidth>
              <Icon icon={['fas', 'circle']} className="circle" />
              <Icon
                icon={['fal', 'rocket']}
                transform="shrink-6 left-.75 down-.25"
                inverse
                className="rocket"
              />
            </IconStack>
            <div className="media-body">
              <h2>You're live!</h2>
              <p>
                Your survey is ready and waiting. The only thing left is to
                invite some people.
              </p>
              <div className="action-items">
                <Button
                  color="info"
                  outline
                  onClick={() => setShowInviteForm(true)}
                >
                  <Icon icon="envelope" className="btn-icon-left" />
                  Invite by Mail
                </Button>
                <Button color="info" outline onClick={handleCopyLink}>
                  <Icon icon="copy" className="btn-icon-left" />
                  Copy Link
                </Button>
                <Link
                  data-test="goto-assessment-link"
                  to={`/assessments/${assessment.id}/run`}
                  target="_blank"
                  className="btn btn-outline-info"
                >
                  Go there now!
                  <Icon icon="arrow-right" className="btn-icon-right" />
                </Link>
              </div>
              {showInviteForm && (
                <Card className="mt-3">
                  <CardBody>
                    <InviteForm
                      onSendInvites={handleSendInvites}
                      team={team}
                      loading={sendingInvites}
                      onCancel={() => setShowInviteForm(false)}
                    />
                  </CardBody>
                </Card>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default connect(
  (
    { entities },
    {
      match: {
        params: { teamId },
      },
    }
  ) => {
    return {
      team: entities.teams[teamId],
    };
  },
  { notify, createAssessmentInvites, loadTeam }
)(LaunchComponent);
