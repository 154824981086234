import { trueToFalse as answers } from './base-answers';

export const organizationalAgility = {
  name: 'Organizational Agility (OA)',
  key: 'organizational-agility',
  category: 'organization',
  questions: [
    {
      text: 'Our organization can execute a change in strategy quickly in response to opportunities and threats',
      key: 'adjust-stategy-change-quickly',
      answers,
    },
    {
      text: 'Our large investments start with building a minimal viable product (MVP) to test the hypothesis',
      key: 'mvp',
      answers,
    },
    {
      text: 'We visualize the end-to-end flow of work that is required to deliver value',
      key: 'visualize-end-to-end-flow',
      answers,
    },
    {
      text: 'We identify and address delays in the flow of work',
      key: 'address-bottlenecks',
      answers,
    },
    {
      text: 'Our business teams operate with Agile practices',
      key: 'agile-business-teams',
      answers,
    },
    {
      text: 'Our employees receive continuous feedback in contrast to annual performance reviews.',
      key: 'continuous-feedback',
      answers,
    },
  ],
};
