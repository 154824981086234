import React from 'react';

import styles from './AssessmentTrendsChart.module.scss';

export const Ring = ({ cx, cy, rSpacer = 7, rOuter = 5, rInner = 3 }) => {
  return (
    <g className={styles['vote-count-glyph']}>
      <g>
        <circle className={styles.spacer} cx={cx} cy={cy} r={rSpacer} />
        <circle
          className={`palette-fill ${styles['outer-ring']}`}
          cx={cx}
          cy={cy}
          r={rOuter}
        />
        <circle className={styles['inner-circle']} cx={cx} cy={cy} r={rInner} />
      </g>
    </g>
  );
};

export default Ring;
