import React, { useMemo } from 'react';
import { map } from 'lodash';
import { LinePath } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';

import { Ring } from './Ring';

const LineWithRings = ({ x, y, data, curve = curveMonotoneX, ...props }) => {
  const strippedData = useMemo(
    () => data.filter((d) => typeof d === 'object'),
    [data]
  );
  return (
    <g {...props}>
      <g>
        <LinePath
          className="connector-line palette-stroke"
          data={data}
          x={x}
          y={y}
          curve={curve}
          fill="black"
        />
        <g>
          {map(strippedData, (d) => (
            <Ring key={d.insertedAt} cx={x(d)} cy={y(d)} />
          ))}
        </g>
      </g>
    </g>
  );
};

export default LineWithRings;
