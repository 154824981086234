import React from 'react';
import { map } from 'lodash';

import { CategoryLayout } from 'js/components/Charts/CategoryLayout';
import { scaleLinear } from '@visx/scale';
import QuestionDifferential from './QuestionDifferential';
import ArrowMarker from '../Differential/ArrowMarker';

import styles from './AssessmentDifferential.module.scss';

const yScale = scaleLinear({ domain: [0, 1], range: [100, 0] });

export const AssessmentDifferentialChart = ({
  assessments,
  categoryLookups,
  summaryMetrics,
}) => {
  const [before, after] = assessments;

  return (
    <CategoryLayout
      categoryLookups={categoryLookups}
      categories={summaryMetrics?.categories}
      stickyCategories
      fullNameBar={false}
      questionSets={summaryMetrics?.questionSets}
      renderQuestionSet={(questionSet, palette) => {
        return map(questionSet.questions, (question) => {
          return (
            <div key={question.key} className={styles['differential-item']}>
              <div className={palette} />
              <svg width={80} height={120}>
                <defs>
                  <ArrowMarker id="arrow" />
                </defs>
                <g transform={`translate(0, 10)`}>
                  <QuestionDifferential
                    from={
                      before?.questionSets[questionSet.key].questions[
                        question.key
                      ]
                    }
                    to={
                      after?.questionSets[questionSet.key].questions[
                        question.key
                      ]
                    }
                    arrowId={'arrow'}
                    yScale={yScale}
                  />
                </g>
              </svg>
              <p className={styles['item-label']}>{question.text}</p>
            </div>
          );
        });
      }}
    />
  );
};

export default AssessmentDifferentialChart;
