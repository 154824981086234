import React from 'react';
import classnames from 'classnames';

import { Icon, IconStack } from 'js/components';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';

// TODO:
// - sync
// - host
// - navigation

// function renderSyncIcon(isHost, isSynced) {
//   const baseIcon = (
//     <IconStack>
//       <Icon icon={isSynced ? ['fal', 'circle'] : ['fal', 'ban']} />
//       <Icon icon="sync" transform="shrink-8" />
//     </IconStack>
//   );

//   return isHost ? (
//     <span className="badged-icon">
//       {baseIcon}
//       <span className="badge">H</span>
//     </span>
//   ) : (
//     baseIcon
//   );
// }

const BallotNav = ({
  assessment,
  isHost,
  isSynced,
  allowNextQuestion = true,
  allowPreviousQuestion = true,
  onNextQuestion,
  onPreviousQuestion,
}) => {
  // const syncIconClass = {
  //   'ballot-nav-sync': true,
  //   synced: isSynced,
  // };

  const nextQuestionId = '';
  const previousQuestionId = '';

  const handleNextQuestion = useCallback(
    (e) => {
      e.preventDefault();

      if (allowNextQuestion) {
        onNextQuestion();
      }
    },
    [onNextQuestion, allowNextQuestion]
  );

  const handlePreviousQuestion = useCallback(
    (e) => {
      e.preventDefault();

      if (allowPreviousQuestion) {
        onPreviousQuestion();
      }
    },
    [onPreviousQuestion, allowPreviousQuestion]
  );

  return (
    <nav className="ballot-nav">
      <ul>
        {/* <li className={classnames(syncIconClass)}>
          <Link to={`/assessments/${assessment.id}/run`}>
            {renderSyncIcon(isHost, isSynced)}
          </Link>
        </li> */}
        <li
          className={classnames({
            'ballot-nav-previous': true,
            disabled: !allowPreviousQuestion,
          })}
        >
          <Link
            to={
              isHost
                ? ''
                : `/assessments/${assessment.id}/run/questions/${previousQuestionId}`
            }
            onClick={handlePreviousQuestion}
          >
            <IconStack>
              <Icon icon={['fal', 'circle']} />
              <Icon icon="arrow-left" transform="shrink-6" />
            </IconStack>
          </Link>
        </li>
        <li
          className={classnames({
            'ballot-nav-next': true,
            disabled: !allowNextQuestion,
          })}
        >
          <Link
            to={
              isHost
                ? ''
                : `/assessments/${assessment.id}/run/questions/${nextQuestionId}`
            }
            onClick={handleNextQuestion}
          >
            <IconStack>
              <Icon icon={['fal', 'circle']} />
              <Icon icon="arrow-right" transform="shrink-6" />
            </IconStack>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

BallotNav.defaultProps = {
  isHost: false,
  onPreviousQuestion: () => {},
  onNextQuestion: () => {},
};

export default BallotNav;
