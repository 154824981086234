import React from 'react';

import { PanelHeader } from 'js/components';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import UpgradeGuestAccountForm from 'js/components/Forms/UpgradeGuestAccountForm';

const GuestAccountRouteComponent = ({ me, onUpgradeGuestAccount }) => {
  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <div className="row">
          <div className="col">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Upgrade to a registered user</CardTitle>
              </CardHeader>
              <CardBody className="">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                    <UpgradeGuestAccountForm
                      user={me}
                      onSubmit={onUpgradeGuestAccount}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestAccountRouteComponent;
