import React from 'react';
import { Icon } from 'js/components';
import StrokedText from './StrokedText';
import NodeFill from './NodeFill';
import NullFill from './NullFill';
import { UncontrolledPopover } from 'reactstrap';
import NodePopover from 'js/routes/Organizations/components/OrgComponent/Visualizations/Participation/Nodes/NodePopover';

const GroupNode = ({ node, width, height, maskId, onSendReminders }) => {
  const iconSize = 10;
  const {
    data: { name: groupName, id, assessmentData },
  } = node;

  const { invitations, in_progress, completed } = assessmentData || {};

  const fillStats = {
    notStarted: invitations - in_progress - completed,
    partial: in_progress,
    completed,
  };

  return (
    <g
      transform={`translate(${node.y - width / 2},${node.x - height / 2})`}
      className="group-node"
    >
      {invitations > 0 ? (
        <>
          <g id={`UncontrolledPopover-group-${id}`}>
            <NodeFill
              maskId={maskId}
              width={width}
              height={height}
              {...fillStats}
            />
          </g>
          <UncontrolledPopover
            placement="right"
            target={`UncontrolledPopover-group-${id}`}
            trigger="hover click"
          >
            <NodePopover
              name={groupName}
              disableReminders={invitations - completed === 0}
              onSendReminders={() => onSendReminders(node.data)}
            />
          </UncontrolledPopover>
        </>
      ) : (
        <NullFill width={width} height={height} />
      )}

      <g
        transform={`translate(${-4 - iconSize}, ${height / 2 - iconSize - 3})`}
      >
        <svg
          viewBox={`0 0 ${iconSize} ${iconSize}`}
          width={iconSize}
          height={iconSize}
        >
          <Icon icon="envelope" className="icon-invites" transform="shrink-3" />
        </svg>
        <StrokedText
          textAnchor="end"
          fill="#fff"
          x="-3"
          y={iconSize / 2 + 1}
          dominantBaseline="middle"
          fontSize={iconSize}
          strokeColor="#2c2c2c"
          strokeWidth="3"
        >
          {invitations}
        </StrokedText>
      </g>
      <g transform={`translate(${width + 4}, ${height / 2})`}>
        <StrokedText
          className="node-name"
          textAnchor="start"
          dominantBaseline="middle"
          fontSize={iconSize}
          strokeColor="#2c2c2c"
          strokeWidth="3"
        >
          {groupName}
        </StrokedText>
      </g>
    </g>
  );
};

export default GroupNode;
