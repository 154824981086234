import { request } from 'js/api/axios';
import keymirror from 'keymirror';
import { spreadApiKeys, extractApiKeys } from '../utils';

import { callApi } from '../api';
import { SCHEMAS } from '../api/schemas';
import { me } from 'js/actions/account-actions';

export const types = keymirror({
  ...spreadApiKeys('CREATE_USER'),
  ...spreadApiKeys('INVITE_USER'),
  ...spreadApiKeys('VERIFY_INVITE_TOKEN'),
  ...spreadApiKeys('ACCEPT_INVITE'),
  ...spreadApiKeys('LOAD_USER'),
  ...spreadApiKeys('UPDATE_USER'),
  ...spreadApiKeys('LOAD_USERS'),
  ...spreadApiKeys('SEARCH_USERS'),
  ...spreadApiKeys('REGISTER'),
  ...spreadApiKeys('REMOVE_USER'),
  ...spreadApiKeys('UPDATE_WORKSPACE_USER'),
});

export function list(queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LOAD_USERS', types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(`/api/workspaces/${currentWorkspaceId}/users`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        schema: SCHEMAS.WORKSPACE_USER_ARRAY,
        meta: { queryId },
      },
      dispatch
    );
  };
}

export function invite(email, role) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('INVITE_USER', types),
        request: (data, token, currentWorkspaceId) =>
          request.post(`/api/workspaces/${currentWorkspaceId}/invites`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { email, role },
      },
      dispatch
    );
  };
}

export function resendInvite(email) {
  return (dispatch) => {
    const encodedEmail = encodeURIComponent(email);
    return callApi(
      {
        types: extractApiKeys('INVITE_USER', types),
        request: (_data, token, currentWorkspaceId) =>
          request.put(
            `/api/workspaces/${currentWorkspaceId}/invites/${encodedEmail}`,
            null,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
      },
      dispatch
    );
  };
}

export function verifyInviteToken(key, token) {
  const encodedKey = encodeURIComponent(key);
  return async (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.VERIFY_INVITE_TOKEN, types),
        request: () =>
          request.get(
            `/api/workspaces/invites/tokens/${token}?key=${encodedKey}`
          ),
      },
      dispatch
    );
  };
}

export function acceptInviteToken(token, key, registration = null) {
  const data = {
    registration,
    key,
  };

  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys(
          registration ? 'REGISTER' : 'ACCEPT_INVITE',
          types
        ),
        request: () =>
          request.post(`/api/workspaces/invites/tokens/${token}`, data),
        data,
      },
      dispatch
    ).then(() => {
      return me()(dispatch, getState);
    });
  };
}

export function removeFromWorkspace(userId) {
  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys('REMOVE_USER', types),
        request: async (_data, token, currentWorkspaceId) => {
          return request
            .delete(`/api/workspaces/${currentWorkspaceId}/users/${userId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (getState().identity?.me?.id === userId) {
                // Dispatch me, so a new workspace can be selected
                return me()(dispatch, getState).then(() => res);
              } else {
                return res;
              }
            });
        },
      },
      dispatch
    );
  };
}

export function updateWorkspaceUser(patch) {
  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys('UPDATE_WORKSPACE_USER', types),
        data: patch,
        request: async (data, token, currentWorkspaceId) => {
          return request
            .patch(
              `/api/workspaces/${currentWorkspaceId}/users/${data.user_id}`,
              { workspaces_user: data },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (getState().identity?.me?.id === data.user_id) {
                // Dispatch me, so user will be reloaded
                return me()(dispatch, getState).then(() => res);
              } else {
                return res;
              }
            });
        },
      },
      dispatch
    );
  };
}

export function load(handle) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LOAD_USER', types),
        request: (_data, token) =>
          request.get(`/api/users/${handle}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { id: handle },
        schema: SCHEMAS.USER,
      },
      dispatch
    );
  };
}

export function create(user) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('CREATE_USER', types),
        request: (data, token) =>
          request.post('/api/users', {
            data,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { user },
        schema: SCHEMAS.USER,
      },
      dispatch
    );
  };
}

export function update(patch) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.UPDATE_USER, types),
        request: (data, token) =>
          request.put(`/api/users/${patch.id}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { user: patch },
        meta: { patch: { users: { [patch.id]: patch } } },
        schema: SCHEMAS.USER,
      },
      dispatch
    );
  };
}

// TODO: add authorization
export function search(options) {
  const searchCriteria = {
    username: options.username || '',
    'exclude_ids[]': options.excludeIds || [],
  };

  return callApi(
    {
      types: extractApiKeys('SEARCH_USER', types),
      request: (data, token) =>
        request.get('/api/users', {
          params: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      data: searchCriteria,
      schema: SCHEMAS.USER,
    },
    () => {}
  );
}
