import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { load as loadTeam } from 'js/actions/team-actions';

import { FullPageLoading } from 'js/components';

import { getEngineStrategies, getEngineForTemplate } from 'js/engines';

import templates from 'js/data/assessment-templates';

const ConfigureAssessmentContainer = ({
  match: {
    params: { assessmentKey, teamId },
  },
  history,
  team,
  workspaceId,
  createAssessment,
  loadTeam,
}) => {
  const [template, setTemplate] = useState(null);
  const [creating, setCreating] = useState(false);

  const handleCreateAssessment = useCallback(
    (assessment, replaceHistory = false) => {
      setCreating(true);

      return createAssessment({
        ...assessment,
        team_id: teamId,
      }).then((assessment) => {
        setCreating(false);
        const strategies = getEngineStrategies(assessment);
        const navigate =
          replaceHistory === true ? history.replace : history.push;

        navigate(strategies.buildPostCreateRedirectPath(assessment));
      });
    },
    [teamId, createAssessment, history.replace, history.push]
  );

  useEffect(() => {
    loadTeam(teamId);
  }, [teamId, loadTeam]);
  useEffect(() => {
    const template = templates.find(
      (template) => template.key === assessmentKey
    );

    if (template && !template.configurable) {
      const { assessment, type } = template;

      handleCreateAssessment({ type, ...assessment }, true);
    } else {
      setTemplate(template);
    }
  }, [assessmentKey, teamId, handleCreateAssessment]);

  const { Configure: ConfigurationComponent } = getEngineForTemplate(template);

  return template && team ? (
    <ConfigurationComponent
      template={template}
      team={team}
      workspaceId={workspaceId}
      onCreateAssessment={handleCreateAssessment}
      creating={creating}
    />
  ) : (
    <FullPageLoading />
  );
};

export default withRouter(
  connect(
    ({ currentWorkspaceId, entities }, { match }) => {
      const { teams } = entities;
      const {
        params: { teamId },
      } = match;

      return {
        team: teams[teamId],
        workspaceId: currentWorkspaceId,
      };
    },
    {
      loadTeam,
      createAssessment: (assessment) => {
        const { create } = getEngineStrategies(assessment);

        return create(assessment);
      },
    }
  )(ConfigureAssessmentContainer)
);
