import React, { useMemo } from 'react';
import { map } from 'lodash';
import { scaleLinear } from '@visx/scale';
import { NA } from 'js/utils/question-set';

import ConditionalLinkWrapper from '../ConditionalLinkWrapper';

const ScorableQuestionResult = ({
  tag: Tag = 'div',
  width,
  height,
  questionData,
  questionHref,
  parameters,
  color,
}) => {
  const [domainMin, domainMax] = questionData.answerDomain;
  let cumulativeTotal = 0;

  const totalCount = questionData.responseCount + questionData.naCount;

  const scaleY = useMemo(
    () =>
      scaleLinear({
        range: [0, height],
        domain: [0, totalCount],
      }),
    [height, totalCount]
  );

  return (
    <Tag className="assessment-history-question-stacked-bar">
      <ConditionalLinkWrapper
        wrap={!!questionHref}
        href={questionHref}
        parameters={parameters}
      >
        <svg viewBox={`0 0 ${width} ${height}`}>
          {map(questionData.responseTotals, (total, key) => {
            if (total === 0) {
              return null;
            }

            const barHeight = scaleY(total);
            const barY = height - barHeight - scaleY(cumulativeTotal);
            const bar = (
              <rect
                key={key}
                x={0}
                y={barY}
                width={width}
                height={barHeight}
                fill={
                  key === NA
                    ? '#8e8e8e'
                    : color((key - domainMin) / (domainMax - domainMin))
                }
              />
            );

            cumulativeTotal += total;

            return bar;
          })}
        </svg>
      </ConditionalLinkWrapper>
    </Tag>
  );

  // const [min, max] = questionData.answerDomain;

  // const score = questionData.meanScore ?? null;

  // const responseColor =
  //   score === null ? '#61c4d2' : color((score - min) / (max - min));

  // return (
  //   <Tag
  //     className="assessment-history-question"
  //     style={{ backgroundColor: responseColor }}
  //     data-score={score}
  //   >
  //     <ConditionalLinkWrapper
  //       wrap={!!questionHref}
  //       href={questionHref}
  //       parameters={parameters}
  //     />
  //   </Tag>
  // );
};

export default ScorableQuestionResult;
