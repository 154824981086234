import React from 'react';
import PropTypes from 'prop-types';

const ConditionalLinkWrapper = ({
  wrap,
  href,
  parameters,
  children,
  tag: Tag = 'a',
}) => {
  return !(wrap && href) ? (
    <>{children}</>
  ) : (
    <Tag href={href.apply(null, parameters)}>{children}</Tag>
  );
};

ConditionalLinkWrapper.propTypes = {
  wrap: PropTypes.bool.isRequired,
  parameters: PropTypes.array.isRequired,
};

export default ConditionalLinkWrapper;
