import React, { useMemo } from 'react';
import { map, uniqueId, noop } from 'lodash';
import classNames from 'classnames';

import { withParentSize } from '@visx/responsive';
import { GridPolar } from '@visx/grid';
import RadialPieChartWedge from './RadialPieChartWedge';
import { scaleLinear } from '@visx/scale';
import { useCallback } from 'react';
import GridOverlay from './GridOverlay';

// Data points must conform to the following shape: { normalizedScore: number }
// normalizedScore is a number between 0 and 1, inclusive
// If normalizedScore is null, the wedge will be masked with a grid pattern
// an optional color map is used to color the wedges

const nullPalette = () => undefined;

const RadialPieChart = withParentSize(
  ({
    parentWidth,
    margin,
    dataPoints,
    paletteClassName = nullPalette,
    onSelectSlice = noop,
    onClearSlices = noop,
  }) => {
    const [outerRingMaskId, unansweredMaskId] = useMemo(() => {
      const chartId = uniqueId('radial-pie-chart-');
      return [`${chartId}-outer-ring-mask`, `${chartId}-unanswered-mask`];
    }, []);

    const handleClearSlices = useCallback(
      () => onClearSlices(),
      [onClearSlices]
    );

    const margins = { top: 0, right: 0, bottom: 0, left: 0, ...margin };

    const chartWidth = parentWidth;
    const width = parentWidth - (margins.left + margins.right);
    const height = width;
    const chartHeight = height + margins.top + margins.bottom;

    // const questionSetsByCategory = useMemo(() => {
    //   return groupBy(dataPoints, (qs) => qs.category);
    // }, [dataPoints]);

    const wedgeArc = (2 * Math.PI) / (Object.keys(dataPoints).length || 1);
    let i = 0;

    const rMax = width / 2 - 5;
    // const rInner = rMax - 15;
    const rInner = rMax;
    const center = { x: width / 2, y: width / 2 };
    const radialScale = scaleLinear({ domain: [0, 1], range: [0, rInner] });

    return (
      <svg
        viewBox={`0 0 ${chartWidth} ${chartHeight}`}
        className="radial-pie-chart"
      >
        <mask id={outerRingMaskId}>
          <circle
            cx={width / 2}
            cy={width / 2}
            r={rMax}
            fill="#fff"
            className="radial-pie-chart-outer-mask"
          />
          <circle
            cx={width / 2}
            cy={width / 2}
            r={rInner}
            fill="#000"
            className="radial-pie-chart-outer-mask"
          />
        </mask>
        <mask id={unansweredMaskId}>
          <GridPolar
            left={width / 2}
            top={width / 2}
            outerRadius={rInner}
            innerRadius={0}
            scaleAngle={radialScale}
            scaleRadial={radialScale}
            numTicksRadial={20}
            numTicksAngle={0}
            strokeWidthRadial={rInner / 40}
          />
        </mask>

        <g transform={`translate(${margins.left},${margins.right})`}>
          {/* <g className="wedges">
          {map(questionSetsByCategory, (c, categoryKey) => {
            const result = (
              <g className={colorMap[categoryKey]} key={categoryKey}>
                <RadialPieChartWedge
                  className="chart-wedge-category"
                  x={width / 2}
                  y={width / 2}
                  r={rMax}
                  theta={wedgeArc * c.length}
                  thetaOffset={-Math.PI / 2 + wedgeArc * k}
                  mask={`url(#${outerRingMaskId})`}
                />
              </g>
            );

            k += c.length;
            return result;
          })}
        </g> */}
          <g className="wedges" onMouseLeave={handleClearSlices}>
            <g>
              {map(dataPoints, (d, id) => {
                return (
                  <g
                    key={`category-wedge-${id}`}
                    className={classNames(
                      'chart-wedge-hover-target',
                      paletteClassName(d)
                    )}
                    onMouseEnter={() => onSelectSlice(d)}
                  >
                    <RadialPieChartWedge
                      className="chart-wedge-hover-spacer"
                      x={width / 2}
                      y={width / 2}
                      r={rInner}
                      theta={wedgeArc}
                      thetaOffset={-Math.PI / 2 + wedgeArc * i}
                    />
                    <RadialPieChartWedge
                      className={classNames({
                        'chart-wedge palette-fill': true,

                        unanswered: d.normalizedScore === null,
                      })}
                      x={width / 2}
                      y={width / 2}
                      r={(d.normalizedScore ?? 1) * rInner}
                      theta={wedgeArc}
                      thetaOffset={-Math.PI / 2 + wedgeArc * i++}
                      mask={
                        d.normalizedScore === null
                          ? `url(#${unansweredMaskId})`
                          : null
                      }
                    />
                  </g>
                );
              })}
            </g>
          </g>
          <GridOverlay
            sliceCount={dataPoints.length ?? Object.keys(dataPoints).length}
            cx={center.x}
            cy={center.y}
            radius={rInner}
          />
          <circle
            cx={center.x}
            cy={center.y}
            r={rInner}
            className="chart-axis"
            style={{ fill: 'none' }}
          />
        </g>
      </svg>
    );
  }
);

export default withParentSize(RadialPieChart);
