import {
  positiveNegativeAnswers,
  persistentTemporaryAnswers,
  shapeAnswers,
  motivatedAnswers,
  storyContextAnswer,
} from './base-answers';

export const cslpmEpmoExperiences = [
  {
    name: 'Experience with EPMO',
    key: 'epmoexperience',
    category: 'epmoexperience',
    questions: [
      {
        text: 'Share a recent experience (with specific stories, experiences, events, rumors, or anecdotes) that gives you hope or concern for Portfolio Management at CareSource:',
        key: 'cslpm-experiencestory-exp',
        type: 'long-text',
      },
      {
        text: 'If this story was on the news, what would the headline be?',
        key: 'cslpm-headline-exp',
        type: 'long-text',
      },
      {
        text: 'What was your overall sentiment of the experience you shared?',
        key: 'cslpm-sentiment-exp',
        answers: positiveNegativeAnswers,
      },
      {
        text: 'To what degree will the events in your story persist or happen again?',
        key: 'cslpm-persist-exp',
        answers: persistentTemporaryAnswers,
      },
      {
        text: 'Are you able to shape (or affect) the situation you shared? (amplify or dampen)',
        key: 'cslpm-shape-exp',
        answers: shapeAnswers,
      },
      {
        text: 'Are you motivated to shape the situation you shared?',
        key: 'cslpm-motivated-exp',
        answers: motivatedAnswers,
      },
      {
        text: 'What was the story you shared mostly about? (pick one)',
        key: 'cslpm-context-exp',
        answers: storyContextAnswer,
      },
    ],
  },
];
