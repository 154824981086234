import React from 'react';
import { connect } from 'react-redux';

import { verifyAccount } from 'js/actions/account-actions';

import VerifyAccountPageComponent from '../components/VerifyAccountPageComponent';

class VerifyAccountPageContainer extends React.Component {
  constructor(props) {
    super(props);

    const { token } = props;

    this.state = {
      token,
      successOrError: null
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      match: {
        params: { token }
      }
    } = nextProps;

    const { token: prevToken } = prevState;

    return token === prevToken
      ? null
      : {
          token
        };
  }

  componentDidMount() {
    this._verifyToken();
  }

  componentDidUpdate(prevProps, prevState) {
    const { token: prevToken } = prevState;
    const { token } = this.state;

    if (token !== prevToken && token) {
      this._verifyToken(token);
    }
  }

  _verifyToken(token) {
    const { verifyAccount } = this.props;

    this.setState({ successOrError: null });
    verifyAccount(token)
      .then(() => this.setState({ successOrError: true }))
      .catch(error => {
        const {
          response: { body, statusCode }
        } = error;

        if (body && body.message) {
          this.setState({ successOrError: body });
        } else if (statusCode === 404) {
          this.setState({ successOrError: { message: 'not found' } });
        } else {
          this.setState({ successOrError: { message: 'unknown' } });
        }
      });
  }

  render() {
    const { me } = this.props;
    const { successOrError } = this.state;

    return (
      <VerifyAccountPageComponent me={me} successOrError={successOrError} />
    );
  }
}

export default connect(
  state => {
    console.log(state);

    const {
      identity: { me }
    } = state;

    console.log(me);

    return {
      me
    };
  },
  {
    verifyAccount
  }
)(VerifyAccountPageContainer);
