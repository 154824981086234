import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  list as listUsers,
  updateWorkspaceUser,
  resendInvite,
  removeFromWorkspace,
} from 'js/actions/user-actions';
import _ from 'lodash';
import { notify } from 'js/actions/alert-actions';
import UsersComponent from 'js/routes/Users/components/UsersComponent';
import {
  QueryBase,
  selectWorkspaceUsersQuery,
} from 'js/store/reducers/queries';
import { canInviteUser } from 'js/utils/authorization-utils';
import { selectActiveWorkspace } from 'js/store/reducers/workspaces';
import { withRouter } from 'react-router-dom';

function UsersRouteContainer({
  listUsers,
  updateWorkspaceUser,
  userQueryResult,
  queryId,
  activeWorkspace,
  canInviteUser,
  resendInvite,
  notify,
  removeFromWorkspace,
}) {
  const fetchUsers = useCallback(() => {
    listUsers(queryId).catch(_.noop);
  }, [queryId, listUsers]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const onResendInvite = useCallback(
    (email) => {
      return (async () => {
        try {
          await resendInvite(email);
          notify({
            type: 'success',
            message: 'The invite has been resent',
          });
          fetchUsers();
        } catch {
          notify({
            type: 'danger',
            message: 'Sorry, there was a problem resending the invite',
          });
        }
      })();
    },
    [resendInvite, notify, fetchUsers]
  );

  const [updateUserApiResults] = useState({});

  const onUpdateRole = useCallback(
    ({ patch }) => {
      return (async () => {
        try {
          await updateWorkspaceUser(patch);
          notify({
            type: 'success',
            message: 'The role has been updated',
          });
          fetchUsers();
        } catch (e) {
          switch (e.response.status) {
            case 403:
              notify({
                type: 'danger',
                message: 'Sorry, you do not have permission to do that',
              });
              break;
            default:
              notify({
                type: 'danger',
                message: 'Sorry, there was a problem updating the role',
              });
          }
        }
      })();
    },
    [updateWorkspaceUser, notify, fetchUsers]
  );

  const onRemoveUser = useCallback(
    (userId) => {
      return (async () => {
        try {
          await removeFromWorkspace(userId);
          notify({
            type: 'success',
            message: 'The user has been removed from the workspace',
          });
          fetchUsers();
        } catch (e) {
          console.error(e);
          notify({
            type: 'danger',
            message: 'Sorry, there was a problem removing the user',
          });
        }
      })();
    },
    [removeFromWorkspace, fetchUsers, notify]
  );

  return (
    <UsersComponent
      workspaceUsers={userQueryResult.data}
      loading={userQueryResult.query.isLoading ?? true}
      isError={!!userQueryResult.query.error}
      activeWorkspace={activeWorkspace}
      canInviteUser={canInviteUser}
      onUpdateRole={onUpdateRole}
      updateUserRoleValue={updateUserApiResults.values}      
      onResendInvite={onResendInvite}
      onRemoveUser={onRemoveUser}
    />
  );
}

export default withRouter(
  connect(
  (store, { match: { params: { workspaceId } = { workspaceId: null } } }) => ({
    userQueryResult: selectWorkspaceUsersQuery(store, workspaceId),
    queryId: `${QueryBase.UsersInWorkspace}-${workspaceId}`,
    canInviteUser: canInviteUser(store.identity.me, workspaceId),
    activeWorkspace: selectActiveWorkspace(store),
  }),
  {
    listUsers,
    updateWorkspaceUser,
    resendInvite,
    notify,
    removeFromWorkspace,
  }
)(UsersRouteContainer)

);
