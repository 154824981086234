import React from 'react';
import { noop } from 'lodash';

import { PanelHeader } from 'js/components';

import BallotNav from './BallotNav';
import BallotReview from './BallotReview';
import BallotStatus from './BallotStatus';
import { useParticipants } from '../../hooks/useParticipants';

const RunReviewComponent = ({
  assessment,
  participant,
  presences,
  isHost = false,
  isSynced = false,
  onPreviousQuestion = () => {},
}) => {
  const participants = useParticipants(assessment, presences);

  return (
    <div className="assessment live-assessment voting-booth">
      <PanelHeader size="xs" />
      <div className="content has-bottom-bar">
        <div className="ballot">
          <BallotStatus participantCount={participants.length} />
          <BallotNav
            assessment={assessment}
            showResults={false}
            isHost={isHost}
            isSynced={isSynced}
            allowNextQuestion={false}
            allowPreviousQuestion={true}
            onNextQuestion={noop}
            onPreviousQuestion={onPreviousQuestion}
          />
          <BallotReview assessment={assessment} participant={participant} />
        </div>
      </div>
      <style>{`
        .main-panel { padding-bottom: 0; }
        .footer { display: none; }
      `}</style>
    </div>
  );
};

export default RunReviewComponent;
