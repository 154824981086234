import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { register } from 'js/actions/account-actions';

import RegistrationPageComponent from '../components/RegistrationPageComponent';

class RegistrationPageContainer extends React.Component {
  constructor(props) {
    super(props);

    this._handleRegister = this._handleRegister.bind(this);
  }

  _handleRegister(form) {
    const { register, history } = this.props;
    const { username, display_name, password, teamName } = form;
    const from = this.props.location.state?.from;

    register({ username, display_name, password }, { name: teamName })
      .then((me) => {
        if (
          from &&
          from.pathname &&
          from.pathname.match(/\/products\/.*\/purchase/)
        ) {
          const url = from.pathname + (from.search ?? '');
          history.push(url);
        } else if (me.verified === true) {
          // TODO: redirect to the location they were originally trying to access - See login container
          // const { from = {} } = this.props.location.state || {};
          history.push(`/dashboard`);
        } else {
          history.push(`/verify?${new URLSearchParams({ email: username })}`);
        }
      })
      .catch((e) => console.error(e));
  }

  render() {
    const { formState } = this.props;

    return (
      <RegistrationPageComponent
        formState={formState}
        onRegister={this._handleRegister}
      />
    );
  }
}

export default withRouter(
  connect(
    (state) => {
      return {
        formState: state.forms.register,
      };
    },
    {
      register,
    }
  )(RegistrationPageContainer)
);
