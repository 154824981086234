import * as React from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { PanelHeader } from 'js/components/index.js';

class NotFound extends React.Component {
  render() {
    return (
      <div className="not-found">
        <PanelHeader size="sm" />
        <div className="content">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Whaaaaa?</CardTitle>
            </CardHeader>
            <CardBody>
              <p>These aren't the droids you're looking for&hellip;</p>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default NotFound;
