import React from 'react';
import { connect } from 'react-redux';

import { Route, Switch } from 'react-router-dom';
import AccountsDashboard from './AccountsDashboard';
import AccountsUsers from './AccountsUsers';
import InviteAccountUsers from './InviteAccountUsers';
import AccountsSettings from './AccountsSettings';
import AccountsSettingsBilling from './AccountsSettingsBilling';

const AccountsRouter = ({ match, me, account, activeWorkspace }) => {
  const routeProps = {
    account,
    me,
    match,
    activeWorkspace,
  };

  return (
    <Switch>
      <Route
        path={`${match.path}/users`}
        exact
        render={() => <AccountsUsers {...routeProps} />}
      />
      <Route
        path={`${match.path}/users/invite`}
        exact
        render={() => <InviteAccountUsers {...routeProps} />}
      />
      <Route
        path={`${match.path}/settings/billing`}
        exact
        render={() => <AccountsSettingsBilling {...routeProps} />}
      />
      <Route
        path={`${match.path}/settings`}
        exact
        render={() => <AccountsSettings {...routeProps} />}
      />
      <Route
        path={match.path}
        render={() => <AccountsDashboard {...routeProps} />}
      />
    </Switch>
  );
};

export default connect(
  (
    _store,
    {
      match: {
        params: { accountId },
      },
      me,
      activeWorkspace,
    }
  ) => {
    return {
      account: me.accounts.find((a) => a.id === accountId),
    };
  },
  null
)(AccountsRouter);
