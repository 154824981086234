import React from 'react';
import classNames from 'classnames';
import styles from './Overlay.module.scss';

export const Overlay = ({ children, className, ...props }) => (
  <div className={classNames(styles.overlay, className)} {...props}>
    {children}
  </div>
);

export default Overlay;
