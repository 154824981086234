import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import EmailVerificationRouteContainer from './EmailVerificationRouteContainer';
import EmailVerificationFormRouteContainer from './EmailVerificationFormRouteContainer';

const EmailVerificationRouterContainer = ({ me, match, ...props }) => {
  return (
    <Switch>
      <Route
        path={`${match.path}/:verificationKey`}
        exact
        component={EmailVerificationRouteContainer}
      />
      <Route
        path={`${match.path}`}
        exact
        component={EmailVerificationFormRouteContainer}
      />
      <Redirect to={match.path} />
    </Switch>
  );
};

export default EmailVerificationRouterContainer;
