import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { setQuestionReveal } from 'js/actions/question-actions';

import CountdownClock from 'js/components/CountdownClock';
import { Icon } from 'js/components';

const VoteTallyCount = (props) => {
  const { question = {}, responses, countdownTime, setQuestionReveal } = props;
  const [revealTimeout, setRevealTimeout] = useState(null);

  const [revealResultsOn, setReveal] = useState(
    question.reveal_results_on || Date.now() + countdownTime
  );
  const questionHasReveal = question.reveal_results_on > 0;

  const revealResults = (revealOn) => {
    if (revealResultsOn && revealResultsOn <= revealOn) {
      return;
    }

    setReveal(revealOn || revealResultsOn);

    // set it 60m ago to help account for machine time differences
    setQuestionReveal(question.id, revealOn);
  };

  useEffect(
    () => {
      let timeout = null;
      if (!questionHasReveal) {
        timeout = setTimeout(() => {
          revealResults(revealResultsOn);
        }, props.waitTime);
        setRevealTimeout(timeout);
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    },
    // TODO: fix warning instead of disabling
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [question.id]
  );

  useEffect(
    () => {
      setReveal(question.reveal_results_on || Date.now() + countdownTime);
    },
    // TODO: fix warning instead of disabling
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [question.reveal_results_on, question.id]
  );

  const handleShowResults = (e) => {
    e.preventDefault();

    clearTimeout(revealTimeout);
    setRevealTimeout(null);
    revealResults(Date.now() - 60 * 60 * 1000);
  };

  const handleClockEnd = () => {
    revealResults(Date.now() - 60 * 60 * 1000);
  };

  // NOTE: use a class that is active when the timer is active to
  // disable the transition effect (no transition duration to hide results)
  // when the class is not applied, the transition time > 0
  return (
    <div className="vote-tally-count">
      <div className="vote-tally-count-wrapper">
        <div className="vote-tally-count-icon">
          <span className="badged-icon">
            <Icon icon="check" size="4x" />
            <span className="badge">{responses.length}</span>
          </span>
        </div>
        <div className="vote-tally-count-timer">
          <CountdownClock
            countdownTo={revealResultsOn}
            onEnd={handleClockEnd}
          />
          <button
            className="btn-link vote-tally-show"
            onClick={handleShowResults}
          >
            Show results
          </button>
        </div>
      </div>
    </div>
  );
};

VoteTallyCount.defaultProps = {
  responses: [],
  countdownTime: 30000,
  waitTime: 3500,
};

export default connect(null, {
  setQuestionReveal,
})(VoteTallyCount);
