import { trueToFalse as answers } from './base-answers';

export const continuousLearning = {
  name: 'Continuous Learning Culture (CLC)',
  key: 'continuous-learning-culture',
  category: 'learning',
  questions: [
    {
      text: 'Relentless improvement is part of our culture',
      key: 'improvement-culture',
      answers,
    },
    {
      text: 'We use data, rather than opinions, to guide our improvements',
      key: 'data-driven',
      answers,
    },
    {
      text: 'Our organization provides time for innovation',
      key: 'innovation-time',
      answers,
    },
    {
      text: 'Our culture supports experimentation without fear of negative consequences',
      key: 'experimentation',
      answers,
    },
    {
      text: 'Our organization invests in individual growth',
      key: 'individual-growth',
      answers,
    },
    {
      text: 'We purposefully share learning across the organization',
      key: 'shared-learning',
      answers,
    },
  ],
};
