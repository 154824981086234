import React from 'react';

import { Link } from 'react-router-dom';
import { PanelHeader, Icon } from 'js/components';
import { Card, CardBody } from 'reactstrap';

const GuestTeamsRouteComponent = () => {
  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <div className="row">
          <div className="col">
            <Card>
              <CardBody className="media text-muted">
                <Icon
                  icon={['far', 'star-exclamation']}
                  size="4x"
                  className="mr-3"
                  fixedWidth
                />
                <div className="media-body">
                  <p>
                    You are signed in with a guest account and the Teams feature
                    is only available to full accounts.
                  </p>
                  <p>
                    To host assessments and start tracking improvements by team,{' '}
                    <Link to="/account">
                      convert your guest account into a full account.
                    </Link>
                  </p>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestTeamsRouteComponent;
