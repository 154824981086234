import React from 'react';
import { find, keyBy, max, toArray } from 'lodash';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { useNotify } from 'js/hooks/useNotify';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { PanelHeader, Icon, IconStack } from 'js/components';

import QuestionOverview from '../QuestionOverview';
import AssessmentDetailChart from './AssessmentDetailLineChart';
import ParticipationStats from 'js/engines/SurveyAssessment/components/Review/ParticipationStats';
import { isArchived } from 'js/utils/team-utils';

import {
  faCopy,
  faClipboardListCheck,
  faPoll,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircle, faRedoAlt } from '@fortawesome/pro-solid-svg-icons';

const AssessmentReviewDetail = ({
  assessments,
  team,
  match: {
    params: { assessmentId },
  },
  assessmentInvitesQueryResult,
}) => {
  const assessment = find(assessments, (a) => a.id === assessmentId);

  const notify = useNotify();
  const handleCopyLink = () => {
    const url = `${window.location.origin}/assessments/${assessment.id}/run`;

    copy(url);

    notify({ type: 'success', message: 'Link copied to clipboard' });
  };

  const participants = toArray(keyBy(assessment?.participants, 'id')).sort(
    (a, b) =>
      a.name?.localeCompare(b.name, 'en', {
        sensitivity: 'base',
      }) ?? -1
  );

  // TODO: refactor this into a utility method
  // NOTE: this calculation is also used by Host/Overview
  const counts = {};
  if (!!assessment) {
    assessment.question_sets.forEach((qs) => {
      qs.questions.forEach((q) => {
        q.responses.forEach((r) => {
          const key = `${q.id}-${r.value}`;
          counts[key] = counts.hasOwnProperty(key) ? counts[key] + 1 : 1;
        });
      });
    });
  }

  const yLimit = max(toArray(counts)) || 0;
  const timestamp = assessment
    ? moment(assessment.inserted_at).format('MMM D, YYYY h:mm a')
    : null;

  const pendingInvites = assessmentInvitesQueryResult.data?.filter(
    (i) => i.email && i.participant_id === null
  );

  const teamIsArchived = isArchived(team);
  const dropdownProps = teamIsArchived ? { disabled: true, isOpen: false } : {};

  return (
    <div className="question-set-history live-assessment-review-detail">
      <PanelHeader size="flex">
        <div className="assessment-review-header">
          <section className="details">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-9">
                  <label>Assessment</label>
                  <h1>{(assessment || {}).name}</h1>
                  <div className="timestamp">{timestamp}</div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="actions">
                    <UncontrolledDropdown
                      {...dropdownProps}
                      disabled={teamIsArchived}
                    >
                      <DropdownToggle
                        color="primary"
                        caret
                        disabled={teamIsArchived}
                      >
                        Actions
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          tag={Link}
                          to={`/assessments/${assessment.id}/host`}
                          disabled={teamIsArchived}
                        >
                          <Icon
                            icon={faClipboardListCheck}
                            size="lg"
                            className="btn-icon-left"
                            fixedWidth
                          />
                          Facilitate this assessment
                        </DropdownItem>
                        <DropdownItem
                          onClick={handleCopyLink}
                          disabled={teamIsArchived}
                        >
                          <Icon
                            icon={faCopy}
                            size="lg"
                            className="btn-icon-left"
                            fixedWidth
                          />
                          Copy link for participants
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          to={`/w/${team.workspace_id}/teams/${team.id}/assessments/${assessment.key}/new`}
                          disabled={teamIsArchived}
                        >
                          <IconStack
                            size="lg"
                            className="btn-icon-left"
                            fixedWidth
                          >
                            <Icon
                              icon={faCircle}
                              mask={faPoll}
                              transform="shrink-4 right-6 down-6"
                            />
                            <Icon
                              icon={faRedoAlt}
                              transform="shrink-7 right-6 down-6"
                            />
                          </IconStack>
                          Run this assessment again
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-9">
                  <label>Team</label>
                  <h2>
                    {team.name}
                    &nbsp;
                  </h2>
                </div>
                <div className="col-12 col-md-3">
                  <ParticipationStats
                    assessment={assessment}
                    invites={assessmentInvitesQueryResult}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="details-chart aggregate-history container-fluid mt-4">
            <AssessmentDetailChart assessment={assessment} height={100} />
          </section>
        </div>
      </PanelHeader>
      <div className="content">
        <div className="row">
          <div className="col col-12 col-md-3 order-md-9">
            <Card className="participant-card">
              <CardHeader>
                <CardTitle>Participants</CardTitle>
              </CardHeader>
              <CardBody>
                <ul className="participant-list">
                  {participants.map((p) => (
                    <li key={p.id} className="participant">
                      <Icon icon="user" />
                      {p.name}
                    </li>
                  ))}
                </ul>
              </CardBody>
              {pendingInvites && pendingInvites.length > 0 && (
                <>
                  <CardHeader>
                    <CardTitle>Pending invites</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <ul className="participant-list">
                      {pendingInvites?.map((i) => (
                        <li key={i.email} className="participant">
                          <Icon icon="envelope" />
                          {i.email}
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </>
              )}
            </Card>
          </div>
          <div className="col col-12 col-md-9 order-md-1">
            <Card>
              <CardBody className="equal-padding">
                {!assessment
                  ? null
                  : assessment.question_sets.map((questionSet) => {
                      return (
                        <div
                          key={questionSet.id}
                          className="question-results-summary"
                        >
                          {questionSet.questions.map((question, i) =>
                            i >= 12 ? null : (
                              <div
                                key={question.id}
                                className="question-result"
                              >
                                <QuestionOverview
                                  question={question}
                                  responses={question.responses}
                                  yLimit={yLimit}
                                />
                              </div>
                            )
                          )}
                        </div>
                      );
                    })}
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentReviewDetail;
