export const wowAnswers = [
  { value: '3', text: 'Doing - Implemented & following' },
  { value: '2', text: 'Started - Trying but not consistent' },
  { value: '1', text: 'Not Started - Plan to implement' },
  { value: '0', text: 'Not Doing - No plan to implement' },
  { value: null, text: 'Not sure/Not Applicable', is_na: true },
];

export const soThatAnswers = [
  { value: '3', text: 'Agree with the desired outcome' },
  { value: '2', text: 'Partially agree with the desired outcome' },
  { value: '1', text: 'Do not agree with the desired outcome' },
  { value: '0', text: 'No value in this desired outcome' },
  { value: null, text: 'Not sure/Not Applicable', is_na: true },
];
