import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { search as searchPassphrases } from 'js/actions/passphrase-actions';

import HomePageComponent from '../components/HomePageComponent';

class HomePageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleLocateAssessment = this.handleLocateAssessment.bind(this);
  }

  handleLocateAssessment(passphrase) {
    const { searchPassphrases, history } = this.props;

    return searchPassphrases({ passphrase: passphrase.trim() }).then(
      results => {
        if (results.length > 0) {
          history.push(`/assessments/${results[0].assessment_id}/run`);
        } else {
          return { errors: { passphrase: 'Not Found' } };
        }
      }
    );
  }

  render() {
    return (
      <HomePageComponent onLocateAssessment={this.handleLocateAssessment} />
    );
  }
}

export default withRouter(
  connect(
    null,
    {
      searchPassphrases
    }
  )(HomePageContainer)
);
