import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { map } from 'lodash';

import { wordFrequency } from 'js/utils/text';
import { scaleLog } from '@visx/scale';
import { Wordcloud } from '@visx/wordcloud';

import { Link, useRouteMatch } from 'react-router-dom';

const LongTextResults = ({
  question,
  filter,
  activeCohort, // TODO: add support for cohorts
  participantCohortMap = {},
  tag: Tag = 'article',
  className,
  width = 400,
  height = 125,
  margin = {
    top: 7,
    bottom: 5,
    left: 0,
    right: 0,
  },
  ...props
}) => {
  // TODO: move this into styling or color utils
  const colors = ['#28b3d1', '#02758d', '#004958'];
  const {
    params: { assessmentId, assessmentSlug, teamId, workspaceId },
  } = useRouteMatch(
    '/w/:workspaceId/teams/:teamId/assessments/:assessmentSlug/detail/:assessmentId'
  );

  const responses = question.responses;
  const words = useMemo(
    () =>
      map(
        wordFrequency(responses.map(({ value }) => value).join(' ')),
        (value, text) => ({
          text,
          value,
        })
      ),
    [responses]
  );

  const fontScale = useMemo(
    () =>
      scaleLog({
        domain: [
          Math.min(...words.map((w) => w.value)),
          Math.max(...words.map((w) => w.value)),
        ],
        range: [10, 30],
      }),
    [words]
  );

  const fontSizeSetter = useCallback((d) => fontScale(d.value), [fontScale]);

  return (
    <Tag className={classnames(className, 'question-results')} {...props}>
      <div className="question-results-wrapper">
        <div className="question-text">
          <p>{question.text}</p>
        </div>
        <div className="primary-results">
          <svg viewBox={`0 0 ${width} ${height}`}>
            <Wordcloud
              words={words}
              width={width}
              height={height}
              viewBox={`0 0 ${width} ${height}`}
              fontSize={fontSizeSetter}
              font={'Roboto'}
              fontWeight={'bold'}
              padding={2}
              spiral="rectangular"
              rotate={0}
              random={() => 0.2222}
            >
              {(cloudWords) =>
                cloudWords.map((w, i) => {
                  return (
                    <text
                      key={w.text}
                      fill={colors[i % colors.length]}
                      textAnchor={'middle'}
                      transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                      fontSize={w.size}
                      fontWeight={w.weight}
                      fontFamily={w.font}
                    >
                      {w.text}
                    </text>
                  );
                })
              }
            </Wordcloud>
          </svg>
        </div>
        <div className="text-right">
          <Link
            to={`/w/${workspaceId}/teams/${teamId}/assessments/${assessmentSlug}/detail/${assessmentId}/questions/${question.id}`}
            className="info"
          >
            &gt; View detailed results
          </Link>
        </div>
      </div>
    </Tag>
  );
};

export default LongTextResults;
