let id = 0;
export function nextId() {
  return --id;
}

export function spreadKeys(baseKey, suffixes) {
  const spread = {};

  (suffixes || []).forEach(s => (spread[baseKey + s] = null));

  return spread;
}

export function spreadApiKeys(baseKey) {
  return spreadKeys(baseKey, ['', '_SUCCESS', '_ERROR']);
}

export function extractApiKeys(baseKey, collection) {
  return [
    collection[baseKey],
    collection[`${baseKey}_SUCCESS`],
    collection[`${baseKey}_ERROR`]
  ];
}

export function mod(n, m) {
  return ((n % m) + m) % m;
}

export function msToCountdown(s) {
  function padZero(n) {
    return (n < 10 ? '0' : '') + n;
  }

  const ms = s % 1000;
  const secs = Math.ceil((s % 60000) / 1000);
  s = (s - ms) / 1000;
  s = (s - (s % 60)) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  const timeComponents = [];
  if (hrs > 0) {
    timeComponents.push(hrs);
  }
  timeComponents.push(hrs > 0 ? padZero(mins) : mins);
  timeComponents.push(padZero(secs));

  return timeComponents.join(':');
}
