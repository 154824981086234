import * as React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import { useParams } from 'react-router';

const TeamForm = (props) => {
  const {
    autoFocus,
    values,
    touched,
    errors,
    //    dirty,
    //    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    //    handleReset
  } = props;

  const { workspaceId } = useParams();

  const formikAttributes = (fieldName) => ({
    id: fieldName,
    name: fieldName,
    value: values[fieldName],
    onChange: handleChange,
    onBlur: handleBlur,
    invalid: errors[fieldName] && touched[fieldName],
  });

  const formikFeedback = (fieldName) => {
    return errors[fieldName] && touched[fieldName] ? (
      <FormFeedback>{errors[fieldName]}</FormFeedback>
    ) : null;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col sm={4}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              {...formikAttributes('name')}
              autoFocus={autoFocus}
            />
            {formikFeedback('name')}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className={'float-right btn btn-primary'}
            type="submit"
            value="submit"
          >
            Submit
          </Button>
          <Link className={'float-right'} to={`/w/${workspaceId}/teams`}>
            <Button className={'btn btn-secondary'}>Cancel</Button>
          </Link>
        </Col>
      </Row>
    </Form>
  );
};

const FormikTeamForm = withFormik({
  mapPropsToValues: ({ team }) => {
    return {
      name: team.name || '',
    };
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name is required!'),
  }),
  handleSubmit: (values, bag) => {
    const { setSubmitting, props } = bag;
    setSubmitting(true);
    props.onSave(values);
  },
  displayName: 'TeamForm',
})(TeamForm);

FormikTeamForm.propTypes = {
  onSave: PropTypes.func,
  team: PropTypes.object,
  autoFocus: PropTypes.bool,
};

FormikTeamForm.defaultProps = {
  onSave: () => {},
  team: {},
  autoFocus: false,
};

export default FormikTeamForm;
