import React from 'react';

import { Link } from 'react-router-dom';
import { Icon } from 'js/components';
import ResetPasswordForm from './ResetPasswordForm';

const SendInstructions = ({ onReset, resetState }) => {
  return (
    <>
      <div className="result-icon">
        <Icon
          fixedWidth
          icon="lightbulb-on"
          transform="shrink-13 left-1.7 up-2.5"
          mask={['far', 'head-side-brain']}
        />
      </div>
      <div className="result-body">
        <h2>Almost there&hellip;</h2>
        <div>
          <p>
            All that's left is to choose a new password (or let your favorite
            password manager do the hard work for you).
          </p>
          <p>
            <Link to="/pages/login">Wait! I remember it now!</Link>
          </p>
          <ResetPasswordForm onSubmit={onReset} state={resetState} />
        </div>
      </div>
    </>
  );
};

export default SendInstructions;
