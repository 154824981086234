import {
  cultureQuestionSets,
  organizationQuestionSets,
  planningQuestionSets,
  processQuestionSets,
  productQuestionSets,
  technicalQuestionSets
} from './question-sets';

export const sketchCapabilities = {
  name: 'Sketch Capabilities',
  key: 'sketch-capabilities',
  question_sets: [
    ...cultureQuestionSets,
    ...organizationQuestionSets,
    ...planningQuestionSets,
    ...processQuestionSets,
    ...productQuestionSets,
    ...technicalQuestionSets
  ]
};

export default sketchCapabilities;
