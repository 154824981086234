import React, { useCallback, useEffect } from 'react';
import OrgComponent from '../components/OrgComponent';
import {
  lookupTeamParticipation,
  teamScores as lookupTeamScores,
} from '../../../actions/org-actions';
import {
  selectTeamParticipationQuery,
  selectTeamScoresQuery,
} from 'js/store/reducers/queries';
import { noop } from 'lodash';
import { connect } from 'react-redux';
import { notify } from 'js/actions/alert-actions';
import { sendReminders } from 'js/actions/assessment-actions';

function OrgAssessmentContainer({
  organization,
  assessmentHistorySummaries,
  loading,
  error,
  lookupTeamScores,
  orgId,
  assessmentKey,
  teamScoresQuery,
  workspace,
  history,
  match,
  lookupTeamParticipation,
  teamParticipationQuery,
  sendReminders,
  notify,
}) {
  const shouldLoadTeamScores =
    !teamScoresQuery?.data &&
    !teamScoresQuery?.query?.error &&
    teamScoresQuery?.query?.isLoading !== true;

  const shouldLoadTeamParticipation =
    !teamParticipationQuery?.data &&
    !teamParticipationQuery?.query?.error &&
    teamParticipationQuery?.query?.isLoading !== true;

  useEffect(() => {
    if (shouldLoadTeamScores) {
      lookupTeamScores(orgId, assessmentKey).catch(noop);
    }
  }, [lookupTeamScores, orgId, assessmentKey, shouldLoadTeamScores]);

  useEffect(() => {
    if (shouldLoadTeamParticipation) {
      lookupTeamParticipation(orgId, assessmentKey).catch(noop);
    }
  }, [
    lookupTeamParticipation,
    orgId,
    assessmentKey,
    shouldLoadTeamParticipation,
  ]);

  const handleSendReminders = useCallback(
    (assessmentIds, threshold) => {
      return (async () => {
        try {
          await sendReminders(assessmentIds, threshold);
          notify({
            type: 'success',
            message: 'Your reminders are on their way!',
          });
        } catch (e) {
          console.error('Failed to send reminders:', e);
          notify({
            type: 'danger',
            message: "Uh oh! Your reminders didn't get through",
          });
        }
      })();
    },
    [notify, sendReminders]
  );

  return (
    <OrgComponent
      workspace={workspace}
      organization={organization}
      assessmentHistorySummaries={assessmentHistorySummaries}
      loading={loading}
      error={error}
      teamScoresQuery={teamScoresQuery}
      history={history}
      assessmentKey={assessmentKey}
      teamParticipationQuery={teamParticipationQuery}
      match={match}
      onSendReminders={handleSendReminders}
    />
  );
}

export default connect(
  (store, params) => {
    const orgId = params.match.params.orgId;
    const assessmentKey = params.match.params.assessmentKey;
    return {
      orgId,
      assessmentKey,
      workspace: params.workspace,
      teamScoresQuery: selectTeamScoresQuery(
        store,
        `${orgId}-${assessmentKey}`
      ),
      teamParticipationQuery: selectTeamParticipationQuery(
        store,
        `${orgId}-${assessmentKey}`
      ),
    };
  },
  {
    lookupTeamScores,
    lookupTeamParticipation,
    sendReminders,
    notify,
  }
)(OrgAssessmentContainer);
