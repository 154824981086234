import { Button } from 'reactstrap';
import styles from './Product.module.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

function ContactUsProduct({
  product,
  isCurrent,
  children,
  buttonLabel,
  buttonColor = 'secondary',
  onBuy,
  disabled,
  cycle,
  lightMode = false,
}) {
  return (
    <div
      className={classnames([styles.root, lightMode ? styles.lightMode : ''])}
    >
      {isCurrent && <div className={styles.currentBadge}>Current</div>}
      <div>
        <h2>{product.name}</h2>
        <div className={styles.price}>Contact us for pricing</div>
        <div className={styles.description}>{children}</div>
      </div>
      <Link
        className={`btn btn-${buttonColor}`}
        disabled={disabled || isCurrent}
        to="/contact"
      >
        {buttonLabel}
      </Link>
      {/* <Button disabled={disabled || isCurrent} color={buttonColor}>
        {buttonLabel}
      </Button> */}
    </div>
  );
}

export default ContactUsProduct;
