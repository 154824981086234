import React from 'react';
import { PanelHeader } from 'js/components';
import { Card, CardBody, CardTitle } from 'reactstrap';

const SkeletonBillingComponent = () => {
  return (
    <div>
      <PanelHeader size="flex" />
      <div className="content">
        <Card className={'account-billing-skeleton-card'}>
          <CardTitle className="skeleton-card-title">
            <div className="skeleton-title-text skeleton-bone"></div>
          </CardTitle>
          <div className="account-settings-container">
            <div className="account-settings-icon skeleton-bone"></div>
            <div>
              <div className="account-settings">
                <div className="label skeleton-bone"></div>
                <div className="value skeleton-bone"></div>
              </div>
              <div className="account-settings">
                <div className="label skeleton-bone"></div>
                <div className="value skeleton-bone"></div>
              </div>
              <div className="account-settings">
                <div className="label skeleton-bone"></div>
                <div className="value skeleton-bone"></div>
              </div>
            </div>
          </div>
          <CardBody></CardBody>
        </Card>
      </div>
    </div>
  );
};

export default SkeletonBillingComponent;
