import React from 'react';
import NullMarker from '../NullMarker';
import ConditionalLinkWrapper from '../ConditionalLinkWrapper';

const NoResponseQuestionResult = ({
  tag: Tag = 'div',
  width,
  height,
  questionHref,
  parameters,
}) => {
  return (
    <Tag className="assessment-history-question-box-plot">
      <ConditionalLinkWrapper
        wrap={!!questionHref}
        href={questionHref}
        parameters={parameters}
      >
        <NullMarker
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
        />
      </ConditionalLinkWrapper>
    </Tag>
  );
};

export default NoResponseQuestionResult;
