import { general as generalHealthCheck, development as developmentHealthCheck } from './squad-health-check';
import { aceAssessment } from './ace-assessment';
import devopsCapabilitiesSample from './accelerate';
import { sketchCapabilities } from './sketch-capabilities';
import { safeBusinessAgility } from './safe-business-agility';
import { safeAgileProductDelivery } from './safe-agile-product-delivery';

export const assessments = {
  [aceAssessment.key]: aceAssessment,
  [safeBusinessAgility.key]: safeBusinessAgility,
  [safeAgileProductDelivery.key]: safeAgileProductDelivery,
  [developmentHealthCheck.key]: developmentHealthCheck,
  [generalHealthCheck.key]: generalHealthCheck,
  [sketchCapabilities.key]: sketchCapabilities,
  [devopsCapabilitiesSample.key]: devopsCapabilitiesSample,
};

const questionSets = {
  aceAssessment,
  devopsCapabilitiesSample,
};

export default questionSets;
