/* eslint jsx-a11y/anchor-is-valid: "off" */
import React from 'react';

const Account = ({ account }) => {
  return (
    <a data-toggle="collapse">
      <span
        data-test="current-workspace-name"
        className="current-workspace-name"
      >
        {account.name}
      </span>
    </a>
  );
};

export default Account;
