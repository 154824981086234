import React from 'react';
import { connect } from 'react-redux';

import AccountInfoRouteContainer from './AccountInfoRouteContainer';
import GuestAccountRouteContainer from './GuestAccountRouteContainer';

class AccountRouteContainer extends React.Component {
  render() {
    const { me } = this.props;

    return me.is_guest ? (
      <GuestAccountRouteContainer {...this.props} />
    ) : (
      <AccountInfoRouteContainer {...this.props} />
    );
  }
}

export default connect(
  ({ identity: { me } }) => ({ me }),
  {}
)(AccountRouteContainer);
