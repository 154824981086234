import React from 'react';

const StrokedText = ({
  strokeWidth,
  strokeColor,
  className,
  children,
  ...props
}) => {
  return (
    <g>
      <text
        {...props}
        fill={strokeColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        className={className ? `${className}-stroke` : null}
      >
        {children}
      </text>
      <text {...props} className={className}>
        {children}
      </text>
    </g>
  );
};

export default StrokedText;
