import { yesNo } from './base-answers';

export const engineeringPractices = {
  name: 'Agile Engineering Practices',
  key: 'agile-engineering-practices',
  category: 'agile-engineering-practices',
  questions: [
    {
      text: 'Are Architectural decisions consistently made while solutioning and implementing as a team?',
      key: 'architecture-emerges',
      answers: yesNo,
    },
    {
      text: 'Does the team engage in Continuous Delivery using pipeline based DevOps?',
      key: 'team-uses-continuous-delivery',
      answers: yesNo,
    },
    {
      text: 'Is unit testing consistently done as a part of the validation of user stories by the Dev team?',
      key: 'unit-testing-part-of-done',
      answers: yesNo,
    },
    {
      text: 'Does the team use Code Review as a peer review to improve the code quality as part of the Definition of Done?',
      key: 'team-conducts-code-reviews',
      answers: yesNo,
    },
    {
      text: 'Does the Dev Team employ consistent refactoring to bring about efficiency gains in existing systems?',
      key: 'teams-continuously-refactor',
      answers: yesNo,
    },
    {
      text: 'Does the Dev Team (Dev/QA/Team Leads) use Automated Testing?',
      key: 'team-uses-automated-testing',
      answers: yesNo,
    },
    {
      text: 'Do you have sufficient access to the available software tools and utilities to perform your role(s)?',
      key: 'access-to-tools',
      answers: yesNo,
    },
    {
      text: 'Is Automated testing encouraged and implemented where appropriate (Unit tests, System Tests or Regression Tests, UATs)?',
      key: 'automated-testing-is-encouraged',
      answers: yesNo,
    },
  ],
};

export default engineeringPractices;
