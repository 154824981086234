import React from 'react';

import styles from './YearUnderlay.module.scss';

function minmaxDate(comparator, date1, date2) {
  return new Date(comparator(date1, date2));
}

export const YearUnderlay = ({ height, xScale }) => {
  const [minTime, maxTime] = xScale.domain();
  const minYear = minTime.getFullYear();
  const maxYear = maxTime.getFullYear();
  const years = Array.from(
    { length: maxYear - minYear + 1 },
    (_, i) => minYear + i
  );

  return (
    <g className={styles['year-underlay']}>
      {years.map((year, i) => {
        const x1 = xScale(minmaxDate(Math.max, minTime, new Date(year, 0))),
          x2 = xScale(minmaxDate(Math.min, maxTime, new Date(year + 1, 0)));
        const width = x2 - x1;

        return width <= 0 ? null : (
          <g className={styles['year']} key={`year-${year}`}>
            <rect x={x1} y={0} width={width} height={height} />
            {width > 35 && (
              <text
                className="tick-label tick-label-highlight"
                textAnchor="start"
                x={x1 + 7}
                y={15}
              >
                {year}
              </text>
            )}
          </g>
        );
      })}
    </g>
  );
};

export default YearUnderlay;
