import { trueToFalse } from './base-answers';

export const piPlanning = {
  name: 'PI Planning',
  key: 'pi-planning',
  category: 'develop-on-cadence',
  questions: [
    {
      text: 'Everyone on our ART attends PI Planning together',
      key: 'everyone-attends-pi-planning',
      answers: trueToFalse,
    },
    {
      text: 'At the start of PI Planning, Business Owners align the ART to the portfolio strategy.',
      key: 'portfolio-strategy-alignment',
      answers: trueToFalse,
    },
    {
      text: 'At the start of PI Planning the ART receives updates on the performance of the current solutions in the market.',
      key: 'update-on-market-solutions',
      answers: trueToFalse,
    },
    {
      text: 'At the start of PI Planning, Product Management presents the current solution vision.',
      key: 'pi-solution-vision',
      answers: trueToFalse,
    },
    {
      text: 'At the start of PI Planning, System Architecture / Engineering provides clear communication around intentional architectural decisions',
      key: 'architecture-decision-communication',
      answers: trueToFalse,
    },
    {
      text: 'At the start of PI Planning, the ART is briefed on changes to development practices.',
      key: 'changes-to-dev-practices',
      answers: trueToFalse,
    },
    {
      text: 'During PI Planning, the teams are empowered to decide how much work they plan',
      key: 'teams-determine-own-capacity',
      answers: trueToFalse,
    },
    {
      text: 'During PI Planning, teams collaborate to identify dependencies',
      key: 'teams-identify-dependencies',
      answers: trueToFalse,
    },
    {
      text: 'During PI Planning, our ART identifies the work necessary to meet key PI milestones.',
      key: 'art-identifies-work-during-planning',
      answers: trueToFalse,
    },
    {
      text: 'During PI Planning, Business Owners work with teams to assign business value to each of their PI Objectives',
      key: 'business-owners-assign-business-value',
      answers: trueToFalse,
    },
    {
      text: 'Our ART addresses risks throughout PI Planning',
      key: 'risks-are-addressed-in-planning',
      answers: trueToFalse,
    },
    {
      text: 'Throughout PI Planning, our ART provides a safe environment for team members to voice their concerns',
      key: 'pi-planning-psychological-safety',
      answers: trueToFalse,
    },
    {
      text: "By the end of PI Planning, our Teams' PI objectives connect to the business and technical strategy",
      key: 'pi-objectices-align-with-strategy',
      answers: trueToFalse,
    },
    {
      text: 'By the end of PI Planning, our ART has a set of committed PI Objectives.',
      key: 'planning-results-in-committed-objectives',
      answers: trueToFalse,
    },
    {
      text: 'PI Planning is an overall positive experience for our ART.',
      key: 'pi-planning-positive-experience',
      answers: trueToFalse,
    },
  ],
};
