import { trueToFalse as answers } from './base-answers';

export const teamAndTechnical = {
  name: 'Team and Technical Agility (TTA)',
  key: 'team-and-technical-agility',
  category: 'technical',
  questions: [
    {
      text: 'We train our people in Agile',
      key: 'agile-training',
      answers,
    },
    {
      text: 'We organize our people into cross-functional Agile teams',
      key: 'cross-functional-teams',
      answers,
    },
    {
      text: 'The teams apply Agile practices',
      key: 'teams-use-agile-practices',
      answers,
    },
    {
      text: 'We build high-quality solutions',
      key: 'build-high-quality-solutions',
      answers,
    },
    {
      text: 'We organize teams of Agile teams around solutions',
      key: 'teams-organized-around-solutions',
      answers,
    },
    {
      text: 'The teams of Agile teams include the functions necessary to deliver end-to-end value',
      key: 'cross-functional-trains',
      answers,
    },
  ],
};
