import { types as ChannelTypes } from 'js/actions/channel-actions';
import { types as AccountTypes } from 'js/actions/account-actions';

const initialState = {};

const presences = (state = initialState, action) => {
  switch (action.type) {
    case ChannelTypes.PRESENCES_UPDATED:
      const { key, participants } = action;
      state = {
        ...state,
        [key]: participants,
      };
      break;

    case AccountTypes.LOGOUT:
    case AccountTypes.LOGOUT_SUCCESS:
    case 'API_ERROR/INVALID_TOKEN':
      return initialState;

    default:
      // do nothing
      break;
  }

  return state;
};

export default presences;
