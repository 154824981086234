import React, { useState } from 'react';
import { Icon } from 'js/components';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import useWindowDimensions from 'js/utils/window-dimensions-hook';

function TeamNode({ node, onRemove, pathToNode }) {
  const [showMenu, setShowMenu] = useState(false);
  const windowDims = useWindowDimensions();
  return (
    <div className="team-node row">
      <div className="gutters">
        {pathToNode.map((n) => (
          <div
            key={n.depthId}
            className={`gutter ${n.isRoot ? 'org-level' : 'group-level'}`}
          />
        ))}
      </div>
      <div className="icons">
        <Icon className="row-icon team-icon" icon="users" />
      </div>
      <div className="row-content">
        <div className="name">{node.name}</div>
        <div className="actions">
          <Dropdown
            size="sm"
            isOpen={showMenu}
            toggle={() => setShowMenu(!showMenu)}
          >
            <DropdownToggle className="btn btn-link">
              <Icon icon={['far', 'ellipsis-h']} />
            </DropdownToggle>
            <DropdownMenu right={windowDims.width <= 600}>
              <DropdownItem
                data-test="remove-dropdown"
                onClick={() => onRemove(pathToNode, node)}
              >
                <Icon icon="trash-alt" className="mx-1" /> Remove
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default TeamNode;
