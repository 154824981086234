import * as React from 'react';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import { PanelHeader } from 'js/components';
import CreateAssessmentTemplate from 'js/components/Assessment/CreateAssessmentTemplate';

// just a placeholder for now
const CreateAssessmentsComponent = ({ templates, onCreateAssessment }) => {
  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h3">
              Which assessment would you like to conduct?
            </CardTitle>
          </CardHeader>
          <CardBody>
            <p>Select from the choices below.</p>
          </CardBody>
        </Card>
        {templates.map(template => (
          <CreateAssessmentTemplate
            key={template.id}
            onSelect={onCreateAssessment.bind(null, template)}
            template={template}
          />
        ))}
      </div>
    </div>
  );
};

CreateAssessmentsComponent.defaultProps = {
  onCreateAssessment: () => {}
};

export default CreateAssessmentsComponent;
