import _ from 'lodash';
import { normalize } from 'normalizr';
import { BEGIN, COMMIT, REVERT } from 'redux-optimist';
import { nextId } from 'js/utils';
import { SCHEMAS as schemaDefinitions } from './schemas';
import { notify } from 'js/actions/alert-actions';

let apiConfig = {
  getAuthToken: () => null,
  getCurrentWorkspaceId: () => null,
};

export function configureApi(config) {
  apiConfig = {
    ...apiConfig,
    ...config,
  };
}

export const SCHEMAS = schemaDefinitions;

export const noSchemaQuerySuccessMutator =
  (meta) => (action, _reqData, respData, status) => {
    return {
      ...action,
      status,
      data: { result: respData },
      meta,
    };
  };

export function callApi(config, dispatch = () => {}) {
  const { types, request, optimistic = true } = config;
  const mutators = config.mutators || [];
  const requestData = config.data;
  const [requestType, successType, errorType] = types;

  let [mutateRequest, mutateSuccess, mutateError] = mutators;

  mutateRequest =
    mutateRequest ||
    ((action, reqData, status) => {
      return { ...action, status, data: reqData, meta: config.meta };
    });
  mutateSuccess =
    mutateSuccess ||
    ((action, reqData, respData, status) => {
      return {
        ...action,
        status,
        data: respData,
        meta: { ...config.meta, request: reqData },
      };
    });
  mutateError =
    mutateError ||
    ((action, reqData, errData, status) => {
      return {
        ...action,
        status,
        error: errData,
        meta: { ...config.meta, request: reqData },
      };
    });

  let optimistRequest = {};
  let optimistRevert = {};
  let optimistCommit = {};

  // if we use optimist
  if (optimistic === true) {
    const baseOptimist = { id: nextId() };
    optimistRequest = {
      optimist: { ...baseOptimist, type: BEGIN },
      optimistic,
    };
    optimistCommit = {
      optimist: { ...baseOptimist, type: COMMIT },
      optimistic,
    };
    optimistRevert = {
      optimist: { ...baseOptimist, type: REVERT },
      optimistic,
    };
  }

  const initialStatus = {
    isLoading: true,
    startedAt: Date.now(),
    finishedAt: null,
  };

  dispatch({
    ...mutateRequest({ type: requestType }, requestData, initialStatus),
    ...optimistRequest,
  });

  return request(
    requestData,
    apiConfig.getAuthToken(),
    apiConfig.getCurrentWorkspaceId()
  ).then(
    (resp) => {
      const data = resp.data?.data ? resp.data.data : resp.data;
      const baseAction = data?.token ? { token: data.token } : {};

      let responseData = data || {};

      if (config.schema) {
        responseData = normalize(data, config.schema);
      }
      dispatch({
        ...mutateSuccess(
          { ...baseAction, type: successType },
          requestData,
          responseData,
          {
            ...initialStatus,
            isLoading: false,
            finishedAt: Date.now(),
          }
        ),
        ...optimistCommit,
      });

      if (config.responseCallback) {
        config.responseCallback(resp);
      }

      return data;
    },
    (error) => {
      if (error?.response?.data?.message) {
        dispatch({
          type: `API_ERROR/${error.response.data.message.toUpperCase()}`,
          data: error?.response?.status,
        });
      }

      // map any error messages to dispatch
      const { errorMessages } = config;
      if (errorMessages && error?.data?.errors) {
        const mappedMessages = _.flatten(
          _.map(error?.response?.data?.errors, (errors, field) => {
            const messages = [];

            errors.forEach((message) => {
              if (errorMessages[field] && errorMessages[field][message]) {
                messages.push(errorMessages[field][message]);
              }
            });

            return messages;
          })
        );

        if (mappedMessages.length === 1) {
          dispatch(
            notify({
              type: 'danger',
              message: mappedMessages[0],
            })
          );
        } else if (mappedMessages.length > 1) {
        }
      }

      dispatch({
        ...mutateError({ type: errorType }, requestData, error, {
          ...initialStatus,
          isLoading: false,
          finishedAt: Date.now(),
        }),
        ...optimistRevert,
      });

      return Promise.reject(error);
    }
  );
}
