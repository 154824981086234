export function mergeSubmissionErrorsWithFormik(
  submissionErrors,
  submissionValues,
  formikErrors,
  formikValues,
  fieldOptions = {}
) {
  // fieldOptions control whether a field should only be considered a
  // submission error if its current (formik) value is the same as the
  // submission value. The default is true

  submissionErrors = submissionErrors ?? {};
  submissionValues = submissionValues ?? {};

  const filteredSubmissionErrors = Object.keys(submissionErrors).reduce(
    (acc, key) => {
      const isErrorValid =
        !(fieldOptions[key] ?? true) ||
        formikValues[key] === submissionValues[key];

      return isErrorValid
        ? {
            ...acc,
            [key]: submissionErrors[key],
          }
        : acc;
    },
    {}
  );

  return {
    ...filteredSubmissionErrors,
    ...formikErrors,
  };
}
