import keymirror from 'keymirror';
import { request } from 'js/api/axios';

import { spreadApiKeys, extractApiKeys } from '../utils';
import { callApi } from '../api';
import { QueryBase } from 'js/store/reducers/queries';
import { SCHEMAS } from 'js/api/schemas';

export const types = keymirror({
  ...spreadApiKeys('CREATE_ASSESSMENT_INVITES'),
  ...spreadApiKeys('LIST_ASSESSMENT_INVITES'),
});

export function createAssessmentInvites(
  workspaceId,
  teamId,
  assessmentId,
  message,
  invites
) {
  return async (dispatch, getState) => {
    const data = { invites, message };

    return callApi(
      {
        types: extractApiKeys('CREATE_ASSESSMENT_INVITES', types),
        request: (data, token) =>
          request.post(
            `/api/workspaces/${workspaceId}/teams/${teamId}/assessments/${assessmentId}/invites`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data,
      },
      dispatch,
      getState
    );
  };
}

export function listAssessmentInvites(workspaceId, teamId, assessmentId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LIST_ASSESSMENT_INVITES', types),
        request: (_data, token) =>
          request.get(
            `/api/workspaces/${workspaceId}/teams/${teamId}/assessments/${assessmentId}/invites`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        schema: SCHEMAS.ASSESSMENT_INVITES_ARRAY,
        meta: { queryId: `${QueryBase.AssessmentInvites}-${assessmentId}` },
      },
      dispatch
    );
  };
}
