import React, { useMemo } from 'react';

import { monogram } from 'js/utils/string';

import MultipleAccounts from './MultipleAccounts';
import SingleAccount from './SingleAccount';

const Accounts = ({ activeAccount, accounts }) => {
  const sortedAccounts = useMemo(
    () =>
      [...accounts].sort((a, b) =>
        a.name?.toUpperCase()?.localeCompare(b.name?.toUpperCase())
      ),
    [accounts]
  );

  return (
    <div className="user">
      <div className="moniker">Account</div>
      <div className="photo">
        <div className="mongram-container">
          <div className="monogram">
            {monogram(activeAccount.name, 2, 2).toUpperCase()}
          </div>
        </div>
      </div>
      <div className="info">
        {accounts.length > 1 ? (
          <MultipleAccounts
            accounts={sortedAccounts}
            activeAccount={activeAccount}
          />
        ) : (
          <SingleAccount account={activeAccount} />
        )}
      </div>
    </div>
  );
};
/*
renderWorkspaces() {
  const { me, activeWorkspace } = this.props;
  const { workspaces } = me;
  const sortedWorkspaces = [...workspaces].sort((a, b) =>
    a.name?.toUpperCase()?.localeCompare(b.name?.toUpperCase())
  );

  const renderSingleWorkspace = () => {
    return (
      <a data-toggle="collapse">
        <span
          data-test="current-workspace-name"
          className="current-workspace-name"
        >
          {activeWorkspace.name}
        </span>
      </a>
    );
  };

  const renderMultipleWorkspaces = () => {
    return (
      <>
        <a
          data-toggle="collapse"
          aria-expanded={this.state.openAvatar}
          onClick={() =>
            this.setState({ openAvatar: !this.state.openAvatar })
          }
        >
          <span data-test="current-workspace-name">
            <span className="current-workspace-name">
              {activeWorkspace.name}
            </span>
            <b className="caret" />
          </span>
        </a>
        <Collapse isOpen={this.state.openAvatar}>
          <ul className="nav">
            {sortedWorkspaces.map((workspace) => (
              <li key={workspace.id}>
                <Link
                  className="btn-link"
                  to={`/w/${workspace.id}/dashboard`}
                  onClick={() => this.setState({ openAvatar: false })}
                >
                  <span className="sidebar-mini-icon">
                    {monogram(workspace.name, 2, 2).toUpperCase()}
                  </span>
                  <span className="sidebar-normal workspace-name">
                    {workspace.name}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </Collapse>
      </>
    );
  };
  */

export default Accounts;
