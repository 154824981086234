import React from 'react';
import { noop } from 'lodash';
import Marker from './Marker';

const Markers = ({
  data,
  onHoverMarker = noop,
  onUnhoverMarker = noop,
  onClickMarker = noop,
  ...props
}) => {
  return (
    <g>
      {data.map((d) => {
        return (
          <Marker
            key={d.id}
            d={d}
            {...props}
            onHover={onHoverMarker}
            onUnhover={onUnhoverMarker}
            onClick={onClickMarker}
          />
        );
      })}
    </g>
  );
};

export default Markers;
