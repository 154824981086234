import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormFeedback
} from 'reactstrap';
import * as Yup from 'yup';
import { titleCase } from 'js/utils/string';

const UserForm = props => {
  const {
    state,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit
    //    handleReset
  } = props;

  const formikAttributes = (fieldName, errorFieldName) => {
    errorFieldName = errorFieldName || fieldName;

    return {
      id: fieldName,
      name: fieldName,
      value: values[fieldName],
      onChange: handleChange,
      onBlur: handleBlur,
      invalid:
        !!(state.error && state.error[errorFieldName]) ||
        (errors[fieldName] && touched[fieldName])
    };
  };

  const feedback = (fieldName, errorFieldName) => {
    errorFieldName = errorFieldName || fieldName;

    const messages = [];

    if (errors[fieldName] && touched[fieldName]) {
      messages.push(
        <FormFeedback key={errors[fieldName]}>{errors[fieldName]}</FormFeedback>
      );
    }

    if (state.error && state.error[fieldName]) {
      state.error[fieldName].forEach(message => {
        messages.push(
          <FormFeedback key={message}>
            {titleCase(fieldName)} {message}
          </FormFeedback>
        );
      });
    }

    return messages;
  };

  const renderErrors = () => {
    if (state.error) {
      return (
        <div>
          <ul>
            {_.map(state.error, (messages, fieldKey) => {
              return messages.map(message => {
                return (
                  <li
                    className="invalid-feedback"
                    key={`${fieldKey}${message}`}
                  >
                    {titleCase(fieldKey)} {message}
                  </li>
                );
              });
            })}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {renderErrors()}
      <Row>
        <Col sm="12" md="4">
          <FormGroup>
            <Label for="name">Username</Label>
            <Input type="text" {...formikAttributes('username')} />
            {feedback('username')}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="4">
          <FormGroup>
            <Label for="size">Password</Label>
            <Input type="password" {...formikAttributes('password')} />
            {feedback('password')}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="4">
          <FormGroup>
            <Label for="team_size">Re-enter password</Label>
            <Input type="password" {...formikAttributes('confirmPassword')} />
            {feedback('confirmPassword')}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="4">
          <Button
            className={'float-right btn btn-primary'}
            type="submit"
            value="submit"
          >
            Submit
          </Button>
          <Link className={'float-right'} to={'/settings/users'}>
            <Button className={'btn btn-secondary'}>Cancel</Button>
          </Link>
        </Col>
      </Row>
    </Form>
  );
};

const FormikUserForm = withFormik({
  mapPropsToValues: ({ user }) => {
    return {
      username: user.username || '',
      password: '',
      confirmPassword: ''
    };
  },
  validationSchema: Yup.object().shape({
    username: Yup.string().required('Username is required!'),
    password: Yup.string().required('Password is required!'),
    confirmPassword: Yup.string().test(
      'match',
      'Passwords do not match',
      function(password) {
        return password === this.options.parent.password;
      }
    )
  }),
  handleSubmit: (values, bag) => {
    const { setSubmitting, props } = bag;
    // do nothing
    setSubmitting(true);
    props.onSave({
      username: values.username,
      password: values.password
    });

    //    setSubmitting(false);
  },
  displayName: 'UserForm'
})(UserForm);

FormikUserForm.propTypes = {
  onSave: PropTypes.func,
  user: PropTypes.object
};

FormikUserForm.defaultProps = {
  onSave: () => {},
  user: {}
};

export default FormikUserForm;
