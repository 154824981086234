export const metricsQuestionSets = [
  {
    name: 'Lightweight change approval process',
    key: 'lightweight-change-approval',
    category: 'metrics',
    questions: [
      {
        text: 'Our change approval process can best be described as:',
        key: 'change-approval-centralization',
        answers: [
          { value: '2', text: 'All production changes must be approved by an external body (e.g. a manager or Change Advisory Board)' },
          { value: '3', text: 'Only high-risk changes, such as database or infrastructure changes, require approval by an external body' },
          { value: '4', text: 'We use peer review (e.g. pair programming, code reviews) to manage changes' },
          { value: '1', text: 'We have no change approval process' }
        ]
      },
      {
        text: 'The change approval process necessary to modify production systems often takes:',
        key: 'change-approval-time',
        answers: [
          { value: '5', text: 'Less than one hour ' },
          { value: '4', text: 'Between one hour and one day' },
          { value: '3', text: 'Between one day and one week' },
          { value: '2', text: 'Between one week and one month' },
          { value: '1', text: 'More than one month' }
        ]
      }
    ]
  },
  {
    name: 'Monitoring',
    key: 'monitoring',
    category: 'metrics',
    questions: [
      {
        text: 'Application performance and infrastructure monitoring metrics are',
        key: 'metrics-completeness',
        answers: [
          { value: '1', text: 'Not captured or known ' },
          { value: '2', text: 'Available only to production support teams and managers' },
          { value: '3', text: 'Available to teams via regularly generated reports' },
          { value: '4', text: 'Easily accessible to teams at any time via dashboards, but not publicly displayed ' },
          { value: '5', text: "Displayed live on public displays in the team's area" }
        ]
      },
      {
        text: 'We use data from application performance and infrastructure monitoring tools to make business decisions on a daily basis',
        key: 'data-based-decisions',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We incorporate monitoring metrics in business objectives and goals',
        key: 'metrics-goals',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Proactive system health checks',
    key: 'system-health-checks',
    category: 'metrics',
    questions: [
      {
        text: 'We are usually informed of system failures by',
        key: 'failure-alerts',
        answers: [
          { value: '5', text: 'Automated alerts sent directly to team members by the monitoring system(s)' },
          { value: '4', text: 'Noticing anomalies on dashboards' },
          { value: '3', text: 'Being notified by a production support team' },
          { value: '2', text: 'Customer complaints' },
          { value: '1', text: 'Discovery by the team when performing other work' }
        ]
      },
      {
        text: 'We identify system degredation and do respond before issues become critical',
        key: 'degredation-alerts',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Improve WIP limits',
    key: 'wip-limits',
    category: 'metrics',
    questions: [
      {
        text: 'Our team usually has:',
        key: 'wip-development',
        answers: [
          { value: '5', text: '1-2 items in progress (started, but not completed) at a time' },
          { value: '4', text: '3-5 items in progress at a time' },
          { value: '3', text: '6-10 items in progress at a time' },
          { value: '2', text: '11-15 items in progress at a time' },
          { value: '1', text: 'More than 15 items in progress at a time' }
        ]
      },
      {
        text: 'Before a release, our team usually has:',
        key: 'wip-unreleased',
        answers: [
          { value: '6', text: '1 item completed and waiting to deploy (i.e. work is deployed automatically through CI/CD)' },
          { value: '5', text: '2-5 items completed and waiting to deploy' },
          { value: '4', text: '6-10 items completed and waiting to deploy' },
          { value: '3', text: '11-20 items completed and waiting to deploy' },
          { value: '2', text: '20-30 items completed and waiting to deploy' },
          { value: '1', text: 'More than 30 items completed and waiting to deploy' }
        ]
      },
      {
        text: 'We have established WIP limits on each stage in the flow of work (e.g. analysis, dev, test, review)',
        key: 'wip-bounds',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We have a process to help us identify bottlenecks in our flow of work',
        key: 'bottlenecks',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We use process improvement techniques to eliminate bottlenecks and achieve improved throughput',
        key: 'process-improvement-flow',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Visualizing work to monitor quality and communicate throughout the team',
    key: 'visualizing-work',
    category: 'metrics',
    questions: [
      {
        text: 'We use tools like kanban and storyboards to organize our work',
        key: 'work-organization',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We use visual displays or dashboards to share information on completed, current and upcoming work',
        key: 'information-radiators',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Quality metrics (e.g. failure and defect rates) are:',
        key: 'quality-metrics',
        answers: [
          { value: '1', text: 'Not captured or known' },
          { value: '2', text: 'Available only to production support teams and managers' },
          { value: '3', text: 'Available to teams via regularly generated reports' },
          { value: '4', text: 'Easily accessible to teams at any time via dashboards, but not publicly displayed ' },
          { value: '5', text: "Displayed live on public displays in the team's area" }
        ]
      },
      {
        text: 'Productivity metrics (e.g. burndown/burnup charts, say-do ratios, churn) are:',
        key: 'productivity-metrics',
        answers: [
          { value: '1', text: 'Not captured or known' },
          { value: '2', text: 'Available only to managers' },
          { value: '3', text: 'Available to teams via regularly generated reports' },
          { value: '4', text: 'Easily accessible to teams at any time via dashboards, but not publicly displayed ' },
          { value: '5', text: "Displayed live on public displays in the team's area" }
        ]
      }
    ]
  }
];

export default metricsQuestionSets;
