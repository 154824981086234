import { wowAnswers } from './base-answers';

export const estimating = [
  {
    name: 'Estimating',
    key: 'estimating',
    category: 'estimating',
    questions: [
      {
        text: 'PO is available when the team is estimating',
        key: 'po—available-wow',
        answers: wowAnswers,
      },
      {
        text: 'Dev and QA on the team are participating in providing a single estimate',
        key: 'qa-participates-wow',
        answers: wowAnswers,
      },
      {
        text: 'The team is estimating in relative size (points) rather than time',
        key: 'relative-size-wow',
        answers: wowAnswers,
      },
      {
        text: 'The team uses Fibonacci scale for pointing',
        key: 'pointing-scale-wow',
        answers: wowAnswers,
      },
      {
        text: 'The team uses Slack planning poker (or similar app) for estimating',
        key: 'planning-poker-wow',
        answers: wowAnswers,
      },
    ],
  },
];
