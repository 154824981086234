import React from 'react';

export const ArrowMarker = ({ id, ...props }) => {
  return (
    <marker
      id={id}
      markerWidth={7}
      markerHeight={7}
      refX={5}
      refY={3.5}
      orient="auto-start-reverse"
    >
      <polygon
        points="0 0, 7 3.5, 0 7"
        className="arrow-marker"
        strokeLinecap="round"
      />
    </marker>
  );
};

export default ArrowMarker;
