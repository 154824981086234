import React from 'react';
import {
  maxTeamsPerAccount,
  allowAutoscalingLicenses,
} from 'js/utils/entitlement-utils';
import { isPastDue, isUnpaidRecently } from 'js/utils/account-utils';
import PastDueMessage from './PastDueMessage';
import UnpaidMessage from './UnpaidMessage';

// NOTE: This should probably work like alerts where something else queues messages,
// and this just reads those and manages dismissal. For now, there's only one global
// message, so just handle it all here (in a very hacky and inefficient way)
const GlobalMessages = ({ activeAccount, me }) => {
  const messages = [];

  if (!activeAccount) {
    return null;
  }

  const maxTeams = maxTeamsPerAccount(activeAccount);
  const activeTeams = activeAccount?.statistics?.active_teams_count;
  const productKey = activeAccount?.product?.key;

  if (isUnpaidRecently(activeAccount)) {
    messages.push({
      type: 'warning',
      content: <UnpaidMessage me={me} activeAccount={activeAccount} />,
    });
  }

  if (
    maxTeams <= activeTeams &&
    productKey !== 'free' &&
    !allowAutoscalingLicenses(activeAccount)
  ) {
    messages.push({
      type: 'danger',
      content: (
        <>
          You've hit the team limit on your current plan. Give your account
          admin a nudge and ask them to add some more licenses.
        </>
      ),
    });
  }

  if (isPastDue(activeAccount)) {
    messages.push({
      type: 'warning',
      content: <PastDueMessage me={me} activeAccount={activeAccount} />,
    });
  }

  if (messages.length === 0) {
    return null;
  }

  const message = messages[0];

  return (
    <div className="global-messages">
      <div className={`alert alert-${message.type}`}>{message.content}</div>
    </div>
  );
};

export default GlobalMessages;
