import { Loading } from 'js/components';
import { useEffect, useState } from 'react';
import {
  createPurchaseRequest,
  list as listProducts,
} from 'js/actions/product-actions';
import { SCHEMAS } from 'js/api/schemas';
import { denormalize } from 'normalizr';
import { connect } from 'react-redux';
import PurchaseRequestError from '../components/PurchaseRequestError';

function PurchaseProductContainer({
  product,
  createPurchaseRequest,
  listProducts,
  priceId,
}) {
  const [error, setError] = useState();
  const productId = product?.id;

  useEffect(() => {
    listProducts().catch((e) => {
      console.error('Failed to lookup the product', e);
      setError(true);
    });
  }, [listProducts, setError]);

  useEffect(() => {
    (async () => {
      try {
        if (productId) {
          const result = await createPurchaseRequest(productId, priceId);
          window.location = result.checkout_session_url;
        }
      } catch (e) {
        console.error('Failed to create a checkout session:', e);
        setError(true);
      }
    })();
  }, [createPurchaseRequest, productId, priceId, setError]);

  if (error) {
    return <PurchaseRequestError />;
  } else {
    return <Loading />;
  }
}

export default connect(
  (store, props) => ({
    product: denormalize(
      store.entities.products?.[props.match.params.id],
      SCHEMAS.PRODUCT,
      store.entities
    ),
    priceId: props.match.params.priceId,
    me: denormalize(store.identity.me, SCHEMAS.ME, store.entities),
  }),
  {
    createPurchaseRequest,
    listProducts,
  }
)(PurchaseProductContainer);
