export const technicalQuestionSets = [
  {
    name: 'Version Control',
    key: 'key',
    category: 'technical',
    questions: [
      {
        text: 'Our application code is in a version control system',
        key: 'version-control-source',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Our system configurations are in a version control system',
        key: 'version-control-system-configs',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Our application configurations are in a version control system',
        key: 'version-control-app-configs',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Our scripts for automating builds and configuration are in a version control system',
        key: 'version-control-builds',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Deployment',
    key: 'deployment',
    category: 'technical',
    questions: [
      {
        text: 'We deploy:',
        key: 'deploy-rate',
        answers: [
          { value: '6', text: 'On demand (multiple times per day)' },
          { value: '5', text: 'Between once per hour and once per day' },
          { value: '4', text: 'Between once per day and once per week' },
          { value: '3', text: 'Between once per week and once per month' },
          { value: '2', text: 'Between once per month and once every six months' },
          { value: '1', text: 'Fewer than once every six months' }
        ]
      },
      {
        text: 'Lead time for changes',
        key: 'change-lead-time',
        answers: [
          { value: '6', text: 'Less than one hour' },
          { value: '5', text: 'Less than one day' },
          { value: '4', text: 'Between one day and one week' },
          { value: '3', text: 'Between one week and one month' },
          { value: '2', text: 'Between one month and six months' },
          { value: '1', text: 'More than six months' }
        ]
      },
      {
        text: 'Mean time to recovery',
        key: 'mean-time-to-recovery',
        answers: [
          { value: '6', text: 'Less than one hour' },
          { value: '5', text: 'Less than one day' },
          { value: '4', text: 'Between one day and one week' },
          { value: '3', text: 'Between one week and one month' },
          { value: '2', text: 'Between one month and six months' },
          { value: '1', text: 'More than six months' }
        ]
      },
      {
        text: 'When we make changes, we encounter errors',
        key: 'change-failure-rate',
        answers: [{ value: '5', text: '0-15%' }, { value: '4', text: '16-30%' }, { value: '3', text: '31-45%' }, { value: '2', text: '46-60%' }, { value: '1', text: '>60%' }]
      }
    ]
  },
  {
    name: 'Continuous Integration',
    key: 'continuous-integration',
    category: 'technical',
    questions: [
      {
        text: 'Code under active development is usually committed',
        key: 'code-commit-interval',
        answers: [
          { value: '5', text: 'Multiple times per day' },
          { value: '4', text: 'Once per day' },
          { value: '3', text: 'Between once per day and once per week' },
          { value: '2', text: 'Between once per week and once per month' },
          { value: '1', text: 'Less than once per month' }
        ]
      },
      {
        text: 'Each checkin triggers a set of quick automated tests',
        key: 'automated-tests-on-commit',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'If the automated build or tests fail, developers fix the issues immediately',
        key: 'broken-build-response',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'The build process creates canonical artifacts that are used as-is for deployments',
        key: 'canonical-build-artifacts',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Trunk-based development',
    key: 'trunk-based-development',
    category: 'technical',
    questions: [
      {
        text: 'How many active branches exist in your source code repository',
        key: 'active-branch-count',
        answers: [{ value: '5', text: '1-2' }, { value: '4', text: '3-5' }, { value: '3', text: '6-9' }, { value: '2', text: '10-15' }, { value: '1', text: 'More than 15' }]
      },
      {
        text: 'Before being merged into trunk, source control branches usally live for',
        key: 'branch-lifetime',
        answers: [
          { value: '5', text: 'Less than one day' },
          { value: '4', text: 'Between one day and one week' },
          { value: '3', text: 'Between one week and one month' },
          { value: '2', text: 'Between one month and six months' },
          { value: '1', text: 'More than six months' }
        ]
      },
      {
        text: 'We are always able to commit code to the main branch if ready and never have code freezes',
        key: 'code-commit-safety',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Test automation',
    key: 'test-automation',
    category: 'technical',
    questions: [
      {
        text: 'Devs write unit tests as part of delivery',
        key: 'unit-tests',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Devs write integration and functional tests as part of delivery',
        key: 'integration-tests',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'When our tests pass, we are confident that the software is releasable',
        key: 'trust-in-automated-tests-passing',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: "When our tests fail, we are confident that the software has a real defect and that the failure isn't a false positive",
        key: 'trust-in-automated-tests-failing',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Test data management',
    key: 'test-data-management',
    category: 'technical',
    questions: [
      {
        text: 'We have adequate data to run our test suite',
        key: 'enough-data',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We can acquire or generate test data on demand',
        key: 'test-data-generation',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We can condition test data in our CI/CD pipeline so test runs can operate independently',
        key: 'test-data-isolation',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Our test data does not limit the amount of or type of tests we can run',
        key: 'test-data-completeness',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Shifting left on security',
    key: 'security',
    category: 'technical',
    questions: [
      {
        text: 'We regularly conduct security reviews of applications',
        key: 'review-frequency',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'The infosec team is involved in the design and demo process for applications',
        key: 'security-involvement',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We use preapproved security libraries and packages',
        key: 'security-libraries',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Security tests are integrated into our automated testing suite',
        key: 'automated-security-tests',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Security practices do not slow down our development process',
        key: 'security-as-impediment',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  },
  {
    name: 'Continuous delivery',
    key: 'continuous-delivery',
    category: 'technical',
    questions: [
      {
        text: 'Our software is always in a deployable state',
        key: 'always-deployable',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We prioritize keeping the software in a deployable state over adding new features',
        key: 'stability-over-new-development',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We receive fast feedback on the quality and deployability of the system',
        key: 'fast-quality-feedback',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'We can deploy our system to production or end users on demand at any time of day with negligible down time',
        key: 'deploy-any-time',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      },
      {
        text: 'Fixes are made quickly if the system is in an undeployable state',
        key: 'fix-deployment-blockers-fast',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' }
        ]
      }
    ]
  }
];

export default technicalQuestionSets;
