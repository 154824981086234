import React from 'react';
import moment from 'moment';
import { Icon } from 'js/components';
import StrokedText from './StrokedText';
import NodeFill from './NodeFill';
import NullFill from './NullFill';
import { UncontrolledPopover } from 'reactstrap';
import NodePopover from 'js/routes/Organizations/components/OrgComponent/Visualizations/Participation/Nodes/NodePopover';

const TeamNode = ({ node, width, height, maskId, onSendReminders }) => {
  const iconSize = 10;

  const {
    data: { assessmentData, name, id, workspaceId, assessmentKey },
  } = node;

  const {
    assessment_date,
    invitations,
    in_progress,
    completed,
    assessment_id,
  } = assessmentData;

  const fillStats = {
    notStarted: invitations - in_progress - completed,
    partial: in_progress,
    completed,
  };

  const assessmentUrl = `/w/${workspaceId}/teams/${id}/assessments/${assessmentKey}/detail/${assessment_id}`;

  const Fill =
    invitations > 0 ? (
      <NodeFill maskId={maskId} width={width} height={height} {...fillStats} />
    ) : (
      <NullFill width={width} height={height} />
    );

  return (
    <g
      transform={`translate(${node.y - width / 2},${node.x - height / 2})`}
      className="team-node"
    >
      <g id={`UncontrolledPopover-${id}`}>{Fill}</g>
      {assessment_id && (
        <UncontrolledPopover
          placement="right"
          target={`UncontrolledPopover-${id}`}
          trigger="hover"
        >
          <NodePopover
            name={name}
            assessmentUrl={assessmentUrl}
            disableReminders={invitations - completed === 0}
            onSendReminders={() => onSendReminders(node.data)}
          />
        </UncontrolledPopover>
      )}
      <g
        transform={`translate(${-4 - iconSize}, ${height / 2 - iconSize - 3})`}
      >
        <svg
          viewBox={`0 0 ${iconSize} ${iconSize}`}
          width={iconSize}
          height={iconSize}
        >
          <Icon icon="envelope" className="icon-invites" transform="shrink-3" />
        </svg>
        <StrokedText
          textAnchor="end"
          fill="#fff"
          x="-3"
          y={iconSize / 2 + 1}
          dominantBaseline="middle"
          fontSize={iconSize}
          strokeColor="#2c2c2c"
          strokeWidth="3"
        >
          {assessment_date ? invitations : 'N/A'}
        </StrokedText>
      </g>
      <g transform={`translate(${-4 - iconSize}, ${height / 2 + 3})`}>
        <svg
          viewBox={`0 0 ${iconSize} ${iconSize}`}
          width={iconSize}
          height={iconSize}
        >
          <Icon
            icon={['far', 'calendar-alt']}
            className="icon-assessment-date"
            transform="shrink-3"
          />
        </svg>
        <StrokedText
          textAnchor="end"
          fill="#fff"
          x="-3"
          y={iconSize / 2 + 1}
          dominantBaseline="middle"
          fontSize={iconSize}
          strokeColor="#2c2c2c"
          strokeWidth="3"
        >
          {assessment_date
            ? moment(assessment_date).format('MMM D, yyyy')
            : 'N/A'}
        </StrokedText>
      </g>
      <g transform={`translate(${width + 4}, ${height / 2})`}>
        <StrokedText
          className="node-name"
          textAnchor="start"
          dominantBaseline="middle"
          fontSize={iconSize}
          strokeColor="#2c2c2c"
          strokeWidth="3"
        >
          {name}
        </StrokedText>
      </g>
    </g>
  );
};

export default TeamNode;
