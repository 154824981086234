import { agreeToDisagree } from './base-answers';

export const changeReadinessQuestionSets = [
  {
    name: 'Vision & Value',
    key: 'vision-and-value',
    category: 'change-readiness',
    questions: [
      {
        text: 'I understand how Operation Model Transformation (OMT) will help achieve its business goals.',
        key: 'understand-op-model-business-goals',
        answers: agreeToDisagree,
      },
      {
        text: 'I can describe the vision and objectives of Operation Model Transformation (OMT).',
        key: 'understand-op-model-vision',
        answers: agreeToDisagree,
      },
      {
        text: 'I understand the value of Operation Model Transformation (OMT) and its implications on my work.',
        key: 'understand-op-model-implications',
        answers: agreeToDisagree,
      },
      {
        text: 'I clearly understand what improvements in operational performance are needed.',
        key: 'understand-needed-op-performance-improvements',
        answers: agreeToDisagree,
      },
    ],
  },
  {
    name: 'Leadership & Sponsorship',
    key: 'leadership-and-sponsorship',
    category: 'change-readiness',
    questions: [
      {
        text: 'I believe that my direct manager supports the implementation of Operation Model Transformation (OMT).',
        key: 'support-from-direct-manager',
        answers: agreeToDisagree,
      },
      {
        text: 'I believe the Senior leadership Team (SLT) leadership holds itself accountable for the changes needed to implement Operation Model Transformation (OMT).',
        key: 'slt-holds-itself-accountable',
        answers: agreeToDisagree,
      },
      {
        text: 'I believe Business leadership has allocated the appropriate resources to support this program.',
        key: 'appropriate-resources-allocated',
        answers: agreeToDisagree,
      },
    ],
  },
  {
    name: 'Communications & Engagement',
    key: 'communications-and-engagement',
    category: 'change-readiness',
    questions: [
      {
        text: 'The Operation Model Transformation (OMT) Implementation Team currently uses effective methods of communication to give me information and updates.',
        key: 'effective-communication-on-transformation',
        answers: agreeToDisagree,
      },
      {
        text: 'I know whom to contact if I have feedback about Operation Model Transformation (OMT).',
        key: 'who-to-contact-with-transformation-feedback',
        answers: agreeToDisagree,
      },
      {
        text: 'I receive sufficient information about Operation Model Transformation (OMT) (enough to do my job).',
        key: 'receive-enough-information-about-transformation',
        answers: agreeToDisagree,
      },
      {
        text: 'My direct manager provides regular updates on Operation Model Transformation (OMT).',
        key: 'manager-provides-transformation-updates',
        answers: agreeToDisagree,
      },
    ],
  },
  {
    name: 'Training & Preparation',
    key: 'training-and-preparation',
    category: 'change-readiness',
    questions: [
      {
        text: 'I am confident I have the technical knowledge required to support changes associated with Operation Model Transformation (OMT)',
        key: 'technical-knowledge-to-support-change',
        answers: agreeToDisagree,
      },
      {
        text: 'I am confident I have the leadership skills (ability to lead initiatives, coaching/feedback & problem solving) required to support changes associated with Operation Model Transformation (OMT).',
        key: 'leadership-skills-to-support-change',
        answers: agreeToDisagree,
      },
      {
        text: 'I believe Operation Model Transformation (OMT) will offer me training that is relevant to the new skills required to fulfill my job duties.',
        key: 'training-will-provide-needed-skill',
        answers: agreeToDisagree,
      },
      {
        text: 'I understand the job performance that will be expected of me throughout the implementation of Operation Model Transformation (OMT).',
        key: 'understand-performance-expectations',
        answers: agreeToDisagree,
      },
    ],
  },
  {
    name: 'Competing Initiatives',
    key: 'competing-initiatives',
    category: 'change-readiness',
    questions: [
      {
        text: 'Operation Model Transformation (OMT) is one of the top priorities within Information Technology.',
        key: 'transformation-is-top-priority',
        answers: agreeToDisagree,
      },
      {
        text: 'There are no other initiatives that are taking resources away from Operation Model Transformation (OMT).',
        key: 'no-transformation-distractions',
        answers: agreeToDisagree,
      },
    ],
  },
];
