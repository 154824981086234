export function getTemplateQuestionDictionary(template) {
  return template.assessment.question_sets.reduce(
    (qsAcc, qs) => ({
      ...qsAcc,
      [qs.key]: {
        name: qs.name,
        questions: qs.questions.reduce(
          (qAcc, q) => ({
            ...qAcc,
            [q.key]: {
              name: q.text,
            },
          }),
          {}
        ),
      },
    }),
    {}
  );
}

export function lookupNameInDictionary(
  dictionary,
  questionSetKey,
  questionKey = null
) {
  return questionKey === null
    ? dictionary[questionSetKey]?.name
    : dictionary[questionSetKey]?.questions[questionKey]?.name;
}
