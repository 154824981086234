import { types as AccountActionTypes } from 'js/actions/account-actions';
import { types as AccountTypes } from 'js/actions/account-actions';

// NOTE: we can pull other form fields into this state if we want
const initialState = {
  isLoading: false,
  isFailed: false,
  error: null,
};

const register = (state = initialState, action) => {
  switch (action.type) {
    case AccountActionTypes.REGISTER:
      return {
        ...initialState,
        isLoading: true,
      };

    case AccountActionTypes.REGISTER_ERROR:
      return {
        ...initialState,
        isLoading: false,
        isFailed: true,
        error: action.error.response.body.errors,
        meta: action.meta,
      };

    case AccountActionTypes.REGISTER_SUCCESS:
      return initialState;

    case AccountTypes.LOGOUT:
    case AccountTypes.LOGOUT_SUCCESS:
    case 'API_ERROR/INVALID_TOKEN':
      return initialState;

    default:
      return state;
  }
};

export default register;
