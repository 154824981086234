import { Icon } from 'js/components';
import React from 'react';

function AcceptInviteFailureComponent() {
  return (
    <div className="page-result">
      <div className="result-icon">
        <Icon icon={['far', 'pumpkin']} transform="shrink-2" />
      </div>
      <div className="result-body">
        <h2>Well, that won't work</h2>
        <div>
          <p className="mb-5">
            Your link seems a bit stale. They're only valid for 7 days, after
            which they turn back into pumpkins. Not to worry, you can just
            contact the person who invited you and ask them for another invite.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AcceptInviteFailureComponent;
