import React from 'react';
import PropTypes from 'prop-types';
import { keyBy, noop, toArray } from 'lodash';

import { Switch, Route } from 'react-router-dom';
import { PanelHeader } from 'js/components';
import Overview from './Overview';
import HostQuestion from './HostQuestion';
import OverviewNav from './OverviewNav';
import QuestionNav from './QuestionNav';
import WrapUp from './WrapUp';
import KickoffNav from './KickoffNav';
import Kickoff from './Kickoff';

const LiveAssessmentHostComponent = ({
  assessment,
  liveAssessmentData,
  presences,
  passphrase,
  match,
  onNextQuestion = noop,
  onPreviousQuestion = noop,
  onToggleAssessmentLock = noop,
  onLastQuestion = noop,
  onChangeQuestion = noop,
  onBulkUpdateRevealResultsOn = noop,
  assessmentInvitesQueryResult,
  onSendInvites,
  sendingInvites,
  question,
}) => {
  const navProps = {
    assessment,
    onNextQuestion,
    onPreviousQuestion,
    allowPreviousQuestion: !(
      question === undefined ||
      assessment === undefined ||
      assessment?.question_sets.find((qs) => qs.questions.length > 0)
        ?.questions[0].id === question?.id
    ),
  };

  const mapParticipant = (present) => (p) => ({
    id: p.id,
    name: p.name,
    present,
  });

  const participantCollection = {
    ...keyBy(
      (assessment.participants || [])
        .map(mapParticipant(false))
        .filter((a) => a),
      (x) => x.id
    ),
    ...keyBy(
      presences.map(mapParticipant(true)).filter((a) => a),
      (x) => x.id
    ),
  };

  const participants = toArray(participantCollection).sort((a, b) => {
    if (a.present !== b.present) {
      return a.present ? -1 : 1;
    }

    return a.name?.localeCompare(b.name, 'en', {
      sensitivity: 'base',
    }) ?? -1;
  });

  const standardProps = {
    assessment,
    liveAssessmentData,
    participants,
    assessmentInvitesQueryResult,
    onSendInvites,
    sendingInvites,
  };

  return (
    <div className="assessment live-assessment">
      <PanelHeader size="xs" />
      <div className="content has-bottom-bar">
        <Switch>
          <Route
            path={`${match.path}/overview`}
            render={(props) => (
              <Overview
                {...standardProps}
                {...props}
                onUpdateQuestionsReveal={onBulkUpdateRevealResultsOn}
              />
            )}
          />
          <Route
            path={`${match.path}/kickoff`}
            render={(props) => (
              <Kickoff {...standardProps} {...props} passphrase={passphrase} />
            )}
          />
          <Route
            path={`${match.path}/wrapup`}
            render={(props) => (
              <WrapUp
                {...standardProps}
                {...props}
                onToggleAssessmentLock={onToggleAssessmentLock}
                onChangeQuestion={onChangeQuestion}
              />
            )}
          />
          <Route
            path={`${match.path}`}
            exact
            render={(props) => (
              <HostQuestion question={question} {...standardProps} {...props} />
            )}
          />
        </Switch>
      </div>
      <div className="detached-nav-bar">
        <Switch>
          <Route
            path={`${match.path}/overview`}
            render={() => <OverviewNav assessment={assessment} />}
          />
          <Route
            path={`${match.path}/kickoff`}
            render={() => <KickoffNav {...navProps} />}
          />
          <Route
            path={`${match.path}/wrapup`}
            render={() => (
              <QuestionNav
                question={question}
                assessment={assessment}
                onNextQuestion={noop}
                onPreviousQuestion={onLastQuestion}
                allowNextQuestion={false}
              />
            )}
          />
          <Route
            path={`${match.path}`}
            exact
            render={() => <QuestionNav question={question} {...navProps} />}
          />
        </Switch>
      </div>
      <style>{`
        .footer { display: none; }
      `}</style>
    </div>
  );
};

LiveAssessmentHostComponent.propTypes = {
  onNextQuestion: PropTypes.func,
  onPreviousQuestion: PropTypes.func,
  onChangeQuestion: PropTypes.func,
  onToggleAssessmentLock: PropTypes.func,
};

export default LiveAssessmentHostComponent;
