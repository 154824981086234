import React from 'react';
import _ from 'lodash';

import { Icon } from 'js/components';

import ResendForm from './ResendForm';

const EmailVerificationFormRouteComponent = ({
  me,
  verified,
  error,
  inProgress,
  onResend,
  resendState,
  email
}) => {
  return (
    <div id="verify-account-form-page" className="featured-form">
      <div className="full-page-content">
        <div className="background-page">
          <div className="container">
            <div className="content">
              <div className="page-result page-success">
                <div className="result-icon">
                  <Icon icon={['far', 'envelope']} fixedWidth />
                </div>
                <div className="result-body">
                  <h2>Almost there&hellip;</h2>
                  <div>
                    <p>
                      Check your email for a link to verify your account (it may
                      take a few seconds to get there). If you don't have it, we
                      can send another.
                    </p>

                    <ResendForm
                      email={email}
                      onSubmit={onResend}
                      state={resendState}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
};

EmailVerificationFormRouteComponent.defaultProps = {
  onResend: _.noop
};

export default EmailVerificationFormRouteComponent;
