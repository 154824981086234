import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { hierarchy } from '@visx/hierarchy';

import assessmentTemplates from 'js/data/assessment-templates';
import { getTemplateQuestionDictionary } from 'js/utils/template';
import { findNode } from 'js/utils/org-utils';
import { scoreNode } from './utils';

import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { Icon } from 'js/components';
import OrgBreadcrumb from './OrgBreadcrumb';
import NodeRecommendation from './NodeRecommendation';

const OrgRecommendations = ({
  match,
  workspace,
  organization,
  questionScores,
  assessmentKey,
  location,
}) => {
  const subOrgId = +(match.params.subOrgId ?? organization.id);

  const template = useMemo(() => {
    return assessmentTemplates.find((t) => t.key === assessmentKey);
  }, [assessmentKey]);

  const templateDictionary = useMemo(
    () => getTemplateQuestionDictionary(template),
    [template]
  );

  const tree = useMemo(
    () => hierarchy(scoreNode(organization, questionScores)),
    [organization, questionScores]
  );

  const node = useMemo(
    () =>
      findNode(tree, (d) => d.data.type === 'org' && d.data.id === subOrgId) ??
      tree,
    [subOrgId, tree]
  );

  const workspaceId = workspace.id;
  const orgId = organization.id;
  const linkForNodeId = useCallback(
    (nodeId) =>
      `/w/${workspaceId}/orgs/${orgId}/${assessmentKey}/detail/${nodeId}/recommendations#org-recommendations-${nodeId}`,
    [workspaceId, orgId, assessmentKey]
  );

  // If there is only one question set, show the questions from that set
  const filterKey =
    template.assessment.question_sets.length === 1
      ? template.assessment.question_sets[0].key
      : null;

  const locationHash = location.hash;
  const [lastScrolledToBookmark, setLastScrolledToBookmark] = useState();
  useEffect(() => {
    if (locationHash && locationHash !== lastScrolledToBookmark) {
      document.getElementById(locationHash.slice(1)).scrollIntoView();
      setLastScrolledToBookmark(locationHash);
    }
  }, [locationHash, lastScrolledToBookmark, setLastScrolledToBookmark]);

  return (
    <>
      <Card>
        <CardBody>
          <div
            id={`org-recommendations-${subOrgId}`}
            className="org-top-recommendations"
          >
            <div className="org-node-recommendation parent-org">
              <h3>
                <OrgBreadcrumb node={node} buildLink={linkForNodeId} />
                <div className="subtitle">
                  <div className="descriptor">Room for improvement</div>
                  <div className="dimension-descriptor">
                    Larger is better
                    <Icon icon={['far', 'long-arrow-right']} className="icon" />
                  </div>
                </div>
              </h3>
              <NodeRecommendation
                node={node}
                questionSetKey={filterKey}
                template={template}
                templateDictionary={templateDictionary}
                showDistribution
              />
            </div>
          </div>
          <div className="footnote mt-2">* Top priority for improvement</div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <div className="org-top-recommendations">
            {(node.children ?? [])
              .sort((a, b) => a.data.name?.localeCompare(b.data.name))
              .map((childNode) => {
                const {
                  data: { type, id, name },
                } = childNode;

                return (
                  <div
                    className="org-node-recommendation"
                    key={`${type}-${id}`}
                  >
                    <h3>
                      {type === 'team' ? (
                        <span>{name}</span>
                      ) : (
                        <Link to={linkForNodeId(id)}>{name}</Link>
                      )}
                      <span className="subtitle">Room for improvement</span>
                    </h3>
                    <NodeRecommendation
                      node={childNode}
                      questionSetKey={filterKey}
                      template={template}
                      templateDictionary={templateDictionary}
                    />
                  </div>
                );
              })}
          </div>
          <div className="footnote mt-2">* Top priority for improvement</div>
        </CardBody>
      </Card>
    </>
  );
};

export default withRouter(OrgRecommendations);
