import React from 'react';
import classNames from 'classnames';
import { scaleColorTraffic } from 'js/utils/color';
import { Icon } from 'js/components';

const ScorecardNode = ({ parentKey = '', node, columnData }) => {
  const {
    depth,
    data: { type, name, scores },
    children,
  } = node;

  const rowClasses = {
    [`palette-tiers-${type === 'team' ? 0 : 1 + (depth % 7)}`]: true,
    'muted-bg': true,
    'squad-scorecard-team': type === 'team',
    'squad-scorecard-group': type !== 'team',
  };

  return (
    <>
      <tr className={classNames(rowClasses)}>
        <td className="squad-scorecard-name">
          <div style={{ paddingLeft: `${depth * 10}px` }}>
            <div>{name}</div>
            <div className="details">
              {type === 'team' ? (
                <>
                  <Icon icon="users" fixedWidth className="icon-left" />
                  Team
                </>
              ) : (
                <>
                  <Icon icon="sitemap" fixedWidth className="icon-left" />
                  Tier {depth + 1} Group
                </>
              )}
            </div>
          </div>
        </td>
        {columnData.map(({ combinedKey }) => {
          const score = scores[combinedKey];
          return (
            <td className="squad-scorecard-question" key={combinedKey}>
              <div
                className="squad-scorecard-result"
                style={{ backgroundColor: scaleColorTraffic(score?.score) }}
              />
            </td>
          );
        })}
      </tr>

      {children?.map((c, i) => {
        const key = `scorecard-${parentKey}-${i}`;

        return (
          <ScorecardNode
            key={key}
            parentKey={key}
            node={c}
            columnData={columnData}
          />
        );
      })}
    </>
  );
};

export default ScorecardNode;
