import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { create as createUser } from 'js/actions/user-actions';

import CreateUserComponent from '../components/CreateUserComponent';

class CreateTeamContainer extends React.Component {
  constructor(props) {
    super(props);

    this._handleSave = this._handleSave.bind(this);
  }

  _handleSave(team) {
    const { createUser, history } = this.props;

    createUser(team).then(() => {
      history.push('/settings/users');
    });
  }

  render() {
    const { formState } = this.props;

    return (
      <CreateUserComponent formState={formState} onSave={this._handleSave} />
    );
  }
}

export default withRouter(
  connect(
    (store, props) => ({
      formState: store.forms.createUser
    }),
    {
      createUser
    }
  )(CreateTeamContainer)
);
