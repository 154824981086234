import React, { useState } from 'react';
import { max, toArray } from 'lodash';

import QuestionOverview from '../QuestionOverview';
import ParticipantList from './ParticipantList';
import { Button } from 'reactstrap';
import { Icon } from 'js/components';

import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';

const Overview = ({
  assessment,
  participants,
  liveAssessmentData,
  onUpdateQuestionsReveal,
}) => {
  const [updating, setUpdating] = useState(false);

  if (!assessment.question_sets) {
    return null;
  }

  const { counts, unrevealedQuestionCount } = assessment.question_sets.reduce(
    (qsAcc, qs) =>
      qs.questions.reduce((acc, q) => {
        return {
          counts: q.responses.reduce((countAcc, r) => {
            const key = `${q.id}-${r.value}`;
            countAcc[key] = countAcc.hasOwnProperty(key)
              ? countAcc[key] + 1
              : 1;
            return countAcc;
          }, acc.counts),
          unrevealedQuestionCount:
            q.reveal_results_on === null || q.reveal_results_on > Date.now()
              ? acc.unrevealedQuestionCount + 1
              : acc.unrevealedQuestionCount,
        };
      }, qsAcc),
    { counts: {}, unrevealedQuestionCount: 0 }
  );

  const yLimit = max(toArray(counts)) || 0;

  const handleUpdateQuestionsReveal = async (reveal) => {
    setUpdating(true);
    await onUpdateQuestionsReveal(reveal ? new Date() : null);
    setUpdating(false);
  };

  return (
    <div className="container-fluid">
      <div className="overview">
        <div className="question-sets">
          {assessment.question_sets.map((questionSet) => {
            return (
              <div key={questionSet.id} className="questions">
                {questionSet.questions.map((question) => (
                  <div key={question.id} className="question">
                    <QuestionOverview
                      forceShowResponses={false}
                      question={question}
                      responses={question.responses}
                      yLimit={yLimit}
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        <div className="overview-sidebar">
          <div className="sidebar-item">
            {unrevealedQuestionCount > 0 ? (
              <Button
                className="m-0"
                color="info"
                disabled={updating}
                onClick={() => handleUpdateQuestionsReveal(true)}
                block
              >
                {updating ? (
                  <Icon
                    className="btn-icon-left"
                    spin
                    icon={faCircleNotch}
                    size="lg"
                    fixedWidth
                  />
                ) : (
                  <Icon
                    className="btn-icon-left"
                    icon={faEye}
                    size="lg"
                    fixedWidth
                  />
                )}
                Reveal all responses
              </Button>
            ) : (
              <Button
                className="m-0"
                color="info"
                disabled={updating}
                onClick={() => handleUpdateQuestionsReveal(false)}
                block
              >
                {updating ? (
                  <Icon
                    className="btn-icon-left"
                    spin
                    icon={faCircleNotch}
                    size="lg"
                    fixedWidth
                  />
                ) : (
                  <Icon
                    className="btn-icon-left"
                    icon={faEyeSlash}
                    size="lg"
                    fixedWidth
                  />
                )}
                Hide all responses
              </Button>
            )}
          </div>
          <div className="participants sidebar-item">
            <ParticipantList
              participants={participants}
              hostId={liveAssessmentData.host_id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
