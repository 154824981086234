import { types as AccountTypes } from 'js/actions/account-actions';
import { types as AccountUserActionTypes } from 'js/actions/account/user-actions';
import { types as ChannelTypes } from 'js/actions/channel-actions';
import { types as CustomerAccountTypes } from 'js/actions/channel-actions/customer-account-actions';
import { types as WorkspaceTypes } from 'js/actions/workspace-actions';

import { connect } from './socket';

import { joinAssessmentChannel, leaveAssessmentChannel } from './assessments';
import { joinCustomerAccountChannel } from './customer-account';
import { denormalize } from 'normalizr';
import { SCHEMAS } from 'js/api/schemas';

let socket = null;

export const channelMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case AccountTypes.LOGIN_SUCCESS:
      case AccountTypes.REGISTER_SUCCESS:
      case AccountUserActionTypes.REGISTER_SUCCESS:
        socket = connect(action.data.token);
        break;

      case AccountTypes.LOGOUT_SUCCESS:
        if (socket !== null) {
          socket.disconnect();
        }
        socket = null;
        break;

      case ChannelTypes.JOIN_ASSESSMENT:
        socket = socket || connect(getState().identity.token);
        joinAssessmentChannel(action.key, socket, dispatch, getState);
        break;

      case ChannelTypes.LEAVE_ASSESSMENT:
        socket = socket || connect(getState().identity.token);
        leaveAssessmentChannel(action.key);
        break;

      case CustomerAccountTypes.JOIN_ACCOUNT_CHANNEL:
        {
          socket = socket || connect(getState().identity.token);
          const accountId = action.data;
          accountId !== undefined &&
            joinCustomerAccountChannel(accountId, socket, dispatch, getState);
        }
        break;

      case WorkspaceTypes.CHANGE_WORKSPACE:
        socket = socket || connect(getState().identity.token);

        const workspace = denormalize(
          getState().entities.workspaces[action.data],
          SCHEMAS.WORKSPACE,
          getState().entities
        );

        if (workspace) {
          joinCustomerAccountChannel(
            workspace?.account?.id,
            socket,
            dispatch,
            getState
          );
        }

        break;

      default: // do nothing
    }

    next(action);
  };

export default channelMiddleware;
