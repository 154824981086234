export const unscoredLikertQuestionSets = [
  {
    name: 'Unscored Likert Questions',
    key: 'unscored-likert-questions',
    category: 'unscored-likert',
    questions: [
      {
        text: '…so that collaboration within my team is encouraged and rewarded',
        key: 'intra-team-collaboration-so-that',
        type: 'unscored-likert',
        answers: [
          { value: '2', text: 'Agree' },
          { value: '1', text: 'Partially agree' },
          { value: '0', text: 'Do not agree' },
          { value: null, is_na: true, text: 'No value' },
        ],
      },
      {
        text: '…so that adapting our budget spend to changing business priorities mid-cycle is',
        key: 'budget-reallocation-so-that',
        type: 'unscored-likert',
        answers: [
          { value: '2', text: 'Agree' },
          { value: '1', text: 'Partially agree' },
          { value: '0', text: 'Do not agree' },
          { value: null, is_na: true, text: 'No value' },
        ],
      },
    ],
  },
];
