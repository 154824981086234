import React from 'react';
import PropTypes from 'prop-types';

const NullMarker = ({
  tag: Tag,
  outerRadius,
  innerRadius,
  width,
  height,
  ...props
}) => {
  return (
    <Tag {...props}>
      <rect
        x="0"
        y="0"
        width={width}
        height={height}
        className="no-response-bg"
      />
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        <path
          fillRule="evenodd"
          d={[
            `M 0 -${outerRadius}`,
            `A ${outerRadius} ${outerRadius} 0 1 0 0 ${outerRadius}`,
            `A ${outerRadius} ${outerRadius} 0 1 0 0 ${-outerRadius}`,
            'Z',
            `M 0 -${innerRadius}`,
            `A ${innerRadius} ${innerRadius} 0 1 1 0 ${innerRadius}`,
            `A ${innerRadius} ${innerRadius} 0 1 1 0 ${-innerRadius}`
          ].join(' ')}
          className="no-response-marker"
        />
      </g>
    </Tag>
  );
};

NullMarker.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  outerRadius: PropTypes.number,
  innerRadius: PropTypes.number
};

NullMarker.defaultProps = {
  tag: 'svg',
  outerRadius: 4,
  innerRadius: 2
};

export default NullMarker;
