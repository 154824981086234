export const planningQuestionSets = [
  {
    name: 'Forecasting',
    key: 'data-driven-forecasting',
    category: 'planning',
    questions: [
      {
        text: 'We use historical data to develop our delivery forecasts',
        key: 'data-driven-forecasts',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'My team is involved in forecasting and roadmapping to set delivery expectations for work that impacts us',
        key: 'team-involved-in-forecasting',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Budgeting',
    key: 'budgeting',
    category: 'planning',
    questions: [
      {
        text: 'We fund teams and products vs cost centers and projects',
        key: 'product-budgeting',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Adapting our budget spend to changing business priorities mid-cycle is',
        key: 'budget-reallocation',
        answers: [
          { value: '5', text: 'Very easy' },
          { value: '4', text: 'Easy' },
          { value: '3', text: 'Neither easy nor difficult' },
          { value: '2', text: 'Difficult' },
          { value: '1', text: 'Very difficult' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Goal-setting and evaluation',
    key: 'goal-setting-and-evaluation',
    category: 'planning',
    questions: [
      {
        text: 'My company has SMART (Specific, Measurable, Achievable, Realistic, Timebound) strategic goals',
        key: 'smart-strategic-goals',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We continually evaluate our goals and adjust as necessary based on new information',
        key: 'adjust-goals',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: `We celebrate success appropriately, even if we only partially accomplish our goals`,
        key: 'celebrate-success',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Goal setting is based on the outcomes and value they deliver vs the tasks required to deliver them',
        key: 'outcome-based-goals',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'In general, the people employed by my organization are working toward the same goals',
        key: 'goal-alignment',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Plan creation and management',
    key: 'plan-creation-and-management',
    category: 'planning',
    questions: [
      {
        text: 'We can usually create plans for up to 2 weeks where the main objectives do not change significantly',
        key: 'short-term-plan-stability',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We can usually create plans for up to 3 months where the main objectives do not change significantly',
        key: 'medium-term-plan-stability',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We effectively identify, manage and mitigate risks throughout creation and execution of our plans',
        key: 'risk-management',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Adapting our plans to take advantage of new opportunities as they arise is ____',
        key: 'plan-agility',
        answers: [
          { value: '5', text: 'Very easy' },
          { value: '4', text: 'Easy' },
          { value: '3', text: 'Neither easy nor difficult' },
          { value: '2', text: 'Difficult' },
          { value: '1', text: 'Very difficult' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'The right people are involved in planning discussions',
        key: 'planning-involves-right-people',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Predictability',
    key: 'predictability',
    category: 'planning',
    questions: [
      {
        text: 'Over 2 weeks, we will likely have completed ____ of what we say we will today',
        key: 'short-term-predictability',
        answers: [
          { value: '1', text: 'less than 40%' },
          { value: '2', text: '40-60%' },
          { value: '3', text: '60-80%' },
          { value: '4', text: '80-95%' },
          { value: '5', text: 'more than 95%' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Over a 3-6 month window, we will likely have completed ____ of what we say we will today',
        key: 'medium-term-predictability',
        answers: [
          { value: '1', text: 'less than 40%' },
          { value: '2', text: '40-60%' },
          { value: '3', text: '60-80%' },
          { value: '4', text: '80-95%' },
          { value: '5', text: 'more than 95%' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: '____ of the work we do within a 2 week window is unplanned or unanticipated at the start of that window.',
        key: 'short-term-churn',
        answers: [
          { value: '5', text: 'less than 10%' },
          { value: '4', text: '10-25%' },
          { value: '3', text: '25-50%' },
          { value: '2', text: '50-75%' },
          { value: '1', text: 'more than 75%' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: '____ of the work we do within a 3-6 month window is unplanned or unanticipated at the start of that window.',
        key: 'medium-term-churn',
        answers: [
          { value: '5', text: 'less than 10%' },
          { value: '4', text: '10-25%' },
          { value: '3', text: '25-50%' },
          { value: '2', text: '50-75%' },
          { value: '1', text: 'more than 75%' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
];

export default planningQuestionSets;
