import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notify } from 'js/actions/alert-actions';
import {
  createAssessmentInvites,
  listAssessmentInvites,
} from 'js/actions/assessment-invite-actions';
import { AssessmentReviewDetail } from '../components/Review';
import { selectAssessmentInvitesQuery } from 'js/store/reducers/queries';
import _ from 'lodash';

function SurveyAssessmentReviewDetailContainer({
  match,
  currentWorkspaceId,
  teamId,
  createAssessmentInvites,
  notify,
  listAssessmentInvites,
  ...otherProps
}) {
  const { assessmentId } = match.params;

  const [sendingInvites, setSendingInvites] = useState(false);

  const fetchInvites = useCallback(() => {
    listAssessmentInvites(currentWorkspaceId, teamId, assessmentId).catch(
      _.noop
    );
  }, [listAssessmentInvites, teamId, assessmentId, currentWorkspaceId]);

  const sendInvites = useCallback(
    (emails, message, fetchAfter = false, subject = 'invitations') => {
      return (async () => {
        try {
          // Create will only resend the email for existing emails
          await createAssessmentInvites(
            currentWorkspaceId,
            teamId,
            assessmentId,
            message,
            emails.map((email) => ({ email }))
          );
          notify({
            type: 'success',
            message: `Your ${subject} are on their way!`,
          });
          fetchAfter && fetchInvites();
        } catch (e) {
          notify({
            type: 'danger',
            message: `Uh oh! Your ${subject} didn't get through`,
          });
        }
      })();
    },
    [
      createAssessmentInvites,
      currentWorkspaceId,
      teamId,
      assessmentId,
      notify,
      fetchInvites,
    ]
  );

  const handleSendInvites = useCallback(
    (emails, message) => {
      return (async () => {
        setSendingInvites(true);
        sendInvites(emails, true, 'invitations');
        setSendingInvites(false);
      })();
    },
    [setSendingInvites, sendInvites]
  );

  const handleSendReminders = useCallback(
    (emails) => {
      return sendInvites(emails, false, 'reminders');
    },
    [sendInvites]
  );

  useEffect(() => {
    fetchInvites();
  }, [fetchInvites]);

  return (
    <AssessmentReviewDetail
      match={match}
      onSendInvites={handleSendInvites}
      sendingInvites={sendingInvites}
      onSendReminders={handleSendReminders}
      {...otherProps}
    />
  );
}

export default connect(
  (store, params) => ({
    currentWorkspaceId: store.currentWorkspaceId,
    // This teamId was undefined.  I am commenting this out and using the teamId passed via props.
    // teamId: store.teamId,
    assessmentInvitesQueryResult: selectAssessmentInvitesQuery(
      store,
      params.match.params.assessmentId
    ),
  }),
  {
    notify,
    createAssessmentInvites,
    listAssessmentInvites,
  }
)(SurveyAssessmentReviewDetailContainer);
