import { padEnd } from 'lodash';

// NOTE: might be able to use String.prototype.padEnd, but I couldn't confirm the babel preset
export function generateAck() {
  return `${padEnd(
    Math.random()
      .toString(36)
      .substring(2, 9),
    7,
    '0'
  )}-${Date.now()}`;
}
