import React, { useMemo } from 'react';
import { AsyncDisplay, LoadingMessage, PanelHeader } from 'js/components';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import OrgTreeView from 'js/routes/Organizations/components/ManageOrg/OrgTreeVeiw/OrgTreeView';
import { normalizeOrg } from 'js/utils/org-utils';

function ManageOrg({
  organization,
  loading,
  errorLoading,
  teams,
  rootOrgs,
  onAddTeamsToOrg,
  onAddGroup,
  onAddOrgRef,
  onRemoveNode,
  onRename,
  onPromoteToOrg,
}) {
  const orgTree = useMemo(() => {
    return organization ? normalizeOrg(organization) : null;
  }, [organization]);

  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content manage-org">
        <Card>
          <CardHeader>
            <CardTitle tag="h5">
              <div>Manage Organization</div>
            </CardTitle>
          </CardHeader>
          <CardBody>
            <div>
              <AsyncDisplay
                isLoading={!organization && !errorLoading && loading}
                isError={errorLoading}
              >
                <AsyncDisplay.Loading>
                  <LoadingMessage iconSize="2x">
                    Loading your org&hellip;
                  </LoadingMessage>
                </AsyncDisplay.Loading>
                <AsyncDisplay.Error>
                  <p className="error">
                    Uh oh! Looks like there was a problem loading the
                    organization.
                  </p>
                </AsyncDisplay.Error>
                <AsyncDisplay.Resolved>
                  {organization && (
                    <>
                      <OrgTreeView
                        orgTree={orgTree}
                        teams={teams}
                        rootOrgs={rootOrgs}
                        onAddTeamsToOrg={onAddTeamsToOrg}
                        onAddGroup={onAddGroup}
                        onAddOrgRef={onAddOrgRef}
                        onRemoveNode={onRemoveNode}
                        onRename={onRename}
                        onPromoteToOrg={onPromoteToOrg}
                      />
                    </>
                  )}
                </AsyncDisplay.Resolved>
              </AsyncDisplay>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default ManageOrg;
