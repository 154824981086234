import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { create as createTeam } from 'js/actions/team-actions';

import CreateTeamComponent from '../components/CreateTeamComponent';

class CreateTeamContainer extends React.Component {
  constructor(props) {
    super(props);

    this._handleSave = this._handleSave.bind(this);
  }

  _handleSave(newTeam) {
    const { createTeam, history, match } = this.props;
    const { workspaceId } = match.params;

    const team = {
      ...newTeam,
      workspace_id: workspaceId
    };

    createTeam(team).then(() => {
      history.push(`/w/${team.workspace_id}/teams`);
    });
  }

  render() {
    return <CreateTeamComponent onSave={this._handleSave} />;
  }
}

export default withRouter(
  connect(
    (store, props) => ({}),
    {
      createTeam
    }
  )(CreateTeamContainer)
);
