import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconStack, Icon } from 'js/components';
import HostNav from './HostNav';

import { faAnalytics, faPlay } from '@fortawesome/pro-solid-svg-icons';

const KickoffNav = (props) => {
  const { assessment } = props;

  return (
    <HostNav {...props}>
      <li className="nav-item">
        <Link to={`/assessments/${assessment.id}/host/overview`}>
          <IconStack size="2x">
            <Icon icon={['far', 'square']} />
            <Icon icon={faAnalytics} transform="shrink-9 left-1 down-0.5" />
          </IconStack>
        </Link>
      </li>
      <li className="nav-item">
        <Link to={`/assessments/${assessment.id}/host`} className="nav-qr">
          <IconStack size="2x">
            <Icon icon={['far', 'square']} />
            <Icon icon={faPlay} transform="shrink-8" />
          </IconStack>
        </Link>
      </li>
    </HostNav>
  );
};

KickoffNav.propTypes = {
  assessment: PropTypes.object.isRequired,
  // questionId: PropTypes.string,
  // qrTo: PropTypes.oneOf(['kickoff', 'question']),
  onPreviousQuestion: PropTypes.func,
  onNextQuestion: PropTypes.func,
};

KickoffNav.defaultProps = {
  qrTo: 'kickoff',
  onPreviousQuestion: () => {},
  onNextQuestion: () => {},
};

export default KickoffNav;
