import React, { useState } from 'react';
import { Icon } from 'js/components';

import styles from './AddWorkspace.module.scss';
import AddWorkspaceForm from './AddWorkspaceForm';

const AddWorkspace = ({
  createWorkspaceSubmissionErrors,
  createWorkspaceSubmissionValues,
  onAddWorkspace = () => {},
}) => {
  const [addingWorkspace, setAddingWorkspace] = useState(false);

  const handleAddWorkspace = async (workspace) => {
    await onAddWorkspace(workspace);
    setAddingWorkspace(false);
  };

  return addingWorkspace ? (
    <div>
      <AddWorkspaceForm
        onSubmit={handleAddWorkspace}
        onCancel={() => setAddingWorkspace(false)}
        submissionErrors={createWorkspaceSubmissionErrors}
        submissionValues={createWorkspaceSubmissionValues}
      />
    </div>
  ) : (
    <button
      className={`btn btn-add ${styles.add}`}
      onClick={() => setAddingWorkspace(true)}
    >
      <div>
        <Icon className="icon" icon={['far', 'plus-circle']} /> <br />
        Add a new workspace
      </div>
    </button>
  );
};

export default AddWorkspace;
