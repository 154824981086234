// CareSource EPMO Base Answers

export const alwaysNeverAnswers = [
  { value: '4', text: 'Always' },
  { value: '3', text: 'Usually' },
  { value: '2', text: 'Sometimes' },
  { value: '1', text: 'Rarely' },
  { value: '0', text: 'Never' },
  { value: null, text: 'Not sure/Not Applicable', is_na: true },
];

export const agreeDisagreeAnswers = [
  { value: '4', text: 'Strongly agree' },
  { value: '3', text: 'Agree' },
  { value: '2', text: 'Neither agree nor disagree' },
  { value: '1', text: 'Disagree' },
  { value: '0', text: 'Strongly disagree' },
  { value: null, text: 'Not sure/Not Applicable', is_na: true },
];

export const cslpmRightData = [
  { value: '3', text: 'I can make an informed choice as I understand the relative priority of all my work' },
  { value: '2', text: 'I decide based on the prioritized backlog' },
  { value: '1', text: 'I work on what my manager indicates is the most important thing to work on' },
  { value: '0', text: 'I do what I think is the next best thing for me to do' },
  { value: null, text: 'Not sure/Not Applicable', is_na: true },
];

// CareSource EPMO Base Answers

export const positiveNegativeAnswers = [
  { value: '4', text: 'Highly Positive' },
  { value: '3', text: 'Positive' },
  { value: '2', text: 'Neither positive nor negative' },
  { value: '1', text: 'Negative' },
  { value: '0', text: 'Highly Negative' },
];

export const persistentTemporaryAnswers = [
  { value: '4', text: 'Persistent: Very likely to happen again' },
  { value: '3', text: 'Expected: Likely to happen again' },
  { value: '2', text: 'Neither temporary nor persistent' },
  { value: '1', text: 'Occasional: Unlikely to happen agiain too often' },
  { value: '0', text: 'Temporary: Very unlikely to happen again' },
];

export const shapeAnswers = [
  { value: '4', text: 'Very easy to shape the situation' },
  { value: '3', text: 'Easy to shape the situation' },
  { value: '2', text: 'Neither impossible nor very easy to shape the situation' },
  { value: '1', text: 'Small ability to shape the situation' },
  { value: '0', text: 'Impossible to shape the situation' },
];

export const motivatedAnswers = [
  { value: '4', text: 'Not at all motivated' },
  { value: '3', text: 'Somewhat motivated' },
  { value: '2', text: 'Neither motivated nor not motivated' },
  { value: '1', text: 'Motivated' },
  { value: '0', text: 'Highly motivated' },
];

export const storyContextAnswer = [
  { value: '12', text: 'Value Delivery' },
  { value: '11', text: 'Planning' },
  { value: '10', text: 'Workload' },
  { value: '9', text: 'Psychological Safety' },
  { value: '8', text: 'Alignment' },
  { value: '7', text: 'Teamwork' },
  { value: '6', text: 'Efficiency' },
  { value: '5', text: 'Leadership/Management' },
  { value: '4', text: 'Skills and Knowledge' },
  { value: '3', text: 'Transparency' },
  { value: '2', text: 'Employee Engagement' },
  { value: '1', text: 'Cost or Budget' },
  { value: '0', text: 'Customer Satisfaction' },
];
