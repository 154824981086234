import keymirror from 'keymirror';
import { request } from 'js/api/axios';
import { callApi } from '../api';
import _ from 'lodash';

import { SCHEMAS } from 'js/api/schemas';
import { spreadApiKeys, extractApiKeys } from '../utils';

export const types = keymirror({
  ...spreadApiKeys('LOAD_TEAM'),
  ...spreadApiKeys('LOAD_TEAMS'),
  ...spreadApiKeys('CREATE_TEAM'),
  ...spreadApiKeys('DELETE_TEAM'),
  ...spreadApiKeys('UPDATE_TEAM'),
  ...spreadApiKeys('ADD_TEAM_MEMBER'),
  ...spreadApiKeys('UPDATE_TEAM_MEMBER'),
  ...spreadApiKeys('REMOVE_TEAM_MEMBER'),
});

export function list(query, queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_TEAMS, types),
        request: (data, token, currentWorkspaceId) =>
          request.get(`/api/workspaces/${currentWorkspaceId}/teams`, {
            params: data.query,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        schema: SCHEMAS.TEAM_ARRAY,
        data: { query },
        meta: { queryId },
      },
      dispatch
    );
  };
}

export function load(handle, queryId = null) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_TEAM, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(`/api/workspaces/${currentWorkspaceId}/teams/${handle}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { team_id: handle },
        meta: { queryId },
        schema: SCHEMAS.TEAM,
      },
      dispatch
    );
  };
}

export function create(team) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.CREATE_TEAM, types),
        request: (data, token, currentWorkspaceId) =>
          request.post(`/api/workspaces/${currentWorkspaceId}/teams`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        data: { team },
        schema: SCHEMAS.TEAM,
      },
      dispatch
    );
  };
}

export function del(team) {
  const id = _.isObject(team) ? team.id : team;

  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.DELETE_TEAM, types),
        request: (_data, token, currentWorkspaceId) =>
          request.delete(`/api/workspaces/${currentWorkspaceId}/teams/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        meta: { delete: { teams: id } },
      },
      dispatch
    );
  };
}

export function update(patch) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_TEAM, types),
        request: (data, token, currentWorkspaceId) =>
          request.put(
            `/api/workspaces/${currentWorkspaceId}/teams/${patch.id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { team: patch },
        meta: { patch: { teams: { [patch.id]: patch } } },
        schema: SCHEMAS.TEAM,
      },
      dispatch
    );
  };
}

export function addMember(teamHandle, teamMember) {
  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys('ADD_TEAM_MEMBER', types),
        request: (data, token, currentWorkspaceId) =>
          request.post(
            `/api/workspaces/${currentWorkspaceId}/teams/${teamHandle}/members`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { team_member: teamMember },
        meta: { teamId: teamHandle },
      },
      dispatch
    ).then(() => {
      return load(teamHandle)(dispatch, getState);
    });
  };
}

export function updateMember(teamMember) {
  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys('UPDATE_TEAM_MEMBER', types),
        request: (data, token, currentWorkspaceId) =>
          request.put(
            `/api/workspaces/${currentWorkspaceId}/teams/${teamMember.team_id}/members/${teamMember.member_id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: { team_member: teamMember },
        meta: { teamId: teamMember.team_id },
      },
      dispatch
    ).then(() => {
      return load(teamMember.team_id)(dispatch, getState);
    });
  };
}

export function removeMember(teamHandle, memberId) {
  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys('REMOVE_TEAM_MEMBER', types),
        request: (data, token, currentWorkspaceId) =>
          request.delete(
            `/api/workspaces/${currentWorkspaceId}/teams/${teamHandle}/members/${memberId}`,
            {
              data,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        meta: { teamId: teamHandle },
      },
      dispatch
    ).then(() => {
      return load(teamHandle)(dispatch, getState);
    });
  };
}
