import React from 'react';
import { PopoverBody, PopoverHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Icon } from 'js/components';

function NodePopover({
  name,
  assessmentUrl,
  onSendReminders,
  disableReminders = false,
}) {
  return (
    <>
      <PopoverHeader>
        <div className="node-popover-header">{name}</div>
      </PopoverHeader>
      <PopoverBody>
        <div className="node-popover-body">
          {assessmentUrl && (
            <div className="node-popover-action">
              <Link to={assessmentUrl}>
                <Icon className="mr-1" icon={['fas', 'info-circle']} />
                Go to assessment
              </Link>
            </div>
          )}
          <div className="node-popover-action">
            <button
              className="btn btn-link"
              onClick={onSendReminders}
              disabled={disableReminders}
            >
              <Icon className="mr-1" icon={['fas', 'envelope']} />
              Send reminders
            </button>
          </div>
        </div>
      </PopoverBody>
    </>
  );
}

export default NodePopover;
