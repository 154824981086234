import keymirror from 'keymirror';
import { request } from 'js/api/axios';

import { SCHEMAS } from '../api/schemas';
import { spreadApiKeys, extractApiKeys } from '../utils';

import { callApi } from '../api';

export const types = keymirror({
  ...spreadApiKeys('LOAD_ASSESSMENT_TEMPLATES'),
});

export function list(queryId) {
  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys(types.LOAD_ASSESSMENT_TEMPLATES, types),
        request: (_data, token, currentWorkspaceId) =>
          request.get(
            `/api/workspaces/${currentWorkspaceId}/assessment-templates`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        meta: !!queryId ? { queryId } : {},
        schema: SCHEMAS.ASSESSMENT_TEMPLATE_ARRAY,
      },
      dispatch
    );
  };
}
