import React, { useMemo } from 'react';

import { Link, useParams } from 'react-router-dom';
import { Icon } from 'js/components';
import classNames from 'classnames';
import { useFlags } from 'js/hooks/useFlags';

import styles from './ProfileDropdown.module.scss';

const NavMenuItems = ({
  profile,
  onLogout,
  itemTag: ItemTag = 'div',
  useLogoutButton = true,
  itemProps,
  linkProps,
  buttonProps,
}) => {
  const flags = useFlags();
  const { workspaceId, accountId } = useParams();

  const account = useMemo(() => {
    const currentAccount =
      accountId !== undefined
        ? profile?.accounts?.find((a) => a?.id === accountId)
        : profile?.workspaces?.find((w) => w?.id + '' === workspaceId)?.account;

    return currentAccount ?? profile?.accounts?.[0];
  }, [workspaceId, accountId, profile]);

  const userHasAccounts = profile?.accounts && profile?.accounts.length > 0;

  return (
    <>
      <ItemTag {...itemProps}>
        <Link to="/account" {...linkProps}>
          <Icon icon="user" size="lg" className="icon-left" fixedWidth />
          Edit profile
        </Link>
      </ItemTag>
      {flags.stripe && userHasAccounts && account && (
        <ItemTag data-test="manage-accounts-link" {...itemProps}>
          <Link to={`/a/${account.id}`} {...linkProps}>
            <Icon icon="briefcase" size="lg" className="icon-left" fixedWidth />
            Manage accounts
          </Link>
        </ItemTag>
      )}
      {useLogoutButton ? (
        <ItemTag {...itemProps}>
          <button
            data-test="sign-out-link"
            onClick={onLogout}
            {...buttonProps}
            className={classNames({
              btn: true,
              [buttonProps?.className ?? '']: true,
            })}
          >
            <Icon icon="sign-out" size="lg" className="icon-left" fixedWidth />
            Sign out
          </button>
        </ItemTag>
      ) : (
        <ItemTag
          {...itemProps}
          className={classNames(
            itemProps?.className,
            buttonProps?.className,
            styles.padded
          )}
          data-test="sign-out-link"
          onClick={onLogout}
        >
          <Icon icon="sign-out" size="lg" className="icon-left" fixedWidth />
          Sign out
        </ItemTag>
      )}
    </>
  );
};

export default NavMenuItems;
