import {
  timeYear,
  timeMonth,
  timeWeek,
  timeDay,
  timeHour,
  timeMinute,
  timeSecond,
} from '@visx/vendor/d3-time';
import { timeFormat } from '@visx/vendor/d3-time-format';

export const timeFormatMillisecond = timeFormat('.%L'),
  timeFormatSecond = timeFormat(':%S'),
  timeFormatMinute = timeFormat('%I:%M'),
  timeFormatHour = timeFormat('%I %p'),
  timeFormatDay = timeFormat('%a %d'),
  timeFormatWeek = timeFormat('%b %d'),
  timeFormatMonth = timeFormat('%B'),
  timeFormatYear = timeFormat('%Y');

// prettier-ignore
export const defaultTimeFormat = (date) =>
  (timeSecond(date) < date ? timeFormatMillisecond
    : timeMinute(date) < date ? timeFormatSecond
    : timeHour(date) < date ? timeFormatMinute
    : timeDay(date) < date ? timeFormatHour
    : timeMonth(date) < date ? (timeWeek(date) < date ? timeFormatDay : timeFormatWeek)
    : timeYear(date) < date ? timeFormatMonth
    : timeFormatYear)(date);

// prettier-ignore
export const timeFormatNoYear = (date) =>
  (timeSecond(date) < date ? timeFormatMillisecond
    : timeMinute(date) < date ? timeFormatSecond
    : timeHour(date) < date ? timeFormatMinute
    : timeDay(date) < date ? timeFormatHour
    : timeMonth(date) < date ? (timeWeek(date) < date ? timeFormatDay : timeFormatWeek)
    : timeFormatMonth)(date);
