import { request } from 'js/api/axios';
import keymirror from 'js/utils/keymirror';
import { spreadApiKeys, extractApiKeys } from 'js/utils';

import { callApi, noSchemaQuerySuccessMutator } from 'js/api';
import { QueryBase } from 'js/store/reducers/queries';

export const types = keymirror(
  {
    ...spreadApiKeys('LOAD_ACCOUNT_SUMMARY'),
  },
  { prefix: 'ACCOUNTS/' }
);

export const loadAccountSummary = (accountId) => {
  const meta = {
    queryId: `${QueryBase.AccountSummary}-${accountId}`,
  };

  return (dispatch) => {
    return callApi(
      {
        types: extractApiKeys('LOAD_ACCOUNT_SUMMARY', types),
        request: (_data, token) =>
          request.get(`/api/accounts/${accountId}/reports/dashboard-summary`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        meta,
        mutators: [undefined, noSchemaQuerySuccessMutator(meta), undefined],
      },
      dispatch
    );
  };
};
