import React from 'react';
import Participant from './Participant';
import _ from 'lodash';

const ParticipantList = (props) => {
  const { participants, hostId } = props;

  const getParticipants = () => {
    const host = _.find(participants, (p) => {
      return p.id === hostId;
    });
    let participantElements = [];

    if (host) {
      participantElements.push(
        <Participant
          key={host.id}
          name={host.name}
          present={host.present}
          type="host"
        />
      );
    }

    participantElements = participantElements.concat(
      _.reject(
        participants.map((p) => {
          if (p.id === hostId) {
            return null;
          }

          return <Participant key={p.id} name={p.name} present={p.present} />;
        }),
        (x) => {
          return x === null;
        }
      )
    );

    return participantElements;
  };

  return (
    <div className="participant-list">
      <h4>Participants</h4>
      {getParticipants()}
    </div>
  );
};

export default ParticipantList;
