import React, { useMemo } from 'react';
import { Grid } from '@visx/grid';
import { noop } from 'lodash';
import { AxisBottom } from '../AxisBottom';
import { YearUnderlay } from '../YearUnderlay';
import { scaleLinear } from '@visx/scale';
import { scaleColorTraffic } from 'js/utils/color';
// import { timeFormat } from '@visx/vendor/d3-time-format';

import Markers from './Markers';

const getAssessmentScore = (a) => a.normalizedScore;
const getQuestionScore = (q) => q.normalizedScore;
const getNullScore = () => 0.5;

export const AssessmentLollipopChart = ({
  assessments,
  width,
  height = 250,
  margins = { top: 10, right: 10, bottom: 20, left: 10 },
  xScale,
  questionFilter = null,
  transientAssessmentFilter = null,
  lockedAssessmentFilter = null,
  radius = 10,
  innerRadius = 5,
  onHoverAssessment = noop,
  onUnhoverAssessment = noop,
  onClickAssessment = noop,
}) => {
  const chartWidth = width - margins.left - margins.right;
  const chartHeight = height - margins.top - margins.bottom;
  const yScale = useMemo(
    () => scaleLinear({ domain: [-0.25, 1.25], range: [chartHeight, 0] }),
    [chartHeight]
  );

  const x = (d) => {
    return xScale(d.insertedAt);
  };
  const y = yScale;

  return chartWidth <= 0 ? null : (
    <div
      style={{ position: 'relative' }}
      className="question-set-overview-chart"
    >
      <svg viewBox={`0 0 ${width} ${height}`}>
        <g transform={`translate(${margins.left} ${margins.top})`}>
          <YearUnderlay height={chartHeight} xScale={xScale} />
          <Grid
            className="chart-grid chart-neutral-line"
            height={chartHeight}
            width={chartWidth}
            rowTickValues={[0, 0.5, 1]}
            xScale={xScale}
            yScale={yScale}
            stroke="#526067"
            numTicksColumns={5}
          />
          <AxisBottom
            chartHeight={chartHeight}
            chartWidth={chartWidth}
            xScale={xScale}
          />
          <rect
            width={chartWidth}
            height={chartHeight}
            className="bounding-box"
          />
          <g>
            <Markers
              data={assessments}
              x={x}
              y={y}
              color={scaleColorTraffic}
              questionFilter={questionFilter}
              transientAssessmentFilter={transientAssessmentFilter}
              lockedAssessmentFilter={lockedAssessmentFilter}
              getAssessmentScore={getAssessmentScore}
              getQuestionScore={getQuestionScore}
              getNullScore={getNullScore}
              radius={radius}
              innerRadius={innerRadius}
              height={chartHeight}
              onHoverMarker={onHoverAssessment}
              onUnhoverMarker={onUnhoverAssessment}
              onClickMarker={onClickAssessment}
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default AssessmentLollipopChart;
