import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody
} from 'reactstrap';

import BusinessGoal from './BusinessGoal';

const GoalSet = ({ entity, goals, onSetPrimary, fluid }) => {
  const handleSetPrimary = (_entity, goal) => {
    onSetPrimary(_entity, goal);
  };

  return (
    <Container fluid>
      <Row>
        {goals.length === 0 ? (
          <Col>
            <Card className="no-goals-message">
              <CardHeader>
                <CardTitle tag="h5">You are goal-less</CardTitle>
              </CardHeader>
              <CardBody>
                <p>No goals are speficied</p>
              </CardBody>
            </Card>
          </Col>
        ) : (
          goals.map(goal => {
            return (
              <Col sm="12" md="6" key={goal.id}>
                <BusinessGoal
                  title={goal.name}
                  primary={goal.id === entity.primary_goal}
                  onSetPrimary={handleSetPrimary.bind(this, entity, goal)}
                />
              </Col>
            );
          })
        )}
      </Row>
    </Container>
  );
};

GoalSet.propTypes = {
  entity: PropTypes.object,
  goals: PropTypes.arrayOf(PropTypes.object),
  onSetPrimary: PropTypes.func
};

GoalSet.defaultProps = {
  onSetPrimary: () => {}
};

export default GoalSet;
