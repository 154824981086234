import React from 'react';

import { Card, CardBody } from 'reactstrap';
import { Icon, PanelHeader } from 'js/components';
import { faSadTear } from '@fortawesome/pro-light-svg-icons';

const FullPageMessage = ({ message }) => {
  return (
    <div>
      <PanelHeader size="xs" />
      <div className="content">
        <Card className="mt-5">
          <CardBody>
            <div class="media text-muted">
              <Icon icon={faSadTear} size="4x" className="mr-3" fixedWidth />
              <div className="media-body not-available-msg">
                <p>{message}</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default FullPageMessage;
