import React from 'react';

import Variability from 'js/components/Charts/Variability';

import styles from '../DetailsPane.module.scss';

export const DetailData = ({
  meanScore,
  medianScore,
  stdDev,
  titleLabel = 'Viewing',
  title = 'Overview',
}) => {
  return (
    <div className={styles['overview-stats']}>
      <div className={styles['overview-title']}>
        <label>{titleLabel}</label>
        <h3>{title}</h3>
      </div>
      <div>
        <label>Mean Score</label>
        <p>
          {meanScore !== null ? (
            <>{Math.round(meanScore * 100)} / 100</>
          ) : (
            <>N/A</>
          )}
        </p>
      </div>
      <div>
        <label>Median Score</label>
        <p>
          {medianScore !== null ? (
            <>{Math.round(medianScore * 100)} / 100</>
          ) : (
            <>N/A</>
          )}
        </p>
      </div>
      <div>
        <label>Variability</label>
        <Variability tag="p" varianceScore={stdDev} />
      </div>
    </div>
  );
};

export default DetailData;
