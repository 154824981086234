import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Icon } from '..';
import AsyncButton from '../Forms/AsyncButton';

function ConfirmModal({
  isOpen,
  title,
  onConfirm,
  onCancel,
  confirmButtonLabel,
  children,
}) {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <div className="primary">
          <Icon
            fixedWidth
            icon={['fas', 'exclamation-triangle']}
            className="mr-2"
          />
          {title}
        </div>
      </ModalHeader>
      <ModalBody>
        {children}
        <hr />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>Cancel</Button>
        <AsyncButton
          color="primary"
          onClick={onConfirm}
          data-test="confirm-btn"
        >
          {confirmButtonLabel ?? 'Submit'}
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmModal;
