import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';

import { Icon } from 'js/components';
import { Link } from 'react-router-dom';

const HomePageComponent = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;

  const formikAttributes = (fieldName) => ({
    id: fieldName,
    name: fieldName,
    value: values[fieldName],
    onChange: handleChange,
    onBlur: handleBlur,
    invalid: `${!!(errors[fieldName] && touched[fieldName])}`,
  });

  return (
    <div id="home-page" className="featured-form">
      <div className="full-page-content shallow">
        <div className="assessment-start">
          <div className="container">
            <div className="row">
              <div className="col col-12">
                <div className="join-form central-form">
                  <div className="join-message">
                    <h1 className="join-call">Join a health check</h1>
                    <p>
                      Give and get real feedback on how your team is performing
                    </p>
                  </div>
                  <form onSubmit={isSubmitting ? () => false : handleSubmit}>
                    {errors.passphrase && (
                      <span className="validation-message">
                        {errors.passphrase}
                      </span>
                    )}
                    <div className="relative-wrapper">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your passphrase..."
                        autoComplete="off"
                        autoFocus={true}
                        {...formikAttributes('passphrase')}
                      />
                      {isSubmitting && (
                        <Icon
                          icon={['far', 'circle-notch']}
                          className="loading-passphrase"
                          spin
                        />
                      )}
                    </div>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <Icon icon="rocket" className="btn-icon-left" size="lg" />{' '}
                      Get started now!
                    </button>
                    <center className="mt-3">
                      <p>
                        Already have an account?{' '}
                        <Link to="/pages/login" className="info">
                          Sign in
                        </Link>
                      </p>
                      <p>
                        Don't have an account?{' '}
                        <Link to="/pages/register" className="info">
                          Sign up for free!
                        </Link>
                      </p>
                    </center>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
};

const FormikHomePageComponent = withFormik({
  handleSubmit: (values, bag) => {
    const { setSubmitting, setErrors, props } = bag;

    if (!values.passphrase || values.passphrase.trim().length === 0) {
      setErrors({
        passphrase: 'Please enter a passphrase',
      });
      setSubmitting(false);
    } else {
      setSubmitting(true);
      const { passphrase } = values;

      const passphraseError = "We couldn't find that passphrase. Try again?";
      Promise.resolve(props.onLocateAssessment(passphrase))
        .then(
          (result) => {
            if (result && result.errors && result.errors.passphrase) {
              setErrors({ passphrase: passphraseError });
            }
          },
          () => {
            setErrors({ passphrase: passphraseError });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    }
  },
})(HomePageComponent);

FormikHomePageComponent.propTypes = {
  onLocateAssessment: PropTypes.func,
};

FormikHomePageComponent.defaultProps = {
  onLocateAssessment: () => {},
};

export default FormikHomePageComponent;
