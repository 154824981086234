import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import { RegistrationForm } from 'js/components';

const RegisterPageComponent = (props) => {
  const { onRegister, formState, defaultUsername } = props;

  return (
    <div id="registration-page">
      <div className="full-page-content shallow">
        <div className="registration-page background-page">
          <Container>
            <Row>
              <Col xs={12} md={8} className="ml-auto mr-auto">
                <h1>Sign up</h1>
                <RegistrationForm
                  state={formState}
                  onSave={onRegister}
                  defaultUsername={defaultUsername}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
};

export default RegisterPageComponent;
