import React from 'react';
import { indexOf, orderBy } from 'lodash';

import { PanelHeader } from 'js/components';
import { Card, CardBody } from 'reactstrap';
import AssessmentSummary from './AssessmentSummary';
import AssessmentReviewHeader from './AssessmentReviewHeader';

const AssessmentReview = (props) => {
  const { assessments, team, teamId, assessmentKey, match } = props;
  const orderedAssessments = orderBy(assessments, ['inserted_at'], ['desc']);
  const name = orderedAssessments.length ? orderedAssessments[0].name : '';

  const collatedData = [];

  orderedAssessments.forEach((a) => {
    (a.question_sets || []).forEach((qs, i) => {
      const questionSet = (collatedData[i] = collatedData[i] || {
        name: qs.name,
        questions: [],
      });

      qs.questions.forEach((q, j) => {
        const question = (questionSet.questions[j] = questionSet.questions[
          j
        ] || {
          text: q.text,
          answers: q.answers,
          responses: [],
        });

        question.responses = [
          ...question.responses,
          ...q.responses.map((r) => ({
            assessmentId: a.id,
            value: indexOf(q.answers, (ans) => ans.id === r.answer_id),
            ...r,
          })),
        ];
      });
    });
  });

  return (
    <div className="question-set-history live-assessment">
      <PanelHeader size="flex">
        <div className="panel-content">
          <AssessmentReviewHeader
            name={name}
            team={team}
            teamId={teamId}
            assessmentKey={assessmentKey}
            assessments={orderedAssessments}
          />
        </div>
      </PanelHeader>
      <div className="content">
        <Card>
          <CardBody>
            <div className="question-set-assessments">
              {orderedAssessments.map((a) => (
                <AssessmentSummary
                  key={a.id}
                  team={team}
                  assessment={a}
                  baseUrl={match.url}
                />
              ))}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AssessmentReview;
