import React, { useCallback, useState } from 'react';
import { Card, CardBody, CardTitle, CardFooter, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { faSnooze } from '@fortawesome/pro-light-svg-icons';
import { faLink } from '@fortawesome/pro-regular-svg-icons';

import { Icon } from 'js/components';
import AssessmentActivityItem from './AssessmentActivityItem';

import styles from './WorkspaceSummary.module.scss';
import { allowWorkspaceInvites } from 'js/utils/entitlement-utils';

const WorkspaceSummary = ({
  workspace,
  isMember = false,
  onAddSelf = async () => {},
}) => {
  const memberStyle = isMember ? styles.member : styles.nonmember;
  const [isAdding, setIsAdding] = useState(false);

  const handleAddMe = useCallback(async () => {
    setIsAdding(true);
    await onAddSelf(workspace);
    setIsAdding(false);
  }, [workspace, onAddSelf, setIsAdding]);

  return (
    <Card className={styles['workspace-summary']}>
      <CardTitle className={styles.name}>
        {isMember ? (
          <Link to={`/w/${workspace.id}/dashboard`}>{workspace.name}</Link>
        ) : (
          <>{workspace.name}</>
        )}
      </CardTitle>
      <CardBody className={styles.body}>
        <div className={styles.teams}>
          <div>{workspace.team_count}</div>
          <div className={styles.subtitle}>
            {workspace.team_count === 1 ? 'team' : 'teams'}
          </div>
        </div>
        <div className={styles.history}>
          {workspace.latest_activity?.length > 0 ? (
            <>
              <label>Recent activity</label>
              <ul className={styles.assessments}>
                {workspace.latest_activity.slice(0, 3).map((a) => {
                  return (
                    <AssessmentActivityItem
                      tag="li"
                      key={a.assessment_id}
                      workspaceId={workspace.id}
                      assessment={a}
                    />
                  );
                })}
              </ul>
            </>
          ) : (
            <>
              <label>Recent activity</label>
              <div className={styles['no-activity']}>
                <Icon icon={faSnooze} size="3x" className="icon" />
                <div className="text-muted">No activity, yet&hellip;</div>
              </div>
            </>
          )}
        </div>
      </CardBody>
      <CardFooter className={`${styles.actions} ${memberStyle}`}>
        {!isMember ? (
          allowWorkspaceInvites(workspace?.account) && (
            <div className={styles.content}>
              <Button
                className="btn-inline"
                color="info"
                onClick={handleAddMe}
                disabled={isAdding}
              >
                {isAdding ? (
                  <Icon
                    className="icon-left"
                    fixedWidth
                    icon={['far', 'circle-notch']}
                    spin
                  />
                ) : (
                  <Icon className="icon-left" fixedWidth icon="plus" />
                )}
                Add me
              </Button>
            </div>
          )
        ) : (
          <div className={styles.content}>
            <Link
              className="btn btn-inline btn-success mr-1"
              to={`/w/${workspace.id}/dashboard`}
            >
              <Icon icon={faLink} />
            </Link>
            <Link
              className="btn btn-inline btn-success"
              to={`/w/${workspace.id}/users`}
            >
              <Icon className="icon-left" fixedWidth icon="envelope" />
              Invite others
            </Link>
          </div>
        )}
      </CardFooter>
    </Card>
  );
};

export default WorkspaceSummary;
