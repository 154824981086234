import React from 'react';
import ParticipantScores from './ParticipantScores';
import QuestionScores from './QuestionScores';

import styles from './AssessmentDetailPane.module.scss';

const timeFormatter = new Intl.DateTimeFormat(undefined, {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});

export const AssessmentDetailPane = ({ assessments, lollipopData, filter }) => {
  const assessmentId = filter.criteria.assessmentId;
  const assessment = assessments.find((a) => a.id === assessmentId);
  const lollipopAssessment = lollipopData.find((a) => a.id === assessmentId);

  return (
    <div>
      <label>Assessment created on</label>
      <p>{timeFormatter.format(assessment.inserted_at)}</p>
      <label>Participant scores</label>
      <div className={styles['detail-panel']}>
        <ParticipantScores
          className={styles['detail-panel']}
          lollipopAssessment={lollipopAssessment}
        />
      </div>
      <label>Question results</label>
      <div className={styles['detail-panel']}>
        <QuestionScores
          className={styles['detail-panel']}
          lollipopAssessment={lollipopAssessment}
        />
      </div>
    </div>
  );
};

export default AssessmentDetailPane;
