import keymirror from 'js/utils/keymirror';

const channelPrefix = '@@acct/';

export const types = keymirror(
  {
    SET_ACTIVE_CUSTOMER_ACCOUNT: null,
    JOIN_ACCOUNT_CHANNEL: null,
  },
  { prefix: channelPrefix }
);

export function setActiveCustomerAccount(account) {
  return {
    type: types.SET_ACTIVE_CUSTOMER_ACCOUNT,
    data: account,
  };
}

export function joinAccountChannel(accountId) {
  return {
    type: types.JOIN_ACCOUNT_CHANNEL,
    data: accountId,
  };
}
