import _ from 'lodash';

import { types as AccountTypes } from 'js/actions/account-actions';
import { types as WorkspaceTypes } from 'js/actions/workspace-actions';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { SCHEMAS } from 'js/api/schemas';

const currentWorkspaceId = (state = null, action) => {
  switch (action.type) {
    case WorkspaceTypes.LOAD_WORKSPACES_SUCCESS:
      const keys = Object.keys(action.data.entities.workspaces || {});

      // Do not override if already set
      if (state) {
        return state;
      }

      return keys.length ? keys[0] : null;

    case AccountTypes.LOAD_ME_SUCCESS:
    case AccountTypes.UPGRADE_GUEST_SUCCESS: {
      const me = _.find(action.data.entities.users, () => true);

      let activeWorkspace = state;
      if (
        activeWorkspace === null ||
        _.find(me.workspaces, (x) => x.id === activeWorkspace) === undefined
      ) {
        activeWorkspace = me.workspaces[0] || null;
      }

      return activeWorkspace;
    }

    case WorkspaceTypes.CHANGE_WORKSPACE:
      return action.data;

    case AccountTypes.LOGOUT_SUCCESS:
    case 'API_ERROR/INVALID_TOKEN':
      return null;

    default:
  }

  return state;
};

const selectCurrentWorkspaceId = (store) => store.currentWorkspaceId;
const selectWorkspaceEntities = (store) => store.entities.workspaces;
const selectEntities = (store) => store.entities;

export const selectActiveWorkspace = createSelector(
  selectCurrentWorkspaceId,
  selectWorkspaceEntities,
  selectEntities,
  (currentWorkspaceId, workspaces, entities) =>
    denormalize(workspaces[currentWorkspaceId], SCHEMAS.WORKSPACE, entities)
);

export { currentWorkspaceId };
