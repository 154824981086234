import RedirectToAssessment from './RedirectToAssessment';

const NotLoadedQuestionDetail = (props) => {
  const { assessment } = props;

  // assessment is not loaded, so we expect the question is not found
  if (!assessment) {
    return null;
  }

  // assessment is loaded, but the question is still not found
  return <RedirectToAssessment {...props} />;
};

export default NotLoadedQuestionDetail;
