import React, { useState, useMemo, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';
import {
  getSummaryMetrics,
  getParticipantMetrics,
  generateBaseParticipantMetrics,
} from 'js/utils/question-set';
import {
  mapValues,
  min,
  max,
  reduce,
  keyBy,
  flatMap,
  map,
  isEqual,
} from 'lodash';
import AssessmentTrendsChart from './AssessmentTrendsChart';
import { scaleTime } from '@visx/scale';

import styles from './AssessmentTrends.module.scss';
import AssessmentLollipopChart from './AssessmentLollipopChart/AssessmentLollipopChart';
import { withParentSize } from '@visx/responsive';
import DetailsPane from './DetailsPane/DetailsPane';
import { useFilter } from './useFilter';

function generateKey(questionSetKey, questionKey) {
  return `${questionSetKey}-${questionKey}`;
}

function generatePaletteMap(summaryMetrics) {
  const { questionSets } = summaryMetrics;

  let i = 1;
  return reduce(
    questionSets,
    (acc, questionSet, questionSetKey) =>
      reduce(
        questionSet.questions,
        (acc, question, questionKey) => {
          acc[generateKey(questionSetKey, questionKey)] = {
            questionSetKey,
            questionSet,
            questionKey,
            question,
            className: `palette-chart-dark-${i++}`,
          };
          return acc;
        },
        acc
      ),
    {}
  );
}

function isFilterForQuestion(filter, questionSetKey, questionKey) {
  return (
    (filter?.type === 'question' || filter?.subtype === 'question') &&
    filter?.criteria?.questionSetKey === questionSetKey &&
    filter?.criteria?.questionKey === questionKey
  );
}

function generateAssessmentFilter(assessmentId) {
  return { type: 'assessment', criteria: { assessmentId } };
}
function generateQuestionFilter(questionSetKey, questionKey) {
  return { type: 'question', criteria: { questionSetKey, questionKey } };
}

function generateOverviewQuestionFilter(questionSetKey, questionKey) {
  return {
    type: 'overview',
    subtype: 'question',
    criteria: { questionSetKey, questionKey },
  };
}

function filterHandler(filterLevel, filterGenerator, filterSetter) {
  return (...args) => {
    filterSetter(filterLevel, filterGenerator(...args));
  };
}
const filterLayers = ['transient', 'locked'];

export const AssessmentTrends = withParentSize(
  ({ assessments, parentWidth }) => {
    const [chartWidth, setChartWidth] = useState(0);
    const gridElemRef = useRef(null);
    const chartElemRef = useRef(null);
    const { setFilter, clearFilter, toggleFilter, activeFilter, filterStack } =
      useFilter(filterLayers, isEqual);

    const lollipopFilters = [...filterLayers].reverse().reduce(
      (acc, layer) => {
        const filter = filterStack[layer];
        if (filter?.type === 'question') {
          return {
            ...acc,
            question: filter,
          };
        } else if (filter?.type === 'assessment') {
          if (layer === 'locked') {
            return {
              ...acc,
              assessmentLocked: filter,
            };
          } else if (layer === 'transient') {
            return {
              ...acc,
              assessmentTransient: filter,
            };
          }
        }

        return acc;
      },
      {
        question: null,
        assessmentLocked: null,
        assessmentTransient: null,
      }
    );

    const handleSetTransientQuestionFilter = useMemo(
      () => filterHandler('transient', generateQuestionFilter, setFilter),
      [setFilter]
    );

    const handleToggleLockedQuestionFilter = useMemo(
      () => filterHandler('locked', generateQuestionFilter, toggleFilter),
      [toggleFilter]
    );

    const handleSetTransientAssessmentFilter = useMemo(
      () => filterHandler('transient', generateAssessmentFilter, setFilter),
      [setFilter]
    );

    const handleToggleLockedAssessmentFilter = useMemo(
      () => filterHandler('locked', generateAssessmentFilter, toggleFilter),
      [toggleFilter]
    );

    const handleSliceOver = useMemo(
      () =>
        filterHandler('transient', generateOverviewQuestionFilter, setFilter),
      [setFilter]
    );

    const clearTransientFilter = () => {
      clearFilter('transient');
    };

    useLayoutEffect(() => {
      const { width } = chartElemRef.current?.getBoundingClientRect() ?? {
        width: 0,
      };

      setChartWidth(width);
    }, [parentWidth]);

    const chartMargins = { top: 10, right: 10, bottom: 15, left: 10 };

    const {
      dataPoints,
      summaryData,
      maxDate,
      minDate,
      paletteMap,
      lollipopData,
      className,
    } = useMemo(() => {
      // console.log('memo');
      const assessmentLookup = keyBy(assessments, 'id');
      console.log({
        assessments,
      });
      const summaryData = getSummaryMetrics(assessments);
      const participantMetrics = getParticipantMetrics(assessments);
      const paletteMap = generatePaletteMap(summaryData);
      const assessmentSequence = [...assessments]
        .sort((a, b) => a.inserted_at - b.inserted_at)
        .map((a) => ({ id: a.id, insertedAt: new Date(a.inserted_at) }));

      const dataPoints = mapValues(
        summaryData.questionSets,
        (questionSet, qsKey) => {
          return mapValues(questionSet.questions, (question, qKey) => {
            return {
              text: question.text,
              data: assessmentSequence
                .map(({ id, insertedAt }) => {
                  const questionData =
                    summaryData.assessments[id].questionSets[qsKey].questions[
                      qKey
                    ];
                  if (!questionData) {
                    return null;
                  }

                  const { normalizedScore } = questionData;
                  return { insertedAt, normalizedScore };
                })
                .filter((x) => x.normalizedScore !== null),
            };
          });
        }
      );

      const lollipopData = assessmentSequence.map(({ id }) => {
        const rawAssessment = assessmentLookup[id];

        const baseParticipantMetrics = rawAssessment.participants.reduce(
          (acc, { id }) => ({
            ...acc,
            [id]: generateBaseParticipantMetrics(),
          }),
          {}
        );

        return {
          id,
          ...summaryData.assessments[id],
          participantMetrics: {
            ...baseParticipantMetrics,
            ...participantMetrics[id],
          },
          rawAssessment,
        };
      });

      const minDayRange = 30;
      let minDate = min(assessmentSequence.map(({ insertedAt }) => insertedAt));
      let maxDate = max(assessmentSequence.map(({ insertedAt }) => insertedAt));
      if (!!minDate) {
        const dateRangeDays = (maxDate - minDate) / (1000 * 60 * 60 * 24);

        if (dateRangeDays < minDayRange) {
          const dateRangePadding =
            (minDayRange - dateRangeDays) * 1000 * 60 * 60 * 24;
          minDate = new Date(minDate.getTime() - 0.8 * dateRangePadding);
          maxDate = new Date(maxDate.getTime() + 0.2 * dateRangePadding);
        } else {
          const dateRangePadding = dateRangeDays * 1000 * 60 * 60 * 24 * 0.03;

          minDate = new Date(minDate.getTime() - dateRangePadding);
          maxDate = new Date(maxDate.getTime() + dateRangePadding);
        }
      }

      return {
        minDate,
        maxDate,
        assessmentSequence,
        dataPoints,
        summaryData,
        paletteMap,
        lollipopData,
      };
    }, [assessments]);

    const xScale = scaleTime({
      domain: [minDate, maxDate],
      range: [0, chartWidth - chartMargins.left - chartMargins.right],
    });

    return (
      <div className={className}>
        <div>
          <div className={styles['chart-grid']} ref={gridElemRef}>
            <div className={styles.deets}>
              <DetailsPane
                filterStack={filterStack}
                filter={activeFilter}
                assessments={assessments}
                summaryData={summaryData}
                lollipopData={lollipopData}
                onSliceOver={handleSliceOver}
                onSliceOut={clearTransientFilter}
                paletteMap={paletteMap}
              />
            </div>
            <div className={styles.lollipop} ref={chartElemRef}>
              <label>Scores</label>
              <AssessmentLollipopChart
                questionFilter={lollipopFilters.question}
                transientAssessmentFilter={lollipopFilters.assessmentTransient}
                lockedAssessmentFilter={lollipopFilters.assessmentLocked}
                assessments={lollipopData}
                width={chartWidth}
                height={200}
                margins={{ ...chartMargins, bottom: 15 }}
                xScale={xScale}
                onHoverAssessment={handleSetTransientAssessmentFilter}
                onUnhoverAssessment={clearTransientFilter}
                onClickAssessment={handleToggleLockedAssessmentFilter}
              />
            </div>
            <div className={styles.lines}>
              <label>Trends</label>
              <AssessmentTrendsChart
                width={chartWidth}
                height={200}
                margins={chartMargins}
                assessments={summaryData.assessments}
                dataPoints={dataPoints}
                paletteMap={paletteMap}
                xScale={xScale}
                filter={activeFilter}
                onHoverTrend={handleSetTransientQuestionFilter}
                onUnhoverTrend={clearTransientFilter}
                onClickTrend={handleToggleLockedQuestionFilter}
                generateKey={generateKey}
              />
            </div>
          </div>
        </div>
        <div className={`${styles['question-selectors']}`}>
          <ol className="">
            {flatMap(summaryData.questionSets, (questionSet, questionSetKey) =>
              map(questionSet.questions, (question, questionKey) => {
                const key = generateKey(questionSetKey, questionKey);
                const paletteClassName = paletteMap[key]?.className;
                const [isLockedStyle, isTransientStyle] = [
                  filterStack.locked,
                  filterStack.transient,
                ].map((stack) =>
                  isFilterForQuestion(stack, questionSetKey, questionKey)
                );

                return (
                  <li key={key}>
                    <div
                      style={{ position: 'relative' }}
                      className={classNames(
                        paletteClassName,
                        styles['question-selector'],
                        {
                          [styles.locked]: isLockedStyle,
                          [styles.transient]: isTransientStyle,
                          'palette-muted': !(isLockedStyle || isTransientStyle),
                        }
                      )}
                      onMouseEnter={() =>
                        handleSetTransientQuestionFilter(
                          questionSetKey,
                          questionKey
                        )
                      }
                      onClick={() =>
                        handleToggleLockedQuestionFilter(
                          questionSetKey,
                          questionKey
                        )
                      }
                      onMouseLeave={clearTransientFilter}
                    >
                      <div>
                        <svg
                          height={4}
                          width="100%"
                          style={{ position: 'absolute', top: 0, left: 0 }}
                        >
                          <rect
                            x={0}
                            y={0}
                            height={4}
                            width="100%"
                            className="palette-fill muted"
                          />
                        </svg>
                        <h4 className="">{question.text}</h4>
                      </div>
                    </div>
                  </li>
                );
              })
            )}
          </ol>
        </div>
      </div>
    );
  }
);

export default AssessmentTrends;
