import React from 'react';

import { PanelHeader, Loading } from 'js/components';
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';

const UserComponent = props => {
  const { user } = props;

  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h5">{(user || {}).username}</CardTitle>
          </CardHeader>
          <CardBody>{!user ? <Loading /> : null}</CardBody>
        </Card>
      </div>
    </div>
  );
};

UserComponent.defaultProps = {};

export default UserComponent;
