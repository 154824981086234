import React, { useMemo } from 'react';
import { map, findKey, findLastKey } from 'lodash';
import { scaleLinear } from '@visx/scale';
import { NA } from 'js/utils/question-set';

import { BoxPlot, ViolinPlot } from '@visx/stats';
import { PatternLines } from '@visx/pattern';
import ConditionalLinkWrapper from '../ConditionalLinkWrapper';

const filteredResponseTotals = (responseTotals) => {
  return Object.entries(responseTotals).reduce((acc, [val, count]) => {
    return val === NA ? acc : { ...acc, [val]: count };
  }, {});
};

// BUG: this should extrapolate the results from the nearest actual results if the breakpoint is not an integer
const percentage = (q, responseTotals, percentage) => {
  const breakPoint = q.responseCount * percentage;
  let position = 0;

  if (breakPoint === 0) {
    return null;
  }

  for (let key in responseTotals) {
    const keyTotal = q.responseTotals[key];
    position += keyTotal ?? 0;

    if (position >= breakPoint) {
      return +key;
    }
  }

  return null;
};

const ScorableQuestionResult = ({
  tag: Tag = 'div',
  questionData,
  questionHref,
  height,
  width,
  constrainedHeight,
  constrainedWidth,
  parameters,
  margin,
}) => {
  const { answerDomain } = questionData;

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [constrainedHeight, 0],
        domain: answerDomain,
      }),
    [constrainedHeight, answerDomain]
  );

  const responseTotals = useMemo(
    () => filteredResponseTotals(questionData.responseTotals),
    [questionData]
  );

  const [boxPlotMin, boxPlotMax] = useMemo(
    () => [
      +findKey(responseTotals, (x) => x > 0),
      +findLastKey(responseTotals, (x) => x > 0),
    ],
    [responseTotals]
  );
  const [firstQuartile, median, thirdQuartile] = useMemo(
    () => [
      percentage(questionData, responseTotals, 0.25),
      percentage(questionData, responseTotals, 0.5),
      percentage(questionData, responseTotals, 0.75),
    ],
    [questionData, responseTotals]
  );

  const violinData = useMemo(
    () =>
      map(responseTotals, (count, key) => ({
        value: +key,
        count,
      })),
    [responseTotals]
  );

  return (
    <Tag className="assessment-history-question-box-plot">
      <ConditionalLinkWrapper
        wrap={!!questionHref}
        href={questionHref}
        parameters={parameters}
      >
        <svg viewBox={`0 0 ${width} ${height}`}>
          <PatternLines
            id="hViolinLines"
            height={3}
            width={3}
            strokeWidth={1}
            stroke="#fff"
            orientation={['horizontal']}
          />
          <g transform={`translate(0, ${margin.top})`}>
            <ViolinPlot
              data={violinData}
              top={(height - constrainedHeight) / 2}
              left={(width - constrainedWidth) / 2}
              width={constrainedWidth}
              valueScale={yScale}
              fill="url(#hViolinLines)"
              stroke="red"
            />
            <BoxPlot
              min={boxPlotMin}
              max={boxPlotMax}
              top={(height - constrainedHeight) / 2}
              left={(width - constrainedWidth) / 2 + 0.35 * constrainedWidth}
              firstQuartile={firstQuartile}
              thirdQuartile={thirdQuartile}
              median={median}
              boxWidth={0.3 * constrainedWidth}
              valueScale={yScale}
            />
          </g>
        </svg>
      </ConditionalLinkWrapper>
    </Tag>
  );
};

export default ScorableQuestionResult;
