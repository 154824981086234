import React from 'react';
import { useParams } from 'react-router-dom';
import { Nav } from 'reactstrap';
import { ListNavLink, Icon } from '../..';

const SettingsSubmenu = () => {
  const { accountId } = useParams();

  return (
    <Nav>
      <ListNavLink to={`/a/${accountId}/settings`} exact>
        <i>
          <Icon icon="cog" fixedWidth />
        </i>
        <p>General</p>
      </ListNavLink>
      <ListNavLink to={`/a/${accountId}/settings/billing`}>
        <i>
          <Icon icon="cog" fixedWidth />
        </i>
        <p data-test="account-billing-p-tag">Billing</p>
      </ListNavLink>
    </Nav>
  );
};

export default SettingsSubmenu;
