import { AsyncDisplay, Icon, LoadingMessage } from 'js/components';
import InviteeList from 'js/components/Assessment/InviteeList';
import InviteForm from 'js/components/Forms/InviteForm';
import { getQuestionCount, getResponseCount } from 'js/utils/assessment-utils';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import ConfirmModal from 'js/components/modal/ConfirmModal';
import { isArchived } from 'js/utils/team-utils';

function AssessmentReviewDetailsInvitees({
  onSendInvites,
  sendingInvites,
  onSendReminders,
  queryResult,
  assessment,
  team,
}) {
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [showRemindAllModal, setShowRemindAllModal] = useState(false);

  const reminderEmails = useMemo(() => {
    return (queryResult.data ?? [])
      .filter(
        (i) =>
          (!i.accepted_at ||
            getResponseCount(assessment, i.participant_id) <
              getQuestionCount(assessment)) &&
          i.email
      )
      .map((i) => i.email);
  }, [queryResult, assessment]);

  const handleSendInvites = useCallback(
    (emails) => {
      return onSendInvites(emails).then(() => setShowInviteForm(false));
    },
    [onSendInvites, setShowInviteForm]
  );

  const handleRemindAll = useCallback(() => {
    return (async () => {
      if (reminderEmails.length > 0) {
        await onSendReminders(reminderEmails);
      }
      setShowRemindAllModal(false);
    })();
  }, [reminderEmails, onSendReminders]);

  return (
    <div className="content survey-review-detail">
      <Card tag="section">
        <CardHeader>
          <div className="action-items">
            <Button
              data-test="show-invite-form-btn"
              color="primary"
              disabled={isArchived(team)}
              outline
              onClick={() => setShowInviteForm(true)}
            >
              <Icon icon="envelope" className="btn-icon-left" />
              Invite others
            </Button>
            <Button
              data-test="remind-all-btn"
              color="primary"
              onClick={() => setShowRemindAllModal(true)}
              disabled={isArchived(team)}
              outline
            >
              <Icon icon="redo" className="btn-icon-left" />
              Remind all
            </Button>
          </div>
          <CardTitle tag="h5">Invitees</CardTitle>
        </CardHeader>
        <CardBody>
          {showInviteForm && (
            <Card outline>
              <CardBody>
                <InviteForm
                  onSendInvites={handleSendInvites}
                  loading={sendingInvites}
                  onCancel={() => setShowInviteForm(false)}
                />
              </CardBody>
            </Card>
          )}
          <AsyncDisplay
            isLoading={queryResult.query?.isLoading ?? true}
            isError={!!queryResult.query?.error}
          >
            <AsyncDisplay.Loading>
              <LoadingMessage iconSize="2x">Loading &hellip;</LoadingMessage>
            </AsyncDisplay.Loading>
            <AsyncDisplay.Error>
              <div>Sorry, there was a problem looking up the invitees.</div>
            </AsyncDisplay.Error>
            <AsyncDisplay.Resolved>
              <InviteeList
                invitees={queryResult.data}
                onSendReminders={onSendReminders}
                assessment={assessment}
                team={team}
              />
            </AsyncDisplay.Resolved>
          </AsyncDisplay>
        </CardBody>
      </Card>
      <ConfirmModal
        isOpen={showRemindAllModal}
        title="Send reminder emails?"
        onConfirm={handleRemindAll}
        onCancel={() => setShowRemindAllModal(false)}
        confirmButtonLabel="Send Reminder Emails"
      >
        <p>
          All participants that have not yet completed the full assessment will
          receive a reminder. Please confirm that you want to send
          <strong>{` ${reminderEmails.length} `}</strong>
          reminder emails.
        </p>
      </ConfirmModal>
    </div>
  );
}

export default AssessmentReviewDetailsInvitees;
