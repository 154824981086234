const squadHealthCheck = {
  id: 0,
  name: 'Squad Health Check (General)',
  key: 'squad-health-check-general',
  engine: 'live-assessment',
  question_sets: [
    {
      name: 'Squad Health Check',
      key: 'squad-health-check',
      questions: [
        {
          text: 'Delivering value',
          key: 'delivering-value',
          answers: [
            {
              value: '2',
              text: "We deliver great stuff! We're proud of it and our stakeholders are really happy.",
            },
            { value: '1', text: "We deliver what we can. We're not thrilled with it, but we don't hate it either." },
            {
              value: '0',
              text: 'We deliver crap. We feel ashamed to deliver it. Our stakeholders hate us.',
            },
          ],
        },
        {
          text: 'Fun',
          key: 'fun',
          answers: [
            {
              value: '2',
              text: 'We love going to work and have great fun working together!',
            },
            { value: '1', text: "It's a job." },
            { value: '0', text: 'Boooooooring...' },
          ],
        },
        {
          text: 'Sustainable Pace',
          key: 'sustainable-pace',
          answers: [
            {
              value: '2',
              text: "We're busy but not overburdened. We could keep this up indefinitely.",
            },
            { value: '1', text: 'We might get a little bit singed around the edges, but nobody has burned out yet.' },
            {
              value: '0',
              text: "We're always working late to make ridiculous deadlines. This place is a death march.",
            },
          ],
        },
        {
          text: 'Learning',
          key: 'learning',
          answers: [
            {
              value: '2',
              text: "We're learning lots of interesting stuff all the time!",
            },
            {
              value: '1',
              text: 'We learn when we can, which usually means figuring out how things work so we can finish our tasks.',
            },
            { value: '0', text: 'We never have time to learn anything' },
          ],
        },
        {
          text: 'Mission',
          key: 'mission',
          answers: [
            {
              value: '2',
              text: 'We know exactly why we are here and we’re really excited about it!',
            },
            {
              value: '1',
              text: "We're moving toward something but it doesn't feel like everyone around us is on the same page.",
            },
            {
              value: '0',
              text: "We have no idea why we are here, there's no high-level picture or focus. Our so-called mission is completely unclear and uninspiring.",
            },
          ],
        },
        {
          text: 'Pawns or players',
          key: 'pawns-or-players',
          answers: [
            {
              value: '2',
              text: 'We are in control of our own destiny! We decide what to build and how to build it.',
            },
            { value: '1', text: "We have a little wiggle room, but mostly we're following someone else's plans." },
            {
              value: '0',
              text: 'We are just pawns in a game of chess with no influence over what we build or how we build it.',
            },
          ],
        },
        {
          text: 'Speed',
          key: 'speed',
          answers: [
            {
              value: '2',
              text: 'We get stuff done really quickly! No waiting and no delays.',
            },
            {
              value: '1',
              text: "We just can't seem to keep up momentum. We get stuff done, but it's not as quickly as we'd like.",
            },
            {
              value: '0',
              text: 'We never seem to get anything done. We keep getting stuck or interrupted. Stories keep getting stuck on dependencies.',
            },
          ],
        },
        {
          text: 'Suitable process',
          key: 'suitable-process',
          answers: [
            { value: '2', text: 'Our way of working fits us perfectly!' },
            {
              value: '1',
              text: 'Our way of working is ok. Some things work well while others are like wading through peanut butter.',
            },
            { value: '0', text: 'Our way of working sucks!' },
          ],
        },
        {
          text: 'Support',
          key: 'support',
          answers: [
            {
              value: '2',
              text: 'We always get great support and help when we ask for it!',
            },
            {
              value: '1',
              text: "Support is a crapshoot. We can mostly get what we need, but don't always know when we'll get it.",
            },
            {
              value: '0',
              text: "We keep getting stuck because we can't get the support and help that we ask for.",
            },
          ],
        },
        {
          text: 'Teamwork',
          key: 'teamwork',
          answers: [
            {
              value: '2',
              text: 'We are a totally gelled super-team with awesome collaboration!',
            },
            { value: '1', text: 'We sometimes work together, but mostly stay in our own lanes.' },
            {
              value: '0',
              text: 'We are a bunch of individuals that neither know nor care about what the other people in the squad are doing.',
            },
          ],
        },
      ],
    },
  ],
};

export default squadHealthCheck;
