import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { some, noop } from 'lodash';

import { Link } from 'react-router-dom';
import { scaleColor, getAverageQuestionScore } from '../utilities';
import { Icon } from 'js/components';
import FileDownload from 'js/containers/FileDownload';
import { useState } from 'react';
import ConfirmArchiveAssessModal from 'js/components/Assessment/ConfirmArchiveAssessModal';

const ListItem = ({
  assessment,
  onToggleAssessmentLock,
  onDeleteAssessment,
  team,
}) => {
  const [assessToArchive, setAssessToArchive] = useState(null);
  const setParentAssmToArchive = (assess) => {
    setAssessToArchive(assess);
  };
  const insertedAt = moment(assessment.inserted_at);

  const handleToggleAssessmentLock = () => {
    onToggleAssessmentLock(assessment);
  };

  const handleDeleteAssessment = () => {
    onDeleteAssessment(assessment);
  };

  const hasResponses = some(assessment.question_sets || [], (qs) =>
    some(qs.questions || [], (q) => (q.responses || []).length > 0)
  );

  const { workspace_id, team_id, key, id } = assessment;
  const url = `/w/${workspace_id}/teams/${team_id}/assessments/${key}/detail/${id}`;

  return (
    <div className="assessment-list-item live-assessment-list-item">
      <div className="timestamp">
        <Link to={url}>{insertedAt.format('MMMM Do YYYY, h:mm:ss a')}</Link>
      </div>
      <div className="action-items">
        <button
          className="btn btn-outline-info btn-inline"
          onClick={handleToggleAssessmentLock}
        >
          <Icon
            icon={assessment.is_locked ? 'lock-alt' : 'lock-open-alt'}
            fixedWidth
          />
        </button>
        <button
          className="btn btn-outline-info btn-inline"
          onClick={
            hasResponses
              ? () => setAssessToArchive(assessment)
              : handleDeleteAssessment
          }
        >
          <Icon icon="trash-alt" fixedWidth />
        </button>
        {hasResponses ? (
          <FileDownload
            href={`/reports/assessment-results?assessment_id=${assessment.id}`}
            className="btn btn-outline-info btn-inline"
          >
            <Icon icon="cloud-download" fixedWidth />
          </FileDownload>
        ) : null}
      </div>
      <div className="spark">
        {assessment.question_sets.map((qs) => {
          return (
            <ul key={qs.id} className="question-set-overview">
              {qs.questions.map((q) => {
                const averageQuestionScore = getAverageQuestionScore(q);

                const style =
                  averageQuestionScore === null
                    ? {}
                    : {
                        backgroundColor: scaleColor(averageQuestionScore),
                      };

                return (
                  <li key={q.id} style={style} className="question-overview" />
                );
              })}
            </ul>
          );
        })}
      </div>
      <ConfirmArchiveAssessModal
        assmToArchive={assessToArchive}
        setParentAssmToArchive={setParentAssmToArchive}
        onDeleteAssessment={onDeleteAssessment}
      />
    </div>
  );
};

ListItem.propTypes = {
  onToggleAssessmentLock: PropTypes.func,
  onDeleteAssessment: PropTypes.func,
};

ListItem.defaultProps = {
  onToggleAssessmentLock: noop,
  onDeleteAssessment: noop,
};

export default ListItem;
