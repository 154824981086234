import React from 'react';
import { Route } from 'react-router-dom';

const LayoutRoute = ({ component: Component, layout: Layout, ...props }) => {
  return (
    <Route
      {...props}
      render={renderProps => (
        <Layout {...props} {...renderProps} component={Component} />
      )}
    />
  );
};

export default LayoutRoute;
