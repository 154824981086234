import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import Question from './Question';

const QuestionSet = ({
  participantId,
  questionSet,
  onRespond,
  onUnrespond,
}) => {
  const { name, questions } = questionSet;

  const handleQuestionResponse = (questionId, answerId, answerValue) => {
    const question = questions.find((q) => q.id === questionId);
    const answer = question.answers.find((a) => a.id === answerId) ?? {
      id: answerId,
      value: answerValue,
      is_na: false,
    };

    onRespond(questionSet, question, answer);
  };

  const handleQuestionUnresponse = useCallback(
    (questionId) => {
      const question = questionSet.questions.find((q) => q.id === questionId);

      onUnrespond(questionSet, question);
    },
    [questionSet, onUnrespond]
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">{name}</CardTitle>
      </CardHeader>
      <CardBody>
        <ul className="list-unstyled">
          {questions.map((q) => {
            const response = _.find(
              q.responses,
              (r) => r?.participant_id === participantId
            );

            return (
              <li key={q.id} className="question-set-question">
                <Question
                  question={q}
                  response={response}
                  onRespond={handleQuestionResponse}
                  onUnrespond={handleQuestionUnresponse}
                />
              </li>
            );
          })}
        </ul>
      </CardBody>
    </Card>
  );
};

QuestionSet.propTypes = {
  onRespond: PropTypes.func,
};

QuestionSet.defaultProps = {
  onRespond: () => {},
};

export default QuestionSet;
