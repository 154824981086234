import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { PanelHeader } from 'js/components';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import AssessmentGroupSummary from 'js/components/Assessment/AssessmentGroupSummary';

const AssessmentsComponent = props => {
  const { assessments, onAssess } = props;

  const groupedAssessments = _.orderBy(
    _.map(_.groupBy(assessments, 'key'), (group, key) => {
      const sortedAssessments = _.orderBy(group, ['inserted_at'], ['desc']);

      return {
        key,
        name: sortedAssessments[0].name,
        assessments: sortedAssessments
      };
    }),
    [x => x.assessments[0].inserted_at],
    ['desc']
  );

  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Recent Activity</CardTitle>
          </CardHeader>
          <CardBody>
            <ul className="list-unstyled">
              {groupedAssessments.map(x => {
                return (
                  <li key={x.key}>
                    <AssessmentGroupSummary group={x} onAssess={onAssess} />
                  </li>
                );
              })}
            </ul>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

AssessmentsComponent.propTypes = {
  onAssess: PropTypes.func
};

AssessmentsComponent.defaultProps = {
  onAssess: () => {}
};

export default AssessmentsComponent;
