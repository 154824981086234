import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { trimEnd } from 'lodash';
import { isActive } from 'js/utils/team-utils';

import {
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  UncontrolledAlert,
} from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  PanelHeader,
  TeamList,
  Icon,
  AsyncDisplay,
  LoadingMessage,
  ReplaceLink,
} from 'js/components';
import { maxTeamsPerAccount } from 'js/utils/entitlement-utils';
import ArchivedTeamList from 'js/components/Team/ArchivedTeamList';

const TeamsRouteComponent = (props) => {
  const {
    teams,
    canModifyTeams,
    workspace,
    onArchiveTeam,
    onRestoreTeam,
    onFavoriteTeam,
    loaded,
    activeAccount,
  } = props;
  const maxTeams = maxTeamsPerAccount(activeAccount);
  const match = useRouteMatch();
  const searchParams = new URLSearchParams(window.location.search);
  const { activeTeams, archivedTeams } = useMemo(() => {
    return teams
      ?.sort((a, b) => a.name?.localeCompare(b.name) ?? -1)
      .reduce(
        (acc, t) => {
          if (isActive(t)) {
            acc.activeTeams.push(t);
          } else {
            acc.archivedTeams.push(t);
          }
          return acc;
        },
        { activeTeams: [], archivedTeams: [] }
      );
  }, [teams]);

  const canAddTeams =
    maxTeams > (activeAccount?.statistics?.active_teams_count ?? 0);

  return (
    <div>
      <PanelHeader size="flex">
        <div className="panel-body-no-label px-4">
          <div className="content-fluid">
            <div className="row">
              <div className="col-12 col-md-9">
                <h1>Teams</h1>
              </div>
              <div className="panel-actions col-12 col-md-3">
                {canModifyTeams && (
                  <Link
                    disabled={!canAddTeams}
                    className="btn btn-primary create-link"
                    to={`/w/${workspace.id}/teams/add`}
                  >
                    <Icon icon="plus" className="btn-icon-left" /> Create
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Nav className="tab-nav nav-underline">
            <NavItem>
              <NavLink
                tag={ReplaceLink}
                active={!searchParams.has('archived')}
                data-view="active"
                to={`${trimEnd(match.url, '/')}`}
              >
                Active
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={ReplaceLink}
                active={searchParams.has('archived')}
                data-view="results"
                to={`${trimEnd(match.url, '/')}?archived`}
              >
                Archived
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </PanelHeader>
      <div className="content">
        <Card>
          {loaded && activeAccount && !canAddTeams && (
            <CardHeader>
              <UncontrolledAlert color="info">
                {workspace?.account?.product?.key === 'free' ? (
                  <>
                    Waypointr's free tier only supports{' '}
                    {maxTeams === 1 ? 'one team' : `${maxTeams} teams`}.{' '}
                    <Link to={`/a/${workspace.account.id}/settings/billing`}>
                      Upgrade to a paid plan
                    </Link>{' '}
                    to manage multiple teams and unlock a bunch of other great
                    stuff.
                  </>
                ) : (
                  <>
                    You've hit the team limit on your current plan. Give your
                    account admin a nudge and ask them to add some more
                    licenses.
                  </>
                )}
              </UncontrolledAlert>
            </CardHeader>
          )}

          <CardBody>
            <AsyncDisplay isLoading={!loaded} isError={false}>
              <AsyncDisplay.Loading>
                <LoadingMessage iconSize="2x">
                  Loading your teams&hellip;
                </LoadingMessage>
              </AsyncDisplay.Loading>
              <AsyncDisplay.Resolved>
                {searchParams.has('archived') ? (
                  <>
                    <div>
                      {(archivedTeams?.length || 0) > 0 ? (
                        <ArchivedTeamList
                          teams={archivedTeams}
                          canModifyTeams={canModifyTeams}
                          canRestoreTeams={canAddTeams}
                          onRestoreTeam={onRestoreTeam}
                        />
                      ) : (
                        <div className="media text-muted mt-4 mb-4">
                          <Icon
                            icon={['fal', 'spider-web']}
                            size="4x"
                            className="mr-3"
                            fixedWidth
                          />
                          <div className="media-body">
                            <p>You don't have any archived teams.</p>
                            <p>
                              Archiving a team suspends it from your account
                              while preserving its data for historical purposes.
                              We recommend archiving teams you no longer need to
                              keep your costs down and your account tidy.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {activeTeams?.length > 0 ? (
                      <TeamList
                        teams={activeTeams}
                        onArchiveTeam={onArchiveTeam}
                        onFavoriteTeam={onFavoriteTeam}
                        canModifyTeams={canModifyTeams}
                      />
                    ) : (
                      <div className="media text-muted mt-4 mb-4">
                        <Icon
                          icon={['far', 'star-exclamation']}
                          size="4x"
                          className="mr-3"
                          fixedWidth
                        />
                        <div className="media-body">
                          <p>
                            You don't have any{' '}
                            {archivedTeams.length > 0
                              ? 'active teams'
                              : 'teams yet'}
                            .
                          </p>
                          <p>
                            Creating a team allows you track their progress over
                            time.
                          </p>
                          {canModifyTeams && (
                            <div>
                              <Link
                                data-test="create-team-link"
                                className="btn btn-primary create-link"
                                to={`/w/${workspace.id}/teams/add`}
                              >
                                <Icon icon="plus" className="btn-icon-left" />{' '}
                                Create a Team
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </AsyncDisplay.Resolved>
            </AsyncDisplay>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

TeamsRouteComponent.propTypes = {
  workspace: PropTypes.any.isRequired,
  teams: PropTypes.arrayOf(PropTypes.object),
  loaded: PropTypes.bool.isRequired,
  onArchiveTeam: PropTypes.func,
  onRestoreTeam: PropTypes.func,
  onFavoriteTeam: PropTypes.func,
};

TeamsRouteComponent.defaultProps = {
  teams: [],
  onArchiveTeam: () => {},
  onRestoreTeam: () => {},
  onFavoriteTeam: () => {},
};

export default TeamsRouteComponent;
