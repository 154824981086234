import { types as AssessmentTypes } from 'js/actions/assessment-actions';
import { types as AccountTypes } from 'js/actions/account-actions';

const initialState = {};

const participation = (state = initialState, action) => {
  switch (action.type) {
    case AssessmentTypes.COMPLETE_PARTICIPANT_WELCOME_SUCCESS:
    case AssessmentTypes.PARTICIPATE_IN_ASSESSMENT_SUCCESS: {
      const { assessment_id } = action.data;

      return {
        ...state,
        [assessment_id]: action.data,
      };
    }

    case AccountTypes.LOGOUT:
    case AccountTypes.LOGOUT_SUCCESS:
    case AssessmentTypes.CLEAR_PARTICIPATION_SESSION:
    case 'API_ERROR/INVALID_TOKEN':
      return initialState;

    default:
  }

  return state;
};

export default participation;
