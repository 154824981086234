import keymirror from 'keymirror';
import { normalize } from 'normalizr';
import { SCHEMAS } from '../api/schemas';
import { spreadApiKeys } from '../utils';

export const types = keymirror({
  ...spreadApiKeys('LOAD_GOALS')
});

export function load() {
  return dispatch =>
    dispatch({
      type: types.LOAD_GOALS_SUCCESS,
      data: normalize(
        [
          { id: 0, ordinal: 0, name: 'Quality of Systems' },
          { id: 1, ordinal: 1, name: 'Engagement of People' },
          { id: 2, ordinal: 2, name: 'Speed to Value' },
          { id: 3, ordinal: 3, name: 'Predictability of Delivery' }
        ],
        SCHEMAS.GOAL_ARRAY
      )
    });
}
