import keymirror from 'js/utils/keymirror';

export const types = keymirror({
  DOWNLOAD_FILE: null
});

export function download(path, options) {
  return {
    type: 'DOWNLOAD_FILE',
    path,
    options
  };
}
