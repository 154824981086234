import { safeBusinessAgility } from '../safe-business-agility';
import { changeReadinessQuestionSets } from './question-sets/change-readiness';

export const businessAgilityExtended = {
  name: 'Business Agility - Extended',
  key: 'business-agility-extended',
  question_sets: [
    ...safeBusinessAgility.question_sets.map((qs) => ({
      ...qs,
      category: 'business-agility',
    })),
    ...changeReadinessQuestionSets,
  ],
};

export default businessAgilityExtended;
