import { trueToFalse as answers } from './base-answers';

export const productDelivery = {
  name: 'Agile Product Delivery (APD)',
  key: 'agile-product-delivery',
  category: 'product-delivery',
  questions: [
    {
      text: 'We adopt a customer centric approach to defining our solutions',
      key: 'customer-centric-design',
      answers,
    },
    {
      text: 'We validate solutions with our customers throughout development',
      key: 'validate-with-customers',
      answers,
    },
    {
      text: 'The teams of Agile teams plan together on a cadence',
      key: 'cross-team-planning-on-cadence',
      answers,
    },
    {
      text: 'The teams of Agile teams regularly demonstrate the integrated solution',
      key: 'regular-integrated-demos',
      answers,
    },
    {
      text: 'We can release solutions as needed',
      key: 'release-on-demand',
      answers,
    },
    {
      text: 'We continuously invest in improving our delivery infrastructure',
      key: 'regular-investment-in-delivery-infrastructure',
      answers,
    },
  ],
};
