export function findChild(org, childId) {
  if (org.id === childId) {
    return org;
  }
  for (const child of org.child_orgs) {
    const found = findChild(child, childId);
    if (found) {
      return found;
    }
  }
  return undefined;
}

// NOTE: this is only necessary because the native d3 node.find() isn't available
// with the current library versions (not sure if it's the d3 reference or @visx)
// TODO: remove the need for this
export function findNode(node, comparer) {
  if (comparer(node)) {
    return node;
  }

  for (const child of node.children ?? []) {
    const found = findNode(child, comparer);
    if (found) {
      return found;
    }
  }
  return undefined;
}

export function pathToChild(org, childId) {
  if (org.id === childId) {
    return [org];
  }

  for (const child of org.child_orgs ?? org.children) {
    const path = pathToChild(child, childId);
    if (path) {
      return [org, ...path];
    }
  }

  return undefined;
}

export function descendentsOfNode(node) {
  return node.children.flatMap((child) => {
    return [child, ...descendentsOfNode(child)];
  });
}

export function normalizeOrg(orgOrTeam, type = 'org', depth = 0) {
  const children = [
    ...(orgOrTeam.child_orgs?.map((child) =>
      normalizeOrg(child, 'org', depth + 1)
    ) ?? []),
    ...(orgOrTeam.teams?.map((team) => normalizeOrg(team, 'team', depth + 1)) ??
      []),
  ];
  return {
    id: orgOrTeam.id,
    name: orgOrTeam.name,
    type,
    isRoot: orgOrTeam.is_root ?? false,
    depthId: `${depth}_${orgOrTeam.id}`,
    children,
  };
}

export function allTeams(node) {
  if (node.type === 'team') {
    return [node];
  }

  return node.children.flatMap((c) => allTeams(c));
}
