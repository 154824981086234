import React, { useMemo } from 'react';
import { Card, CardBody } from 'reactstrap';
import { keyBy, reduce, mapValues } from 'lodash';
import { hierarchy } from '@visx/hierarchy';
import assessmentTemplates from 'js/data/assessment-templates';
import ScorecardNode from './ScorecardNode';

// const mockOrg = {
//   type: 'root',
//   name: 'Workspace name',
//   child_orgs: [
//     {
//       name: 'Provider',
//       child_orgs: [],
//       teams: [
//         { id: 1, name: 'Provider - Team 1' },
//         { id: 2, name: 'Provider - Team 2' },
//         { id: 3, name: 'Provider - Team 3' },
//       ],
//     },
//     {
//       name: 'Childless',
//       child_orgs: [],
//       teams: [],
//     },
//     {
//       type: 'group',
//       name: 'Member',
//       child_orgs: [
//         {
//           type: 'group',
//           name: 'Member - Train 1',
//           child_orgs: [],
//           teams: [
//             { id: 3, name: 'Member - Team 1' },
//             { id: 4, name: 'Member - Team 2' },
//           ],
//         },
//         {
//           name: 'Member - Train 2',
//           child_orgs: [
//             {
//               name: 'Member - Subtrain 2a',
//               child_orgs: [],
//               teams: [
//                 { id: 7, name: 'Member - Team 5' },
//                 { id: 8, name: 'Member - Team 6' },
//               ],
//             },
//           ],
//           teams: [
//             { id: 5, name: 'Member - Team 3' },
//             { id: 6, name: 'Member - Team 4' },
//           ],
//         },
//       ],
//       teams: [],
//     },
//   ],
//   teams: [],
// };

// const mockScores = [
//   {
//     node_id: 1,
//     node_name: 'Sketch',
//     node_type: 'team',
//     question_scores: [
//       { score: 0.42, question_key: 'delivering-value', question_set_key: 'squad-health-check' },
//       { score: 0.14, question_key: 'easy-to-release', question_set_key: 'squad-health-check' },
//       { score: 0.78, question_key: 'fun', question_set_key: 'squad-health-check' },
//       { score: 0.37, question_key: 'health-of-codebase', question_set_key: 'squad-health-check' },
//       { score: 0.50, question_key: 'learning', question_set_key: 'squad-health-check' },
//       { score: 0.50, question_key: 'mission', question_set_key: 'squad-health-check' },
//       { score: 0.14, question_key: 'pawns-or-players', question_set_key: 'squad-health-check' },
//       { score: 0.64, question_key: 'speed', question_set_key: 'squad-health-check' },
//       { score: 0.21, question_key: 'suitable-process', question_set_key: 'squad-health-check' },
//       { score: 0.64, question_key: 'support', question_set_key: 'squad-health-check' },
//       { score: 0.71, question_key: 'teamwork', question_set_key: 'squad-health-check' },
//     ],
//   },
//   {
//     node_id: 2,
//     node_name: 'Testing team',
//     node_type: 'team',
//     question_scores: [
//       { score: 0.50, question_key: 'teamwork', question_set_key: 'squad-health-check' },
//     ],
//   },
//   {
//     node_id: 3,
//     node_name: 'Sketch',
//     node_type: 'team',
//     question_scores: [
//       { score: 0.42, question_key: 'delivering-value', question_set_key: 'squad-health-check' },
//       { score: 0.14, question_key: 'easy-to-release', question_set_key: 'squad-health-check' },
//       { score: 0.78, question_key: 'fun', question_set_key: 'squad-health-check' },
//       { score: 0.37, question_key: 'health-of-codebase', question_set_key: 'squad-health-check' },
//       { score: 0.50, question_key: 'learning', question_set_key: 'squad-health-check' },
//       { score: 0.50, question_key: 'mission', question_set_key: 'squad-health-check' },
//       { score: 0.14, question_key: 'pawns-or-players', question_set_key: 'squad-health-check' },
//       { score: 0.64, question_key: 'speed', question_set_key: 'squad-health-check' },
//       { score: 0.21, question_key: 'suitable-process', question_set_key: 'squad-health-check' },
//       { score: 0.64, question_key: 'support', question_set_key: 'squad-health-check' },
//       { score: 0.71, question_key: 'teamwork', question_set_key: 'squad-health-check' },
//     ],
//   },
//   {
//     node_id: 4,
//     node_name: 'Sketch',
//     node_type: 'team',
//     question_scores: [
//       { score: 0.42, question_key: 'delivering-value', question_set_key: 'squad-health-check' },
//       { score: 0.14, question_key: 'easy-to-release', question_set_key: 'squad-health-check' },
//       { score: 0.78, question_key: 'fun', question_set_key: 'squad-health-check' },
//       { score: 0.37, question_key: 'health-of-codebase', question_set_key: 'squad-health-check' },
//       { score: 0.50, question_key: 'learning', question_set_key: 'squad-health-check' },
//       { score: 0.50, question_key: 'mission', question_set_key: 'squad-health-check' },
//       { score: 0.14, question_key: 'pawns-or-players', question_set_key: 'squad-health-check' },
//       { score: 0.64, question_key: 'speed', question_set_key: 'squad-health-check' },
//       { score: 0.21, question_key: 'suitable-process', question_set_key: 'squad-health-check' },
//       { score: 0.64, question_key: 'support', question_set_key: 'squad-health-check' },
//       { score: 0.71, question_key: 'teamwork', question_set_key: 'squad-health-check' },
//     ],
//   },
//   {
//     node_id: 8,
//     node_name: 'Sketch',
//     node_type: 'team',
//     question_scores: [
//       { score: 0.42, question_key: 'delivering-value', question_set_key: 'squad-health-check' },
//       { score: 0.14, question_key: 'easy-to-release', question_set_key: 'squad-health-check' },
//       { score: 0.78, question_key: 'fun', question_set_key: 'squad-health-check' },
//       { score: 0.37, question_key: 'health-of-codebase', question_set_key: 'squad-health-check' },
//       { score: 0.50, question_key: 'learning', question_set_key: 'squad-health-check' },
//       { score: 0.50, question_key: 'mission', question_set_key: 'squad-health-check' },
//       { score: 0.14, question_key: 'pawns-or-players', question_set_key: 'squad-health-check' },
//       { score: 0.64, question_key: 'speed', question_set_key: 'squad-health-check' },
//       { score: 0.21, question_key: 'suitable-process', question_set_key: 'squad-health-check' },
//       { score: 0.64, question_key: 'support', question_set_key: 'squad-health-check' },
//       { score: 0.71, question_key: 'teamwork', question_set_key: 'squad-health-check' },
//     ],
//   },
//   {
//     node_id: 1,
//     node_name: 'Sketch',
//     node_type: 'team',
//     question_scores: [
//       { score: 0.42, question_key: 'delivering-value', question_set_key: 'squad-health-check' },
//       { score: 0.14, question_key: 'easy-to-release', question_set_key: 'squad-health-check' },
//       { score: 0.78, question_key: 'fun', question_set_key: 'squad-health-check' },
//       { score: 0.37, question_key: 'health-of-codebase', question_set_key: 'squad-health-check' },
//       { score: 0.50, question_key: 'learning', question_set_key: 'squad-health-check' },
//       { score: 0.50, question_key: 'mission', question_set_key: 'squad-health-check' },
//       { score: 0.14, question_key: 'pawns-or-players', question_set_key: 'squad-health-check' },
//       { score: 0.64, question_key: 'speed', question_set_key: 'squad-health-check' },
//       { score: 0.21, question_key: 'suitable-process', question_set_key: 'squad-health-check' },
//       { score: 0.64, question_key: 'support', question_set_key: 'squad-health-check' },
//       { score: 0.71, question_key: 'teamwork', question_set_key: 'squad-health-check' },
//     ],
//   },
// ];

const buildNode = (orgNode, teamData) => {
  const orgNodes = orgNode.child_orgs.map((node) => buildNode(node, teamData));
  const teamNodes = orgNode.teams.map((team) => ({
    id: team.id,
    name: team.name,
    type: 'team',
    scores: keyBy(
      teamData[team.id]?.scores ?? [],
      ({ question_key, question_set_key }) =>
        `${question_set_key}|${question_key}`
    ),
  }));
  const children = [...orgNodes, ...teamNodes];

  const scoreTotals = children.reduce((acc, child) => {
    const { scores } = child;
    const result = reduce(
      scores,
      (childAcc, { score, question_key, question_set_key }, key) => {
        const { total, count } = childAcc[key] || { total: 0, count: 0 };
        childAcc[key] = {
          total: total + score,
          count: count + 1,
          question_key,
          question_set_key,
        };
        return childAcc;
      },
      acc
    );
    return result;
  }, {});

  const scores = mapValues(
    scoreTotals,
    ({ total, count, question_key, question_set_key }) => ({
      score: count > 0 ? total / count : null,
      question_key,
      question_set_key,
    })
  );

  return {
    ...orgNode,
    children,
    scores,
  };
};

// TODO: make this handle multiple question sets
const SquadScorecard = ({ organization, questionScores, assessmentKey }) => {
  const teamScores = useMemo(
    () => keyBy(questionScores, 'team_id'),
    [questionScores]
  );

  const tree = useMemo(
    () => hierarchy(buildNode(organization, teamScores)),
    [organization, teamScores]
  );
  const columnData = useMemo(() => {
    const { assessment } = assessmentTemplates.find(
      ({ key }) => key === assessmentKey
    );

    const data = assessment.question_sets
      .map((qs) =>
        qs.questions.map(({ key, text }) => ({
          combinedKey: `${qs.key}|${key}`,
          questionKey: key,
          questionSetKey: qs.key,
          text,
        }))
      )
      .flat();

    for (const qs of questionScores) {
      for (const s of qs.scores) {
        const key = `${s.question_set_key}|${s.question_key}`;
        if (!data.some((c) => c.combinedKey === key)) {
          data.push({
            combinedKey: key,
            questionKey: s.question_key,
            questionSetKey: s.question_set_key,
            text: s.question_text,
          });
        }
      }
    }

    return data;
  }, [assessmentKey, questionScores]);

  return (
    <Card>
      <CardBody>
        <table className="squad-scorecard-grid">
          <thead>
            <tr>
              <th className="squad-scorecard-name squad-scorecard-header"></th>

              {columnData.map(({ combinedKey, text }) => (
                <th
                  className="squad-scorecard-question squad-scorecard-header"
                  key={combinedKey}
                >
                  {text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <ScorecardNode node={tree} columnData={columnData} />
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

export default SquadScorecard;
