import React from 'react';

import { AsyncDisplay, Icon, LoadingMessage, PanelHeader } from 'js/components';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  UncontrolledAlert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import UserList from 'js/components/User/UserList';
import { allowWorkspaceInvites } from 'js/utils/entitlement-utils';

const UsersComponent = (props) => {
  const {
    me,
    workspaceUsers,
    loading,
    isError,
    activeWorkspace,
    canInviteUser,
    onUpdateRole,
    onResendInvite,
    onRemoveUser,
    updateUserSubmissionValues,
  } = props;

  const handleEditUserFormSubmit = (values) => {
    return onUpdateRole({ id: me.id, ...values });
  };

  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        {!allowWorkspaceInvites(activeWorkspace?.account) && (
          <UncontrolledAlert color="info">
            <>
              Waypointr's free tier only supports one user.{' '}
              <Link to={`/a/${activeWorkspace.account.id}/settings/billing`}>
                Upgrade to a paid plan
              </Link>{' '}
              to manage multiple users and unlock a bunch of other great stuff.
            </>
          </UncontrolledAlert>
        )}
        <Card>
          <CardHeader>
            {canInviteUser && (
              <div className="action-items">
                <Link
                  data-test="add-user-btn"
                  className="btn btn-primary float-right create-link"
                  to={`/w/${activeWorkspace.id}/users/invite`}
                  disabled={!allowWorkspaceInvites(activeWorkspace?.account)}
                >
                  <Icon icon="plus" className="btn-icon-left" />
                  Invite User
                </Link>
              </div>
            )}
            <CardTitle tag="h5">Users</CardTitle>
          </CardHeader>
          <CardBody>
            <AsyncDisplay isLoading={loading} isError={isError}>
              <AsyncDisplay.Loading>
                <LoadingMessage iconSize="2x">
                  Loading users&hellip;
                </LoadingMessage>
              </AsyncDisplay.Loading>
              <AsyncDisplay.Error>
                <div>Sorry, there was a problem looking up the users.</div>
              </AsyncDisplay.Error>
              <AsyncDisplay.Resolved>
                <UserList
                  onUpdateRole={onUpdateRole}
                  handleSubmit={handleEditUserFormSubmit}
                  workspaceUsers={workspaceUsers}
                  onResendInvite={onResendInvite}
                  onRemoveUser={onRemoveUser}
                  me={me}
                  submissionValues={updateUserSubmissionValues}
                  activeWorkspace={activeWorkspace}
                />
              </AsyncDisplay.Resolved>
            </AsyncDisplay>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default UsersComponent;
