import React, { useMemo } from 'react';
import { withParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { Grid } from '@visx/grid';

const SetDetailChart = ({
  parentWidth,
  parentHeight,
  height = 200,
  data,
  barSpacing = 10,
  margin,
}) => {
  const margins = { top: 10, right: 0, bottom: 0, left: 0, ...margin };
  const chartWidth = parentWidth - margins.left - margins.right;
  const chartHeight = (height || parentHeight) - margins.top - margins.bottom;
  const barWidth = (chartWidth - (data.length + 1) * barSpacing) / data.length;

  const yScale = useMemo(
    () => scaleLinear({ domain: [0, 1], range: [chartHeight, 0] }),
    [chartHeight]
  );
  const xScale = useMemo(
    () => scaleLinear({ domain: [0, 1], range: [0, chartWidth] }),
    [chartWidth]
  );

  return (
    <svg
      viewBox={`0 0 ${parentWidth} ${height || parentHeight}`}
      className="set-details-chart"
    >
      <g transform={`translate(${margins.left} ${margins.top})`}>
        <g>
          {data.map((d, i) => {
            if (d.normalizedScore === null) {
              return null;
            }
            const y = yScale(d.normalizedScore);
            const barHeight = chartHeight - y;
            return (
              <g
                key={d.key}
                className={d.className}
                transform={`translate(${
                  barSpacing + i * (barSpacing + barWidth)
                })`}
              >
                <rect
                  x={0}
                  y={chartHeight - barHeight}
                  width={barWidth}
                  height={barHeight}
                />
                <g className="detail-points">
                  {(d.details || []).map((detail, i) => {
                    const detailX = barWidth / 2;
                    const detailY = yScale(detail.normalizedScore);
                    return (
                      <g key={i}>
                        <circle
                          cx={detailX}
                          cy={detailY}
                          r={barSpacing / 2 + 1}
                          className="detail-point-stroke"
                        />
                        <circle
                          cx={detailX}
                          cy={detailY}
                          r={barSpacing / 2}
                          className="detail-point"
                        />
                      </g>
                    );
                  })}
                </g>
              </g>
            );
          })}
        </g>
        <g className="axis">
          <path
            d={`M0 0 L0 ${chartHeight} L${chartWidth} ${chartHeight}`}
            className="chart-axis"
          />
          {/* x={0}
            y={0}
            width={chartWidth}
            height={chartHeight}
            className="chart-axis"
            fill="none"
          /> */}
          <Grid
            className="chart-grid"
            width={chartWidth}
            height={chartHeight}
            xScale={xScale}
            yScale={yScale}
            rowTickValues={[0.2, 0.4, 0.6, 0.8, 1]}
            columnTickValues={[1]}
          />
        </g>
      </g>
    </svg>
  );
};

export default withParentSize(SetDetailChart);
