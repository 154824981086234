import { Icon } from 'js/components';
import React from 'react';
import styles from './PricingFeaturesList.module.scss';

const PricingFeaturesList = ({ features }) => (
  <ul className={styles.pricingFeatures}>
    {features.map((f, i) => (
      <li key={`${i}-${f}`}>
        <Icon icon={['far', 'check']} className="icon" />
        {f}
      </li>
    ))}
  </ul>
);

export default PricingFeaturesList;
