import React from 'react';
import { map } from 'lodash';
import { scaleLinear } from '@visx/scale';

import { ViolinPlot } from '@visx/stats';
import { CategoryLayout } from 'js/components/Charts/CategoryLayout';
import ConditionalLinkWrapper from '../ConditionalLinkWrapper';
import NotScorableQuestionResult from './NotScorableQuestionResult';
import ScorableQuestionResult from './ScorableQuestionResult';
import NoResponseQuestionResult from './NoResponseQuestionResult';

function getQuestionResult(questionData) {
  if (questionData.responseCount === 0) {
    return NoResponseQuestionResult;
  }

  switch (questionData.type) {
    case 'long-text':
    case 'multi-select':
    case 'single-select':
      return NotScorableQuestionResult;

    case 'likert':
    default:
      return ScorableQuestionResult;
  }
}

const AssessmentHistoryBoxPlot = ({
  dataMap,
  assessmentId,
  margin = { top: 1, bottom: 1 },
  categoryLookups,
  questionSetHref,
  questionHref,
}) => {
  const questionSets = assessmentId
    ? dataMap.assessments[assessmentId].questionSets
    : dataMap.questionSets;

  const width = 20;
  const height = 120;
  const constrainedWidth = 0.9 * width;
  const constrainedHeight = height - margin.top - margin.bottom;

  return (
    <div className="row">
      <div className="col">
        <div className="assessment-history-chart">
          <div className="assessment-history-visualization" />
          <div className="assessment-history-questions">
            <CategoryLayout
              categoryLookups={categoryLookups}
              categories={dataMap?.categories}
              questionSets={questionSets}
              questionSetClassName="assessment-history-question-set"
              renderQuestionSetName={(questionSet) => (
                <span className="question-set-name">
                  <ConditionalLinkWrapper
                    wrap={!!questionSetHref}
                    href={questionSetHref}
                    parameters={[questionSet.key]}
                  >
                    {questionSet.displayName}
                  </ConditionalLinkWrapper>
                </span>
              )}
              renderQuestionSet={(questionSet, _palette) => {
                return (
                  <ul className="category-question-list">
                    {map(questionSet.questions, (q, qKey) => {
                      const QuestionResult = getQuestionResult(q);

                      return (
                        <QuestionResult
                          key={qKey}
                          tag="li"
                          width={width}
                          height={height}
                          constrainedWidth={constrainedWidth}
                          constrainedHeight={constrainedHeight}
                          questionData={q}
                          questionHref={questionHref}
                          parameters={[questionSet.key, qKey]}
                          margin={margin}
                        />
                      );
                    })}
                  </ul>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const yScaleGlyph = scaleLinear({
  range: [-16, 16],
  domain: [-1, 1],
});

export const Glyph = (
  <svg
    viewBox="0 0 32 32"
    className="chart-glyph assessment-history-box-plot-glyph"
  >
    <defs>
      <pattern
        id="glyph-violin-lines"
        width="3"
        height="3"
        patternUnits="userSpaceOnUse"
      >
        <path
          className="glyph-violin-lines"
          d="M 0,1.5 l 3,0"
          stroke="#fff"
          strokeWidth="1"
          strokeLinecap="square"
          shapeRendering="auto"
        />
      </pattern>
    </defs>
    <g transform="translate(16, 16)">
      <ViolinPlot
        data={[
          { value: -1, count: 0 },
          { value: 0, count: 10 },
          { value: 1, count: 0 },
        ]}
        top={0}
        left={-14}
        width={28}
        valueScale={yScaleGlyph}
        fill="url(#glyph-violin-lines)"
      />
      <line x1="-3" y1="-14" x2="3" y2="-14" className="min" />
      <rect x="-1" y="-14" width="2" height="7" className="min-to-first" />
      <rect x="-1" y="7" width="2" height="7" className="max-to-third" />
      <line x1="-3" y1="14" x2="3" y2="14" className="max" />
      <rect
        x="-5"
        y="-7"
        width="10"
        height="14"
        rx="2"
        ry="2"
        className="box"
      />
      <rect x="-5" y="-1" width="10" height="2" className="median" />
    </g>
  </svg>
);

export default AssessmentHistoryBoxPlot;
