import keymirror from 'js/utils/keymirror';

const channelPrefix = '@@channel/';

export const types = keymirror(
  {
    JOIN_ASSESSMENT: null,
    LEAVE_ASSESSMENT: null,
    PRESENCES_UPDATED: null
  },
  { prefix: channelPrefix }
);

export const joinAssessmentChannel = key => {
  return {
    type: types.JOIN_ASSESSMENT,
    key
  };
};

export const leaveAssessmentChannel = key => {
  return {
    type: types.LEAVE_ASSESSMENT,
    key
  };
};
