import { types as AlertTypes } from 'js/actions/alert-actions';

const initialState = {
  alerts: [],
};

function addAlert(alerts, newAlert) {
  return [...alerts, newAlert];
}

const alerts = (state = initialState, action) => {
  switch (action.type) {
    case AlertTypes.DISMISS_ALERT:
      state = {
        ...state,
        alerts: state.alerts.filter((x) => x.id !== action.id),
      };
      break;

    case AlertTypes.ALERT:
      state = {
        ...state,
        alerts: addAlert(state.alerts, { ...action.alert, id: +Date.now() }),
      };
      break;

    default:
    // do nothing
  }

  return state;
};

export default alerts;
