import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';

// Based on NavLink from react-router-dom, but extended to wrap the component in an "li" element
// The Route is used primarily to access the same "match" logic from react-router

/**
 * A <Link> wrapper that knows if it's "active" or not.
 */
const ListNavLink = ({
  to,
  exact,
  strict,
  location,
  activeClassName,
  className,
  activeStyle,
  style,
  isActive: getIsActive,
  'aria-current': ariaCurrent,
  ...rest
}) => {
  const path = typeof to === 'object' ? to.pathname : to;

  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

  return (
    <Route
      path={escapedPath}
      exact={exact}
      strict={strict}
      location={location}
      children={({ location, match }) => {
        const isActive = !!(getIsActive ? getIsActive(match, location) : match);

        return (
          <li className={isActive ? activeClassName : ''}>
            <Link
              to={to}
              className={
                isActive
                  ? [className, activeClassName].filter(i => i).join(' ')
                  : className
              }
              style={isActive ? { ...style, ...activeStyle } : style}
              aria-current={(isActive && ariaCurrent) || null}
              {...rest}
            />
          </li>
        );
      }}
    />
  );
};

ListNavLink.propTypes = {
  to: Link.propTypes.to,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  location: PropTypes.object,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  activeStyle: PropTypes.object,
  style: PropTypes.object,
  isActive: PropTypes.func,
  'aria-current': PropTypes.oneOf([
    'page',
    'step',
    'location',
    'date',
    'time',
    'true'
  ])
};

ListNavLink.defaultProps = {
  activeClassName: 'active',
  'aria-current': 'true'
};

export default ListNavLink;
