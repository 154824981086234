import _ from 'lodash';

import { types as AccountTypes } from 'js/actions/account-actions';
import { types as AccountUserActionTypes } from 'js/actions/account/user-actions';

const initialState = {
  token: '',
  me: null,
  pendingVerifications: [],
};

const identity = (state = initialState, action) => {
  switch (action.type) {
    case AccountTypes.LOGIN_SUCCESS:
    case AccountTypes.REGISTER_SUCCESS:
    case AccountUserActionTypes.REGISTER_SUCCESS:
    case AccountTypes.RESET_PASSWORD_SUCCESS:
      return { ...state, token: action.token };

    case AccountTypes.LOAD_ME_SUCCESS:
      return { ...state, me: _.find(action.data.entities.users, () => true) };

    case AccountTypes.LOAD_VERIFICATIONS_SUCCESS:
      return { ...state, pendingVerifications: action.data || [] };

    case AccountTypes.UPGRADE_GUEST_SUCCESS:
      return {
        ...state,
        me: _.find(action.data.entities.users, () => true),
        token: action.token,
      };

    case AccountTypes.LOGOUT:
    case AccountTypes.LOGOUT_SUCCESS:
    case 'API_ERROR/INVALID_TOKEN':
      return initialState;

    default:
  }

  // patch the "me" data if any updates come through other paths
  if (
    action.data &&
    action.data.entities &&
    action.data.entities.users &&
    state.me &&
    state.me.id &&
    action.data.entities.users[state.me.id]
  ) {
    state = {
      ...state,
      me: { ...state.me, ...action.data.entities.users[state.me.id] },
    };
  }

  return state;
};

export default identity;
