export const questionSets = [
  {
    name: 'Agile Ceremonies',
    key: 'agile-ceremonies',
    category: 'agile-ceremonies',
    questions: [
      {
        text: "Which of the following best describes your team's use of Agile ceremonies?",
        key: 'agile-ceremonies-question',
        answers: [
          { value: '1', text: 'Level 1 - The team does not use, or does not consistently use, Agile methodologies.' },
          { value: '2', text: 'Level 2 - The team starts adopting Agile ceremonies, but not consistently. Standups, retrospectives, and sprint planning might be used, but Agile methodologies are not applied or embedded in team culture.' },
          { value: '3', text: "Level 3 - The team's Agile ceremonies are consistently followed; ceremonies like standups, sprint planning, and retrospectives are well-executed. Sprint outcomes do not consistently drive continuous improvement." },
          { value: '4', text: "Level 4 - The team's Agile ceremonies are advanced and adapts processes based on feedback and lessons learned. Continuous improvement is a key focus." },
          { value: '5', text: 'Level 5 - The team has mastered Agile ceremonies and continuously experiments with new practices to drive innovation.' },
          { value: '6', text: 'Level 6 - The team has mastered Agile ceremonies and continuously experiments via formal cycles of hypothesis-driven innovation. Experiments could be driven internally from the team, or via the CoP.' },
        ],
      },
    ],
  },
  {
    name: 'Ownership',
    key: 'ownership',
    category: 'ownership',
    questions: [
      {
        text: "Which of the following best describes your team's sense of ownership?",
        key: 'ownership-question',
        answers: [
          { value: '1', text: 'Level 1 - No clear ownership of roles, work, or outcomes; responsibilities are undefined.' },
          { value: '2', text: 'Level 2 - Team is developing a sense of responsibility for their work but still relies heavily on guidance and direction.' },
          { value: '3', text: 'Level 3 - Team members start to own processes and take responsibility for their work and outcomes. A product owner for each product is established but rudimentary;  processes are in alignment with EPM. Basic customer feedback is gathered; processes for deeper insights are still developing.' },
          { value: '4', text: 'Level 4 - Teams are largely self-organizing and take ownership of their work, with minimal need for oversight. Product Owners are providing clear direction, prioritization, sharing the roadmap for their product, and getting customer feedback.' },
          { value: '5', text: 'Level 5 - Team demonstrates a high degree of autonomy, making day-to-day decisions independently while staying aligned with organizational objectives.' },
          { value: '6', text: 'Level 6 - Team has complete autonomy in decision-making while maintaining alignment with organizational goals through regular strategic reviews and shared objectives.' },
        ],
      },
    ],
  },
  {
    name: 'Work Management',
    key: 'work-management',
    category: 'work-management',
    questions: [
      {
        text: "Which of the following best describes your team's work management practices?",
        key: 'work-management-question',
        answers: [
          { value: '1', text: 'Level 1 - Team has no functional backlog. Work is unpredictable and chaotic; teammates prioritize work ad-hoc.' },
          { value: '2', text: 'Level 2 - Team has a functional backlog. Sprints are built, but the team may struggle to determine capacity. Teammates lack the knowledge to best prioritize work.' },
          { value: '3', text: 'Level 3 - Sprints are effectively planned, and the team is generally successful in reaching their sprint goals (i.e., good say-do ratio as defined by the team). Teammates are mostly self-sufficient in prioritizing work.' },
          { value: '4', text: 'Level 4 - Sprints and backlog are right-sized and well-organized. Teammates are self-guided in choosing work to best meet the needs of the business. Priority is determined by a combination of stakeholder feedback and tracking of key metrics.' },
          { value: '5', text: 'Level 5 - Rapid customer feedback loops ensure that stakeholders are partners in prioritization and backlog management. Team continuously self-evaluates to prevent drift and seek opportunities for growth. Team serves as a work-management leader in IS, capable of teaching other teams.' },
          { value: '6', text: 'Level 6 - Team is capable of utilizing automation as a form of efficient work management as well as product-focused proactive monitoring. This type of monitoring gives the team the foresight needed to mitigate issues and plan their work proactively versus reactively.' },
        ],
      },
    ],
  },
  {
    name: 'Collaboration',
    key: 'collaboration',
    category: 'collaboration',
    questions: [
      {
        text: "Which of the following best describes your team's collaboration practices?",
        key: 'collaboration-question',
        answers: [
          { value: '1', text: 'Level 1 - Team members work in silos with minimal collaboration, even within the team.' },
          { value: '2', text: 'Level 2 - Improved collaboration within the team, but knowledge sharing is confined to the team; minimal interaction with other teams.' },
          { value: '3', text: 'Level 3 - Teams begin learning from and sharing knowledge with other teams, increasing cross-department collaboration and reducing knowledge silos.' },
          { value: '4', text: 'Level 4 - Cross-department collaboration with knowledge and best practices adopted throughout the department through conversations, meetings, documentation, and cross-training.' },
          { value: '5', text: 'Level 5 - Seamless collaboration across the organization, with self-organizing teams that quickly mobilize, actively monitor, and continuously improve their working relationships.' },
          { value: '6', text: 'Level 6 - Cross-functional collaboration is ingrained; teams across different domains actively share insights and rapidly adopt successful innovations organization-wide.' },
        ],
      },
    ],
  },
  {
    name: 'Tooling',
    key: 'tooling',
    category: 'tooling',
    questions: [
      {
        text: "Which of the following best describes your team's use of tools?",
        key: 'tooling-question',
        answers: [
          { value: '1', text: 'Level 1 - Reliance on basic tools, like email and spreadsheets, for work management; Agile-specific tools (e.g., ServiceNow) are not utilized. Work may be logged in ServiceNow but managed in other tools.' },
          { value: '2', text: 'Level 2 - Agile tools (e.g., ServiceNow) are introduced but only a basic implementation of the tool. Work is visualized in stories but prioritization is typically managed outside of the tool.' },
          { value: '3', text: 'Level 3 - Consistent use of Agile tools; metrics are tracked and utilized for continuous process improvement. ServiceNow is used for managing work, enabling metrics to highlight areas for improvement.' },
          { value: '4', text: 'Level 4 - Advanced metrics are tracked, and applications are proactively managed using sophisticated tools, with all team members confident in day-to-day functions of their tools. Team uses ServiceNow dashboards to track key metrics in real time, allowing them to monitor performance and proactively address issues before they impact operations.' },
          { value: '5', text: 'Level 5 - Advanced, fully integrated toolsets—including real-time analytics, predictive modeling, and automation—support innovation and continuous decision-making, with all team members empowered to enhance their tool experience. ServiceNow’s analytics and automation features may be used to help team forecast needs and adjust priorities dynamically, fostering a responsive and data-informed work environment.' },
          { value: '6', text: 'Level 6 - Cutting-edge tools featuring predictive models, real-time data analytics, and continuous feedback loops are fully integrated; tooling enables real-time, data-driven decision-making and strategic innovation. Predictive insights and feedback loops within ServiceNow enable strategic, real-time adjustments that drive innovation and keep team goals aligned with business objectives.' },
        ],
      },
    ],
  },
];

export default questionSets;
