import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { PanelHeader } from 'js/components';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import FavoriteTeamSummary from './FavoriteTeamSummary';
import JoinAssessmentForm from 'js/components/Forms/JoinAssessmentForm';
import { AsyncDisplay, LoadingMessage, Icon } from 'js/components';

const HomeRouteComponent = props => {
  const {
    workspaceId,
    teams,
    onJoinAssessment,
    joinAssessmentMessage,
    loaded,
    loadingAssessments,
    onToggleAssessmentLock,
    onDeleteAssessment,
    onCopyLink
  } = props;

  const sortedTeams = _.orderBy(teams, ['name'], ['asc']);

  const handleJoin = values => {
    return onJoinAssessment(values.passphrase);
  };

  const standardFavoriteTeamSummaryProps = {
    loaded,
    loadingAssessments,
    onToggleAssessmentLock,
    onDeleteAssessment,
    onCopyLink
  };
  return (
    <div>
      <PanelHeader size="sm" />
      <div className="content">
        <div className="row">
          <div className="col-12 col-md-8">
            <AsyncDisplay isLoading={!loaded} isError={false}>
              <AsyncDisplay.Loading>
                <Card>
                  <CardBody>
                    <LoadingMessage iconSize="2x">
                      Loading your favorite teams&hellip;
                    </LoadingMessage>
                  </CardBody>
                </Card>
              </AsyncDisplay.Loading>
              <AsyncDisplay.Resolved>
                {_.some(teams, x => true) ? (
                  sortedTeams.map(team => (
                    <FavoriteTeamSummary
                      key={team.id}
                      {...standardFavoriteTeamSummaryProps}
                      team={team}
                    />
                  ))
                ) : (
                  <Card>
                    <CardBody className="media text-muted">
                      <Icon
                        icon={['far', 'star-exclamation']}
                        size="4x"
                        className="mr-3"
                        fixedWidth
                      />
                      <div className="media-body">
                        <p>You don't have any favorite teams yet.</p>
                        <p>
                          Mark some teams as favorites and you'll get a summary
                          of their recent activity here, so you can keep up to
                          date on their progress at a glance.
                        </p>
                        <p>
                          <Link to={`/w/${workspaceId}/teams`}>
                            Add some favorites now
                          </Link>
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                )}
              </AsyncDisplay.Resolved>
            </AsyncDisplay>
          </div>
          <div className="col-12 col-md-4">
            <Card>
              <CardBody>
                <JoinAssessmentForm
                  onJoin={handleJoin}
                  message={joinAssessmentMessage}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeRouteComponent.propTypes = {
  onJoinAssessment: PropTypes.func
};

HomeRouteComponent.defaultProps = {
  onJoinAssessment: () => {}
};

export default HomeRouteComponent;
