import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  respond as respondToAssessment,
  unrespond as unrespondToAssessment,
  clearParticipationSession,
} from 'js/actions/assessment-actions';

import RunComponent from '../components/SurveyAssessmentRunComponent';

const SurveyAssessmentRunEngine = (props) => {
  const {
    participant,
    assessment,
    match,
    location,
    history,
    unrespondToAssessment,
  } = props;

  const handleResponse = (assessmentId, question, answer) => {
    const { respondToAssessment } = props;

    const myResponse = _.find(
      question.responses,
      (r) => r.participant_id === participant.id
    );

    const response = {
      participant_id: participant.id,
      answer_id: answer?.id,
      value: answer.value,
      is_na: answer?.is_na ?? false,
    };

    if (myResponse) {
      response.id = myResponse.id;
    }

    respondToAssessment(assessmentId, question.id, response);
  };

  const handleUnresponse = useCallback(
    (assessmentId, question) => {
      unrespondToAssessment(assessmentId, question.id);
    },
    [unrespondToAssessment]
  );

  const handleClearSession = () => {
    props.clearParticipationSession();
  };

  return (
    <RunComponent
      match={match}
      location={location}
      history={history}
      participant={participant}
      assessment={assessment}
      onRespond={handleResponse}
      onUnrespond={handleUnresponse}
      onClearSession={handleClearSession}
    />
  );
};

SurveyAssessmentRunEngine.propTypes = {
  assessment: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    me: state.identity.me,
  }),
  {
    respondToAssessment,
    unrespondToAssessment,
    clearParticipationSession,
  }
)(SurveyAssessmentRunEngine);
