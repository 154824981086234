import React from 'react';
import classNames from 'classnames';

const PanelHeader = ({ size, content, children, className }) => {
  return (
    <div
      className={classNames({
        [className || '']: true,
        'panel-header': true,
        [size !== undefined ? `panel-header-${size}` : '']: true,
      })}
    >
      {content}
      {children}
    </div>
  );
};

export default PanelHeader;
