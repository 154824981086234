import React from 'react';
import PropTypes from 'prop-types';

const IconStack = ({ size, className, fixedWidth, children }) => {
  const classNames = ['fa-layers'];

  if (size) {
    classNames.push('fa-' + size);
  }

  if (className) {
    classNames.push(className);
  }

  if (fixedWidth) {
    classNames.push('fa-fw');
  }

  return <span className={classNames.join(' ')}>{children}</span>;
};

IconStack.propTypes = {
  size: PropTypes.oneOf(['lg', '2x', '3x', '4x', '5x']),
  fixedWidth: PropTypes.bool,
  className: PropTypes.string
};

IconStack.defaultProps = {
  fixedWidth: false
};

export default IconStack;
