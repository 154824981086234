import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { noop, uniqBy } from 'lodash';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { QuadrantGlyph, TrendGlyph } from './Glyphs';

import templates from 'js/data/assessment-templates';

const AssessmentPicker = ({
  assessmentHistorySummaries,
  defaultActiveKey = null,
  onChoose = noop,
}) => {
  const options = useMemo(
    () =>
      uniqBy(
        assessmentHistorySummaries.map((s) => ({
          key: s.assessment_key,
          text:
            templates.find((t) => t.key === s.assessment_key)?.name ??
            s.assessment_name,
          glyph: s.assessment_type === 'live' ? TrendGlyph : QuadrantGlyph,
        })),
        (o) => o.key
      ),
    [assessmentHistorySummaries]
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(undefined);

  useEffect(() => {
    const initialActiveItem =
      options.find((o) => o.key === defaultActiveKey) ?? options[0];
    setActiveItem(initialActiveItem);
  }, [options, defaultActiveKey]);

  const toggle = useCallback(
    () => setDropdownOpen((prevState) => !prevState),
    [setDropdownOpen]
  );

  if (options.length === 0 || !activeItem) {
    return null;
  }

  return (
    <div className="assessment-picker">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle>
          <>
            <div className="selected-name" id="assessment-picker-selected">
              <i>{activeItem.glyph}</i> {activeItem.text}
            </div>
          </>
        </DropdownToggle>
        <DropdownMenu right modifiers={{ arrow: { enabled: false } }}>
          {options.map((o) => (
            <DropdownItem
              active={o.key === activeItem.key}
              key={o.key}
              onClick={() => {
                setActiveItem(o);
                onChoose(o.key);
              }}
            >
              <i>{o.glyph}</i> {o.text}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default AssessmentPicker;
