import { Presence } from 'phoenix';
import _ from 'lodash';

import { types as ChannelTypes } from 'js/actions/channel-actions';
import { load as loadAssessment } from 'js/actions/assessment-actions';
import { types as LiveAssessmentTypes } from 'js/actions/live-assessment-actions';
import { listAssessmentInvites } from 'js/actions/assessment-invite-actions';

const channels = {};

const handleUpdatedAssessment = (key, dispatch, getState) => {
  const channel = channels[key];
  if (channel) {
    channel.load =
      channel.load ||
      _.throttle((key, dispatch, getState) => {
        loadAssessment(key)(dispatch, getState);
      }, 2000);

    channel.load(key, dispatch, getState);
  } else {
    loadAssessment(key)(dispatch, getState);
  }
};

const handlePresenceChange = (key, dispatch, getState) => {
  // For the host, update participants and invites
  const channel = channels[key];
  if (channel) {
    channel.loadInvites =
      channel.loadInvites ||
      _.throttle((key, dispatch, getState) => {
        const assessment = getState().entities.assessments[key];
        const liveAssessment = getState().entities.liveAssessments[key];
        const hosting =
          getState().participation[key]?.id === liveAssessment?.host_id;
        if (assessment && hosting) {
          listAssessmentInvites(
            assessment.workspace_id,
            assessment.team_id,
            key
          )(dispatch, getState);
          loadAssessment(key)(dispatch, getState); //also load assessment to update participant list
        }
      }, 2000);

    channel.loadInvites(key, dispatch, getState);
  }
};

const syncPresentUsers = (presence, key, dispatch) => {
  const participants = presence.list().map((p) => p.metas[0]);

  dispatch({
    type: ChannelTypes.PRESENCES_UPDATED,
    key,
    participants,
  });
};

export const joinAssessmentChannel = (key, socket, dispatch, getState) => {
  if (!socket) {
    return;
  }
  if (channels[key]) {
    return;
  } // already joined

  const channel = (channels[key] = socket.channel(`assessment:${key}`, {}));

  const updateAssessment = () =>
    handleUpdatedAssessment(key, dispatch, getState);
  const updateLiveAssessment = (message) => {
    const { data: liveAssessment, ack } = message;

    dispatch({
      type: LiveAssessmentTypes.UPDATE_LIVE_ASSESSMENT_SUCCESS,
      data: {
        entities: {
          liveAssessments: { [liveAssessment.assessment_id]: liveAssessment },
        },
      },
      meta: { ack },
    });

    // return handleUpdatedLiveAssessment(key, dispatch, getState);
  };

  channel.on('RESPONSE_RECORDED', updateAssessment);
  channel.on('CREATED', updateAssessment);
  channel.on('UPDATED', updateAssessment);
  channel.on('PARTICIPANT_CREATED', updateAssessment);
  channel.on('PARTICIPANT_UPDATED', updateAssessment);
  channel.on('LIVE_ASSESSMENT_UPDATED', updateLiveAssessment);
  channel.on('presence_diff', () =>
    handlePresenceChange(key, dispatch, getState)
  );

  let presence = new Presence(channel);

  // presence.onJoin(onJoin)
  // presence.onLeave(onLeave)
  presence.onSync(() => syncPresentUsers(presence, key, dispatch));
  // let presences = {};
  // channel.on('presence_state', response => {
  //   presences = Presence.syncState(presences, response);
  //   syncPresentUsers(presences, key, dispatch);
  // });
  // channel.on('presence_diff', response => {
  //   presences = Presence.syncDiff(presences, response);
  //   syncPresentUsers(presences, key, dispatch);
  // });

  channel
    .join()
    .receive('ok', (resp) => {
      console.log('Joined successfully', resp);
    })
    .receive('error', (resp) => {
      console.log('Unable to join', resp);
    });
};

export const leaveAssessmentChannel = (key) => {
  if (!channels[key]) {
    return;
  }

  channels[key].leave();
  delete channels[key];
};

// function joinChannel(channelId) {
//   const { socket } = this.props;
//   if (!socket) { return; }

//   if (this.channel) {
//     this.channel.leave();
//   }

//   this.channel = socket.channel(`assessment:${channelId}`, {});
//   this.channel.on('broadcast', (a, b, c, d, e, f) => {
//     console.log('broadcast received', a, b, c, d, e, f);
//   })
//   this.channel.on('shout', (a, b, c, d, e, f) => {
//     console.log('shout received', a, b, c, d, e, f);
//   })
//   this.channel.join()
//     .receive("ok", resp => { console.log("Joined successfully", resp) })
//     .receive("error", resp => { console.log("Unable to join", resp) })

//   if (this.channelShout) {
//     clearInterval(this.channelShout);
//   }

//   const channel = this.channel;
//   let i = 0;
//   this.channelShout = setInterval(() => {
//     console.log('shout');
//     channel.push(`shouting`, { body: ++i }, 10000)
//       // .receive("ok", (msg) => console.log("created message", msg))
//       .receive("error", (reasons) => console.log("create failed", reasons))
//       .receive("timeout", () => console.log("Networking issue..."))
//   }, 10000);
// }
