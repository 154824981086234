import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import _ from 'lodash';

import { SCHEMAS } from 'js/api/schemas';
import { participate } from 'js/actions/assessment-actions';
import { me as loadMe } from 'js/actions/account-actions';
import { getEngine } from 'js/engines';

import { FullPageLoading } from 'js/components';
import { bindActionCreators } from 'redux';
import { isArchived } from 'js/utils/team-utils';
import FullPageMessage from 'js/components/Messages/FullPageMessage';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const AssessmentRunContainer = (props) => {
  const {
    assessmentMeta,
    assessment,
    loadAssessment,
    participant,
    isAuthenticated,
    me,
    loadMe,
    participate,
    match: {
      params: { assessmentId },
    },
  } = props;

  const query = useQuery();

  const inviteToken = query.get('t');
  const cohort = query.get('c');

  const participantToken = participant ? participant.token : undefined;
  const [loaded, setLoaded] = useState(false);
  const assessmentType = assessmentMeta?.type;

  const isFullyLoaded = me && me.id && _.every(me.workspaces);

  useEffect(() => {
    if (!isAuthenticated || (isAuthenticated && !isFullyLoaded)) {
      loadMe();
    }
  }, [isAuthenticated, isFullyLoaded, loadMe]);

  useEffect(() => {
    if (assessmentId !== undefined && assessmentId !== null && !!participant) {
      loadAssessment(assessmentId, participant.token);
    }
  }, [assessmentId, loadAssessment, participant]);

  useEffect(
    () => {
      if (
        // !assessmentType ||
        // (['live-assessment', 'live'].includes(assessmentType) &&
        //   !isAuthenticated)
        !assessmentType
      ) {
        //make sure assessment is loaded and user is authenticated (if necessary) before participating
        return;
      }

      if (!loaded || (participantToken === undefined && assessmentId)) {
        participate(assessmentId, participantToken, inviteToken, cohort);
        setLoaded(true);
      }
    },
    // The linter wants "loaded" added as a dependency but then participate is called twice (perhaps loaded is not needed in the conditional)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      assessmentId,
      participantToken,
      inviteToken,
      assessmentType,
      participate,
      isAuthenticated,
    ]
  );

  if (!assessmentMeta) {
    return <FullPageLoading />;
  }

  const RunComponent = getEngine(assessmentMeta).Run;

  // Add protection if the assessment requires authentication
  // if (['live-assessment', 'live'].includes(assessmentMeta.type)) {
  //   const meLoaded = !!(me && me.id && _.every(me.workspaces));

  //   if (!isAuthenticated) {
  //     return (
  //       <Redirect
  //         to={{ pathname: '/pages/login', state: { from: location } }}
  //       />
  //     );
  //   } else if (!meLoaded) {
  //     return <FullPageLoading />;
  //   }
  // }

  if (!assessment || !assessment.team) {
    return <FullPageLoading />;
  }

  if (!participant) {
    return <FullPageLoading />;
  }

  if (isArchived(assessment.team)) {
    return (
      <FullPageMessage message="We're sorry, this assessment is no longer available." />
    );
  }

  return <RunComponent assessment={assessmentMeta} {...props} />;
};

const ConnectedAssessmentRunContainer = connect(
  (store, props) => {
    const {
      match: {
        params: { assessmentId },
      },
    } = props;
    const { identity, participation, entities, router } = store;

    const isAuthenticated = !!(identity.me && identity.token);
    const { me } = identity;

    const normalizedAssessment = entities.assessments[assessmentId];
    const assessment = denormalize(
      normalizedAssessment,
      SCHEMAS.ASSESSMENT,
      entities
    );

    return {
      me: denormalize(me, SCHEMAS.ME, entities),
      participant: participation[assessmentId] || null,
      isAuthenticated,
      assessment,
      location: router.location,
    };
  },
  {
    participate,
    loadMe,
  }
)(AssessmentRunContainer);

const AssessmentRunContainerEngineWrapper = (props) => (
  <ConnectedAssessmentRunContainer {...props} />
);

const loadStrategies = new Map();

export default connect(null, (dispatch, { assessmentMeta }) => {
  const engine = getEngine(assessmentMeta);
  if (!loadStrategies.has(engine)) {
    loadStrategies.set(
      engine,
      bindActionCreators(engine.strategies.load, dispatch)
    );
  }

  return {
    loadAssessment: loadStrategies.get(engine),
  };
})(AssessmentRunContainerEngineWrapper);
