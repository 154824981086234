import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { SCHEMAS } from 'js/api/schemas';
import { list as listLiveAssessments } from 'js/actions/live-assessment-actions';
import { load as loadTeam } from 'js/actions/team-actions';

import LiveAssessmentReviewComponent from '../components/Review/LiveAssessmentReviewComponent';

const LiveAssessmentReviewEngine = ({
  match,
  combinedAssessments,
  team,
  listLiveAssessments,
  loadTeam,
}) => {
  const { assessmentKey, teamId } = match.params;

  useEffect(() => {
    loadTeam(teamId);
  }, [teamId, loadTeam]);
  useEffect(() => {
    listLiveAssessments({ key: assessmentKey, team_id: teamId });
  }, [assessmentKey, teamId, listLiveAssessments]);

  return (
    <LiveAssessmentReviewComponent
      match={match}
      assessmentKey={assessmentKey}
      teamId={teamId}
      team={team || {}}
      assessments={combinedAssessments}
    />
  );
};

LiveAssessmentReviewEngine.propTypes = {
  assessments: PropTypes.arrayOf(PropTypes.object),
};

export default connect(
  (state, ownProps) => {
    const { assessments, match } = ownProps;
    const { teamId } = match.params;
    let liveAssessmentsLoaded = true;

    const combinedAssessments = assessments.map((a) => {
      const liveAssessment = state.entities.liveAssessments[a.id];
      liveAssessmentsLoaded = liveAssessmentsLoaded && !!liveAssessment;

      return { ...a, ...(state.entities.liveAssessments[a.id] || {}) };
    });

    return {
      combinedAssessments,
      team: denormalize(
        state.entities.teams[teamId],
        SCHEMAS.TEAM,
        state.entitites
      ),
    };
  },
  {
    listLiveAssessments,
    loadTeam,
  }
)(LiveAssessmentReviewEngine);
