import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { find, flatten, sortBy } from 'lodash';
import copy from 'copy-to-clipboard';
import { useNotify } from 'js/hooks/useNotify';
import { isArchived } from 'js/utils/team-utils';

import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Icon } from 'js/components';
import ParticipantBar from './ParticipantBar';
import QuestionOverview from '../QuestionOverview';
import { isRecentAndActive } from 'js/utils/assessment-utils';

function AssessmentSummary({ assessment, team, baseUrl }) {
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const notify = useNotify();

  const handleParticipantSelected = (id) => {
    const selectedParticipant = id
      ? find(assessment.participants, (p) => p.id === id)
      : null;

    setSelectedParticipant(selectedParticipant);
  };

  const d = moment(assessment.inserted_at);

  const handleCopyLink = () => {
    const url = `${baseUrl}/assessments/${assessment.id}/run`;

    copy(url);

    notify({ type: 'success', message: 'Link copied to clipboard.' });
  };

  const showParticipationLinks =
    !isArchived(team) && isRecentAndActive(assessment);

  return (
    <div className="assessment-summary">
      <h3 className="assessment-date">
        <Icon icon={['far', 'calendar-alt']} />
        {d.format('MMM D, YYYY h:mm a')}

        <div className="action-items">
          <Link
            to={`${baseUrl}/detail/${assessment.id}`.replace('//', '/')}
            className="btn btn-info btn-inline"
          >
            Details
          </Link>
          {showParticipationLinks && (
            <Button
              className="btn-inline"
              color="info"
              onClick={handleCopyLink}
            >
              <Icon icon="copy" className="btn-icon-left" />
              Participant Link
            </Button>
          )}
        </div>
      </h3>
      <ParticipantBar
        assessment={assessment}
        onSelectParticipant={handleParticipantSelected}
      />
      <ol className="question-overview-list row list-unstyled">
        {flatten(
          sortBy(assessment.question_sets, ['ordinal', 'id']).map((qs) =>
            sortBy(qs.questions, ['ordinal', 'id']).map((q) => {
              return (
                <li key={q.id} className="question-overview-list-item col">
                  <QuestionOverview
                    question={q}
                    responses={q.responses}
                    participant={selectedParticipant}
                  />
                </li>
              );
            })
          )
        )}
      </ol>
    </div>
  );
}

AssessmentSummary.propTypes = {
  assessment: PropTypes.object.isRequired,
};

export default AssessmentSummary;
