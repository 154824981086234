import React from 'react';

import ResetTokenInvalid from './ResetTokenInvalid';
import ResetPassword from './ResetPassword';

const PasswordResetRouteComponent = ({ tokenVerified, onReset }) => {
  return (
    <div id="forgot-password-form-page" className="featured-form">
      <div className="full-page-content">
        <div className="background-page">
          <div className="container">
            <div className="content">
              <div className="page-result page-success">
                {(() => {
                  switch (tokenVerified) {
                    case false:
                      return <ResetTokenInvalid />;

                    case true:
                      return <ResetPassword onReset={onReset} />;

                    default:
                      return null;
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
};

export default PasswordResetRouteComponent;
