import { trueToFalse } from './base-answers';

export const creatingValuableProducts = {
  name: 'Creating Valuable Products',
  key: 'creating-valuable-products',
  category: 'customer-centricity',
  questions: [
    {
      text: 'Our ART uses rolling-wave roadmaps to plan solution development over time',
      key: 'rolling-wave-roadmaps',
      answers: trueToFalse,
    },
    {
      text: 'Our ART incorporates user experience design in solution development',
      key: 'user-experience-in-design',
      answers: trueToFalse,
    },
    {
      text: 'Our ART develops solutions that meet performance, security, and usability standards',
      key: 'solutions-meet-nfr-standards',
      answers: trueToFalse,
    },
    {
      text: 'Our ART allocates appropriate capacity to maintain the health of our solutions',
      key: 'capacity-to-maintain-health',
      answers: trueToFalse,
    },
  ],
};
