import React from 'react';
import { PanelHeader } from 'js/components';
import { Card, CardBody } from 'reactstrap';
import { PricingList } from 'js/components/Pricing';

const FreeBillingComponent = ({ products, loading, account }) => {
  return (
    <div>
      <PanelHeader size="flex" />
      <div className="content">
        <Card>
          <CardBody className="my-4">
            <div>
              <div className="account-settings-pricing">
                <PricingList
                  products={products}
                  loading={loading}
                  account={account}
                  titleText={'Need more great features from Waypointr?'}
                  lightMode={true}
                >
                  <p>
                    We hope you're enjoying the free plan and all the great
                    features it has to offer. When things start to feel a little
                    cramped because you're outgrowing the free plan, we have a
                    premium plan that will give you all the features you need to
                    keep you going.
                  </p>
                </PricingList>
              </div>
            </div>
            <div className="full-page-background" />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default FreeBillingComponent;
