import React from 'react';
import PropTypes from 'prop-types';

import { withFormik } from 'formik';
import { Icon } from 'js/components';

const JoinAssessmentForm = props => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    message,
    isSubmitting
  } = props;

  const formikAttributes = fieldName => ({
    id: fieldName,
    name: fieldName,
    value: values[fieldName],
    onChange: handleChange,
    onBlur: handleBlur,
    invalid: errors[fieldName] && touched[fieldName]
  });

  return (
    <form
      onSubmit={isSubmitting ? () => false : handleSubmit}
      className="join-assessment-form"
    >
      <div>
        <label>Join an assessment</label>
        {message && message.length > 0 ? (
          <div className="validation-message">{message}</div>
        ) : null}
      </div>
      <input
        type="text"
        className="form-control"
        placeholder="Enter your passphrase&hellip;"
        {...formikAttributes('passphrase')}
      />
      <div>
        <button type="submit" className="btn btn-primary">
          {isSubmitting ? (
            <Icon
              icon={['far', 'circle-notch']}
              className="btn-icon-left"
              spin
            />
          ) : (
            <Icon icon="rocket" className="btn-icon-left" />
          )}
          Join
        </button>
      </div>
    </form>
  );
};

const FormikJoinAssessmentForm = withFormik({
  mapPropsToValues: () => ({
    passphrase: ''
  }),
  handleSubmit: (values, bag, c, d, e) => {
    const { props, setSubmitting } = bag;
    let { passphrase } = values;
    passphrase = passphrase.trim();

    if (passphrase.length > 0) {
      setSubmitting(true);

      Promise.resolve(props.onJoin({ passphrase })).finally(() => {
        setSubmitting(false);
      });
    } else {
      setSubmitting(false);
    }
  }
})(JoinAssessmentForm);

FormikJoinAssessmentForm.propTypes = {
  onJoin: PropTypes.func,
  message: PropTypes.string
};

FormikJoinAssessmentForm.defaultProps = {
  onJoin: () => {},
  message: ''
};

export default FormikJoinAssessmentForm;
