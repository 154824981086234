import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loadAccountSummary } from 'js/actions/account/report-actions';
import { create as createWorkspace } from 'js/actions/workspace-actions';
import { addSelfToWorkspace } from 'js/actions/account/workspace-actions';
import { selectAccountSummary } from 'js/store/reducers/queries';
import { notify } from 'js/actions/alert-actions';
import { forEach } from 'lodash';

import AccountsDashboardComponent from '../components/AccountsDashboardComponent';
const errorMessages = {
  name: {
    'has already been taken': {
      field: 'name',
      message:
        "Sorry, someone beat you to that name. You'll have to chose another.",
    },
  },
};

const createWorkspaceApiResultsInitialState = { errors: null, values: null };

const AccountsDashboard = ({
  match: {
    params: { accountId },
  },
  me,
  isLoading,
  loadAccountSummary,
  createWorkspace,
  accountSummary,
  addSelfToWorkspace,
  notify,
}) => {
  const [currentSummary, setCurrentSummary] = useState(accountSummary);
  const [
    { errors: createWorkspaceApiErrors, values: createWorkspaceApiValues },
    setCreateWorkspaceApiResults,
  ] = useState(createWorkspaceApiResultsInitialState);

  useEffect(() => {
    if (!isLoading) {
      setCurrentSummary(accountSummary);
    }
  }, [isLoading, accountSummary, accountId]);

  useEffect(() => {
    if (accountId !== undefined) {
      loadAccountSummary(accountId);
    }
  }, [accountId, loadAccountSummary]);

  const handleCreateWorkspace = async (workspace) => {
    try {
      setCreateWorkspaceApiResults({ errors: null, values: workspace });
      await createWorkspace({ ...workspace, account_id: accountId });
      await loadAccountSummary(accountId);
      notify({
        type: 'success',
        message: (
          <>
            Congratulations, your <b>{workspace.name}</b> workspace has been
            created
          </>
        ),
      });
      setCreateWorkspaceApiResults(createWorkspaceApiResultsInitialState);
    } catch (e) {
      if (e.response) {
        const errors = {};
        forEach(e.response?.body?.errors ?? {}, (errs, key) => {
          if (errorMessages[key] && errorMessages[key]) {
            errs.forEach((err) => {
              if (errorMessages[key] && errorMessages[key][err]) {
                const { field, message } = errorMessages[key][err];
                errors[field] = message;
              }
            });
          }

          setCreateWorkspaceApiResults({ errors, values: workspace });

          notify({
            type: 'danger',
            message:
              "Uh oh...Looks like we couldn't create that workspace. Check out the messages and try again.",
          });
        });
      }
    }
  };

  const handleAddSelfToWorkspace = async (workspace) => {
    await addSelfToWorkspace(workspace.id);
    notify({
      type: 'success',
      message: (
        <>
          You've been added to <b>{workspace.name}</b>.
        </>
      ),
    });
  };

  return (
    <AccountsDashboardComponent
      me={me}
      isLoading={isLoading}
      accountSummary={currentSummary}
      onCreateWorkspace={handleCreateWorkspace}
      createWorkspaceSubmissionErrors={createWorkspaceApiErrors}
      createWorkspaceSubmissionValues={createWorkspaceApiValues}
      onAddSelfToWorkspace={handleAddSelfToWorkspace}
    />
  );
};

export default connect(
  (
    store,
    {
      match: {
        params: { accountId },
      },
    }
  ) => {
    const query = selectAccountSummary(store, accountId);
    return {
      isLoading: query?.query?.isLoading ?? false,
      accountSummary: query?.data ?? null,
    };
  },
  {
    loadAccountSummary,
    createWorkspace,
    addSelfToWorkspace,
    notify,
  }
)(AccountsDashboard);
