import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { load as loadAssessment } from 'js/actions/assessment-actions';
import { SCHEMAS } from 'js/api/schemas';

import { getEngine } from 'js/engines';

import { FullPageLoading } from 'js/components';

const LaunchAssessmentContainer = ({
  assessment,
  assessmentId,
  loadAssessment,
  ...props
}) => {
  useEffect(() => {
    if (assessment === undefined) {
      loadAssessment(assessmentId);
    }
  }, [assessment, assessmentId, loadAssessment]);

  if (!assessment) {
    return <FullPageLoading />;
  }

  const { Launch } = getEngine(assessment);

  return <Launch assessment={assessment} {...props} />;
};

export default connect(
  ({ currentWorkspaceId, entities }, { match }) => {
    const { teams, assessments } = entities;
    const {
      params: { teamId, assessmentId },
    } = match;

    const normalizedAssessment = assessments[assessmentId];
    const assessment = denormalize(
      normalizedAssessment,
      SCHEMAS.ASSESSMENT,
      entities
    );

    return {
      team: teams[teamId],
      assessmentId,
      assessment,
      workspaceId: currentWorkspaceId,
    };
  },
  {
    loadAssessment,
  }
)(LaunchAssessmentContainer);
