import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { loginAsGuest } from 'js/actions/account-actions';

import GuestLoginPageComponent from '../components/GuestLoginPageComponent';

class GuestLoginPageContainer extends React.Component {
  handleLogin = (form) => {
    const { history, loginAsGuest } = this.props;
    const { from } = this.props.location.state || {
      from: { pathname: '/guest' },
    };

    return loginAsGuest(form.anonymous ? '' : form.displayName).then(() => {
      history.push(from.pathname + (from.search ?? ''));
    });
  };

  render() {
    const { from } = this.props.location.state;

    return <GuestLoginPageComponent onLogin={this.handleLogin} from={from} />;
  }
}

export default withRouter(
  connect(null, {
    loginAsGuest,
  })(GuestLoginPageContainer)
);
