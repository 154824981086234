import moment from 'moment';

export function isPastDue(account) {
  return (
    account.subscription_status &&
    (account.subscription_status.toLowerCase() === 'past_due' ||
      account.subscription_status.toLowerCase() === 'incomplete')
  );
}

export function isUnpaidRecently(account) {
  const status_time = moment(account.subscription_status_time);
  const tooOld = status_time.isBefore(moment().subtract(30, 'days'));
  return (
    !tooOld &&
    account.subscription_status &&
    (account.subscription_status.toLowerCase() === 'unpaid' ||
      account.subscription_status.toLowerCase() === 'incomplete_expired')
  );
}
