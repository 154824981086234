export const mixedQuestionSets = [
  {
    name: 'Mixed Questions',
    key: 'mixed-questions',
    category: 'mixed',
    questions: [
      {
        text: 'Quality metrics (e.g. failure and defect rates) are:',
        key: 'quality-metrics',
        answers: [
          { value: '1', text: 'Not captured or not accurate' },
          { value: '2', text: 'Captured but not reviewed' },
          { value: '3', text: 'Reviewed by the team but rarely used to generate actions' },
          { value: '4', text: 'Regularly used to inform changes to our practices' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'What is the average flight velocity of an unladen swallow?',
        key: 'swallow-velocity',
        type: 'long-text',
      },
      {
        text: "Before an issue is added to a sprint, it's pointed",
        key: 'pointing',
        type: 'likert',
        answers: [
          { value: '2', text: 'Doing - Implemented and Following' },
          { value: '1', text: 'Started - Not Consistent' },
          { value: '0', text: 'Not Started - Plan to Implement' },
          { value: null, text: 'Not Doing - No Plan to Implement', is_na: true },
        ],
      },
      {
        text: '…so that the team understands the impact to planned work',
        key: 'pointing-so-that',
        type: 'unscored-likert',
        answers: [
          { value: '2', text: 'Agree' },
          { value: '1', text: 'Partially agree' },
          { value: '0', text: 'Do not agree' },
          { value: null, is_na: true, text: 'No value' },
        ],
      },
    ],
  },
];
