import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import classnames from 'classnames';

import styles from './PricingList.module.scss';

import ContactUsProduct from './ContactUsProduct';
import Product from './Product';
import PricingFeaturesList from './PricingFeaturesList';

const enterpriseProduct = {
  id: null,
  name: 'Enterprise',
};

function PricingList({
  products,
  loading,
  account,
  children,
  titleText = 'Choose the plan that’s right for you.',
  lightMode = false,
}) {
  const { push } = useHistory();
  const [selectedCycle, setSelectedCycle] = useState('month');

  const [freeProduct, premiumProduct] = useMemo(() => {
    return [
      products?.find((p) => p.key === 'free'),
      products?.find((p) => p.key === 'premium'),
    ];
  }, [products]);

  const handleCycleChange = useCallback(
    (e) => {
      setSelectedCycle(e.target.value);
    },
    [setSelectedCycle]
  );

  const isError = !loading && (!freeProduct || !premiumProduct);
  const showLoading = loading && (!freeProduct || !premiumProduct);

  const isCurrentProduct = useCallback(
    (product) => {
      return account?.product_id === product.id;
    },
    [account]
  );

  const purchase = useCallback(
    (product, price) => {
      if (product === freeProduct) {
        push('/pages/register');
      } else {
        push(`/products/${product.id}/purchase/${price.id}`, {
          authPage: 'register',
        });
      }
    },
    [push, freeProduct]
  );

  return (
    <div
      id="pricing-page"
      className={classnames([
        'featured-form',
        styles.root,
        lightMode ? styles.lightMode : '',
      ])}
      data-test="pricing-list"
    >
      <div className="full-page-content">
        <div className="container">
          <div className={classnames(['content', styles.content])}>
            {showLoading ? null : (
              <div>
                <h2 className={classnames(['mb-5', styles.titleText])}>
                  {titleText}
                </h2>
                {children ? (
                  <section className={styles.children}>{children}</section>
                ) : null}
                {isError ? (
                  <p className="danger">
                    Oops, looks like there was a problem loading the available
                    plans.
                  </p>
                ) : (
                  <div>
                    <div className={styles.billingCycle}>
                      <div>Billing Cycle:</div>
                      <button
                        className={classnames({
                          [`btn btn-link ${styles.toggleButton}`]: true,
                          [styles.selected]: selectedCycle === 'month',
                        })}
                        value="month"
                        onClick={handleCycleChange}
                      >
                        Monthly
                      </button>
                      <div
                        className={classnames({
                          [styles.toggle]: true,
                          [styles.checked]: selectedCycle === 'year',
                        })}
                        onClick={() =>
                          setSelectedCycle(
                            selectedCycle === 'year' ? 'month' : 'year'
                          )
                        }
                      />
                      <button
                        className={classnames({
                          [`btn btn-link ${styles.toggleButton}`]: true,
                          [styles.selected]: selectedCycle === 'year',
                        })}
                        value="year"
                        onClick={handleCycleChange}
                      >
                        Yearly
                      </button>
                    </div>
                    <div className={styles.plans}>
                      {freeProduct && (
                        <Product
                          product={freeProduct}
                          isCurrent={isCurrentProduct(freeProduct)}
                          buttonLabel="Get started"
                          onBuy={purchase}
                          disabled={isCurrentProduct(premiumProduct)}
                          cycle={selectedCycle}
                          lightMode={lightMode}
                        >
                          <p className="lead">
                            Start using Waypointr for free with some great basic
                            features.
                          </p>
                          <PricingFeaturesList
                            features={[
                              '1 team',
                              '90 day history',
                              'Industry-leading assessments for analyzing where you stand',
                              '10 participant cap per assessment',
                            ]}
                          />
                        </Product>
                      )}

                      {premiumProduct && (
                        <Product
                          product={premiumProduct}
                          isCurrent={isCurrentProduct(premiumProduct)}
                          buttonLabel={
                            isCurrentProduct(freeProduct)
                              ? 'Upgrade to premium'
                              : 'Buy premium'
                          }
                          onBuy={purchase}
                          buttonColor="primary"
                          cycle={selectedCycle}
                          lightMode={lightMode}
                        >
                          <div>
                            <p className="lead">
                              Enjoy all of the great features of the Free plan
                              plus:
                            </p>
                            <PricingFeaturesList
                              features={[
                                'No team limit (requires one license per team)',
                                'Enterprise security',
                                'Build and manage your organizational tree to perform enhanced analysis',
                                '20 participant cap per assessment',
                                'Unlimited history',
                              ]}
                            />
                          </div>
                        </Product>
                      )}

                      <ContactUsProduct
                        product={enterpriseProduct}
                        isCurrent={isCurrentProduct(enterpriseProduct)}
                        buttonLabel="Contact us"
                        onBuy={purchase}
                        buttonColor="primary"
                        cycle={selectedCycle}
                        lightMode={lightMode}
                      >
                        <div>
                          <p className="lead">
                            Enjoy all of the great features of the Premium plan
                            plus:
                          </p>
                          <PricingFeaturesList
                            features={[
                              'Customize and benchmark your own assessments',
                              'Integrate system data for a more holistic view',
                              'Product evaluations to understand how happy your customers are',
                              'and more…',
                            ]}
                          />
                        </div>
                      </ContactUsProduct>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
}

export default PricingList;
