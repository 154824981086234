import React from 'react';
import { Link } from 'react-router-dom';
import { FeatureCard, PanelHeader } from 'js/components';
import { faSitemap } from '@fortawesome/pro-solid-svg-icons';
import {
  faAnalytics,
  faChartNetwork,
  faMailBulk,
} from '@fortawesome/pro-regular-svg-icons';

import styles from './OrgTeaser.module.scss';
import UpgradeNudge from 'js/components/UpgradeNudge';
import { useFlags } from 'js/hooks/useFlags';
import { Card, CardBody } from 'reactstrap';

const OrgTeaser = ({ account }) => {
  const flags = useFlags();

  return (
    <div>
      <PanelHeader size="flex">
        <div className="panel-content px-4">
          <div className="orgs-panel-header">
            <div>
              <h1>Organizations</h1>
              <p>
                Organizations allow you to build org structures for viewing the
                performance of multiple teams.
              </p>
            </div>
          </div>
        </div>
      </PanelHeader>
      <div className="content">
        <UpgradeNudge>
          <p>Organizations aren't included in Waypointr's free tier.</p>
          {flags.stripe ? (
            <p>
              <Link to={`/a/${account?.id}/settings/billing`}>
                Upgrade to a paid plan
              </Link>{' '}
              to manage your organization and unlock a bunch of other great
              stuff!
            </p>
          ) : (
            <p>
              Contact{' '}
              <a href="mailto:support@waypointr.com">support@waypointr.com</a>{' '}
              to find a reseller or get on the list to be part of our our closed
              beta.
            </p>
          )}
          <p>
            This is just a sampling of the organizational management
            deliciousness that awaits you:
          </p>
        </UpgradeNudge>
        <section>
          <Card>
            <CardBody className={styles.cards}>
              <FeatureCard
                src="/static/media/orgs/org-editor.png"
                alt="Org Tree"
                title="Build your org structure"
                icon={faSitemap}
                className={styles.feature}
              >
                <p>
                  Want to map teams to reporting structure? Value streams? Both?
                  You can build as many org structures as you need to see the
                  data how you want to see it. A team can be in multiple org
                  structures and you can even compose larger org structures from
                  smaller ones to make managing them easier.
                </p>
              </FeatureCard>
              <FeatureCard
                src="/static/media/orgs/org-participation-tree.png"
                alt="Org Tree"
                title="Track assessment progress"
                icon={faMailBulk}
                className={styles.feature}
              >
                <p>
                  Chasing people down for responses is half the battle. View
                  response rates down to the team level and send reminders so
                  you can focus on the work, not the follow-up.
                </p>
              </FeatureCard>
              <FeatureCard
                src="/static/media/orgs/org-results-tree.png"
                alt="Org Tree"
                title="See how your results roll up"
                icon={faChartNetwork}
                className={styles.feature}
              >
                <p>
                  Challenges aren't consistent throughout your organization. The
                  right visualizations help you see hot spots so you can focus
                  your efforts where they're needed most.
                </p>
              </FeatureCard>
              <FeatureCard
                src="/static/media/orgs/analytics.png"
                alt="Org Tree"
                title="Dig into organizational trends"
                icon={faAnalytics}
                className={styles.feature}
              >
                <p>
                  Where you are today isn't as important as where you're going.
                  Any organization can get better, and any organization can get
                  worse. Knowhing which direction you're headed is the first
                  step to getting where you want to be.
                </p>
              </FeatureCard>
            </CardBody>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default OrgTeaser;
