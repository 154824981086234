import React, { useCallback, useState } from 'react';
import { uniq } from 'lodash';

import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import { Icon } from '..';

function InviteForm({
  onSendInvites,
  onCancel,
  loading,
  team,
  heading = 'Tell people about the survey',
  subHeading = 'Bring one or a hundred of your closest friends. We have plenty of room.',
  clearAfterSend = false,
}) {
  const [emailInviteState, setEmailInviteState] = useState({
    validEmails: 0,
    invitees: [],
    value: '',
    message: '',
  });
  const [displayCustomMessage, setDisplayCustomMessage] = useState(false);
  const invitingSomebody = emailInviteState.validEmails > 0;

  const updateEmailInviteState = (value) => {
    /* eslint-disable no-useless-escape, no-control-regex */
    // Borrowing this from Yup temporarily (with modifications)
    const emailRegex =
      /((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))+/gi;
    /* eslint-enable no-useless-escape, no-control-regex */

    const matches = uniq(
      [...(value || '').matchAll(emailRegex)].map((m) => m[0]),
      (x) => x.toLowerCase()
    );

    setEmailInviteState({
      ...emailInviteState,
      validEmails: matches.length,
      invitees: matches,
      value: value,
    });
  };

  const handleEmailsChange = (e) => {
    updateEmailInviteState(e.target.value);
  };

  const handleMessageChange = (e) => {
    setEmailInviteState({
      ...emailInviteState,
      message: e.target.value,
    });
  };

  const handleAddTeamMembers = (e) => {
    e.preventDefault();

    const inviteeMap = emailInviteState.invitees.reduce((acc, x) => {
      acc[x] = true;
      return acc;
    }, {});

    const membersToAdd = team.members.reduce((acc, member) => {
      if (!inviteeMap.hasOwnProperty(member.email)) {
        acc.push(`${member.name} <${member.email}>`);
      }

      return acc;
    }, []);

    if (membersToAdd.length > 0) {
      const existingValueDivider =
        emailInviteState.value.length === 0 ? '' : '\n';
      updateEmailInviteState(
        emailInviteState.value + existingValueDivider + membersToAdd.join('\n')
      );
    }
  };

  const resetForm = useCallback(() => {
    setEmailInviteState({
      validEmails: 0,
      invitees: [],
      value: '',
      message: '',
    });
  }, [setEmailInviteState]);

  const handleSend = useCallback(
    (e) => {
      e.preventDefault();
      if (invitingSomebody && !loading) {
        const res = onSendInvites(
          emailInviteState.invitees,
          emailInviteState.message
        );
        if (clearAfterSend && res?.then) {
          res.then(() => resetForm());
        } else if (clearAfterSend) {
          resetForm();
        }
      }
    },
    [
      emailInviteState,
      onSendInvites,
      invitingSomebody,
      loading,
      clearAfterSend,
      resetForm,
    ]
  );

  const handleCancel = useCallback(() => {
    resetForm();
    onCancel();
  }, [resetForm, onCancel]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.code === 'Enter' && e.ctrlKey) {
        handleSend(e);
      }
    },
    [handleSend]
  );

  return (
    <section>
      {heading && <h5 className="mb-0">{heading}</h5>}
      {subHeading && <p className="text-muted">{subHeading}</p>}
      <Form onSubmit={handleSend}>
        {!displayCustomMessage && (
          <button
            className="bg-transparent border-0 pl-0"
            onClick={() => setDisplayCustomMessage(true)}
          >
            <Label for="custom-message" style={{ cursor: 'pointer' }}>
              Add a note to the invite
            </Label>
          </button>
        )}
        {displayCustomMessage && (
          <>
            <Label for="custom-message">Add a note to the invite</Label>
            <FormGroup>
              <Input
                id="message"
                name="text"
                type="textarea"
                onChange={handleMessageChange}
              />
            </FormGroup>
          </>
        )}
        <FormGroup>
          <div>
            <Label
              className="mt-2"
              for="emails"
              style={{ display: 'inline-block' }}
            >
              Email addresses
            </Label>
            {team?.members?.length > 0 && (
              <span className="label">
                {' '}
                (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={handleAddTeamMembers}
                >
                  Add all team members
                </button>
                )
              </span>
            )}
          </div>
          <Input
            id="emails"
            name="text"
            type="textarea"
            onChange={handleEmailsChange}
            value={emailInviteState.value}
            onKeyPress={handleKeyPress}
          />
          {!invitingSomebody ? null : (
            <FormText>
              Inviting {emailInviteState.validEmails}{' '}
              {emailInviteState.validEmails === 1 ? 'person' : 'people'}
            </FormText>
          )}
        </FormGroup>
        {onCancel && <Button onClick={handleCancel}>Cancel</Button>}
        <Button
          type="submit"
          data-test="send-invites-btn"
          color="primary"
          disabled={!invitingSomebody || loading}
        >
          <Icon icon="envelope" className="btn-icon-left" />
          Send invites
        </Button>
      </Form>
    </section>
  );
}

export default InviteForm;
