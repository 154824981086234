import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import {
  load as loadOrg,
  listAssessmentHistorySummaries,
} from 'js/actions/org-actions';

import { selectAssessmentHistorySummaries } from 'js/store/reducers/queries';

import OrgComponentRouter from '../components/OrgComponent/OrgComponentRouter';
import { denormalize } from 'normalizr';
import { SCHEMAS } from 'js/api/schemas';
import assessmentTemplates from 'js/data/assessment-templates';

const OrgContainer = ({
  loadOrg,
  listAssessmentHistorySummaries,
  match,
  org,
  workspace,
  assessmentHistorySummaries,
}) => {
  const {
    params: { orgId },
  } = match;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const filteredAssessmentHistorySummaries = useMemo(
    () =>
      assessmentHistorySummaries.filter((s) =>
        assessmentTemplates.some((t) => t.key === s.assessment_key)
      ),
    [assessmentHistorySummaries]
  );

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          loadOrg(orgId),
          listAssessmentHistorySummaries(orgId),
        ]);
      } catch (e) {
        console.error('failed to load the org:', e);
        setError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [loadOrg, orgId, setLoading, setError, listAssessmentHistorySummaries]);

  return (
    <OrgComponentRouter
      organization={org}
      assessmentHistorySummaries={filteredAssessmentHistorySummaries}
      workspace={workspace}
      match={match}
      loading={loading}
      error={error}
    />
  );
};

export default connect(
  (
    store,
    {
      match: {
        params: { orgId, workspaceId },
      },
    }
  ) => {
    const { entities } = store;

    return {
      org: denormalize(
        entities.organizations[orgId],
        SCHEMAS.ORGANIZATION,
        entities
      ),
      workspace: entities.workspaces[workspaceId],
      assessmentHistorySummaries:
        selectAssessmentHistorySummaries(store, orgId)?.data ?? [],
    };
  },
  {
    loadOrg,
    listAssessmentHistorySummaries,
  }
)(OrgContainer);
