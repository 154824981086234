import React, { useLayoutEffect, useState, useRef } from 'react';
import { sumBy } from 'lodash';

import { scaleLinear } from '@visx/scale';
import { Link } from 'react-router-dom';
import QuestionScore from './QuestionScore';
import { scaleColorTraffic } from 'js/utils/color';
import { FIELD_DELIMITER } from 'js/utils/string';
import { isNumeric } from 'js/utils/question-set';

const ReviewChart = ({ assessment, participantId, height, colors }) => {
  const chartRef = useRef();
  const [width, setWidth] = useState(null);

  useLayoutEffect(() => {
    const handleResize = () => {
      setWidth(chartRef.current ? chartRef.current.offsetWidth : null);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const questionCount = sumBy(
    assessment.question_sets,
    (qs) => qs.questions.length
  );
  let qsShift = 0;
  const questionWidth = width / questionCount;

  let dotDiameter = 8;
  let barWidth = 2;

  if (dotDiameter > 0.5 * questionWidth) {
    dotDiameter = 0.5 * questionWidth;
    barWidth = dotDiameter / 4;
  }

  let minHeight = 0.1 * height;
  let maxHeight = 0.9 * height;
  const reserveHeight = 0.05 * height;

  if (maxHeight + dotDiameter / 2 > height - reserveHeight) {
    maxHeight = height - reserveHeight - dotDiameter / 2;
  }

  if (minHeight - dotDiameter / 2 < reserveHeight) {
    minHeight = reserveHeight + dotDiameter / 2;
  }

  const colorDomain = { '': scaleColorTraffic };

  return (
    <div className="survey-review-chart" ref={chartRef}>
      <h3 className="chart-title">Your responses:</h3>
      {(() =>
        width === null ? null : (
          <svg viewBox={`0 0 ${width} ${height}`}>
            {assessment.question_sets.map((qs) => {
              const currentQsShift = qsShift;
              qsShift += qs.questions.length * questionWidth;
              return (
                <Link
                  key={qs.id}
                  transform={`translate(${currentQsShift},0)`}
                  to={`/assessments/${assessment.id}/run/set/${qs.id}`}
                >
                  {qs.questions.map((q, i) => {
                    let color = scaleColorTraffic;
                    if (isNumeric(q)) {
                      const answerDomain = q.answers
                        .map((a) => a.value)
                        .sort()
                        .join(FIELD_DELIMITER);

                      if (colorDomain[answerDomain] === undefined) {
                        const domain = q.answers.reduce(
                          (acc, a) => {
                            const value = +a.value;

                            return {
                              min:
                                acc.min === null
                                  ? value
                                  : Math.min(value, acc.min),
                              max:
                                acc.max === null
                                  ? value
                                  : Math.max(value, acc.max),
                            };
                          },
                          { min: null, max: null }
                        );

                        colorDomain[answerDomain] = scaleLinear({
                          domain: [domain.min, domain.max],
                          range: colors.range,
                        });
                      }

                      color = colorDomain[answerDomain];
                    }

                    return (
                      <g
                        key={q.id}
                        transform={`translate(${i * questionWidth}, 0)`}
                      >
                        <QuestionScore
                          question={q}
                          participantId={participantId}
                          width={questionWidth}
                          height={height}
                          minHeight={minHeight}
                          maxHeight={maxHeight}
                          dotDiameter={dotDiameter}
                          barWidth={barWidth}
                          color={color}
                        />
                      </g>
                    );
                  })}
                  <line
                    x1={0.1 * questionWidth}
                    y1={height}
                    x2={
                      questionWidth * qs.questions.length - 0.1 * questionWidth
                    }
                    y2={height}
                    className="question-set-bar"
                  />
                </Link>
              );
            })}
          </svg>
        ))()}
    </div>
  );
};

ReviewChart.defaultProps = {
  colors: { range: ['#e5eae3', '#375A28'] },
  height: 50,
};

export default ReviewChart;
