export const cultureQuestionSets = [
  {
    name: 'Collaboration',
    key: 'collaboration',
    category: 'culture',
    questions: [
      {
        text: 'Collaboration within my team is encouraged and rewarded',
        key: 'intra-team-collaboration',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: `I am encouraged to collaborate with other teams to advance the organization's goals`,
        key: 'inter-team-collaboration',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Continuous Improvement',
    key: 'continuous-improvement',
    category: 'culture',
    questions: [
      {
        text: `I feel safe and comfortable raising problems and improvement opportunities to leadership, even if I don't have a proposed solution`,
        key: 'safety-without-solutions',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Leadership prioritizes and works on improvement opportunities the same as other work ',
        key: 'leadership-improvement-backlog',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We use data on team performance to identify opportunities for improvement and measure the impact',
        key: 'data-driven-team-performance',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Generative Culture',
    key: 'generative-culture',
    category: 'culture',
    questions: [
      {
        text: 'Messengers feel secure delivering bad news (e.g. production issues, setbacks, etc.) candidly and without hesitation',
        key: 'punish-messengers',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'It is easy to collaborate across functional areas of the organization',
        key: 'easy-cross-collaboration',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Risks are shared between stakeholders and delivery teams',
        key: 'shared-risks',
        answers: [
          { value: '1', text: 'Never' },
          { value: '2', text: 'Very rarely' },
          { value: '3', text: 'Rarely' },
          { value: '4', text: 'Occasionally' },
          { value: '5', text: 'Very frequently' },
          { value: '6', text: 'Always' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'Success is shared between stakeholders and delivery teams',
        key: 'shared-success',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'When failures occur, my organization focuses on learning over blame',
        key: 'failures-inspire-learning',
        answers: [
          { value: '1', text: 'Never' },
          { value: '2', text: 'Very rarely' },
          { value: '3', text: 'Rarely' },
          { value: '4', text: 'Occasionally' },
          { value: '5', text: 'Very frequently' },
          { value: '6', text: 'Always' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Leadership',
    key: 'leadership',
    category: 'culture',
    questions: [
      {
        text: 'My leader or manager has a clear understanding of how my team supports the organization',
        key: 'leader-understands-team-fit',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'My leader or manager says positive things about my team and the work we produce',
        key: 'leader-gives-positive-feedback',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'My leader or manager challenges me to think about old problems in new ways',
        key: 'leader-encourages-new-solutions',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'I feel comfortable, safe and heard when sharing ideas and concerns with my leader or manager',
        key: 'leader-psychological-safety',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'My leader or manager leads by inspiration rather than authority',
        key: 'lead-by-inspiration',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'My leader advocates effectively for my needs',
        key: 'leader-as-advocate',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Learning',
    key: 'learning',
    category: 'culture',
    questions: [
      {
        text: 'Failures are expected and treated primarily as opportunities to improve the system',
        key: 'failures-as-opportunity',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'New ideas are welcomed and considered, regardless of where they come from',
        key: 'welcome-new-ideas',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'We identify unsuccessful pilots and experiments quickly and abandon or pivot them (i.e. we fail fast and refocus our efforts quickly)',
        key: 'abandon-failed-approaches-quickly',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
  {
    name: 'Make Work Meaningful',
    key: 'make-work-meaningful',
    category: 'culture',
    questions: [
      {
        text: 'I am glad I chose to work for this organization rather than another company',
        key: 'glad-i-work-here',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'I talk of this organization to my friends as a great company to work for',
        key: 'tell-friends-great-company',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: `I am excited to go to work every day knowing the challenges I'm presented with are worth solving`,
        key: 'motivated-by-work',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: `My values and my organization's values are very similar`,
        key: 'values-align',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
      {
        text: 'My organization cares about me',
        key: 'organization-cares-about-me',
        answers: [
          { value: '1', text: 'Strongly disagree' },
          { value: '2', text: 'Disagree' },
          { value: '3', text: 'Somewhat disagree' },
          { value: '4', text: 'Neither agree nor disagree' },
          { value: '5', text: 'Somewhat agree' },
          { value: '6', text: 'Agree' },
          { value: '7', text: 'Strongly agree' },
          { value: null, text: 'N/A', is_na: true },
        ],
      },
    ],
  },
];

export default cultureQuestionSets;
