import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ConfirmModal from 'js/components/modal/ConfirmModal';
import _ from 'lodash';

const ConfirmArchiveAssessModal = ({
  assmToArchive,
  setParentAssmToArchive,
  onDeleteAssessment,
}) => {
  const insertedAt = moment(assmToArchive?.inserted_at);

  return (
    <ConfirmModal
      isOpen={!!assmToArchive}
      title="Archive assessment?"
      onConfirm={() => {
        onDeleteAssessment(assmToArchive);
        setParentAssmToArchive(null);
      }}
      onCancel={() => setParentAssmToArchive(null)}
      confirmButtonLabel="Archive"
    >
      <p>
        Please confirm that you want to archive{' '}
        <strong>{assmToArchive?.name || 'this assessment'}</strong> from{' '}
        <i>{insertedAt.format('MMMM Do YYYY, h:mm:ss a')}</i>.
      </p>
    </ConfirmModal>
  );
};

ConfirmArchiveAssessModal.propTypes = {
  onDeleteAssessment: PropTypes.func,
};

ConfirmArchiveAssessModal.defaultProps = {
  onDeleteAssessment: _.noop,
};

export default ConfirmArchiveAssessModal;
