import React, { useCallback, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import AsyncButton from 'js/components/Forms/AsyncButton';
import { Icon } from 'js/components';

function SendRemindersModal({
  isOpen,
  onConfirm,
  onCancel,
  assessmentIds,
  orgName,
}) {
  const [threshold, setThreshold] = useState('0.9');

  const handleConfirm = useCallback(() => {
    return onConfirm(assessmentIds, +threshold);
  }, [assessmentIds, threshold, onConfirm]);

  return (
    <Modal isOpen={isOpen} size="100px">
      <ModalHeader>
        <div className="primary">
          <Icon
            fixedWidth
            icon={['fas', 'exclamation-triangle']}
            className="mr-2"
          />
          Send reminders?
        </div>
      </ModalHeader>
      <ModalBody>
        <div style={{ lineHeight: '24px' }}>
          You are about to send reminders for{' '}
          <strong>{assessmentIds.length}</strong> assessment
          {assessmentIds.length > 1 && 's'} under{' '}
          <strong style={{ whiteSpace: 'nowrap' }}>{orgName}</strong>.
        </div>
        <div className="mt-3 mb-4">
          <span>Remind anyone that has completed less than:</span>
          <Input
            id="exampleSelect"
            name="select"
            type="select"
            value={threshold}
            onChange={(e) => setThreshold(e.target.value)}
          >
            <option value="1">All questions</option>
            <option value="0.9">90% of the questions</option>
            <option value="0.8">80% of the questions</option>
            <option value="0.7">70% of the questions</option>
            <option value="0.6">60% of the questions</option>
            <option value="0.5">50% of the questions</option>
            <option value="0.4">40% of the questions</option>
            <option value="0.3">30% of the questions</option>
            <option value="0.2">20% of the questions</option>
            <option value="0.1">10% of the questions</option>
          </Input>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel}>Cancel</Button>
        <AsyncButton
          color="primary"
          onClick={handleConfirm}
          data-test="confirm-btn"
        >
          Send reminders
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
}

export default SendRemindersModal;
