import React from 'react';
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';

const ConfigurationOverview = ({ questionCount }) => {
  const a = 1.2453;
  const b = 0.6183;

  const estimatedCompletionTime =
    Math.round(2 * (a * Math.pow(questionCount, b))) / 2;
  const completionTimeHasHalfMinutes = estimatedCompletionTime % 1 > 0;

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h6">Overview</CardTitle>
      </CardHeader>
      <CardBody>
        <div className="row">
          <div className="col-5">
            <label>Questions</label>
            <p data-test="question-count-val">{questionCount}</p>
          </div>
          <div className="col-7">
            <label>Est. Time</label>
            <p>
              {questionCount > 0 ? (
                <>
                  {Math.floor(estimatedCompletionTime)}
                  {completionTimeHasHalfMinutes ? <>&frac12;</> : null} minutes
                </>
              ) : (
                <>N/A</>
              )}
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

ConfigurationOverview.defaultProps = {
  categoryFilters: {},
  questionSetFilters: {},
};

export default ConfigurationOverview;
