export const Role = Object.freeze({
  Admin: 'admin',
});

export function canInviteUser(user, workspaceId) {
  return getWorkspaceUser(user, workspaceId)?.role === Role.Admin;
}

export function canChangeWorkspaceSettings(user, workspaceId) {
  return getWorkspaceUser(user, workspaceId)?.role === Role.Admin;
}

export function canCreateTeams(user, workspaceId) {
  return getWorkspaceUser(user, workspaceId)?.role === Role.Admin;
}

export function canManageAccount(user, accountId) {
  return user.accounts.some((a) => a.id === accountId);
}

function getWorkspaceUser(user, workspaceId) {
  return user?.workspaces_users?.find((wu) => wu.workspace_id === +workspaceId);
}
