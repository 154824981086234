import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { updateAccount } from 'js/actions/account-actions';

import AccountsSettingsComponent from '../components/AccountsSettingsComponent';
import { notify } from 'js/actions/alert-actions';

function AccountsSettings({ account, me, updateAccount, notify }) {
  const onSubmit = useCallback(
    async (accountName) => {
      try {
        await updateAccount(accountName, account.id);
        notify({
          type: 'success',
          message: 'Account name updated successfully',
        });
      } catch (e) {}
    },
    [account, updateAccount, notify]
  );

  return (
    <AccountsSettingsComponent me={me} account={account} onSubmit={onSubmit} />
  );
}

export default connect(
  (state, { me, account }) => {
    return {};
  },
  {
    updateAccount,
    notify,
  }
)(AccountsSettings);
