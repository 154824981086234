// This is a version of the keyMirror library (ultimately from facebook)
// modified to pass options for an additional prefix and/or suffix when
// generating the keys.
//
// https://github.com/STRML/keyMirror

/**
 * Constructs an enumeration with keys equal to their value.
 *
 * For example:
 *
 *   var COLORS = keyMirror({blue: null, red: null});
 *   var myColor = COLORS.blue;
 *   var isColorValid = !!COLORS[myColor];
 *
 * The last line could not be performed if the values of the generated enum were
 * not equal to their keys.
 *
 *   Input:  {key1: val1, key2: val2}
 *   Output: {key1: key1, key2: key2}
 *
 * @param {object} obj - The object to mirror
 * @param {Object} opts - Optional configuration
 * @param {string} [opts.prefix=] - A string to prepend to every key
 * @param {string} [opts.suffix=] - A string to append to every key
 * @return {object} - A new object with the same keys whose values are = opts.prefix + key + opts.suffix
 */

export const keyMirror = (obj, opts) => {
  const options = {
    prefix: '',
    suffix: '',
    ...opts
  };

  var ret = {};
  var key;
  if (!(obj instanceof Object && !Array.isArray(obj))) {
    throw new Error('mirror(...): Argument must be an object.');
  }
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      ret[key] = options.prefix + key + options.suffix;
    }
  }
  return ret;
};

export default keyMirror;
