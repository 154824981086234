/* eslint jsx-a11y/anchor-is-valid: "off" */
import React, { useCallback, useMemo } from 'react';

import { ListNavLink, Icon } from '../..';
import { Nav, NavItem } from 'reactstrap';
import { Link, Route, withRouter } from 'react-router-dom';

import { Button } from 'js/components';
import Accounts from './Accounts';
import SettingsSubmenu from './SettingsSubmenu';

const AccountsSidebar = ({ match, user }) => {
  const accounts = user.accounts;
  const activeAccount = useMemo(() => {
    return match.params.accountId === undefined
      ? null
      : user.accounts.find((a) => a.id === match.params.accountId);
  }, [user.accounts, match.params.accountId]);

  const toggleSidebar = useCallback(() => {
    document.body.classList.toggle('sidebar-mini');
  }, []);

  return (
    <div>
      <div className="sidebar" data-color="blue">
        <div className="logo">
          <Link to="/dashboard" className="simple-text logo-mini">
            <Icon icon={['fas', 'map-marker-alt']} />
          </Link>
          <Link to="/dashboard" className="simple-text logo-normal">
            Waypointr
          </Link>
          <div className="navbar-minimize">
            <Button
              simple
              neutral
              icon
              round
              color="transparent"
              id="minimizeSidebar"
              onClick={toggleSidebar}
            >
              <i className="visible-on-sidebar-regular">
                <Icon icon="align-center" />
              </i>
              <i className="visible-on-sidebar-mini">
                <Icon icon="list-ul" />
              </i>
            </Button>
          </div>
        </div>

        <div className="sidebar-wrapper">
          <Accounts accounts={accounts} activeAccount={activeAccount} />

          <Nav>
            <ListNavLink
              to={`/a/${activeAccount.id}`}
              exact
              className="nav-link"
              activeClassName="active"
            >
              <i>
                <Icon icon="tachometer-alt" fixedWidth />
              </i>
              <p data-test="account-dash-p-tag">Account Dashboard</p>
            </ListNavLink>
            <ListNavLink
              data-test="nav-users"
              to={`/a/${activeAccount.id}/users`}
              exact
              className="nav-link"
              activeClassName="active"
            >
              <i>
                <Icon icon="user-friends" fixedWidth />
              </i>
              <p>Users</p>
            </ListNavLink>

            <ListNavLink
              to={`/a/${activeAccount.id}/settings`}
              className="nav-link"
              activeClassName="active"
            >
              <i>
                <Icon icon="cog" fixedWidth />
              </i>
              <p data-test="account-settings-p-tag">Settings</p>
            </ListNavLink>
            <Route path="/a/:accountId/settings">
              <NavItem className="submenu">
                <SettingsSubmenu />
              </NavItem>
            </Route>
          </Nav>
        </div>
      </div>
    </div>
  );
};

// AccountsSidebar.propTypes = {
//   me: PropTypes.object.isRequired,
//   activeAccount: PropTypes.object.isRequired,
//   changeWorkspace: PropTypes.func,
// };

// AccountsSidebar.defaultProps = {
//   changeWorkspace: () => {},
// };

export default withRouter(AccountsSidebar);
