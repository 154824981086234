import React from 'react';
import DifferentialItem from 'js/components/Charts/Differential/DifferentialItem';

export const ScorableQuestionDifferential = ({
  from,
  to,
  width = 80,
  height = 100,
  yScale,
}) => {
  const question = to ?? from;
  const [minAnswerValue, maxAnswerValue] = question.answerDomain;
  const answerScalar =
    minAnswerValue === maxAnswerValue
      ? 1
      : 1 / (maxAnswerValue - minAnswerValue);

  const ticks = Object.keys((from ?? to)?.answers ?? {})
    .map(
      (answerValue) => (parseFloat(answerValue) - minAnswerValue) * answerScalar
    )
    .slice(1, -1);
  return (
    <DifferentialItem
      from={from?.normalizedScore}
      to={to?.normalizedScore}
      ticks={ticks}
      arrowId={'arrow'}
      width={width}
      height={height}
      yScale={yScale}
    />
  );
};

export default ScorableQuestionDifferential;
