import React from 'react';
import OverviewDetailPane from './OverviewDetailPane';
import QuestionDetailPane from './QuestionDetailPane';
import AssessmentDetailPane from './AssessmentDetailPane';

const DetailsPane = (props) => {
  const { filter } = props;
  const filterType = filter?.type;

  // prettier-ignore
  return filterType === 'question' ? <QuestionDetailPane {...props} /> 
    : filterType === 'assessment' ? <AssessmentDetailPane {...props} /> 
    : <OverviewDetailPane {...props} />;
};

export default DetailsPane;
