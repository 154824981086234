import React from 'react';

export const RecommendationsGlyph = (
  <svg viewBox="0 0 32 32" className="chart-glyph assessment-recommendations">
    <rect x="2" y="2" width="25" height="8" className="bar-1" />
    <rect x="2" y="12" width="17" height="8" className="bar-2" />
    <rect x="2" y="22" width="8" height="8" className="bar-3" />
  </svg>
);

export const ScorecardGlyph = (
  <svg viewBox="0 0 32 32" className="chart-glyph squad-scorecard">
    <g transform="translate(0,3.5)">
      <rect x="1" y="0" width="6" height="3" className="bar" />
      <circle cx="15" cy="1.5" r="4" className="circle-g" />
      <circle cx="27" cy="1.5" r="4" className="circle-y" />
    </g>
    <g transform="translate(0,14.5)">
      <rect x="1" y="0" width="6" height="3" className="bar" />
      <circle cx="15" cy="1.5" r="4" className="circle-y" />
      <circle cx="27" cy="1.5" r="4" className="circle-g" />
    </g>
    <g transform="translate(0,25.5)">
      <rect x="1" y="0" width="6" height="3" className="bar" />
      <circle cx="15" cy="1.5" r="4" className="circle-y" />
      <circle cx="27" cy="1.5" r="4" className="circle-r" />
    </g>
  </svg>
);

export const ResultsGlyph = (
  <svg viewBox="0 0 32 32" className="chart-glyph assessment-recommendations">
    <path d="M 3 30 L 3 26 A 3 3 0 0 1 9 26 L 9 30" className="bar-1" />
    <path d="M 13 30 L 13 16 A 3 3 0 0 1 19 16 L 19 30" className="bar-2" />
    <path d="M 23 30 L 23 7 A 3 3 0 0 1 29 7 L 29 30" className="bar-3" />
    <line
      x1="1"
      y1="30"
      x2="31"
      y2="30"
      className="axis"
      stroke="#ccc"
      strokeWidth="2"
    />
    {/* <rect x="2" y="2" width="25" height="8" className="bar-1" />
    <rect x="2" y="12" width="17" height="8" className="bar-2" />
    <rect x="2" y="22" width="8" height="8" className="bar-3" /> */}
  </svg>
);

export const QuadrantGlyph = (
  <svg viewBox="0 0 32 32" className="chart-glyph quadrant">
    <g>
      <rect x="16" y="2" width="14" height="14" className="target-quadrant" />
      <line x1="16" y1="2" x2="16" y2="30" className="cross" />
      <line x1="1" y1="16" x2="30" y2="16" className="cross" />
    </g>
    <g>
      <circle cx="8" cy="9" r="3" className="circle-1" />
      <circle cx="10" cy="23" r="3" className="circle-2" />
      <circle cx="15" cy="15" r="3" className="circle-3" />
      <circle cx="23" cy="7" r="3" className="circle-4" />
      <circle cx="26" cy="18" r="3" className="circle-5" />
    </g>
    <path d="M1,2 L1,30 L31,30" className="axis" />
  </svg>
);

export const TreeGlyph = (
  <svg viewBox="0 0 32 32" className="chart-glyph tree-map">
    <path d="M6,16 C16,16 11,6 26,6" className="node-connector" />
    <path d="M6,16 C16,16 11,16 26,16" className="node-connector" />
    <path d="M6,16 C16,16 11,26 26,26" className="node-connector" />
    <circle cx="6" cy="16" r="4" className="node-root" />
    <circle cx="26" cy="6" r="4" className="node-1" />
    <circle cx="26" cy="16" r="4" className="node-2" />
    <circle cx="26" cy="26" r="4" className="node-3" />
  </svg>
);

export const TreeGlyphRectangles = (
  <svg viewBox="0 0 32 32" className="chart-glyph tree-map-rectangles">
    <path d="M10,16 C18,16 13,6 26,6" className="node-connector" />
    <path d="M10,16 C18,16 13,16 26,16" className="node-connector" />
    <path d="M10,16 C18,16 13,26 26,26" className="node-connector" />
    <g>
      <path
        d="M5.5,13 l4.5,0 a 1 1 0 0 1 1 1 l0,4 a 1 1 0 0 1 -1 1 l-4.5,0 L5.5,13"
        className="node-root-2"
      />
      <path
        d="M6,13 l0,6 l-4,0 a 1 1 0 0 1 -1 -1 l0,-4 a 1 1 0 0 1 1 -1 L6,13"
        className="node-root-1"
      />
    </g>
    <rect x="21" y="3" width="10" height="6" rx="1" className="node-1" />
    <rect x="21" y="13" width="10" height="6" rx="1" className="node-2" />
    <rect x="21" y="23" width="10" height="6" rx="1" className="node-3" />
  </svg>
);

export const TrendGlyph = (
  <svg viewBox="0 0 32 32" className="chart-glyph trend-history">
    <path d="M4,10 L9,8 L17,12 L22,8 L30,6" className="line-1" fill="none" />
    <path d="M4,21 L9,16 L17,18 L22,13 L29,14" className="line-2" fill="none" />
    <path d="M1,2 L1,30 L31,30" className="axis" />
  </svg>
);

export const AreaGlyph = (
  <svg viewBox="0 0 32 32" className="chart-glyph trend-area">
    <path d="M1,10 L9,8 L17,12 L22,8 L30,6 L30,30 L1,30" className="area-1" />
    <path
      d="M1,21 L9,16 L17,18 L22,15 L30,14 L30,30 L1,30"
      className="area-2"
    />
    <path
      d="M1,27 L9,23 L17,30 L22,22 L30,25 L30,30 L1,30"
      className="area-3"
    />
    <path d="M1,2 L1,30 L31,30" className="axis" />
  </svg>
);
