import { request } from 'js/api/axios';

import { types as AssessmentTypes } from 'js/actions/assessment-actions';
import { types as LiveAssessmentTypes } from 'js/actions/live-assessment-actions';

import { callApi } from 'js/api';
import { SCHEMAS } from 'js/api/schemas';
import { extractApiKeys } from 'js/utils';

export function create(assessment) {
  const assessmentData = { assessment };
  return (dispatch, getState) => {
    return callApi(
      {
        types: extractApiKeys(
          AssessmentTypes.CREATE_ASSESSMENT,
          AssessmentTypes
        ),
        request: (data, token, currentWorkspaceId) =>
          // TODO: handle null workspace and team ids
          request.post(
            `/api/workspaces/${currentWorkspaceId}/teams/${assessment.team_id}/assessments`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
        data: assessmentData,
        schema: SCHEMAS.ASSESSMENT,
      },
      dispatch,
      getState
    ).then((result) => {
      const liveData = { live_assessment: { assessment_id: result.id } };

      // assessment.id;
      return callApi(
        {
          types: extractApiKeys(
            LiveAssessmentTypes.CREATE_LIVE_ASSESSMENT,
            LiveAssessmentTypes
          ),
          request: (data, token) =>
            request.post(`/api/live-assessments`, data, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
          data: liveData,
          schema: SCHEMAS.LIVE_ASSESSMENT,
        },
        dispatch,
        getState
      ).then(() => result);
    });
  };
}
