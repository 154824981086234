import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import UnassessedOrgComponent from './UnassessedOrgComponent';
import OrgAssessmentContainer from '../../containers/OrgAssessmentContainer';

const OrgComponentRouter = ({ match, ...props }) => {
  const { assessmentHistorySummaries } = props;
  const path = match.path;

  return (
    <Switch>
      <Route
        path={`${path}/:assessmentKey/detail/:subOrgId?`}
        render={(routerProps) => (
          <OrgAssessmentContainer {...props} {...routerProps} />
        )}
      />
      <Route
        path={`${path}/:assessmentKey`}
        exact
        render={({ match: m }) => (
          <Redirect
            to={`${match.url}/${m.params.assessmentKey}/detail/${match.params.orgId}`}
          />
        )}
      ></Route>
      <Route path={path} exact>
        {assessmentHistorySummaries.length > 0 ? (
          <Redirect
            to={`${match.url}/${assessmentHistorySummaries[0].assessment_key}/detail/${match.params.orgId}`}
          />
        ) : (
          <UnassessedOrgComponent {...props} />
        )}
      </Route>
    </Switch>
  );
};

export default OrgComponentRouter;
