import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Icon } from 'js/components';
import QuestionOverview from '../QuestionOverview';
import ParticipantList from './ParticipantList';

const WrapUp = ({
  assessment,
  participants,
  liveAssessmentData,
  onToggleAssessmentLock,
  onChangeQuestion,
}) => {
  if (!assessment.question_sets) {
    return null;
  }

  const counts = {};
  assessment.question_sets.forEach((qs) => {
    qs.questions.forEach((q) => {
      q.responses.forEach((r) => {
        const key = `${q.id}-${r.value}`;
        counts[key] = counts.hasOwnProperty(key) ? counts[key] + 1 : 1;
      });
    });
  });

  const yLimit = _.max(_.toArray(counts)) || 0;

  const handleToggleAssessmentLock = () => {
    onToggleAssessmentLock(assessment);
  };

  const handleChangeQuestion = (questionId) => {
    onChangeQuestion(questionId);
  };

  return (
    <div className="container-fluid wrapup">
      <div className="row">
        <div className="col col-12 col-md-9 order-md-1">
          <div className="row summary-bar">
            <div className="col-6 col-sm-8">
              <h2>Review summary</h2>
            </div>
            <div className="text-right col-6 col-sm-4">
              <button
                className="btn btn-primary"
                onClick={handleToggleAssessmentLock}
              >
                {assessment.is_locked ? (
                  <span>
                    <Icon icon="lock-alt" className="btn-icon-left" />
                    Locked
                  </span>
                ) : (
                  <span>
                    <Icon icon="lock-open-alt" className="btn-icon-left" />
                    Unlocked
                  </span>
                )}
              </button>
            </div>
          </div>
          <div className="question-sets">
            {assessment.question_sets.map((questionSet) => {
              return (
                <div key={questionSet.id} className="question-set">
                  <div className="questions">
                    {questionSet.questions.map((question) => (
                      <div key={question.id} className="">
                        <QuestionOverview
                          question={question}
                          responses={question.responses}
                          yLimit={yLimit}
                          onClick={handleChangeQuestion.bind(this, question.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col col-12 col-md-3 order-md-9">
          <div className="sidebar-item">
            <ParticipantList
              participants={participants}
              hostId={liveAssessmentData.host_id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

WrapUp.propTypes = {
  onToggleAssessmentLock: PropTypes.func,
  onChangeQuestion: PropTypes.func,
};

WrapUp.defaultProps = {
  onToggleAssessmentLock: () => {},
  onChangeQuestion: () => {},
};

export default WrapUp;
