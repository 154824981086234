import React from 'react';

import { Route, Switch } from 'react-router-dom';
import LiveAssessmentReviewDetailContainer from '../../containers/LiveAssessmentReviewDetailContainer';

import AssessmentReview from './AssessmentReview';

const LiveAssessmentReviewComponent = ({ match, ...myProps }) => {
  const props = {
    ...myProps,
  };

  return (
    <Switch>
      <Route
        path={`${match.path}/detail/:assessmentId`}
        render={(routeProps) => (
          <LiveAssessmentReviewDetailContainer {...props} {...routeProps} />
        )}
      />
      <Route
        path={`${match.path}`}
        render={(routeProps) => <AssessmentReview {...props} {...routeProps} />}
      />
    </Switch>
  );
};

export default LiveAssessmentReviewComponent;
