import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  verifyInviteToken,
  acceptInviteToken,
} from 'js/actions/account/user-actions';

import { useHistory } from 'react-router';

import { AsyncDisplay, LoadingMessage } from 'js/components';
import RegistrationPageComponent from 'js/routes/Pages/Account/components/RegistrationPageComponent';
import { notify } from 'js/actions/alert-actions';
import AcceptInviteSuccessComponent from '../components/AcceptInviteSuccessComponent';
import AcceptInviteFailureComponent from '../components/AcceptInviteFailureComponent';

function AcceptInviteContainer({
  token,
  verifyToken,
  location,
  registrationFormState,
  acceptInvite,
  notify,
}) {
  const { search } = location;
  const { push: pushHistory } = useHistory();
  const key = React.useMemo(
    () => new URLSearchParams(search).get('key'),
    [search]
  );

  const [error, setError] = useState(null);
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tokenDetails, setTokenDetails] = useState();

  useEffect(() => {
    (async () => {
      try {
        const details = await verifyToken(key, token);

        setTokenDetails(details);
        if (!details.requires_registration) {
          try {
            await acceptInvite(token, key);
            pushHistory(`/a/${details.account_id}`);
            setAccepted(true);
          } catch (e) {
            setError('There was a problem processing your request.');
          }
        }
      } catch (e) {
        setError('It looks like your invitation is not valid.');
      } finally {
        setLoading(false);
      }
    })();
  }, [
    token,
    setLoading,
    acceptInvite,
    key,
    verifyToken,
    setError,
    setTokenDetails,
    pushHistory,
  ]);

  const onRegister = useCallback(
    async (form) => {
      const { username, display_name, password } = form;

      try {
        await acceptInvite(token, key, { username, display_name, password });
        setAccepted(true);
      } catch (e) {
        notify({
          type: 'danger',
          message: 'Sorry, there was a problem signing you up',
        });
      }
    },
    [notify, acceptInvite, key, token, setAccepted]
  );

  return (
    <div id="accept-invite-page" className="featured-form">
      <div className="full-page-content">
        <div className="background-page">
          <div className="container">
            <div className="content">
              <div>
                <AsyncDisplay isLoading={loading} isError={!loading && !!error}>
                  <AsyncDisplay.Loading>
                    <LoadingMessage iconSize="2x">
                      Processing invitation&hellip;
                    </LoadingMessage>
                  </AsyncDisplay.Loading>
                  <AsyncDisplay.Error>
                    <AcceptInviteFailureComponent message={error} />
                  </AsyncDisplay.Error>
                  <AsyncDisplay.Resolved>
                    {accepted ? (
                      <AcceptInviteSuccessComponent
                        accountId={tokenDetails.account_id}
                        accountName={tokenDetails.account_name}
                      />
                    ) : (
                      tokenDetails?.requires_registration && (
                        <RegistrationPageComponent
                          formState={registrationFormState}
                          onRegister={onRegister}
                          defaultUsername={key}
                        />
                      )
                    )}
                  </AsyncDisplay.Resolved>
                </AsyncDisplay>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-page-background" />
    </div>
  );
}

export default connect(
  (state, props) => {
    const { match } = props;

    return {
      token: match.params.token,
      registrationFormState: state.forms.register,
    };
  },
  {
    verifyToken: verifyInviteToken,
    acceptInvite: acceptInviteToken,
    notify,
  }
)(AcceptInviteContainer);
