import React from 'react';
import ScorableQuestionDifferential from './ScorableQuestionDifferential';
import { isScorable } from 'js/utils/question-set';
import UnscorableQuestionDifferential from './UnscorableQuestionDifferential';

function getComponent(questionFrom, questionTo) {
  const questionFromIsNullOrScorable =
    !questionFrom || isScorable(questionFrom);
  const questionToIsNullOrScorable = !questionTo || isScorable(questionTo);

  if (!questionFromIsNullOrScorable || !questionToIsNullOrScorable) {
    return UnscorableQuestionDifferential;
  }

  return ScorableQuestionDifferential;
}

export const QuestionDifferential = ({
  from,
  to,
  width = 80,
  height = 100,
  ...props
}) => {
  const Component = getComponent(from, to);

  if (!Component) {
    return null;
  }

  return (
    <Component from={from} to={to} width={width} height={height} {...props} />
  );
};

export default QuestionDifferential;
