export const longTextQuestionSets = [
  {
    name: 'Long Text Questions',
    key: 'long-text-questions',
    category: 'long-text',
    questions: [
      {
        text: 'What is your name?',
        key: 'intra-team-collaboration',
        type: 'long-text',
      },
      {
        text: 'What is your quest?',
        key: 'quest',
        type: 'long-text',
      },
    ],
  },
];
