import * as React from 'react';
import { Icon } from 'js/components';

const Loading = () => {
  return (
    <div className="loading">
      <Icon icon={['fal', 'circle-notch']} spin />
    </div>
  );
};

export default Loading;
