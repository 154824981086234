import { Icon } from 'js/components';
import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

function AcceptInviteSuccessComponent({ workspaceId, workspaceName }) {
  return (
    <div className="page-result">
      <div className="result-icon">
        <Icon
          fixedWidth
          icon={['fas', 'check']}
          transform="shrink-4"
          mask={['fas', 'circle']}
        />
      </div>
      <div className="result-body">
        <h2>Success!</h2>
        <div>
          <p>
            You have been added to the workspace:{' '}
            <strong className="primary">{workspaceName}</strong>
          </p>
          <Link to={`/w/${workspaceId}/dashboard`}>
            <Button color="primary" size="lg" className="full-width-sm">
              Go to the Workspace
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AcceptInviteSuccessComponent;
