import { yesNo } from './base-answers';

export const teamDynamics = {
  name: 'Team Dynamics',
  key: 'team-dynamics',
  category: 'team-dynamics',
  questions: [
    {
      text: 'Are the Dev team members 100% dedicated for the work of the selected team (Are team members part of just one Agile team)?',
      key: 'dedicated-team-members',
      answers: yesNo,
    },
    {
      text: 'Is the team able to self-organize around the work to deliver value?',
      key: 'self-organizing-teams',
      answers: yesNo,
    },
    {
      text: 'Does the team work at a sustainable pace to deliver features?',
      key: 'sustainable-pace',
      answers: yesNo,
    },
    {
      text: 'Does the Agile Team follow INVEST principle to add any user story as the Product Backlog Item? (INVEST: Independent, Negotiable, Valuable, Estimable, Small and Testable)',
      key: 'invest-principles',
      answers: yesNo,
    },
    {
      text: 'Does the team have a clear definition of DONE?',
      key: 'clear-definition-of-done',
      answers: yesNo,
    },
    {
      text: 'Does the Scrum Master have the tools to remove impediments during the Sprint?',
      key: 'scrum-master-able-to-remove-impediments',
      answers: yesNo,
    },
    {
      text: 'Does the team have a clear definition of READY?',
      key: 'clear-definition-of-ready',
      answers: yesNo,
    },
    {
      text: 'Does the team encourage and uplift others?',
      key: 'team-is-encouraging',
      answers: yesNo,
    },
    {
      text: 'Does the team look for positive behaviors and incorporate them into process to produce the best outcomes?',
      key: 'incorporates-positive-behaviors',
      answers: yesNo,
    },
  ],
};

export default teamDynamics;
