import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { denormalize } from 'normalizr';

import { SCHEMAS } from 'js/api';

import { listRecent } from 'js/actions/assessment-actions';
import { search as searchPassphrases } from 'js/actions/passphrase-actions';

import GuestHomeRouteComponent from '../components/GuestHomeRouteComponent';

const queryIdBase = 'home-route-container';

export class GuestHomeRouteContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passphraseMessage: '',
      initialLoadComplete: false,
      assessments: [],
    };
  }

  componentDidMount() {
    this.setState({ initialLoadComplete: true });

    const { listRecentAssessments } = this.props;
    listRecentAssessments(`${queryIdBase}-recent-assessments`).then(
      (assessments) => {
        this.setState({ initialLoadComplete: true, assessments });
      }
    );
  }

  componentWillUnmount() {
    this.setState({ initialLoadComplete: false });
  }

  handleJoinAssessment = (passphrase) => {
    const { searchPassphrases, history } = this.props;
    this.setState({
      passphraseMessage: '',
    });

    const setNotFound = () => {
      this.setState({
        passphraseMessage: 'No assessment found',
      });
    };

    return searchPassphrases({ passphrase }).then((results) => {
      if (results.length > 0) {
        history.push(`/assessments/${results[0].assessment_id}/run`);
      } else {
        setNotFound();
      }
    }, setNotFound);
  };

  render() {
    const { loadingAssessments } = this.props;
    const { initialLoadComplete, passphraseMessage, assessments } = this.state;

    return (
      <GuestHomeRouteComponent
        joinAssessmentMessage={passphraseMessage}
        loaded={initialLoadComplete}
        loadingAssessments={loadingAssessments}
        onJoinAssessment={this.handleJoinAssessment}
        assessments={assessments}
      />
    );
  }
}

export default withRouter(
  connect(
    (state) => {
      const assessmentQueryInfo =
        state.queries[`${queryIdBase}-recent-assessments`] || {};

      const assessments = denormalize(
        assessmentQueryInfo.result || [],
        SCHEMAS.ASSESSMENT_ARRAY,
        state.entities
      );

      return {
        me: state.identity.me,
        assessments,
        loadingAssessments: assessmentQueryInfo.isLoading || false,
      };
    },
    {
      searchPassphrases,
      listRecentAssessments: listRecent,
    }
  )(GuestHomeRouteContainer)
);
